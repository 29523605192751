import promisify from "../helpers/promise.util.js";
import Cavi2Util from "../helpers/cavi.util.js";

const { toQueryString } = Cavi2Util;

/*
Class to manage all Everest requests API
Get the connection token
*/
var Everest_CLS = function (urlConfig, libelleConfig) {
    this.contentJSON = urlConfig;
    this.url = this.contentJSON.urlEverest; //this.url = urlWSEverest;
    this.urlServer = this.contentJSON.urlServer;
    this.urlOpenDap = this.contentJSON.urlEverestOpenDap;
    this.urlWFS = this.contentJSON.urlWFS;
    this.urlWMS = this.contentJSON.urlWFS.replace("wfs?", "wms");
  this.authToken = null;
  this.selectedId = null; //MOSA ID
  this.selectedStudyAreaName = null; //MOSA StudyAreaName
    this.SuperUser = false;
    this.studyArea = [];
    this.KpiMeta = [];
    this.callbackErrorTokenTimeout = undefined; //Permet le lock screen en cas de timeout du token.
    this.tabError = []; //Liste de toutes les requètes qui ont plantés à cause du token. On les relances une fois le token up de nouveau.

    //Gestion des elts selectionnees
    this.selectedTypeName = null;
    this.selectedCaseId = null; //Requis pour modif une simu
    this.selectedPid = null; //Requis pour modif une simu
    this.selectedScenarioId = null;

    this.Results = []; //Pour pas requeter plusieurs fois la même chose
    this.UOModified = {
        Target: [],
        Context: [],
        Initiatives: []
    }; //Pour centraliser les éléments modifiés entre tous les panels.

    // stores content JSON file libelles
    // used by tasksimulation
    this.libelle = libelleConfig;

    //stores pairs id simu and date of the last run
    // used in TaskSimulation_CLS.js
    this.infosSimuRun = [];

    //boolean set true when a simulation successfully finish runnnin
    // test un tw3d with a timer
    //used in TaskSimulation_CLS.js  and tw3d.js
    this.boolCallWFSDyn = false;

    //boolean set true when a user launch manually a run (not from init)
    this.cptRunIsnotFromInit = 0;

    this.boolRunIsnotFromInit = false;
}

Everest_CLS.prototype.constructor = Everest_CLS;

Everest_CLS.prototype.Set_Selected_Id = function (id) {
  this.selectedId = id;
}
Everest_CLS.prototype.Set_Selected_StudyAreaName = function (id) {
  this.selectedStudyAreaName = id;
}



//Based on the SC001 Authenticate
// Security API
//API to manage authentication and access control.
Everest_CLS.prototype.Init_Token = function(username, password, callbackFctSuccess, callbackFctError) {
    //ajax request
    $.ajax({
        //html method used for this request
        type: 'post',
        context: this,
        //data is the response expected
        success: function(data, status) {
            //store the authentication Token for furhter use
            this.authToken = data;
            document.cookie = "EVERESTSessionId=" + data;
            document.cookie= "EVERESTUsername=" +username;

            if (callbackFctSuccess != undefined) {
                callbackFctSuccess(data, status);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            if (callbackFctError != undefined) {
                callbackFctError(jqXHR, textStatus, errorThrown);
            }
        },
        //url of the webservice called for request
        url: this.url + "access/login/authentication",
        //data send to the webservice
        data: '{"username":"' + username + '", "password":"' + password + '"}',
        //kind of content expected as response
        contentType: "application/json;"
    });
}

Everest_CLS.prototype.getUsername = function() {
    let utok = document.cookie.match(/(?:^|;)\s*EVERESTUsername=([^;]+)/);
    if(!utok) return undefined;
    return utok[1].trim();
}
//SC002
//Check that the token is valid.
Everest_CLS.prototype.Check_Token = function(callbackSuccess, callbackError) {

    var url = "access/login/tokenstatus";
    if(! this.authToken) {
        //Try to fetch it from cookie
        let ktok = document.cookie.match(/(?:^|;)\s*EVERESTSessionId=([a-z0-9]+)/);
        if(!ktok || ktok[1]==="unset") {
            if (callbackError != undefined) {
                callbackError(undefined,undefined,undefined);
            }
            return;
        }
        this.authToken = ktok[1];
    }

    var token = this.authToken;

    //ajax request
    $.ajax({
        //html method used for this request
        type: 'get',
        context: this,
        //put user Key in request header
        beforeSend: function(request) {
            request.setRequestHeader("X-sessionId", token);
        },
        //data is the response expected
        success: function(data, status) {

            if (callbackSuccess != undefined) {
                callbackSuccess(data, status);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            if (callbackError != undefined) {
                callbackError(jqXHR, textStatus, errorThrown);
            }
        },
        //url of the webservice called for request
        url: this.url + url,
        //means just check token but do not extend the duration
        extendsession: 0,
        //kind of content expected as response
        contentType: "application/json;"
    });

};

//Conn
Everest_CLS.prototype.TestConn = function(username, callbackSuccess, callbackError) {
    //  this.get("", "Conn", callbackSuccess, callbackError);
    this.Check_Token(callbackSuccess, callbackError);
};


/*****************************
    User Profile API
******************************/
// UP001 : Read Profile
//Read the user profile. This profil is accessible for the user himself or for super user only.
Everest_CLS.prototype.ReadProfile = function(username, callbackSuccess) {
    this.get("users/" + username, "ReadProfile", callbackSuccess, null);
};

//UP003 : Reset Password
//Request to regenerate password. NB. This server must always return a success status
//(204) even if login is unknown or there is a problem sending the mail ...
// - login
// return random password
Everest_CLS.prototype.ResetPassword = function(login, callbackSuccess, callbackError) {
    this.post("access/login/" + login + "/forgotpassword", "ResetPassword", login, callbackSuccess, callbackError);
};

//UP004 : Resend Login
//Request to send the login by mail. NB. This server must always return a success status
//(204) even if email is unknown or there is a problem sending the mail ...
// input : - email
Everest_CLS.prototype.ResendLogin = function(email, callbackSuccess, callbackError) {
    this.post("access/login/forgotlogin", "ResendLogin", email, callbackSuccess, callbackError);
};
//UP002 : Update Profile
//Update the user profile. This profil is accessible for the user himself or for super user only.
//inputs :   -   fullName
//           -   email
//           -   password
Everest_CLS.prototype.UpdateProfile = function(param, fullName, email, password, callbackSuccess, callbackError) {
    var inputs = {
        "fullName": fullName,
        "email": email,
        "password": password
    };
    this.set("users/" + login, param, inputs, callbackSuccess, callbackError);
};


/*****************************
    Urban Data API
******************************/
// UA001 :  MOSA -> List Models
//List of all available models This service is based on Paged List Service Pattern. If user is a
//super user all models except those with state DELETED are returned. If user is not a
//super user only models with state PUBLISHED are returned.
Everest_CLS.prototype.List_Models = function(callbackSuccess, callbackError) {
    this.get("models/", "List_Models", callbackSuccess, callbackError);
};

//UA001b : Read Model Metadata
//Get metadata about a model
//Recup les info du Mosa (dont la liste des initiatives et des timescales)
Everest_CLS.prototype.List_Models_Info = function(callbackSuccess, callbackerror) {
    if (this.Results["List_Models_Info"] == undefined) {
        this.get("models/" + this.selectedId, "List_Models_Info " + this.selectedId + " :", function(data) {
            this.Results["List_Models_Info"] = data;
            callbackSuccess(data);
        }.bind(this), callbackerror);
    } else {
        callbackSuccess(this.Results["List_Models_Info"]);
    }
};

// UA003: List Root Urban Objects
//List of all urban objects of a given model that have no OwnerPID (null). This service is
//based on Paged List Service Pattern. This contains usually one Item which is the city.
//  - modelId : Id of the model considered
Everest_CLS.prototype.List_Root_Urban_Objects = function(callbackSuccess, callbackError) {
   return this.get("models/" + this.selectedId + "/urban/rootobjects/", "List_Root_Urban_Objects", callbackSuccess, callbackError);
};

//UA004 : List Urban Objects Children
//List of all urban objects of an urban area mdoel that are children of the given PID. if PID
//is at town, it would for exemple list zone ... This service is based on Paged List Service Pattern
//Urban Objects => objets de niveau n+x (les 10 premiers)
//  - modelId : Id of the urban area model
//  - pid : PID of urban object to read children
Everest_CLS.prototype.List_Urban_Objects_Children = function(pid, obj, callbackSuccess, callbackError) {
    this.get("models/" + this.selectedId + "/urban/objects/" + pid + "/subobjects", obj, callbackSuccess, callbackError);
};

//UA005: Get Urban Object Metadata => Get info about an urban object by PID
//  - modelId : id of the selected model
//  - pid : PID of urban object to read
Everest_CLS.prototype.Get_Urban_Object_Metadata = function(pid, obj, callbackSuccess) {
    this.get("models/" + this.selectedId + "/urban/objects/" + pid, obj, callbackSuccess);
};


//UA006: Read Urban Objects General Info => List of all the values of all attributes for an urban object
//  - modelId : id of the urban area model considered.
//  - pid : PID of entity to read
Everest_CLS.prototype.Read_Urban_Objects_General_Info = function(pid, obj, callbackSuccess) {
    this.get("models/" + this.selectedId + "/urban/objects/" + pid + "/attributes", "Read_Urban_Objects_General_Info : " + pid, this.logData, null);
};

//UA007: Read General Info Metadata => Returns all the meta information about an urban object type attributes.
//  - modelId : the model Id
//  - typeName : name of the urban object type
Everest_CLS.prototype.Read_General_Info_Metadata = function(typeName, obj, callbackSuccess) {
    this.get("models/" + this.selectedId + "/urban/metadata/" + typeName, obj, callbackSuccess);
};

//UA008: List WFS Layers attached to a given MOSA.
//  - modelId : the model Id
Everest_CLS.prototype.List_Model_WFSlayers = function(obj, callbackSuccess, callbackError) {
  this.get("models/" + this.selectedId + "/layers", obj, callbackSuccess, callbackError);
};

//UA009: Link WFS Layers to a given MOSA.
//  - modelId : the model Id
//  - layerId : the layer ID
Everest_CLS.prototype.Link_Model_WFSlayers = function(layerId, callbackSuccess, callbackError) {
    this.set("models/" + this.selectedId + "/layers/studyarea/" + layerId, layerId, "", callbackSuccess, callbackError);
};

//UA010: unlink WFS Layers from given MOSA.
//  - modelId : the model Id
//  - layerId : the layer ID
Everest_CLS.prototype.Unlink_Model_WFSlayers = function(layerId, callbackSuccess, callbackError) {
    this.delete_fct("models/" + this.selectedId + "/layers/studyarea/" + layerId, "unlink_model_" + this.selectedId + "_wfslayer_" + layerId, callbackSuccess, callbackError);
};

// Autres
//Urban Objects => objets de niveau n+x (la suite en recursif tant que plus de 10 éléments)
Everest_CLS.prototype.List_Urban_Objects_Children_Next_Url = function(fullurl, obj, callbackSuccess, callbackError) {
    this.get(fullurl.replace(this.url, ""), obj, callbackSuccess, callbackError);
};



/*****************************
    Model Management API
******************************/
//MM001: Create a new System Model. User must be a super user to perform this Operation. It is created in state UNPUBLISHED.
//  - name : name of the model
//  - description : description of the model
Everest_CLS.prototype.Create_Model = function(name, description, studyAreaCode, callbackSuccess, callbackError) {
    var inputs = {
        "name": name,
        "description": description,
        "studyAreaCode": studyAreaCode
    };
    this.post("models/", "Create_Model : " + name, inputs, callbackSuccess, callbackError);
};

//MM006: Delete Model. This operation can be only done by a super user.
//  - id : id of model
Everest_CLS.prototype.Delete_Model = function(id, callbackSuccess, callbackError) {
    this.delete_fct("models/" + id, "Delete_Model : " + id, callbackSuccess, callbackError);
};

//MM008: Read log files. Download a zipfile containing functional log files of a model. This service can only be accessed by super user.
//  - id : id of model downloading log
Everest_CLS.prototype.Read_Log_File = function(id, callbackSuccess, callbackError) {

    this.get("models/" + id + "/logfiles", "Read_Log_File : " + id, callbackSuccess, callbackError);
};

//MM005: Update a model, this can be done by superuser only.
//  - name : name of the model.
//  - description : description of the model
//  - MIDFileId: the identifier of the attached file, null if there is no MID File.
//  - cityGMLFileId: the identifier of the attached file, null if there is no CityGML File.
//  - state : The desired state : PUBLISHED or UNPUBLISHED
Everest_CLS.prototype.Update_Model = function(id, inputs, callbackSuccess, callbackError) {
    this.set("models/" + id, id, inputs, callbackSuccess, callbackError);
};


/*****************************
    File Management API
******************************/
//FM001: Creates a new file into database. The status of the created file is “CREATED”.
// - name : name is a name for the file, this is only used for listing, this is an optional parameter.
// - type : type this is a file type, this mandatory but must be one of the valid file types.
// - metadata : metadata this is the user defined key/value pairs associated to the file. User is free to set any string typed key/values in metadata. They will be linked to the file and will be available when file info is read.
Everest_CLS.prototype.Create_File = function(name, type, metadata, callbackSuccess, callbackError) {
    var inputs = {
        "name": name,
        "type": type,
        "metadata": metadata
    };
    this.post("files/", "Create_File : " + name, inputs, callbackSuccess, callbackError);
};

//FM002: Upload the content of the file. The state after upload is either “PUBLISHED” or “PROCESSING”.
//  - The file content as the body of the request.
Everest_CLS.prototype.Upload_File = function(name, id, type, data, callbackSuccess, callbackError) {
    this.UploadFile("files/" + id + "/content", name, type, data, callbackSuccess, callbackError);
};
/*****************************
    StudyArea API
******************************/
//SY001
Everest_CLS.prototype.List_Shapefiles = function(saCode, callbackSuccess, callbackError) {
    console.log(saCode!=undefined?saCode:this.selectedStudyAreaName);
  return this.get("studyarea/" + (saCode != undefined ? saCode : this.selectedStudyAreaName) + "/layers", "List_Shapefiles", callbackSuccess, callbackError);
};
//SY002 Read Study Area WFS Layer
Everest_CLS.prototype.Read_StudyAreaDescription = function(layerId, callbackSuccess, callbackError) {
    this.get("studyarea/layers/" + layerId, "Read_StudyAreaDescription : " + layerId, callbackSuccess, callbackError);
};
//SY003
// Create Study Area WFS layer
Everest_CLS.prototype.Create_StudyArea = function(saCode, displayName, type, callbackSuccess, callbackError) {
    var inputs = {
        "displayName": displayName,
        "type": type,
        "wfsUrl": this.urlWFS
    };
    this.post("studyarea/" + saCode + "/layers", "Create_Layer : " + displayName, inputs, callbackSuccess, callbackError);
};
//SY004
// Update Study Area WFS Layers
Everest_CLS.prototype.Update_StudyArea = function(id, input, callbackSuccess, callbackError) {
    this.set("studyarea/layers/" + id, id, input, callbackSuccess, callbackError);
};
//SY005
//Delete Study Area
Everest_CLS.prototype.Delete_StudyArea = function(id, callbackSuccess, callbackError) {
    this.delete_fct("studyarea/layers/" + id, "Delete_StudyArea : " + id, callbackSuccess, callbackError);
};
/**
 * Delete GeoServer Layer (Pythelet Service: gsrm)
 *
 * data should have following structure
 * [
 *   {
 *      "studyArea": "D",
 *      "technicalName": "Layer-123456"
 *   },
 *   {
 *      "studyArea": "D",
 *      "caseId": "123456"
 *   },
 *   .....
 * ]
 *
 * @param data
 * @constructor
 */
Everest_CLS.prototype.Delete_GeoServerLayer = function(data){
  return this.post("proc/gsrm/invoke", undefined, data);
};

/*****************************
    Execution API
******************************/
//EX003: Read Run Status
// The status of the run represents the state of the run at a given moment. The different states are:
// pendingConfiguration     created      inProgress     stopped     finished        failed
//This operation returns the state of the run. The progress will be in percentage from 0 to 100.
//  - id : id of model downloading log
Everest_CLS.prototype.Read_Run_Status_Exec = function(id, callbackSuccess, callbackError) {
    this.get("exec/run/" + id + "/status", "Read_Run_Status_Exec : " + id, callbackSuccess, callbackError);
};

// EX004: Read Run Output File
//Read content of a file in the run directory.
// - runId: the id of the run
// - filename : name of the file to read from run directory; Non intenral users can only read functional.log and output.<something> files.
Everest_CLS.prototype.Read_Run_Output_File = function(id, filename, callbackSuccess, callbackError) {
    return this.get("proc/run/" + id + "/files/" + filename, "Read_Run_Output_File : " + id, callbackSuccess, callbackError);
};


/*****************************
    Simulation Management API
******************************/

//MA002: List Urban Object Types => This operation returns all the urban object types of a model.
//Urban Object Types (Array)=> City / Town / Neighborhood / Precinct / Building

Everest_CLS.prototype.List_Urban_Object_Types = function(callbackSuccess, callbackError) {
    if (this.Results["List_Urban_Object_Types"] == undefined) {
        this.get("models/" + this.selectedId + "/sim/metadata", "List_Urban_Object_Types", function(data) {
            this.Results["List_Urban_Object_Types"] = data;
            callbackSuccess(data);
        }.bind(this), callbackError);
    } else {
        callbackSuccess(this.Results["List_Urban_Object_Types"]);
    }
};

//MA003: Read Urban Object Type Metadata => Returns all the meta information about an urban object type attributes.
//  - typeName : name of the urban object type
//Utilisé par this.GestionInitiatives.Load(typeName); sur Gestion_UrbanObj.Click
Everest_CLS.prototype.Read_Urban_Object_Type_Metadata = function(typeName, obj, callbackSuccess, callbackError) {
    this.get("models/" + this.selectedId + "/sim/metadata/" + typeName, obj, callbackSuccess, callbackError);
};

//MA003b: Read KPIMetadata => This operation returns all the meta information about an urban object type KPIs. These are attributes of the
//Output { "<KPIName>":{ "displayName":<name to display for attribute>, "timescale":<timescalecode or null> , "numericValueMin":<minimumValue>, "numericValueMax":<maximumValue>, "type":"<attributeType>",
//          "unit":"<unitValue>", "metadatas":[ { "name":"<meta_name>" , "value":"<meta_value>" }, {...}, ... ]  }, ... }
//    KPIName: name of the attribute in Target Values Structure
//    displayName:name to display for attribute,
//    minimumValue: minimum value of the attribute (if type == NUM)
//    maximumValue: maximum value of the attribute (if type == NUM)
//    attributeType: type of the attribute : NUM, STR or BOOL.
//    unitValue: unit of the value (type == NUM)
//    meta_name: name of the metadata
//    meta_value: value of the metadata
//    timescale : code of eventual timescale to which attribute blongs.
Everest_CLS.prototype.Read_KPIMetadata = function(typeName, params, callbackSuccess, callbackError) {
    this.get("models/" + this.selectedId + "/targets/metadata/" + typeName, params, callbackSuccess, callbackError);
};

//Créer / Copier un bundle (dossier)
//MA005: Create Or Copy Bundle
Everest_CLS.prototype.Create_Or_Copy_Bundle = function(node_parent, name, description, copyOf, callbackSuccess, callbackFailed) {
    let inputs = {
        "name": name,
        "description": description
    };

    if (copyOf) {
        inputs["copyOf"] = copyOf;
    }
    inputs["modelId"] = this.selectedId;

    return this.post("sim/bundles", node_parent, inputs, callbackSuccess, callbackFailed);
};

//MA006: Update Bundle Info
Everest_CLS.prototype.Update_Bundle_Info = function(bundleId, name, description, users, groups) {
    let input = {};
    if (name && name !== "") {
        input["name"] = name;
    }
    // description is mandatory, otherwise backend won't update
    input["description"] = description;

    // auth
    if(users && Array.isArray(users)){
        input["authorizedUsers"] = users
    }

    if(groups && Array.isArray(groups)) {
        input["authorizedGroups"] = groups
    }

    //urlWS, id, input, callbackFctSuccess, callbackFctError
    return this.set("sim/bundles/" + bundleId, bundleId, input, undefined, undefined);
};


//MA007: List_Bundles => Dossier de simulation
//    - List_Simulation_Cases_By_Bundle=> Feuille
Everest_CLS.prototype.List_Bundles = function(callbackSuccess, callbackError) {
    /*this.get("models/" + this.selectedId + "/sim/metadata/" + typeName, obj, () => {
      //this.contentJSON
      callbackSuccess(data, status, id);
    }*/
    this.get("models/" + this.selectedId + "/sim/bundles", "List_Bundles", (data, status, id) => {
        if(!callbackSuccess) return;

        if(this.contentJSON.limitBundlesTo) {
            data.items = _.filter(data.items,(item)=>{return this.contentJSON.limitBundlesTo.contains(item.name)});
        }
         callbackSuccess(data, status, id)
    }, callbackError);
};

//     List_Bundles_Next_Url => Dossier : le reste en recursif tant que plus de 10 éléments
Everest_CLS.prototype.List_Bundles_Next_Url = function(fullurl, callbackSuccess, callbackError) {
    this.get(fullurl.replace(this.url, ""), "List_Bundles", (data, status, id) => {
        if(!callbackSuccess) return;

        if(this.contentJSON.limitBundlesTo) {
            data.items = _.filter(data.items,(item)=>{return this.contentJSON.limitBundlesTo.contains(item.name)});
        }
         callbackSuccess(data, status, id)
    }, callbackError);
};

//MA008: Read Bundle Info
//output : {"name":<name of the bundle>,"description":<description of the bundle>,"modelId":<idOfOwningModel>}
//Info sur le dossier
Everest_CLS.prototype.Read_Bundle_Info = function(node, callbackSuccess, callbackFailed) {
    return this.get("sim/bundles/" + node.id, node, callbackSuccess, callbackFailed);
};

//MA009: Delete Bundle
Everest_CLS.prototype.Delete_Bundle = function(node, callbackSuccess, callbackFailed) {
    return this.delete_fct("sim/bundles/" + node.id, node, callbackSuccess, callbackFailed);
};

//Créer / Copier une Simulation
//MA010: Create Or Copy Simulation Case
Everest_CLS.prototype.Create_Or_Copy_Simulation_Case = function(param, name, description, copyOf, callbackSuccess, callbackFailed) {
    let inputs = {
        "name": name,
        "description": description
    };
    if (copyOf !== undefined && copyOf !== null) {
        inputs["copyOf"] = copyOf;
    }
    return this.post("sim/bundles/" + param.pid + "/cases", param, inputs, callbackSuccess, callbackFailed);
};

//MA011: List Simulation Cases By Bundle
//    - List_Simulation_Cases_By_Bundle=> Feuille :(0 à 10) le reste via next url
Everest_CLS.prototype.List_Simulation_Cases_By_Bundle = function(obj, callbackSuccess, callbackError) {
    this.get("sim/bundles/" + obj.id + "/cases", obj, callbackSuccess, callbackError);
};

//     List_Simulation_Cases_By_Bundle=> Feuille : le reste en recursif tant que plus de 10 éléments
Everest_CLS.prototype.List_Simulation_Cases_By_Bundle_Next_Url = function(fullurl, obj, callbackSuccess, callbackError) {
    this.get(fullurl.replace(this.url, ""), obj, callbackSuccess, callbackError);
};

//MA012: Update Simulation Case Info And Scenario
//Input : { "name":<new name>, "description":<new textual description>, "scenarioId":<scenarioId> }
Everest_CLS.prototype.Update_Simulation_Case_Info_And_Scenario = function(param, caseId, name, description, scenarioId, bundleId, callbackSuccess, callbackFailed) {
    var input = {};
    if (name) {
        input["name"] = name;
    }
    if (description) {
        input["description"] = description;
    }
    if (scenarioId) {
        input["scenarioId"] = scenarioId;
    }
    if (bundleId) {
        input["bundleId"] = bundleId;
    }
    //urlWS, id, input, callbackFctSuccess, callbackFctError
    return this.set("sim/cases/" + caseId, param, input, callbackSuccess, callbackFailed);
};

//MA013: Read Simulation Case Info=> Read information about a given simulation case
//Exemple de retour pour caseId 1001962 : Object {scenarioId: 1001904, description: "", name: "Sim Test Pierre", bundleId: 1001961}
//On a un numéro de scenario.
Everest_CLS.prototype.Read_Simulation_Case_Info = function(node, callbackSuccess, callbackFailed) {
    return this.get("sim/cases/" + node.id, node, callbackSuccess, callbackFailed);
};

//MA014: Delete Simulation Case
Everest_CLS.prototype.Delete_Simulation_Case = function(node, callbackSuccess, callbackFailed) {
    return this.delete_fct("sim/cases/" + node.id, node, callbackSuccess, callbackFailed);
};


/*****************************
    Scenario Management Api (Context)
******************************/

/**
 * Get all scenarios with its info (combined response of MA004 & SM002b)
 * MA004 lists all scenarios but with incomplete information, e.g. property "published" is not available
 *
 * where
 * <MA004>: list scenarios by modelId
 * <SM002b>: read scenario info by scenarioId
 *
 * @returns {Promise<some>}
 */
Everest_CLS.prototype.getAllScenarios = function () {

  return promisify(this.List_Scenarios(undefined)).then(data => {
    // if only first page available, return first page's data
    if (!data["nextUrl"]) {
      return data;
    }

    // recursively get data from next url
    let getDataFromNextUrl = (nextUrl) => {
      return promisify(this.getNextUrl(nextUrl))
        .then(nextData => {
          // merge data from first page and data from next url
          data["items"] = data["items"].concat(nextData["items"]);
          data["nextUrl"] = nextData["nextUrl"];

          if (nextData["nextUrl"]) {
            return getDataFromNextUrl(nextData["nextUrl"]);
          } else {
            // resolve final page's data
            return data;
          }
        });
    };

    return getDataFromNextUrl(data["nextUrl"]);

  }).then((data) => {
    if (!Array.isArray(data["items"]) || !data["items"].length) {
      return;
    }
    // get each scenario's info
    let promises = data["items"].map((item) => promisify(this.Read_Scenario_Info(undefined, item["id"])));

    return Promise.all(promises).then((resolved) => {
      // resolved promise order is preserved
      data["items"].forEach((item, idx) => {
        // add "published" prop for each scenario with its info "published"
        item["published"] = resolved[idx]["published"];
      });

      return data;
    });

  });

};

//MA004: List Scenarios => List available scenarios. If the user is a super user all the scenarios are listed else only scenarios with published = TRUE
//Output {  "totalCount":<totalNumberOfMatchingObjects>, "firstItemIndex":<the position of the first item>,
//          "items": [ { "id": <scenarioId>, "name":"<scenarioName>", "description":"<scenarioDescription>"}, ... ],
//          "nextUrl":<nextpageurl>}
Everest_CLS.prototype.List_Scenarios = function(param, callbackSuccess, callbackFailed) {
    return this.get("models/" + this.selectedId + "/sim/scenarios", param, callbackSuccess, callbackFailed);
};

//SM001: Create Or Copy Scenario (Super user)
//Output{ "id":<createdScenarioId> }
Everest_CLS.prototype.Create_Or_Copy_Scenario = function(param, name, description, copyOf, callbackSuccess, callbackFailed) {
    var inputs = {
        "name": name,
        "description": description
    };
    if (copyOf != undefined && copyOf != null) {
        inputs.copyOf = copyOf;
    } else {
        inputs.modelId = this.selectedId;
    }
    return this.post("sim/scenarios", param, inputs, callbackSuccess, callbackFailed);
};

//SM002: Update Scenario Info And Status (Super user)
//Output: Nothing
Everest_CLS.prototype.Update_Scenario_Info = function(param, scenarioId, name, description, published, callbackSuccess, callbackFailed) {
    var inputs = {
        "name": name,
        "description": description,
        "published": published
    };
    return this.set("sim/scenarios/" + scenarioId, param, inputs, callbackSuccess, callbackFailed);
};

//SM002b: Read Scenario Info => Read information about the scenario. If scenario is not published user must be (Super user)
//Output { { "name":"<newScenarioName>", "description":"<newScenariodescription>", "published":"<true/false publication status>" } }
Everest_CLS.prototype.Read_Scenario_Info = function(param, scenarioId, callbackSuccess, callbackFailed) {
    return this.get("sim/scenarios/" + scenarioId, param, callbackSuccess, callbackFailed);
};

//SM003: Delete Scenario => Delete a scenario. User must be (super user)
//Scenario can only be deleted if :
//  - Scenario is user defined.
//  - There is no simulation case linked to this scenario.
//In case of error, an explicit error message is returned in this case precising the cause and the eventual list of SimCases that are linked to the scenario.
Everest_CLS.prototype.Delete_Scenario = function(param, scenarioId, callbackSuccess, callbackFailed) {
    return this.delete_fct("sim/scenarios/" + scenarioId, param, callbackSuccess, callbackFailed);
};

//SM004: Read Scenario Attribute Values => Return values of each scenario attribue.
//Output {  "<factorName>": [<timestep1value>,<timestep2value> ...],"<factorName>": [<timestep1value>,<timestep2value> ...],... }
Everest_CLS.prototype.Read_Scenario_Attribute_Values = function(param, scenarioId, pid, callbackSuccess, callbackFailed) {
    return this.get("sim/scenarios/" + scenarioId + "/objects/" + pid + "/attributes", param, callbackSuccess, callbackFailed);
};

//SM005: Update Scenario Attribute Values => Update the value of all the scenario attributes for an urban objects that are specified in the request.
//An error must be thrown if json keys are not an attribute for scenario. User must be (super user)
//Input {"<factorName>": [<timestep1value>,<timestep2value> ...],"<factorName>": [<timestep1value>,<timestep2value> ...],...}
//Output: Nothing
Everest_CLS.prototype.Update_Scenario_Attribute_Values = function(param, scenarioId, pid, inputs, callbackSuccess, callbackFailed) {
    return this.set("sim/scenarios/" + scenarioId + "/objects/" + pid + "/attributes", param, inputs, callbackSuccess, callbackFailed);
};


/*****************************
    Scenario Management Api (old)
******************************/
////SM002b: Read Scenario Info => Read information about the scenario. If scenario is not published user must be super user
//Everest_CLS.prototype.Read_Scenario_Info = function (scenarioId) {
//    this.get("sim/scenarios/" + scenarioId, "Read_Scenario_Info : " + scenarioId, this.logData, null);
//};
////SM004: Read Scenario Attribute Values => Return values of each scenario attribue.
////scenarioId: id of the scenario
////pid : PID of entity to read
//Everest_CLS.prototype.Read_Scenario_Attribute_Values = function (scenarioId, pid) {
//    this.get("sim/scenarios/" + scenarioId + "/objects/" + pid + "/attributes", "Read_Scenario_Attribute_Values : " + pid, this.logData, null);
//};
////Click sur save dans initiatives ?
////SM005: Update Scenario Attribute Values => Update the value of all the scenario attributes for an urban objects that are specified in the request.
////scenarioId: id of the scenario
////pid : PID of entity to read
////Input
////    {
////    "<factorName>": [<timestep1value>,<timestep2value> ...],
////    "<factorName>": [<timestep1value>,<timestep2value> ...],
////    ...
////    }
//Everest_CLS.prototype.Update_Scenario_Attribute_Values = function (scenarioId, pid,input) {
//    this.set("sim/scenarios/" + scenarioId + "/objects/" + pid + "/attributes", "Update_Scenario_Attribute_Values : " + pid, input, this.logData, null);
//};


/*****************************
    Simulation Setup API
******************************/
//SA006: Read Urban Objects Attributes => List of all the values of all attributes linked to the simulation case.
// - caseId : id of the simulation case.
// - pid : PID of entity to read
Everest_CLS.prototype.Read_Urban_Objects_Attributes = function(caseId, pid, callbackSuccess, callbackError) {
    return this.get("setup/cases/" + caseId + "/objects/" + pid + "/attributes", "Read_Urban_Objects_Attributes : " + caseId + "$" + pid, callbackSuccess, callbackError);
};

//SA007: Update Urban Object Attributes => Update the value of all the attributes for an urban objects.
// - caseId : id of the simulation case.
// - pid : PID of entity to read
//Input {"attributeName1": <attributevalue1>,"attributeName2": <attributevalue2>,...}
//  - attributeName: name of the attribute
//  - attributevalue: value of the attribute ( STR, NUM or BOOL)
Everest_CLS.prototype.Update_Urban_Objects_Attributes = function(param, caseId, pid, input, callbackSuccess) {
    // req Yohan:
    // this.set("setup/cases/" + caseId + "/objects/" + pid + "/attributes", "Update_Urban_Objects_Attributes : " + caseId + "$" + pid, input, callbackSuccess, null);
    // test pour etoile UOModified
    return this.set("setup/cases/" + caseId + "/objects/" + pid + "/attributes", param, input, callbackSuccess, null);
};

//SA011: List Updated Urban Objects => List of urban objects that have been modified in a simulation case
// - caseId : id of the simulation case.
Everest_CLS.prototype.List_Updated_Urban_Objects = function(param, caseId, callbackSuccess, callbackFailed) {
    this.get("setup/cases/" + caseId + "/delta/objects", param, callbackSuccess, callbackFailed);
};

//SA012: List Updated Urban Objects Attributes => List the attributes that have been modified in the simulation case.
// - caseId : id of the simulation case.
// - pid : PID of entity to read
Everest_CLS.prototype.List_Updated_Urban_Objects_Attributes = function(caseId, pid, callbackSuccess, callbackError) {
    this.get("setup/cases/" + caseId + "/delta/objects/" + pid + "/attributes", "List_Updated_Urban_Objects_Attributes : " + caseId + "$" + pid, callbackSuccess, callbackError);
};


/*****************************
        Strategy API
*****************************/
//ST001: Create Strategy
Everest_CLS.prototype.Create_Strategy = function(param, name, description, simcaseId, callbackSuccess, callbackFailed) {
    var inputs = {
        "name": name,
        "description": description,
        "simcaseId": simcaseId
    };
    this.post("sim/strategies", param, inputs, callbackSuccess, callbackFailed);
};

//ST002: Apply Strategies
//Input {"strategies": [<strageyId1>,<strategyId2>...],"clear": <boolean> }
Everest_CLS.prototype.Apply_Strategy = function(param, strategies, simcaseId, callbackSuccess, callbackFailed) {
    var inputs = {
        "strategies": strategies,
        "clear": true
    }; //Demander plus de précisions sur ce point !!!
    this.set("sim/cases/" + simcaseId + "/strategies", param, inputs, callbackSuccess, callbackFailed);
};

//ST003: List Appied Strategies
//OUTPUT : {"strategies": [ {"id":<strageyId1>,"date":"<date of application>"}, {"id":<strageyId2>,"date":"<date of application>"}...] }
Everest_CLS.prototype.List_Appied_Strategies = function(param, simcaseId, callbackSuccess, callbackFailed) {
    this.get("sim/cases/" + simcaseId + "/strategies", param, callbackSuccess, callbackFailed);
};

//ST004: List Strategies
//OUTPUT : {    "items": [ {"id":<strageyId1>,"name":"<name>","description":"<description>}, {"id":<strageyId1>,"name":"<name>","description":"<description>}, ],
//              "totalCount":<totalCountOfItems>, "firstItemIndex":<the position of the first item>, "nextUrl":"<url to fetch following items>" }
Everest_CLS.prototype.List_Strategies = function(param, callbackSuccess, callbackFailed) {
    this.get("models/" + this.selectedId + "/sim/strategies", param, callbackSuccess, callbackFailed);
};

//ST005: Delete Strategy
Everest_CLS.prototype.Delete_Strategies = function(param, strategyId, callbackSuccess, callbackFailed) {
    this.delete_fct("sim/strategies/" + strategyId, param, callbackSuccess, callbackFailed);
};


/*****************************
        Targets API
*****************************/
//TA001: Create Or Copy Target
//Output{ "id":<createdTargetId> }
Everest_CLS.prototype.Create_Or_Copy_Target = function(param, name, description, copyOf, callbackSuccess, callbackFailed) {
    var inputs = {
        "name": name,
        "description": description
    };
    if (copyOf != undefined && copyOf != null) {
        inputs.copyOf = copyOf;
    }
    this.post("models/" + this.selectedId + "/targets", param, inputs, callbackSuccess, callbackFailed);
};

//TA002: Update Target Info
//Output: Nothing
Everest_CLS.prototype.Update_Target_Info = function(param, targetId, name, description, published, callbackSuccess, callbackFailed) {
    var inputs = {
        "name": name,
        "description": description,
        "published": published
    };
    this.set("targets/" + targetId, param, inputs, callbackSuccess, callbackFailed);
};

//TA003: List Targets
//Output {  "totalCount":<totalNumberOfMatchingObjects>, "firstItemIndex":<the position of the first item>,
//          "items": [ { "id": <targetId>, "name":"<targetName>", "description":"<targetDescription>", "published":"<true/false publication status>"}, ... ],
//          "nextUrl":<nextpageurl>}
Everest_CLS.prototype.List_Targets = function(param, callbackSuccess, callbackFailed) {
    this.get("models/" + this.selectedId + "/targets", param, callbackSuccess, callbackFailed);
};

//TA004: Delete Target (super user)
Everest_CLS.prototype.Delete_Target = function(param, targetId, callbackSuccess, callbackFailed) {
    this.delete_fct("targets/" + targetId, param, callbackSuccess, callbackFailed);
};

//TA005: Read Target KPIValues => Return for each KPI the list of target values. Super user can access this service for all targets. Non super user can only access this service if target is published.
//Output { "KPI1Name": [<timestep1Target>,<timestep2Target> ...], "KPI2Name": [<timestep1Target>,<timestep2Target> ...], ... }
Everest_CLS.prototype.Read_Target_KPIValues = function(param, targetId, pid, callbackSuccess, callbackFailed) {
    this.get("targets/" + targetId + "/objects/" + pid + "/targets", param, callbackSuccess, callbackFailed);
};

//TA006: Update Target KPIValues => Update the value of all the targets for an urban objects that are specified in the request. This service is only available for super user The target must not be published.
//Input { "KPI1Name": [<timestep1Target>,<timestep2Target> ...], "KPI2Name": [<timestep1Target>,<timestep2Target> ...], ... }
//Output: Nothing
Everest_CLS.prototype.Update_Target_KPIValues = function(param, targetId, pid, inputs, callbackSuccess, callbackFailed) {
    this.set("targets/" + targetId + "/objects/" + pid + "/targets", param, inputs, callbackSuccess, callbackFailed);
};

//TA007: Read Target Info => Read information about target. If user is not a super user AND target is not published an error is thrown.
//Output { { "name":"<targetName>", "description":"<targetDescription>", "published":"<true/false publication status>" } }
Everest_CLS.prototype.Read_Target_Info = function(param, targetId, callbackSuccess, callbackFailed) {
    this.get("targets/" + targetId, param, callbackSuccess, callbackFailed);
};


/*****************************
         MCDA API
*****************************/
//MC001: Execute Ranking
//Input{ "simCases":[<simcaseId1>,<simcaseId2>,...], "timeStep":"<stepLabel>", "KPIs": {"<KPI1Name>": { "weight": <weightforKPI1> , "preferredTrend": <"up"/"down">,"pid" : [<PIDs to take into account for this KPI>]} ,}}
//timeStep : the timestap at which the ranking shall be performed.
//simcaseId1... : Ids of simcases to rank, must be already run and have results.
//KPIXName : Name of KPI as shown in OpenDAP results service : <TypeName>/<OuputName> like Neightborhood/TotalEnergyConsumption
//weight : weight value between 0 and 1.
//preferredTrend : “up” the KPI value is best when bigger, “down” the KPI Value is best when lower.
//PIDs : list of PIDs to take into account for this KPI, usually this is neighborhood.
//Output{"simCaseId1": <RankingScore>,"simCaseId2": <RankingScore>...}
Everest_CLS.prototype.Execute_Ranking = function(param, inputs, callbackSuccess, callbackFailed) {
    this.post("proc/mcda/invoke", param, inputs, callbackSuccess, callbackFailed);
};


/*****************************
    Simulation Execution API
******************************/
//RA001: Run Simulation Case => Run the given simulation case
//caseId : id of the simulation case to run
Everest_CLS.prototype.Run_Simulation_Case = function(caseId, callbackSuccess, callbackError) {
    this.post("sim/cases/" + caseId + "/run", caseId, {}, callbackSuccess, callbackError);
};

//RA006: Stop Simulation Case => Stop the given simulation case
//caseId : id of the simulation case to run
Everest_CLS.prototype.Stop_Simulation_Case = function(caseId, callbackSuccess, callbackError) {
    this.delete_fct("sim/cases/" + caseId + "/run", caseId, callbackSuccess, callbackError);
};

//RA002: Read Run Status
//caseId : id of the simulation case to run
Everest_CLS.prototype.Read_Run_Status = function(caseId, callbackSuccess, callbackError) {
    return this.get("sim/cases/" + caseId + "/run", caseId, callbackSuccess, callbackError);
};

//RA004: Export result in NetCDF
//id : id of the simulation case
Everest_CLS.prototype.getUrl_Export_Result_NetCDF = function(caseId) {
    // url have to be like : dx/datacollection/SimcaseID?pids=id1,id2,id3&attributes=attrib1,attrib2,attrib3&sessionid=token
    return this.urlServer + "data/export/results/CASE-" + caseId + "/dataset.nc" + "?" + "sessionid=" + this.authToken;
};

//RA005: Export Result in XLSX
//caseId : id of the simulation case to run
Everest_CLS.prototype.getUrl_Export_Result_XLSX = function(caseId, callbackSuccess, callbackError, contype) {

    return this.url + "sim/cases/" + caseId + "/results/xlsx.zip" + "?" + "sessionid=" + this.authToken;
};

/*****************************
    Output Management API
******************************/
//OM001: Checkout Results File
Everest_CLS.prototype.Checkout_Results_File = function(caseId, callbackSuccess) {
    this.post("outputs/" + caseId + "/checkout", "Checkout_Results_File : " + caseId, {}, callbackSuccess, null);
};

//OM002: Checkin Results File
Everest_CLS.prototype.Checkin_Results_File = function(caseId) {
    this.post("outputs/" + caseId + "/checkin", "Checkin_Results_File : " + caseId, {}, this.logData, null);
};


/*****************************
 Viewpoint API
******************************/
//VA001: List Camera Viewpoints
Everest_CLS.prototype.List_Camera_Viewpoints = function(modelId, callbackSuccess, callbackError) {
    this.get("viewpoints/" + modelId, "viewpoints MOSA : " + modelId, callbackSuccess, callbackError);
};

//VA002: Save Camera Viewpoint
Everest_CLS.prototype.Save_Camera_Viewpoints = function(modelId, Name, input, callbackSuccess) {
    this.set("viewpoints/" + modelId + "/" + Name, "Save viewpoints: " + Name + "   MOSA : " + modelId, input, callbackSuccess, null);
};

//VA003: Read Camera Viewpoint
Everest_CLS.prototype.Read_Camera_Viewpoints = function(params, callbackSuccess) {
    var mosaId = params.mosaID;
    var name = params.name;
    this.get("viewpoints/" + mosaId + "/" + name, "Read viewpoints MOSA: " + name + "   " + mosaId, callbackSuccess, null);
};

//VA004: Delete Camera Viewpoint
Everest_CLS.prototype.Delete_Camera_Viewpoints = function(modelId, Name, callbackSuccess) {
    this.delete_fct("viewpoints/" + modelId + "/" + Name, "Delete viewpoints: " + Name + "  MOSA   : " + modelId, callbackSuccess, null);
};


/***************************
     Data Exchange API
  (Import / Export xlsx)
****************************/
//DX001: Export Data To XLSX
/*
Input
    URL Parameters :
        collId : the id of the data collection
    Query Parameters:
        inherit : adding inherit=false to the URL causes the service to export only values that have been modified in the collection itself, not those inherited from parents.
        compact : adding compact=true to the url causes only columns and sheets that contain values are exported. Empty sheets and data columns are automatically removed.
        attributes : adding a list of attribtes in attributes=<attr1>,<attr2>,<attr3>... causes only attributes of this name to be exported. If this partameter is not set in the url, all attributes can be exported.
        pids : when pids=<pid1>,<pid2>,<pid3> is added to the url, only data of the given pids is exported.




Data collections include :

    A simulation case to exchange simulation case’s initiatives parameters.
    A scenario to exchange scenario parameters.
    A system model to exchange model’s general tab info.
    A strategy to exchange strategy’s initiatives parameters.
    A target to exchange target KPI values for the given target.

The collection id the given object’s numeric identifier.


Output => The raw output is the xlsx file.
*/
Everest_CLS.prototype.Export_Data_To_XLSX = function(param, collId, filter, callbackSuccess, callbackError) {
    this.get("dx/datacollection/" + collId + (filter == undefined ? "" : filter), param, callbackSuccess, callbackError);
};

Everest_CLS.prototype.getUrl_Export_Data_To_XLSX = function(collId, filter) {
    // url have to be like : dx/datacollection/SimcaseID?pids=id1,id2,id3&attributes=attrib1,attrib2,attrib3&sessionid=token
    return this.url + "dx/datacollection/" + collId + (filter ? filter + "&sessionid=" : "?sessionid=") + this.authToken;
};

/**
    Fct used to download to user side a file : Excel or a NetCDF
    collId and filter : for all export Excel in initiatives, context and targets tab

    Special parameters :
        is_dlXLSX : boolean->  is true if we call fct for downloading Excel file, else is set to false (download NetCDF)
        input : name : string stores simulation name (export Excel and netCDF )
                type: set type of export : 'netCDF' or 'Excel'
*/
Everest_CLS.prototype.dl_Export_Data_To_XLSX = function(collId, filter, is_dlXLSX, input) {
    // url have to be like : dx/datacollection/SimcaseID?pids=id1,id2,id3&attributes=attrib1,attrib2,attrib3&sessionid=token
    var fileURL;
    var fileName;
    if (is_dlXLSX == true) {
        fileURL = this.getUrl_Export_Data_To_XLSX(collId, filter);
        fileName = collId + ".xlsx";
    } else {
        if (input["type"] == "netCDF") {
            fileName = input["name"] + "-" + input["id"] + ".nc";
            fileURL = this.getUrl_Export_Result_NetCDF(input["id"]);
        } else {
            fileName = input["name"] + "-" + input["id"] + ".zip";
            fileURL = this.getUrl_Export_Result_XLSX(input["id"]);
        }
    }
    return fileURL;


    // for non-IE
    // if (!window.ActiveXObject) {
    //     var save = document.createElement('a');
    //     save.href = fileURL;
    //     save.target = '_blank';
    //     save.download = fileName;
    //
    //     var event = document.createEvent('Event');
    //     event.initEvent('click', true, true);
    //     save.dispatchEvent(event);
    //     (window.URL || window.webkitURL).revokeObjectURL(save.href);
    // }
    //
    //     // for IE
    // else if (!!window.ActiveXObject && document.execCommand) {
    //     var _window = window.open(fileURL, '_blank');
    //     _window.document.close();
    //     _window.document.execCommand('SaveAs', true, fileName || fileURL)
    //     _window.close();
    // }
};

//DX002: Import Data From XLSX
/*Input =>  An XLSX file is the body of the request.
            An optional query parameter : clear, when the URL is called with clear=true (like xxx/dx/datacollection/<collId>?clear=true)
            previous content of the collection is cleared before the new data is loaded.

Output => Nothing*/
Everest_CLS.prototype.Import_Data_From_XLSX = function(param, collId, filter, contentType, data, callbackSuccess, callbackError) {
    this.UploadFile("dx/datacollection/" + collId + (filter == undefined ? "" : filter), param, contentType, data, callbackSuccess, callbackError);
};

//DX003: Enable DXImport
Everest_CLS.prototype.Enable_DXImport = function(param, callbackSuccess, callbackError) {
    this.post("dx/enable", param, {}, callbackSuccess, callbackError);
};

//DX004: Disable DXImport
Everest_CLS.prototype.Disable_DXImport = function(param, callbackSuccess, callbackError) {
    this.post("dx/disable", param, {}, callbackSuccess, callbackError);
};


/*****************************
        Gestion interne
      Apres Refresh Token
******************************/
//A executer apres un refesh du token
Everest_CLS.prototype.RerunErrors = function() {
    for (var i in this.tabError) {
        if (this.tabError[i].mode == "GET") {
            this.get(this.tabError[i].urlWS, this.tabError[i].id, this.tabError[i].callbackFctSuccess, this.tabError[i].callbackFctError);
        } else if (this.tabError[i].mode == "POST") {
            this.post(this.tabError[i].urlWS, this.tabError[i].id, this.tabError[i].input, this.tabError[i].callbackFctSuccess, this.tabError[i].callbackFctError);
        } else if (this.tabError[i].mode == "PUT") {
            this.set(this.tabError[i].urlWS, this.tabError[i].id, this.tabError[i].input, this.tabError[i].callbackFctSuccess, this.tabError[i].callbackFctError);
        } else if (this.tabError[i].mode == "DELETE") {
            this.delete_fct(this.tabError[i].urlWS, this.tabError[i].id, this.tabError[i].callbackFctSuccess, this.tabError[i].callbackFctError);
        }
    }
    this.tabError = [];
};


/*****************************
        Gestion interne
      Apres Refresh Token
******************************/
//Get
Everest_CLS.prototype.get = function(urlWS, id, callbackFctSuccess, callbackFctError, contentType) {

    //url of the webservice called for request
    let url = ~urlWS.indexOf("http") ? urlWS : this.url + urlWS;

    // if urlWS contains 'log', contentType = "text/html"
    if (~urlWS.substring(urlWS.indexOf(".") + 1, urlWS.length).indexOf("log")) {
        // contentType = "text/html";
    } else {
        contentType = "application/json";
    }

    let token = this.authToken;


    return $.ajax({
        //html method used for this request
        type: "GET",
        //kind of content expected as response
        contentType: contentType,

        //put user Key in request header
        beforeSend: function(request) {
            request.setRequestHeader("X-sessionId", token);
        },

        //function called if success
        //data is the response expected
        success: function(data, status) {
            if ($.isFunction(callbackFctSuccess)) {
                callbackFctSuccess(data, status, id);
            }
        },
        error: $.proxy(function(jqXHR, textStatus, errorThrown) {
            if (jqXHR.responseText && jqXHR.responseText.indexOf("ANN") > -1 && $.isFunction(callbackFctSuccess)) {
                callbackFctSuccess(jqXHR.responseText, status, id);
            }
            if ($.isFunction(callbackFctError)) {
                callbackFctError(jqXHR, textStatus, errorThrown);

            }
            if ($.isFunction(this.callbackErrorTokenTimeout) && (jqXHR.status === 401 || (jqXHR.responseJSON && jqXHR.responseJSON.errorMessage && jqXHR.responseJSON.errorMessage.indexOf("Token is not valid") > -1))) {
                //TODO Améliorer pour ne pas lancer 25 fois la même requête (evolution du % etc...)
                this.tabError.push({
                    mode: 'GET',
                    urlWS: urlWS,
                    id: id,
                    callbackFctSuccess: callbackFctSuccess,
                    callbackFctError: callbackFctError,
                    input: null
                });
                this.callbackErrorTokenTimeout(jqXHR, textStatus, errorThrown);

            }
        }, this),
        url: url
    });
};

Everest_CLS.prototype.getNextUrl = function(fullurl, param, callbackFctSuccess, callbackFctError) {
    // // add domain to nextUrl in case that it's accessed via non eifer internal network
    // let addDomainToHost = (url) => {
    //     let domain = "eifer.kit.edu";
    //     if (url.includes(domain))
    //         return url;
    //
    //     let matches = url.match(/eif-\S+-01/g);
    //     let host = matches && matches[0] ? matches[0] : "";
    //
    //     return host ? url.replace(host, `${host}.${domain}`) : url;
    // };
    // fullurl = addDomainToHost(fullurl);

    //this.get(fullurl.replace(this.url, ""), param, callbackFctSuccess, callbackFctError);
    return this.get(fullurl, param, callbackFctSuccess, callbackFctError);
};

//PUT
Everest_CLS.prototype.set = function(urlWS, id, input, callbackFctSuccess, callbackFctError) {

    //ajax request
    var token = this.authToken;
    return $.ajax({
        //html method used for this request
        type: 'put',
        context: this,

        //put user Key in request header
        beforeSend: function(request) {
            request.setRequestHeader("X-sessionId", token);
        },

        //function called if success
        //data is the response expected
        success: function(data, status) {
            if ($.isFunction(callbackFctSuccess)) {
                callbackFctSuccess(data, status, id);
            }
        },
        error: $.proxy(function(jqXHR, textStatus, errorThrown) {
            if ($.isFunction(callbackFctError)) {
                callbackFctError(jqXHR, textStatus, errorThrown);
            }
            if ($.isFunction(this.callbackErrorTokenTimeout) && jqXHR.status === 401) {
                this.tabError.push({
                    mode: 'PUT',
                    urlWS: urlWS,
                    id: id,
                    input: input,
                    callbackFctSuccess: callbackFctSuccess,
                    callbackFctError: callbackFctError
                });
                this.callbackErrorTokenTimeout(jqXHR, textStatus, errorThrown);
            }

        }, this),
        //url of the webservice called for request
        url: this.url + urlWS,
        //data send to the webservice
        data: JSON.stringify(input),
        //kind of content expected as response
        contentType: "application/json;"
    });
}

Everest_CLS.prototype.UploadFile = function(urlWS, param, contentType, data, callbackFctSuccess, callbackFctError) {

    //ajax request
    var token = this.authToken;
    $.ajax({
        //html method used for this request
        type: 'put',
        context: this,
        contentType: contentType,
        //contentType: contentType,

        //put user Key in request header
        beforeSend: function(request) {
            request.setRequestHeader("X-sessionId", token);
        },

        //function called if success
        //data is the response expected
        success: function(data, status) {
            callbackFctSuccess(data, status, param);
        },
        error: $.proxy(function(jqXHR, textStatus, errorThrown) {
            if (callbackFctError != undefined) {
                callbackFctError(jqXHR, textStatus, errorThrown);

            }
            if (this.callbackErrorTokenTimeout != undefined && jqXHR.status == 401) {
                this.tabError.push({
                    mode: 'PUT',
                    urlWS: urlWS,
                    id: param,
                    input: input,
                    callbackFctSuccess: callbackFctSuccess,
                    callbackFctError: callbackFctError
                });
                this.callbackErrorTokenTimeout(jqXHR, textStatus, errorThrown);
            }


        }, this),
        //url of the webservice called for request
        url: this.url + urlWS,

        //data send to the webservice
        data: data,
        processData: false // Don't process the files
    });
}

//Post
Everest_CLS.prototype.post = function(urlWS, id, input, callbackFctSuccess, callbackFctError, contentType) {
    if (!contentType) {
        contentType = "application/json";
    }
    //ajax request
    let token = this.authToken;

    return $.ajax({
        //html method used for this request
        type: "post",
        //put user Key in request header
        beforeSend: function(request) {
            request.setRequestHeader("X-sessionId", token);
        },
        //function called if success
        //data is the response expected
        success: function(data, status) {
            if ($.isFunction(callbackFctSuccess)) {
                callbackFctSuccess(data, status, id);
            }
        },
        error: $.proxy(function(jqXHR, textStatus, errorThrown) {
            if ($.isFunction(callbackFctError)) {
                callbackFctError(jqXHR, textStatus, errorThrown);

            }
            if ($.isFunction(this.callbackErrorTokenTimeout) && jqXHR.status === 401) {
                //TODO Améliorer pour ne pas lancer 25 fois la même requête (evolution du % etc...)
                this.tabError.push({
                    mode: "POST",
                    urlWS: urlWS,
                    input: input,
                    id: id,
                    callbackFctSuccess: callbackFctSuccess,
                    callbackFctError: callbackFctError
                });
                this.callbackErrorTokenTimeout(jqXHR, textStatus, errorThrown);
            }

        }, this),
        //url of the webservice called for request
        url: this.url + urlWS,
        //data send to the webservice
        data: JSON.stringify(input),
        //kind of content expected as response
        contentType: contentType
    });
};

//Delete
Everest_CLS.prototype.delete_fct = function(urlWS, id, callbackFctSuccess, callbackFctError) {
    //ajax request
    let token = this.authToken;

    return $.ajax({
        //html method used for this request
        type: 'DELETE',
        //put user Key in request header
        beforeSend: function(request) {
            request.setRequestHeader("X-sessionId", token);
        },
        //function called if success
        //data is the response expected
        success: function(data, status) {
            if ($.isFunction(callbackFctSuccess)) {
                callbackFctSuccess(data, status, id);
            }
        },
        error: $.proxy(function(jqXHR, textStatus, errorThrown) {
            if ($.isFunction(callbackFctError)) {
                callbackFctError(jqXHR, textStatus, errorThrown);

            }
            if ($.isFunction(this.callbackErrorTokenTimeout) && jqXHR.status === 401) {
                //TODO Améliorer pour ne pas lancer 25 fois la même requête (evolution du % etc...)
                this.tabError.push({
                    mode: 'DELETE',
                    urlWS: urlWS,
                    id: id,
                    callbackFctSuccess: callbackFctSuccess,
                    callbackFctError: callbackFctError,
                    input: null
                });
                this.callbackErrorTokenTimeout(jqXHR, textStatus, errorThrown);
            }

        }, this),
        //url of the webservice called for request
        url: this.url + urlWS,
        //kind of content expected as response
        contentType: "application/json"
    });
};


/***************************
          OpenDap
***************************/
Everest_CLS.prototype.getOpenDap = function(id, filter, params, callbackFctSuccess, callbackFctError, isLoadDataset) {


    if (filter != "") {
        var token = this.authToken;
        var url = this.urlOpenDap + (id != undefined && id != null ? id : this.selectedCaseId) + '/dataset.nc';

        try {
            if (isLoadDataset != undefined && isLoadDataset != null && isLoadDataset == true) {
                loadDataset(url + (filter != undefined && filter != null ? filter : ''), callbackFctSuccess, callbackFctError, {
                    'X-Sessionid': token
                }, params);
            } else {
                url = url + (filter != undefined && filter != null ? filter : '');
                loadData(url, (data,params) => {
                    //FIX timescales from  everest metadata when available (due to encoding problem of opendap server)
                    //This is normally already cached.
                     if(this.Results["List_Models_Info"])  {
                        let tsIndex=_.keyBy(this.Results["List_Models_Info"].timescales, "code");
                        for(var key in data) {
                            if(!/^TIMESCALE-.*/.test(key)) continue;
                            let code=key.substr(10);
                            if(code in tsIndex) {
                                data[key].data=tsIndex[code].steps;
                            }
                        }
                     }
                    callbackFctSuccess(data, params);
                  }, callbackFctError, {
                    'X-Sessionid': token
                }, params);
            }
        } catch (err) {
            if (callbackFctError != undefined) {
                callbackFctError(err, new ModalErrorMessage('Result Error', err));
                //if (this.callbackErrorTokenTimeout != undefined && ((jqXHR.status == 401) || (jqXHR.responseJSON != undefined && jqXHR.responseJSON.errorMessage != undefined && jqXHR.responseJSON.errorMessage.indexOf("Token is not valid") > -1))) {
                //    //TODO Améliorer pour ne pas lancer 25 fois la même requête (evolution du % etc...)
                //    this.tabError.push({ mode:'GET',urlWS: url, id: 'Result Error ' + err, callbackFctSuccess: callbackFctSuccess, callbackFctError: callbackFctError, input:null });
                //    this.callbackErrorTokenTimeout(jqXHR, textStatus, errorThrown);

                if (this.callbackErrorTokenTimeout != undefined && jqXHR.status == 401) {
                    //TODO Améliorer pour ne pas lancer 25 fois la même requête (evolution du % etc...)
                    this.tabError.push({
                        mode: 'GET',
                        urlWS: url,
                        id: 'Result Error ' + err,
                        callbackFctSuccess: callbackFctSuccess,
                        callbackFctError: callbackFctError,
                        input: null
                    });
                    this.callbackErrorTokenTimeout(jqXHR, textStatus, errorThrown);
                }
                //}
            } else {
                var er = new ModalErrorMessage('Result Error', err);

            }
        }
    }
};
Everest_CLS.prototype.GetOpenDapMetadata = function(id, params, callbackFctSuccess, callbackFctError) {
    this.getOpenDap(id, null, params, callbackFctSuccess, (callbackFctError!=undefined?callbackFctError:null), true);
};
/***
TEST OpenDap
*/
/*
Everest_CLS.prototype.TestApiOpenDap = function (id) {
    //var token = this.authToken;
    //var timeScale = "TIMESCALE-Yearly";
    //var pidKind = "PID-Building";
    ////var bottomBar.selectedOutput[0] = "/Results/Building/Building_AnAggThermalProduction";
    //var bottomBar = "/Results/Building/Building_AnAggThermalProduction";
    //var url = 'https://curtis-nf.edf-labs.net/data/dods/results/CASE-' + (id != undefined && id != null ? id : this.selectedCaseId) + '/dataset.nc';
    //loadData(url + '.dods?' + pidKind + ',' + timeScale + ',' + bottomBar, //url
    //            onResultLoaded, //callback
    //            { 'X-Sessionid': token },"Building_AnAggThermalProduction" + pidKind + timeScale); //extraheaders

    //var token = this.authToken;
    var timeScale = "TIMESCALE-Yearly";
    var pidKind = "PID-Building";
    //var bottomBar.selectedOutput[0] = "/Results/Building/Building_AnAggThermalProduction";
    var bottomBar = "/Results/Building/Building_AnAggThermalProduction";
    var filter = '.dods?' + pidKind + ',' + timeScale + ',' + bottomBar;
    this.getOpenDap(id, filter, ["Building_AnAggThermalProduction", pidKind, timeScale, id], onResultLoaded, null);
};*/


//TODO : Faire un mise en force du retour TestApiOpenDapMetadata pour que l'utilisateur sélectionne le "bottomBar" actuellement mit en dur dans le script TestApiOpenDap
//A quoi sert attribute à la racine du retour ? fournit les url à aller taper dans bottombar ?
Everest_CLS.prototype.TestApiOpenDapMetadata = function(id) {
    this.getOpenDap(id, null, null, function(data, params) {
        return true;
    }, null, true);
};

Everest_CLS.prototype.TestViewpoint = function() {

    var pos1 = {
        "targetx": 358862,
        "targety": 147739,
        "targetz": 13,
        "rotation1": 40,
        "rotation2": -24,
        "length": 1000
    };
    this.Save_Camera_Viewpoints("Start", pos1);
};

Everest_CLS.prototype.TestViewpointdel = function() {
    this.delete_fct_Camera_Viewpoints("Building_1");

};

/**
 * Search users by full name, study area and group. (UP005)
 *
 * @param fullname <String> optional
 * @param studyarea <String> optional
 * @param group <String> group code
 * @return {Promise<unknown>}
 */
Everest_CLS.prototype.searchUsers = function(fullname, studyarea, group) {
    const query = toQueryString({ fullname, studyarea, group });
    return this.recurse(`users?${query}`, query);
};

/**
 * Search groups by name and study area (UP006)
 *
 * @param name <String> group code
 * @param studyarea <String> optional
 * @return {Promise<unknown>}
 */
Everest_CLS.prototype.searchGroups = function(name, studyarea) {
    const query = toQueryString({ name, studyarea });
    return this.recurse(`groups?${query}`, query);
};

// recursive get hoc
Everest_CLS.prototype.recurse = function(url, query) {
    return promisify(this.get(url)).then(data => {
        // if only first page available, return first page's data
        if (!data["nextUrl"]) {
            return data;
        }

        // recursively get data from next url
        let _wrapper = (nextUrl) => {
            // propagate original query to next url
            let _nextUrl = query ? `${nextUrl}&${query}` : nextUrl;

            return promisify(this.getNextUrl(_nextUrl))
                .then(nextData => {
                    // merge data from first page and data from next url
                    data["items"] = data["items"].concat(nextData["items"]);
                    data["nextUrl"] = nextData["nextUrl"];

                    if (nextData["nextUrl"]) {
                        return _wrapper(nextData["nextUrl"]);
                    } else {
                        // resolve final page's data
                        return data;
                    }
                });
        };

        return _wrapper(data["nextUrl"]);
    });
};

export default Everest_CLS;
