import Attributs_CLS from './attributs.js'
/**
    In SU mode :
    Create a box to edit a Target selected
*/

var SU_Edit_Target_CLS = function(Everest, UI) {
    this.UI = UI;
    this.Everest = Everest;
    this.tabTarget = [];
    this.data_Models_Info = null; //Info au niveau du mosa (liste des categories d'initiatives + timescales possibles)
    this.UO_Metadata = []; //Listes des metadata des attributs pour un typeName
    this.Urban_Objects_Attributes = null; //Liste des valeurs sauvegardées pour un couple "pid" (UrbanObject) / "targetID" (target)
    this.treeUO = $.fn.zTree.getZTreeObj("tree_URB");

    this.nodesC = null;
    this.UOSelected = undefined;
    this.contentFileJSON = this.UI.accessContentJSON;
    this.contentMessageFileJSON = this.UI.accessMessageContentJSON;
    this.statusPublish = undefined;


    this.currentNodeTarget = null; //On stoque les info de la target, car le sim id de everest peut changer et ne plus être bon.
    //Construit la box de gestion des Target
    //Rajoute la box
    this.createboxSU_Edit_Tgt(function() {});

    //Ajout des events
    $("#Edit_Target_Reset").click(function() {
        this.Reset_modif();
    }.bind(this));
    $("#Edit_Target_save").click(function() {
        this.Sauve_modif();
    }.bind(this));
    $("#Edit_Target_finish").click(function() {
        $("#box_Edit_Target").hide();
    }.bind(this));
    $("#Edit_Target_export").click(function() {
        $(".load_wait_fctExportSU").show();
        document.getElementById("Edit_Target_export").disabled = true;
        var filtre = "";
        var nodes = this.treeUO.getCheckedNodes(true);
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i)) {
                filtre += (filtre == "" ? "?pids=" : ",") + nodes[i].id;
            }
        }

        var fileName = this.currentNodeTarget.id + ".xlsx";
        var xlsxLink = this.Everest.dl_Export_Data_To_XLSX(this.currentNodeTarget.id, (filtre == "" ? undefined : filtre), true, {});
        this.UI.createDowloadAlertLink(xlsxLink, fileName);

        setTimeout(function() {
            document.getElementById("Edit_Target_export").disabled = false;
            $(".load_wait_fctExportSU").hide();
        }, 7000);

    }.bind(this));
    $("#Edit_Target_import").click(function() {
        //  document.getElementById("Edit_Target_import").disabled = true;
        //  $(".load_wait_fctImportSU").show();
        var inputFile = document.getElementById('Edit_Target_import_input');
        inputFile.click();
    }.bind(this));
    $("#Edit_Target_import_input").change(function() {
        var inputFile = document.getElementById('Edit_Target_import_input');
        var val = inputFile.value;
        if (inputFile.files != undefined && inputFile.files[0] != undefined) {
            var reader = new FileReader();
            reader.onloadend = function() {
                var self = this;
                self.Everest.Import_Data_From_XLSX(self.currentNodeTarget.name, self.currentNodeTarget.id, "", inputFile.files[0].type, reader.result, function(d, s, p) {
                    self.UI.createAlert("notif", "success", self.contentMessageFileJSON.MESSAGE_ALERT_SUCCESS);
                }, function(d, s, p) {
                    self.Everest_callbackError(d, s);

                });
                var control = $("#Edit_Target_import_input");
                control.replaceWith(control = control.clone(true));
            }.bind(this);
            reader.readAsArrayBuffer(inputFile.files[0]);
            //   document.getElementById("gestion_Initiatives_import").disabled = false;
            // $(".load_wait_fctImportSU").hide();
        }
    }.bind(this));



    this.init = function() {
        this.tabTarget = [];
        this.Everest.List_Models_Info(this.initTree.bind(this), this.Everest_callbackError.bind(this));
    }


    this.initTree = function(data, status, id) {
        this.data_Models_Info = data;
        var params = {
            mode: "Tree",
            uid: "trgt",
            idParent: "items_edit_Target",
            Fct_save: this.Sauve_Edit_Target.bind(this)
        };
        var par = {
            code: "Edit_Target_it"
        };
        this.tabTarget[par.code] = new Attributs_CLS(par, data.timescales, params, this.UI);
        $("#Edit_Target_kpi_filter").keyup(function() {
            var value = $.trim($("#Edit_Target_kpi_filter").val()).toLowerCase();
            for (var i in this.tabTarget) {
                if (this.tabTarget[i].Filter != undefined) {
                    this.tabTarget[i].Filter(value);
                }
            }

        }.bind(this));
    }


    /*
        Reset value of the target
    */
    this.Reset = function() {
        for (var i in this.tabTarget) {
            if (this.tabTarget[i].Reset != undefined) {
                this.tabTarget[i].Reset();
            }
        }
        this.Urban_Objects_Attributes = null;

    }


    this.ResetFromUO = function() {
        this.Reset();
        this.Everest.selectedTypeName = null;
        this.Everest.selectedPid = null;
        $("#Target_noUO").show();
        $("#items_edit_Target").hide();
    }

    /*
        When select a UO, load the content to editing
    */
    this.LoadFromUO = function(uo, treeNode) {

        if (this.Everest.selectedPid != treeNode.id) {
            this.Reset();
            this.Everest.selectedTypeName = null;
            this.Everest.selectedPid = null;
            if (uo.ListModelZoom != undefined) {
                var typeName = uo.ListModelZoom[treeNode.level].name;
                // $('txt_menu_droite').wrapInner("Appliqué sur : txt");
                //$('#txt_menu_droite').text("Selected : " + typeName);
                this.Everest.selectedTypeName = typeName;
                this.Everest.selectedPid = treeNode.id;
                this.Load();
                $("#Target_noUO").hide();
            }
        }
    }


    /*
        Called by LoadFromUO()
        Launches Everest req determinig if this level of building has KPI to editing
    */
    this.Load = function(nodeTarget) {

        $("#items_edit_Target").hide();
        if (nodeTarget != undefined && nodeTarget != null) {
            this.currentNodeTarget = nodeTarget; //Bien passer le node.id à tous les appels everest
            $("#Edit_Target_title_span").text(nodeTarget.name);
            $("#box_Edit_Target").show();
            //Mets * sur les items modifiés
            $(".UOModified").hide();
            for (var idItem in this.Everest.UOModified.Target) {
                if (this.Everest.UOModified.Target.hasOwnProperty(idItem)) {
                    // $(".diyModified_" + idItem).show();
                }
            }
        }
        $("#Target_noAttrib").hide();
        //Si un uo est selectionné, on lance l'init, sinon on affiche le "You have to select one or more UO"
        var selectedNodes = this.treeUO.getCheckedNodes(true);
        if (selectedNodes.length > 0) {
            $("#Target_noUO").hide();
            $("#overlay_Edit_Target").show();
            var params = {
                nodeTarget: this.currentNodeTarget,
                nodeUo: selectedNodes[0]
            }
            this.UOSelected = params.nodeTarget;
            //(param, targetId, pid, callbackSuccess,...)
            this.Everest.Read_Target_KPIValues(params, params.nodeTarget.id, params.nodeUo.id, this.Read_Target_KPIValues_Callback.bind(this), this.Everest_callbackError.bind(this));
        } else {
            $("#Target_noUO").show();
        }
    }

    //Recup les valeurs par defaut pour le couple pid/Targetid
    this.Read_Target_KPIValues_Callback = function(data, status, obj) {
        this.Urban_Objects_Attributes = data;
        //Si le typename à changé, on requetes les nouveaux params.
        if (this.UO_Metadata[this.Everest.selectedTypeName] == undefined) {
            this.Everest.Read_KPIMetadata(this.Everest.selectedTypeName, this.Everest.selectedTypeName, this.Read_KPIMetadata_Callback.bind(this), this.Everest_callbackError.bind(this));
        } else {
            //On a tout, on lance le traitement
            this.BuildInterface(this.UO_Metadata[this.Everest.selectedTypeName]);
        }
    }

    //Recup les valeurs pour un typeName
    this.Read_KPIMetadata_Callback = function(data, status, typeName) {
        this.UO_Metadata[typeName] = data;
        //this.Object_Type_Name = typeName;
        //this.Object_Type_Metadata = data;
        //On a tout, on lance le traitement
        this.BuildInterface(this.UO_Metadata[typeName]);

    }



    this.BuildInterface = function(Object_Type_Metadata) {
        //Pour rappel, on doit construire une interface à partir de 3 elements :
        //  - data_Models_Info :  contient les info générique (notamment les timescales) => Requeter une fois au départ
        //  - Object_Type_Metadata (ou this.UO_Metadata[this.Everest.selectedTypeName]) : contient le type de données pour chaque élément=> Requeter une fois par typename (Building / city / ...)
        //  - Urban_Objects_Attributes => contient les valeurs pour chaque timescale correspondant.
        var isEmpty = true;

        this.UI.classBindData.resetValueKPI_tgt_SU = true;
        this.Everest.Read_Target_Info(this.UOSelected, this.UOSelected.id, function(d) {
            this.statusPublish = d.published;
            //Construit l'arbre
            var zNodes = [];
            var nTmp = [];
            /*var nodes = [
	        {id:1, pId:0, name: "pNode 01"},
	        {id:11, pId:1, name: "child 01"},
	        {id:12, pId:1, name: "child 02"}
            ];
            */
            var pStr;
            var obj;
            var indice = -1;
            this.tabTarget["Edit_Target_it"].Reset();
            for (var i in Object_Type_Metadata) {
                pStr = this.GetMetadata(Object_Type_Metadata[i]["metadatas"], "Initiative");
                if (pStr != undefined) {
                    if (nTmp[pStr] == undefined) {
                        obj = {
                            id: pStr,
                            pId: 0,
                            name: pStr,
                            open: false,
                            isParent: true
                        };
                        zNodes.push(obj);
                        nTmp[pStr] = obj.id;
                    }
                    obj = {
                        id: i,
                        pId: nTmp[pStr],
                        name: Object_Type_Metadata[i]["displayName"],
                        isParent: false,
                        obj: Object_Type_Metadata[i]
                    };
                    zNodes.push(obj);
                    isEmpty = false;
                }
            }



            /*
            for (var key in Object_Type_Metadata) {
                //if (this.Urban_Objects_Attributes[key] != undefined) {//Target=> on prend tout
                    var item = Object_Type_Metadata[key];
                    if (this.Is_Allowed(item.metadatas) == true) {
                        this.tabTarget["Edit_Target_it"].AddItem(key, item, (this.Urban_Objects_Attributes[key] != undefined?this.Urban_Objects_Attributes[key]:""));
                        isEmpty = false;
                    }
                //}
            }*/
            if (isEmpty) {
                //Afficher "Aucun rez"...
                $("#Target_noAttrib").show();
                $("#items_edit_Target").hide();
            } else {
                this.tabTarget["Edit_Target_it"].AddItem(Object_Type_Metadata, zNodes, this.Urban_Objects_Attributes, d.published, false);
                //Init des valeurs
                for (var i in this.tabTarget) {
                    if (this.tabTarget[i].InitAllValues != undefined) {
                        this.tabTarget[i].InitAllValues(0);
                    }
                }
                $("#Target_noAttrib").hide();
                $("#items_edit_Target").show();
            }
            $("#overlay_Edit_Target").hide();


        }.bind(this), this.Everest_callbackError.bind(this));


        this.UI.classBindData.resetValueKPI_tgt_SU = false;

    }


    //Convertit les metadata en tableau associatif. Plus simple d'acces
    this.GetMetadata = function(tab, meta) {
        for (var i in tab) {
            if (tab.hasOwnProperty(i) && tab[i].name == meta) {
                return tab[i].value;
            }
        }
        return "";
    }



    //retourne True si le compte à les droits suffisant pour afficher le champ
    this.Is_Allowed = function(metadatas) {
        if (this.Everest.SuperUser == true)
            return true;

        var resu = false;
        for (var i = 0; i < metadatas.length; i++) {
            if (metadatas[i].name == "SuperUser") {
                if (metadatas[i].value == "no") {
                    return true;
                }
            }
        }
        return resu;
    }

    /*
        Reset all values of Target
        Called when user click on Reset Button
    */
    this.Reset_modif = function() {
        /*for (var i in this.tabTarget) {
            if (this.tabTarget.hasOwnProperty(i)) {
                if (this.tabTarget[i].Reset != undefined) {
                    this.tabTarget[i].Reset();
                }
            }
        }*/

        if (this.statusPublish != undefined) {
            document.getElementById("Edit_Target_Reset").disabled = true;
            $(".load_wait_fctResetContextSU").show();
            if (this.statusPublish != true) {

                for (var i in this.tabTarget) {
                    if (this.tabTarget[i].InitAllValues != undefined) {
                        this.tabTarget[i].InitAllValues();
                        this.UI.createAlert("notif", "success", this.contentMessageFileJSON.MESSAGE_ALERT_RESET);
                    }
                }

            } else {
                for (var i in this.tabTarget) {
                    if (this.tabTarget[i].InitAllValues != undefined) {
                        this.tabTarget[i].InitAllValues();
                    }
                }
            }
            this.Urban_Objects_Attributes = null;

            this.Everest.selectedTypeName = null;
            this.Everest.selectedPid = null;
            $("#ini_noUO").show();
            // document.getElementById("Edit_Target_Reset").disabled = false;
            $(".load_wait_fctResetContextSU").hide();

        }
    }


    /*
        Fct call when the user click on Save Button
    */
    this.Sauve_modif = function() {
        if (this.statusPublish != undefined) {
            if (this.statusPublish != true) {
                $(".load_wait_fctSauveContextSU").show();
                document.getElementById("Edit_Target_save").disabled = true;
                for (var i in this.tabTarget) {
                    if (this.tabTarget.hasOwnProperty(i)) {
                        if (this.tabTarget[i].SaveAllValues != undefined) {
                            this.tabTarget[i].SaveAllValues();
                            this.UI.createAlert("notif", "success", this.contentMessageFileJSON.MESSAGE_ALERT_SAVE);
                        }
                    }
                }
                $(".load_wait_fctSauveContextSU").hide();
            } else {
                this.Reset_modif();
                this.UI.createAlert("alert", "error", this.contentMessageFileJSON.MESSAGE_ALERT_NOUPDATE_PUBLISH);
            }
        }
    }


    /*
        Apply modif edit on all selected UO automatically
    */
    this.Sauve_Edit_Target = function(object) {
        //Boucle sur tous les items cochés plutot qu'un update simple.
        this.nodesC = this.treeUO.getCheckedNodes(true);
        for (var i = 0; i < this.nodesC.length; i++) {

            if (i + 1 == this.nodesC.length) {
                this.Everest.Update_Target_KPIValues(this.nodesC[i].id, this.currentNodeTarget.id, this.nodesC[i].id, object, this.Sauve_Target_Last_callbacksucces.bind(this), this.Sauve_Target_callbackError.bind(this));
            } else {
                this.Everest.Update_Target_KPIValues(this.nodesC[i].id, this.currentNodeTarget.id, this.nodesC[i].id, object, this.Sauve_Target_callbacksucces.bind(this), this.Sauve_Target_callbackError.bind(this));
            }
        }
    }

    this.Sauve_Target_callbacksucces = function(data, status, id) {
        this.Everest.UOModified.Target[id] = id;
        //commenté pour pas que * apparaissent ici
        // $(".diyModified_" + id).show();
    }


    /*
        Called if the save succeeded and the save had applied on all selected UO
        And recall Sauve_Target_callbacksucces
    */
    this.Sauve_Target_Last_callbacksucces = function(data, status) {
        this.Sauve_Target_callbacksucces(data, status, this.nodesC[this.nodesC.length - 1].id);

        this.UI.createAlert("alert", "success", this.contentFileJSON.MENU_LABEL_MAJ_DATA);
    }


    /*
        Called if the save req failed
    */
    this.Sauve_Target_callbackError = function(data, status) {

        this.UI.createAlert("alert", "error", data.responseJSON.errorMessage);

    }

    /*
        Called if Everest req failed
    */
    this.Everest_callbackError = function(data, status) {

        $("#overlay_Edit_Target").hide();
        this.treeUO.checkAllNodes(false);

        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");
    }
}

SU_Edit_Target_CLS.prototype.constructor = SU_Edit_Target_CLS;

SU_Edit_Target_CLS.prototype.createboxSU_Edit_Tgt = function(CallBackClose) {

    $("#box_Edit_Target").remove();
    var c = "<div id=\"Edit_Target_content\">";
    c += "      <div style=\"position:absolute; top:0px; left:0px; bottom:0px; width:260px; overflow : auto;\">";
    c += "          <div id=\"uo_Edit_Target\" style=\"width:100%;\"></div>"; //Arbre avec les urban Objects
    c += "      </div>";
    c += "      <div class=\"use-skin-border\" style=\"position:absolute; top:0px; left:260px; bottom:0px; right:0px; overflow:auto;\">";
    c += "          <div style=\"text-align:left; height:65px;\">";
    c += "              <div class=\"pull-left\" >";
    c += "                  <table><tr><td><h3 style=\"padding-bottom: 9px;\">" + this.contentFileJSON.MENU_LEFT_SUPER_USER_EDIT_TARGET_EDIT_NAME + "</h3></td><td>&nbsp;&nbsp;&nbsp;<input type=\"text\" id=\"Edit_Target_kpi_filter\" /></td></tr></table>";
    c += "              </div>";
    c += "              <div class=\"pull-right\" style=\"margin-top:10px\" >";
    c += "                  <button  class=\"btn use-skin classBtnClick\" style=\"color:#ffffff\" id=\"Edit_Target_import\"><i class=\"fa fa-upload\"></i></button>";
    /*<div class="load_wait_fctImportSU" style="display:none;"><i class="fa fa-refresh fa-spin"></i></div>*/
    c += "                    <input type=\"file\" id=\"Edit_Target_import_input\" style=\"display:none;\"/>";
    c += "                  <button  class=\"btn use-skin classBtnClick\" style=\"color:#ffffff\" id=\"Edit_Target_export\"><i class=\"fa fa-download\"></i></button>  <div class=\"load_wait_fctExportSU\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div>";
    c += "              </div>";
    c += "          </div>";
    c += "          <div id=\"Target_noUO\" class=\"callout use-skin-border\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
    c += "              <h4>" + this.contentFileJSON.MENU_TOP_CITY_URBAN + "</h4>";
    c += "              <p>" + this.contentFileJSON.MENU_TOP_CITY_URBAN_MESSAGE + "</p>";
    c += "          </div>";
    c += "          <div id=\"Target_noAttrib\" class=\"callout use-skin-border\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
    c += "              <h4>" + this.contentFileJSON.MENU_LABEL_TARGET + "</h4>";
    c += "              <p>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_EDIT_TARGET_EDIT_MESSAGE + "</p>";
    c += "          </div>";
    c += "          <div id=\"items_edit_Target\" style=\"display:none\">";
    c += "          </div>";
    c += "          <div class=\"overlay\" style=\"display:none\">";
    c += "              <i class=\"fa fa-refresh fa-spin\"></i>";
    c += "          </div>";
    c += "      </div>";
    c += "  </div>";

    var f = "<div class=\"pull-right\" ><button class=\"btn use-skin classBtnReset\" style=\"color:#ffffff\" id=\"Edit_Target_Reset\" disabled>" + this.contentFileJSON.MENU_BUTTON_RESET + "</button> <div class=\"load_wait_fctResetContextSU\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div>  <button class=\"btn use-skin classBtnSave\" style=\"color:#ffffff;\" id=\"Edit_Target_save\" disabled>" + this.contentFileJSON.MENU_BUTTON_SAVE + "</button> <div class=\"load_wait_fctSauveContextSU\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div> <button class=\"btn use-skin\" style=\"color:#ffffff\" id=\"Edit_Target_finish\">" + this.contentFileJSON.MENU_BUTTON_FINISH + "</button></div>";

    this.UI.CreateBox("Edit_Target", {
        draggable: true,
        group: "SuperUser",
        resizable: {
            minHeight: 500,
            minWidth: 800 /*, handles: 'e, s, w'*/
        }, //...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
        useSkin: true,
        title: this.contentFileJSON.MENU_LABEL_TAR_ATT + "<span id=\"Edit_Target_title_span\"></span>",
        content: c,
        footer: f,
        addOverlay: true,
        visible: false,
        menuBt: {
            reduceTo: "div_ManageTarget", //ID vers qui on doit réduire la div
            fullscreen: {
                css: {
                    position: "absolute",
                    top: "125px",
                    width: "860px",
                    height: "500px",
                    left: "6%",
                    right: "",
                    bottom: ""
                }
            } //position / taille de div pour quand on sort du fullscreen
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "125px",
            left: "6%",
            width: "860px",
            height: "500px",
            "min-width": "800px",
            "min-height": "500px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};
export default SU_Edit_Target_CLS;
