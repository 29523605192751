"use strict";

import Cavi2Util from "../helpers/cavi.util.js";

// Market Value Events (namespace: MV)
export const MV_CELL_KPI_FETCH_PENDING = "MV_CELL_KPI_FETCH_PENDING";
export const MV_CELL_KPI_FETCH_SUCCEED = "MV_CELL_KPI_FETCH_SUCCEED";
export const MV_CELL_KPI_FETCH_FAIL = "MV_CELL_KPI_FETCH_FAIL";
export const MV_CELL_KPI_FETCH_ABORT = "MV_CELL_KPI_FETCH_ABORT";

/**
 * usage:
 *  - fire an event:
 *    dispatcher.dispatch('example.event.name', arg1, arg2...)
 *
 *  - subscribe an event:
 *    dispatcher.subscribe('example.event.name');  //subscribe an event without listeners
 *    dispatcher.subscribe('example.event.name', function(arg1, arg2...){});
 *    dispatcher.subscribe('example.event.name', function1(arg1, arg2...){}, function2(arg1, arg2...){}..);
 *    dispatcher.subscribe('example.event.name', [function1(arg1, arg2...){}, function2(arg1, arg2...){}..]);
 *
 *  - unsubscribe an event:
 *    dispatcher.unsubscribe('example.event.name');
 *
 *  - register event listeners for an event:
 *    dispatcher.addEventListeners('example.event.name', function(arg1, arg2...){});
 *    dispatcher.addEventListeners('example.event.name', [function1(arg1, arg2...){}, function2(arg1, arg2...){}..]);
 *
 *  - list event listeners for an event
 *    dispatcher.listEventListeners('example.event.name');
 */
function EventDispatcher() {
    this.eventList = {};

    var self = this;

    this.subscribe = function (eventName) {
        if (self.eventList[eventName] === undefined) {
            self.eventList[eventName] = [];
        }

        var eventListeners = Array.prototype.slice.call(arguments);
        eventListeners.shift();

        this.addEventListeners(eventName, eventListeners);
    };

    this.unsubscribe = function (eventName) {
        if (self.eventList.hasOwnProperty(eventName)) {
            delete self.eventList[eventName];
        }
    };

    this.dispatch = function (eventName) {
        var args = Array.prototype.slice.call(arguments);
        args.shift();
        var eventList = self.eventList[eventName];
        if (eventList !== undefined) {
            for (var i in eventList) {
                eventList[i].apply(this, args);
            }
        }
    };

    /**
     * Register multiple event listeners for a specific event
     * @param eventName
     * @param eventListeners an array of event listeners or a single one
     */
    this.addEventListeners = function (eventName, eventListeners) {
        // if event not found, do nothing
        if (!self.eventList.hasOwnProperty(eventName)) {
            return;
        }

        if (Array.isArray(eventListeners)) {
            for (var i = 0; i < eventListeners.length; i++) {
                this.addEventListeners(eventName, eventListeners[i]);
            }
        } else {
            // duplicate event listeners are disallowed
            if (!Cavi2Util.isInArray(eventListeners, self.eventList[eventName])) {
                self.eventList[eventName].push(eventListeners);
            }
        }
    };

    /**
     * list all event listeners of a specific event, return empty array when event name not exist
     * @param eventName
     * @returns {*}
     */
    this.listEventListeners = function (eventName) {
        if (self.eventList.hasOwnProperty(eventName)) {
            return self.eventList[eventName];
        }
        return [];
    };
}

export default EventDispatcher;
