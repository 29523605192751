import LanguageSelectorModel from "../components/language.selector.js";
import InfoModalModel from "../components/info.modal.js";
import Carousel from "../components/carousel.js";
import koCustomBindings from "../bindings/ko.bindings.index.js";
import i18nextMixin from "./ko.i18n.mixin.js";
import BundleSharing from "../components/bundle.sharing.js";
import MarketValue from "../components/market.value.js";
import TemplateLangSelector from "../templates/lang-selector.html";
import TemplateCarousel from "../templates/carousel.html";
import TemplateContourSelection from "../templates/contour-selection.html";
import TemplateInfoModal from "../templates/info-modal.html";
import TemplateLayerSelection from "../templates/layers-selection.html";
import TemplateBundleSharing from "../templates/bundle-sharing.html";
import TemplateMarketValue from "../templates/market-value.html";

const { hasOwnProperty } = Object.prototype;

class KnockoutRegister {
    registerComponents(GestionMOSA) {
        // register ko components
        ko.components.register("language-selector", {
            // viewModel: LanguageSelectorModel,
            viewModel: {
                createViewModel: () => new LanguageSelectorModel(i18nextMixin.options),
            },
            // template: { path: "src/templates/lang-selector.html" },
            template: TemplateLangSelector,
        });

        ko.components.register("info-modal", {
            // viewModel: InfoModalModel,
            viewModel: InfoModalModel,
            // template: { path: "src/templates/info-modal.html" },
            template: TemplateInfoModal,
        });

        ko.components.register("carousel", {
            viewModel: { instance: Carousel },
            // template: { path: "src/templates/carousel.html" },
            template: TemplateCarousel,
        });

        ko.components.register("layers-selection", {
            viewModel: { instance: GestionMOSA.AnalysingResult.layersSelectionModel },
            // template: { path: "src/templates/layers-selection.html" },
            template: TemplateLayerSelection,
        });

        ko.components.register("contour-selection", {
            viewModel: { instance: GestionMOSA.tw3d.contourSelectionModel },
            // template: { path: "src/templates/contour-selection.html" },
            template: TemplateContourSelection,
        });

        ko.components.register("bundle-sharing", {
            viewModel: { instance: BundleSharing },
            // template: { path: "src/templates/bundle-sharing.html" },
            template: TemplateBundleSharing,
        });

        ko.components.register("market-value", {
            viewModel: { instance: MarketValue },
            // template: { path: "src/templates/market-value.html" },
            template: TemplateMarketValue,
        });
    }

    registerCustomBindings() {
        // register custom ko binding handlers
        Object.keys(koCustomBindings).forEach(binding => {
            if (!hasOwnProperty.call(ko.bindingHandlers, binding)) {
                ko.bindingHandlers[binding] = koCustomBindings[binding];
            } else {
                console.warn(`Binding ${binding} is already exists`);
            }
        });
    }
}

// export as singleton
const koRegister = new KnockoutRegister();

export default koRegister;
