import Attributs_CLS from "../attributs.js";
import AnalysingResultsUI from "../view/analysing_results_ui.js";

var NETCDF_EMPTY = 9.969209968386869e+36;
var MAX_FLOAT = 9.969209968386869e+36;

/*
 Class to manage the Results->KPI Selection tab
 It is used to compare different simulations with previously defined KPIs
 /!\ The comparison can be set on charts or on tables /!\
 */

class Analysing_Result_CLS {
  constructor(Everest, UI, TW3D, GestionUrbObj) {

        this.GestionUrbObj = GestionUrbObj;
        this.analysingResultsUI = new AnalysingResultsUI(this, UI);
        this.Everest = Everest;
        this.tw3d = TW3D;

        this.currentKpiData = null;
        this.currentSimId = null;


        this.cpt = 0;
        this.cptRes = 0;
        this.cptMultiSimu = 0;
        this.cptReportImg = 0; // count nb of turn to fills results on report
        this.tabNameKPITitle = [];
        this.tabNbBuilding = 0;
        this.treeNodeSave = null;
        this.boolTest = false;
        this.tabNameKPI = []; //stores  LABEL of KPI name
        this.tabNameSimu = []; //stores name of selected simulations
        this.tabNameId = []; // stores selected simulations ID
        this.tabAssocSimuId_name = []; //stores key-value pairs for simulations (id and name)
        this.tabContext = [];
        this.data_Models_Info = null; //Info at mosa level (initiatives category list + all timescales)
        this.UO_Metadata = []; // list of metadata containing in attributs result request for a specific typename
        this.Urban_Objects_Attributes = null; //list of values saved for a "pid" (UrbanObject) / "caseId" (simulation) pair L

        this.Sauv_Param = undefined;
        this.Sauv_data = undefined;

        //stores all displayname of KPI
        this.save_MetadataLabel = [];

        //copy all grpahs of a chart
        //used for the show/hide all on charts in Results box
        this.save_graphsLine = [];

        // string setting choice of report type : PDF, Word
        this.reportChosen = "";

        // string setting choice of export type : NetCDF, Excel
        this.exportChosen = "";

        //stores all selected simu (nodes) + description of the simu
        // used in report
        this.listSimuSelectedReport = [];


        // nb total of charts
        this.nbGraphs = 0;

        // table stores min and max value on axis
        // uses to set a common range on axis for a same KPI
        this.objMinMaxAxis = [];

        //this object stores pairs simID and technical ID (used in create report)
        this.objToCreateTableReport = [];

        //boolean set true when resultagraph has finished :used in report
        this.resultIsFinished = false;

        this.currentNodeContext;
        this.currentLstScenario;

        this.errorKpi = false;

        // this will be overwrite later
        this.layersSelectionModel = {};
    }

    setErrorKpi(val) {
        this.errorKpi = val;
    }

    getErrorKpi() {
        return this.errorKpi;
    }

  getCheckedNodesTree() {
    return this.analysingResultsUI.getCheckedNodesTree();
  }
    /*
     Init the interface
     create the box, define functions called when click on btns
     */
    init(GestionSimulations) {
        this.analysingResultsUI.init();
        GestionSimulations.addEventTree("treeSimAnaResult", this.updateSimCaseTree.bind(this));

        this.tabContext = [];
        this.Everest.List_Models_Info(this.initTree.bind(this), this.Everest_callbackError.bind(this));

    }

    /**
     Fct which add or remove nodes on the simcase ztree on interfaces : ranking, results, targeting
     This is NOT a shared tree, cause events are different for each of these interfaces
     This fct is c.alled when user add, remove, run a simcase
     -> stored in tabEvent and called in runComplete() : interface_gestion_simu.js
     */
    updateSimCaseTree(node, nodeParent, boolIsDelete) {
        this.analysingResultsUI.updateSimCaseTree(node, nodeParent, boolIsDelete);
    }

    getIndexSimuRun(id) {
        return this.Everest.infosSimuRun.findIndex(function(x) {
            return x.idSim == id;
        });
    }

    getInfoSimuRun(index) {
        return this.Everest.infosSimuRun[index];
    }

    /*onCheck*/
    createKpiList(treeNode) {
      // creates the kpi list according to the UO level selected (building or neighbor)
      this.treeNodeSave = treeNode;
      this.Everest.GetOpenDapMetadata(treeNode.id, treeNode.id, this.SetKpi.bind(this), this.setKpiError.bind(this));
      //this.Everest.getOpenDap(treeNode.id, ".dods?PID-Neighborhood", this.tmpTest.bind(this), this.tmpTestError.bind(this));//"PID-Neighborhood" "PID-Precinct" "PID-Town"
    }

  tmpTest(data) {
    console.log("tmpTest");
    console.log(data);
  }

  tmpTestError(data, status) {
    console.log("tmpTestError");
    console.log(data);
  }
    setKpiError(data, status) {
        this.SetKpi(null, null);
        this.errorKpi = true;
    }

    initTree(data, status, id) {
        this.data_Models_Info = data;
        var params = {
            mode: "Edit",
            uid: "ana_results",
            isReadOnly: true,
            idParent: "kpi_analysing_Result"
        };
        var par = {
            code: "kpi_analysing_it"
        };
        this.tabContext[par.code] = new Attributs_CLS(par, null, params, this.analysingResultsUI.getUI());
    }

    /*
     reset the
     */
    Reset() {
        for (var i in this.tabContext) {
            if (this.tabContext[i].Reset != undefined) {
                this.tabContext[i].Reset();
            }
        }
        this.Urban_Objects_Attributes = null;

    }


    /*
     When user select another level of UO, it reset the content of KPIs and some shared vars stored in Everest class
     **/
    ResetFromUO() {
        this.Reset();
        this.Everest.selectedTypeName = null;
        this.Everest.selectedPid = null;
    }

    /**
     Call if a Scenario or a UrbanObject is checked
     **/
  updateCheck() {
    this.GestionUrbObj.update_tree_others();
        this.errorKpi = false;
        this.analysingResultsUI.resetMessage();
        var nodes = this.analysingResultsUI.getCheckedNodesTree();
      if (nodes.length > 0) {
          var nodesUO = this.GestionUrbObj.getCheckedNodesUO();
            if (nodesUO.length > 0) {
                var noRes = true;
                for (var i in nodes) {
                    if (nodes.hasOwnProperty(i)) {
                        if (nodes[i].isParent != true) {
                            this.analysingResultsUI.setVisibilityOverlay(true);
                            this.createKpiList(nodes[i]);
                            this.analysingResultsUI.UI.allreadycharge_AnaRes = true;
                            noRes = false;
                        }
                    }
                }
                if (noRes == true) {
                    //Show message check at least one UO + one Scenario
                    this.analysingResultsUI.messageBadSelect();
                }
            } else {
                //Show message check at least one UO + one Scenario
                this.analysingResultsUI.messageBadSelect();
            }
        } else {
            //Show message check at least one UO + one Scenario
            this.analysingResultsUI.messageBadSelect();
        }
    }

    /**
     Call the Load() fct which launches the fct returning list of KPIs for a typename (UO level)
     */
    LoadFromUO(uo, treeNode) {
        if (this.Everest.selectedPid != treeNode.id) {
            this.Reset();
            //uo.tree.checkNode(treeNode, true, true, true);
            if (uo.ListModelZoom != undefined) {

                alert("This code seems dead, but if not : please use typeNode.UOtype ald ListModelZoom");
                var typeName = uo.ListModelZoom[treeNode.level].name;
                this.Everest.selectedTypeName = typeName;

                this.Everest.selectedPid = treeNode.id;
                this.Load();
            }
        }
    }


    /*

     */
    Load(nodeContext) {
        if (nodeContext != undefined && nodeContext != null) {
            this.currentNodeContext = nodeContext; //Bien passer le node.id à tous les appels everest
            this.analysingResultsUI.markUOModified(this.Everest.UOModified.Context);
        }
        this.analysingResultsUI.load();
    }

    readScenarioAttributeValues(selectedNodes) {
        var params = {
            nodeContext: this.currentNodeContext,
            nodeUo: selectedNodes[0]
        };
        //(param, scenarioId, pid, callbackSuccess,...)
        this.Everest.Read_Scenario_Attribute_Values(params, params.nodeContext.id, params.nodeUo.id, this.Read_Scenario_Attribute_Values_Callback.bind(this), this.Everest_callbackError.bind(this));
    }


    //Get default values for a key-value pair pid/contextid
    Read_Scenario_Attribute_Values_Callback(data, status, obj) {
        this.Urban_Objects_Attributes = data;
        //if the typename has changed, launches a request to get new params
        if (this.UO_Metadata[this.Everest.selectedTypeName] == undefined) {
            this.Everest.Read_Urban_Object_Type_Metadata(this.Everest.selectedTypeName, this.Everest.selectedTypeName, this.Read_Urban_Object_Type_Metadata_Callback.bind(this), this.Everest_callbackError.bind(this));
        } else {
            //if we have all elements, launches the build
            this.BuildInterface(this.UO_Metadata[this.Everest.selectedTypeName]);
        }
    }

    //get values of a specific typename
    Read_Urban_Object_Type_Metadata_Callback(data, status, typeName) {

        this.UO_Metadata[typeName] = data;
        //if we have all elements, launches the build
        this.Everest.Read_KPIMetadata(typeName, typeName, this.Read_Urban_Object_KPI_Metadata_Callback.bind(this), this.Everest_callbackError.bind(this));
        this.BuildInterface(this.UO_Metadata[typeName]);

    }

    Read_Urban_Object_KPI_Metadata_Callback(data) {
        //empty  ? why ?
    }

    /*
     Init and build the interfac part for KPIs
     */
    BuildInterface(Object_Type_Metadata) {
        //We build an interface thanks to 3 elements :
        //  - data_Models_Info :  contains generic infos (timescale..) => request at the launch
        //  - Object_Type_Metadata (or this.UO_Metadata[this.Everest.selectedTypeName]) : contains data type for each elements => request one time for each typename (Building / city / ...)
        //  - Urban_Objects_Attributes => contains values for each timescale

        var isEmpty = true;
        this.tabContext["kpi_analysing_it"].Reset();
        for (var key in Object_Type_Metadata) {
            if (this.Urban_Objects_Attributes[key] != undefined) {
                var item = Object_Type_Metadata[key];
                if (item.initiative == undefined) {//&& this.Is_Allowed(item.metadatas) == true) {//IGOTMA 3 Is_Allowed is undefined
                    this.tabContext["kpi_analysing_it"].AddItem(key, item, this.Urban_Objects_Attributes[key], null, false);
                    isEmpty = false;
                }
            }
        }
        if (isEmpty) {
            //Show "No results" div...
            this.analysingResultsUI.setVisibilityResult(false);
        } else {
            //Init values
            for (var i in this.tabContext) {
                if (this.tabContext[i].InitValues != undefined) {
                    this.tabContext[i].InitValues();
                }
            }
            this.analysingResultsUI.setVisibilityResult(true);
        }
        this.analysingResultsUI.setVisibilityOverlay(false);
    }


    /*deprecated ? Still used ?*/
    addResult(node, nodeParent) {
        this.analysingResultsUI.addResult(node, nodeParent);
    }


    // Same actions than the SetKpi() function in interface_gestion_urbanobj.js
    // This function is to update the kpi list if user select a UO and THEN a simu
    SetKpi(data) {

        if (this.errorKpi == false) {
            //igo TMA 2
            //this.currentKpiData = data;
            //if (data != null) {
            this.currentKpiData = data;//stores data  later.
            //}
            this.save_MetadataLabel = [];
            this.analysingResultsUI.setKpi(data);
        } else {
            this.currentKpiData = null;//stores data  later.
            this.save_MetadataLabel = [];
            this.analysingResultsUI.setKpi(null);
        }
    }

    setSaveMetadataLabel(val) {
        this.save_MetadataLabel = val;
    }

    getSaveMetadataLabel() {
        return this.save_MetadataLabel;
    }

    getCurrentKpiData() {
        return this.currentKpiData;
    }

    /*
     Main function:
     - creates empty charts
     - calls onResultLoaded() on callbacksuccess of the getopendata() request
     */
    ResultGraph(hideRes) {
        this.resultIsFinished = false;
        this.save_graphsLine = [];
        this.analysingResultsUI.setVisibilityWaitFctRes(true);
        //igo TMA 2
        /*
        if (this.UI.classBindData.currentKpiData != undefined) {
            this.currentKpiData = this.UI.classBindData.currentKpiData;
        }
        if (this.UI.classBindData.treeKpi != undefined) {
            this.treeKpi = this.UI.classBindData.treeKpi;
        }*/


        this.objToCreateTableReport = [];
        this.objMinMaxAxis = [];

        this.tabNameKPI = [];
        this.tabNameSimu = [];
        this.tabNameId = [];
        this.tabAssocSimuId_name = [];

        this.tabNameKPITitle = [];
        this.tabNbBuilding = 0;
        this.cpt = 0;
        this.cptRes = 0;
        this.cptMultiSimu = 0;
        this.cptReportImg = 0;
        this.nbGraphs = 0;
        this.boolTest = false;
        var tabUO = [];
        var hasNode = false;

        this.analysingResultsUI.emptyChart();
      var nodes = this.GestionUrbObj.getCheckedNodesUO();
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i)) {
                tabUO[nodes[i].id] = nodes[i].name;
                this.tabNbBuilding++;
                hasNode = true;
            }
        }
        if (this.analysingResultsUI.resultGraphCheck(hasNode) == false) {
            return -1;
        }
      var hasNodes = false;
        var nodes = this.analysingResultsUI.getCheckedNodesKpi();
        var filtre = "";
        var params = []; //[it[it.length - 1], pidKind, timeScale, nodes[i].id];
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i) && nodes[i].isParent == false) {
                var it = nodes[i].id.split("/");
                it = it[it.length - 1];
                if (this.currentKpiData[it] != undefined) {
                    hasNodes = true;
                    this.tabNameKPI.push(it); // this.tabNameKPI.push(nodes[i].id);
                    this.tabNameKPITitle.push(nodes[i].name);
                    filtre += (filtre == "" ? "" : ",") + this.currentKpiData[it].array.dimensions[1] + "," + this.currentKpiData[it].array.dimensions[0] + "," + nodes[i].id;

                    params.push([it, this.currentKpiData[it].array.dimensions[0], this.currentKpiData[it].array.dimensions[1], nodes[i].id]);
                }
            } else {
                this.analysingResultsUI.setVisibilityWaitFctRes(false);
            }
        }

        //FIXME Disable this for PRODEC
      // if (hasNodes == false) {
      //   this.analysingResultsUI.resultGraphNoKpi();
      //   return -1;
      // }
        // return if selected urban objects are more than allowed
        if(this.analysingResultsUI.isSelectedUOMoreThanAllowed()){
            return -1;
        }

      //
        if (this.analysingResultsUI.resultGraphTableAndDiv(hideRes) == -1) {
            return -1;
        }


        var all = "";

        for (var j = 0; j < this.tabNameKPI.length; j++) {
            const attributes = this.currentKpiData.attributes["/Results/" + this.Everest.selectedTypeName + "/" + this.tabNameKPI[j]];
            if (!attributes) {
                continue;
            }
            var unit = this.analysingResultsUI.fixOpenDapStrEncoding(attributes["UNIT"]);
            for (var i = 0; i < this.tabNameSimu.length; i++) {
                this.analysingResultsUI.addResultGraph(i, j,
                    this.tabNameId,
                    this.tabNameKPI,
                    this.tabNameSimu,
                    unit,
                    this.tabNameKPITitle,
                    all
                );
            }
        }

        //Update the context field
      this.updateContext(this.tabNameId);


        var backFilter = filtre;
        for (var i = 0; i < this.tabNameSimu.length; i++) {
            filtre = backFilter;
            for (var j = 0; j < this.tabNameKPI.length; j++) {
                var filter = ".dods?";
                if (this.tabNameKPI.length > 1) {
                    var test = filtre.split(",", 3).toString();

                    filtre = filtre.replace(test, "");
                    filtre = filtre.substring(1, filtre.length);
                    filter += test;
                } else {
                    filter += filtre;
                }


                this.Everest.getOpenDap(this.tabNameId[i], filter, {
                    id: this.tabNameId[i],
                    tab: params
                }, this.onResultLoaded.bind(this), this.Everest_callbackError.bind(this));

                this.analysingResultsUI.setVisibilityWaitFctRes(false);
                //  this.posDot++;

            }
        }

        // dispatch event "onKpiDataLoaded"
        if (window.eventDispatcher) {
            // reformed a variable that is passed
            var selectedSimCases = {};
            for (var i = 0; i < this.tabNameId.length; i++) {
                selectedSimCases[this.tabNameId[i]] = { name: this.tabNameSimu[i] };
            }
            window.eventDispatcher.dispatch("onKpiDataLoaded", selectedSimCases);
        }

        // hide all the tab links except 'chart'
        // $("li[id*=link2tabPopupR]:not(.active)").each(function() {
        //     $(this).hide();
        // });
    }

    addSimu(name, id) {
        this.tabNameSimu.push(name);
        this.tabNameId.push(id);
        this.tabAssocSimuId_name.push({
            "id": id,
            "name": name
        });
        this.cptMultiSimu++;
    }

  updateContext(lstSimulation) {
    //First we grab all Scenario only once
    let promise = this.Everest.getAllScenarios();
    promise.then((bundleInfo) => {
      this.currentLstScenario = {};
      if (bundleInfo != undefined && bundleInfo.items != undefined) {
        //we reorder the return to have an associative object
        for (var i in bundleInfo.items) {
          if (bundleInfo.items.hasOwnProperty(i)) {
            this.currentLstScenario[bundleInfo.items[i].id] = bundleInfo.items[i].name;
          }
        }
        //console.log("================");
        //console.log(this.currentLstScenario);
        //For each simulation checked we go to search the id of the scenario used
        for (var i in lstSimulation) {
          if (lstSimulation.hasOwnProperty(i)) {
            let node = { id: lstSimulation[i] };
            this.Everest.Read_Simulation_Case_Info(node, (data, params) => {
              //console.log("++++++++++++");
              //console.log(params);
              //console.log(data);
              if (data != undefined && data.scenarioId != undefined && this.currentLstScenario != undefined && this.currentLstScenario[data.scenarioId] != undefined) {

                this.analysingResultsUI.setScenarioInformation(node.id,this.currentLstScenario[data.scenarioId]);
                //console.log(data.scenarioId + " => " + this.currentLstScenario[data.scenarioId]);
              }
              //console.log("++++++++++++");

            }, this.Everest_callbackError.bind(this));
            //console.log(lstSimulation[i]);
          }
        }
        //console.log("================");
      }
    }).catch(error => this.Everest_callbackError(error));

  }



  updateNbGraphs() {
    this.nbGraphs = this.tabNameKPITitle.length * this.cptMultiSimu;
  }


    /**

     Fct loops on content chart graphs and show all elements of the graphs
     Check a radio btn call this fct
     /!\ : showGraph() is like a removeGraph
     we save content graphs chart in save_graphsLine table
     */
    showAllGraphs(obj) {
        this.analysingResultsUI.showAllGraphs(obj, this.save_graphsLine);
    }

    /**

     Fct loops on content chart graphs and hide all elements of the graphs
     Check a radio btn call this fct
     /!\ : hideGraph() is like a removeGraph
     we save content graphs chart in save_graphsLine table
     */
    hideAllGraphs(obj) {
        this.analysingResultsUI.hideAllGraphs(obj, this.save_graphsLine);
    }

    /**
     * Show GIS Results Layers
     *
     * @constructor
     */
    GISResult() {

        // filter & simplify the selected simulation cases
        let selectedSimCases = this.analysingResultsUI
            .getCheckedNodesTree()
            .filter(node => !node.isParent)
            .map(node => ({ caseId: node.id, caseName: node.name }));

        let message = undefined;

        // notify users how to trigger GIS Results
        if (!selectedSimCases.length) {
            message = this.analysingResultsUI.contentMessageFileJSON.MESSAGE_ALERT_RESULTS_LAYERS_SELECT_SIMU;
            this.analysingResultsUI.UI.createAlert("alert", "warning", message);
            return;
        }

        // notify users that no results layers available in this MOSA
        if (!this.tw3d.resultsLayers.length) {
            message = this.analysingResultsUI.contentMessageFileJSON.MESSAGE_ALERT_RESULTS_LAYERS_NO_RESULTS_LAYERS;
            this.analysingResultsUI.UI.createAlert("alert", "warning", message);

            // dispatch event "onResultsLayerLoaded", only works for PRODEC OFFSHORE
            if (window.eventDispatcher) {
                window.eventDispatcher.dispatch("onResultsLayerLoaded", selectedSimCases);
            }

            return;
        }

        // notify users that no base map available in this MOSA
        if (!this.is3dLoaded()) {
            message = this.analysingResultsUI.contentMessageFileJSON.MESSAGE_ALERT_RESULTS_LAYERS_NO_BASE_MAP;
            this.analysingResultsUI.UI.createAlert("alert", "warning", message);
            return;
        }

        // now show & trigger the results layer selections
        this.layersSelectionModel.show();

        this.layersSelectionModel.run(selectedSimCases, this.tw3d.resultsLayers, this.tw3d);

    }

    /**
     Fct called when click on 'coloring' btn
     check if all necessaries data are ok before call the getOpenDap() request.
     On its callback success build the interface of coloring, and enable the highlight coloration in the 3D
     */
    ColorResult() {
        var List_node = [];
        this.tabNameKPI = [];
        this.tabNameSimu = [];
        this.tabNameId = [];
        this.tabAssocSimuId_name = [];
        var tabUO = [];

        var Cpt_Simu = 0;
        var Cpt_KPI = 0;
        var Cpt_UO = 0;

        //igo TMA 2
        /*
        if (this.UI.classBindData.currentKpiData != undefined) {
            this.currentKpiData = this.UI.classBindData.currentKpiData;
        }
        if (this.UI.classBindData.treeKpi != undefined) {
            this.treeKpi = this.UI.classBindData.treeKpi;
        }*/

      var nodes = this.GestionUrbObj.getCheckedNodesUO();
      var UOType = "";
        for (var i in nodes) {
          if (nodes.hasOwnProperty(i)) {
            if (nodes[i].UOtype != undefined) {
              UOType = nodes[i].UOtype;
            }
            tabUO[nodes[i].id] = nodes[i].name;
            Cpt_UO++;
          }
      }
      if (UOType != "" && this.GestionUrbObj.isEnableColoring(UOType)==false) {
        this.analysingResultsUI.createAlert("MESSAGE_ALERT_ENABLE_COLORING");
        return -1;
      }
      if (Cpt_UO == 0) {
        this.analysingResultsUI.createAlert("MESSAGE_ALERT_CHECK");
        return -1;
      }

        nodes = this.analysingResultsUI.getCheckedNodesTree();


        for (var i in nodes) {
            if (nodes.hasOwnProperty(i)) {
                if (nodes[i].isParent == false) {
                    this.tabNameSimu.push(nodes[i].name);
                    this.tabNameId.push(nodes[i].id);
                    Cpt_Simu++;
                }
            }
        }

        if (Cpt_Simu > 1) {
            this.analysingResultsUI.createAlert("MESSAGE_ALERT_COLOR_SIMUCOUNT");
            return -1;
        }
        if (this.analysingResultsUI.getTreeKpi() == undefined) {
            this.analysingResultsUI.createAlert("MESSAGE_ALERT_SELECT_DATA");
            return -1;
        }
        nodes = this.analysingResultsUI.getCheckedNodesKpi();
        if (nodes.length == 0) {
            this.analysingResultsUI.createAlert("MESSAGE_ALERT_SELECT_DATA");
            return -1;
        }
        var filtre = "";
        var params = [];

        for (var i in nodes) {
            if (nodes.hasOwnProperty(i) && nodes[i].isParent == false) {
                var it = nodes[i].id.split("/");
                it = it[it.length - 1];
                if (this.currentKpiData[it] != undefined) {
                    this.tabNameKPI.push(it); // this.tabNameKPI.push(nodes[i].id);
                    //Mode 1 seul kpi, la requete multi marche pas.
                    filtre += (filtre == "" ? "" : ",") + this.currentKpiData[it].array.dimensions[1] + "," + this.currentKpiData[it].array.dimensions[0] + "," + nodes[i].id;

                    params.push([it, this.currentKpiData[it].array.dimensions[0], this.currentKpiData[it].array.dimensions[1], nodes[i].id]);
                    Cpt_KPI++;
                }
            }
        }

        if (Cpt_KPI > 1) {
            this.analysingResultsUI.createAlert("MESSAGE_ALERT_COLOR_KPICOUNT");
            return -1;
        }

        this.Everest.getOpenDap(this.tabNameId[0], ".dods?" + filtre, {
            id: this.tabNameId[0],
            tab: params
        }, this.onResultLoadedColor.bind(this));
    }


    /*
     Creates the box coloring (color slider + KPI name )
     The coloring is only available for 1 sim + 1 UO + 1 KPI
     */
    onResultLoadedColor(data, params) {
        var nodes = this.analysingResultsUI.getCheckedNodesKpi();
        var nameKPI = nodes[1].name;
        var unity = this.analysingResultsUI.fixOpenDapStrEncoding(this.currentKpiData.attributes[nodes[1].id]["UNIT"]);
        var nameKP12 = nodes[1].id.toString().split("/")[nodes[1].id.toString().split("/").length - 1];
        var timescale = data[nameKP12][nodes[1].id].dimensions[0];
        var timeStep = data[timescale].data;

        this.Sauv_Param = params;
        this.Sauv_data = data;

        this.analysingResultsUI.boxAnalysingResultColoring(nameKPI, unity, params, data);

    }

    /*
     Fct called when user close the coloring popup
     Removes color on building selected
     */
    CallBackClose() {
        this.tw3d.UnSelectAllUO();
    }

    /*
     Fct called when user change value on coloring slider
     Updates color on building selected
     */
    Slider_OnChange(data) {

        function toColorRanges(areaColor) {
            let colorRanges = {
                minColor: "127/255/255", // blue =>  (127, 255, 255)
                maxColor: "247/32/32" // red => (247, 32, 32)
            };

            // if areaColor is not defined, return default
            if (!areaColor) {
                return colorRanges;
            }

            // e.g. => "123/43/35;243/122/133" => ["123/43/35", "243/122/133"]
            let colors = areaColor.split(";").map(elem => elem.trim().replace(/^\/+|\/+$/g, ""));

            // if areaColor not follow "123/43/35;243/122/133", return default
            if (colors.length !== 2) {
                return colorRanges;
            }

            // check if each color in the colorRange follow "r/g/b"
            let flag = colors.every(elem => {
                let arr = elem.split("/");
                // check each r,g,b in [0, 255]
                return arr.length === 3 && arr.every(item => parseInt(item) >= 0 && parseInt(item) <= 255);
            });

            // if true, set the area color
            if (flag) {
                colorRanges.minColor = colors[0];
                colorRanges.maxColor = colors[1];
            }

            return colorRanges;
        }

        var Selected_year = data.from_value;
        var Kpi_link = this.Sauv_Param.tab[0][3];
        var Area_Color = this.currentKpiData.attributes[Kpi_link]["META-AreaColor"]; //TODO: Finir les Bind
        // var tab_Split = Area_Color.split(";");
        var colorRanges = toColorRanges(Area_Color);

        var Coul1 = colorRanges["minColor"].split("/");
        var Coul2 = colorRanges["maxColor"].split("/");


        for (var i = 0; i < 3; i++) {
            Coul1[i] = parseInt(Coul1[i]);
            Coul2[i] = parseInt(Coul2[i]);
        }


        var params = this.Sauv_Param;
        var data = this.Sauv_data;

        var dataType = params.tab[0][0];
        var timeScale = params.tab[0][1];
        var pidKind = params.tab[0][2];
        var simId = params.id;
        var tabUO = [];
      var nodes = this.GestionUrbObj.getCheckedNodesUO();
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i)) {
                tabUO[nodes[i].id] = nodes[i].id;
            }
        }


        var resu = [];
        var Val_Max = -MAX_FLOAT;
        var Val_Min = MAX_FLOAT;
        for (var x = 0; x < data[timeScale]["data"].length; x++) {

            for (var y = 0; y < data[pidKind]["data"].length; y++) {

                var id = data[pidKind]["data"][y].toString();
                if (tabUO[id] != undefined) { //Filtre pour ne garder que les cochés.
                    if (data[dataType] != undefined && data[dataType]["data"] != undefined && data[dataType]["data"][0][x][y] != undefined && data[dataType]["data"][0][x][y] != NETCDF_EMPTY) {
                        var val = data[dataType]["data"][0][x][y];
                        if (val > Val_Max) {
                            Val_Max = val;
                        }
                        if (val < Val_Min) {
                            Val_Min = val;
                        }
                        if (data[timeScale]["data"][x] == Selected_year.toString()) {
                            resu.push({
                                "pid": tabUO[id],
                                "val": val,
                                "color": {
                                    "r": 255,
                                    "v": 255,
                                    "b": 255
                                }
                            }); //TODO traiter quand plus de 2 dimensions.
                        }
                    }
                }
            }
        }


        if (resu.length == 0) { //IF there is no results show in box
            this.analysingResultsUI.sliderOnChange(null, null, Coul1, Coul2);
            return;
        }

        if (this.currentKpiData.attributes[Kpi_link]["META-Decimal"] != undefined) {
            let nbRound = this.currentKpiData.attributes[Kpi_link]["META-Decimal"];
            Val_Min = Val_Min.toFixed(nbRound);
            Val_Max = Val_Max.toFixed(nbRound);
        }
        this.analysingResultsUI.sliderOnChange(Val_Min, Val_Max, Coul1, Coul2);


        var Diff = Val_Max - Val_Min;
        if (Diff == 0) {
            Diff = 1;
        }
        for (var i = 0; i < resu.length; i++) {

            var calc_pour = (resu[i].val - Val_Min) * 100 / Diff;
            var calc_pour_r = 0;
            var calc_pour_v = 0;
            var calc_pour_b = 0;
            if (parseInt(Coul2[0]) > parseInt(Coul1[0])) {
                calc_pour_r = parseInt(Coul1[0]) + (calc_pour * ((parseInt(Coul2[0]) - parseInt(Coul1[0])) / 100));
            } else {
                calc_pour_r = parseInt(Coul1[0]) - (calc_pour * ((parseInt(Coul1[0]) - parseInt(Coul2[0])) / 100));
            }
            if (parseInt(Coul2[1]) > parseInt(Coul1[1])) {
                calc_pour_v = parseInt(Coul1[1]) + (calc_pour * ((parseInt(Coul2[1]) - parseInt(Coul1[1])) / 100));
            } else {
                calc_pour_v = parseInt(Coul1[1]) - (calc_pour * ((parseInt(Coul1[1]) - parseInt(Coul2[1])) / 100));
            }
            if (parseInt(Coul2[2]) > parseInt(Coul1[2])) {
                calc_pour_b = parseInt(Coul1[2]) + (calc_pour * ((parseInt(Coul2[2]) - parseInt(Coul1[2])) / 100));
            } else {
                calc_pour_b = parseInt(Coul1[2]) - (calc_pour * ((parseInt(Coul1[2]) - parseInt(Coul2[2])) / 100));
            }
            var recup_val = resu[i].val;
            resu[i].color.r = parseInt(calc_pour_r);
            resu[i].color.v = parseInt(calc_pour_v);
            resu[i].color.b = parseInt(calc_pour_b);

        }

        this.tw3d.ColorUO(resu);
    }


    /*
     Fct called when user click on 'Export results' button
     There is NetCDF and Excel files type available
     */
    ExportResult() {
        this.analysingResultsUI.exportResult();
    }


    /*
     Fct called when user click on 'Report' button
     There is PDF and Word files type available
     */
    ReportResult() {
        this.analysingResultsUI.reportResult();
    }

    /*
     Called when user choose a type of Report export (for graphs and arrays)
     obj: element event onclick
     */
    LaunchCreateReport(obj) {
        this.analysingResultsUI.launchCreateReport(obj);
    }

    setListSimuSelectedReport(val) {
        this.listSimuSelectedReport = val;
    }

    setReportChosen(val) {
        this.reportChosen = val;
    }

    getReportChosen() {
        return this.reportChosen;
    }

    /*
     Check choice of the user PDF or Word report
     if the result calculate isn't finished (this.resultIsFinished =false) wait 0.5s before check it again
     */
    checkChoice() {
        if (this.resultIsFinished == true) {
            if (this.reportChosen == "PDF") {
                this.CreateReportPDF();
            } else if (this.reportChosen == "WORD") {
                this.CreateReportWord();
            }
        } else {
            setTimeout(() => {
                this.checkChoice();
            }, 500);
        }
    }


    /*
     Called when user choose a type of Export file (for simu cases)
     obj: element event onclick
     */
    LaunchCreateExport(obj) {
        this.analysingResultsUI.launchCreateExport(obj);
    }

    setExportChosen(val) {
        this.exportChosen = val;
    }

    getExportChosen() {
        return this.exportChosen;
    }

    /*
     Calls the fct dl_Export_Data_To_XLSX(collId, filter, is_dlXLSX, input) in Everest class
     is_dlXLSX: set to false cause we are not using donwload icon, it's another processing
     input: set the name of the simu selected, it's ID and type of file export chosen
     */
    ExportNETCDF(simSelected) {
        var urls = {};
        simSelected.forEach((sel) => {
            var input = {
                "name": sel.name,
                "id": sel.id,
                "type": "netCDF"
            };
            var fileName = input["name"] + "-" + input["id"] + ".nc";
            var netcdfLink = this.Everest.dl_Export_Data_To_XLSX(sel, "", false, input);
            urls[fileName] = netcdfLink;
        });
        this.analysingResultsUI.exportNetcdfOrExcel(urls);
    }

    ExportEXCEL(simSelected) {
        var urls = {};
        simSelected.forEach((sel) => {
            var input = {
                "name": sel.name,
                "id": sel.id,
                "type": "xlsx"
            };

            var fileName = input["name"] + "-" + input["id"] + ".zip";
            var xlsxLink = this.Everest.dl_Export_Data_To_XLSX(sel, "", false, input);
            urls[fileName] = xlsxLink;
        });
        this.analysingResultsUI.exportNetcdfOrExcel(urls);
    }

    /*
     Fct which add elements to create the report in Docx format and donwload the result
     */
    CreateReportWord() {
        this.analysingResultsUI.createReportWord();
    }

    // set correspondance between id sim and its name in objToCreateTableReport array
    updateLinkObjToCreateTableReport() {
        for (var element in this.objToCreateTableReport) {
            if (this.objToCreateTableReport.hasOwnProperty(element)) {
                for (var el in this.tabAssocSimuId_name) {
                    if (this.tabAssocSimuId_name.hasOwnProperty(el)) {
                        if (this.objToCreateTableReport[element]["IDsim"] == this.tabAssocSimuId_name[el]["id"]) {
                            this.objToCreateTableReport[element]["nameSim"] = this.tabAssocSimuId_name[el]["name"];
                        }
                    }
                }
            }
        }
    }

    is3dLoaded() {
        return !!this.tw3d.socle;
    }

    getSnapShot(fct, w, h) {
        return this.tw3d.socle.Api.Window.GetSnapShot(fct, w, h);
    }


    /*
     Fct which add elements to create the report in PDF format and donwload the result
     */
    CreateReportPDF() {
        this.analysingResultsUI.createReportPDF();
    }


    /*
     Fct converts a img URL to a base 64 URL
     - based on the convert example of html-docx-js plugin
     - for each graphs, calls the context request to define infos about the simulations and context related
     - convert the image src to canvas.toDataURL() and returns result to insert in the report content string
     */
    convertImgTo64() {
        return this.analysingResultsUI.convertImgTo64();
    }


    /*
     Fct retrieves informatons about context of selected simulations and export chart into PNG and add to the content
     */
    findCtxFromSimID(content, chart, self, type, contentReport) {
        chart["export"].capture({}, function() {
            chart["export"].toPNG({}, function(data) {
                // Save chart data into chart object itself
                chart["export"].setup.chart.exportedImage = data;
                for (var element in self.listSimuSelectedReport) {
                    if (self.listSimuSelectedReport.hasOwnProperty(element) && self.listSimuSelectedReport[element].id == chart.nameID) {
                        self.Everest.Read_Simulation_Case_Info(self.listSimuSelectedReport[element], function(data, status, node) {
                            self.listSimuSelectedReport[element].description = data.description;
                            self.Everest.Read_Scenario_Info(node, data.scenarioId, function(d, s, n) {
                                if (type == "Word") {
                                    self.analysingResultsUI.appendContentWord(content, chart, self.listSimuSelectedReport, d, element);
                                } else if (type == "PDF") {
                                    content.push({
                                        text: "Simulation : " + chart.nameSimu,
                                        fontSize: 14,
                                        bold: true
                                    });
                                    content.push({
                                        text: "Description " + chart.nameSimu + " : " + self.listSimuSelectedReport[element].description,
                                        fontSize: 12
                                    });
                                    content.push({
                                        text: "KPI : " + chart.nameKPI,
                                        bold: true,
                                        fontSize: 12
                                    });
                                    content.push({
                                        text: "Context : " + d.name,
                                        bold: true,
                                        fontSize: 12
                                    });
                                    content.push({
                                        text: "Description context : " + d.description,
                                        fontSize: 12
                                    });
                                    // image graphs
                                    content.push({
                                        "image": chart["export"].setup.chart.exportedImage,
                                        alignment: "center",
                                        width: 700,
                                        height: 400,
                                        "pageBreak": "after"
                                    });
                                }

                                self.cptReportImg++;
                                // when all results of Everest request are set we complete the report with sim/context and graphs infos and finally save report
                                if (self.cptReportImg == self.nbGraphs) {
                                    if (type == "Word") {
                                        self.analysingResultsUI.appendAllTableWord(content, contentReport, self.objToCreateTableReport);
                                    } else if (type == "PDF") {
                                        self.analysingResultsUI.appendAllTablePdf(content, self.objToCreateTableReport);
                                    }
                                }

                            }, self.Everest_callbackError);
                        }, self.Everest_callbackError);

                    }
                }

            });
        });
    }


    /*
     If element in the string is a container type
     it parses its content elements, and fills the structure 'cnt'
     */
    parseElementContainer(cnt, e, p, styles) {
        var elements = [];
        var children = e.childNodes;
        if (children.length != 0) {
            for (var i = 0; i < children.length; i++) {
                p = this.parseElement(elements, children[i], p, styles);
            }
        }
        if (elements.length != 0) {
            for (var i = 0; i < elements.length; i++) {
                cnt.push(elements[i]);
            }
        }
        return p;
    }

    /*
     Fct which defines styling on html elements
     */
    defineStyle(o, styles) {
        for (var i = 0; i < styles.length; i++) {
            var st = styles[i].trim().toLowerCase().split(":");
            if (st.length == 2) {
                switch (st[0]) {
                    case "font-size": {
                        o.fontSize = parseInt(st[1]);
                        break;
                    }
                    case "text-align": {
                        switch (st[1]) {
                            case "right":
                                o.alignment = "right";
                                break;
                            case "center":
                                o.alignment = "center";
                                break;
                        }
                        break;
                    }
                    case "font-weight": {
                        switch (st[1]) {
                            case "bold":
                                o.bold = true;
                                break;
                        }
                        break;
                    }
                    case "text-decoration": {
                        switch (st[1]) {
                            case "underline":
                                o.decoration = "underline";
                                break;
                        }
                        break;
                    }
                    case "font-style": {
                        switch (st[1]) {
                            case "italic":
                                o.italics = true;
                                break;
                        }
                        break;
                    }
                }
            }
        }
    }

    /*
     Fct fills the structure 'cnt' according to different elements type in params 'e'
     and apply styles if defined
     */
    parseElement(cnt, e, p, styles) {
        if (!styles)
            styles = [];
        if (e.getAttribute) {
            var nodeStyle = e.getAttribute("style");
            if (nodeStyle) {
                var ns = nodeStyle.split(";");
                for (var k = 0; k < ns.length; k++) {
                    styles.push(ns[k]);
                }
            }
        }


        switch (e.nodeName.toLowerCase()) {
            case "#text": {
                var t = {
                    text: e.textContent.replace(/\n/g, "")
                };
                if (styles) {
                    this.defineStyle(t, styles);
                }
                p.text.push(t);
                break;
            }

            case "span": {
                this.parseElementContainer(cnt, e, p, styles);
                break;
            }
            case "br": {
                p = this.newParagraph();
                cnt.push(p);
                break;
            }
            case "table": {
                var t = {
                    table: {
                        widths: [],
                        body: []
                    }
                };
                var border = e.getAttribute("border");
                // forces the border styling
                var isBorder = true;

                t.fontSize = 9;
                this.parseElementContainer(t.table.body, e, p, styles);

                var widths = e.getAttribute("widths");
                if (!widths) {
                    if (t.table.body.length != 0) {
                        if (t.table.body[0].length != 0) {
                            for (var k = 0; k < t.table.body[0].length; k++) {
                                t.table.widths.push("auto");
                            }
                        }
                    }
                } else {
                    var w = widths.split(",");
                    for (var k = 0; k < w.length; k++) {
                        t.table.widths.push(w[k]);
                    }
                }
                cnt.push(t);
                break;
            }
            case "tbody":
            case "thead": {
                this.parseElementContainer(cnt, e, p, styles);
                break;
            }
            case "tr": {
                var row = [];
                this.parseElementContainer(row, e, p, styles);
                cnt.push(row);
                break;
            }
            case "td":
            case "th": {
                p = this.newParagraph();
                var st = {
                    stack: []
                };
                st.stack.push(p);

                var rspan = e.getAttribute("rowspan");
                if (rspan) {
                    st.rowSpan = parseInt(rspan);
                }
                var cspan = e.getAttribute("colspan");
                if (cspan) {
                    st.colSpan = parseInt(cspan);
                }

                this.parseElementContainer(st.stack, e, p, styles);
                cnt.push(st);
                break;
            }
            case "div":
            case "p": {
                p = this.newParagraph();
                var st = {
                    stack: []
                };
                st.stack.push(p);
                this.defineStyle(st, styles);
                this.parseElementContainer(st.stack, e, p);

                cnt.push(st);
                break;
            }
            case "tfoot": {
                break;
            }
            default: {
                break;
            }
        }
        return p;
    };

    /*
     Fct creates a new entry text for each part of the string html passed in params
     cnt : content, structure of element to createPDF
     */
    parseHtmlTable(cnt, htmlText) {
        var html = $(htmlText.replace(/\t/g, "").replace(/\n/g, ""));
        var p = this.newParagraph();
        for (var i = 0; i < html.length; i++) {
            this.parseElement(cnt, html.get(i), p);
        }
    }

    /*
     Fct create a new entry text in the 'content' array if the html corresponding of a new paragraph (p, br, div, td...)
     */
    newParagraph() {
        var p = {
            text: []
        };
        return p;
    }


    /*
     Fct which add tables containing values in 'tabResultTable_<simid>' div
     Adds buttons on theses DataTables tables to export content
     data : data response of getOpenDap() request
     params : params returned by the getOpenDap() request
     dataType: technicalID of the KPI
     timeScale : type of timescale of the KPI
     pidKind: type of UO pid
     tabUO: selected UO
     simId: simulation ID
     */
    updateValueGraphTab(data, params, dataType, timeScale, pidKind, tabUO, simId) {
        const attributes = this.currentKpiData.attributes["/Results/" + this.Everest.selectedTypeName + "/" + dataType];
        if (!attributes) {
            return;
        }
        var unity = this.analysingResultsUI.fixOpenDapStrEncoding(attributes["UNIT"]);
        this.analysingResultsUI.updateValueGraphTab(data, params, dataType, timeScale, pidKind, tabUO, simId, unity);
    }

    appendObjToCreateTableReport(val) {
        this.objToCreateTableReport.push(val);
    }

    updateNbCptRes() {
        if (this.tabNameKPITitle.length > 1)
            this.cptRes++;
    }

    /**
     * Enrich the initial graph config for KPI attributes satarting with META-Chart-xxxx.
     */
    graphConfigFromMetadata(initGraph, kpiMeta) {
        for (var key in kpiMeta) {
            if (!key.startsWith("META-CHART-")) continue;
            var val = kpiMeta[key];
            if (!val) continue;
            try {
                val = JSON.parse(val);
            } catch (e) {
                console.log("Value of metadata " + key + " is not JSON, using as is :", val);
                //using value as is as it is not JSON parsable
            }
            initGraph[key.substring("META-CHART-".length)] = val;
        }
        return initGraph;
    }

    /*
     Callback fct of the getOpenDap() request
     Updates graphs value dataProvider and calls this.updateValueGraphTab() to fill tables of values
     */
    onResultLoaded(data, params) {
        for (var k = 0; k < params.tab.length; k++) {
            var dataType = params.tab[k][0];
            var kpiMeta = this.currentKpiData.attributes[params.tab[k][3]];
            var timeScale = params.tab[k][1];
            var pidKind = params.tab[k][2];
            var simId = params.id;
            var tabUO = [];
          var nodes = this.GestionUrbObj.getCheckedNodesUO();
            for (var i in nodes) {
                if (nodes.hasOwnProperty(i)) {
                    tabUO[nodes[i].id] = nodes[i].name;
                }
            }

            if (this.cptMultiSimu > 1) {
                this.analysingResultsUI.updateGraphVal(data, params, dataType, timeScale, pidKind, tabUO, simId, kpiMeta);
            }
            // else only one simulation
            else {
                this.updateValueGraphTab(data, params, dataType, timeScale, pidKind, tabUO, simId);

                this.analysingResultsUI.updateGraphValOneSimu(k, data, params, dataType, timeScale, pidKind, tabUO, simId, kpiMeta);
            }


            // if all graphs had been updated
            // - loops on each of them, and set common min and max values for a same KPI
            // - changes the size of the "JS chart by amCharts" label
            if (this.cpt == this.nbGraphs) {
                this.resultIsFinished = true;
                for (var i = 0; i < this.tabNameKPI.length; i++) {
                    var max = undefined;
                    var min = undefined;
                    for (var element in this.objMinMaxAxis) {
                        if (this.objMinMaxAxis.hasOwnProperty(element)) {
                            if (this.objMinMaxAxis[element]["nameKPI"] == this.tabNameKPI[i]) {
                                if (max != undefined) {
                                    if (this.objMinMaxAxis[element]["max"] > max) {
                                        max = this.objMinMaxAxis[element]["max"];
                                    } else {
                                        this.objMinMaxAxis[element]["max"] = max;
                                    }
                                } else {
                                    max = this.objMinMaxAxis[element]["max"];
                                }
                                if (min != undefined) {
                                    if (this.objMinMaxAxis[element]["min"] < min) {
                                        min = this.objMinMaxAxis[element]["min"];
                                    } else {
                                        this.objMinMaxAxis[element]["min"] = min;
                                    }
                                } else {
                                    min = this.objMinMaxAxis[element]["min"];
                                }
                            }
                        }
                    }
                }
                this.analysingResultsUI.setMinMaxGraph(this.objMinMaxAxis);
            }

        }

    }

    updateValGraph(valMinMaxAxis, graphCollec, graphBubbleCollec, graphPieCollec, graphBarCollec) {
        this.objMinMaxAxis.push(valMinMaxAxis);
        this.cpt++;
        //sauvegarde à faire la premiere fois qu'on lance deselect après un resultGraph
        this.save_graphsLine.push(graphCollec); // on fait une copie de l'ensemble des graphs et courbes avant de faire le hide -> se comporte comme un remove
        this.save_graphsLine.push(graphBubbleCollec);
        this.save_graphsLine.push(graphPieCollec);
        this.save_graphsLine.push(graphBarCollec);
    }


    Everest_callbackError(data, status) {
        // skip kpi not found error and log to console
        if(typeof data === "string" && data.includes("not found")) {
            // For prodcec, it can be two case,
            // 1. either can be string kpi, it won't be handled by those jsdap legacy code
            // 2. or it can be the real error message that the expected KPI is not found
            console.info(`🚨: Expected ${data}`);
            return
        }

        this.analysingResultsUI.everestCallbackError(data, status);
    }

    Excel_callbackSuccess(data, status) {
        return true;
    }

}

export default Analysing_Result_CLS;
