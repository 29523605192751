const noop = () => undefined;

class Tw3D_CLS {
    constructor() {
        this.resultsLayers = [];
        this.socle = undefined
    }
    createLegend = noop;
    getGeoObjectAppearance = noop;
    Add_layerWFS = noop;
    Add_layer = noop;
    DeselectAll = noop;
    ColorUO = noop;
    Everest_callbackError = noop;
    createLegendPolygon = noop;
    createInfoModal = noop;
    SelectUO = noop;
    SelectBuildingbyContour = noop;
    List_Model_WFSlayers_callback = noop;
    Fill_WFSlayers_submenu_error = noop;
    addClassificationTag = noop;
    Init = noop;
    UnSelectAllUO = noop;
    finish_init = noop;
    getCommonAppearance = noop;
    createboxSavePOV = noop;
    Fct_SelectZtree = noop;
    createLegendPoint = noop;
    create_list_WFSlayers = noop;
    flyToBbox = noop;
    createLegendPolyline = noop;
    list_POV = noop;
    getPolygonAppearance = noop;
    getMode3DAppearance = noop;
    getLabelAppearance = noop;
    getPolylineAppearance = noop;
    getWFSLayerTypeName = noop;
    initEnd = noop;
    loadWFSFeatureLayer = noop;
    parseClassification = noop;
    runEndCheck = noop;
    showInfoModal = noop;
    showProjectTree = noop;
    toTw3dAltitudeType = noop;
    toTw3dLayerType = noop;
}

export default Tw3D_CLS;
