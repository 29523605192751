
/***
Store all data previously stored in UI.js for getting them later in another classes
***/


var DataTmpBind_CLS = function(UI) {


    //store data eturned by setKPI from UO used in results menu
    //this.currentKpiData = undefined;
    //store the KPI ztree
    // used to update KPI in analysing results: when user first click on UO, and then on a simu, we must update the KPI list. It is the same fct setKPI used than the one display on gestion_urbanobj
    this.treeKpi = undefined;
    //store tmp METADATA LABEl from UO management
    this.save_LABEL = undefined;



    ////stores display name of neighborhood level from GetOpenDapMetadata() request
    //this.save_displayname_Neighbor = [];

    this.simuSelectedInitiative = "";



    //REPORT INFOS
    //infos related MOSA, Simulations selected for report, cotext applied on these simu

    //store MOSA id
    this.mosaID = undefined;
    //store MOSA name
    this.mosaName = "";
    // store user name fullname
    this.userFullname = "";
    this.List_Sim_Selected = []; // defines "name" "id" "descr" "context"



    this.authToken = null;

    //id simu
    this.idsimu = undefined;


    //last sim id selected and run complete
    //sed to update layers list WFS dynamic
    this.lastSimIdRunComplete = undefined;


    //used to access at the table containing  UO modified list
    this.copyEverest = undefined;
    //table copy containing all KPi targets values
    this.changeValCopy = [];


    //modified values for Target SU
    this.changeValueTarget = [];


    // obj containing infos about UO City
    // /!\ used for targeting, we no longer sotre the pid in static
   // this.infoUOneighbor = undefined;

    this.resetValueKPI_tgt_SU = false;



    // boolean set to TRUE when we publish a target in SU
    // - when user open Analysing -> kpi targets : update the publish icons Targets
    this.isChangePubliStateTgt = false;


    // boolean set to true when we do a publish Context in SU
    // - when user open Simulating-> scenario context list : update publish icon
    this.isChangePubliStateCtx = false;


    //boolean used to open the first kpi inInitiative tab
    this.isFirstInitiative = false;


    // booleen pour traitement spécifique quand on clic sur 'ChangeMOSA' : on enléve le # qui s'ajoute à l'adresse et on lance Reset et Init sur liste MOSA
    this.isChangingMOSA = false;

    //boolean used when we create a new MOSA
    this.isAddingNewMOSA = false;


    // store RegEx content, when check labels/input text when create new element (sim name, bundlen context ....)
    this.contentRegExLabel = '^.*$';

    //store RegEx content, when check content of input text in Initiatives tab
    this.contentRegExSTR = '^[A-Za-z ]+$';

    this.contentRegExResendLogin = "^[A-Za-z0-9@._-]+$"; // we enter our email to resend us our login
    this.contentRegExResetPWD = "^[A-Za-z0-9]+$"; // we enter our login to reset our pwd

    // string contains KPI name selected for coloring
    // use : store here the name on the first coloring launch and when we click another time on coloring, check if name different
    this.firstKPIColoring = "";



    // save a copy of the basemap (for accessing to 'Api')
    // used for checking user input text, if the entry corresponds to a tw3d move input, Freeze the cam
    this.soclecopy = undefined;



    //copy the ztree containing sim cases
    // use this copy to create e ztree in results, ranking, targeting
    this.simTreecopy = undefined;


    //stores select id MOSA in EDIT MOSA panel
    this.selectIdEditMOSA = null;
    //store study Area of selected MOSA
    this.selectSAMOSA = null;
}


DataTmpBind_CLS.prototype.constructor = DataTmpBind_CLS;

export default DataTmpBind_CLS;
