"use strict";
import "../assets/scss/themes/iscroll-theme.scss";
import * as IScroll from 'iscroll'

import Cavi2Util from "../helpers/cavi.util.js";

class InterfaceGestionInitUI {
    constructor(controller,UI) {
        this.controller = controller;

        //TODO Remove the link with UI.
        this.UI = UI;
        //File with all texts to display
        this.contentFileJSON = this.UI.accessContentJSON;
        this.contentMessageFileJSON = this.UI.accessMessageContentJSON;
        //Urban object tree
        this.treeUO = $.fn.zTree.getZTreeObj("tree_URB");

        this.myScroll = null; //Gestion du scroll des cat�gories
        //Construit la box de gestion des Initiatives
        //Rajoute la box
        this.createboxGestionInitiatives(function() {return true;});
        //Init of all events
        this.init();
    }

    createboxGestionInitiatives(CallBackClose) {

        $("#box_gestion_Initiatives").remove();
        var c = "<div id=\"gestion_Initiatives_content\">";
        c += "      <div style=\"position:absolute; top:0px; left:0px; bottom:0px; width:250px; overflow : auto;\">";
        c += "          <div id=\"uo_gestion_Initiatives\" style=\"width:100%;\"></div>"; //Arbre avec les urban Objects
        c += "      </div>";
        c += "      <div style=\"position:absolute; top:0px; left:250px; bottom:0px; width:200px; h; \">";
        c += "          <div id=\"wrapper_scroller\" class=\"wrapper_scroller\">";
        c += "              <div  class=\"scroller\">";
        c += "                  <ul id=\"gestion_Initiatives_categories\"></ul>";
        c += "              </div>";
        c += "          </div>";
        c += "      </div>";
        c += "      <div id=\"gestion_Initiatives_part_content_attributes\" style=\"position:absolute; top:0px; left:455px; bottom:0px; right:0px; overflow:hidden;\">";
        c += "          <div style=\"text-align:left; height:50px;width:98%;\">";
        c += "              <div class=\"pull-right\" style=\"margin-top:10px\" >";
        c += "                  <button  class=\"btn use-skin classBtnClick\" style=\"color:#ffffff\" id=\"gestion_Initiatives_import\"><i class=\"fa fa-upload\"></i></button>";
        /* <div class="load_wait_fctImport" style="display:none;"><i class="fa fa-refresh fa-spin"></i></div>*/
        c += "                    <input type=\"file\" id=\"gestion_Initiatives_import_input\" style=\"display:none;\"/>";
        c += "                  <button  class=\"btn use-skin classBtnClick\" style=\"color:#ffffff\" id=\"gestion_Initiatives_export\"><i class=\"fa fa-download\"></i></button>  <div class=\"load_wait_fctExport\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div>";
        c += "              </div>";
        c += "          </div>";
        c += "          <div id=\"ini_noUO\" class=\"callout use-skin-border\" style=\"background-color:  rgba(0,91,187, 0.1); \">";
        c += "              <h4>" + this.contentFileJSON.MENU_TOP_CITY_URBAN + "</h4>";
        c += "              <p>" + this.contentFileJSON.MENU_TOP_CITY_URBAN_MESSAGE + "</p>";
        c += "          </div>";
        c += "          <div id=\"ini_noIni\"  class=\"callout use-skin-border\" style=\"background-color: rgba(0,91,187, 0.1); display:none\">";
        c += "              <h4>" + this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_DEFINE_SUBTITLE + "</h4>";
        c += "              <p>" + this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_DEFINE_TEXT + "</p>";
        c += "          </div>";
        //c += "          <div class=\"box-group use-skin-border \" id=\"list_checkbox_accordion_initiatives\">";
        //c += "          </div>";
        c += "          <div class=\"box-group\" id=\"accordion_initiatives\">";
        c += "              <!-- we are adding the .panel class so bootstrap.js collapse plugin detects it -->";
        c += "          </div>";
        c += "          <div class=\"overlay\" style=\"display:none\">";
        c += "              <i class=\"fa fa-refresh fa-spin\"></i>";
        c += "          </div>";
        c += "      </div>";
        c += "  </div>";

        var f = "<div class=\"pull-right\" ><button class=\"btn use-skin classBtnReset\" style=\"color:#ffffff\" id=\"gestion_Initiatives_Reset\" disabled>" + this.contentFileJSON.MENU_BUTTON_RESET + "</button><div class=\"load_wait_fctReset\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div> <button class=\"btn use-skin classBtnSave\" style=\"color:#ffffff;\" id=\"gestion_Initiatives_save\" disabled>" + this.contentFileJSON.MENU_BUTTON_SAVE + "</button><div class=\"load_wait_fctSauve\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div> <button class=\"btn use-skin\" style=\"color:#ffffff\" id=\"gestion_Initiatives_finish\">" + this.contentFileJSON.MENU_BUTTON_FINISH + "</button></div>";

        this.UI.CreateBox("gestion_Initiatives", {
            draggable: true,
            group: "Simulating",
            resizable: {
                minHeight: 500,
                minWidth: 800 /*, handles: 'e, s, w'*/
            }, //...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
            useSkin: true,
            title: this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_DEFINE_TITLE + " <span id=\"gestion_Initiatives_title_simcase\"></span>",
            content: c,
            footer: f,
            addOverlay: true,
            visible: false,
            menuBt: {
                reduceTo: "div_SimulationCase", //ID vers qui on doit r�duire la div
                fullscreen: {
                    css: {
                        position: "absolute",
                        top: "115px",
                        width: "860px",
                        height: "500px",
                        left: "5%",
                        right: "",
                        bottom: ""
                    },
                    fct: function() {
                        if (this.myScroll != null) {
                            this.myScroll.refresh();
                        }
                    }.bind(this)
                } //position / taille de div pour quand on sort du fullscreen
            },
            style: { //Constient tous les styles � appliquer avec en id le nom du style
                position: "absolute",
                top: "115px",
                left: "5%",
                width: "865px",
                height: "500px",
                "min-width": "800px",
                "min-height": "500px",
                "z-index": Zindex + 1000
            }
        }, CallBackClose);
        Zindex = Zindex + 1;
    }
    initTreeUO(){
        if (this.treeUO==undefined){
            this.treeUO = $.fn.zTree.getZTreeObj("tree_URB");
        }
    }
    init() {
        $("#gestion_Initiatives_Reset").click(() => this.controller.Reset_modif());
        $("#gestion_Initiatives_save").click(() => this.controller.Sauve_modif());
        $("#gestion_Initiatives_finish").click(() => {$("#box_gestion_Initiatives").hide()});
        $("#gestion_Initiatives_export").click(() =>{
            $(".load_wait_fctExport").show();
            document.getElementById("gestion_Initiatives_export").disabled = true;
            this.initTreeUO();
            this.controller.initiativesExport(this.treeUO.getCheckedNodes(true));
        });
        $("#gestion_Initiatives_import").click(() => {
            var inputFile = document.getElementById("gestion_Initiatives_import_input");
            inputFile.value="";
            inputFile.click();
        });


        $("#gestion_Initiatives_import_input").change(function() {
            var inputFile = document.getElementById("gestion_Initiatives_import_input");
            var val = inputFile.value;
            var filtre = "";
            if (val && val.length > 0 && inputFile.files != undefined && inputFile.files[0] != undefined) {
                var reader = new FileReader();
                reader.onloadend = function() {
                        var self = this;
                        self.controller.Everest.Import_Data_From_XLSX(this.controller.currentNodeSimu.name, this.controller.currentNodeSimu.id, "", inputFile.files[0].type, reader.result, function(d, s, p) {
                        self.UI.createAlert("notif", "success", self.contentMessageFileJSON.MESSAGE_ALERT_SUCCESS);
                    }, function(d, s, p) {
                        self.everestCallbackError(d, s);
                    });
                    inputFile.value="";
                }.bind(this);
                reader.readAsArrayBuffer(inputFile.files[0]);
            }

        }.bind(this));

        $("#box_gestion_Initiatives").resize(function() {
            if (this.myScroll != null) {
                this.myScroll.refresh();
            }
        }.bind(this));
    }

    /*List_Updated_Urban_Objects_Init*/
    markUrbanObjectModified(tree, initives) {
        this.initTreeUO();
        var treeNode = this.treeUO.getNodeByParam("id", initives);

        if (treeNode != null) {
            if ($("#Modif_" + treeNode.id).length == 0) {
                var aObj = $("#" + treeNode.tId + "_a");
                var editStr = "<span id='Modif_" + treeNode.id + "' class='UOModified diyModified_" + treeNode.id + "' >*</span>";
                aObj.append(editStr);
            }
            $(".diyModified_" + treeNode.id).show();
        }
    }


    hideModifiedUrbanObjects() {
        $(".UOModified").hide();
    }


    /*Export*/
    createDowloadAlertLink(xlsxLink,fileName){

        this.UI.createDowloadAlertLink(xlsxLink, fileName);
        setTimeout(function() {
            document.getElementById("gestion_Initiatives_export").disabled = false;
            $(".load_wait_fctExport").hide();
        }, 7000);
    }

    /*Import*/
    createAlertImport(){
        this.UI.createAlert("notif", "success", this.contentMessageFileJSON.MESSAGE_ALERT_SUCCESS);
    }
    importCloneControl(){
        var control = $("#gestion_Initiatives_import_input");
        control.replaceWith(control = control.clone(true));
    }

    /*Init tree*/
    resetScroll(emptyCat){
        if (this.myScroll != null) {
            this.myScroll.destroy();
            this.myScroll = null;

        }
        if (emptyCat!=undefined && emptyCat==true)
            $("#gestion_Initiatives_categories").empty();
    }
    getUI(){
        return this.UI;
    }
    getToken(){
        return this.UI.classBindData.authToken;
    }
    setIsFirstInitiative(val){
        this.UI.classBindData.isFirstInitiative=val;
    }
    /*ResetFromUO*/
    /*LoadFromUO*/
    setVisibilityNoUO(visible){
        if (visible){
            $("#ini_noUO").show();
        }else{
            $("#ini_noUO").hide();
        }
    }

    /*Load*/
    load(nodeSimu){
        if (this.UI.checkUO) {
            this.setVisibilityNoUO(false);
        }
        this.UI.classBindData.idsimu = nodeSimu.id;
        $("#gestion_Initiatives_title_simcase").text(nodeSimu.name);
        $("#box_gestion_Initiatives").show();
        this.resetScrool();
    }
    setVisibilityNoIni(visible){
        if (visible){
            $("#ini_noIni").show();
        }else{
            $("#ini_noIni").hide();
        }
    }
    setVisibilityOverlayGestIni(visible){
        if (visible){
            $("#overlay_gestion_Initiatives").show();
        }else{
            $("#overlay_gestion_Initiatives").hide();
        }
    }
    resetScrool(){
        if (this.myScroll == null) { //Bug si pa visible lors de l'init.
            this.myScroll = new IScroll("#wrapper_scroller", {
                tap: true,
                scrollbars: true,
                mouseWheel: true,
                interactiveScrollbars: true,
                shrinkScrollbars: "scale",
                fadeScrollbars: true
            });
        } else {
            this.myScroll.refresh();
        }
    }
    /*BuildInterface*/
    buildInterface(){
        /*$(".checkPerso :checkbox").change((e)=> {
            //FIXME: Do this in a better way than parsing, ex. use some data-initiative data-attribute on the element directly ... GRRR
            alert("This could not work because I parse the id of item and i shouldn't");
            var idSrc = e.currentTarget.id;
            var isChecked = e.currentTarget.checked;
            if (idSrc != undefined) {
                var nameComplete = idSrc.replace("box_item_", "");
                if (idSrc.indexOf("ini_")) {
                    var subStr = nameComplete.replace("ini_", ""); //subStr contient nom initiative + nom attribut
                    var initiative = subStr.substr(0, subStr.indexOf("_")); // recupere nom initiative
                    var attribut = subStr.substr(subStr.indexOf("_") + 1, subStr.length); //recupere nom attribut
                    this.controller.updateCheckBox(isChecked,initiative,attribut);
                }
            }
        });*/

        //Get the first item and select it
        var id = $("#gestion_Initiatives_categories").find(".box-header:visible:first").data("idparent");
        if (id!=undefined){
            this.controller.selectIni(id);
        }
        //console.log($("#gestion_Initiatives_categories").find(".box-header:visible:first").data("idparent"));
    }

    /*List_Updated_Urban_Objects_Attributes_Callback*/
    listUpdatedUrbanObjectsAttributesCallback(data, status, obj){
        var string;
        for (var i = 0; i < data["modified : "].length; i++) {
            var divId = Cavi2Util.replaceChars(data["modified : "][i]);
            string = $(".label_" + divId).html();
            if (string!=undefined && string.lastIndexOf("*") < 0) {
                $(".label_" + divId).append(" * ");
            }
        }
    }

    /*Reset_modif*/
    resetChange(){

    }
    setVisibilityWaitReset(visible){
        if (visible){
            document.getElementById("gestion_Initiatives_Reset").disabled = true;
            $(".load_wait_fctReset").show();
        }else{
            $(".load_wait_fctReset").hide();
        }
    }
    isVisibleCollapse(uid,code){
        return $("#collapse_" + uid + "_" + code).is(":visible");
    }
    /*Sauve_modif*/
    setVisibilityWaitSave(visible){
        if (visible){
            document.getElementById("gestion_Initiatives_save").disabled = true;
            $(".load_wait_fctSauve").show();
        }else{
            $(".load_wait_fctSauve").hide();
        }
    }
    getVisibilitySave(){
        return document.getElementById("gestion_Initiatives_save").disabled == false;
    }
    confirm (){
        return confirm(this.contentMessageFileJSON.MESSAGE_ALERT_BEFORE_CHECK);
    }
    /*Sauve_initiative*/
    getCheckedNodes(checked){
        this.initTreeUO();
        return this.treeUO.getCheckedNodes(checked);
    }
    /*Sauve_initiative_updateUO_callbacksucces*/
    setVisibilityDiy(id,visible){
        if (visible){
            $(".diyModified_" + id).show();
        }else{
            $(".diyModified_" + id).hide();
        }
    }
    /*Sauve_initiative_callbacksucces*/
    saveInitSucces(){
        this.UI.createAlert("alert", "success", this.contentFileJSON.MENU_LABEL_MAJ_DATA);
    }
    /*Sauve_initiative_callbackError*/
    saveInitError(){
        this.UI.createAlert("alert", "error", data.responseJSON.errorMessage);
    }

    /*Everest_callbackError*/
    everestCallbackError(data, status) {
        if (data.responseJSON != undefined) {
            var error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        }
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");

    }
}


export default InterfaceGestionInitUI;
