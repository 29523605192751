"use strict";


export const
    DEFAULT_FILL_COLOR = "#000000",
    DEFAULT_STROKE_COLOR = "#ffffff",
    LAYER_TYPE_LABELS = "Labels",
    LAYER_TYPE_POLYLINES = "Polylines",
    LAYER_TYPE_POLYGONS = "Polygones",
    LAYER_TYPE_3DOBJECTS = "3DObjects";
