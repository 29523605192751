//import Attributs_Context_CLS from './attributs_context.js';
import Attributs_Initiative_CLS from "./attributs_initiative.js";

/**
    In SU mode :
    Create a box to edit a Target selected
*/

var SU_Edit_Context_CLS = function(Everest, UI) {
    this.UI = UI;
    this.Everest = Everest;
    this.tabContext = [];
    this.data_Models_Info = null; //Info au niveau du mosa (liste des categories d'initiatives + timescales possibles)
    this.UO_Metadata = []; //Listes des metadata des attributs pour un typeName
    this.Urban_Objects_Attributes = null; //Liste des valeurs sauvegardées pour un couple "pid" (UrbanObject) / "caseId" (simulation)
    this.treeUO = $.fn.zTree.getZTreeObj("tree_URB");

    this.contentFileJSON = this.UI.accessContentJSON;
    this.contentMessageFileJSON = this.UI.accessMessageContentJSON;
    this.saveData = null;
    this.cptData = 0;
    this.nodesC = null;
    this.UOSelected = undefined;

    this.currentNodeContext = null; //On stoque les info du context, car le sim id de everest peut changer et ne plus être bon.
    //Construit la box de gestion des Context
    //Rajoute la box
    this.createboxSU_Edit_Ctx(function() {
        return true;
    });

    //Ajout des events
    $("#Edit_Context_Reset").click(function() {
        this.Reset_modif();
    }.bind(this));
    $("#Edit_Context_save").click(function() {
        this.Sauve_modif();
    }.bind(this));
    $("#Edit_Context_finish").click(function() {
        $("#box_Edit_Context").hide();
    }.bind(this));
    $("#Edit_Context_export").click(function() {
        var filtre = "";
        var nodes = this.treeUO.getCheckedNodes(true);
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i)) {
                filtre += (filtre == "" ? "?pids=" : ",") + nodes[i].id;
            }
        }
        if(this.contextAttributeList) {
            filtre+=(filtre==""?"?":"&")+"attributes="+this.contextAttributeList.join(",");
        }
        var fileName = "Context-"+this.currentNodeContext.id+".xlsx";
        var xlsxLink = this.Everest.dl_Export_Data_To_XLSX(this.currentNodeContext.id, (filtre == "" ? "" : filtre), true, {});
        this.UI.createDowloadAlertLink(xlsxLink, fileName);
    }.bind(this));
    $("#Edit_Context_import").click(function() {
        var inputFile = document.getElementById('Edit_Context_import_input');
        inputFile.click();
    }.bind(this));
    $("#Edit_Context_import_input").change(function() {
        var inputFile = document.getElementById('Edit_Context_import_input');
        var val = inputFile.value;
        if (val && inputFile.files != undefined && inputFile.files[0] != undefined) {
            var reader = new FileReader();
            reader.onloadend = function() {
                var self = this;
                self.Everest.Import_Data_From_XLSX(self.currentNodeContext.name, self.currentNodeContext.id, "", inputFile.files[0].type, reader.result, function(d, s, p) {
                    self.UI.createAlert("notif", "success", self.contentMessageFileJSON.MESSAGE_ALERT_SUCCESS);
                }, function(d, s, p) {
                    self.Everest_callbackError(d, s);

                });
                inputFile.value="";
                var control = $("#Edit_Context_import_input");
                control.replaceWith(control = control.clone(true));
            }.bind(this);
            reader.readAsArrayBuffer(inputFile.files[0]);

        }
    }.bind(this));

    this.init = function() {
        this.tabContext = [];
        this.Everest.List_Models_Info(this.initTree.bind(this), this.Everest_callbackError.bind(this));
    }

    this.initTree = function(data, status, id) {
        this.data_Models_Info = data;
        var params = {
            mode: "Edit",
            uid: "ctx",
            idParent: "items_edit_context",
            Fct_save: this.Sauve_Edit_Context.bind(this)
        };
        var par = {
            code: "Edit_Context_it"
        };
        //this.tabContext[par.code] = new Attributs_Context_CLS(par, data.timescales, params, this.UI);
        this.tabContext[par.code] = new Attributs_Initiative_CLS(par, data.timescales, params, this.UI, false);
    }



    /*
        Reset value of the context
    */

    this.Reset = function() {
        for (var i in this.tabContext) {
            if (this.tabContext[i].Reset != undefined) {
                this.tabContext[i].Reset();
            }
        }
        this.Urban_Objects_Attributes = null;

    }

    this.ResetFromUO = function() {
        this.Reset();
        this.Everest.selectedTypeName = null;
        this.Everest.selectedPid = null;
        $("#Context_noUO").show();
        $("#items_edit_context").hide();
    }

    /*
        When select a UO, load the content to editing
    */
    this.LoadFromUO = function(uo, treeNode) {
        //if (this.Everest.selectedPid != treeNode.id) {
            this.Reset();
            //uo.tree.checkNode(treeNode, true, true, true);
            if (uo.ListModelZoom != undefined) {
                var typeName = treeNode.UOtype;
                this.Everest.selectedTypeName = typeName;
                this.Everest.selectedPid = treeNode.id;
                this.Load();
                $("#Context_noUO").hide();
            }
        //}
    }
    //Confirm before cancel change
    this.beforeCheck = function(uo) {
        //console.log(document.getElementById("Edit_Context_save").disabled);
        if (document.getElementById("Edit_Context_save").disabled == true){
            return true;
        }else{
            if (confirm(this.contentMessageFileJSON.MESSAGE_ALERT_BEFORE_CHECK)){
                this.Reset_modif();
                return true;
            }else{
                return false;
            }
        }
    }

    /*
        Called by LoadFromUO()
        Launches Everest req determinig if this level of building has KPI to editing
    */
    this.Load = function(nodeContext) {
        $("#items_edit_context").hide();
        if (nodeContext != undefined && nodeContext != null) {
            this.currentNodeContext = nodeContext; //Bien passer le node.id à tous les appels everest
            $("#Edit_Context_title_span").text(nodeContext.name);
            $("#box_Edit_Context").show();
            //Mets * sur les items modifiés
            $(".UOModified").hide();
            for (var idItem in this.Everest.UOModified.Context) {
                if (this.Everest.UOModified.Context.hasOwnProperty(idItem)) {
                    $(".diyModified_" + idItem).show();
                }
            }
        }
        $("#Context_noAttrib").hide();
        //Si un uo est selectionné, on lance l'init, sinon on affiche le "You have to select one or more UO"
        var selectedNodes = this.treeUO.getCheckedNodes(true);
        if (selectedNodes.length > 0) {
            $("#Context_noUO").hide();
            $("#overlay_Edit_Context").show();
            var params = {
                nodeContext: this.currentNodeContext,
                nodeUo: selectedNodes[0]
            }
            //(param, scenarioId, pid, callbackSuccess,...)
            this.UOSelected = params.nodeContext;
            this.Everest.Read_Scenario_Attribute_Values(params, params.nodeContext.id, params.nodeUo.id, this.Read_Scenario_Attribute_Values_Callback.bind(this), this.Everest_callbackError.bind(this));
        } else {
            $("#Context_noUO").show();
        }
    }


    //Recup les valeurs par defaut pour le couple pid/contextid
    this.Read_Scenario_Attribute_Values_Callback = function(data, status, obj) {


        this.Urban_Objects_Attributes = data;
        //Si le typename à changé, on requetes les nouveaux params.
        if (this.UO_Metadata[this.Everest.selectedTypeName] == undefined) {
            this.Everest.Read_Urban_Object_Type_Metadata(this.Everest.selectedTypeName, this.Everest.selectedTypeName, this.Read_Urban_Object_Type_Metadata_Callback.bind(this), this.Everest_callbackError.bind(this));
        } else {
            //On a tout, on lance le traitement
            this.Read_Urban_Object_Type_Metadata_Callback(this.UO_Metadata[this.Everest.selectedTypeName],true,this.Everest.selectedTypeName);
        }
    }

    //Recup les valeurs pour un typeName
    this.Read_Urban_Object_Type_Metadata_Callback = function(data, status, typeName) {

        this.UO_Metadata[typeName] = data;
        //this.Object_Type_Name = typeName;
        //this.Object_Type_Metadata = data;
        //On a tout, on lance le traitement
        this.contextAttributeList=[];
        for (var key in data) {
            if(!data[key].initiative) this.contextAttributeList.push(key);
        }

        this.BuildInterface(data);
    }


  //Recup l'ordre dans les metadata
  this.Get_DisplayOrder = function (item, defaultVal) {
    var retour = (defaultVal == undefined ? 0 : defaultVal);
    if (item["metadatas"] != undefined) {
      for (var i = 0; i < item["metadatas"].length; i++) {
        if (item["metadatas"][i].name == "DisplayOrder") {
          retour = item["metadatas"][i].value;
        }
      }
    }
    return retour;
  }

    this.BuildInterface = function(Object_Type_Metadata) {
        //Pour rappel, on doit construire une interface à partir de 3 elements :
        //  - data_Models_Info :  contient les info générique (notamment les timescales) => Requeter une fois au départ
        //  - Object_Type_Metadata (ou this.UO_Metadata[this.Everest.selectedTypeName]) : contient le type de données pour chaque élément=> Requeter une fois par typename (Building / city / ...)
        //  - Urban_Objects_Attributes => contient les valeurs pour chaque timescale correspondant.
        var isEmpty = true;

        this.Everest.Read_Scenario_Info(this.UOSelected, this.UOSelected.id, function(d) {

          this.tabContext["Edit_Context_it"].Reset();

          var otmSorted = [];
          for (var key in Object_Type_Metadata) {
            if (this.Urban_Objects_Attributes[key] != undefined) {
              var item = Object_Type_Metadata[key];
              if (item.initiative == undefined && this.Is_Allowed(item.metadatas) == true) {
                otmSorted.push({ key: key, displayOrder: this.Get_DisplayOrder(item) });
              }
            }
          }
          otmSorted.sort(function (a, b) {
            return a.displayOrder - b.displayOrder;
          });
          var key;
          for (var i in otmSorted) {
            if (otmSorted.hasOwnProperty(i)) {
              key = otmSorted[i].key;
              var item = Object_Type_Metadata[key];
              this.tabContext["Edit_Context_it"].AddItem(key, item, this.Urban_Objects_Attributes[key], d.published, false, "SUEdit");
              isEmpty = false;
            }
          }
            if (isEmpty) {
                //Afficher "Aucun rez"...
                $("#Context_noAttrib").show();
                $("#items_edit_context").hide();
            } else {
                //Init des valeurs
                for (var i in this.tabContext) {
                    if (this.tabContext[i].InitValues != undefined) {
                        this.tabContext[i].InitValues();
                    }
                }
                $("#Context_noAttrib").hide();
                $("#items_edit_context").show();
                if ($("#nav_ctx_Edit_Context_it").find(".active").find(".nav-link").is(":visible")==false){
                    $("#nav_ctx_Edit_Context_it").find(".nav-link:visible:first").tab('show');
                }
            }

            // disable all ionRonslider slider after sort
            $("#items_edit_context .attribut-ini-collapse input").each(function() {
                const slider = $(this).data("ionRangeSlider");
                if (slider) {
                    slider.update({ disable: d.published });
                }
            });

            // disable import if neccessary
            $("#Edit_Context_import").prop("disabled", d.published);

            $("#overlay_Edit_Context").hide();


        }.bind(this), this.Everest_callbackError.bind(this));




    }

    //retourne True si le compte à les droits suffisant pour afficher le champ
    this.Is_Allowed = function(metadatas) {
        if (this.Everest.SuperUser == true)
            return true;

        var resu = false;
        for (var i = 0; i < metadatas.length; i++) {
            if (metadatas[i].name == "SuperUser") {
                if (metadatas[i].value == "no") {
                    return true;
                }
            }
        }
        return resu;
    }

    /*
        Reset all values of Context
        Called when user click on Reset Button
    */
    this.Reset_modif = function() {
        for (var i in this.tabContext) {
            if (this.tabContext.hasOwnProperty(i)) {
                if (this.tabContext[i].InitAllValues != undefined) {
                    this.tabContext[i].InitAllValues();
                    this.UI.createAlert("notif", "success", this.contentMessageFileJSON.MESSAGE_ALERT_RESET);
                }
            }
        }
    }

    /*
        Fct call when the user click on Save Button
    */
    this.Sauve_modif = function() {
        document.getElementById("Edit_Context_save").disabled = true;
        for (var i in this.tabContext) {
            if (this.tabContext.hasOwnProperty(i)) {
                if (this.tabContext[i].SaveAllValues != undefined) {
                    this.tabContext[i].SaveAllValues();
                    this.UI.createAlert("notif", "success", this.contentMessageFileJSON.MESSAGE_ALERT_SAVE);
                }
            }
        }
    }

    /*
        Apply modif edit on all selected UO automatically
    */
    this.Sauve_Edit_Context = function(object, afterSaveCB) {
        // en utilisant le createAlert();
        //this.Everest.Update_Urban_Objects_Attributes(this.currentNodeContext.id, this.Everest.selectedPid, object, this.UI.createAlert("alert", "success", this.contentFileJSON.MENU_LABEL_MAJ_DATA, this.UI.createAlert("alert", "error", data.responseJSON.errorMessage));
        //Boucle sur tous les items cochés plutot qu'un update simple.
        this.nodesC = this.treeUO.getCheckedNodes(true);
        this.Sauve_Edit_Context_afterSaveCB = afterSaveCB;

        for (var i = 0; i < this.nodesC.length; i++) {
            if (i + 1 == this.nodesC.length) {
                this.Everest.Update_Scenario_Attribute_Values(this.nodesC[i].id, this.currentNodeContext.id, this.nodesC[i].id, object, this.Sauve_Context_Last_callbacksucces.bind(this), this.Sauve_Context_callbackError.bind(this));
            } else {
                this.Everest.Update_Scenario_Attribute_Values(this.nodesC[i].id, this.currentNodeContext.id, this.nodesC[i].id, object, this.Sauve_Context_callbacksucces.bind(this), this.Sauve_Context_callbackError.bind(this));
            }
        }
    }

    this.Sauve_Context_callbacksucces = function(data, status, id) {
        this.Everest.UOModified.Context[id] = id;
        $(".diyModified_" + id).show();
    }


    /*
        Called if the save succeeded and the save had applied on all selected UO
        And recall Sauve_Context_callbacksucces
    */
    this.Sauve_Context_Last_callbacksucces = function(data, status) {
        this.Sauve_Context_callbacksucces(data, status, this.nodesC[this.nodesC.length - 1].id);
        if(this.Sauve_Edit_Context_afterSaveCB) this.Sauve_Edit_Context_afterSaveCB();
        this.UI.createAlert("alert", "success", this.contentFileJSON.MENU_LABEL_MAJ_DATA);
    }


    /*
        Called if the save req failed
    */
    this.Sauve_Context_callbackError = function(data, status) {

        this.UI.createAlert("alert", "error", data.responseJSON.errorMessage);
    }

    /*
        Called if Everest req failed
    */
    this.Everest_callbackError = function(data, status) {

        $("#overlay_Edit_Context").hide();
        this.treeUO.checkAllNodes(false);

        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");
    }


}

SU_Edit_Context_CLS.prototype.constructor = SU_Edit_Context_CLS;

SU_Edit_Context_CLS.prototype.createboxSU_Edit_Ctx = function(CallBackClose) {

    $("#box_Edit_Context").remove();
    var c = "<div id=\"Edit_Context_content\">";
    c += "      <div style=\"position:absolute; top:0px; left:0px; bottom:0px; width:260px; overflow : auto;\">";
    c += "          <div id=\"uo_Edit_Context\" style=\"width:100%;\"></div>"; //Arbre avec les urban Objects
    c += "      </div>";
    c += "      <div class=\"use-skin-border\" style=\"position:absolute; top:0px; left:260px; bottom:0px; right:0px; overflow:auto;\">";
    c += "          <div style=\"text-align:left; height:50px;\">";
    c += "              <div class=\"pull-left\" >";
    c += "                  <h3>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_EDIT_NAME + "</h3>";
    c += "              </div>";
    c += "              <div class=\"pull-right\" style=\"margin-top:10px\" >";
    c += "                  <button  class=\"btn use-skin classBtnClick\" style=\"color:#ffffff\" id=\"Edit_Context_import\"><i class=\"fa fa-upload\"></i></button>";
    /* <div class="load_wait_fctImportSU" style="display:none;"><i class="fa fa-refresh fa-spin"></i></div>*/
    c += "                    <input type=\"file\" id=\"Edit_Context_import_input\" style=\"display:none;\"/>";
    c += "                  <button  class=\"btn use-skin classBtnClick\" style=\"color:#ffffff\" id=\"Edit_Context_export\"><i class=\"fa fa-download\"></i></button> <div class=\"load_wait_fctExportSU\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div>";
    c += "              </div>";
    c += "          </div>";
    c += "          <div id=\"Context_noUO\" class=\"callout use-skin-border\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
    c += "              <h4>" + this.contentFileJSON.MENU_TOP_CITY_URBAN + "</h4>";
    c += "              <p>" + this.contentFileJSON.MENU_TOP_CITY_URBAN_MESSAGE + "</p>";
    c += "          </div>";
    c += "          <div id=\"Context_noAttrib\" class=\"callout use-skin-border\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
    c += "              <h4>" + this.contentFileJSON.MENU_CONTEXT_LABEL + "</h4>";
    c += "              <p>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_EDIT_MESSAGE + "</p>";
    c += "          </div>";
    c += "          <div id=\"items_edit_context\" style=\"display:none\">";
    c += "          </div>";
    c += "          <div class=\"overlay\" style=\"display:none\">";
    c += "              <i class=\"fa fa-refresh fa-spin\"></i>";
    c += "          </div>";
    c += "      </div>";
    c += "  </div>";

    var f = "<div class=\"pull-right\" ><button class=\"btn use-skin classBtnReset\" style=\"color:#ffffff\" id=\"Edit_Context_Reset\" disabled>" + this.contentFileJSON.MENU_BUTTON_RESET + "</button> <div class=\"load_wait_fctResetContextSU\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div> <button class=\"btn use-skin classBtnSave\" style=\"color:#ffffff\" id=\"Edit_Context_save\" disabled>" + this.contentFileJSON.MENU_BUTTON_SAVE + "</button> <div class=\"load_wait_fctSauveContextSU\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div> <button class=\"btn use-skin\" style=\"color:#ffffff\" id=\"Edit_Context_finish\">" + this.contentFileJSON.MENU_BUTTON_FINISH + "</button></div>";

    this.UI.CreateBox("Edit_Context", {
        draggable: true,
        group: "SuperUser",
        resizable: {
            minHeight: 500,
            minWidth: 800 /*, handles: 'e, s, w'*/
        }, //...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
        useSkin: true,
        title: this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_EDIT_TITLE + "<span id=\"Edit_Context_title_span\"></span>",
        content: c,
        footer: f,
        addOverlay: true,
        visible: false,
        menuBt: {
            reduceTo: "div_ManageScenario", //ID vers qui on doit réduire la div
            fullscreen: {
                css: {
                    position: "absolute",
                    top: "115px",
                    width: "860px",
                    height: "500px",
                    left: "5%",
                    right: "",
                    bottom: ""
                }
            } //position / taille de div pour quand on sort du fullscreen
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "115px",
            left: "5%",
            width: "860px",
            height: "500px",
            "min-width": "800px",
            "min-height": "500px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};
export default SU_Edit_Context_CLS;
