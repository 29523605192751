"use strict";

import AnalysingRankingUI from "../view/analysing_ranking_ui.js";
/*
    Class to manage the Results->Scenario ranking tab
    It is used to compare different simulations with previously defined KPIs, and rank it
    It is applied at neighborhood level
*/

class Analysing_Ranking_CLS{

  constructor(Everest, UI, GestionUrbObj) {

    this.GestionUrbObj = GestionUrbObj;

        this.analysingRankingUI = new AnalysingRankingUI(this, UI);
        this.Everest = Everest;

        this.currentKpiData = null;
        this.currentSimId = null;

        //this.chart = null;
        this.cDataChart;
        this.currentYear;
        //this.mainSlider;

        this.tabValues = [];
        this.tabLastRank = [];

        this.tableaux = [];

        this.errorKpi=false;
    }
    setErrorKpi(val){
        this.errorKpi=val;
    }
    getErrorKpi(){
        return this.errorKpi;
  }
  getCheckedNodesTree() {
    return this.analysingRankingUI.getCheckedNodesTree();
  }

    /*
        Build and init component of the result ranking box
    */
    init(GestionSimulations) {
        this.analysingRankingUI.init();
        GestionSimulations.addEventTree("treeSimAnaRanking", this.updateSimCaseTree.bind(this));
        this.Everest.List_Models_Info((data, status, id) => {
            var timescalesItem = null;
            for (var i in data.timescales) {
                if (data.timescales.hasOwnProperty(i)) {
                    if (data.timescales[i].code == "Y") { //item.timescale) {
                        timescalesItem = data.timescales[i];
                    }
                }
            }
            this.analysingRankingUI.initTimeScale(timescalesItem);
        }, this.Everest_callbackError.bind(this));
    }


    /**
    Fct which add or remove nodes on the simcase ztree on interfaces : ranking, results, targeting
    This is NOT a shared tree, cause events are different for each of these interfaces
    This fct is called when user add, remove, run a simcase
        -> stored in tabEvent and called in runComplete() : interface_gestion_simu.js
    */
    updateSimCaseTree(node, nodeParent, boolIsDelete) {
        this.analysingRankingUI.updateSimCaseTree(node, nodeParent, boolIsDelete);

    }
    getIndexSimuRun(id){
        return this.Everest.infosSimuRun.findIndex(function(x) {
            return x.idSim == id;
        });
    }
    getInfoSimuRun(index){
        return this.Everest.infosSimuRun[index];
    }

    /*
    fct called when we check a ztree checkbox
    */
    onCheck(treeNode) {
        //igo TMA 2
        /*for (var i = 0; i < allNodes.length; i++) {
            this.Everest.GetOpenDapMetadata(allNodes[i].id, allNodes[i].id, this.SetKpi.bind(this), this.setKpiError.bind(this));
        }*/
        this.Everest.GetOpenDapMetadata(treeNode.id, treeNode.id, this.SetKpi.bind(this), this.setKpiError.bind(this));
    }
    setKpiError(data, status){
        this.SetKpi(null,null);
        this.errorKpi=true;
    }
    /*Still used ?*/
    addResult(node, nodeParent) {
        this.analysingRankingUI.addResult(node, nodeParent);
    }


    /**
    Call if a Scenario or a UrbanObject is checked
    **/
  updateCheck() {
    this.GestionUrbObj.update_tree_others();
        this.errorKpi=false;
        this.analysingRankingUI.resetMessage();
        var nodes = this.analysingRankingUI.getCheckedNodesTree();
        if(nodes.length > 0){
          var nodesUO = this.GestionUrbObj.getCheckedNodesUO();
            if (nodesUO.length > 0) {
                var noRes=true;
                for (var i in nodes) {
                    if (nodes.hasOwnProperty(i)) {
                        if (nodes[i].isParent != true) {
                            this.analysingRankingUI.setVisibilityOverlay(true);
                            this.onCheck(nodes[i]);
                            this.analysingRankingUI.UI.allreadycharge_AnaRank = true;
                            noRes=false;
                        }
                    }
                }
                if (noRes==true){
                    //Show message check at least one UO + one Scenario
                    this.analysingRankingUI.messageBadSelect();
                }
            }else{
                //Show message check at least one UO + one Scenario
                this.analysingRankingUI.messageBadSelect();
            }
        }else{
            //Show message check at least one UO + one Scenario
            this.analysingRankingUI.messageBadSelect();
        }
    }



    /*
        Build the list of KPIs after the request returns data
        data: return of GetOpenDapMetadata()
    */
    SetKpi(data) {
        //igo TMA 2
        //this.currentKpiData = data; //Used to get later these data
        //if (data != null) {
        this.currentKpiData = (this.errorKpi==false?data:null);//Used to get later these data
        //}
        this.analysingRankingUI.setKpi((this.errorKpi==false?data:null));
    }



    /*
    It is called when Execute_Ranking() return successefully
    data, status, input: values returned by the Everest request
    */
    Rank_Success(data, status, input) {
        this.tabLastRank = []; //Retour => Object {1003919: 1, 1003929: 1}

        function SortRank(Tab)
        {
            var Tab_rank = [];
            for (var key in Tab)
                if (Tab.hasOwnProperty(key))
                    Tab_rank.push([key, Tab[key]]); // each item is an array in format [key, value]


            Tab_rank.sort(function (a, b) {
                return a[1] < b[1] ? -1 : a[1] > b[1] ? 1 : 0;
            });
            return Tab_rank;
        }

        this.analysingRankingUI.rankSuccess(SortRank(data),input);
    }


    setTabLastRank(id,val){
        this.tabLastRank[id] =val;
    }

    getTabLastRank(id){
        return this.tabLastRank[id];
    }



    /*
        Call the fct for calculating results, and updating content of tables
    */
    executeRanking(input){
        this.Everest.Execute_Ranking(input, input, this.Rank_Success.bind(this), this.Everest_callbackError.bind(this));
    }

    setCurrentYear(val){
        this.currentYear=val;
    }
    getCurrentYear(){
        return this.currentYear;
    }

    setcDataChart(val){
        this.cDataChart=val;
    }
    getcDataChart(){
        return this.cDataChart;
    }

    CalculateResults() {
        this.analysingRankingUI.calculateResults();
    }

    calculateResultsKpi(nodes,nodesKpi){
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i) && nodes[i].isParent == false) {
                //Loop on each KPI selected
                for (var k in nodesKpi) {
                    if (nodes.hasOwnProperty(i) && nodesKpi[k].isParent == false) {
                        var params = [];
                        var filtre = "";
                        var it = nodesKpi[k].id.split("/");
                        it = it[it.length - 1];
                        params.id = nodes[i].id;
                        params.name = nodes[i].name;
                        params.idKpi = nodesKpi[k].id;
                        params.nameKpi = nodesKpi[k].name;
                        params.it = it;
                        if (this.currentKpiData[it] != undefined) {
                            //Mode 1 seul kpi, la requete multi marche pas.
                            filtre += (filtre == "" ? '' : ',') + this.currentKpiData[it].array.dimensions[1] + "," + this.currentKpiData[it].array.dimensions[0] + "," + nodesKpi[k].id;

                            params.type1 = this.currentKpiData[it].array.dimensions[0];
                            params.type2 = this.currentKpiData[it].array.dimensions[1];
                            params.unit = this.currentKpiData.attributes[nodesKpi[k].id].UNIT;

                        }

                        if (this.tabValues[nodes[i].id] != undefined && this.tabValues[nodes[i].id][nodesKpi[k]] != undefined) {

                            // If the requets had been  launched already, we only show the result
                            this.CallBackAddResult(this.tabValues[nodes[i].id][nodesKpi[k]], params);
                        } else {

                            // Launches openDap request
                            var filter = '.dods?' + filtre;
                            this.Everest.getOpenDap(nodes[i].id, filter, params, this.CallBackAddResult.bind(this), this.Everest_callbackError.bind(this));
                        }
                    }

                }
            }
        }

    }


    /*
    Organize data returned by the request
    called on successfull return getOpenDap() request
    */
    CallBackAddResult(data, params) {
        if (this.tabValues[params.id] == undefined) {
            this.tabValues[params.id] = [];
        }
        if (this.tabValues[params.id][params.idKpi] == undefined) {
            this.tabValues[params.id][params.idKpi] = data;
        }

        //var nodesKpi = this.treeKpi.getCheckedNodes(true);

        for (var x = 0; x < data[params.type1]["data"].length; x++) {
            if (this.cDataChart[x] == undefined) {
                this.cDataChart[x] = [];
            }
            if (this.cDataChart[x][params.it] == undefined) { //Kpi
                this.cDataChart[x][params.it] = {
                    id: params.nameKpi
                }; //  this.cDataChart[x][params.it] = { id: params.it };
            }
            this.cDataChart[x][params.it]["nameKpi"] = params.it;
            this.cDataChart[x][params.it][params.id] = data[params.it]["data"][0][x][0]; //On prend que la 1ere valeur dans tous les cas.
        }
        this.analysingRankingUI.callBackAddResult(params,this.cDataChart[this.currentYear.val][params.it][params.id],this.Everest.KpiMeta[this.Everest.selectedTypeName][params.it].unit)



    }

    /*
        Define another error box msg
    */
    Everest_callbackError(data, status) {
        this.analysingRankingUI.everestCallbackError(data, status);
    }
}

export default Analysing_Ranking_CLS;
