import Cavi2Util from "./cavi.util.js";
import i18nextko from "i18next-ko/src/i18next-ko.js";
import i18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import i18nextXHRBackend from "i18next-xhr-backend";
import Cookies from "js-cookie"

const { _, ko } = window;

// i18next browser detector plugin's option
const detection = {
    // order and from where user language should be detected
    order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag", "path", "subdomain"],

    // keys or params to lookup language from
    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    lookupLocalStorage: "i18nextLng",
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ["localStorage", "cookie"],
    excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    // set 1 day as defaults
    cookieMinutes: 1440,
    // cookieDomain: "myDomain",

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,
};

// i18next xhr backend plugin's option
const backend = {
    // path where resources get loaded from, or a function
    // returning a path:
    // function(lngs, namespaces) { return customPath; }
    // the returned path will interpolate lng, ns if provided like giving a static path
    loadPath: [
        Cavi2Util.getRedirectRoot(window.location.pathname),
        "locales/{{lng}}/{{ns}}.json",
    ].join("/"),

    // path to post missing resources
    addPath: "/locales/add/{{lng}}/{{ns}}",

    // your backend server supports multi-loading
    // /locales/resources.json?lng=de+en&ns=ns1+ns2
    allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

    // parse data after it has been fetched
    // in example use https://www.npmjs.com/package/json5
    // here it removes the letter a from the json (bad idea)
    // parse: function(data) {
    //     return data.replace(/a/g, "");
    // },

    // allow cross domain requests
    crossDomain: false,

    // allow credentials on cross domain requests
    withCredentials: false,

    // define a custom xhr function
    // can be used to support XDomainRequest in IE 8 and 9
    // ajax: function(url, options, callback, data) {
    // },

    // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
    // queryStringParams: { v: "1.3.5" }
};

// i18next options
const i18nOptions = {
    debug: false,
    preload: ["en"],
    fallbackLng: ["en"],
    whitelist: ["en"],
    ns: ["common", "menu", "message"],
    defaultNS: ["common"],
    detection,
    backend,
};

const i18nextMixin = {
    options: i18nOptions,

    init(options = undefined) {
        // overwrite if new option is passed through
        _.merge(this.options, options);
        // options = options ? $.extend(this.options, options) : this.options;

        return new Promise((resolve, reject) => {
            // init i18next-ko
            i18nextko
                .use(i18nextBrowserLanguageDetector)
                .use(i18nextXHRBackend)
                .init(
                    undefined,
                    Cookies.get("i18next") || this.options.fallbackLng[0],
                    ko,
                    undefined,
                    this.options,
                    error => (!error ? resolve(ko) : reject(error)),
                );
        });
    },

    on(event, fn, ...fnArgs) {
        // i18next currently supported events
        const events = ["initialized", "languageChanged", "loaded", "failedLoading", "missingKey"];

        // fn call args
        const params = [...fnArgs];

        if (!events.includes(event)) {
            throw Error(`${event} is not supported, available events are: ${events.join(",")}`);
        }

        if (!_.isFunction(fn)) {
            throw Error(`${fn} is not a Function`);
        }

        // register event listeners
        i18nextko.i18n.on(event, (...args) => {
            // if params are undefined, we only pass i18next callback args
            if (params.length) {
                fn(...args, ...params);
            } else {
                fn(...args);
            }
        });

        return this;
    },

    updateAppComponentsLang: (lng, UI, GestionMOSA) => {
        const menu = i18nextko.i18n.getResourceBundle(lng, "menu");
        const message = i18nextko.i18n.getResourceBundle(lng, "message");

        /* eslint-disable no-param-reassign */
        // override the UI stuff
        UI.accessContentJSON = menu;
        UI.accessMessageContentJSON = message;
        UI.newBox.contentFileJSON = menu;

        // override the MOSA management stuff
        GestionMOSA.interfaceMosaUI.contentFileJSON = menu;
        GestionMOSA.interfaceMosaUI.contentMessageFileJSON = message;
        /* eslint-enable no-param-reassign */

        // TODO the list below can go very long..., so better isolate viewModel with different components...
        // All following DOM elements are initialized after KO binding, so they won't be observed
        // now explicitly refresh them when language is changed
        if ($("#div_SuperUser").length) {
            $("#div_SuperUser").text(i18nextko.i18n.t("MENU_LEFT_SUPER_USER", { ns: "menu" }));
            $("#div_ManageScenario").text(
                i18nextko.i18n.t("MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT", { ns: "menu" }),
            );
            $("#div_ManageTarget").text(i18nextko.i18n.t("MENU_LEFT_SUPER_USER_TARGET", { ns: "menu" }));
        }

        // The default value of MOSA selection
        if ($("#list_select_mosa_usersimple #defaultVal").length) {
            const translation = i18nextko.i18n.t("MENU_LABEL_VALUE_DROPDOWN", { ns: "menu" });
            $("#list_select_mosa_usersimple #defaultVal").text(translation);
            $("#list_select_mosa_usersimple #defaultVal").attr("title", translation);
        }

        // The login error message
        if ($("#loginErrorTxt").length && $("#loginErrorTxt").is(":visible")) {
            const translation = i18nextko.i18n.t($("#loginErrorTxt").attr("data-error"), {
                ns: "message",
            });
            $("#loginErrorTxt").text(translation);
        }
    },
};

export default i18nextMixin;
