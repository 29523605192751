import Cavi2Util from "./helpers/cavi.util.js";
import Dynamic_Table_CLS from "./model/dynamic_table_cls.js";

//Manage all the attribute of an initiative
var Attributs_Initiative_CLS = function(objParent, timescales, params, UI, isFirstInitiative) {
    this.ui = UI;
    //Permet de savoir si on a changé un élément ou non. pour afficher les boutons save/reset
    this.boolHaveChange = true;

    this.contentFileJSON = this.ui.accessContentJSON;

    this.params = params; //Options, mode accordeon, edit, view, et données qui vont avec pour que tout soit en param...
    this.objParent = objParent; //Obj recup de Everest.
    this.timescales = timescales; //Obj recup de Everest.
    this.timescalesSteps=undefined;//To know for each timescale if there is too many step to configure them or not.
    this.childs = []; //Liste des elt (metadata)
    this.defaultVal = []; //Liste des valeurs par defaut
    this.changeVal = []; //Liste des changements de valeurs.
    this.mainSlider = new Array();
    this.mainSliderCurrentVal = new Array();
    this.idChangeVal = new Array();
    this.boolChangeCorrec = false;

    // largeur % des sliders
    this.widthSlide = "auto";

    //tableau qui stocke les attributs que l'on veut exporter
    this.tabExportAttr = [];
    //stocke l'id de l'input que l'on a modifié la valeur
    this.nameTxtBox = new Array();
    //booleen pour ajouter les checkbox de selection pour export
    //si true on se trouve dans le panel Initiative
    this.boolAddCheckExport = false;

   // this.boolIsInitiativeValue = false;
    this.isFirstInitiative= isFirstInitiative;

    this.isIniChanged = false;

    this.GeneriqueDefaultTxt = new Array();
    this.GeneriqueDefaultNum = new Array();
    if (this.params.isReadOnly == undefined) {
        this.params.isReadOnly = false;
    }
    //Keep the dynamic tables to not use the lbl as ID
    this.dynamicTables = [];

    this.selectPanel = function(){
        $(".panel" + this.params.uid).hide();
        $('.imgHeadingSel').addClass('imgHeading').removeClass('imgHeadingSel');

        $("#panel_" + this.params.uid + "_" + this.objParent.code).show();
        $("#header_" + this.params.uid + "_" + this.objParent.code + " .imgHeading").addClass('imgHeadingSel').removeClass('imgHeading');

        //If there is no tab selected, we select the first visible tab.
        if ($("#nav_" + this.params.uid + "_" + this.objParent.code).find(".active").find(".nav-link").is(":visible")==false){
            $("#nav_" + this.params.uid + "_" + this.objParent.code).find(".nav-link:visible:first").tab('show');
            //$("#nav_" + this.params.uid + "_" + this.objParent.code).find(".nav-link:visible:first").addClass("active");
        }
    }

    this.AddDiv = function() {
        var txtRow = "";
        var txtCheck = "";

        if (this.params.mode == "Accord" || this.params.mode == "Edit") {
            var img = "";
            var order = " data-sort='0' ";
            var color = "";
            if (this.objParent.metadatas != undefined) {
                for (var i in this.objParent.metadatas) {
                    if (this.objParent.metadatas[i].name == "IconPath") {
                        img = "<img src='" + this.objParent.metadatas[i].value + "' alt='' /> ";
                    } else if (this.objParent.metadatas[i].name == "Order") {
                        order = " data-sort='" + this.objParent.metadatas[i].value + "' ";
                    } else if (this.objParent.metadatas[i].name == "BgColor") {
                        color = " background:" + this.objParent.metadatas[i].value + "; border-radius: 10px; ";
                    }
                }
            }
            txtRow += "     <div class='box-header panel-heading' id='header_" + this.params.uid + "_" + this.objParent.code + "' data-idparent='" + this.objParent.code + "' style='padding: 4px;" + color + "'>";
            txtRow += "         <h4 class='box-title' title='" + this.objParent.description + "'>";
            txtRow += "             <table><tr><td class='" + (this.isFirstInitiative == true?"imgHeadingSel ":"imgHeading ") + " panelHeading'></td>" +
                "<td class='panelHeading'>" + img + this.objParent.displayName + "</td></tr></table>";
                //<a id='acollapse_" + this.params.uid + "_" + this.objParent.code + "' data-toggle='collapse' data-parent='#" + this.params.idParent + "' href='#panel_" + this.params.uid + "_" + this.objParent.code + "' ";
            //txtRow += "             aria-expanded=" + (this.isFirstInitiative == true?"'true'":"'false' class='collapsed'") + " >&nbsp;&nbsp;" + this.objParent.displayName + "</a>";
            txtRow += "         </h4>";
            txtRow += "     </div>";
            $("#" + this.params.gestionAttrib).append("<li class='panel_" + this.params.uid + "_" + this.objParent.code + " sortini' " + order + ">" + txtRow + "</li>");
            //Rajout de l'event
            $("#header_" + this.params.uid + "_" + this.objParent.code).click(()=>{this.selectPanel()});
            //Sort items
            this.sortDiv(this.params.gestionAttrib,"sortini","panel_" + this.params.uid + "_" + this.objParent.code);


            txtRow = "<div class='panel box box-primary itAcc panel" + this.params.uid + " panel_" + this.params.uid + "_" + this.objParent.code + "'" +
                    " id='panel_" + this.params.uid + "_" + this.objParent.code + "' style='border:0; height:100%;' " +(this.isFirstInitiative == true?"":"display:none;") + ">";
            txtRow += "     <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "' class='use-skin-border' style='height: 100%; width:100%;'>";



            txtRow += "         <div class='changeW box-body' style='height:100%'>";


            //if (this.params.uid == "ini") {
                var isFirst=true;
                var li="";
                var divContent="";
                for (var i in this.timescales){
                    if (this.timescales.hasOwnProperty(i)) {
                        var it = this.timescales[i];
                        var idItem = "collapse_" + this.params.uid + "_" + this.objParent.code + "_" + it.code;
                        li += "                 <li class='nav-item" + (isFirst == true ? " active" : "") + "'>" +
                        //li += "                 <li class='nav-item'>" +
                                                    "<a class='nav-link' "+
                                                    " id='" + idItem + "_tab' href='#" + idItem + "' data-toggle='tab'" +
                                                    " role='tab' style='display:none;'>" + it.displayName + "</a></li>";

                        divContent += "                 <div class='tab-pane" + (isFirst == true ? " active" : "") + " ' id='" + idItem + "' role='tabpanel' style='overflow-y:scroll; height:100%;'>";
                        //divContent += "                 <div class='tab-pane' id='" + idItem + "' role='tabpanel' style='overflow-y:scroll; height:100%;'>";
                        if (it.steps!=undefined && it.steps.length > 1){//Specific to constant which doesn't have list of value
                            divContent += "                 <div id='"+ idItem + "_slider' class='sliderYear' style='width:" + this.widthSlide + ";'></div>";
                        }
                        divContent += "                     <div id='"+ idItem + "_content' class='attribut-ini-collapse'></div>";
                        divContent += "                 </div>";
                        isFirst=false;
                    }
                }
                txtRow += "         <div class='nav-tabs-custom' style='width:100%;height:100%'>";
                txtRow += "             <ul class='nav nav-tabs' id='nav_" + this.params.uid + "_" + this.objParent.code + "'  role='tablist'>";
                txtRow += li + "             </ul>";
                txtRow +=               '<div class="tab-content" id="nav_' + this.params.uid + "_" + this.objParent.code + '_content"  style="border-width:3px;border-color:black; height:calc(100% - 44px);">'
                                        + divContent + "</div>";
                txtRow += "         </div>";
            //} else {
            //    txtRow += "         <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "_slider' class='sliderYear' style='width:" + this.widthSlide + ";'></div>";
            //    txtRow += "         <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "_content'></div>";
            //}
            txtRow += "         </div>";
            txtRow += "     </div>";
            txtRow += "</div>";
        }


        $('#' + this.params.idParent).append(txtRow);

        //Rajout du slider en fct du timescale du premier item
        var timescalesItem = null;
        if (this.timescales != null) {
            if (this.timescalesSteps==undefined){
                this.timescalesSteps=new Array();
            }
            for (var i in this.timescales) {
                if (this.timescales.hasOwnProperty(i)) {
                    //Todo : Gérer si plus de 100 elt => Passer via le excel
                    timescalesItem = this.timescales[i];

                    if (this.timescales[i].steps!=undefined &&  this.timescales[i].steps.length > 1){

                        var idItem = this.params.uid + "_" + this.objParent.code + (this.params.mode == "Accord" || this.params.mode == "Edit" ? "_" + timescalesItem.code : "");

                        let c = this.timescales[i].code;
                        if(this.timescales[i].steps.length < 100) { //item.timescale) {//suppr de DY en attendant de trouver une alternative.
                            this.timescalesSteps[c]=true;
                            //console.time("Adding slider : " + idItem);
                            this.childs["slider_" + idItem] = timescalesItem;

                            txtRow = "     <input id='slider_" + idItem + "'  readonly='' value='' style='display:none'/><hr/>";
                            $("#collapse_" + idItem +  "_slider").append(txtRow);


                            // creates slider for each initiative
                            $("#slider_" + idItem).ionRangeSlider({
                                type: "single",
                                values: timescalesItem.steps,
                                grid: true,
                                //  force_edges: true,        // décalage du slider sur les bords
                                onFinish: (data)=>{
                                    this.Slider_OnChange(data,c)
                                },
                                //  onStart: this.Slider_OnChange.bind(this)
                            });
                            if (this.GeneriqueDefaultTxt[timescalesItem.code]==undefined){
                                this.GeneriqueDefaultTxt[timescalesItem.code]=new Array();
                            }
                            if (this.GeneriqueDefaultNum[timescalesItem.code]==undefined){
                                this.GeneriqueDefaultNum[timescalesItem.code]=new Array();
                            }
                            for (var i = 0; i < timescalesItem.steps.length; i++) {
                                this.GeneriqueDefaultTxt[timescalesItem.code].push("");
                                this.GeneriqueDefaultNum[timescalesItem.code].push(0);
                            }

                            this.mainSlider[timescalesItem.code] = $("#slider_" + idItem).data("ionRangeSlider");

                            //console.timeEnd("Adding slider : " + idItem);
                        }else{
                            this.timescalesSteps[c]=false;
                            txtRow= "          <div id='Message_" + idItem + "' class=\"use-skin-border\" style=\"background-color: rgba(0,91,187, 0.1);\">";
                            txtRow += "              <p>" + this.contentFileJSON.MENU_LABEL_INITIATIVE_TO_MANY_STEPS + "</p>";
                            txtRow += "          </div>";
                            $("#collapse_" + idItem +  "_slider").append(txtRow);
                        }
                    }
                    else{
                        this.GeneriqueDefaultTxt[timescalesItem.code]=[""];
                        this.GeneriqueDefaultNum[timescalesItem.code]=[0];
                        this.timescalesSteps[this.timescales[i].code]=true;
                    }
                }
            }
        }
        if (this.params.mode == "Accord" || this.params.mode == "Edit") {
            $('.panel_' + this.params.uid + "_" + this.objParent.code).hide();
        }
    }

    this.sortDiv = function(idDiv, classSort, idPanel){
        var divList = $( "#" + idDiv + " ." + classSort);
        divList.sort(function(a, b){
            var contentA =parseInt( $(a).data('sort'));
            var contentB =parseInt( $(b).data('sort'));
            if (contentA <= contentB)
                $(a).after($(b));
            else
                $(a).before($(b));
            return (contentA < contentB) ? -1 : (contentA > contentB) ? 1 : 0;
        });
        //Do not use append as html as it disconnects all the jquery data() and eventhandles associated.
        var $pan = $("#" + idPanel );
        divList.each(function() {
          $pan.append($(this));
        });
    }

    this.Reset = function() {
        if (this.objParent != null) {
            $('.panel_' + this.params.uid + "_" + this.objParent.code).hide();
            //if (this.params.uid == "ini") {
                for (var i in this.timescales){
                    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_" + this.timescales[i].code + "_tab").hide();
                    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_" + this.timescales[i].code + "_content" ).empty();
                }
            //} else {
            //    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_tab").hide();
            //    $('#collapse_' + this.params.uid + "_" + this.objParent.code + "_content").empty();
            //}
            this.childs = []; //Liste des elt (metadata)
            this.defaultVal = []; //Liste des valeurs par defaut
            this.changeVal = []; //Liste des changements de valeurs.
          this.ui.classBindData.changeValCopy = [];
          this.dynamicTables = [];
        }
    }

    // Fct appelée lorsque l'on bouge le slider
    this.Slider_OnChange = function(data, code) {

        if ((this.nameTxtBox[code]!=undefined && this.nameTxtBox[code].indexOf("trgt") > -1) && this.boolChangeCorrec) {
            var nodes = ztree.getNodes();
            for (var t in nodes) {
                if (nodes.hasOwnProperty(t)) {
                    nodes[t].chkDisabled = false;
                    ztree.checkNode(nodes[t], true);
                }
            }
        }

        this.mainSliderCurrentVal[code] = data.from;
        this.InitValues(false);
    };


    /** Fct mainly used for rounding input text values numeric and updating input values
    called by : initValues()
                UpdateVal() : just for testing numeric values in this case

    */
    this.roundValues = function(item, newVal, id, tabMeta) {

        // de-coupling the div id and attribute name
        var divId = Cavi2Util.replaceChars(id);

        if (item.type == "NUM") {
            var unite = item.unit;
            if ((item.numericValueMin == 0 && item.numericValueMax == 0) || item.numericValueMax == null || item.numericValueMin == null) {

                //on fait le traitement des valeurs arrondies
                var valueMeta = tabMeta["Decimal"];
                if (valueMeta == undefined) { //non trouve
                    valueMeta = 0; //val pare defaut
                }

                valueMeta = parseFloat(valueMeta);
                if (newVal != "" && isNaN(valueMeta) == false) {
                    if ($.isNumeric(newVal)) {
                        if (valueMeta > 0) {
                            // si ce qui renseigné est DECIMAL
                            if (newVal % 1 != 0) {

                                newVal = newVal.toString();
                                var sub = newVal.slice(newVal.indexOf('.') + 1, newVal.length);
                                if (sub.length < valueMeta) {
                                    var nbZeroAdd = "";
                                    for (var i = sub.length; i < valueMeta; i++) {
                                        nbZeroAdd += "0";
                                    }

                                    if (newVal.indexOf('.') == -1) {
                                        $("#item_" + this.params.uid + "_" + divId).val(newVal + "." + nbZeroAdd);
                                    } else {
                                        $("#item_" + this.params.uid + "_" + divId).val(newVal + nbZeroAdd);
                                    }

                                } else if (sub.length > valueMeta) {
                                    $("#item_" + this.params.uid + "_" + divId).val(parseFloat(newVal).toFixed(valueMeta));
                                } else {
                                    newVal = parseFloat(newVal);
                                    $("#item_" + this.params.uid + "_" + divId).val(newVal);
                                }
                            } else { // ENTIER
                                newVal = parseFloat(newVal);
                                //entier donc on doit compléter de 0 nb fois renvoyé par metadatas
                                var nbZeroAdd = "";
                                for (var i = 0; i < valueMeta; i++) {
                                    nbZeroAdd += "0";
                                }
                                $("#item_" + this.params.uid + "_" + divId).val(newVal + "." + nbZeroAdd);
                            }
                        } else if (valueMeta == 0) {
                            //on arrondi la valeur 1.2->1     1.7->2
                            newVal = Math.round(newVal);
                            $("#item_" + this.params.uid + "_" + divId).val(newVal);
                        }
                    } else {
                        $("#item_" + this.params.uid + "_" + divId).val(newVal);
                    }
                } else {
                    $("#item_" + this.params.uid + "_" + divId).val(newVal);
                }

            } else {

                if (tabMeta["Table"] != undefined || (tabMeta["selectableoptions"] != undefined && tabMeta["selectableoptionsdisplay"] != undefined)) {
                    $("#item_" + this.params.uid + "_" + divId).val(newVal.toString());
                } else {
                    //Updates slider
                    // attribut slider or year slider
                    var slider = $("#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId).data("ionRangeSlider");

                    // dirty hack, no need to update 'from' after each handle realase
                    // is_init is flag to tell if it is first init, we update once 'from'
                    if (slider && !slider.hasOwnProperty('is_init')) {
                        slider.update({
                            from: newVal
                        });
                    }
                    slider.is_init = true;
                }

            }
        } else if (item.type == "BOOL") {
            // var tabMeta = this.MetadataToObj(item.metadatas);
            if (tabMeta["Table"] == undefined && tabMeta["selectableoptions"] == undefined && tabMeta["selectableoptionsdisplay"] == undefined) {
                $("#item_" + this.params.uid + "_" + divId).prop('checked', newVal);
            } else {
                $("#item_" + this.params.uid + "_" + divId).val(newVal.toString());
            }

        } else {

            $("#item_" + this.params.uid + "_" + divId).val(newVal);
        }


        //TODO : update minichart if any associated with this item either with changeVal[id] or defaultVal[id]
    };

    this.initMinichart = function(id, item){
        var divId = Cavi2Util.replaceChars(id);
        var chartId = "minichart_" + this.params.uid + "_" + divId;
        var chart = AmCharts.makeChart( chartId, {
          "type": "serial",
          "theme": "light",
          "dataProvider": [  ],
          "categoryField": "step",
          "autoMargins": false,
          "marginLeft": 0,
          "marginRight": 5,
          "marginTop": 0,
          "marginBottom": 0,
          "graphs": [ {
            "valueField": "value",
            "bullet": "round",
            "bulletSizeField":"bulletSize",
            "balloonText": ("[[category]]: [[value]] "+item.unit),
            "balloon":{
                  "fontSize": 5
             },
            "showBalloon": true,
            "lineColorField": "color"
          } ],

          "valueAxes": [ {
            "gridAlpha": 0,
            "axisAlpha": 0
          } ],
          "categoryAxis": {
            "gridAlpha": 0,
            "axisAlpha": 0,
            "startOnAxis": true
          }
        } );
        $("#"+chartId).data('cavi-minichart',chart);
    }

    this.updateMinichart = function(id,item,cv) {
        var divId = Cavi2Util.replaceChars(id);
        var chart = $("#minichart_" + this.params.uid + "_" + divId).data('cavi-minichart');
        if(!chart) return;
        var data = this.changeVal[id]||this.defaultVal[id]||[];
        chart.dataProvider =[];
        var ts = this.timescales.find(ts => { return ts.code === item.timescale; });
        data.forEach(function(val,idx){
            chart.dataProvider.push({
                step:ts.steps[idx],
                value:val,
                color: idx == cv ? "#FF0000" : "#0000FF",
                bulletSize: idx == cv ? 8 : 1
            })
        })
        chart.validateData();
    };

    this.InitValues = function(IsResetValue) {
        var cv;
        var visibilite = $("#box_TargetList_Edit").is(':visible');


        for (var id in this.childs) {
            if (this.childs.hasOwnProperty(id)) {
                var newVal = null;
                var item = this.childs[id];
                var tabMeta = this.MetadataToObj(item.metadatas);

                cv = (this.mainSliderCurrentVal[item.timescale] != undefined) ? this.mainSliderCurrentVal[item.timescale] : 0;

                if (visibilite == true && this.ui.classBindData.changeValueTarget[id] != undefined && this.ui.classBindData.changeValueTarget[id][cv] != undefined) {
                    newVal = this.ui.classBindData.changeValueTarget[id][cv];
                /*
                    if (id.indexOf("Neighborhood_") > -1 || id.indexOf("Building_") > -1) {
                        this.boolIsInitiativeValue = false;
                    } else {
                        this.boolIsInitiativeValue = true;
                    }
               */
                } else if (visibilite == false && this.ui.classBindData.changeValCopy[id] != undefined && this.ui.classBindData.changeValCopy[id][cv] != undefined && IsResetValue == false) {
                    // newVal = this.changeVal[id][cv];
                    newVal = this.ui.classBindData.changeValCopy[id][cv];

                }
                if (this.defaultVal[id] != undefined && this.defaultVal[id][cv] != undefined) {
                    //si id contient building ou neighborhood, on est dans les valeurs de targets, sinon c'est les initiatives
                    //si dans initiatives on prend en priorité le contenu de changeValCopy et pas defaltVal(l'inverse de ce que l'on fait pour les targets)
                    // if (this.boolIsInitiativeValue == false) {
                    if (this.childs[id].IsInitiative == false) {
                        //si reset prendre la valeurs par defaut
                        newVal = this.defaultVal[id][cv];
                        if (IsResetValue == true) {
                            this.UpdateVal(id, newVal);
                        }
                    } else {
                        if (this.ui.classBindData.changeValCopy[id] == undefined) {
                            newVal = this.defaultVal[id][cv];
                            if (IsResetValue == true) {
                                this.UpdateVal(id, newVal);
                            }
                        }
                    }
                }
                if (newVal != null) {
                    this.roundValues(item, newVal, id, tabMeta);
                }

                if(item.type == "NUM" && (item.timescale in this.mainSlider)) {
                    this.updateMinichart(id,item,cv);
                }

            }
        }
        if (IsResetValue == true) {
            this.changeVal = []; //Liste des changements de valeurs.
            this.ui.classBindData.changeValCopy = [];
        }
    }

    this.InitAllValues = function(val) {
        for (var s in this.mainSlider){
            if (this.mainSlider.hasOwnProperty(s)) {
                for (var i = 0; i < this.mainSlider[s].options.p_values.length; i++) {
                    this.mainSliderCurrentVal[s] = i;
                    this.InitValues(true);
                }
            }
        }

        this.InitValues(true);
        if (val != undefined) {
            for (var s in this.mainSlider){
                if (this.mainSlider.hasOwnProperty(s)) {
                    this.mainSlider[s].update({
                        from: 0
                    });
                    this.Slider_OnChange({
                        from: 0
                    },s);
                }
            }
        }
        $(".classBtnSave").prop("disabled", true);
        $(".classBtnReset").prop("disabled", true);
    }

    this.SaveAllValues = function() {
        //Input { "KPI1Name": [<timestep1Target>,<timestep2Target> ...], "KPI2Name": [<timestep1Target>,<timestep2Target> ...], ... }
        var Everest_Modif = {};
        var isEmpty = true;
        for (var elem in this.changeVal) {

            if (this.changeVal.hasOwnProperty(elem)) {
                //var node =
                //    if node.isChecked
                Everest_Modif[elem] = this.changeVal[elem];
            }
        }
        if (this.boolChangeCorrec == true)
            this.params.Fct_save(Everest_Modif, ()=>{
                //Update defaultVal with the key/values of changeVal
                _(this.changeVal).toPairs().forEach((kv)=>{
                    this.defaultVal[kv[0]]=kv[1];
                })
            });
        $(".classBtnSave").prop("disabled", true);
        $(".classBtnReset").prop("disabled", true);
    }

    //Convertit les metadata en tableau associatif. Plus simple d'acces
    this.MetadataToObj = function(tab) {
        var retour = [];
        for (var i in tab) {
            if (tab.hasOwnProperty(i)) {
                retour[tab[i].name] = tab[i].value;
            }
        }
        return retour;
    }
    //Cherche une metadata par sa cle
    this.GetMetadataByName = function(metadatas, name) {
        for (var metadata in metadatas) {
            if (metadatas.hasOwnProperty(metadata)) {
                if (metadatas[metadata].name == name) {
                    return metadatas[metadata].value;
                }
            }
        }

        return undefined;
    }

  this.getDynamicTable = function (title, timescale) {
    var retour = -1;
    for (var i = 0; i < this.dynamicTables.length; i++) {
      if (this.dynamicTables[i].name == title && this.dynamicTables[i].timescale == timescale) {
        retour = i;
        return i;
      }
    }
    return retour;
  }

    this.AddItem = function(id, item, defaultVal, statusPublished, isBuildingInitiative,idParent,isReadOnly) {
        // try to distinguish context stuff
        if( isReadOnly !== undefined) {
            this.params.isReadOnly = isReadOnly;
        }

        var append=false;
        var divId = Cavi2Util.replaceChars(id);

        var addCheckboxIni = isBuildingInitiative;
        var status;
        if (statusPublished != null && statusPublished != undefined)
            status = statusPublished;
        //TODO Rajouter les event onchange sur checkbox que l'on doit traiter à part
        //Ecrire dans le tableau changeVal

        var eventGenerique = true;
        var eventCb=false;
        this.defaultVal[id] = defaultVal; //Garde de coté toutes les valeurs par défaut
        this.childs[id] = item;
        var txtRow = '';
        var nameIni = (item.initiative == undefined ? idParent : item.initiative);

        if (this.timescalesSteps!=undefined && this.timescalesSteps[item.timescale]==true){

            if (addCheckboxIni) {
                txtRow = '<label class="checkPerso" id="cb_box_item_' + this.params.uid + '_' + nameIni + '_' + divId + '" style="text-align:left;font-weight: normal;">';
            } else {
                txtRow = '<label style="text-align:left;font-weight: normal;">';
            }

        if (item.type == "NUM" && (this.timescalesSteps!=undefined && this.timescalesSteps[item.timescale]==true)) {
            let minichartDiv="<div class='attribut-ini-minichart' id='minichart_" + this.params.uid + "_" + divId+"'></div>";
            txtRow = minichartDiv + txtRow
        }

        var tabMeta = this.MetadataToObj(item.metadatas);
          var idDivItem = 'div_box_item_' + this.params.uid + '_' + nameIni + '_' + divId;
          var isCell = false;
          var createDiv = true;
          if (tabMeta.Table != undefined) {
            createDiv = false;
            //console.log("Table : " + tabMeta.Table);
            var idTable = this.getDynamicTable(tabMeta.Table, item.timescale);
            if (idTable == -1) {
              idTable = this.dynamicTables.length;
              this.dynamicTables[idTable] = new Dynamic_Table_CLS(idTable, tabMeta.Table, idDivItem, item.timescale);
              $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_" + item.timescale + "_content").append('<div id="' + idDivItem + '_tbl" class="sortAttr" data-sort="' + (tabMeta["DisplayOrder"] != undefined ? tabMeta["DisplayOrder"] : "0") + '"></div>');
              //Create table
              $("#" + idDivItem + "_tbl").html(this.dynamicTables[idTable].getHtmlInit());
            }
            if (tabMeta.TabRow != undefined && tabMeta.TabColumn != undefined) {
              //console.log("Row : " + tabMeta.TabRow);
              //console.log("Column : " + tabMeta.TabColumn);
                    var inpTxt = "<input class='dynamicTableInput' type='text' placeholder='' id='item_" + this.params.uid + "_" + divId + "' " + ((this.params.isReadOnly == true ? "disabled " : "") || (status == true ? "disabled " : "")) + " />";
                    this.dynamicTables[idTable].setText(tabMeta.TabRow, tabMeta.TabColumn, '<div id="' + idDivItem + '">' + inpTxt + '</div>');
              isCell = true;
                    append = true;
            }
            //console.log("==================");

          } else {
            $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_" + item.timescale + "_content").append('<div id="' + idDivItem + '" class="sortAttr" data-sort="' + (tabMeta["DisplayOrder"] != undefined ? tabMeta["DisplayOrder"] : "0") + '"></div>');
          }
          this.sortDiv("collapse_" + this.params.uid + "_" + this.objParent.code + "_" + item.timescale + "_content","sortAttr","collapse_" + this.params.uid + "_" + this.objParent.code + "_" + item.timescale + "_content");

            //Liste déroulante
            if (isCell == false && ((tabMeta["selectableoptions"] != undefined && tabMeta["selectableoptions"] != null) || (tabMeta["selectableoptionsdisplay"] != undefined && tabMeta["selectableoptionsdisplay"] != null))) {

                if (addCheckboxIni) {
                    txtRow += "<p><input  type='checkbox' id='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "' /><label  class='base_label label_" + divId + "' for='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "'>" + item.displayName + "</label></p></label><br/>";
                } else {
                    txtRow += item.displayName + "</label><br/>";
                }
                var tVal = null
                var tDisp = null;

                if (item.type == "BOOL") {
                    tVal = [false, true];
                } else if (tabMeta["selectableoptions"] != undefined && tabMeta["selectableoptions"] != null) {
                    tVal = tabMeta["selectableoptions"].split(';');
                } else {
                    tVal = tabMeta["selectableoptionsdisplay"].split(';');
                }
                if (tabMeta["selectableoptionsdisplay"] != undefined && tabMeta["selectableoptionsdisplay"] != null) {
                    tDisp = tabMeta["selectableoptionsdisplay"].split(';');
                } else {
                    tDisp = tabMeta["selectableoptions"].split(';');
                }
                //Add d'un select...
                txtRow += '<select name="item_' + this.params.uid + '_' + divId + '" id="item_' + this.params.uid + '_' + divId + '"' + ((this.params.isReadOnly == true ? 'disabled ' : '') || (status == true ? 'disabled ' : '')) + '>';

                for (var i in tVal) {
                    if (tVal.hasOwnProperty(i)) {
                        txtRow += '<option value="' + tVal[i] + '">' + tDisp[i] + '</option>';
                    }
                }
                txtRow += "</select><br/>";


                //if (this.params.uid == "ini") {
                $("#" + idDivItem).append(txtRow);
                ////} else {
                //    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                //}
                append=true;
            }
            else {

                if (item.type == "BOOL") { //Checkbox
                    eventGenerique = false;
                    if (addCheckboxIni) {
                        txtRow += "<input type='checkbox' id='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "' /><label class='base_label label_" + divId + "' for='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "' style='padding-top:15px;'> </label>";
                        txtRow += "</label><input type='checkbox' id='item_" + this.params.uid + "_" + divId + "' " + ((this.params.isReadOnly == true ? "disabled " : "") || (status == true ? "disabled " : "")) + "/> <b>" + item.displayName + "</b><br/>";
                    } else {
                        txtRow += "<input type='checkbox' id='item_" + this.params.uid + "_" + divId + "' " + ((this.params.isReadOnly == true ? "disabled " : "") || (status == true ? "disabled " : "")) + "/> " + item.displayName + "</label><br/>";
                    }

                    //if (this.params.uid == "ini") {
                    $("#" + idDivItem).append(txtRow);
                    //} else {
                    //    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                    //}
                    append=true;


                    $('#item_' + this.params.uid + "_" + divId).change(function() {
                        //event on checkbox : check/uncheck
                        this.isIniChanged = false;
                        this.UpdateVal(id, $('#item_' + this.params.uid + "_" + divId).is(':checked'));
                    }.bind(this));
                } else {
                  if (isCell == false) {
                    if (addCheckboxIni) {
                      txtRow += "<p><input type='checkbox' id='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "' /><label  class='base_label label_" + divId + "' for='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "'>" + item.displayName + "</label></p></label><br/>";
                    } else {
                      txtRow += item.displayName + "</label><br/>";
                    }
                    //if (this.params.uid == "ini") {
                    $("#" + idDivItem).append(txtRow);
                    //} else {
                    //    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                    //}
                    append=true;

                    if (item.type == "NUM") {
                        var numericValueMin = item.numericValueMin;
                        var numericValueMax = item.numericValueMax;
                        var unite = item.unit;
                        if (unite == "-") {
                            unite = "";
                        }
                        if ((numericValueMin == numericValueMax) || numericValueMax == null || numericValueMin == null ) {

                            //probleme alignement avec <input>
                            txtRow = "            <table><tr><td style='width:60%;'> <input class='form-control' type='text' placeholder='Enter ...' id='item_" + this.params.uid + "_" + divId + "' " + ((this.params.isReadOnly == true ? "disabled " : "") || (status == true ? "disabled " : "")) + "/></td><td style='width:10%;'>&nbsp;</td><td style='text-align:left;'>" + item.unit + "</td></tr></table>";

                            //if (this.params.uid == "ini") {
                            $("#" + idDivItem).append(txtRow);
                            //} else {
                            //    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                            //}
                            append=true;

                        } else {
                            //Slider
                            eventGenerique = false;
                            txtRow = "   <div id='test_" + id + "' style='width:50%;' class='px-1 py-2 mx-1 my-2' > <input id='slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId + "'  readonly='' style='display:none'/></div>";

                            //if (this.params.uid == "ini") {
                            $("#" + idDivItem).append(txtRow);
                            //} else {
                            //    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                            //}
                            append=true;



                            //calculate step for the slider for attribut of initiatives
                            // uses the METADATA decimal, to define precision of value

                            var tabMeta = this.MetadataToObj(item.metadatas);
                            var step = Math.pow(10,-tabMeta["Decimal"]||0);

                            // if 'deactivation' defined in meta and its value is 'yes', disable the slider in default
                            const metaDeactivation = item.metadatas.find(meta => meta.name === "deactivation");
                            const isDisabled = !!metaDeactivation
                                && !!metaDeactivation.hasOwnProperty("value")
                                && metaDeactivation["value"].toString().toLowerCase() === "yes";

                            if (unite == "%") {
                                $("#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId).ionRangeSlider({
                                    skin: "modern",
                                    min: numericValueMin,
                                    max: numericValueMax,
                                    type: 'single',
                                    step: step,
                                    postfix: "",
                                    prettify: false,
                                    grid: true,
                                    disable: (this.params.isReadOnly === true || isDisabled),
                                    onFinish: function(data) {
                                        this.isIniChanged = true;
                                        this.nameTxtBox[item.timescale] = $("#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId)[0].id;
                                        this.UpdateVal(id, data.from);
                                    }.bind(this),
                                    onUpdate: function(data) {
                                        //this.UpdateVal(id, data.from);
                                    }.bind(this),
                                });
                            } else {
                                $("#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId).ionRangeSlider({
                                    skin: "modern",
                                    min: numericValueMin,
                                    max: numericValueMax,
                                    type: 'single',
                                    step: step,
                                    postfix: unite,
                                    prettify: false,
                                    grid: true,
                                    disable: (this.params.isReadOnly === true || isDisabled),
                                    onFinish: function(data) {
                                        this.isIniChanged = true;
                                        this.nameTxtBox[item.timescale] = $("#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId)[0].id;
                                        this.UpdateVal(id, data.from);
                                    }.bind(this),
                                    onUpdate: function(data) {
                                        //this.UpdateVal(id, data.from);
                                    }.bind(this),
                                });
                            }



                        }
                    } else if (item.type == "STR") {
                        txtRow = "             <input class='form-control' type='text' placeholder='Enter ...' id='item_" + this.params.uid + "_" + divId + "' " + ((this.params.isReadOnly == true ? "disabled " : "") || (status == true ? "disabled " : "")) + "/>";

                        //if (this.params.uid == "ini") {
                        $("#" + idDivItem).append(txtRow);
                        //} else {
                        //    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                        //}
                        append=true;
                    }
                }
            }
            }
            if (isCell==false && item.type == "NUM" && (item.timescale in this.mainSlider)) {
                this.initMinichart(id, item);
            }
            if (eventGenerique == true) {
                $('#item_' + this.params.uid + "_" + divId).change(function(e) {
                    if (this.params.uid == "ini") {
                        this.isIniChanged = true;
                    } else {
                        this.isIniChanged = false;
                    }
                    this.idChangeVal[item.timescale] = '#item_' + this.params.uid + "_" + divId;
                    this.nameTxtBox[item.timescale] = $(this.idChangeVal[item.timescale])[0].id;
                    this.UpdateVal(id, $(this.idChangeVal[item.timescale]).val());

                }.bind(this));
            }
            if (addCheckboxIni==true){//checkbox
                $("#cb_box_item_" + this.params.uid + "_" + nameIni + "_" + divId + " :checkbox").change((e)=> {
                    var isChecked = e.currentTarget.checked;
                    if (isChecked) {
                        this.tabExportAttr.push(id);
                    } else { // supprime du bon tableau, l'attribut que l'on a décoché
                        for (var i = 0; i < this.tabExportAttr.length; i++) {
                            if (this.tabExportAttr[i] == id) {
                                this.tabExportAttr.splice(i, 1);
                            }
                        }
                    }
                    /*
                    Bug with Boostrap which set the div to a bad position, Hide / Show on the div solve the problem
                    We should replace the UI managed by boostrap by coding ourself the ui*/
                    let idShowHide = "#panel_" + this.params.uid + "_" + this.objParent.code;
                    $(idShowHide).hide();
                    window.setTimeout(() => { $(idShowHide).show(); }, 1);


                    //console.log(this.tabExportAttr);
                });
            }
            if (append==true){
                $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_" + item.timescale + "_tab").show();
            }
        }else{
            $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_" + item.timescale + "_tab").show();
        }
        $('.panel_' + this.params.uid + "_" + this.objParent.code).show();

    }

    this.UpdateVal = function(id, newVal) {
        var treeUO = $.fn.zTree.getZTreeObj("tree_URB");
        var nodesSelected = treeUO.getCheckedNodes(true);
        var boolValue = false;
        var item = this.childs[id];
        var idInput = this.nameTxtBox[item.timescale];
        var cv = (this.mainSliderCurrentVal[item.timescale] != undefined) ? this.mainSliderCurrentVal[item.timescale] : 0;

        var tabMeta = this.MetadataToObj(item.metadatas);

        if (item.type == "NUM") {
            try {
                if (newVal == "")
                    newVal = 0;
                //on parse l'entrée txtbox : de base renvoie tjs un string
                //si le résultat est un numérique on lance les traitements sinon message erreur

                var reg = new RegExp(',', 'g');

                if (reg.test(newVal)) {
                    newVal = newVal.replace(reg, '.');
                }
                newVal = parseFloat(newVal);
                if ($.isNumeric(newVal)) {

                    var valueMeta = tabMeta["Decimal"];
                    if (valueMeta == undefined) { //non trouve
                        valueMeta = 0; //val pare defaut
                    }

                    if (valueMeta != undefined) {
                        this.roundValues(item, newVal, id, tabMeta);
                    } else {

                        var catBtnBox = this.idChangeVal[item.timescale];
                        if (catBtnBox.indexOf("ctx") > -1) {
                            document.getElementById("Edit_Context_save").disabled = false;
                            document.getElementById("Edit_Context_Reset").disabled = false;

                        }else if (catBtnBox.indexOf("Initiatives") > -1) {
                            document.getElementById("gestion_Initiatives_Reset").disabled = false;
                            document.getElementById("gestion_Initiatives_save").disabled = false;
                        }

                    }


                    this.update(id, cv, newVal, item.timescale);
                    if(item.timescale in this.mainSlider)
                        this.updateMinichart(id,item,cv);
                    this.boolChangeCorrec = true;
                    $(".classBtnSave").prop("disabled", false);
                    $(".classBtnReset").prop("disabled", false);



                } else {
                    if (this.boolHaveChange == true) {
                        this.ui.createAlert("alert", "error", this.contentFileJSON.MENU_LABEL_INITIATIVE_ERROR_TYPE + this.contentFileJSON.MENU_LABEL_INITIATIVE_ERROR_TYPE_NUM);
                        $(this.idChangeVal[item.timescale]).val((this.defaultVal != undefined && this.defaultVal[id] != undefined ? this.defaultVal[id][cv] : ""))
                        this.boolChangeCorrec = false;
                        return -1;
                    }

                }

            } catch (err) {

            }
        } else if (item.type == "BOOL") {
            newVal = (newVal == false || newVal == "false" ? false : true);
            $(".classBtnSave").prop("disabled", false);
            $(".classBtnReset").prop("disabled", false);
            this.boolChangeCorrec = true;
            this.update(id, cv, newVal, item.timescale);
        } else if (item.type == "STR") {
            var tabMeta = this.MetadataToObj(item.metadatas);
            // check if we've selected a dropdown menu
            // if not : we use a regex for testing input content
            // else just update value
            if (tabMeta["selectableoptions"] == undefined) {
                var reg = new RegExp(this.ui.classBindData.contentRegExSTR);

                // if the regex fails : open error box and reset value in input text

                if (!reg.test(newVal) && tabMeta["selectableoptions"] != undefined) {
                    if (this.boolHaveChange == true) {
                        this.ui.createAlert("alert", "error", this.contentFileJSON.MENU_LABEL_INITIATIVE_ERROR_TYPE + this.contentFileJSON.MENU_LABEL_INITIATIVE_ERROR_TYPE_STR);
                        $(this.idChangeVal[item.timescale]).val((this.defaultVal != undefined && this.defaultVal[id] != undefined ? this.defaultVal[id][cv] : ""));
                        this.boolChangeCorrec = false;
                        return -1;
                    }

                } else {
                    this.update(id, cv, newVal, item.timescale);
                    this.boolChangeCorrec = true;
                    $(".classBtnSave").prop("disabled", false);
                    $(".classBtnReset").prop("disabled", false);
                }
            } else {
                this.update(id, cv, newVal, item.timescale);
                this.boolChangeCorrec = true;
                $(".classBtnSave").prop("disabled", false);
                $(".classBtnReset").prop("disabled", false);
            }

        }



        if (this.boolChangeCorrec) {
            for (var item in nodesSelected) {
                if (this.ui.current_skin == "skin-blue") {
                    $(".diyModified_" + nodesSelected[item].id).show();
                    //on affiche seulement quand on est dans les initiative
                    for (var i = 0; i < this.ui.classBindData.copyEverest.UOModified.Initiatives[this.ui.classBindData.idsimu].length; i++) {
                        if (this.ui.classBindData.copyEverest.UOModified.Initiatives[this.ui.classBindData.idsimu][i] == nodesSelected[item].id) {
                            break;
                        }
                    }
                    if (this.ui.classBindData.copyEverest.UOModified.Initiatives[this.ui.classBindData.idsimu].length == i) {
                        this.ui.classBindData.copyEverest.UOModified.Initiatives[this.ui.classBindData.idsimu][this.ui.classBindData.copyEverest.UOModified.Initiatives[this.ui.classBindData.idsimu].length] = nodesSelected[item].id;
                    }

                }

            }
        }

    }

    this.update = function(id, cv, newVal, timescale) {

        var visibilite = $("#box_Edit_Target").is(':visible');


        //defaultVal contains data set by SU and saved
        if (this.defaultVal != undefined && this.defaultVal[id] != undefined && this.defaultVal[id][cv] != undefined) {
            if (this.defaultVal[id][cv] == newVal) {
                if (this.changeVal[id] != undefined) { //On remet la valeur par defaut
                    if (this.isIniChanged == true) {
                        if (this.mainSlider[timescale] !=undefined) {
                            for (var i = cv; i <= this.mainSlider[timescale].old_to; i++) {
                                this.changeVal[id][i] = newVal;
                                this.ui.classBindData.changeValCopy[id][i] = this.changeVal[id][i];
                            }
                        } else {
                            this.changeVal[id][cv] = newVal;
                            this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                        }
                    } else {
                        this.changeVal[id][cv] = newVal;
                        if (visibilite == true) {
                            this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                        } else {
                            this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                            this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                        }
                    }


                    //Check voir si toutes les valeurs sont identiques
                    if (this.AreEquals(this.changeVal[id], this.defaultVal[id])) {
                        delete this.changeVal[id];
                        delete this.ui.classBindData.changeValCopy[id];
                    }
                } else {
                    //pour avoir tout les KPIS existants en valeur par défaut (de base toutes les valeurs des années sont à 0 )
                    //utilisé pour faire la comparaison entre les KPI qui ont été modifiés + sauvés et leur valeur par défaut, et cocher ceux qui ont été modifié.
                    this.changeVal[id] = this.GeneriqueDefaultNum[timescale].slice();
                    this.ui.classBindData.changeValCopy[id] = this.changeVal[id];
                    this.ui.classBindData.changeValueTarget[id] = this.changeVal[id];
                }
            } else {
                if (this.changeVal[id] == undefined) {
                    this.changeVal[id] = this.defaultVal[id].slice();
                    this.ui.classBindData.changeValCopy[id] = this.changeVal[id];

                    if (visibilite == true) {
                        this.ui.classBindData.changeValueTarget[id] = this.changeVal[id];
                    } else {
                        this.ui.classBindData.changeValCopy[id] = this.changeVal[id];
                        this.ui.classBindData.changeValueTarget[id] = this.changeVal[id];
                    }
                }
                if (this.isIniChanged == true) {
                    if (this.mainSlider[timescale] != undefined) {
                        for (var i = cv; i <= this.mainSlider[timescale].old_to; i++) {
                            this.changeVal[id][i] = newVal;
                            this.ui.classBindData.changeValCopy[id][i] = this.changeVal[id][cv];
                        }
                    } else {

                        this.changeVal[id][cv] = newVal;
                        this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                    }
                } else {
                    this.changeVal[id][cv] = newVal;
                    this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                    if (visibilite == true) {
                        this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                    } else {
                        this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                        this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                    }

                }
            }
        } else {
            // On n'a pas fait de save value sur ce KPI avant
            // - > Pas de valeur par defaut pour ce champ, on se sert de this.GeneriqueDefault pour init.
            var tree = $.fn.zTree.getZTreeObj("tree_" + this.params.uid + "_" + this.objParent.code);
            var nodes = tree.getNodesByParam("id", id);
            if (this.changeVal[id] == undefined) { // prend le contenu du dossier du KPI sélectionné -> tous les KPI du même dossier
                if (nodes.length > 0 && nodes[0].obj.type == "NUM") {
                    this.changeVal[id] = this.GeneriqueDefaultNum[timescale].slice();
                    this.ui.classBindData.changeValCopy[id] = this.changeVal[id];

                    if (visibilite == true) {
                        this.ui.classBindData.changeValueTarget[id] = this.changeVal[id];
                    } else {
                        this.ui.classBindData.changeValCopy[id] = this.changeVal[id];
                        this.ui.classBindData.changeValueTarget[id] = this.changeVal[id];
                    }

                } else {
                    this.changeVal[id] = this.GeneriqueDefaultTxt[timescale].slice();
                    this.ui.classBindData.changeValCopy[id] = this.changeVal[id];
                }
            }


            if (this.isIniChanged == true) {
                if ( this.mainSlider[timescale] != undefined) {
                    for (var i = cv; i <= this.mainSlider[timescale].old_to; i++) {
                        if (nodes.length > 0 && nodes[0].obj.type == "NUM") {
                            this.changeVal[id][i] = parseFloat(newVal);
                            this.ui.classBindData.changeValCopy[id][i] = this.changeVal[id][i];
                        } else {
                            this.changeVal[id][i] = newVal;
                            this.ui.classBindData.changeValCopy[id][i] = this.changeVal[id][i];
                        }
                    }
                } else {
                    if (nodes.length > 0 && nodes[0].obj.type == "NUM") {
                        this.changeVal[id][cv] = parseFloat(newVal);
                        this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                    } else {
                        this.changeVal[id][cv] = newVal;
                        this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                    }
                }
            } else {
                // remplace ancienne valeur par la nouvelle après un 'Save'
                this.changeVal[id][cv] = newVal;
                if (visibilite == true) {
                    this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                } else {
                    this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                    this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                }
            }




        }

        var divId = Cavi2Util.replaceChars(id);
        var $elem = $("#item_ini_"+divId+",#test_"+divId+">.irs,#item_ctx_"+divId+",#test_"+divId+">.irs");
        if(this.changeVal[id]) {
            // move the visual change to parent
            // because it takes control of the slider and disable keyboard
            $elem.parent().addClass("changedIniAttrib");
            $elem.parents("td:first()").addClass("changedIniAttribContainer");
        } else {
            $elem.parent().removeClass("changedIniAttrib");
            $elem.parents("td:first()").removeClass("changedIniAttribContainer");
        }
    }


    this.AreEquals = function(tab1, tab2) {
        if (tab1.length != tab2.length)
            return false;
        for (var i in tab1) {
            if (tab1.hasOwnProperty(i)) {
                if (tab1[i] != tab2[i])
                    return false;
            }
        }
        return true;
    }


    //Init la div
    this.AddDiv();
}
Attributs_Initiative_CLS.prototype.constructor = Attributs_Initiative_CLS;

export default Attributs_Initiative_CLS;
