import {
  LAYER_TYPE_LABELS,
  LAYER_TYPE_POLYGONS,
  LAYER_TYPE_POLYLINES,
} from "../helpers/constants.js";

function createLabelsLegend(canvas, fillColor, strokeColor) {
  const ctx = canvas.getContext("2d");
  // Label's 'txt_color' as fillStyle
  // Label's 'bg_color' as strokeStyle
  ctx.fillStyle = fillColor;
  ctx.strokeStyle = strokeColor;
  ctx.beginPath();
  ctx.strokeRect(5, 5, 4, 4);
  ctx.strokeRect(18, 9, 4, 4);
  ctx.strokeRect(9, 15, 4, 4);
  ctx.strokeRect(18, 18, 4, 4);
  ctx.fillRect(5, 5, 4, 4);
  ctx.fillRect(18, 9, 4, 4);
  ctx.fillRect(9, 15, 4, 4);
  ctx.fillRect(18, 18, 4, 4);
  ctx.fill();
}

function createPolylinesLegend(canvas, fillColor, strokeColor) {
  const ctx = canvas.getContext("2d");
  // Polylines's 'border_color' as strokeStyle
  ctx.strokeStyle = strokeColor;
  ctx.lineWidth = 4;
  ctx.beginPath();
  ctx.moveTo(2, 22);
  ctx.lineTo(10, 10);
  ctx.lineTo(14, 14);
  ctx.lineTo(22, 2);
  ctx.stroke();

  // Polylines's 'line_color' as fillStyle
  ctx.strokeStyle = fillColor;
  ctx.lineWidth = 2;
  ctx.stroke();
}

function createPolygonsLegend(canvas, fillColor, strokeColor) {
  const ctx = canvas.getContext("2d");

  // Polygons's 'fill_color' as fillStyle
  // Polygons's 'border_color' as fillStyle
  ctx.fillStyle = fillColor;
  ctx.strokeStyle = strokeColor;

  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(2, 5);
  ctx.lineTo(10, 2);
  ctx.lineTo(22, 15);
  ctx.lineTo(14, 22);
  ctx.lineTo(2, 18);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
}

function createLegend(type, canvas, fillColor, strokeColor) {
  if (type === LAYER_TYPE_LABELS) {
    createLabelsLegend(canvas, fillColor, strokeColor);
  } else if (type === LAYER_TYPE_POLYLINES) {
    createPolylinesLegend(canvas, fillColor, strokeColor);
  } else if (type === LAYER_TYPE_POLYGONS) {
    createPolygonsLegend(canvas, fillColor, strokeColor);
  } else {
    // do nothing for LAYER_TYPE_3DOBJECTS
  }
}

function createLegendCollapseSymbol(canvas, options) {
  const ctx = canvas.getContext("2d");
  const defaults = Object.assign(
    {
      symbol: "+",
      fillStyle: "black",
      strokeStyle: "white",
      lineWidth: 2,
      font: "small-caps bold 12pt 'Arial'",
      x: 15,
      y: 24,
    },
    options
  );

  ctx.fillStyle = defaults.fillStyle;
  ctx.strokeStyle = defaults.strokeStyle;
  ctx.lineWidth = defaults.lineWidth;
  ctx.font = defaults.font;
  ctx.strokeText(defaults.symbol, defaults.x, defaults.y);
  ctx.fillText(defaults.symbol, defaults.x, defaults.y);
}

function toggleLegendCollapseSymbol(canvas, state) {
  switch (state) {
    case "show":
      // overwrite +
      createLegendCollapseSymbol(canvas, {
        symbol: "+",
        fillStyle: "white",
      });
      // redraw -
      createLegendCollapseSymbol(canvas, {
        symbol: "-",
        fillStyle: "black",
      });
      break;
    case "hide":
      // overwrite -
      createLegendCollapseSymbol(canvas, {
        symbol: "-",
        fillStyle: "white",
      });
      // redraw +
      createLegendCollapseSymbol(canvas, {
        symbol: "+",
        fillStyle: "black",
      });
      break;
    default:
      break;
  }
}

// define legend related bindings for canvas
export default {
  legend: {
    init: (element, valueAccessor) => {
      // unwrap the legendConfig
      const legendConfig = ko.unwrap(valueAccessor());

      // create canvas legend
      createLegend(legendConfig.type, element, legendConfig.fillColor, legendConfig.strokeColor);

      // if it is legend root & has classifications, add + symbol to canvas
      if (legendConfig.groups && legendConfig.groups.length) {
        createLegendCollapseSymbol(element);
      }
    },
  },
  legendCollapse: {
    init: element => {
      const canvas = $(`[data-target*="#${element.id}"]`)[0];
      $(element).on("show.bs.collapse", () => {
        toggleLegendCollapseSymbol(canvas, "show");
      });
      $(element).on("hide.bs.collapse", () => {
        toggleLegendCollapseSymbol(canvas, "hide");
      });
    },
  },
};
