
/***
Class to let managing all task type, checking success, adding new task and launching Everest run request again
***/

var TaskManager_CLS = function() {
    this.allTask = [];
    this.interval = null;
    //stores id of sim currently running
    this.simRunning = [];

}


TaskManager_CLS.prototype.constructor = TaskManager_CLS;

/*
    Adds a new task of run
    newTask: instance of tasksimulation_cls
*/
TaskManager_CLS.prototype.AddTask = function(newTask) {
    if (this.allTask[newTask.id] == null) {
        this.allTask[newTask.id] = newTask;
        //Force check for all  tasks.
        if (this.interval == null) {
            this.CheckAllTask();
        } //Else the next loop by setInterval, will launch the task
    } else {

        // If the task is already in the array : we just do a run, or we do a 'getlastrun' (when we open a bundle containing some simulations already launched)
        // so if we do some modifications, we must call again the run fct
        this.allTask[newTask.id] = newTask;
        //Force check for all  tasks..
        if (this.interval == null) {
            this.CheckAllTask();
        } //Else the next loop by setInterval, will launch the task
    }
}


TaskManager_CLS.prototype.RemoveTask = function(id) {
    if (this.allTask[id] != null) {
        delete this.allTask[id];
    }
}

/*
Loop on the array list of run
Check if the run is ended or not, if not call again this fct thanks to setInterval
If the run ended, remove the id of this simu from the array of cimu currently running
*/
TaskManager_CLS.prototype.CheckAllTask = function() {
    var hasTaskRunning = false;
    // Loop on all tasks and check if they are complete
    for (var id in this.allTask) {
        if (this.allTask.hasOwnProperty(id)) {
            if (this.allTask[id] != null && this.allTask[id].isFinished != undefined && this.allTask[id].isFinished() == false) {
                hasTaskRunning = true;
                this.allTask[id].Check();
            } else {


                // if a simu is finished running, we loop on the list of simu currently running, and we delete the id of this simu
                for (var element in this.simRunning) {
                    if (this.simRunning.hasOwnProperty(element)) {
                        if (id == this.simRunning[element]) {
                            if ((this.simRunning[element] != null || this.simRunning[element] != undefined) && this.allTask[id].isFinished != undefined && this.allTask[id].isFinished() == true) {

                                this.simRunning.splice(element, 1);
                            }
                        }

                    }
                }
            }
        }
    }
    if (hasTaskRunning == false && this.interval != null) {
        clearInterval(this.interval);
        this.interval = null;
    } else if (hasTaskRunning == true && this.interval == null) {
        //Check all every 2 secondes. It is the task itself which manage if it is necessary to launch again the Everest run request or not
        this.interval = setInterval(this.CheckAllTask.bind(this), 2000);
    }
}

export default TaskManager_CLS;
