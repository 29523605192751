import Cavi2Util from "./helpers/cavi.util.js";

var Attributs_CLS = function(objParent, timescales, params, UI) {
    this.ui = UI;
    //
    this.boolHaveChange = true;

    this.contentFileJSON = this.ui.accessContentJSON;

    this.params = params; //Options, mode accordeon, edit, view, et données qui vont avec pour que tout soit en param...
    this.objParent = objParent; //Obj recup de Everest.
    this.timescales = timescales; //Obj recup de Everest.
    this.childs = []; //Liste des elt (metadata)
    this.defaultVal = []; //Liste des valeurs par defaut
    this.changeVal = []; //Liste des changements de valeurs.
    this.mainSlider = null;
    this.mainSliderCurrentVal = 0;
    this.idChangeVal = undefined;
    this.boolChangeCorrec = false;

    // largeur % des sliders
    this.widthSlide = "auto";

    //tableau qui stocke les attributs que l'on veut exporter
    this.tabExportAttr = [];
    //stocke l'id de l'input que l'on a modifié la valeur
    this.nameTxtBox = "";
    //booleen pour ajouter les checkbox de selection pour export
    //si true on se trouve dans le panel Initiative
    this.boolAddCheckExport = false;

   // this.boolIsInitiativeValue = false;

    this.isIniChanged = false;

    this.tree; //Pour le mode "Tree", sinon false.
    this.GeneriqueDefaultTxt = [];
    this.GeneriqueDefaultNum = [];
    if (this.params.isReadOnly == undefined) {
        this.params.isReadOnly = false;
    }

    this.AddDiv = function() {
        var txtRow = "";
        var txtCheck = "";

        if (this.params.mode == "Accord") {
            txtRow += "     <div class='box-header panel-heading' id='header_" + this.params.uid + "_" + this.objParent.code + "' style='padding: 4px;'>";
            txtRow += "         <h4 class='box-title' title='" + this.objParent.description + "'>";
            if (this.ui.classBindData.isFirstInitiative == true) {
                txtRow += "             <a id='acollapse_" + this.params.uid + "_" + this.objParent.code + "' data-toggle='collapse' data-parent='#" + this.params.idParent + "' href='#collapse_" + this.params.uid + "_" + this.objParent.code + "' ";
                txtRow += "             aria-expanded='true' >&nbsp;&nbsp; " + this.objParent.displayName + "</a>";
            } else {
                txtRow += "             <a id='acollapse_" + this.params.uid + "_" + this.objParent.code + "' data-toggle='collapse' data-parent='#" + this.params.idParent + "' href='#collapse_" + this.params.uid + "_" + this.objParent.code + "' ";
                txtRow += "             aria-expanded='false' class='collapsed'>&nbsp;&nbsp; " + this.objParent.displayName + "</a>";
            }

            txtRow += "         </h4>";
            txtRow += "     </div>";
            $('#' + this.params.gestionAttrib).append("<li class='panel_" + this.params.uid + "_" + this.objParent.code + "'>" + txtRow + "</li>");
            txtRow = "<div class='panel box box-primary itAcc panel_" + this.params.uid + "_" + this.objParent.code + "' style='border:0'>";

            if (this.ui.classBindData.isFirstInitiative == true) {
                txtRow += "     <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "' class='panel-collapse collapse in use-skin-border' aria-expanded='true'>";
            } else {
                txtRow += "     <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "' class='panel-collapse collapse use-skin-border' aria-expanded='false' style='height: 0px;'>";
            }

            txtRow += "         <div class='changeW box-body'>";


            if (this.params.uid == "ini") {
                var hasNode = false;
                txtRow += "         <div class='nav-tabs-custom' style='width:100%;'>";
                txtRow += "             <ul class='nav nav-tabs' id='nav_" + this.params.uid + "_" + this.objParent.code + "'>";
                txtRow += "                 <li" + (hasNode == false ? " class='active'" : "") + "><a href='#collapse_" + this.params.uid + "_" + this.objParent.code + "_variant' data-toggle='tab'>" + this.contentFileJSON.MENU_LABEL_OTHER + "</a></li>";
                hasNode = true;
                txtRow += "                 <li" + (hasNode == false ? " class='active'" : "") + "><a href='#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_constant' data-toggle='tab'>" + this.contentFileJSON.MENU_LABEL_CONSTANT + "</a></li>";
                txtRow += "             </ul>";
                txtRow += "             <div class='tab-content' style='border-width:3px;border-color:black;'>";

                txtRow += "                 <div class='tab-pane" + (hasNode == false ? " active" : "") + " styleShadowBox' id='collapse_" + this.params.uid + "_" + this.objParent.code + "_content_constant' ></div>";
                hasNode = false;
                txtRow += "                 <div class='tab-pane" + (hasNode == false ? " active" : "") + "' id='collapse_" + this.params.uid + "_" + this.objParent.code + "_variant'>";
                txtRow += "                     <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "_slider' class='sliderYear' style='width:" + this.widthSlide + ";'></div>";
                txtRow += "                     <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "_content_variant'class='styleShadowBox' ></div>";
                txtRow += "                 </div>";
                txtRow += "             </div>";
                txtRow += "         </div>";
            } else {
                txtRow += "         <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "_slider' class='sliderYear' style='width:" + this.widthSlide + ";'></div>";
                txtRow += "         <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "_content'></div>";
            }
            txtRow += "         </div>";
            txtRow += "     </div>";
            txtRow += "</div>";
            // var position_data_top = $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").position().top;
            //txtCheck += "<div class='css-check' ><input type='checkbox' id='box_'" + this.params.uid + "_" + this.objParent.code + "' /><label for='box_'" + this.params.uid + "_" + this.objParent.code + "' </label></div>";
        } else if (this.params.mode == "Tree") {
            txtRow = "<div class='panel box panel_" + this.params.uid + "_" + this.objParent.code + "' style='border:0'>";
            txtRow += "     <div class='box-body'>";
            txtRow += "         <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "_slider' class='sliderYear' style='width:" + this.widthSlide + ";'></div>";
            txtRow += "         <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "_content' style='text-align:left;'></div>";
            txtRow += "         <ul id='tree_" + this.params.uid + "_" + this.objParent.code + "'  class='ztree'></ul>";
            txtRow += "         <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "_content' style='text-align:left;'></div>";
            txtRow += "     </div>";
            txtRow += "</div>";
        } else {
            txtRow = "<div class='panel box panel_" + this.params.uid + "_" + this.objParent.code + "' style='border:0'>";
            txtRow += "     <div class='box-body'>";
            txtRow += "         <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "_slider' class='sliderYear' style='width:" + this.widthSlide + ";'></div>";
            txtRow += "         <div id='collapse_" + this.params.uid + "_" + this.objParent.code + "_content' style='text-align:left;'></div>";
            txtRow += "     </div>";
            txtRow += "</div>";
        }



        $('#' + this.params.idParent).append(txtRow);
        //  $('#list_checkbox_accordion_initiatives').append(txtCheck);

        //Rajout du slider en fct du timescale du premier item
        var timescalesItem = null;
        if (this.timescales != null) {
            for (var i in this.timescales) {
                if (this.timescales.hasOwnProperty(i)) {
                    if (this.timescales[i].code == "Y") { //item.timescale) {
                        timescalesItem = this.timescales[i];
                    }
                }
            }
        }

        if (timescalesItem != null) {
            this.childs["slider_" + this.params.uid + "_" + this.objParent.code] = timescalesItem;

            txtRow = "     <input id='slider_" + this.params.uid + "_" + this.objParent.code + "'  readonly='' value='' style='display:none'/><hr/>";
            $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_slider").append(txtRow);


            // creates annual slider for each initiative
            $("#slider_" + this.params.uid + "_" + this.objParent.code).ionRangeSlider({
                type: "single",
                values: timescalesItem.steps,
                grid: true,
                //  force_edges: true,        // décalage du slider sur les bords
                onFinish: this.Slider_OnChange.bind(this),
                //  onStart: this.Slider_OnChange.bind(this)
            });

            for (var i = 0; i < timescalesItem.steps.length; i++) {
                this.GeneriqueDefaultTxt.push("");
                this.GeneriqueDefaultNum.push(0);
            }
            this.mainSlider = $("#slider_" + this.params.uid + "_" + this.objParent.code).data("ionRangeSlider");

        }
        if (this.params.mode == "Accord") {
            $('.panel_' + this.params.uid + "_" + this.objParent.code).hide();
        } else if (this.params.mode == "Tree") {
            this.initTree();
            this.mainSlider.update({
                from: 0
            });
            this.Slider_OnChange({
                from: 0
            });
        }
    }



    this.addDiyDom = function(treeId, treeNode) {
        if (treeNode.isParent == false) {
            var cv = this.mainSliderCurrentVal;
            var id = treeNode.id;
            var newVal;
            if (this.defaultVal[id] != null) {
                newVal = this.defaultVal[id][cv];
            } else if (this.ui.classBindData.changeValueTarget == [] || this.ui.classBindData.changeValueTarget[id] == undefined || this.ui.classBindData.changeValueTarget[id][cv] == undefined) {
                newVal = "";
            } else {
                newVal = this.ui.classBindData.changeValueTarget[id][cv];
            }



            var aObj = $("#" + treeNode.tId);
            //var editStr = " - <span><input type='text' id='item_" + this.params.uid + "_" + treeNode.id + "' " + (this.params.isReadOnly != undefined ? "disabled='true'" : "") + "/></span>";
            //var editStr = " - <span><input type='text' id='item_" + this.params.uid + "_" + treeNode.id + "' disabled='true' /> " + (treeNode.obj != undefined && treeNode.obj.unit != undefined ? treeNode.obj.unit : "") + "</span>";
            var editStr = " - <span id='span_" + treeNode.tId + "' ><input type='text' id='item_" + this.params.uid + "_" + treeNode.id + "' " + (this.params.isReadOnly != undefined ? "disabled='true'" : "") + "/> " + (treeNode.obj != undefined && treeNode.obj.unit != undefined ? treeNode.obj.unit : "") + "</span>";
            aObj.append(editStr);
            $('#item_' + this.params.uid + "_" + treeNode.id).change(function() {
                this.isIniChanged = false;
                this.idChangeVal = '#item_' + this.params.uid + "_" + treeNode.id;
                this.UpdateVal(treeNode.id, $(this.idChangeVal).val());

            }.bind(this));
            //Force l'init de la valeur
            this.idChangeVal = '#item_' + this.params.uid + "_" + treeNode.id;
            $(this.idChangeVal).val(newVal);
            this.UpdateVal(treeNode.id, $(this.idChangeVal).val());
        }
    };
    this.onCheck = function(event, treeId, treeNode) {
        if (this.params.isReadOnly == false) {
            var allNodes = [];
            if (treeNode.isParent == true) {
                allNodes = treeNode.children;
            } else {
                allNodes.push(treeNode);
            }
            for (var i = 0; i < allNodes.length; i++) {
                if (treeNode.checked == true) {
                    $('#item_' + this.params.uid + "_" + allNodes[i].id).prop("disabled", false);
                    //$('#item_' + this.params.uid + "_" + allNodes[i].id).css('visibility', 'visible');
                } else {
                    $('#item_' + this.params.uid + "_" + allNodes[i].id).prop("disabled", true);
                    //$('#item_' + this.params.uid + "_" + allNodes[i].id).css('visibility', 'hidden');
                }
            }
        }
    };



    this.initTree = function() {
        if (this.tree != undefined) {
            $.fn.zTree.destroy("tree_" + this.params.uid + "_" + this.objParent.code);
        }

        var zNodes = [];
        var nTmp = [];
        var pStr;
        var obj;
        var indice = -1;


        var settings = {
            view: {
                dblClickExpand: true,
                showLine: true,
                selectedMulti: false,
                addDiyDom: this.addDiyDom.bind(this)
            },
            check: {
                enable: true,
                chkboxType: {
                    "Y": "ps",
                    "N": "ps"
                }
            },
            data: {
                simpleData: {
                    enable: true,
                    idKey: "id",
                    pIdKey: "pId",
                    rootPId: ""
                }
            },
            callback: {
                onCheck: this.onCheck.bind(this)
            }
        }

        this.tree = $.fn.zTree.init($("#tree_" + this.params.uid + "_" + this.objParent.code), settings, zNodes);
        $("#tree_" + this.params.uid + "_" + this.objParent.code + "_filter").keyup(function() {
            if (this.tree != undefined) {
                var value = $.trim($("#tree_" + this.params.uid + "_" + this.objParent.code + "_filter").val()).toLowerCase();
                //Recoche tout
                nodes = this.tree.getNodesByParam("isHidden", true);
                this.tree.showNodes(nodes);

                if (value != "") {
                    nodeList = this.tree.getNodesByFilter(function filter(node) {
                        return !node.isParent && node.name.toLowerCase().indexOf(value) == -1;
                    });

                    //Masque les items.
                    this.tree.hideNodes(nodeList);
                }
            }

        }.bind(this));
    };

    this.Reset = function() {
        if (this.objParent != null) {
            $('.panel_' + this.params.uid + "_" + this.objParent.code).hide();
            if (this.params.uid == "ini") {
                $('#collapse_' + this.params.uid + "_" + this.objParent.code + "_content_constant").empty();
                $('#collapse_' + this.params.uid + "_" + this.objParent.code + "_content_variant").empty();
            } else {
                $('#collapse_' + this.params.uid + "_" + this.objParent.code + "_content").empty();
            }
            this.childs = []; //Liste des elt (metadata)
            this.defaultVal = []; //Liste des valeurs par defaut
            this.changeVal = []; //Liste des changements de valeurs.
            this.ui.classBindData.changeValCopy = [];
        }
        if (this.tree != undefined) {
            this.initTree();
        }
    }

    // Fct appelée lorsuqe l'on bouge le slider des années
    this.Slider_OnChange = function(data) {

        if ((this.nameTxtBox.indexOf("trgt") > -1) && this.boolChangeCorrec) {
            var nodes = ztree.getNodes();
            for (var t in nodes) {
                if (nodes.hasOwnProperty(t)) {
                    nodes[t].chkDisabled = false;
                    ztree.checkNode(nodes[t], true);
                }
            }
        }
        if (this.mainSlider != null && this.mainSlider != undefined && this.mainSlider.input.id == "slider_trgt_ro_TargetList_Edit_it") {
            var treeAttr = $.fn.zTree.getZTreeObj("tree_trgt_ro_TargetList_Edit_it");

            var allNodes = treeAttr.getNodes();
            var tableauValueDefault = [];
            var tableauValueModif = [];
            tableauValueModif = this.ui.classBindData.changeValueTarget;
            tableauValueDefault = this.defaultVal; //contain KPI changed and saved and its values
            //parcourir les nodes
            // check si valeur du champs txt différent de ce qu il y a de base en valeur par defaut
            // si oui on coche les cases et on met en disable (comme c'est deja le cas)
            for (var element in allNodes) {
                if (allNodes.hasOwnProperty(element)) {
                    //on met tout à enable pour pouvoir tout décocher et ensuite faire le traitement
                    treeAttr.setChkDisabled(allNodes[element], false, true, true);
                    treeAttr.checkAllNodes(false);
                    for (var el in allNodes[element].children) { // parcours la liste des enfants de chaque nodes

                        if (allNodes[element].children.hasOwnProperty(el)) {
                            $("#span_" + allNodes[element].children[el].tId).css("visibility", "hidden");
                            for (var itemKPIModif in tableauValueModif) { // parcours tableau qui contient la liste des kpi dont les valeurs ont été modifiées

                                if (tableauValueModif.hasOwnProperty(itemKPIModif) && itemKPIModif == allNodes[element].children[el].id) { // on test si le node enfant correspond à un element du tableau
                                    if (tableauValueDefault[itemKPIModif] != undefined && (tableauValueModif[itemKPIModif] != tableauValueDefault[itemKPIModif])) {
                                        if (tableauValueDefault[itemKPIModif][data.from] != tableauValueModif[itemKPIModif][data.from]) {

                                            treeAttr.checkNode(allNodes[element], true, false);
                                            treeAttr.checkNode(allNodes[element].children[el], true, true);
                                            treeAttr.setChkDisabled(allNodes[element].children[el], true, false, true);
                                            $("#span_" + allNodes[element].children[el].tId).css("visibility", "visible");
                                        } else {
                                            treeAttr.checkNode(allNodes[element].children[el], false, false);
                                            treeAttr.setChkDisabled(allNodes[element].children[el], true, false, true);
                                        }

                                    }
                                }
                            }
                        }
                    }
                    treeAttr.setChkDisabled(allNodes[element], true, false, true);
                }
            }
            this.mainSliderCurrentVal = data.from;
            this.InitValues(true);
        } else if (this.mainSlider != null && this.mainSlider != undefined && this.mainSlider.input.id == "slider_trgt_Edit_Target_it" && this.ui.classBindData.resetValueKPI_tgt_SU == true) {
            this.mainSliderCurrentVal = data.from;
            this.InitValues(true);
        } else {
            this.mainSliderCurrentVal = data.from;
            this.InitValues(false);
        }
        ////////////////
        //   this.mainSliderCurrentVal = data.from;
        //  this.InitValues(false);
        ///////////////

        //  this.InitAllValues(this.mainSliderCurrentVal);
    };


    /** Fct mainly used for rounding input text values numeric and updating input values
    called by : initValues()
                UpdateVal() : just for testing numeric values in this case

    */
    this.roundValues = function(item, newVal, id, tabMeta) {

        // de-coupling the div id and attribute name
        var divId = Cavi2Util.replaceChars(id);

        if (item.type == "NUM") {
            var unite = item.unit;
            if (item.numericValueMin == 0 && item.numericValueMax == 0) {

                //on fait le traitement des valeurs arrondies
                var valueMeta = tabMeta["Decimal"];
                if (valueMeta == undefined) { //non trouve
                    valueMeta = 0; //val pare defaut
                }

                valueMeta = parseFloat(valueMeta);
                if (newVal != "" && isNaN(valueMeta) == false) {
                    if ($.isNumeric(newVal)) {
                        if (valueMeta > 0) {
                            // si ce qui renseigné est DECIMAL
                            if (newVal % 1 != 0) {

                                newVal = newVal.toString();
                                var sub = newVal.slice(newVal.indexOf('.') + 1, newVal.length);
                                if (sub.length < valueMeta) {
                                    var nbZeroAdd = "";
                                    for (var i = sub.length; i < valueMeta; i++) {
                                        nbZeroAdd += "0";
                                    }

                                    if (newVal.indexOf('.') == -1) {
                                        $("#item_" + this.params.uid + "_" + divId).val(newVal + "." + nbZeroAdd);
                                    } else {
                                        $("#item_" + this.params.uid + "_" + divId).val(newVal + nbZeroAdd);
                                    }

                                } else if (sub.length > valueMeta) {
                                    $("#item_" + this.params.uid + "_" + divId).val(parseFloat(newVal).toFixed(valueMeta));
                                } else {
                                    newVal = parseFloat(newVal);
                                    $("#item_" + this.params.uid + "_" + divId).val(newVal);
                                }
                            } else { // ENTIER
                                newVal = parseFloat(newVal);
                                //entier donc on doit compléter de 0 nb fois renvoyé par metadatas
                                var nbZeroAdd = "";
                                for (var i = 0; i < valueMeta; i++) {
                                    nbZeroAdd += "0";
                                }
                                $("#item_" + this.params.uid + "_" + divId).val(newVal + "." + nbZeroAdd);
                            }
                        } else if (valueMeta == 0) {
                            //on arrondi la valeur 1.2->1     1.7->2
                            newVal = Math.round(newVal);
                            $("#item_" + this.params.uid + "_" + divId).val(newVal);
                        }
                    } else {
                        $("#item_" + this.params.uid + "_" + divId).val(newVal);
                    }
                } else {
                    $("#item_" + this.params.uid + "_" + divId).val(newVal);
                }

            } else {

                if (tabMeta["selectableoptions"] != undefined && tabMeta["selectableoptionsdisplay"] != undefined) {
                    $("#item_" + this.params.uid + "_" + divId).val(newVal.toString());
                } else {
                    //Updates slider
                    // attribut slider or year slider
                    var slider = $("#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId).data("ionRangeSlider");
                    if (slider != undefined) {
                        slider.update({
                            from: newVal
                        });
                    }
                }

            }
        } else if (item.type == "BOOL") {
            // var tabMeta = this.MetadataToObj(item.metadatas);
            if (tabMeta["selectableoptions"] == undefined && tabMeta["selectableoptionsdisplay"] == undefined) {
                $("#item_" + this.params.uid + "_" + divId).prop('checked', newVal);
            } else {
                $("#item_" + this.params.uid + "_" + divId).val(newVal.toString());
            }

        } else {

            $("#item_" + this.params.uid + "_" + divId).val(newVal);
        }
    };

    this.InitValues = function(IsResetValue) {
        var cv = this.mainSliderCurrentVal;
        var visibilite = $("#box_TargetList_Edit").is(':visible');


        for (var id in this.childs) {
            if (this.childs.hasOwnProperty(id)) {
                var newVal = null;
                var item = this.childs[id];
                var tabMeta = this.MetadataToObj(item.metadatas);

                cv = (item.timescale == "Y") ? this.mainSliderCurrentVal : 0; //TODO : Premier item si c'est pas du Y, voir comment gerer tous les cas.




                if (visibilite == true && this.ui.classBindData.changeValueTarget[id] != undefined && this.ui.classBindData.changeValueTarget[id][cv] != undefined) {
                    newVal = this.ui.classBindData.changeValueTarget[id][cv];
                /*
                    if (id.indexOf("Neighborhood_") > -1 || id.indexOf("Building_") > -1) {
                        this.boolIsInitiativeValue = false;
                    } else {
                        this.boolIsInitiativeValue = true;
                    }
               */
                } else if (visibilite == false && this.ui.classBindData.changeValCopy[id] != undefined && this.ui.classBindData.changeValCopy[id][cv] != undefined && IsResetValue == false) {
                    // newVal = this.changeVal[id][cv];
                    newVal = this.ui.classBindData.changeValCopy[id][cv];

                    /*if (id.indexOf("Neighborhood_") > -1 || id.indexOf("Building_") > -1) {
                        this.boolIsInitiativeValue = false;
                    } else {
                        this.boolIsInitiativeValue = true;
                    }*/

                } else if (this.params.mode == "Tree") {
                    newVal = "";
                    if (IsResetValue == true) {
                        this.UpdateVal(id, newVal);
                    }

                }
                if (this.defaultVal[id] != undefined && this.defaultVal[id][cv] != undefined) {
                    //si id contient building ou neighborhood, on est dans les valeurs de targets, sinon c'est les initiatives
                    //si dans initiatives on prend en priorité le contenu de changeValCopy et pas defaltVal(l'inverse de ce que l'on fait pour les targets)
                    // if (this.boolIsInitiativeValue == false) {
                    if (this.childs[id].IsInitiative == false) {
                        //si reset prendre la valeurs par defaut
                        newVal = this.defaultVal[id][cv];
                        if (IsResetValue == true) {
                            this.UpdateVal(id, newVal);
                        }
                    } else {
                        if (this.ui.classBindData.changeValCopy[id] == undefined) {
                            newVal = this.defaultVal[id][cv];
                            if (IsResetValue == true) {
                                this.UpdateVal(id, newVal);
                            }
                        }
                    }
                }
                if (newVal != null) {
                    this.roundValues(item, newVal, id, tabMeta);
                }



            }
        }
        if (IsResetValue == true) {
            this.changeVal = []; //Liste des changements de valeurs.
            this.ui.classBindData.changeValCopy = [];
        }
    }

    this.InitAllValues = function(val) {
        console.log(this.mainSlider);
        for (var i = 0; i < this.mainSlider.options.p_values.length; i++) {
            this.mainSliderCurrentVal = i;
            this.InitValues(true);
        }

        this.InitValues(true);
        if (val != undefined) {
            this.mainSlider.update({
                from: 0
            });
            this.Slider_OnChange({
                from: 0
            });
        }
        $(".classBtnSave").prop("disabled", true);
        $(".classBtnReset").prop("disabled", true);
    }

    this.SaveAllValues = function() {
        //Input { "KPI1Name": [<timestep1Target>,<timestep2Target> ...], "KPI2Name": [<timestep1Target>,<timestep2Target> ...], ... }
        var Everest_Modif = {};
        var isEmpty = true;
        if (this.params.mode == "Tree") {
            var nodes = this.tree.getCheckedNodes(true);
            for (var i in nodes) {
                if (nodes.hasOwnProperty(i)) {
                    if (nodes[i].isParent == false) {
                        if (this.changeVal[nodes[i].id] != undefined) {
                            Everest_Modif[nodes[i].id] = this.changeVal[nodes[i].id];
                            isEmpty = false;
                        } else {
                            //Everest_Modif[nodes[i].id] = this.GeneriqueDefault.slice()
                        }
                    }
                }
            }
            if (isEmpty == false) {
                this.params.Fct_save(Everest_Modif);
            }
        } else {
            for (var elem in this.changeVal) {

                if (this.changeVal.hasOwnProperty(elem)) {
                    //var node =
                    //    if node.isChecked
                    Everest_Modif[elem] = this.changeVal[elem];
                }
            }
            if (this.boolChangeCorrec == true)
                this.params.Fct_save(Everest_Modif);
        }
        $(".classBtnSave").prop("disabled", true);
        $(".classBtnReset").prop("disabled", true);
    }

    //Convertit les metadata en tableau associatif. Plus simple d'acces
    this.MetadataToObj = function(tab) {
        var retour = [];
        for (var i in tab) {
            if (tab.hasOwnProperty(i)) {
                retour[tab[i].name] = tab[i].value;
            }
        }
        return retour;
    }
    //Cherche une metadata par sa cle
    this.GetMetadataByName = function(metadatas, name) {
        for (var metadata in metadatas) {
            if (metadatas.hasOwnProperty(metadata)) {
                if (metadatas[metadata].name == name) {
                    return metadatas[metadata].value;
                }
            }
        }

        return undefined;
    }


    this.AddItem = function(id, item, defaultVal, statusPublished, isBuildingInitiative) {

        var divId = Cavi2Util.replaceChars(id);

        var addCheckboxIni = isBuildingInitiative;
        var status;
        if (statusPublished != null && statusPublished != undefined)
            status = statusPublished;
        if (this.params.mode == "Tree") {
            this.childs = id;
            this.defaultVal = defaultVal; //Garde de coté toutes les valeurs par défaut
            var newNodes = this.tree.addNodes(null, item, true);

            ////Rajout des bt Run/Dir
            //for (var i in newNodes) {
            //    if (newNodes[i].isParent == false) {
            //        if ($("item_" + this.params.uid + "_" + newNodes[i].id).length > 0) {
            //        } else {
            //            this.addDiyDom  (i, newNodes[i]);
            //            //var aObj = $("#" + newNodes[i].tId + "_a");
            //            //var editStr = "<span>Test</span>";//"<input class='form-control' type='text' placeholder='Enter ...' id='item_" + this.params.uid + "_" + newNodes[i].id + "' " + (this.params.isReadOnly != undefined ? "disabled='true'" : "") + "/>";
            //            //aObj.append(editStr);
            //            //$('#item_' + this.params.uid + "_" + newNodes[i].id).change(function () {
            //            //    this.idChangeVal = '#item_' + this.params.uid + "_" + newNodes[i].id;
            //            //    this.UpdateVal(newNodes[i].id, $(this.idChangeVal).val());

            //            //}.bind(this));
            //        }
            //    }
            //}



            $('.panel_' + this.params.uid + "_" + this.objParent.code).show();
        } else {
            //TODO Rajouter les event onchange sur checkbox que l'on doit traiter à part
            //Ecrire dans le tableau changeVal

            var eventGenerique = true;
            this.defaultVal[id] = defaultVal; //Garde de coté toutes les valeurs par défaut
            this.childs[id] = item;
            var txtRow = '';
            var nameIni = item.initiative;
            if (addCheckboxIni) {
                txtRow = '<label class="checkPerso" style="text-align:left;font-weight: normal;">';
            } else {
                txtRow = '<label style="text-align:left;font-weight: normal;">';
            }

            var tabMeta = this.MetadataToObj(item.metadatas);
            //Liste déroulante
            if ((tabMeta["selectableoptions"] != undefined && tabMeta["selectableoptions"] != null) || (tabMeta["selectableoptionsdisplay"] != undefined && tabMeta["selectableoptionsdisplay"] != null)) {

                if (addCheckboxIni) {

                    txtRow += "<p><input  type='checkbox' id='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "' /><label  class='base_label label_" + divId + "' for='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "'>" + item.displayName + "</label></p></label><br/>";


                } else {
                    txtRow += item.displayName + "</label><br/>";

                }
                var tVal = null
                var tDisp = null;

                if (item.type == "BOOL") {
                    tVal = [false, true];
                } else if (tabMeta["selectableoptions"] != undefined && tabMeta["selectableoptions"] != null) {
                    tVal = tabMeta["selectableoptions"].split(';');
                } else {
                    tVal = tabMeta["selectableoptionsdisplay"].split(';');
                }
                if (tabMeta["selectableoptionsdisplay"] != undefined && tabMeta["selectableoptionsdisplay"] != null) {
                    tDisp = tabMeta["selectableoptionsdisplay"].split(';');
                } else {
                    tDisp = tabMeta["selectableoptions"].split(';');
                }
                //Add d'un select...
                txtRow += '<select name="item_' + this.params.uid + '_' + id + '" id="item_' + this.params.uid + '_' + id + '"' + ((this.params.isReadOnly == true ? 'disabled ' : '') || (status == true ? 'disabled ' : '')) + '>';

                for (var i in tVal) {
                    if (tVal.hasOwnProperty(i)) {
                        txtRow += '<option value="' + tVal[i] + '">' + tDisp[i] + '</option>';
                    }
                }
                txtRow += "</select><br/>";


                if (this.params.uid == "ini") {

                    if (item.timescale == "C") {
                        $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_constant").append(txtRow);
                    } else {
                        $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_variant").append(txtRow);
                    }

                } else {
                    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                }
            } else {

                if (item.type == "BOOL") { //Checkbox
                    eventGenerique = false;
                    if (addCheckboxIni) {
                        txtRow += "<input type='checkbox' id='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "' /><label class='base_label label_" + divId + "' for='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "' style='padding-top:15px;'> </label>";

                        txtRow += "</label><input type='checkbox' id='item_" + this.params.uid + "_" + divId + "' " + ((this.params.isReadOnly == true ? "disabled " : "") || (status == true ? "disabled " : "")) + "/> <b>" + item.displayName + "</b><br/>";
                    } else {
                        txtRow += "<input type='checkbox' id='item_" + this.params.uid + "_" + divId + "' " + ((this.params.isReadOnly == true ? "disabled " : "") || (status == true ? "disabled " : "")) + "/> " + item.displayName + "</label><br/>";

                    }

                    if (this.params.uid == "ini") {
                        if (item.timescale == "C") {
                            $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_constant").append(txtRow);
                        } else {
                            $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_variant").append(txtRow);
                        }

                    } else {
                        $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                    }


                    $('#item_' + this.params.uid + "_" + divId).change(function() {
                        //event on checkbox : check/uncheck
                        this.isIniChanged = false;
                        this.UpdateVal(id, $('#item_' + this.params.uid + "_" + divId).is(':checked'));
                    }.bind(this));
                } else {
                    if (addCheckboxIni) {
                        txtRow += "<p><input type='checkbox' id='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "' /><label  class='base_label label_" + divId + "' for='box_item_" + this.params.uid + "_" + nameIni + "_" + divId + "'>" + item.displayName + "</label></p></label><br/>";
                    } else {
                        txtRow += item.displayName + "</label><br/>";
                    }

                    if (this.params.uid == "ini") {

                        if (item.timescale == "C") {
                            $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_constant").append(txtRow);
                        } else {
                            $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_variant").append(txtRow);
                        }

                    } else {
                        $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                    }

                    if (item.type == "NUM") {
                        var numericValueMin = item.numericValueMin;
                        var numericValueMax = item.numericValueMax;
                        var unite = item.unit;
                        if (unite == "-") {
                            unite = "";
                        }
                        if (numericValueMin == 0 && numericValueMax == 0) {

                            //probleme alignement avec <input>
                            txtRow = "            <table><tr><td style='width:60%;'> <input class='form-control' type='text' placeholder='Enter ...' id='item_" + this.params.uid + "_" + divId + "' " + ((this.params.isReadOnly == true ? "disabled " : "") || (status == true ? "disabled " : "")) + "/></td><td style='width:10%;'>&nbsp;</td><td style='text-align:left;'>" + item.unit + "</td></tr></table>";

                            if (this.params.uid == "ini") {

                                if (item.timescale == "C") {
                                    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_constant").append(txtRow);
                                } else {
                                    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_variant").append(txtRow);
                                }

                            } else {
                                $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                            }

                        } else {
                            //Slider
                            eventGenerique = false;


                            txtRow = "   <div id='test_" + id + "' style='width:50%;' > <input id='slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId + "'  readonly='' style='display:none'/></div>";

                            if (this.params.uid == "ini") {

                                if (item.timescale == "C") {
                                    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_constant").append(txtRow);
                                } else {
                                    $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_variant").append(txtRow);
                                }

                            } else {
                                $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                            }

                            /*
                            if (unite == "%") {
                                numericValueMax = numericValueMax * 100;
                            }
                            */


                            //calculate step for the slider for attribut of initiatives
                            // uses the METADATA decimal, to define precision of value
                            var step;
                            var tabMeta = this.MetadataToObj(item.metadatas);
                            var valueMeta = tabMeta["Decimal"];
                            if (valueMeta == undefined) { //if undefined
                                valueMeta = 0; //default val
                            }
                            var newstep = "0.";
                            for (var i = 0; i < valueMeta - 1; i++) {
                                newstep += "0";
                            }
                            newstep += "1";
                            step = parseFloat(newstep);


                            if (unite == "%") {
                                $("#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId).ionRangeSlider({
                                    min: numericValueMin,
                                    max: numericValueMax,
                                    type: 'single',
                                    step: step,
                                    postfix: "",
                                    prettify: false,
                                    grid: true,
                                    //force_edges: true,
                                    disable: (this.params.isReadOnly == true ? true : false),
                                    onFinish: function(data) {
                                        this.isIniChanged = true;
                                        this.nameTxtBox = $("#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId)[0].id;
                                        this.UpdateVal(id, data.from);
                                    }.bind(this),
                                    onUpdate: function(data) {
                                        //this.UpdateVal(id, data.from);
                                    }.bind(this),
                                });
                            } else {
                                $("#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId).ionRangeSlider({
                                    min: numericValueMin,
                                    max: numericValueMax,
                                    type: 'single',
                                    step: step,
                                    postfix: unite,
                                    prettify: false,
                                    grid: true,
                                    //force_edges: true,
                                    disable: (this.params.isReadOnly == true ? true : false),
                                    onFinish: function(data) {
                                        this.isIniChanged = true;
                                        this.nameTxtBox = $("#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId)[0].id;
                                        this.UpdateVal(id, data.from);
                                    }.bind(this),
                                    onUpdate: function(data) {
                                        //this.UpdateVal(id, data.from);
                                    }.bind(this),
                                });
                            }
                            console.log("data of", "#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId,  $("#slider_" + this.params.uid + "_" + this.objParent.code + "_" + divId).data())


                        }
                    } else if (item.type == "STR") {
                        txtRow = "             <input class='form-control' type='text' placeholder='Enter ...' id='item_" + this.params.uid + "_" + divId + "' " + ((this.params.isReadOnly == true ? "disabled " : "") || (status == true ? "disabled " : "")) + "/>";

                        if (this.params.uid == "ini") {

                            if (item.timescale == "C") {
                                $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_constant").append(txtRow);
                            } else {
                                $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content_variant").append(txtRow);
                            }

                        } else {
                            $("#collapse_" + this.params.uid + "_" + this.objParent.code + "_content").append(txtRow);
                        }
                    }
                }
            }
            if (eventGenerique == true) {
                $('#item_' + this.params.uid + "_" + divId).change(function(e) {
                    if (this.params.uid == "ini") {
                        this.isIniChanged = true;
                    } else {
                        this.isIniChanged = false;
                    }
                    this.idChangeVal = '#item_' + this.params.uid + "_" + divId;
                    this.nameTxtBox = $(this.idChangeVal)[0].id;
                    this.UpdateVal(id, $(this.idChangeVal).val());

                }.bind(this));
            }
            /*
            if (eventGenerique == true) {
                $('#item_' + this.params.uid + "_" + divId).on('input', function () {
                    this.idChangeVal = '#item_' + this.params.uid + "_" + id;
                    this.UpdateVal(id, $(this.idChangeVal).val());
                }.bind(this));
            }
            */
            $('.panel_' + this.params.uid + "_" + this.objParent.code).show();

        }
    }
    this.UpdateVal = function(id, newVal) {
        var treeUO = $.fn.zTree.getZTreeObj("tree_URB");
        var nodesSelected = treeUO.getCheckedNodes(true);
        var boolValue = false;
        var item = this.childs[id];
        var idInput = this.nameTxtBox;
        var cv = (item.timescale == "Y") ? this.mainSliderCurrentVal : 0; //TODO : Premier item si c'est pas du Y, voir comment gerer tous les cas.
        //var newVal = $('#item_'+ this.params.uid + "_" + divId).val();

        var tabMeta = this.MetadataToObj(item.metadatas);

        if (item.type == "NUM") {
            //newVal = parseFloat(newVal);
            //var reg = new RegExp('^[0-9]+$');
            try {
                if (newVal == "")
                    newVal = 0;
                //on parse l'entrée txtbox : de base renvoie tjs un string
                //si le résultat est un numérique on lance les traitements sinon message erreur

                var reg = new RegExp(',', 'g');

                if (reg.test(newVal)) {
                    newVal = newVal.replace(reg, '.');
                }
                newVal = parseFloat(newVal);
                if ($.isNumeric(newVal)) {

                    var valueMeta = tabMeta["Decimal"];
                    if (valueMeta == undefined) { //non trouve
                        valueMeta = 0; //val pare defaut
                    }

                    if (valueMeta != undefined) {
                        this.roundValues(item, newVal, id, tabMeta);
                    } else {

                        var catBtnBox = this.idChangeVal;
                        if (catBtnBox.indexOf("ctx") > -1) {
                            document.getElementById("Edit_Context_save").disabled = false;
                            document.getElementById("Edit_Context_Reset").disabled = false;

                        } else if (catBtnBox.indexOf("trgt") > -1) {

                            document.getElementById("Edit_Target_save").disabled = false;
                            document.getElementById("Edit_Target_Reset").disabled = false;
                        } else if (catBtnBox.indexOf("Initiatives") > -1) {
                            document.getElementById("gestion_Initiatives_Reset").disabled = false;
                            document.getElementById("gestion_Initiatives_save").disabled = false;
                        }

                    }


                    this.update(id, cv, newVal, item.timescale);
                    this.boolChangeCorrec = true;
                    $(".classBtnSave").prop("disabled", false);
                    $(".classBtnReset").prop("disabled", false);



                } else {
                    if (this.boolHaveChange == true) {
                        this.ui.createAlert("alert", "error", this.contentFileJSON.MENU_LABEL_INITIATIVE_ERROR_TYPE + this.contentFileJSON.MENU_LABEL_INITIATIVE_ERROR_TYPE_NUM);
                        $(this.idChangeVal).val((this.defaultVal != undefined && this.defaultVal[id] != undefined ? this.defaultVal[id][cv] : ""))
                        this.boolChangeCorrec = false;
                        return -1;
                    }

                }

            } catch (err) {

            }

            /*
            if (!reg.test(newVal.toString())) {
                this.ui.createAlert("alert", "error", this.contentFileJSON.MENU_LABEL_INITIATIVE_ERROR_TYPE + this.contentFileJSON.MENU_LABEL_INITIATIVE_ERROR_TYPE_NUM);
                $(this.idChangeVal).val((this.defaultVal!=undefined && this.defaultVal[id]!=undefined?this.defaultVal[id][cv]:""))
                this.boolChangeCorrec = false;
            } else {
                this.update(id, cv, newVal);
                this.boolChangeCorrec = true;
               // this.ui.createAlert("alert", "success", this.contentFileJSON.MENU_LABEL_MAJ_DATA);
            }*/
        } else if (item.type == "BOOL") {
            newVal = (newVal == false || newVal == "false" ? false : true);
            $(".classBtnSave").prop("disabled", false);
            $(".classBtnReset").prop("disabled", false);
            this.boolChangeCorrec = true;
            this.update(id, cv, newVal, item.timescale);
        } else if (item.type == "STR") {
            var tabMeta = this.MetadataToObj(item.metadatas);
            // check if we've selected a dropdown menu
            // if not : we use a regex for testing input content
            // else just update value
            if (tabMeta["selectableoptions"] == undefined) {
                var reg = new RegExp(this.ui.classBindData.contentRegExSTR);

                // if the regex fails : open error box and reset value in input text

                if (!reg.test(newVal) && tabMeta["selectableoptions"] != undefined) {
                    if (this.boolHaveChange == true) {
                        this.ui.createAlert("alert", "error", this.contentFileJSON.MENU_LABEL_INITIATIVE_ERROR_TYPE + this.contentFileJSON.MENU_LABEL_INITIATIVE_ERROR_TYPE_STR);
                        $(this.idChangeVal).val((this.defaultVal != undefined && this.defaultVal[id] != undefined ? this.defaultVal[id][cv] : ""));
                        this.boolChangeCorrec = false;
                        return -1;
                    }

                } else {
                    this.update(id, cv, newVal, item.timescale);
                    this.boolChangeCorrec = true;
                    $(".classBtnSave").prop("disabled", false);
                    $(".classBtnReset").prop("disabled", false);
                }
            } else {
                this.update(id, cv, newVal, item.timescale);
                this.boolChangeCorrec = true;
                $(".classBtnSave").prop("disabled", false);
                $(".classBtnReset").prop("disabled", false);
            }

        }



        if (this.boolChangeCorrec) {
            for (var item in nodesSelected) {
                if (this.ui.current_skin == "skin-blue") {
                    $(".diyModified_" + nodesSelected[item].id).show();
                    //on affiche seulement quand on est dans les initiative
                    for (var i = 0; i < this.ui.classBindData.copyEverest.UOModified.Initiatives[this.ui.classBindData.idsimu].length; i++) {
                        if (this.ui.classBindData.copyEverest.UOModified.Initiatives[this.ui.classBindData.idsimu][i] == nodesSelected[item].id) {
                            break;
                        }
                    }
                    if (this.ui.classBindData.copyEverest.UOModified.Initiatives[this.ui.classBindData.idsimu].length == i) {
                        this.ui.classBindData.copyEverest.UOModified.Initiatives[this.ui.classBindData.idsimu][this.ui.classBindData.copyEverest.UOModified.Initiatives[this.ui.classBindData.idsimu].length] = nodesSelected[item].id;
                    }

                }

            }
        }

    }

    this.update = function(id, cv, newVal, timescale) {

        var visibilite = $("#box_Edit_Target").is(':visible');


        //defaultVal contains data set by SU and saved
        if (this.defaultVal != undefined && this.defaultVal[id] != undefined && this.defaultVal[id][cv] != undefined) {
            if (this.defaultVal[id][cv] == newVal) {
                if (this.changeVal[id] != undefined) { //On remet la valeur par defaut
                    if (this.isIniChanged == true) {
                        if (timescale == "Y") {
                            for (var i = cv; i <= this.mainSlider.old_to; i++) {
                                this.changeVal[id][i] = newVal;
                                this.ui.classBindData.changeValCopy[id][i] = this.changeVal[id][i];
                            }
                        } else {
                            this.changeVal[id][cv] = newVal;
                            this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                        }
                    } else {
                        this.changeVal[id][cv] = newVal;
                        if (visibilite == true) {
                            this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                        } else {
                            this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                            this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                        }
                    }


                    //Check voir si toutes les valeurs sont identiques
                    if (this.AreEquals(this.changeVal[id], this.defaultVal[id])) {
                        delete this.changeVal[id];
                        delete this.ui.classBindData.changeValCopy[id];
                    }
                } else {
                    //pour avoir tout les KPIS existants en valeur par défaut (de base toutes les valeurs des années sont à 0 )
                    //utilisé pour faire la comparaison entre les KPI qui ont été modifiés + sauvés et leur valeur par défaut, et cocher ceux qui ont été modifié.
                    this.changeVal[id] = this.GeneriqueDefaultNum.slice();
                    this.ui.classBindData.changeValCopy[id] = this.changeVal[id];
                    this.ui.classBindData.changeValueTarget[id] = this.changeVal[id];
                }
            } else {
                if (this.changeVal[id] == undefined) {
                    this.changeVal[id] = this.defaultVal[id].slice();
                    this.ui.classBindData.changeValCopy[id] = this.changeVal[id];

                    if (visibilite == true) {
                        this.ui.classBindData.changeValueTarget[id] = this.changeVal[id];
                    } else {
                        this.ui.classBindData.changeValCopy[id] = this.changeVal[id];
                        this.ui.classBindData.changeValueTarget[id] = this.changeVal[id];
                    }
                }
                if (this.isIniChanged == true) {
                    if (timescale == "Y") {
                        for (var i = cv; i <= this.mainSlider.old_to; i++) {
                            this.changeVal[id][i] = newVal;
                            this.ui.classBindData.changeValCopy[id][i] = this.changeVal[id][cv];
                        }
                    } else {

                        this.changeVal[id][cv] = newVal;
                        this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                    }
                } else {
                    this.changeVal[id][cv] = newVal;
                    this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                    if (visibilite == true) {
                        this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                    } else {
                        this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                        this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                    }

                }
            }
        } else {
            // On n'a pas fait de save value sur ce KPI avant
            // - > Pas de valeur par defaut pour ce champ, on se sert de this.GeneriqueDefault pour init.
            var tree = $.fn.zTree.getZTreeObj("tree_" + this.params.uid + "_" + this.objParent.code);
            var nodes = tree.getNodesByParam("id", id);
            if (this.changeVal[id] == undefined) { // prend le contenu du dossier du KPI sélectionné -> tous les KPI du même dossier
                if (nodes.length > 0 && nodes[0].obj.type == "NUM") {
                    this.changeVal[id] = this.GeneriqueDefaultNum.slice();
                    this.ui.classBindData.changeValCopy[id] = this.changeVal[id];

                    if (visibilite == true) {
                        this.ui.classBindData.changeValueTarget[id] = this.changeVal[id];
                    } else {
                        this.ui.classBindData.changeValCopy[id] = this.changeVal[id];
                        this.ui.classBindData.changeValueTarget[id] = this.changeVal[id];
                    }

                } else {
                    this.changeVal[id] = this.GeneriqueDefaultTxt.slice();
                    this.ui.classBindData.changeValCopy[id] = this.changeVal[id];
                }
            }


            if (this.isIniChanged == true) {
                if (timescale == "Y") {
                    for (var i = cv; i <= this.mainSlider.old_to; i++) {
                        if (nodes.length > 0 && nodes[0].obj.type == "NUM") {
                            this.changeVal[id][i] = parseFloat(newVal);
                            this.ui.classBindData.changeValCopy[id][i] = this.changeVal[id][i];
                        } else {
                            this.changeVal[id][i] = newVal;
                            this.ui.classBindData.changeValCopy[id][i] = this.changeVal[id][i];
                        }
                    }
                } else {
                    if (nodes.length > 0 && nodes[0].obj.type == "NUM") {
                        this.changeVal[id][cv] = parseFloat(newVal);
                        this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                    } else {
                        this.changeVal[id][cv] = newVal;
                        this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                    }
                }
            } else {
                // remplace ancienne valeur par la nouvelle après un 'Save'
                this.changeVal[id][cv] = newVal;
                if (visibilite == true) {
                    this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                } else {
                    this.ui.classBindData.changeValCopy[id][cv] = this.changeVal[id][cv];
                    this.ui.classBindData.changeValueTarget[id][cv] = this.changeVal[id][cv];
                }
            }




        }
    }

    this.Filter = function(value) {
        //Recoche tout
        var tree = $.fn.zTree.getZTreeObj($("tree_" + this.params.uid + "_" + this.objParent.code));
        var nodes = tree.getNodesByParam("isHidden", true);
        tree.showNodes(nodes);

        if (value != "") {
            nodeList = tree.getNodesByFilter(function filter(node) {
                return !node.isParent && node.name.toLowerCase().indexOf(value) == -1;
            });
            //Masque les items.
            tree.hideNodes(nodeList);
        }
    }

    this.AreEquals = function(tab1, tab2) {
        if (tab1.length != tab2.length)
            return false;
        for (var i in tab1) {
            if (tab1.hasOwnProperty(i)) {
                if (tab1[i] != tab2[i])
                    return false;
            }
        }
        return true;
    }


    //Init la div
    this.AddDiv();
}
Attributs_CLS.prototype.constructor = Attributs_CLS;

export default Attributs_CLS;
