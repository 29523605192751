import {
    MARKET_VALUE_LAYERS,
    MARKET_VALUE_TIME_INTERVAL,
    COUNTRY_ABBREV,
} from "../prodec/prodec.constants.js";
import {
    MV_CELL_KPI_FETCH_ABORT,
    MV_CELL_KPI_FETCH_FAIL,
    MV_CELL_KPI_FETCH_SUCCEED,
} from "../prodec/prodec.event.js";

import geoserver from "../prodec/prodec.gs.js";
import { makeChart } from "../prodec/prodec.mv.chart.js";
import handleError from "../errorHandler.js";
import Cavi2Util from "../helpers/cavi.util.js";

import kpisMeta from "../assets/data/market_values/kpis.json";
import turbinesMeta from "../assets/data/market_values/turbines.json";

const { wfs, utils } = geoserver;
const { downloadFile } = Cavi2Util;

const CACHE_CHART_DATA = {
    ref: undefined,
    stats: {},
};

// common WFS request handler
const useWFS = async (url, options = {}) =>
    wfs
        .getFeature(url, { ...(options || {}) })
        .then(utils.respHandler)
        .then(utils.getFeatureProps);

// get reference data via WFS
const getChartRef = async (url, options = {}) => {
    if (CACHE_CHART_DATA.ref) {
        return CACHE_CHART_DATA.ref;
    }
    try {
        const ref = await useWFS(url, {
            typeNames: MARKET_VALUE_LAYERS.REF,
            ...(options || {}),
        });
        CACHE_CHART_DATA.ref = ref;
        return ref;
    } catch (e) {
        // rethrow for centralize handling
        throw e;
    }
};

// get stats data viw WFS
const getChartStats = async (url, options = {}) => {
    const { viewparams, ...others } = options || {};
    const { manufacturer, turbine, hub_height } = viewparams || {};

    // if any of them are undefined, return empty array
    if ([manufacturer, turbine, hub_height].some((item) => !item)) {
        return [];
    }

    // if cache is there, return from cache
    const cacheId = `${manufacturer}-${turbine}-${hub_height}`;
    if (CACHE_CHART_DATA.stats[cacheId]) {
        return CACHE_CHART_DATA.stats[cacheId];
    }

    // if cache is not there, get from server
    try {
        const stats = await useWFS(url, {
            typeNames: MARKET_VALUE_LAYERS.STATS,
            ...(others || {}),
            viewparams: _.map(
                {
                    manufacturer,
                    turbine,
                    hub_height,
                },
                (value, key) => `${key}:${value}`
            ).join(";"),
        });
        CACHE_CHART_DATA.stats[cacheId] = stats;

        return stats;
    } catch (e) {
        // rethrow for centralize handling
        throw e;
    }
};

// high order function to guarantee data will always have same sorted time dimension
const useTimeDimensionNormalize = (interval = MARKET_VALUE_TIME_INTERVAL) => {
    const [start_year, end_year] = interval;
    return (fn) => _.range(start_year, end_year + 1).map((year) => fn(year));
};

const normalizeRef = (data, kpi) => {
    const normalize = useTimeDimensionNormalize();

    return normalize((year) => {
        const found = _.find(data, (x) => x.year === year) || {};
        return {
            year,
            reference: found[kpi] ? found[kpi] : 0,
        };
    });
};

const normalizeStats = (data, kpi) => {
    const normalize = useTimeDimensionNormalize();

    return normalize((year) => {
        const found = _.find(data, (x) => x.year === year && x.kpi === kpi) || {};
        return {
            year,
            percentile_0: found["percentile_0"] ? found["percentile_0"] : null,
            percentile_25: found["percentile_25"] ? found["percentile_25"] : null,
            percentile_75: found["percentile_75"] ? found["percentile_75"] : null,
            percentile_100: found["percentile_100"] ? found["percentile_100"] : null,
        };
    });
};

const normalizeCellValue = (data) => {
    const normalize = useTimeDimensionNormalize();

    return normalize((year) => {
        const found = _.find(data, (x) => x.year === year) || {};
        return {
            year,
            value: found["value"] ? found["value"] : null,
        };
    });
};

const toChartDataProvider = (...args) => _.merge([], ...args);

class MarketValue {
    constructor() {
        // deps
        this._map = undefined;
        this._chart = undefined;
        this._ui = undefined;
        // e.g. FR, DE etc.
        this._countryCode = undefined

        // states
        this.isInit = ko.observable(false);
        this.isFetching = ko.observable(false);
        this.selectedKpi = ko.observable("");
        this.selectedTurbine = ko.observable("");
        this.selectedHubHeight = ko.observable("");

        // props
        this.kpis = ko.observable([]);
        this.turbines = ko.observable([]);
        this.hubHeights = ko.pureComputed(function () {
            const found = this.turbines().find((elem) => elem.name === this.selectedTurbine());
            return found ? found.hub_heights : [];
        }, this);

        // handlers
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.hideSidebar = this.hideSidebar.bind(this);
    }

    init(...deps) {
        if (!this.isInit()) {
            const [map, ui] = deps;

            // used for create error message
            this._ui = ui;

            // used for view params and styles
            this._countryCode = COUNTRY_ABBREV[map._country];

            // setup bs-select
            this.setupSelect();

            // setup map
            this.setupMap(map);

            // setup resize
            this.setupResize();

            // update state
            this.isInit(true);
        }
    }

    setupSelect() {
        // use promise to fake API call, in case future we have a rest end point
        Promise.resolve([kpisMeta, turbinesMeta])
            .then((data) => {
                const [kpis, turbinesByCountry] = data;
                const turbines = turbinesByCountry[this._countryCode];

                this.selectedKpi(kpis[0].name);
                this.selectedTurbine(turbines[0].name);
                this.selectedHubHeight(turbines[0].hub_heights[0]);

                this.kpis(kpis);
                this.turbines(turbines);

                return data;
            })
            .then((data) => {
                // register event for observing bs-select changes
                const [kpis] = data;

                $("#mv-select-kpi")
                    .on("loaded.bs.select", function () {
                        // setup tooltips
                        const $data = $(this).data("selectpicker").selectpicker.current.data;

                        for (let each of $data) {
                            const { element, text } = each;
                            const { description } = kpis.find((x) => x.name === text) || {};
                            $(element).attr({ title: description || "" });
                        }
                    })
                    .on("changed.bs.select", (evt) => {
                        this.selectedKpi(evt.target.value);
                    });

                $("#mv-select-turbine").on("changed.bs.select", (evt) => {
                    this.selectedTurbine(evt.target.value);
                    // disable submit firstly, don't want to have unexpected UI behaviour
                    const btn = $(".mv-submit-btn");
                    btn.prop("disabled", true);

                    // update hub height select options dynamically
                    const select = $("#mv-select-hub-height");
                    const { hub_heights } =
                        this.turbines().find((elem) => elem.name === evt.target.value) || {};
                    const prevHubHeight = this.selectedHubHeight();
                    const nextHubHeight =
                        hub_heights && hub_heights.includes(prevHubHeight)
                            ? prevHubHeight
                            : hub_heights[0];
                    select.val(nextHubHeight);
                    this.selectedHubHeight(nextHubHeight);
                    select.selectpicker("refresh");

                    btn.prop("disabled", false);
                });

                $("#mv-select-hub-height").on("changed.bs.select", (evt) => {
                    this.selectedHubHeight(evt.target.value);
                });
            })
            .catch((error) => this.handleError(error))
            .finally(() => {
                // post refresh all selects
                $(".mv-component .selectpicker").selectpicker("refresh");
                // avoid overlap with the map
                $(".mv-component .dropdown-menu").css({ zIndex: 1001 });
            });
    }

    setupMap(map) {
        const mapId = "mv-map-content";
        const elem = $(`#${mapId}`);

        this._map = map;
        this._map.init(mapId, MARKET_VALUE_TIME_INTERVAL);

        // register data load events
        elem.on(MV_CELL_KPI_FETCH_ABORT, () => {
            console.log(`Event ${MV_CELL_KPI_FETCH_ABORT} is fired`);
        });

        elem.on(MV_CELL_KPI_FETCH_SUCCEED, (evt, data) => {
            if (this._chart) {
                const { dataProvider: prevData } = this._chart;
                const nextData = toChartDataProvider(prevData, normalizeCellValue(data));

                _.merge(this._chart, {
                    dataProvider: nextData,
                });

                this._chart.validateData();
            }
        });

        elem.on(MV_CELL_KPI_FETCH_FAIL, (evt, error) => {
            this.handleError(error, this._ui);
        });
    }

    setupResize() {
        $(".custom-sidebar").resizableSafe({
            handleSelector: ".custom-sidebar-splitter",
            resizeWidthFrom: "left",
            resizeHeight: false,
            onDragEnd: () => {
                if (this._map) {
                    this._map.resize();
                }
            },
        });
    }

    handleSubmit() {
        const { id: kpi, unit } = this.kpis().find((x) => x.name === this.selectedKpi()) || {};
        const { name: turbine, manufacturer } =
            this.turbines().find((x) => x.name === this.selectedTurbine()) || {};

        const viewParams = {
            manufacturer,
            turbine,
            hub_height: this.selectedHubHeight(),
            kpi_name: kpi,
            country_code: this._countryCode,
        };

        // handle map
        this._map.removeLayer();
        this._map.removeLegend();
        this._map.addLayer({
            layers: MARKET_VALUE_LAYERS.MAP,
            styles: `STYLES-MV-${this._countryCode}-${kpi.toUpperCase()}`,
            _viewparams: viewParams,
        });
        this._map.addLegend({ kpi, unit });

        // handle chart
        Promise.all([
            getChartRef(this._map.owsURL),
            getChartStats(this._map.owsURL, { viewparams: viewParams }),
        ])
            .then((data) => {
                const [ref, stats] = data;
                const dataProvider = toChartDataProvider(
                    normalizeRef(ref, kpi),
                    normalizeCellValue([]),
                    normalizeStats(stats, kpi)
                );
                const title = `${this.selectedKpi()} `;
                const subTitle = `turbine: ${this.selectedTurbine()}, hub height: ${this.selectedHubHeight()}m`;

                if (!this._chart) {
                    this._chart = makeChart("mv-chart-content", {
                        dataProvider,
                        titles: [{ text: title }, { text: subTitle }],
                        valueAxes: [
                            {
                                title: unit ? `value (${unit})` : "value",
                            },
                        ],
                    });
                } else {
                    _.merge(this._chart, {
                        dataProvider,
                        titles: [{ text: title }, { text: subTitle }],
                        valueAxes: [
                            {
                                title: unit ? `value (${unit})` : "value",
                            },
                        ],
                    });
                    this._chart.validateData();
                }
            })
            .catch(this.handleError);
    }

    handleExport() {
        const { id: kpi } = this.kpis().find((x) => x.name === this.selectedKpi()) || {};
        const { name: turbine, manufacturer } =
            this.turbines().find((x) => x.name === this.selectedTurbine()) || {};

        const [start_year, end_year] = MARKET_VALUE_TIME_INTERVAL;

        const viewParams = {
            kpi_name: kpi,
            manufacturer,
            turbine,
            hub_height: this.selectedHubHeight(),
            start_year,
            end_year: end_year + 1,
            country_code: this._countryCode,
        };

        this.isFetching(true);

        wfs.getFeature(this._map.owsURL, {
            typeNames: MARKET_VALUE_LAYERS.MAP,
            outputFormat: "csv",
            cql_filter: "value IS NOT NULL",
            propertyName: "id,year,value",
            viewparams: _.map(
                viewParams,
                (value, key) => `${key}:${encodeURIComponent(value)}`
            ).join(";"),
        })
            .then(utils.respHandler)
            .then((content) => {
                downloadFile(
                    content,
                    `${this.selectedKpi()}-${turbine}-${this.selectedHubHeight()}.csv`
                );
            })
            .catch((error) => this.handleError(error))
            .finally(() => this.isFetching(false));
    }

    handleError(error) {
        let err;

        // handle GeoServer Error
        if (Array.isArray(error)) {
            // reform ows exceptions
            err = {
                responseText: _.map(error, (x) =>
                    utils.isOWSError(x) ? `${x.locator}: ${x.text}` : ""
                ).join(","),
            };
        } else {
            // any other kinds of error
            err = error;
        }

        handleError(err, this._ui);
    }

    hideSidebar() {
        $("#maMap").removeClass("sidebar-on");
    }
}

const instance = new MarketValue();

export default instance;
