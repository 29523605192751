import SU_Edit_Context_CLS from "./su_edit_context.js";
import promisify from "./helpers/promise.util.js";

/**
    In SU mode :
    Creates a box with all existed Context
    Defines a right-click menu on this box and event on each element of this menu

*/

var SU_ManageScenarioContext_CLS = function(Everest, UI) {
    this.UI = UI;
    this.Everest = Everest;
    this.EditContext = undefined;
    this.contentFileJSON = this.UI.accessContentJSON;
    this.contentMessageFileJSON = this.UI.accessMessageContentJSON;
    //stores result of List_Scenarios() request
    this.saveData = null;
    this.cptData = 0;

    this.boolAdd = false;
    this.selected = null;

    // if the nextUrl is not null
    // stores other items in saveData
    this.nextUrl = false;

    //Construit la box de gestion des résultats
    //Rajoute la box
    this.createboxSU_ListCtx(function() {});

    // flag to check if current active context published
    this.isCurrentContextPublished = false;


    var rMenu = '<div id="rMenuContext" class="box-body no-padding" >';
    rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_edit_context"><i class="fa fa-edit"></i> ' + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_EDIT + '</a>';
    rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_duplicate_context"><i class="fa fa-copy"></i> ' + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_DUPLICATE + '</a>';
    rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_delete_context"><i class="fa fa-trash-o"></i> ' + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_DELETE + '</a>';
    rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_infos_context"><i class="fa fa-info"></i> ' + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_INFOS + '</a>';
    rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_toogle_publish_context"><i class="fa fa-bars"></i> ' + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_PUBLISH + '</a>';
    rMenu += '  </div>';
    $("#page_centre").prepend(rMenu);
    this.tree = $("#treeContext");
    this.isInit = false;
    this.zNodes = [];
    this.currentCopy = null;
    $("#box_SU_ManageScenarioContext .overlay").show();

    this.init = function() {
        //if (this.Interface_MOSA.selectedId != null && this.Everest.authToken != null) {
        this.UI.classBindData.isChangePubliStateCtx = false;

        if (this.UI.classBindData.authToken != null) {
            if (this.isInit == true) {
                $.fn.zTree.destroy("treeContext");
                this.isInit = false;
            }
            this.zNodes = [];
            this.Everest.List_Scenarios(null, this.initTree.bind(this), this.Everest_callbackError.bind(this));

            //Init le click sur "New context"
            $("#m_new_context").click($.proxy(function() {
                this.NewContext();
            }, this));
            //Menu  : Click Droit
            $("#m_edit_context").on("click", () => {
                $("#rMenuContext").hide();

                const selectedNode = this.tree.getSelectedNodes()[0];
                if (selectedNode) {
                    this.BT_EditContext(selectedNode);
                    // ugly hack to disable Excel import, if no urban objects are selected and panel is open
                    $("#Edit_Context_import").prop("disabled", this.isCurrentContextPublished);
                }
            });

            //Init le click sur "Edit Context Simulation" du menu droit
            $("#m_duplicate_context").click($.proxy(function() {
                $("#rMenuContext").hide();
                $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
                if (this.tree.getSelectedNodes()[0]) {
                    this.NewContext(this.tree.getSelectedNodes()[0]);
                }
            }, this));
            //Init le click sur "Delete Simulation" du menu droit
            $("#m_delete_context").click($.proxy(function() {
                $("#rMenuContext").hide();
                $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
                if (this.tree.getSelectedNodes()[0]) {
                    this.selected = this.tree.getSelectedNodes()[0];

                    this.UI.createConfirmBox(this.contentFileJSON.MENU_LABEL_MESSAGE_DEL_CONTEXT + this.selected.name, this.callbackSuccess_ConfirmDelContext.bind(this));

                }
            }, this));
            //Init le click sur "Infos" du menu droit
            $("#m_infos_context").click($.proxy(function() {
                $("#rMenuContext").hide();
                $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
                if (this.tree.getSelectedNodes()[0]) {
                    var node = this.tree.getSelectedNodes()[0];
                    this.Everest.Read_Scenario_Info(node, node.id, this.callbackSuccessInfos.bind(this), this.Everest_callbackError.bind(this));
                }
            }, this));
            //Init le click sur "Apply context" du menu droit
            $("#m_toogle_publish_context").click($.proxy(function() {
                $("#rMenuContext").hide();
                $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
                if (this.tree.getSelectedNodes()[0]) {
                    var node = this.tree.getSelectedNodes()[0];
                    this.Everest.Read_Scenario_Info(node, node.id, function(data, status, obj) {
                        var param = data;
                        param.published = !data.published;
                        param.id = obj.id;

                        this.Everest.Update_Scenario_Info(param, param.id, param.name, param.description, param.published, (d, s, o) => {
                            // alert("Context " + (o.published == false ? "Unp" : "P") + "ublished.");

                            this.UI.createAlert("notif", "success", "Context " + (o.published == false ? "Unp" : "P") + "ublished.");
                            var aObj = $("#" + node.tId);
                            var checkStatut = $("#status_" + node.tId);
                            checkStatut.remove();
                            aObj.prepend('<i id="status_' + node.tId + '" class="fa fa-' + (o.published == true ? 'unlock-alt" style="color:green' : 'lock" style="color:orange') + '"></i>');

                            // need to sync nodes of the 'treeContextList'
                            let contextListTree = $.fn.zTree.getZTreeObj("treeContextList");
                            let foundNode = contextListTree.getNodeByParam("id", node["id"], null);

                            if (foundNode) {
                                // if node is existed, we update the status of 'published'
                                foundNode["published"] = o.published === true;
                                contextListTree.updateNode(foundNode);
                            } else {
                                // if node is not existed, we add a new node to the tree
                                let newNode = {
                                    id: param.id,
                                    pId: 0,
                                    name: param.name,
                                    isParent: false,
                                    published: o.published === true,
                                    description: param.description
                                };
                                contextListTree.addNodes(null, newNode);
                            }

                            // if the edit panel is open, sync the disable/enable of the panel
                            if($("#box_Edit_Context").is(":visible")) {
                                this.BT_EditContext(node);
                                $("#Edit_Context_import").prop("disabled", o.published);
                            }

                            this.UI.classBindData.isChangePubliStateCtx = true;
                        }, this.Everest_callbackError.bind(this));

                    }.bind(this), this.Everest_callbackError.bind(this));

                }
            }, this));
        }
    }


    /*
        Update list of contexts and call a notif Alert when we delete a context
    */
    this.callbackSuccess_ConfirmDelContext = function(data, status, node) {
        this.Everest.Delete_Scenario(this.selected, this.selected.id, function(data, status, node) {
            this.tree.removeNode(node);
            // alert("Context deleted");
            this.UI.createAlert("notif", "success", this.contentMessageFileJSON.MESSAGE_ALERT_CONTEXT_DEL);

            // we also need to sync the nodes of tree "treeContextList"
            let contextListTree = $.fn.zTree.getZTreeObj("treeContextList");
            let foundNode = contextListTree.getNodeByParam("id", node["id"], null);
            // remove the found node
            if (foundNode) {
                contextListTree.removeNode(foundNode);
            }

        }.bind(this), this.Everest_callbackError.bind(this));
    };



    this.addNewNodeDom = function(nodeNew) {

        var tree = $.fn.zTree.getZTreeObj("treeContextList");
        var nomItem = nodeNew[0].tId;
        var separateur = nomItem.indexOf("_");
        var sub = nomItem.substring(separateur, nomItem.length);
        var newNodes = tree.addNodes(null, nodeNew, true);
        var aObj = $("#" + newNodes[0].tId);
        aObj.prepend('<i id="status_' + newNodes[0].tId + '" class="fa fa-lock" style="color:orange"></i>');

    }


    this.addDiyDom = function(treeId, treeNode) {
        var aObj = $("#" + treeNode.tId);

        if (treeNode.level == 0 && this.boolAdd == false) {

            this.Everest.Read_Scenario_Info(this.saveData.items[this.cptData], this.saveData.items[this.cptData].id, function(d) {
                aObj.prepend('<i id="status_' + treeNode.tId + '" class="fa fa-' + (d.published == true ? 'unlock-alt" style="color:green' : 'lock" style="color:orange') + '"></i>');
            }, this.Everest_callbackError.bind(this));
        }
        this.cptData++;
    };



    /*
        Creates the tree of contexts
    */
    this.initTree = function(data, status, id) {
        this.UI.classBindData.isChangePubliStateCtx = false;
        this.cptData = 0;
        if (this.nextUrl == true) {
            for (var element in data.items) {
                if (data.items.hasOwnProperty(element)) {
                    this.saveData.items.push(data.items[element]);
                }

            }
        } else {
            this.saveData = data;
        }

        for (var i = 0; i < data.items.length; i++) {
            var obj = {
                id: data.items[i].id,
                pId: 0,
                name: data.items[i].name,
                isParent: false,
                description: data.items[i].description
            };
            this.zNodes.push(obj);
        }

        if (data.nextUrl != null) {
            this.nextUrl = true;
            this.Everest.getNextUrl(data.nextUrl, id, this.initTree.bind(this), this.Everest_callbackError.bind(this));
        } else {
            if (this.zNodes.length > 0) {

                var settings = {
                    view: {
                        dblClickExpand: false,
                        showLine: false,
                        selectedMulti: false,
                        addDiyDom: this.addDiyDom.bind(this)
                    },
                    data: {
                        simpleData: {
                            enable: true,
                            idKey: "id",
                            pIdKey: "pId",
                            rootPId: ""
                        }
                    },
                    callback: {
                        onRightClick: this.OnRightClick.bind(this),
                        onDblClick: this.OnDbClick.bind(this)
                    }
                }

                this.tree = $.fn.zTree.init(this.tree, settings, this.zNodes);
                this.isInit = true;
            }
            $("#box_SU_ManageScenarioContext .overlay").hide();
        }
    }

    /*
        Defines the event on rightclick : open the right menu
    */
    this.OnRightClick = function(event, treeId, treeNode) {

        if (!treeNode && event.target.tagName.toLowerCase() != "button" && $(event.target).parents("a").length == 0) {
            this.tree.cancelSelectedNode();
        } else if (treeNode && !treeNode.noR) {
            this.tree.selectNode(treeNode);

            promisify(this.Everest.Read_Scenario_Info(undefined, treeNode.id))
                .then(data => {
                    // update the flag
                    this.isCurrentContextPublished = data["published"];
                    // switch the context menu option "edit" to "view"
                    const menuOption = data["published"] ? this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_VIEW : this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_EDIT;
                    $("#m_edit_context").html(`<i class="fa fa-edit"></i> ${menuOption}`);

                    // show the menu
                    this.showRMenu("node", event.clientX, event.clientY);
                })
                .catch(error => this.Everest_callbackError(error));
        }
    }


    this.OnDbClick = function(event, treeId, treeNode) {
        if (!treeNode) return;

        promisify(this.Everest.Read_Scenario_Info(undefined, treeNode.id))
            .then(data => {
                // update the flag
                this.isCurrentContextPublished = data["published"];

                // switch the context menu option "edit" to "view"
                const menuOption = data["published"] ? this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_VIEW : this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_EDIT;
                $("#m_edit_context").html(`<i class="fa fa-edit"></i> ${menuOption}`);

                // popup the panel
                this.BT_EditContext(treeNode);

                // ugly hack to disable Excel import, if no urban objects are selected and panel is open
                $("#Edit_Context_import").prop("disabled", this.isCurrentContextPublished);

            })
            .catch(error => this.Everest_callbackError(error));
    };

    /*
        Show right menu
    */
    this.showRMenu = function(type, x, y) {
        $("#rMenuContext").stop().css({
            "top": y + "px",
            "left": x + "px"
        }).show("fold");
        $("body").bind("mousedown", this.onBodyMouseDown.bind(this));
    }

    this.onBodyMouseDown = function(event) {
        if (!(event.target.id == "rMenuContext" || $(event.target).parents("#rMenuContext").length > 0)) {
            $("#rMenuContext").hide();
            $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
        }
    }


    /*
        Add new entry in the tree list
    */
    this.Addchild = function(data, status, obj) {
        this.boolAdd = true;
        var tabNode = [];
        for (var i = 0; i < data.items.length; i++) {
            tabNode.push({
                id: data.items[i].id,
                pId: obj.id,
                name: data.items[i].name
            });
        }
        var newNodes = this.tree.addNodes(null, tabNode, true);
        var aObj = $("#" + newNodes[0].tId);
        aObj.prepend('<i id="status_' + newNodes[0].tId + '" class="fa fa-lock" style="color:orange"></i>');

        //this.addNewNodeDom(newNodes);
        if (data.nextUrl != undefined && data.nextUrl != null) { //On charge la suite
            this.Everest.getNextUrl(data.nextUrl, obj, this.callbackSuccessChild.bind(this), this.Everest_callbackError.bind(this));
        }
    }



    /*
        When click on Create Context : call Everest req to create new Context
        Update interface with the AddChild fct
    */
    this.NewContext = function(nodeCopy) {
        //Rajoute la box
        this.createboxSU_NewCtx(nodeCopy, function() {});

        $("#newContext_create").click({
            nodeCopy: nodeCopy
        }, function(event) {
            if ($("#newContext_name").val().length > 0) {

                var reg = new RegExp(this.UI.classBindData.contentRegExLabel);
                if (reg.test($("#newContext_name").val())) {
                    //TA001: Create Or Copy Context
                    $("#overlay_newContext").show();
                    var param = {
                        pid: null,
                        name: $("#newContext_name").val(),
                        nodeCopy: event.data.nodeCopy
                    };
                    this.Everest.Create_Or_Copy_Scenario(param, $("#newContext_name").val(), $("#newContext_comments").val(), (event.data.nodeCopy != undefined && event.data.nodeCopy != null ? event.data.nodeCopy.id : undefined), function(data, status, p) {
                        var obj = {
                            id: p.pid,
                            nextUrl: null
                        };
                        var d = {
                            items: [{
                                id: data.id,
                                name: p.name
                            }]
                        };
                        this.Addchild(d, status, obj);
                        $("#box_newContext").remove();
                        // alert("New context created");

                        this.UI.createAlert("notif", "success", this.contentMessageFileJSON.MESSAGE_ALERT_CONTEXT_ADDED);
                    }.bind(this), this.Everest_callbackError.bind(this));
                } else {
                    this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_FOLDER_WRONG_NAME);
                    return -1;
                }
            } else {
                // alert("Name is empty!");
                this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_EMPTY_NAME);
                return -1;
            }
        }.bind(this));

    }


    /*
        When click on Edit entry in right-click menu : creates new interface
    */
    this.BT_EditContext = function(node) {
        if (this.EditContext == undefined) {
            this.EditContext = new SU_Edit_Context_CLS(this.Everest, this.UI);
            this.EditContext.init();
        }
        this.UI.moveUOto({
            idParent: "box_Edit_Context",
            id: "uo_Edit_Context",
            ItemLink: this.EditContext
        });

        this.EditContext.Load(node);
    }

    /*
        Creates infos interface of the context selected : when click on Infos button
    */
    this.callbackSuccessInfos = function(data, status, node) {
        this.createboxSU_InfosCtx(data, function() {});
    }



    this.Everest_callbackError = function(data, status) {

        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");
    }

}
SU_ManageScenarioContext_CLS.prototype.constructor = SU_ManageScenarioContext_CLS;

SU_ManageScenarioContext_CLS.prototype.createboxSU_ListCtx = function(CallBackClose) {

    $("#box_SU_ManageScenarioContext").remove();
    var c = "<div id=\"SU_ManageScenarioContext_content\">";
    c += "      <a class=\"btn btn-block btn-social btn-xs use-skin\" style=\"color:#ffffff\" id=\"m_new_context\"><i class=\"fa fa-folder-o\"></i>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_CREATE + "</a>";
    c += "      <ul id=\"treeContext\" class=\"ztree\"></ul>";
    c += "      <div id=\"SU_ManageScenarioContext_overlay\" class=\"overlay\">";
    c += "          <i class=\"fa fa-refresh fa-spin\"></i>";
    c += "      </div>";
    c += "  </div>";

    this.UI.CreateBox("SU_ManageScenarioContext", {
        draggable: true,
        group: "SuperUser",
        resizable: {
            minHeight: 500,
            minWidth: 475
        },
        useSkin: true,
        title: "Context List",
        content: c,
        visible: false,
        menuBt: {
            reduceTo: "div_ManageScenario", //ID vers qui on doit réduire la div
            fullscreen: {
                css: {
                    position: "absolute",
                    top: "130px",
                    width: "500px",
                    height: "500px",
                    left: "8%",
                    right: "",
                    bottom: ""
                },
                fct: function() {

                }.bind(this)
            } //position / taille de div pour quand on sort du fullscreen
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "130px",
            left: "8%",
            width: "475px",
            height: "500px",
            "min-width": "475px",
            "min-height": "500px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};

SU_ManageScenarioContext_CLS.prototype.createboxSU_NewCtx = function(nodeCopy, CallBackClose) {

    $("#box_newContext").remove();
    var c = "<div id=\"newContext_content\" style=\"width:100%; height:100%;\">";
    c += "      <table style=\"width:100%; height:100%;\">";
    if (nodeCopy != undefined) {
        c += "          <tr style=\"height: 50px;\"><td colspan=\"2\"><i>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_DUPLICATE_COPY + " " + nodeCopy.name + "</i></td></tr>";
    }
    c += "          <tr style=\"height: 50px;\"><td>" + this.contentFileJSON.MENU_NEW_NAME + " </td>";
    c += "          <td>";
    c += "              <input type=\"text\" class=\"form-control\" id=\"newContext_name\" placeholder=\"" + this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_NEWCONTEXT_NAME_CONTENT + "\">";
    c += "          </td></tr>";
    c += "          </tr>";
    c += "          <td>" + this.contentFileJSON.MENU_NEW_COMMENTS + " </td>";
    c += "          <td>";
    c += "              <textarea  rows=\"5\" style=\"width:100%; height:100%; resize:none;\" id=\"newContext_comments\" placeholder=\"\"></textarea>";
    c += "          </td></tr>";
    c += "      </table>";
    c += "  </div>";
    var f = "<div class=\"pull-right\" ><button class=\"btn use-skin\" style=\"color:#ffffff\" id=\"newContext_create\">" + this.contentFileJSON.MENU_NEW_CREATE + "</button></div>";
    var h = (nodeCopy != undefined ? 360 : 310);
    this.UI.CreateBox("newContext", {
        draggable: true,
        group: "SuperUser",
        useSkin: true,
        title: this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_CREATE_NEW,
        content: c,
        addOverlay: true,
        footer: f,
        resizable: {
            minHeight: h,
            minWidth: 400
        },
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            left: "40%",
            width: "400px",
            height: h + "px",
            "min-width": "400px",
            "min-height": h + "px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};

SU_ManageScenarioContext_CLS.prototype.createboxSU_InfosCtx = function(data, CallBackClose) {

    $("#box_infosContext").remove();
    var c = "<div id=\"infosContext_content\" style=\"width:100%; height:100%;\">";
    c += "      <table style=\"width:100%; height:100%;\">";
    c += "          <tr style=\"height: 50px;\"><td>" + this.contentFileJSON.MENU_NEW_NAME + " </td>";
    c += "              <td>" + data.name + "</td></tr>";
    c += "          </tr>";
    c += "          <td>" + this.contentFileJSON.MENU_NEW_COMMENTS + " </td>";
    c += "          <td>";
    c += "              <div class=\"box box-solid box-primary\" style=\"width:100%; height:100%; overflow:auto; text-align:left;padding:5px\" >" + data.description + "</div>";
    c += "          </td></tr>";
    c += "          <tr style=\"height: 50px;\"><td>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_INFO_PUBLISHED + " </td>";
    c += "              <td><i class=\"fa fa-" + (data.published == true ? "unlock-alt\" style=\"color:green" : "times-circle\" style=\"color:red") + "\"></i>" + (data.published == true ? " Yes" : " No") + "</td></tr>";
    c += "          </tr>";
    c += "      </table>";
    c += "  </div>";

    this.UI.CreateBox("infosContext", {
        draggable: true,
        group: "SuperUser",
        useSkin: true,
        title: data.name,
        content: c,
        resizable: {
            minHeight: 310,
            minWidth: 400
        },
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            left: "40%",
            width: "400px",
            height: "310px",
            "min-width": "400px",
            "min-height": "310px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};

export default SU_ManageScenarioContext_CLS;
