"use strict";
import i18nextko from "i18next-ko/src/i18next-ko.js";

class InterfaceMosaUI {
    constructor(UI, controller) {
        this.controller = controller;
        this.UI = UI;
        this.contentMessageFileJSON = this.UI.accessMessageContentJSON;
        this.contentFileJSON = this.UI.accessContentJSON;

        //timer to update file selection when update MOSA MID or cityGML
        this.timer_selectFile = undefined;
        //timer to update file selection when create NEW LAYER
        this.timer_selectShape = undefined;

        //On the link shape layer
        //Datatable build div on the fly, we need to "select" shp layer on the fly when we change of pages
        this.listSelectedShp = undefined;


        // i18n configuration
        // i18n data-bind of labels for mosaListTable
        this.mosaTableLabelBinding = {
            mid: "i18n:{text:{key:'menu:MENU_LABEL_MID_MOSATABLE'}}",
            cityGML: "i18n:{text:{key:'menu:MENU_LABEL_GML_MOSATABLE'}}",
            published: "i18n:{text:{key:'menu:MENU_LABEL_PUBLISHED_MOSATABLE'}}",
            updatedDate: "i18n:{text:{key:'menu:MENU_LABEL_UPDATE_DATE_MOSATABLE'}}"
        };

        // i18n data-bind of labels for newMosa
        this.newMosaLabelBinding = {
            projectName: "i18n:{text:{key:'menu:MENU_NEW_CITY_PROJECT_MOSA'}}",
            mosaName: "i18n:{text:{key:'menu:MENU_NEW_NAME_MOSA'}}",
            mosaNamePlaceHolder: "i18n:{placeholder:{key:'menu:MENU_NEW_PLACEHOLDER_MOSA'}}",
            mosaDescription: "i18n:{text:{key:'menu:MENU_NEW_DESCR_MOSA'}}",
            mosaCreateButton: "i18n:{text:{key:'menu:MENU_NEW_CREATE'}}"
        };

        // i18n data-bind of labels for createLayer
        this.createLayerLabelBinding = {
            studyArea: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_STUDY_AREA'}}",
            name: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_NAME'}}",
            namePlaceHolder: "i18n:{placeholder:{key:'menu:MENU_CREATE_LAYER_LABEL_NAME_PLACEHOLDER'}}",
            type: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_TYPE'}}",
            typePolygons: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_TYPE_POLYGONS'}}",
            typePolylines: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_TYPE_POLYLINES'}}",
            type3DObjects: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_TYPE_3DOBJECTS'}}",
            typeLabels: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_TYPE_LABELS'}}",
            description: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_DESCRIPTION'}}",
            filePath: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_FILE_PATH'}}",
            fileStatus: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_FILE_STATUS'}}",
            downloadProgress: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_DOWNLOAD_PROGRESS'}}",
            downloadStatus: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_DOWNLOAD_STATUS'}}",
            uploadLog: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_UPLOAD_LOG'}}",
            createButton: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_CREATE_BUTTON'}}",
            closeButton: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_CLOSE_BUTTON'}}",
            configureButton: "i18n:{text:{key:'menu:MENU_CREATE_LAYER_LABEL_CONFIGURE_BUTTON'}}"
        };

        // i18n data-bind of labels for updateLayer
        this.updateLayerLabelBinding = {
            projection: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_PROJECTION'}}",
            toolTip: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_TOOLTIP'}}",
            bbox: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_BBOX'}}",
            bboxXMin: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_BBOX_XMIN'}}",
            bboxXMax: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_BBOX_XMAX'}}",
            bboxYmin: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_BBOX_YMIN'}}",
            bboxYmax: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_BBOX_YMAX'}}",
            altitudeType: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_ALTITUDE_TYPE'}}",
            altitudeTypeAbsolute: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_ALTITUDE_TYPE_ABSOLUTE'}}",
            altitudeTypeRelative: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_ALTITUDE_TYPE_RELATIVE'}}",
            altitudeTypePolygonAbsolute: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_ALTITUDE_TYPE_POLYGON_ABSOLUTE'}}",
            altitudeTypePolygonRelative: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_ALTITUDE_TYPE_POLYGON_RELATIVE'}}",
            visibility: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_VISIBILITY'}}",
            visibilityYes: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_VISIBILITY_YES'}}",
            visibilityNo: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_VISIBILITY_NO'}}",
            visibilityPolygonYes: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_VISIBILITY_POLYGON_YES'}}",
            visibilityPolygonNo: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_VISIBILITY_POLYGON_NO'}}",
            minDistance: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_MIN_DISTANCE'}}",
            maxDistance: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_MAX_DISTANCE'}}",
            border: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_BORDER'}}",
            fill: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_FILL'}}",
            extrusion: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_EXTRUSION'}}",
            line: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_LINE'}}",
            altitude: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_ALTITUDE'}}",
            attributes3DObjects: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_3DOBJECTS_ATTRIBUTES'}}",
            imageURL: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_IMAGE_URL'}}",
            scale: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_SCALE'}}",
            scaleX: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_SCALE_X'}}",
            scaleY: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_SCALE_Y'}}",
            scaleZ: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_SCALE_Z'}}",
            rotation: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_ROTATION'}}",
            rotationPitch: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_ROTATION_PITCH'}}",
            rotationYaw: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_ROTATION_YAW'}}",
            rotationRoll: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_ROTATION_ROLL'}}",
            textTitle: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_TEXT_TITLE'}}",
            textContent: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_TEXT_CONTENT'}}",
            textFont: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_TEXT_FONT'}}",
            textFontBold: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_TEXT_FONT_BOLD'}}",
            textFontItalic: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_TEXT_FONT_ITALIC'}}",
            textFontUnderline: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_TEXT_FONT_UNDERLINE'}}",
            textBackground: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_TEXT_BACKGROUND'}}",
            textImage: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_TEXT_IMAGE'}}",
            textImageUrl: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_TEXT_URL'}}",
            textLineToGround: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_TEXT_LINE_TO_GROUND'}}",
            size: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_SIZE'}}",
            color: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_COLOR'}}",
            opacity: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_OPACITY'}}",
            width: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_WIDTH'}}",
            updateButton: "i18n:{text:{key:'menu:MENU_UPDATE_LAYER_LABEL_UPDATE_BUTTON'}}"
        };

        // i18n data-bind of labels for linkLayer
        this.linkLayerLabelBinding = {
            name: "i18n:{text:{key:'menu:MENU_LABEL_NAME'}}",
            id: "i18n:{text:{key:'menu:MENU_LABEL_ID'}}",
            finishButton: "i18n:{text:{key:'menu:MENU_BUTTON_FINISH'}}"
        };

    }

    getUI() {
        return this.UI;
    }

    /*
    Timer calls this function to check the file name selected in the Windows input file
    */
    updateFileSelected() {
        let files = document.getElementById("updateMosa_url").files;
        if (files[0] !== undefined) {
            let nameFile = document.getElementById("updateMosa_url").files[0].name;
            $("#fileSelectedUpdateMOSA").text(nameFile);
        }
    }

    updateShapeFileSelected() {
        if ($("#box_createLayer").length) {
            let files = document.getElementById("selectShapeFile").files;
            if (files[0] !== undefined) {
                let nameFile = document.getElementById("selectShapeFile").files[0].name;
                $("#fileSelectedUpdateSHAPE").text(nameFile);
            }
        } else {
            //Stop the timer
            if (this.timer_selectShape !== undefined) {
                window.clearInterval(this.timer_selectShape);
                this.timer_selectShape = undefined;
            }
        }
    }

    /*New*/

    // In SuperUser
    // fct called when user create a MOSA : fill the form with name, comment, then a new box appears when user validate  'create'.
    // He can now set a MID.
    // When the upload is ended, user can add a cityGML file, in updating the MOSA
    newMosa() {
        //define the skin to use. Actually skin-yellow correspond to the SU menu
        // this.UI.SetClsBG("skin-yellow");
        this.createboxCreateMOSA(function() {
        });

        $("#newMosa_create").click((event) => {
            if ($("#newMosa_modelname").val().length > 0) {
                var reg = new RegExp(this.UI.classBindData.contentRegExLabel);

                if (reg.test($("#newMosa_modelname").val())) {
                    $("#overlay_newMosa").show();
                    this.controller.createModel($("#newMosa_List_Select_studyArea").val(),
                        $("#newMosa_modelname").val(),
                        $("#newMosa_comments").val());
                } else {
                    this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_FOLDER_WRONG_NAME);
                }
            } else {

                this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_EMPTY_NAME);
            }
        });
    }

    createboxCreateMOSA(CallBackClose) {

        $("#box_newMosa").remove();
        let c = `<div id="newMosa_content" style="width:100%; height:100%;">`;
        c += `      <table style="width:100%; height:100%;">`;

        if (this.UI.Everest.studyArea.length > 1) {
            c += `          <tr style="height: 50px;"><td data-bind="${this.newMosaLabelBinding.projectName}">${this.contentFileJSON.MENU_NEW_CITY_PROJECT_MOSA}</td>`;
            c += `          <td>`;
            c += `              <div class="styledSelect">`;
            c += `                       <select id="newMosa_List_Select_studyArea" >`;

            for (let i = this.UI.Everest.studyArea.length - 1; i >= 0; i--) {
                c += `<option id="city_project_${this.UI.Everest.studyArea[i].code}" value="${i}" title="City Project">${this.UI.Everest.studyArea[i].name}</option>`;

            }

            c += `                    </select></div>`;
            c += `          </td></tr>`;
        }
        c += `          <tr style="height: 50px;"><td data-bind="${this.newMosaLabelBinding.mosaName}">${this.contentFileJSON.MENU_NEW_NAME_MOSA}</td>`;
        c += `          <td>`;
        c += `              <input type="text" class="form-control" id="newMosa_modelname" autocomplete="off" value="" data-bind="${this.newMosaLabelBinding.mosaNamePlaceHolder}">`;
        c += `          </td></tr>`;
        c += `          <tr style="height: 50px;">`;
        c += `              <td data-bind="${this.newMosaLabelBinding.mosaDescription}">${this.contentFileJSON.MENU_NEW_DESCR_MOSA}</td>`;
        c += `              <td>`;
        c += `                  <textarea  rows="5" style="width:100%; height:100%; resize:none;" id="newMosa_comments" placeholder=""></textarea>`;
        c += `              </td></tr>`;
        c += `      </table>`;
        c += `  </div>`;

        let f = `<div class="pull-right" ><button class="btn btn-primary use-skin" id="newMosa_create" data-bind="${this.newMosaLabelBinding.mosaCreateButton}">${this.contentFileJSON.MENU_NEW_CREATE}</button></div>`;

        //$("#createNewMOSA").append(c);
        this.UI.CreateBox("newMosa", {
            draggable: true,
            group: "SuperUser",
            useSkin: true,
            title: this.contentFileJSON.MENU_NEW_MOSA,
            tag: "MENU_NEW_MOSA",
            content: c,
            addOverlay: true,
            footer: f,
            //resizable: { minHeight: 360, minWidth: 400 },
            visible: true,
            menuBt: {
                close: true
            },
            style: { //Contient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "300px",
                left: "700px",
                width: "400px",
                height: "360px",
                "min-width": "400px",
                "min-height": "360px",
                "z-index": Zindex + 1
            }
        }, CallBackClose);
        Zindex = Zindex + 1;

        // post apply bindings for the dom element
        ko.applyBindings({}, document.getElementById("box_newMosa"));
    }

    removeNewMosa() {
        $("#box_newMosa").remove();
    }

    setIsAddingNewMOSA(val) {
        this.UI.classBindData.isAddingNewMOSA = val;
    }

    createboxUpdateMOSA(modelId) {
        //add the box to add a MID file
        this.UI.newBox.createboxUpdateMOSA("MID", () => {
            if (this.timer_selectFile != undefined) {
                window.clearInterval(this.timer_selectFile);
            }
        }); // on crée une box avec les intitulés pour un MID file

        if (this.timer_selectFile != undefined) {
            window.clearInterval(this.timer_selectFile);
        }
        //set the timer to check if a file is selected and update the textarea which display the name of the file
        this.timer_selectFile = setInterval(() => {
            this.updateFileSelected();
        }, 1000);

        document.getElementById("updateMosaSelect_load").disabled = true;
        $("#updateMosa_check").change(function(event) {
            var e = event;
            if (e.target.checked == true) {
                $("#boxLogUpdate").css("display", "block");
            } else {
                $("#boxLogUpdate").css("display", "none");
            }
        });
        $("#updateMosa_url").click(function(event) {
            document.getElementById("updateMosaSelect_load").disabled = false;
        });

        $("#updateMosaSelect_load").click((event) => {
            $("#updateMosaSelect_load").prop("disabled", true);
            $("#updateMosa_url").prop("disabled", true);
            //  var typeZip = "application/zip";

            //clear timer when select load button
            window.clearInterval(this.timer_selectFile);


            var inputFile = document.getElementById("updateMosa_url");

            if (inputFile.files != null && inputFile.files != undefined && inputFile.files[0] != undefined) {
                var type = inputFile.files[0].type;
                var nameFile = inputFile.files[0].name;
                var nameParsed = nameFile.substring(0, nameFile.indexOf("."));
                var extension = nameFile.substring(nameFile.indexOf(".") + 1, nameFile.length);
                if (extension == "zip") {
                    type = "MID";
                    var meta = {
                        "modelId": modelId.toString()
                    };
                    this.controller.createFile(nameParsed, type, meta, nameFile, inputFile);
                } else {
                    this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_MOSA_UPDATE_FILE_EXTENSION);
                    $("#updateMosaSelect_load").prop("disabled", false);
                    $("#updateMosa_url").prop("disabled", false);
                }
            } else {
                this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_MOSA_UPDATE_FILE);
                $("#updateMosaSelect_load").prop("disabled", false);
                $("#updateMosa_url").prop("disabled", false);
            }
        });
    }

    setTextUpdateMosa(val) {
        $("#runIDUpdateMOSA").text("Run ID : " + val);
    }

    /*readRun*/
    readRun(dataResponse) {
        $("#runStatusUpdateMOSA").text(dataResponse.progress + " %");
        $("#textStatusUpdateMOSA").text(dataResponse.progressMessage);
    }

    resetReadRun(newMOSAId) {
        $("#LstMOSA_" + newMOSAId + "_MID_TD").empty();
        var html = "<i class='fa fa-fw fa-check'></i>";
        $("#LstMOSA_" + newMOSAId + "_MID_TD").append(html);
        $("#box_updateMosaSelect").remove();
    }

  /*Read_Run_Output_File*/
  readRunOutputFile(d) {
    var posScrollMax = $("#boxLogUpdate").prop("scrollHeight");
    var posScroll = $("#boxLogUpdate").scrollTop();
    var boolBottomScroll = false;
    if (posScroll == posScrollMax) {
      //le scroll était tout en bas
      boolBottomScroll = true;
    }
    $("#boxLogUpdate").val(d);
    var NewposScroll = $("#boxLogUpdate").scrollTop();
    if (boolBottomScroll == true) {
      $("#boxLogUpdate").scrollTop($("#boxLogUpdate").prop("scrollHeight"));
    } else {
      $("#boxLogUpdate").scrollTop(posScroll);
    }
  }
  /*Read_Run_Output_File*/
  readRunOutputFileLayer(data, status, errorTxt, isOk) {
    var posScrollMax = $("#newShape_log").prop("scrollHeight");
    var posScroll = $("#newShape_log").scrollTop();
    var boolBottomScroll = false;
    if (posScroll == posScrollMax) {
      //le scroll était tout en bas
      boolBottomScroll = true;
    }
    $("#newShape_log").val((isOk == false ? errorTxt + "\n" : "") + data.responseText);
    var NewposScroll = $("#newShape_log").scrollTop();
    if (boolBottomScroll == true) {
      $("#newShape_log").scrollTop($("#newShape_log").prop("scrollHeight"));
    } else {
      $("#newShape_log").scrollTop(posScroll);
    }
  }


    /*readRunLayer*/
    readRunLayer(data, shapeId, shapeName, shapeType, runid) {

        $("#DownloadSHAPEBar").css("width", data.progress + "%").attr("aria-valuenow", data.progress);

        if (data.status === "failed") {
            this.UI.createAlertSU("alert", "error", this.contentMessageFileJSON.MESSAGE_ALERT_UPLOAD_LAYER_FAIL);
            $("#id_CreateLayer_Go").prop("disabled", false);
            clearTimeout();
        } else if (data.progress < 100) {
            setTimeout(() => {
                this.controller.readRunLayer(runid);
            }, 1000);
            $("#DownloadSHAPEStateMsg").html(data.progressMessage);
        } else {
            clearTimeout();
            $("#DownloadSHAPEStateMsg").html(this.contentMessageFileJSON.MESSAGE_ALERT_UPLOAD_LAYER_SUCCESS);

            let stadyArea = $("#id_StudyAreaLayer").val();
            $("#id_CreateLayer_Go").toggle();
            $("#id_CloseLayer_Go").toggle();
            $("#id_ConfigureLayer_Go").toggle();
            let myRow = "<tr id='LstSHAPE_" + shapeId + "'></tr>";
            $("#LstSHAPE").children("tbody").prepend(myRow);

            let myRowData = "<td id='LstSHAPE_" + shapeId + "_name_TD'><p id='" + shapeId + "'></p><b>" + shapeName + "</b></td>";
            myRowData += "<td id='LstSHAPE_" + shapeId + "_TYPE_TD'><b>" + shapeType + "</b></td>";
            myRowData += "<td id='LstSHAPE_" + shapeId + "_state_TD'><b>" + "UNPUBLISHED" + "</b></td>"; //tochange ?
            myRowData += "<td id='LstSHAPE_" + shapeId + "studyAreaCode_TD'>" + stadyArea + "</td>";
            let jRow = $("#LstSHAPE_" + shapeId).prepend(myRowData);

          let table = $("#LstSHAPE").DataTable();
          if (jRow != undefined) {
            if (jRow[0] != undefined) {
              table.rows.add(jRow[0]).draw();
            } else {
              table.rows.add(jRow).draw();
            }
          }
        }
    }

    /*SelectMOSA*/
    getSelectedMOSA() {
        return $("#list_select_mosa_usersimple").val();
    }

    /*changeMOSA*/
    changeMOSA() {
        var list = document.getElementById("list_select_mosa_usersimple");
        if (list.firstChild.id == "defaultVal") {
            var defaultValue = list.removeChild(list.firstChild);
        }
        document.getElementById("btnEnterMosaValid").disabled = false;
    }

    listModelsInfo(data) {
        if (data.authorFullName != null)
            $("#mosaAuthor_usersimple").text(data.authorFullName);
        else
            $("#mosaAuthor_usersimple").text(" --- ");

        if (data.createDate != null)
            $("#mosaDate_usersimple").text(data.createDate);
        else
            $("#mosaDate_usersimple").text(" --- ");

        if (data.description)
            $("#mosaInfos_usersimple").text(data.description);
        else
            $("#mosaInfos_usersimple").text(" --- ");
    }

    /*Select*/
    selectMosa(id, mosa) {
        $("#mainDiv").hide();
        // $("#mosa_panel_usersimple").hide();
        $("#mosa_panel_bg").hide();
        $("body").css("overflow", "hidden");


        // define the skin, with the default value (Simulating )
        if (this.UI.current_skin != "skin-blue") {
            this.UI.SetClsBG("skin-blue");
        }


        this.UI.classBindData.mosaID = mosa.id;
        this.UI.classBindData.mosaName = mosa.name;

        this.UI.classBindData.mosaDescr = mosa.description;
    }

    setNbBoxOpened(val) {
        this.UI.nbBoxOpened = val;
    }

    setVisibilityMosaPanel(visible) {
        if (visible) {
            $("#mosa_panel_usersimple").show();
        } else {
            $("#mosa_panel_usersimple").hide();
        }
    }

    setVisibilityMosaEditPanel(visible) {
        if (visible) {
            $("#mosaPanelEdit").show();
        } else {
            $("#mosaPanelEdit").hide();
        }
    }

    setVisibilityShapefilePanel(visible) {
        if (visible) {
            $("#shapePanelEdit").show();
        } else {
            $("#shapePanelEdit").hide();
        }
    }

    /*Init()*/
    init(authToken) {
        this.UI.classBindData.authToken = authToken;
        if (this.UI.classBindData.authToken) {
            $("#box-body_content_tableMOSA").empty();
            let c = `<table class="table table-striped table-results-calculs" id="LstMOSA" style="width: 100%">`;
            c += `<thead>`;
            c += `<tr>`;
            c += `<th style="width: 40%">&nbsp;</th>`;
            c += `<th style="cursor:pointer;" data-bind="${this.mosaTableLabelBinding.mid}">${this.contentFileJSON.MENU_LABEL_MID_MOSATABLE}</th>`;
            c += `<th style="cursor:pointer;" data-bind="${this.mosaTableLabelBinding.cityGML}">${this.contentFileJSON.MENU_LABEL_GML_MOSATABLE}</th>`;
            c += `<th style="cursor:pointer;" data-bind="${this.mosaTableLabelBinding.published}">${this.contentFileJSON.MENU_LABEL_PUBLISHED_MOSATABLE}</th>`;
            c += `<th style="cursor:pointer;" data-bind="${this.mosaTableLabelBinding.updatedDate}">${this.contentFileJSON.MENU_LABEL_UPDATE_DATE_MOSATABLE}</th>`;
            // c += `<th style="cursor:pointer;">${this.contentFileJSON.MENU_LABEL_studyArea}</th>`;
            c += `</tr>`;
            c += `</thead><tfoot></tfoot><tbody></tbody>`;

            $("#box-body_content_tableMOSA").append(c);

            // post apply bindings for the dom element
            ko.applyBindings({}, document.getElementById("LstMOSA"));

            //Events
            $("#LstMOSA_publish").click($.proxy(() => {
                var id = this.UI.classBindData.selectIdEditMOSA;
                if (id == null) {
                    this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_MUST_CHOOSE_MOSA);
                } else {
                    this.controller.getMosa(id).publish(id);
                }

            }, this));
            $("#LstMOSA_update").click($.proxy(() => {
                var id = this.UI.classBindData.selectIdEditMOSA;
                if (id == null) {
                    this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_MUST_CHOOSE_MOSA);
                } else {
                    this.controller.getMosa(id).update(id);
                }

            }, this));
            $("#LstMOSA_update_Model").click($.proxy(() => {
                var id = this.UI.classBindData.selectIdEditMOSA;
                if (id == null) {
                    this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_MUST_CHOOSE_MOSA);
                } else {
                    this.controller.getMosa(id).updateMOSAfile("MID");
                    ;
                }

            }, this));
            $("#LstMOSA_update_3D").click($.proxy(() => {
                var id = this.UI.classBindData.selectIdEditMOSA;
                if (id == null) {
                    this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_MUST_CHOOSE_MOSA);
                } else {
                    this.controller.getMosa(id).updateMOSAfile("3D");
                }

            }, this));

            $("#LstSHAPE_Create_new_Layer").click($.proxy(() => {
                var id = this.UI.classBindData.selectIdEditMOSA;
                var studyAreas = this.UI.classBindData.userStudyAreas;
                this.controller.createLayer(id, studyAreas);
            }, this));

            $("#LstSHAPE_Delete").click($.proxy(() => {
                var id = this.UI.classBindData.selectIdEditSHAPE;
                if (id == null) {
                    this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_DELETE_LAYER_WARN_SELECT_FILE_TO_DELETE);
                } else {
                    this.controller.deleteLayer(id);
                }
            }, this));

            $("#LstSHAPE_Update").click($.proxy(() => {
                var id = this.UI.classBindData.selectIdEditSHAPE;
                if (id == null) {
                    this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_UPDATE_LAYER_WARN_SELECT_FILE_TO_UPDATE);
                } else {
                    //console.log($("#LstSHAPE_" + id + "_TYPE_TD").text());
                    var layerType = $("#LstSHAPE_" + id + "_TYPE_TD").text();
                    switch (layerType) {
                        case "Polygones":
                            this.controller.updatePolygonsLayer(id);
                            break;
                        case "Polylines":
                            this.controller.updatePolylinesLayer(id);
                            break;
                        case "3DObjects":
                            this.controller.update3DObjectsLayer(id);
                            break;
                        case "Labels":
                            this.controller.updateLabelsLayer(id);
                            break;
                        default:
                            break;
                    }
                }
            }, this));

            $("#LstSHAPE_Publish").click($.proxy(() => {
                var id = this.UI.classBindData.selectIdEditSHAPE;
                if (id == null) {
                    this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_UPDATE_LAYER_WARN_SELECT_FILE_TO_UPDATE);
                } else {
                    //console.log($("#LstSHAPE_" + id + "_TYPE_TD").text());
                    var layerState = $("#LstSHAPE_" + id + "_state_TD").text();
                    switch (layerState) {
                        case "PUBLISHED":
                            this.controller.updateStudyArea(id, "UNPUBLISHED");
                            break;
                        default:
                            this.controller.updateStudyArea(id, "PUBLISHED");
                            break;
                    }
                }
            }, this));

            $("#LstMOSA_Shapeview").click($.proxy(() => {
                this.setVisibilityMosaEditPanel(false);
                this.setVisibilityShapefilePanel(true);
            }, this));

            $("#LstSHAPE_Mosaview").click($.proxy(() => {
                this.setVisibilityMosaEditPanel(true);
                this.setVisibilityShapefilePanel(false);
            }, this));

            $("#LstMOSA_delete").click($.proxy(() => {
                var id = this.UI.classBindData.selectIdEditMOSA;
                var studyAreas = this.UI.classBindData.userStudyAreas;
                if (id == null) {
                    this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_MUST_CHOOSE_MOSA);
                } else {
                    this.controller.getMosa(id).delete_fct(id);
                }

            }, this));

            $("#LstMOSA_Link_Shape").click($.proxy(() => {
                var id = this.UI.classBindData.selectIdEditMOSA;
                var studyArea = this.UI.classBindData.selectSAMOSA;
                this.controller.linkLayer(id, studyArea);

            }, this));

            $("#LstMOSA_load").click($.proxy(() => {
                var id = this.UI.classBindData.selectIdEditMOSA;
                if (id == null) {
                    this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_MUST_CHOOSE_MOSA);
                } else {
                    $("#mosa_panel_usersimple").hide();
                    $("#mosaPanelEdit").hide();
                    $("#browser-warning").hide();
                    this.controller.getMosa(id).fct_Select(id);
                }

            }, this));

            this.filterDate_status = "desc"; // avant de lancer le tri des résultats sur le callback, on définit que l'on veut trier sur les dates en décroissant
            //add an static option

            $("#list_select_mosa_usersimple").empty();

            let defaultOptionValue = i18nextko.i18n.t("MENU_LABEL_VALUE_DROPDOWN", { ns: "menu" });
            let option = `<option id="defaultVal" value="default" title="${defaultOptionValue}">${defaultOptionValue}</option>`;

            $("#list_select_mosa_usersimple").append(option);

            this.controller.listModels();
        }
    }

    updateStudyAreaCallback(id, txt) {
        $("#LstSHAPE_" + id + "_state_TD").text(txt);
    }

    /*linkLayer*/
    createboxLinkLayer(listWFS) {
        this.createboxLinkLayerHtml(listWFS, () => {
            window.clearInterval(this.timer_selectShape);
            this.timer_selectShape = undefined;
        });
    }

    createboxLinkLayerHtml(listWFS, CallBackClose) {
        $("#box_LinkLayer").remove();

        let c = `	<table id="linkLayerTab" class="table dataTable no-footer" style="overflow: auto;" cellspacing="0"> `;
        c += `<thead><tr><th data-bind="${this.linkLayerLabelBinding.name}">Name</th><th data-bind="${this.linkLayerLabelBinding.id}">Id</th></tr></thead>`;
        c += `<tbody>`;

        for (let i = 0; i < listWFS.length; i++) {
            c += `<tr id="linkLayer_${listWFS[i].id}"><td>${listWFS[i].displayName == null ? listWFS[i].technicalName : listWFS[i].displayName}</td><td>${listWFS[i].id}</td></tr>`;
        }
        c += `</tbody>`;
        c += `</table>`;
        let f = `<div class="pull-right" ><button class="btn btn-primary use-skin" id="id_box_LinkLayer_finish" data-bind="${this.linkLayerLabelBinding.finishButton}">Finish</button></div>`;

        this.UI.CreateBox("LinkLayer", {
            draggable: true,
            group: "SuperUser",
            useSkin: true,
            title: this.contentFileJSON.MENU_LINK_LAYER,
            tag: "MENU_LINK_LAYER",
            content: c,
            addOverlay: true,
            footer: f,
            //resizable: { minHeight: 400, minWidth: 400 },
            visible: true,
            menuBt: {
                close: true
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "10%",
                left: "35%",
                width: "30%",
                height: "80%",
                "z-index": Zindex + 1
            }
        }, CallBackClose);
        Zindex = Zindex + 1;

        // post apply bindings for the dom element
        ko.applyBindings({}, document.getElementById("box_LinkLayer"));
    }

    linkLayer() {
        var self = this;
        $("#id_box_LinkLayer_finish").click(function(event) {
            $("#box_LinkLayer").hide();
        });

        var table = $("#linkLayerTab").DataTable();
        $("#linkLayerTab tbody").on("click", "tr", function() {
            let tmp = $(this).attr("id").split("_");
            var idShape = tmp[1];
            if ($(this).hasClass("green")) {
                //unlinklayer
                self.controller.unlinkModelWFSlayers(idShape, $(this));
            } else {
                //linklayer
                self.controller.linkModelWFSlayers(idShape, $(this));
            }
        });
    }

    unlinkModelWFSlayers(idShape, objDom) {
        console.log("Unlink_Model_WFSlayers success");
        objDom.removeClass("green");
        if (this.listSelectedShp != undefined && this.listSelectedShp.has(idShape.toString())) {
            this.listSelectedShp.delete(idShape.toString());
        }
    }

    linkModelWFSlayers(idShape, objDom) {
        console.log("Link_Model_WFSlayers success");
        objDom.addClass("green");
        if (this.listSelectedShp == undefined) {
            this.listSelectedShp = new Map();
        }
        this.listSelectedShp.set(idShape.toString(), true);
    }

    /*getwfsModel*/
    getwfsModelCallback(d) {
        console.log("List_Model_WFSlayers success");
        this.listSelectedShp = new Map();
        var table = $("#linkLayerTab").DataTable();
        for (var i = 0; i < +d.studyAreaLayers.length; i++) {
            this.listSelectedShp.set(d.studyAreaLayers[i].id.toString(), true);
        }
        table.on("draw.dt", () => {
            this.refreshSelectedShp();
        });
        this.refreshSelectedShp();
    }

    refreshSelectedShp() {
        if (this.listSelectedShp != undefined) {
            this.listSelectedShp.forEach((value, key, map) => {
                if (!$("#linkLayer_" + key).hasClass("green")) {
                    $("#linkLayer_" + key).addClass("green");
                }
            });
        }
    }

    /*createLayer*/
    createLayer(idMosa, studyAreas) {
        //create the box with input file and logs
        this.timer_selectShape = setInterval(() => {
            this.updateShapeFileSelected();
        }, 1000);
        this.createboxNewLayer(idMosa, studyAreas, () => {
            window.clearInterval(this.timer_selectShape);
            this.timer_selectShape = undefined;
        });
        //console.log(this.Everest);
        $("#id_CreateLayer_Go").click((event) => {
            var selectedArea;
            if (studyAreas.length == 1) {
                selectedArea = studyAreas[0].code;
            } else {
                selectedArea = $("#id_StudyAreaLayer").val();
            }
            var layerName = $("#id_NameLayer").val();
            var layerType = $("#id_TypeLayer").val();
            var layerDesc = $("#newShape_comments").val();

            //create Error messages
            var files = document.getElementById("selectShapeFile").files;
            if (files[0] !== undefined) {
                var layerFile = document.getElementById("selectShapeFile").files[0].name;
            } else {
                this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_UPLOAD_LAYER_WARN_CHOOSE_FILE);
                $("#id_CreateLayer_Go").prop("disabled", false);
                return 0;
            }

            if (layerName === "") {
                this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_UPLOAD_LAYER_WARN_ENTER_NAME);
                $("#id_CreateLayer_Go").prop("disabled", false);
                return 0;
            }
            $("#id_CreateLayer_Go").prop("disabled", true);
            //register the new wfs layer in EVEREST using SY003
            this.controller.createStudyArea(selectedArea, layerName, layerType);
        });

        $("#id_CloseLayer_Go").click(function(event) {
            $("#box_createLayer").hide();
        });

        $("#id_ConfigureLayer_Go").click((event) => {
            console.log("configure");
            $("#box_createLayer").hide();
            var id = this.controller.getShapeId();
            var layerType = this.controller.getShapeType();
            switch (layerType) {
                case "Polygones":
                    this.controller.updatePolygonsLayer(id);
                    break;
                case "Polylines":
                    this.controller.updatePolylinesLayer(id);
                    break;
                case "3DObjects":
                    this.controller.update3DObjectsLayer(id);
                    break;
                case "Labels":
                    this.controller.updateLabelsLayer(id);
                    break;
                default:
                    break;
            }
        });
    }

    createboxNewLayer(type, studyAreaList, CallBackClose) {
        $("#box_createLayer").remove();

        let c = `<div id="newLayer_content" style="width:100%; height:100%;">`;
        c += `   <table style="width:100%; height:100%;">`;
        if (studyAreaList.length === 1) {
            c += `        <tr style="height: 50px;"><td data-bind="${this.createLayerLabelBinding.studyArea}"></td><td><span id="id_StudyAreaLayer">${studyAreaList[0].name}</span></td></tr>`;
        } else {
            c += `        <tr style="height: 50px;">`;
            c += `            <td><label for="studyArea" data-bind="${this.createLayerLabelBinding.studyArea}"></label></td>`;
            c += `            <td><div class="styledSelect">`;
            c += `                <select name="studyArea" id="id_StudyAreaLayer">`;

            for (let i = 0; i < studyAreaList.length; i++) {
                c += `                    <option value="${studyAreaList[i].code}">${studyAreaList[i].name}</option>`;
            }
            c += `                </select>`;
            c += `            </div></td>`;
            c += `        </tr>`;
        }
        //Layer Name
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.createLayerLabelBinding.name}"></td>`;
        c += `            <td><input type="text" class="form-control" name="nameLayer" id="id_NameLayer" value="" autocomplete="off" data-bind="${this.createLayerLabelBinding.namePlaceHolder}"></td>`;
        c += `        </tr>`;

        //Layer type
        c += `        <tr style="height: 50px;">`;
        c += `				    <td data-bind="${this.createLayerLabelBinding.type}"></td>`;
        c += `            <td><div class="styledSelect">`;
        c += `                <select name="layerType" id="id_TypeLayer">`;
        c += `                    <option value="Polygones" data-bind="${this.createLayerLabelBinding.typePolygons}"></option>`;
        c += `                    <option value="Polylines" data-bind="${this.createLayerLabelBinding.typePolylines}"></option>`;
        c += `                    <option value="3DObjects" data-bind="${this.createLayerLabelBinding.type3DObjects}"></option>`;
        c += `                    <option value="Labels" data-bind="${this.createLayerLabelBinding.typeLabels}"></option>`;
        c += `                </select>`;
        c += `            </div></td>`;
        c += `        </tr>`;

        //description
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.createLayerLabelBinding.description}"></td>`;
        c += `            <td><textarea class="form-control" rows="5" style="resize:none;" id="newShape_comments" placeholder=""></textarea></td>`;
        c += `        </tr>`;

        //upload button + path
        c += `        <tr style="height: 50px;">`;
        c += `            <td><span class="btn btn-primary use-skin fileinput-button"><span data-bind="${this.createLayerLabelBinding.filePath}"></span><input id="selectShapeFile" type="file" ></span></td>`;
        c += `            <td><span id="fileSelectedUpdateSHAPE" data-bind="${this.createLayerLabelBinding.fileStatus}"></span></td>`;
        c += `        </tr>`;

        //upload progress-bar
        c += `        <tr id="DownloadSHAPEDiv" style="display:none;height: 50px;">`;
        c += `            <td><span id="DownloadSHAPETitle" data-bind="${this.createLayerLabelBinding.downloadProgress}"></span></td>`;
        c += `            <td><div class="progress" style="width:100%;">`;
        c += `                <div id="DownloadSHAPEBar"class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width:0%">`;
        c += `                </div>`;
        c += `            </div></td>`;
        c += `        </tr>`;

        //progressinfo
        c += `        <tr id="DownloadStateDiv" style="display:none;height: 50px;">`;
        c += `            <td><span id="DownloadSHAPEState" data-bind="${this.createLayerLabelBinding.downloadStatus}"></span></td>`;
        c += `            <td><span id="DownloadSHAPEStateMsg"></span></td>`;
        c += `            </div></td>`;
        c += `        </tr>`;

      //log
      c += `        <tr id="LogSHAPEDivChkBox" style="height: 50px;">`;
      c += `            <td colspan="2"><input type="checkbox" id="updateShp_check"><label for="updateShp_check" data-bind="${this.createLayerLabelBinding.showLog}">${this.contentFileJSON.MENU_BUTTON_LOG}</label></td>`;
      c += `        </tr>`;
      c += `        <tr id="LogSHAPEDiv" style="display:none;height: 50px;">`;
      c += `            <td colspan="2"><textarea class="form-control" rows="5" style="resize:none;" id="newShape_log" placeholder="" disabled></textarea></td>`;
      c += `        </tr>`;

        c += `    </table>`;
        c += `</div>`;

        let f = `<div class="pull-right" >`;
        f += `<button class="btn btn-primary use-skin" style="margin-right:5px;" id="id_CreateLayer_Go" data-bind="${this.createLayerLabelBinding.createButton}"></button>`;
        f += `<button class="btn btn-primary use-skin" style="display:none;margin-right:5px;" id="id_CloseLayer_Go" data-bind="${this.createLayerLabelBinding.closeButton}"></button>`;
        f += `<button class="btn btn-primary use-skin" style="display:none;margin-right:5px;" id="id_ConfigureLayer_Go" data-bind="${this.createLayerLabelBinding.configureButton}"></button>`;
        f += `</div>`;

        this.UI.CreateBox("createLayer", {
            draggable: true,
            group: "SuperUser",
            useSkin: true,
            title: this.contentFileJSON.MENU_NEW_LAYER,
            tag: "MENU_NEW_LAYER",
            content: c,
            addOverlay: true,
            footer: f,
            //resizable: { minHeight: 400, minWidth: 400 },
            visible: true,
            menuBt: {
                close: true
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "10%",
                left: "35%",
                width: "30%",
                height: "80%",
                "z-index": Zindex + 1
            }
        }, CallBackClose);
        Zindex = Zindex + 1;
      $('#updateShp_check').change(function () {
        if ($(this).is(":checked")) {
          //'checked' event code
          $("#LogSHAPEDiv").show();
          return;
        } else {
          //'unchecked' event code
          $("#LogSHAPEDiv").hide();
        }
      });
        // post apply bindings for the dom element
        ko.applyBindings({}, document.getElementById("box_createLayer"));

    }

    /*uploadSHAPEFILE*/
    uploadShapefile(data) {
        var layerFile = document.getElementById("selectShapeFile").files;
        if (layerFile[0] === undefined) {
            this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_UPLOAD_LAYER_WARN_CHOOSE_FILE);
            return 0;
        } else {
            var type = layerFile[0].type;
            var nameFile = layerFile[0].name;
            var nameParsed = nameFile.substring(0, nameFile.indexOf("."));
            var extension = nameFile.substring(nameFile.indexOf(".") + 1, nameFile.length);
            if (extension === "zip") {
                type = "SHAPEFILE";
                var meta = {
                    "layerId": data.id.toString()
                };
                this.controller.createShapefile(data, nameFile, nameParsed, meta, type, layerFile);
            } else {
                this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_MOSA_UPDATE_FILE_EXTENSION);
                $("#updateMosaSelect_load").prop("disabled", false);
                $("#updateMosa_url").prop("disabled", false);
            }
        }

    }

    uploadShapefileCallback(d) {
        $("#DownloadSHAPEDiv").show();
        $("#DownloadStateDiv").show();
        if (d.runId == null) {
            this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_UPLOAD_LAYER_WARN_NO_RUN_ID);
        } else {
            this.controller.readRunLayer(d.runId);
        }
    }

    /*updatePolygonsLayer*/
    createboxPolygonsForm(d) {
        this.createboxPolygonsFormHtml(d, () => {
            window.clearInterval(this.timer_selectFile);
        });
    }

    createboxPolygonsFormHtml(data, CallBackClose) {
        $("#box_createPolygonsLayer").remove();

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if (data[key] == null) {
                    data[key] = "";
                }
                for (let key2 in data[key]) {
                    if (data[key].hasOwnProperty(key2)) {
                        if (data[key][key2] == null) {
                            data[key][key2] = "";
                        }
                    }
                }
            }
        }

        //TODO why copy & paste code to create DOM, why not use a template?  it makes the translation hardly maintained
        let c = `<div id="PolygonsForm_content" style="width:100%; height:100%;">`;
        c += `   <table style="width:100%; height:100%;">`;
        // Advanced mode is too bugged
        //c += '        <tr style="height: 50px;">';
        //c += '            <td>Advanced Mode</td>';
        //c += '            <td><input type="checkbox" id="cboxPolygonsAdvanced" value="checkbox1"></td>';
        //c += '        </tr>';

        //projection
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.projection}"></td>`;
        c += `            <td colspan="5"><input type="text" class="form-control" id="id_Polygons_projection" value="${data.proj}" autocomplete="off"></td>`;
        c += `        </tr>`;

        //Infobulle
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.toolTip}"></td>`;
        c += `            <td colspan="5"><input type="text" class="form-control" id="id_Polygons_Infobulle" value="${data.altText}" autocomplete="off"></td>`;
        c += `        </tr>`;

        //BBOX
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.bbox}"></b></td></tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxXMin}"></td>`;
        c += `            <td colspan="2"><input id="id_Polygons_bbox_x_min" class="form-control" type="number" step="0.1" name="bbox_x_min" value="${data.boundingBox.xmin}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxXMax}"></td>`;
        c += `            <td colspan="2"><input id="id_Polygons_bbox_x_max" class="form-control" type="number" step="0.1" name="bbox_x_max" value="${data.boundingBox.xmax}"></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxYmin}"></td>`;
        c += `            <td colspan="2"><input id="id_Polygons_bbox_y_min" class="form-control" type="number" step="0.1" name="bbox_x_min" value="${data.boundingBox.ymin}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxYmax}"></td>`;
        c += `            <td colspan="2"><input id="id_Polygons_bbox_y_max" class="form-control" type="number" step="0.1" name="bbox_x_max" value="${data.boundingBox.ymax}"></td>`;
        c += `        </tr>`;

        //altitudeType
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.altitudeType}"></b></td>`;
        c += `        </tr><tr>`;
        c += `            <td colspan="6"><form><label for="id_Polygons_Altitude_type_abs" data-bind="${this.updateLayerLabelBinding.altitudeTypePolygonAbsolute}"></label><input type="radio" name="altitudeType" value="absolute" id="id_Polygons_Altitude_type_abs" ${data.altitudeType === "absolute" ? "checked" : ""}> &nbsp;&nbsp;&nbsp; <label for="id_Polygons_Altitude_type_rel" data-bind="${this.updateLayerLabelBinding.altitudeTypePolygonRelative}"></label><input type="radio" name="altitudeType" value="relative" id="id_Polygons_Altitude_type_rel" ${data.altitudeType === "relative" ? "checked" : ""}></form></td>`;
        c += `        </tr>`;

        //Visibility
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.visibility}"></b></td>`;
        c += `        </tr><tr>`;
        c += `            <td colspan="6"><form><label for="id_Polygons_visibility_true" data-bind="${this.updateLayerLabelBinding.visibilityPolygonYes}"></label> <input type="radio" name="visibility"  value="true" id="id_Polygons_visibility_true" ${data.visible ? "checked" : ""}> &nbsp;&nbsp;&nbsp; <label for="id_Polygons_visibility_false" data-bind="${this.updateLayerLabelBinding.visibilityPolygonNo}"></label><input type="radio" name="visibility"  value="false" id="id_Polygons_visibility_false" ${!data.visible ? "checked" : ""}></form></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.minDistance}"></td>`;
        c += `            <td colspan="2"><input id="id_Polygons_visibility_min" class="form-control" type="number" step="0.1" name="visibility_min" value="${data.visibilityDistanceMin}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.maxDistance}"></td>`;
        c += `            <td colspan="2"><input id="id_Polygons_visibility_max" class="form-control" type="number" step="0.1" name="visibility_max" value="${data.visibilityDistanceMax}"></td>`;
        c += `        </tr>`;

        //Specific Polygons attributes
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.border}"></b></td></tr>`;
        c += `        <tr style="height: 50px;" id="tr_Polygon_Border">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.color}"></td>`;
        c += `            <td><input type="text" id="id_Polygons_Border_Color" value="${data.layerConfig.border_color}"  class="form-control color" ></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.opacity}"></td>`;
        c += `            <td><input type="text" id="id_Polygons_Border_Opacity" step="0.1" class="form-control" min="0" max="1" value="${data.layerConfig.border_opacity}" ></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.width}"></td>`;
        c += `            <td><input type="text" id="id_Polygons_Border_Width"  class="form-control" min="0" value="${data.layerConfig.border_width}"></td>`;
        c += `        </tr>`;

        //Fill
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.fill}"></b></td></tr>`;
        c += `        <tr style="height: 50px;" id="tr_Polygon_Fill">`;
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.color}"></td>`;
        c += `            <td width="80px"><input type="text" id="id_Polygons_Fill_Color" value="${data.layerConfig.fill_color}"  class="form-control color" ></td>`;
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.opacity}"></td>`;
        c += `            <td width="80px"><input type="text" id="id_Polygons_Fill_Opacity" step="0.1" class="form-control" min="0" max="1" value="${data.layerConfig.fill_opacity}" ></td>`;
        c += `            <td width="80px"> </td>`;
        c += `            <td width="80px"> </td>`;
        c += `        </tr>`;

        // Altitude
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.extrusion}"></b></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td> </td>`;
        c += `            <td colspan="5"><input id="id_Polygons_Altitude" class="form-control" type="text" step="0.1" min="0" value="${data.layerConfig.extrusion}"></td>`;
        c += `        </tr>`;
        c += `    </table>`;
        c += `</div>`;
        let f = `<div class="pull-right" ><button class="btn btn-primary use-skin" id="id_CreatePolygonsLayer_Go" data-bind="${this.updateLayerLabelBinding.updateButton}"></button></div>`;

        this.UI.CreateBox("createPolygonsLayer", {
            draggable: true,
            group: "SuperUser",
            useSkin: true,
            title: this.contentFileJSON.MENU_UPDATE_LAYER,
            tag: "MENU_UPDATE_LAYER",
            content: c,
            addOverlay: true,
            footer: f,
            visible: true,
            menuBt: {
                close: true
            },
            style: {
                position: "absolute",
                top: "10%",
                left: "30%",
                width: "35%",
                height: "80%",
                "z-index": Zindex + 1
            }
        }, CallBackClose);
        //$(document).delegate("#id_CreatePolygonsLayer_Go","click",this.updatePolygonsEvent.bind(this));

        // post apply bindings for the dom element
        ko.applyBindings({}, document.getElementById("box_createPolygonsLayer"));

        Zindex = Zindex + 1;
        var colorbox = $(".color");
        colorbox.colorpickerplus();
        colorbox.on("changeColor", function(e, color) {
            if (color == null)
                $(this).val("transparent");
            else
                $(this).val(color);
        });
        var Advanced = $("#cboxPolygonsAdvanced");
        Advanced.change(function(event) {
            var checkbox = event.target;
            var tr_Polygon_Border = $("#tr_Polygon_Border");
            var tr_Polygon_Fill = $("#tr_Polygon_Fill");
            var d;
            if (checkbox.checked) {
                console.log("Advanced On");
                d = "<td>Color</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Polygons_Border_Width\" placeholder=\"\" value=\"" + data.layerConfig.border_width + "\"></textarea></td>";
                tr_Polygon_Border.html(d);

                d = "<tr id=\"tr_Polygon_Border_Color\">";
                d += "<td>Color</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Polygons_Border_Color\" placeholder=\"\" value=\"" + data.layerConfig.border_color + "\"></textarea></td>";
                d += "</tr>";
                tr_Polygon_Border.after(d);

                d = "<tr id=\"tr_Polygon_Border_Opacity\">";
                d += "<td>Opacity</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Polygons_Border_Opacity\" placeholder=\"\" value=\"" + data.layerConfig.border_opacity + "\"></textarea></td>";
                d += "</tr>";
                tr_Polygon_Border.after(d);

                d = "<td>Color</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Polygons_Fill_Color\" placeholder=\"\" value=\"" + data.layerConfig.fill_color + "\"></textarea></td>";
                tr_Polygon_Fill.html(d);

                d = "<tr id=\"tr_Polygon_Fill_Opacity\">";
                d += "<td>Color</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Polygons_Fill_Opacity\" placeholder=\"\" value=\"" + data.layerConfig.fill_opacity + "\"></textarea></td>";
                d += "</tr>";
                tr_Polygon_Fill.after(d);
            } else {
                console.log("Advanced Off");

                d = "<td>Color</td>";
                d += "<td><input type=\"text\" id=\"id_Polygons_Border_Color\" value=\"#ffffff\"  class=\"form-control color\" ></td>";
                d += "<td>Opacity</td>";
                d += "<td><input type=\"number\" id=\"id_Polygons_Border_Opacity\" step=\"0.1\" class=\"form-control\" min=\"0\" max=\"1\" value=\"1\" ></td>";
                d += "<td>Width</td>";
                d += "<td><input type=\"number\" id=\"id_Polygons_Border_Width\"  class=\"form-control\" min=\"0\"></td>";
                tr_Polygon_Border.html(d);

                $("#tr_Polygon_Border_Color").remove();
                $("#tr_Polygon_Border_Opacity").remove();

                d = "<td width=\"80px\">Color</td>";
                d += "<td width=\"80px\"><input type=\"text\" id=\"id_Polygons_Fill_Color\" value=\"#ffffff\"  class=\"form-control color\" ></td>";
                d += "<td width=\"80px\">Opacity</td>";
                d += "<td width=\"80px\"><input type=\"number\" id=\"id_Polygons_Fill_Opacity\" step=\"0.1\" class=\"form-control\" min=\"0\" max=\"1\" value=\"1\" ></td>";
                d += "<td width=\"80px\"> </td>";
                d += "<td width=\"80px\"> </td>";
                tr_Polygon_Fill.html(d);

                $("#tr_Polygon_Fill_Opacity").remove();
            }
        });
    }

    updatePolygonsEvent(id) {
        var JsonToSend = {
            "displayName": $("#LstSHAPE_" + id + "_name_TD").text().replace(/ /g, ""),
            /*"visible": $('#id_Polygons_visibility:checked').val(),*/
            "visible": $("input[name=visibility]:checked").val(),
            "visibilityDistanceMin": $("#id_Polygons_visibility_min").val(),
            "visibilityDistanceMax": $("#id_Polygons_visibility_max").val(),
            /*"altitudeType": $('#id_Polygons_Altitude_type:checked').val(),*/
            "altitudeType": $("input[name=altitudeType]:checked").val(),
            "description": "sampleDesc", //to fill
            "proj": $("#id_Polygons_projection").val(),
            "altText": $("#id_Polygons_Infobulle").val(),
            "status": "UNPUBLISHED",
            "boundingBox": {
                "xmin": $("#id_Polygons_bbox_x_min").val(),
                "xmax": $("#id_Polygons_bbox_x_max").val(),
                "ymin": $("#id_Polygons_bbox_y_min").val(),
                "ymax": $("#id_Polygons_bbox_y_max").val()
            },
            "layerConfig": {
                "border_color": $("#id_Polygons_Border_Color").val(),
                "border_width": $("#id_Polygons_Border_Width").val(),
                "border_opacity": $("#id_Polygons_Border_Opacity").val(),
                "fill_color": $("#id_Polygons_Fill_Color").val(),
                "fill_opacity": $("#id_Polygons_Fill_Opacity").val(),
                "extrusion": $("#id_Polygons_Altitude").val()
            }
        };
        $("#box_createLayer").remove();
        console.log(JsonToSend);
        for (var key in JsonToSend) {
            if (JsonToSend.hasOwnProperty(key)) {

                if (JsonToSend[key] == null || JsonToSend[key] == "") {
                    delete JsonToSend[key];
                }
                for (var key2 in JsonToSend[key]) {
                    if (JsonToSend[key].hasOwnProperty(key2)) {
                        if (JsonToSend[key][key2] == null || JsonToSend[key][key2] == "") {
                            delete JsonToSend[key][key2];
                        }
                    }
                }
            }
        }
        this.controller.updateStudyAreaLayer(id, JsonToSend, "box_createPolygonsLayer");
    }

    updatePolygonsLayer(id) {
        $("#id_CreatePolygonsLayer_Go").click((event) => {
            this.updatePolygonsEvent(id);
        });
    }

    removeDom(id) {
        $("#" + id).remove();

    }

    /*updatePolylinesLayer*/
    createboxPolylinesForm(d) {
        this.createboxPolylinesFormHtml(d, () => {
            window.clearInterval(this.timer_selectFile);
        });
    }

    createboxPolylinesFormHtml(data, CallBackClose) {

        $("#box_createPolylinesLayer").remove();

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if (data[key] == null) {
                    data[key] = "";
                }
                for (let key2 in data[key]) {
                    if (data[key].hasOwnProperty(key2)) {
                        if (data[key][key2] == null) {
                            data[key][key2] = "";
                        }
                    }
                }
            }
        }

        //TODO why copy & paste code to create DOM, why not use a template?  it makes the translation hardly maintained
        let c = `<div id="PolygonsForm_content" style="width:100%; height:100%;">`;
        c += `   <table style="width:100%; height:100%;">`;
        // Advanced mode is too bugged
        //c += '        <tr style="height: 50px;">';
        //c += '            <td>Advanced Mode</td>';
        //c += '            <td><input type="checkbox" id="cboxPolylineAdvanced" value="checkbox2"></td>';
        //c += '        </tr>';

        // Projection
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.projection}">Projection</td>`;

        //le variable data n"est pas passé comme parametre
        c += `            <td colspan="5"><input type="text" class="form-control" id="id_Polylines_projection" value="${data.proj}" autocomplete="off"></td>`;
        c += `        </tr>`;

        // Infobulle
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.toolTip}">Infobulle</td>`;
        c += `            <td colspan="5"><input type="text" class="form-control" id="id_Polylines_Infobulle" value="${data.altText}" autocomplete="off"></td>`;
        c += `        </tr>`;

        // BBOX
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.bbox}">Bounding Box</b></td></tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxXMin}">X min</td>`;
        c += `            <td colspan="2"><input id="id_Polylines_bbox_x_min" class="form-control" type="number" step="0.1" name="bbox_x_min" value="${data.boundingBox.xmin}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxXMax}">X max</td>`;
        c += `            <td colspan="2"><input id="id_Polylines_bbox_x_max" class="form-control" type="number" step="0.1" name="bbox_x_max" value="${data.boundingBox.xmax}"></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxYmin}">Y min</td>`;
        c += `            <td colspan="2"><input id="id_Polylines_bbox_y_min" class="form-control" type="number" step="0.1" name="bbox_x_min" value="${data.boundingBox.ymin}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxYmax}">Y max</td>`;
        c += `            <td colspan="2"><input id="id_Polylines_bbox_y_max" class="form-control" type="number" step="0.1" name="bbox_x_max" value="${data.boundingBox.ymax}"></td>`;
        c += `        </tr>`;

        //altitudeType
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.altitudeType}">Altitude Type</b></td>`;
        c += `            <td><form><label for="id_Polylines_Altitude_type_abs" data-bind="${this.updateLayerLabelBinding.altitudeTypeAbsolute}">Absolute</label> <input type="radio" name="altitudeType"  value="absolute" id="id_Polylines_Altitude_type_abs" ${data.altitudeType === "absolute" ? "checked" : ""}><label for="id_Polylines_Altitude_type_rel" data-bind="${this.updateLayerLabelBinding.altitudeTypeRelative}">Relative</label> <input type="radio" name="altitudeType"  value="relative" id="id_Polylines_Altitude_type_rel" ${data.altitudeType === "relative" ? "checked" : ""}></form></td>`;
        c += `        </tr>`;

        // Visibility
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.visibility}">Visibility</b></td>`;
        c += `            <td><form><label for="id_Polylines_visibility_true" data-bind="${this.updateLayerLabelBinding.visibilityYes}">Yes</label><input type="radio" name="visibility"  value="true" id="id_Polylines_visibility_true" ${data.visible ? "checked" : ""}><label for="id_Polylines_visibility_false" data-bind="${this.updateLayerLabelBinding.visibilityNo}">No</label><input type="radio" name="visibility"  value="false" id="id_Polylines_visibility_false" ${!data.visible ? "checked" : ""}></form></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td>Min</td>`;
        c += `            <td colspan="2"><input id="id_Polylines_visibility_min" class="form-control" type="number" step="0.1" name="visibility_min" value="${data.visibilityDistanceMin}"></td>`;
        c += `            <td>Max</td>`;
        c += `            <td colspan="2"><input id="id_Polylines_visibility_max" class="form-control" type="number" step="0.1" name="visibility_max" value="${data.visibilityDistanceMax}"></td>`;
        c += `        </tr>`;

        // Specific Polylines attributes
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.border}">Border</b></td></tr>`;
        c += `        <tr style="height: 50px;" id="tr_Polyline_Border">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.color}">Color</td>`;
        c += `            <td><input type="text" id="id_Polylines_Border_Color" value="${data.layerConfig.border_color}"  class="form-control color" ></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.opacity}">Opacity</td>`;
        c += `            <td><input type="text" id="id_Polylines_Border_Opacity" step="0.1" class="form-control" min="0" max="1" value="${data.layerConfig.border_opacity}" ></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.width}">Width</td>`;
        c += `            <td><input type="text" id="id_Polylines_Border_Width"  class="form-control" min="0" value="${data.layerConfig.border_width}"></td>`;
        c += `        </tr>`;

        // Scale
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.line}">Line</b></td></tr>`;
        c += `        <tr style="height: 50px;" id="tr_Polyline_Line">`;
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.color}">Color</td>`;
        c += `            <td width="80px"><input type="text" id="id_Polylines_Line_Color" value="${data.layerConfig.line_color}"  class="form-control color" ></td>`;
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.opacity}">Opacity</td>`;
        c += `            <td width="80px"><input type="text" id="id_Polylines_Line_Opacity" step="0.1" class="form-control" min="0" max="1" value="${data.layerConfig.line_opacity}" ></td>`;
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.width}">Width</td>`;
        c += `            <td width="80px"><input type="text" id="id_Polylines_Line_Width"  class="form-control" min="0" value="${data.layerConfig.line_width}"></td>`;
        c += `        </tr>`;

        // Altitude
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.altitude}">Altitude</b></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.altitude}">Altitude</td>`;
        c += `            <td colspan="5"><input id="id_Polylines_Altitude" class="form-control" type="text" step="0.1" min="0" value="${data.layerConfig.altitude}"></td>`;
        c += `        </tr>`;
        c += `    </table>`;
        c += `</div>`;
        let f = `<div class="pull-right" ><button class="btn btn-primary use-skin" id="id_CreatePolylinesLayer_Go" data-bind="${this.updateLayerLabelBinding.updateButton}">Update</button></div>`;

        this.UI.CreateBox("createPolylinesLayer", {
            draggable: true,
            group: "SuperUser",
            useSkin: true,
            title: this.contentFileJSON.MENU_UPDATE_LAYER,
            tag: "MENU_UPDATE_LAYER",
            content: c,
            addOverlay: true,
            footer: f,
            visible: true,
            menuBt: {
                close: true
            },
            style: {
                position: "absolute",
                top: "10%",
                left: "30%",
                width: "35%",
                height: "80%",
                "z-index": Zindex + 1
            }
        }, CallBackClose);
        //$(document).delegate("#id_CreatePolylinesLayer_Go","click",this.updatePolylinesEvent.bind(this));

        // post apply bindings for the dom element
        ko.applyBindings({}, document.getElementById("box_createPolylinesLayer"));


        Zindex = Zindex + 1;
        var colorbox = $(".color");
        colorbox.colorpickerplus();
        colorbox.on("changeColor", function(e, color) {
            if (color == null)
                $(this).val("transparent");
            else
                $(this).val(color);
        });
        var Advanced = $("#cboxPolylineAdvanced");
        Advanced.change(function(event) {
            var checkbox = event.target;
            var tr_Polyline_Border = $("#tr_Polyline_Border");
            var tr_Polyline_Line = $("#tr_Polyline_Line");
            var d;
            if (checkbox.checked) {
                console.log("Advanced On");
                d = "<td>Color</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Polylines_Border_Width\" placeholder=\"\" value=\"" + data.layerConfig.border_width + "\"></textarea></td>";
                tr_Polyline_Border.html(d);

                d = "<tr id=\"tr_Polyline_Border_Color\">";
                d += "<td>Color</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Polylines_Border_Color\" placeholder=\"\" value=\"" + data.layerConfig.border_color + "\"></textarea></td>";
                d += "</tr>";
                tr_Polyline_Border.after(d);

                d = "<tr id=\"tr_Polyline_Border_Opacity\">";
                d += "<td>Opacity</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Polylines_Border_Opacity\" placeholder=\"\" value=\"" + data.layerConfig.border_opacity + "\"></textarea></td>";
                d += "</tr>";
                tr_Polyline_Border.after(d);

                d = "<td>Color</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Polylines_Line_Width\" placeholder=\"\" value=\"" + data.layerConfig.line_width + "\"></textarea></td>";
                tr_Polyline_Line.html(d);

                d = "<tr id=\"tr_Polyline_Line_Color\">";
                d += "<td>Color</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Polylines_Line_Color\" placeholder=\"\" value=\"" + data.layerConfig.line_color + "\"></textarea></td>";
                d += "</tr>";
                tr_Polyline_Line.after(d);

                d = "<tr id=\"tr_Polyline_Line_Opacity\">";
                d += "<td>Opacity</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Polylines_Line_Opacity\" placeholder=\"\" value=\"" + data.layerConfig.line_opacity + "\" ></textarea></td>";
                d += "</tr>";
                tr_Polyline_Line.after(d);

            } else {
                console.log("Advanced Off");

                d = "<td>Color</td>";
                d += "<td><input type=\"text\" id=\"id_Polylines_Border_Color\" value=\"" + data.layerConfig.border_color + "\"  class=\"form-control color\" ></td>";
                d += "<td>Opacity</td>";
                d += "<td><input type=\"number\" id=\"id_Polylines_Border_Opacity\" step=\"0.1\" class=\"form-control\" min=\"0\" max=\"1\" value=\"" + data.layerConfig.border_opacity + "\" ></td>";
                d += "<td>Width</td>";
                d += "<td><input type=\"number\" id=\"id_Polylines_Border_Width\"  class=\"form-control\" min=\"0\" value=\"" + data.layerConfig.border_width + "\"></td>";
                tr_Polyline_Border.html(d);

                $("#tr_Polyline_Border_Color").remove();
                $("#tr_Polyline_Border_Opacity").remove();

                d = "<td>Color</td>";
                d += "<td><input type=\"text\" id=\"id_Polylines_Line_Color\" value=\"" + data.layerConfig.line_color + "\"  class=\"form-control color\" ></td>";
                d += "<td>Opacity</td>";
                d += "<td><input type=\"number\" id=\"id_Polylines_Line_Opacity\" step=\"0.1\" class=\"form-control\" min=\"0\" max=\"1\" value=\"" + data.layerConfig.line_opacity + "\" ></td>";
                d += "<td>Width</td>";
                d += "<td><input type=\"number\" id=\"id_Polylines_Line_Width\"  class=\"form-control\" min=\"0\" value=\"" + data.layerConfig.line_width + "\"></td>";
                tr_Polyline_Border.html(d);

                $("#tr_Polyline_Line_Color").remove();
                $("#tr_Polyline_Line_Opacity").remove();


            }
        });
    }

    updatePolylinesEvent(id) {

        var JsonToSend = {
            "displayName": $("#LstSHAPE_" + id + "_name_TD").text().replace(/ /g, ""),
            /*"visible": $('#id_Polylines_visibility:checked').val(),*/
            "visible": $("input[name=visibility]:checked").val(),
            "visibilityDistanceMin": $("#id_Polylines_visibility_min").val(),
            "visibilityDistanceMax": $("#id_Polylines_visibility_max").val(),
            /*"altitudeType": $('#id_Polylines_Altitude_type:checked').val(),*/
            "altitudeType": $("input[name=altitudeType]:checked").val(),
            "description": "sampleDesc", //to fill
            "proj": $("#id_Polylines_projection").val(),
            "altText": $("#id_Polylines_Infobulle").val(),
            "status": "UNPUBLISHED",
            "boundingBox": {
                "xmin": $("#id_Polylines_bbox_x_min").val(),
                "xmax": $("#id_Polylines_bbox_x_max").val(),
                "ymin": $("#id_Polylines_bbox_y_min").val(),
                "ymax": $("#id_Polylines_bbox_y_max").val()
            },
            "layerConfig": {
                "border_color": $("#id_Polylines_Border_Color").val(),
                "border_width": $("#id_Polylines_Border_Width").val(),
                "border_opacity": $("#id_Polylines_Border_Opacity").val(),
                "line_color": $("#id_Polylines_Line_Color").val(),
                "line_width": $("#id_Polylines_Line_Width").val(),
                "line_opacity": $("#id_Polylines_Line_Opacity").val(),
                "altitude": $("#id_Polylines_Altitude").val()
            }
        };
        $("#box_createLayer").remove();
        console.log(JsonToSend);
        for (var key in JsonToSend) {
            if (JsonToSend.hasOwnProperty(key)) {

                if (JsonToSend[key] == null || JsonToSend[key] == "") {
                    delete JsonToSend[key];
                }
                for (var key2 in JsonToSend[key]) {
                    if (JsonToSend[key].hasOwnProperty(key2)) {
                        if (JsonToSend[key][key2] == null || JsonToSend[key][key2] == "") {
                            delete JsonToSend[key][key2];
                        }
                    }
                }
            }
        }
        this.controller.updateStudyAreaLayer(id, JsonToSend, "box_createPolylinesLayer");
    }

    updatePolylinesLayer(id) {
        $("#id_CreatePolylinesLayer_Go").click((event) => {
            this.updatePolylinesEvent(id);
        });
    }

    /*update3DObjectsLayer*/
    createbox3DObjectsForm(d) {
        this.createbox3DObjectsFormHtml(d, () => {
            window.clearInterval(this.timer_selectFile);
        });
    }

    createbox3DObjectsFormHtml(data, CallBackClose) {
        $("#box_create3DObjectsLayer").remove();

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if (data[key] == null) {
                    data[key] = "";
                }
                for (let key2 in data[key]) {
                    if (data[key].hasOwnProperty(key2)) {
                        if (data[key][key2] == null) {
                            data[key][key2] = "";
                        }
                    }
                }
            }
        }

        let c = `<div id="3DObjectsForm_content" style="width:100%; height:100%;">`;
        c += `   <table style="width:100%; height:100%;">`;
        //Projection
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.projection}">Projection</td>`;
        c += `            <td colspan="5"><input type="text" class="form-control" id="id_3D_projection" value="${data.proj}" autocomplete="off"></td>`;
        c += `        </tr>`;

        //Infobulle
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.toolTip}">Infobulle</td>`;
        c += `            <td colspan="5"><input type="text" class="form-control" id="id_3D_Infobulle" value="${data.altText}" autocomplete="off"></td>`;
        c += `        </tr>`;

        //BBOX
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.bbox}">Bounding Box</b></td></tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxXMin}">X min</td>`;
        c += `            <td colspan="2"><input id="id_3D_bbox_x_min" class="form-control" type="number" step="0.1" name="bbox_x_min"  value="${data.boundingBox.xmin}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxXMax}">X max</td>`;
        c += `            <td colspan="2"><input id="id_3D_bbox_x_max" class="form-control" type="number" step="0.1" name="bbox_x_max" value="${data.boundingBox.xmax}"></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxYmin}">Y min</td>`;
        c += `            <td colspan="2"><input id="id_3D_bbox_y_min" class="form-control" type="number" step="0.1" name="bbox_x_min" value="${data.boundingBox.ymin}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxYmax}">Y max</td>`;
        c += `            <td colspan="2"><input id="id_3D_bbox_y_max" class="form-control" type="number" step="0.1" name="bbox_x_max" value="${data.boundingBox.ymax}"></td>`;
        c += `        </tr>`;

        //Visibility
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.visibility}">Visibility</b></td>`;
        c += `            <td><form><label for="id_3D_visibility_true" data-bind="${this.updateLayerLabelBinding.visibilityYes}">Yes</label><input type="radio" name="visibility"  value="true" id="id_3D_visibility_true" ${data.visible ? "checked" : ""}><label for="id_3D_visibility_false" data-bind="${this.updateLayerLabelBinding.visibilityNo}">No</label><input type="radio" name="visibility"  value="false" id="id_3D_visibility_false" ${!data.visible ? "checked" : ""}></form></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.minDistance}">Min</td>`;
        c += `            <td colspan="2"><input id="id_3D_visibility_min" class="form-control" type="number" step="0.1" name="visibility_min" value="${data.visibilityDistanceMin}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.maxDistance}">Max</td>`;
        c += `            <td colspan="2"><input id="id_3D_visibility_max" class="form-control" type="number" step="0.1" name="visibility_max" value="${data.visibilityDistanceMax}"></td>`;
        c += `        </tr>`;

        //altitudeType
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.altitudeType}">Altitude Type</b></td>`;
        c += `            <td><form><label for="id_3D_Altitude_type_abs" data-bind="${this.updateLayerLabelBinding.altitudeTypeAbsolute}">Absolute</label><input type="radio" name="altitudeType"  value="absolute" id="id_3D_Altitude_type_abs" ${data.altitudeType === "absolute" ? "checked" : ""}><label for="id_3D_Altitude_type_rel" data-bind="${this.updateLayerLabelBinding.altitudeTypeRelative}">Relative</label><input type="radio" name="altitudeType"  value="relative" id="id_3D_Altitude_type_rel" ${data.altitudeType === "relative" ? "checked" : ""}></form></td>`;
        c += `        </tr>`;

        //Specific 3DObjects attributes
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.attributes3DObjects}">Specific 3DObjects attributes</b></td></tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.imageURL}">Image url</td>`;
        c += `            <td colspan="5"><input id="id_3D_3DObjects_img_url" class="form-control" type="text" value="${data.layerConfig.url}" autocomplete="off"></td>`;
        c += `        </tr>`;

        //Scale
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.scale}">Scale</b></td></tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.scaleX}">X</td>`;
        c += `            <td><input id="id_3D_3DObjects_Scale_X" class="form-control" type="text" step="0.1" name="3DObjects_Scale_X" value="${data.layerConfig.scale_x}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.scaleY}">Y</td>`;
        c += `            <td><input id="id_3D_3DObjects_Scale_Y" class="form-control" type="text" step="0.1" name="3DObjects_Scale_Y" value="${data.layerConfig.scale_y}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.scaleZ}">Z</td>`;
        c += `            <td><input id="id_3D_3DObjects_Scale_Z" class="form-control" type="text" step="0.1" name="3DObjects_Scale_Z" value="${data.layerConfig.scale_z}"></td>`;
        c += `        </tr>`;

        //Rotation
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.rotation}">Rotation</b></td></tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.rotationPitch}">Pitch</td>`;
        c += `            <td width="80px"><input id="id_3D_3DObjects_Rotation_P" class="form-control" type="text" step="0.1" name="3DObjects_Rotation_P" value="${data.layerConfig.rot_p}"></td>`; //je ne suis pas sure de cette valeur
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.rotationYaw}">Yaw</td>`;
        c += `            <td width="80px"><input id="id_3D_3DObjects_Rotation_Y" class="form-control" type="text" step="0.1" name="3DObjects_Rotation_Y"  value="${data.layerConfig.rot_y}"></td>`;
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.rotationRoll}">Roll</td>`;
        c += `            <td width="80px"><input id="id_3D_3DObjects_Rotation_R" class="form-control" type="text" step="0.1" name="3DObjects_Rotation_R"  value="${data.layerConfig.rot_r}"></td>`; //je ne suis pas sure de cette valeur
        c += `        </tr>`;
        // Altitude
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.altitude}">Altitude</b></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.altitude}">Altitude</td>`;
        c += `            <td colspan="5"><input id="id_3D_3DObjects_Altitude" class="form-control" type="text" step="0.1" name="3DObjects_Altitude" value="${data.layerConfig.altitude}"></td>`;
        c += `        </tr>`;
        c += `    </table>`;
        c += `</div>`;
        let f = `<div class="pull-right" ><button class="btn btn-primary use-skin" id="id_Create3DObjectsLayer_Go" data-bind="${this.updateLayerLabelBinding.updateButton}">Update</button></div>`;

        this.UI.CreateBox("create3DObjectsLayer", {
            draggable: true,
            group: "SuperUser",
            useSkin: true,
            title: this.contentFileJSON.MENU_UPDATE_LAYER,
            tag: "MENU_UPDATE_LAYER",
            content: c,
            addOverlay: true,
            footer: f,
            visible: true,
            menuBt: {
                close: true
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "10%",
                left: "30%",
                width: "35%",
                height: "80%",
                "z-index": Zindex + 1
            }
        }, CallBackClose);
        Zindex = Zindex + 1;

        // post apply bindings for the dom element
        ko.applyBindings({}, document.getElementById("box_create3DObjectsLayer"));

        //$(document).delegate("#id_Create3DObjectsLayer_Go","click",this.update3DObjectEvent.bind(this));
    }

    update3DObjectEvent(id) {
        var JsonToSend = {
            "displayName": $("#LstSHAPE_" + id + "_name_TD").text().replace(/ /g, ""),
            /*"visible": $('#id_3D_visibility:checked').val(),*/
            "visible": $("input[name=visibility]:checked").val(),
            "visibilityDistanceMin": $("#id_3D_visibility_min").val(),
            "visibilityDistanceMax": $("#id_3D_visibility_max").val(),
            /*"altitudeType": $('#id_3D_Altitude_type:checked').val(),*/
            "altitudeType": $("input[name=altitudeType]:checked").val(),
            "description": "sampleDesc", //to fill
            "proj": $("#id_3D_projection").val(),
            "altText": $("#id_3D_Infobulle").val(),
            "status": "UNPUBLISHED",
            "boundingBox": {
                "xmin": $("#id_3D_bbox_x_min").val(),
                "xmax": $("#id_3D_bbox_x_max").val(),
                "ymin": $("#id_3D_bbox_y_min").val(),
                "ymax": $("#id_3D_bbox_y_max").val()
            },
            "layerConfig": {
                "url": $("#id_3D_3DObjects_img_url").val(),
                "scale_x": $("#id_3D_3DObjects_Scale_X").val(),
                "scale_y": $("#id_3D_3DObjects_Scale_Y").val(),
                "scale_z": $("#id_3D_3DObjects_Scale_Z").val(),
                "rot_p": $("#id_3D_3DObjects_Rotation_P").val(),
                "rot_r": $("#id_3D_3DObjects_Rotation_R").val(),
                "rot_x": $("#id_3D_3DObjects_Rotation_P").val(),
                "rot_y": $("#id_3D_3DObjects_Rotation_Y").val(),
                "rot_z": $("#id_3D_3DObjects_Rotation_R").val(),
                "altitude": $("#id_3D_3DObjects_Altitude").val(),
                "description": "sampleDesc" //to fill
            }
        };
        $("#box_createLayer").remove();
        console.log(JsonToSend);
        for (var key in JsonToSend) {
            if (JsonToSend.hasOwnProperty(key)) {

                if (JsonToSend[key] == null || JsonToSend[key] == "") {
                    delete JsonToSend[key];
                }
                for (var key2 in JsonToSend[key]) {
                    if (JsonToSend[key].hasOwnProperty(key2)) {
                        if (JsonToSend[key][key2] == null || JsonToSend[key][key2] == "") {
                            delete JsonToSend[key][key2];
                        }
                    }
                }
            }
        }
        this.controller.updateStudyAreaLayer(id, JsonToSend, "box_create3DObjectsLayer");
    }

    update3DObjectsLayer(id) {
        //  this.UI.newBox.createbox3DObjectsForm(function () {
        //      window.clearInterval(this.timer_selectFile);
        //  }.bind(this));
        $("#id_Create3DObjectsLayer_Go").click((event) => {
            this.update3DObjectEvent(id);
        });
    }

    /*updateLabelsLayer*/
    createboxLabelsForm(d) {
        this.createboxLabelsFormHtml(d, () => {
            window.clearInterval(this.timer_selectFile);
        });
    }

    createboxLabelsFormHtml(data, CallBackClose) {
        $("#box_createLabelsLayer").remove();

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if (data[key] == null) {
                    data[key] = "";
                }
                for (let key2 in data[key]) {
                    if (data[key].hasOwnProperty(key2)) {
                        if (data[key][key2] == null) {
                            data[key][key2] = "";
                        }
                    }
                }
            }
        }

        let c = `<div id="LabelsForm_content" style="width:100%; height:100%;">`;

        c += `   <table style="width:100%; height:100%;">`;
        // Advanced mode is too bugged
        //c += '        <tr style="height: 50px;">';
        //c += '            <td>Advanced Mode</td>';
        //c += '            <td><input type="checkbox" id="cboxLabelAdvanced" value="checkbox1"></td>';
        //c += '        </tr>';

        //projection
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.projection}">Projection</td>`;
        c += `            <td colspan="5"><input type="text" class="form-control" id="id_Labels_projection" value="${data.proj}" autocomplete="off"></td>`;
        c += `        </tr>`;

        //Infobulle
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.toolTip}">Infobulle</td>`;
        c += `            <td colspan="5"><input type="text" class="form-control" id="id_Labels_Infobulle" value="${data.altText}" autocomplete="off"></td>`;
        c += `        </tr>`;

        //BBOX
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.bbox}">Bounding Box</b></td></tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxXMin}">X min</td>`;
        c += `            <td colspan="2"><input id="id_Labels_bbox_x_min" class="form-control" type="number" step="0.1" name="bbox_x_min" value="${data.boundingBox.xmin}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxXMax}">X max</td>`;
        c += `            <td colspan="2"><input id="id_Labels_bbox_x_max" class="form-control" type="number" step="0.1" name="bbox_x_max" value="${data.boundingBox.xmax}"></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxYmin}">Y min</td>`;
        c += `            <td colspan="2"><input id="id_Labels_bbox_y_min" class="form-control" type="number" step="0.1" name="bbox_x_min" value="${data.boundingBox.ymin}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.bboxYmax}">Y max</td>`;
        c += `            <td colspan="2"><input id="id_Labels_bbox_y_max" class="form-control" type="number" step="0.1" name="bbox_x_max" value="${data.boundingBox.ymax}"></td>`;
        c += `        </tr>`;

        //Visibility
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.visibility}">Visibility</b></td>`;
        c += `            <td><form><label for="id_Labels_visibility_true" data-bind="${this.updateLayerLabelBinding.visibilityYes}">Yes</label><input type="radio" name="visibility"  value="true" id="id_Labels_visibility_true" ${data.visible ? "checked" : ""}><label for="id_Labels_visibility_false" data-bind="${this.updateLayerLabelBinding.visibilityNo}">No</label><input type="radio" name="visibility"  value="false" id="id_Labels_visibility_false" ${!data.visible ? "checked" : ""}></form></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.minDistance}">Min</td>`;
        c += `            <td colspan="2"><input id="id_Labels_visibility_min" class="form-control" type="number" step="0.1" name="visibility_min" value="${data.visibilityDistanceMin}"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.maxDistance}">Max</td>`;
        c += `            <td colspan="2"><input id="id_Labels_visibility_max" class="form-control" type="number" step="0.1" name="visibility_max" value="${data.visibilityDistanceMax}"></td>`;
        c += `        </tr>`;

        //altitudeType
        c += `        <tr></tr>`;
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.altitudeType}">Altitude Type</b></td>`;
        c += `            <td><form><label for="id_Labels_Altitude_type_abs" data-bind="${this.updateLayerLabelBinding.altitudeTypeAbsolute}">Absolute</label><input type="radio" name="altitudeType"  value="absolute" id="id_Labels_Altitude_type_abs" ${data.altitudeType === "absolute" ? "checked" : ""}><label for="id_Labels_Altitude_type_rel" data-bind="${this.updateLayerLabelBinding.altitudeTypeRelative}">Relative</label><input type="radio" name="altitudeType"  value="relative" id="id_Labels_Altitude_type_rel" ${data.altitudeType === "relative" ? "checked" : ""}></form></td>`;
        c += `        </tr>`;

        //Text
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.textTitle}">Text</b></td></tr>`;

        //Content
        c += `        <tr style="height: 50px;">`;
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.textContent}">Content</td>`;
        c += `            <td colspan="5"><input type="text" class="form-control" id="id_Labels_Text_Content" value="${data.layerConfig.text}" autocomplete="off"></td>`;
        c += `        </tr>`;

        //Color
        c += `        <tr style="height: 50px;" id="tr_Label_Text_Color">`;
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.color}">Color</td>`;
        c += `            <td colspan="5"><input type="text" class="form-control color" id="id_Labels_Text_Color" value="${data.layerConfig.txt_color}" autocomplete="off"></td>`;
        c += `        </tr>`;

        //Size
        c += `        <tr style="height: 50px;" id="tr_Label_Text_Size">`;
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.size}">Size</td>`;
        c += `            <td colspan="5" ><input type="text" class="form-control" id="id_Labels_Text_Size" step="0.1" value="${data.layerConfig.txt_size}" autocomplete="off"></td>`;
        c += `        </tr>`;

        //Font
        c += `        <tr style="height: 50px;">`;
        c += `            <td width="80px" data-bind="${this.updateLayerLabelBinding.textFont}">Font</td>`;
        c += `            <td colspan="5"><div class="styledSelect" style="width:100%;">`;
        c += `                <select name="Labels_Font" id="id_Labels_Font" value="${data.layerConfig.txt_font}" >`;
        c += `                    <option value="Roboto">Roboto</option>`;
        c += `                    <option value="RobotoCondensed">Roboto Condensed</option>`;
        c += `                    <option value="Arial">Arial</option>`;
        c += `                </select>`;
        c += `            </div></td>`;
        c += `        </tr>`;

        //Bold Italic Underline
        c += `        <tr style="height: 50px;">`;
        c += `            <td width="80px"></td>`;
        c += `            <td width="80px"><b data-bind="${this.updateLayerLabelBinding.textFontBold}">Bold</b><input type="checkbox" id="id_Labels_cboxB" value="Bold" name="Labels_Text_Bold" ></td>`;
        c += `            <td width="80px"><b data-bind="${this.updateLayerLabelBinding.textFontItalic}">Italic</b><input type="checkbox" id="id_Labels_cboxI" value="Italic" name="Labels_Text_Italic"></td>`;
        c += `            <td width="80px"><b data-bind="${this.updateLayerLabelBinding.textFontUnderline}">Underline</b><input type="checkbox" id="id_Labels_cboxU" value="Underline" name="Labels_Text_Underline"></td>`;
        c += `            <td width="80px"> </td>`;
        c += `            <td width="80px"> </td>`;
        c += `        </tr>`;

        //Background
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.textBackground}">Background</b></td></tr>`;
        c += `        <tr style="height: 50px;" id="tr_Label_Bg">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.color}">Color</td>`;
        c += `            <td colspan="2"><input id="id_Labels_Background_Color" value="${data.layerConfig.bg_color}" type="text" class="form-control color" name="Labels_Background_Color"></td>`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.opacity}">Opacity</td>`;
        c += `            <td colspan="2"><input type="text" id="id_Labels_Background_Opacity" step="0.1" class="form-control" min="0" max="1" value="${data.layerConfig.bg_opacity}" name="Labels_Background_Opacity"></td>`;
        c += `        </tr>`;

        //Images
        c += `        <tr style="height: 100px;"><td colspan="3"><b data-bind="${this.updateLayerLabelBinding.textImage}">Image</b></td></tr>`;

        //URL
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.textImageUrl}">URL</td>`;
        c += `            <td colspan="5"><input type="text" class="form-control" id="id_Labels_Image_URL" value="${data.layerConfig.image_url}" autocomplete="off"></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.size}">Size</td>`;
        c += `            <td colspan="5"><input type="text" class="form-control" id="id_Labels_Image_Size" name="Labels_Image_Size" value="${data.layerConfig.image_size}"></td>`;
        c += `        </tr>`;

        // Line to the ground
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.textLineToGround}">Line to the ground</b></td>`;
        c += `            <td><form><label for="id_Label_Line_true" data-bind="${this.updateLayerLabelBinding.visibilityYes}">Yes</label><input type="radio" name="visibilityLTG"  value="true" id="id_Label_Line_true"  checked><label for="id_Label_Line_false" data-bind="${this.updateLayerLabelBinding.visibilityNo}">No</label><input type="radio" name="visibilityLTG"  value="false" id="id_Label_Line_false" ></form></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;" id="tr_Label_Line_Color">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.color}">Color</td>`;
        c += `            <td colspan="5"><input id="id_Labels_Line_Color" value="${data.layerConfig.line_color}" type="text" class="form-control color" name="Labels_Background_Color"></td>`;
        c += `        </tr>`;

        // Altitude
        c += `        <tr style="height: 100px;">`;
        c += `            <td colspan="3"><b data-bind="${this.updateLayerLabelBinding.altitude}">Altitude</b></td>`;
        c += `        </tr>`;
        c += `        <tr style="height: 50px;">`;
        c += `            <td data-bind="${this.updateLayerLabelBinding.altitude}">Altitude</td>`;
        c += `            <td colspan="5"><input id="id_Label_Altitude" class="form-control" type="text" step="0.1" name="Label_Altitude" value="${data.layerConfig.altitude}"></td>`;
        c += `        </tr>`;
        c += `    </table>`;
        c += `</div>`;

        let f = `<div class="pull-right" ><button class="btn btn-primary use-skin" id="id_CreateLabelsLayer_Go" data-bind="${this.updateLayerLabelBinding.updateButton}">Update</button></div>`;

        this.UI.CreateBox("createLabelsLayer", {
            draggable: true,
            group: "SuperUser",
            useSkin: true,
            title: this.contentFileJSON.MENU_UPDATE_LAYER,
            tag: "MENU_UPDATE_LAYER",
            content: c,
            addOverlay: true,
            footer: f,
            visible: true,
            menuBt: {
                close: true
            },
            style: {
                position: "absolute",
                top: "10%",
                left: "30%",
                width: "35%",
                height: "80%",
                "z-index": Zindex + 1
            }
        }, CallBackClose);

        // post apply bindings for the dom element
        ko.applyBindings({}, document.getElementById("box_createLabelsLayer"));

        var colorbox = $(".color");
        colorbox.colorpickerplus();
        colorbox.on("changeColor", function(e, color) {
            if (color == null)
                $(this).val("transparent");
            else
                $(this).val(color);
        });
        var Advanced = $("#cboxLabelAdvanced");
        Advanced.change(function(event) {
            var checkbox = event.target;
            var td_label_text_size = $("#tr_Label_Text_Size");
            var td_label_text_color = $("#tr_Label_Text_Color");
            var tr_label_bg = $("#tr_Label_Bg");
            var tr_label_line_color = $("#tr_Label_Line_Color");
            var d;
            if (checkbox.checked) {
                console.log("Advanced On");
                d = "<td width=\"80px\">Size</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Labels_Text_Size\" placeholder=\"\" value=\"" + data.layerConfig.txt_size + "\"></textarea></td>";
                td_label_text_size.html(d);

                d = "<td width=\"80px\">Color</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Labels_Text_Color\" placeholder=\"\" value=\"" + data.layerConfig.txt_color + "\"></textarea></td>";
                td_label_text_color.html(d);

                d = "<td>Color</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Labels_Background_Color\" placeholder=\"\" value=\"" + data.layerConfig.bg_color + "\"></textarea></td>";
                tr_label_bg.html(d);

                d = "<tr id=\"tr_Label_Bg_opacity\">";
                d += "<td>Opacity</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Labels_Background_Opacity\" placeholder=\"\" value=\"" + data.layerConfig.bg_opacity + "\"></textarea></td>";
                d += "</tr>";
                tr_label_bg.after(d);

                d = "<td width=\"80px\">Color</td>";
                d += "<td colspan=\"5\" ><textarea class=\"form-control\" rows=\"5\" style=\"resize:none;\" id=\"id_Labels_Line_Color\" placeholder=\"\" value=\"" + data.layerConfig.line_color + "\"></textarea></td>";
                tr_label_line_color.html(d);
            } else {
                console.log("Advanced Off");
                d = "<td width=\"80px\">Size</td>";
                d += "<td colspan=\"5\" ><input type=\"number\" class=\"form-control\" id=\"id_Labels_Text_Size\" step=\"0.1\" value=\"\" autocomplete=\"off\"></td>";
                td_label_text_size.html(d);

                d = "<td width=\"80px\">Color</td>";
                d += "<td colspan=\"5\"><input type=\"text\" class=\"form-control color\" id=\"id_Labels_Text_Color\" value=\"#ffffff\" autocomplete=\"off\"></td>";
                td_label_text_color.html(d);

                d = "<td>Color</td>";
                d += "<td colspan=\"2\"><input id=\"id_Labels_Background_Color\" value=\"#ffffff\" type=\"text\" class=\"form-control color\" name=\"Labels_Background_Color\"></td>";
                d += "<td>Opacity</td>";
                d += "<td colspan=\"2\"><input type=\"number\"id=\"id_Labels_Background_Opacity\" step=\"0.1\" class=\"form-control\" min=\"0\" max=\"1\" value=\"1\" name=\"Labels_Background_Opacity\"></td>";
                tr_label_bg.html(d);

                d = "<td>Color</td>";
                d += "<td colspan=\"5\"><input id=\"id_Labels_Line_Color\" value=\"#ffffff\" type=\"text\" class=\"form-control color\" name=\"Labels_Background_Color\"></td>";
                tr_label_line_color.html(d);
                $("#tr_Label_Bg_opacity").remove();
            }
        });
        //$(document).delegate("#id_CreateLabelsLayer_Go","click",this.updateLabelsEvent.bind(this));

    }

    updateLabelsEvent(id) {
        var JsonToSend = {
            "displayName": $("#LstSHAPE_" + id + "_name_TD").text().replace(/ /g, ""),
            /*"visible": $('#id_Labels_visibility:checked').val(),*/
            "visible": $("input[name=visibility]:checked").val(),
            "visibilityDistanceMin": $("#id_Labels_visibility_min").val(),
            "visibilityDistanceMax": $("#id_Labels_visibility_max").val(),
            /*"altitudeType": $('#id_Labels_Altitude_type:checked').val(),*/
            "altitudeType": $("input[name=altitudeType]:checked").val(),
            "description": "sampleDesc", //to fill
            "proj": $("#id_Labels_projection").val(),
            "altText": $("#id_Labels_Infobulle").val(),
            "status": "UNPUBLISHED",
            "boundingBox": {
                "xmin": $("#id_Labels_bbox_x_min").val(),
                "xmax": $("#id_Labels_bbox_x_max").val(),
                "ymin": $("#id_Labels_bbox_y_min").val(),
                "ymax": $("#id_Labels_bbox_y_max").val()
            },
            "layerConfig": {
                "text": $("#id_Labels_Text_Content").val(),
                "txt_color": $("#id_Labels_Text_Color").val(),
                "txt_size": $("#id_Labels_Text_Size").val(),
                "txt_font": $("#id_Labels_Font").val(),
                "txt_bold": $("#id_Labels_cboxB").val(),
                "txt_italic": $("#id_Labels_cboxI").val(),
                "txt_underline": $("#id_Labels_cboxU").val(),
                "bg_color": $("#id_Labels_Background_Color").val(),
                "bg_opacity": $("#id_Labels_Background_Opacity").val(),
                /*"line_to_ground": $('#id_Label_Line:checked').val(),*/
                "line_to_ground": $("input[name=visibilityLTG]:checked").val(),
                "line_color": $("#id_Labels_Line_Color").val(),
                "image_url": $("#id_Labels_Image_URL").val(),
                "image_size": $("#id_Labels_Image_Size").val(),
                "altitude": $("#id_Label_Altitude").val()
            }
        };
        $("#box_createLayer").remove();
        console.log(JsonToSend);
        for (var key in JsonToSend) {
            if (JsonToSend.hasOwnProperty(key)) {

                if (JsonToSend[key] == null || JsonToSend[key] == "") {
                    delete JsonToSend[key];
                }
                for (var key2 in JsonToSend[key]) {
                    if (JsonToSend[key].hasOwnProperty(key2)) {
                        if (JsonToSend[key][key2] == null || JsonToSend[key][key2] == "") {
                            delete JsonToSend[key][key2];
                        }
                    }
                }
            }
        }
        this.controller.updateStudyAreaLayer(id, JsonToSend, "box_createLabelsLayer");
    }

    updateLabelsLayer(id) {
        //  this.UI.newBox.createboxLabelsForm(function () {
        //      window.clearInterval(this.timer_selectFile);
        //  }.bind(this));
        $("#id_CreateLabelsLayer_Go").click((event) => {
            this.updateLabelsEvent(id);
        });
    }

    /*deleteLayer*/
    confirmDeleteLayer(id) {
        this.UI.createConfirmBoxSU(this.contentMessageFileJSON.MESSAGE_ALERT_DELETE_LAYER_INFO, () => {
            this.controller.deleteStudyArea(id);
        });
    }

    deleteStudyArea(idShape) {
        //refresh the list
        $("#LstSHAPE").DataTable().row("#LstSHAPE_" + idShape).remove().draw(false);
        this.deselectAllLayers();
    }

    /*publishMOSA*/
    publishMOSA() {
        var id = this.UI.classBindData.selectIdEditMOSA;
        if (id == null) {
            this.UI.createAlertSU("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_MUST_CHOOSE_MOSA);
        } else {
            this.controller.getMosa(id).publish(id);
        }
    }

    /*LstMOSA*/
    initLstMOSA() {
        // use the DataTables plugin for creation of MOSA list with Select extension
        // we can define the type of sorting on columns
        // for sorting non-text values, we use alt-string plugin. For example 1,2,3 columns display results with icons, we use alt-string to sort them on the 'alt' tag
        // Do Not Use the scroll stuff, it won't work for "HIDDEN TABLE"
        $("#LstMOSA").DataTable({
            // "fixedHeader": true,
            // "responsive": true,
            // "bAutoWidth": true,
            // "scrollY": 200,
            // "scrollX": true,
            "lengthMenu": [ 6, 60, 100 ],
            "order": [
                [4, "desc"], [0, "asc"], [3, "asc"]
            ],
            "columnDefs": [
                {
                    "targets": 0,
                    "orderable": true
                }, {
                    "targets": 1,
                    "order": [
                        [1, "asc"],
                        [4, "desc"]
                    ]
                }, {
                    "targets": 2,
                    "order": [
                        [2, "asc"],
                        [4, "desc"]
                    ]
                }, {
                    "targets": 3,
                    "order": [
                        [3, "asc"],
                        [4, "desc"]
                    ]
                }, {
                    "targets": 5,
                    "visible": false
                }, {
                    type: "alt-string",
                    targets: 0
                }, {
                    type: "alt-string",
                    targets: 1
                }, {
                    type: "alt-string",
                    targets: 2
                }, {
                    type: "alt-string",
                    targets: 3
                }
            ]
            //select:true

        });

        var table = $("#LstMOSA").DataTable();

        var self = this;
        $("#LstMOSA tbody").on("click", "tr", function() {
            if ($(this).find(".dataTables_empty").length > 0 || $(this).hasClass("selected")) {
                $(this).removeClass("selected");
                $("#LstMOSA_publish").prop("disabled", true);
                $("#LstMOSA_Link_Shape").prop("disabled", true);
                $("#LstMOSA_update_Model").prop("disabled", true);
                $("#LstMOSA_update_3D").prop("disabled", true);
                $("#LstMOSA_delete").prop("disabled", true);
                $("#LstMOSA_load").prop("disabled", true);
                self.UI.classBindData.selectIdEditMOSA = null;
                self.UI.classBindData.selectSAMOSA = null;
            } else {
                table.$("tr.selected").removeClass("selected");
                $(this).addClass("selected");
                $("#LstMOSA_publish").prop("disabled", false);
                $("#LstMOSA_Link_Shape").prop("disabled", false);
                $("#LstMOSA_update_Model").prop("disabled", false);
                $("#LstMOSA_update_3D").prop("disabled", false);
                $("#LstMOSA_delete").prop("disabled", false);
                $("#LstMOSA_load").prop("disabled", false);
                var data = table.cell(".selected", 0).node();
                var Myid = $(".selected").find("p:first").attr("id");
                self.UI.classBindData.selectIdEditMOSA = parseFloat(Myid);
                self.controller.getMosa(self.UI.classBindData.selectIdEditMOSA).info(self.UI.classBindData.selectIdEditMOSA);
                if (table.cell(".selected", 5) === "undefined") {
                    var MySa = $(".selected td").last().html();
                    self.UI.classBindData.selectSAMOSA = MySa;

                } else {
                    self.UI.classBindData.selectSAMOSA = table.cell(".selected", 5).data();
                }

            }

        });
        // when all MOSA are added, enable the 'Editing MOSA' btn
        document.getElementById("buttonEditingMOSA").disabled = false;
    }

    /*callbackShapeSuccess*/
    addRowLstShape(d) {
        // dataTables str sort not handle line breaks such as \n \t
        const removeLineBreaks = (str) => str.replace(/\r?\n|\r/g, "");

        let myRow = `<tr id="LstSHAPE_${d.id}"></tr>`;
        $("#LstSHAPE").children("tbody").append(myRow);

        let myRowData = `<td id="LstSHAPE_${d.id}_name_TD">
                         <p id="${d.id}" class="hidden"></p>
                         <b style="padding: 1rem 0px" alt="${d.displayName ? removeLineBreaks(d.displayName) : d.technicalName}">
                            ${d.displayName ? d.displayName : d.technicalName}
                         </b>
                         </td>`;
        myRowData += `<td id="LstSHAPE_${d.id}_TYPE_TD"><b alt="${d.type}">${d.type}</b></td>`;
        myRowData += `<td id="LstSHAPE_${d.id}_state_TD"><b alt="${d.status === "PUBLISHED" ? "yes" : "no"}">${d.status}</b></td>`;
        myRowData += `<td id="LstSHAPE_${d.id}_studyAreaCode_TD">${d.studyAreaCode}</td>`;

        let jRow = $("#LstSHAPE_" + d.id).append(myRowData);

        let table = $("#LstSHAPE").DataTable();
        //var dat = table.data();
        try {
            table.row.add(jRow).draw();
        } catch (e) {
            console.error(e);
        }

    }

    deselectAllLayers() {
        $("#LstSHAPE tbody tr.selected").removeClass("selected");
        $("#LstSHAPE_Delete").prop("disabled", true); //rend les bouton delete et autres inactif
        $("#LstSHAPE_Update").prop("disabled", true);
        $("#LstSHAPE_Publish").prop("disabled", true);
        self.UI.classBindData.selectIdEditSHAPE = null;
    }

    initLstShape() {

        var table = $("#LstSHAPE").DataTable();
        var self = this;
        // register multiple times from interface_user, make sure it has only one click listener
        $("#LstSHAPE tbody").unbind("click");

        $("#LstSHAPE tbody").on("click", "tr", function() {
            if ($(this).find(".dataTables_empty").length > 0 || $(this).hasClass("selected")) {
                self.deselectAllLayers();
            } else {
                table.$("tr.selected").removeClass("selected");
                $(this).addClass("selected");

                var res = this.id.split("_");
                $("#LstSHAPE_Delete").prop("disabled", false); //rend le bouton delete et autre actifs
                $("#LstSHAPE_Update").prop("disabled", false);
                $("#LstSHAPE_Publish").prop("disabled", false);
                self.UI.classBindData.selectIdEditSHAPE = res[1];
                self.controller.readInfoShp(self.UI.classBindData.selectIdEditSHAPE);
            }
        });
    }

    setBtEditShpDisabled(val) {
        document.getElementById("buttonEditingSHAPE").disabled = val;
    }

    setInfoShp(d) {
        if (d.displayName != null)
            $("#shapeName").text(d.displayName);
        else
            $("#shapeName").text(d.technicalName);

        if (d.id != null)
            $("#shapeId").text(d.id);
        else
            $("#shapeId").text(" --- ");

        if (d.descriptionn)
            $("#shapeDesc").text(d.description);
        else
            $("#shapeDesc").text(" --- ");
    }

    everestCallbackError(data, status) {
        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageErrorSU(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageErrorSU(error, data.responseText);
        else
            this.UI.createAlertSU("alert", "error", "ERROR");
    }
}

export default InterfaceMosaUI;
