"use strict";

let Cavi2Util = function() {
};

Cavi2Util.CONST_DEFAULT_META_INITIATIVE = "General";

/**
 * Get the project root dir after internal redirection on EIFER server
 *
 * @param pathName {String} window.location.pathname
 * @returns {string} redirected root dir
 */
Cavi2Util.getRedirectRoot = function(pathName) {

    pathName = pathName ? pathName : "";

    let paths = pathName.split("/");
    paths.pop();

    let rootDir = paths.join("/");

    return rootDir;
};

Cavi2Util.replaceChars = function(str) {
    str = str.toString().replace(/[^a-zA-Z0-9]/g, "_").replace(/_{2,}/g, "_");
    if (str.slice(-1) == "_") {
        return str.substring(0, str.length - 1);
    }
    return str;
};

/**
 *  Transform the selected object (stored as property of empty array)from Tab_Select to a normal object
 *
 * @param id object id
 * @param property
 * @returns {*}
 */
Cavi2Util.propertyToObject = function(id, property) {
    let value = {};

    //neglect undefined or null
    if (property === undefined || property === null) {
        return {};
    }

    // status can be either "Attributes" or "Exeption"
    let status = property.hasOwnProperty("Attributes") ? "Attributes" : property.hasOwnProperty("Exeption") ? "Exeption" : undefined;

    if (!status) return {};

    for (let i in property[status]) {
        if (property[status].hasOwnProperty(i)) {
            value[i] = property[status][i];
        }
    }

    return {
        id: id,
        status: status,
        attr: value,
    };
};

/**
 * Transform Tab_Select (empty array) to an array, which contains selected objects
 *
 * @param data
 * @param callback {function}
 * @returns {Array}
 */
Cavi2Util.malformedDataToArray = function(data, callback = undefined) {
    //neglect undefined or null
    if (data === undefined || data === null) {
        return [];
    }

    const arr = [];

    // jsdap already extends the array with "contains"
    const { contains, ...items } = data;

    for (let i in items) {
        if (i) {
            const elem = _.isFunction(callback) ? callback(i, items[i]) : Cavi2Util.propertyToObject(i, items[i]);
            arr.push(elem);
        }
    }

    return arr;
};

/**
 * Get all attributes values (either from 'pid' or from first attr) as an array of all selections
 * Return either {attrKey: [attrVal1, attrVal2...]} or {}
 *
 * @param selections an array of selected objects
 */
Cavi2Util.getAttrObject = function(selections) {
    let attrObject = {};

    for (let item of selections) {
        // only handle last user interaction is to select an object
        if (item["status"] === "Attributes") {
            let attrNames = Object.keys(item["attr"]);

            // if attr has 'pid', pick pid, otherwise pick the first attr
            let dstAttr = attrNames.includes("pid") ? "pid" : attrNames.length ? attrNames[0] : undefined;

            // store all attr key and attr val as {attrKey: [attrVal1, attrVal2...]}
            dstAttr && !attrObject.hasOwnProperty(dstAttr) ? attrObject[dstAttr] = [] : undefined;

            dstAttr ? attrObject[dstAttr].push(item["attr"][dstAttr]) : undefined;
        }
    }

    return attrObject;
};

Cavi2Util.getRandomId = function() {
    return Math.random().toString(36).replace(/[^a-z]+/g, "").substr(2, 10);
};

Cavi2Util.toIdentifier = function(lastSelect) {

    // default use id
    let identifier = lastSelect["id"];

    // keyword used to search
    let keywords = ["Name", "Pid", "Id"];

    // fallback strategy; attr.name > attr.pid > attr.id > id; lowercaseCase > capitalizeCase > upperCase
    let fallback = keywords.reduce((previousVal, currentVal) => {
        previousVal = previousVal.concat([currentVal.toLowerCase(), currentVal, currentVal.toUpperCase()]);
        return previousVal;
    }, []);

    // found attr names, which is one of fallback
    let found = Object.keys(lastSelect["attr"]).filter((k) => fallback.includes(k));

    // loop the fallback in order, if found, exists
    for (let key of fallback) {
        if (found.includes(key) && lastSelect["attr"][key]) {
            identifier = lastSelect["attr"][key];
            break;
        }
    }

    return identifier;
};

Cavi2Util.isHexColor = function(str) {
    return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(str);
};

Cavi2Util.getColorFallback = function(color, fallbackColor) {
    return Cavi2Util.isHexColor(color) ? color : fallbackColor;
};

/**
 * Find the max z-index in the root
 *
 * @param root {string} css selector
 * @param onlyVisible: flag to determine if only find max z of visible elements
 * @return {number}
 */
Cavi2Util.getMaxZ = function(root, onlyVisible = true) {
    root = root ? root : "body";

    return Math.max(
        ...$.map($(`${root} * ${onlyVisible ? ":visible" : ""}`), e => {
            if ($(e).css("position") !== "static")
                return parseInt($(e).css("z-index")) || 1;
        }),
    );
};

Cavi2Util.getMaxZofClass = function(root) {
    root = root ? root : "body";

    return Math.max(
        ...$.map($(`.${root}`), e => {
            if ($(e).css("position") !== "static")
                return parseInt($(e).css("z-index")) || 1;
        }),
    );
};

/************************
 * Project prodec Utils
 ************************/

/**
 * Check if a string can have one of the chars array
 * @example
 *
 * Cavi2Util.hasChars('Hello',['H', 'A']) => return true
 * Cavi2Util.hasChars('Hello',['X', 'A']) => return true
 * Cavi2Util.hasChars('Hello',['X']) => return false
 *
 * @param str
 * @param chars an array of strings or chars
 * @returns {boolean}
 */
Cavi2Util.hasChars = function(str, chars) {

    let flag = false;

    if (!Array.isArray(chars)) {
        chars = [chars];
    }

    for (let i = 0; i < chars.length; i++) {
        flag = str.indexOf(chars[i]) > -1;
        // once found then break
        if (flag) break;

    }

    return flag;
};

Cavi2Util.isInArray = function isInArray(value, array) {
    return array.indexOf(value) > -1;
};

/**
 * Flatten an array of property value of an object recursively
 * Return a new object
 *
 * var foo = {name:["juan"]} => {name:"juan"}
 * var bar = {name:["juan", "jason"]} => {name:["juan", "json"]} //do nothing
 *
 *
 * @param obj
 */
Cavi2Util.flatten = function(obj) {
    var ret = {};

    Object.keys(obj).map(function(key) {

        if (Array.isArray(obj[key])) {
            // return the first element when it has only one
            ret[key] = obj[key].length === 1 ? obj[key][0] : obj[key];
        } else {
            // recurse the deepest level
            ret[key] = obj[key] && obj[key] === Object(obj[key]) ? Cavi2Util.flatten(obj[key]) : obj[key];
        }

    });

    return ret;
};

/***
 * Filter an object if its properties contains one of those keywords
 *
 * Cavi2Util.filterProps({name: 'juan', age: 28, club: 'MU'}, 'name') => return {name: 'juan'}
 * Cavi2Util.filterProps({name: 'juan', age: 28, club: 'MU'}, ['name', 'age']) => return {name: 'juan', age: 28}
 * Cavi2Util.filterProps({name: 'juan', age: 28, club: 'MU'}, ['foo']) => return {}
 *
 * @param obj
 * @param keywords {String|Array}
 */
Cavi2Util.filterProps = function(obj, keywords) {
    var ret = {};

    Object.keys(obj).map(function(elem) {
        if (Cavi2Util.hasChars(elem, keywords)) {
            ret[elem] = obj[elem];
        }
        return elem;
    });

    return ret;
};

Cavi2Util.fixOpenDapStrEncoding = function(arg) {

    if (toString.call(arg) === "[object Object]") {
        return JSON.parse(Cavi2Util.fixOpenDapStrEncoding(JSON.stringify(arg)));
    }

    if (toString.call(arg) === "[object Array]") {
        return arg.map(function(elem) {
            return Cavi2Util.fixOpenDapStrEncoding(elem);
        });
    }

    return decodeURIComponent(escape(arg).replace(/%uF7/g, "%"));
};

Cavi2Util.fixDigits = (arg, digit = 4) => {
    // we don't parse int
    if (Number.isInteger(arg)) {
        return arg;
    }
    let numeric = parseFloat(arg);
    // parse back to float, toFixed returns string
    return !isNaN(numeric) ? parseFloat(numeric.toFixed(digit)) : arg;
};

Cavi2Util.toQueryString = (params) => {
    if (toString.call(params) === "[object Object]") {
        return Object.keys(params)
            .map(key => params[key] ? `${key}=${params[key]}` : null)
            .filter(item => item)
            .join("&");
    }

    return "";
};

Cavi2Util.downloadFile = (content, fileName, contentType = "text/plain;charset=utf-8;") => {
    const blob = new Blob([content], {
        type: contentType,
    });

    if (window.navigator.msSaveBlob) {
        // FOR IE BROWSER
        navigator.msSaveBlob(blob, fileName);
    } else {
        // FOR OTHER BROWSERS
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.style = "visibility:hidden";
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};


/**
 *  JS equivalent of Python format
 *  The first arg is str, the rest are replacements
 *
 *  Cavi2Util.format("The %s is %s and %s", "apple", "big", "red")
 *  return "The apple is big and red"
 *
 * @param args
 * @return {string|*}
 */
Cavi2Util.format = (...args) => {
    let [str, ...rest] = args;

    if (typeof str !== "string") {
        return "";
    }

    let i = 0;

    return str.replace(/%s/g, () => typeof rest[i] != "undefined" ? rest[i++] : "");
};

Cavi2Util.version2Year = (arg, sep = ".") => {
    if (!arg) return undefined;
    return String(arg).split(sep).find(item => item);
};

export default Cavi2Util;
