import SU_Edit_Target_CLS from './su_edit_target.js'
/**
    In SU mode :
    Create a box with all existed Targets
    Define a right-click menu on this box and event on each element of this menu

*/

var SU_ManageTarget_CLS = function(Everest, UI) {
    this.UI = UI;
    this.Everest = Everest;
    this.contentFileJSON = this.UI.accessContentJSON;
    this.contentMessageFileJSON = this.UI.accessMessageContentJSON;
    //stores result of List_Targets() request
    this.saveData = null;
    this.cptData = 0;


    this.selected = null;
    this.boolAdd = false;
    this.EditTarget = undefined;

    // if the nextUrl is not null
    // stores other items in saveData
    this.nextUrl = false;

    //Construit la box de gestion des résultats
    //Rajoute la box
    this.createboxSU_ListTgt(function() {return true;});


    var rMenu = '<div id="rMenuTarget" class="box-body no-padding" >';
    rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_edit_target"><i class="fa fa-edit"></i> ' + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_EDIT + '</a>';
    rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_duplicate_target"><i class="fa fa-copy"></i> ' + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_DUPLICATE + '</a>';
    rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_delete_target"><i class="fa fa-trash-o"></i> ' + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_DELETE + '</a>';
    rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_infos_target"><i class="fa fa-info"></i> ' + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_INFOS + '</a>';
    rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_toogle_publish_target"><i class="fa fa-bars"></i> ' + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_PUBLISH + '</a>';
    rMenu += '  </div>';
    $("#page_centre").prepend(rMenu);
    this.tree = $("#treeTarget");
    this.isInit = false;
    this.zNodes = [];
    this.currentCopy = null;
    $("#box_SU_ManageTarget .overlay").show();

    this.init = function() {
        //if (this.Interface_MOSA.selectedId != null && this.Everest.authToken != null) {
        this.UI.isUpdatedPubliTgt = false;

        this.UI.classBindData.isChangePubliStateTgt = false;
        if (this.UI.classBindData.authToken != null) {
            if (this.isInit == true) {
                $.fn.zTree.destroy("treeTarget");
                this.isInit = false;
            }
            this.zNodes = [];
            this.Everest.List_Targets(null, this.initTree.bind(this), this.Everest_callbackError.bind(this));

            //Init le click sur "New target"
            $("#m_new_target").click($.proxy(function() {
                this.NewTarget();
            }, this));
            //Menu  : Click Droit
            //Init le click sur "Edit Target Simulation" du menu droit
            $("#m_edit_target").click($.proxy(function() {
                $("#rMenuTarget").hide();
                $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
                if (this.tree.getSelectedNodes()[0]) {
                    this.BT_EditTarget(this.tree.getSelectedNodes()[0]);
                }
            }, this));
            //Init le click sur "Edit Target Simulation" du menu droit
            $("#m_duplicate_target").click($.proxy(function() {
                $("#rMenuTarget").hide();
                $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
                if (this.tree.getSelectedNodes()[0]) {
                    this.NewTarget(this.tree.getSelectedNodes()[0]);
                }
            }, this));
            //Init le click sur "Delete Simulation" du menu droit
            $("#m_delete_target").click($.proxy(function() {
                $("#rMenuTarget").hide();
                $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
                if (this.tree.getSelectedNodes()[0]) {
                    //boolean to updatelist target select in Targeting interface
                    this.UI.isUpdatedPubliTgt = true;

                    this.selected = this.tree.getSelectedNodes()[0];

                    this.UI.createConfirmBox(this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_DELETE_CONFIRM + this.selected.name, this.callbackSuccess_ConfirmDelTarget.bind(this));

                }
            }, this));
            //Init le click sur "Infos" du menu droit
            $("#m_infos_target").click($.proxy(function() {
                $("#rMenuTarget").hide();
                $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
                if (this.tree.getSelectedNodes()[0]) {
                    var node = this.tree.getSelectedNodes()[0];
                    this.Everest.Read_Target_Info(node, node.id, this.callbackSuccessInfos.bind(this), this.Everest_callbackError.bind(this));
                }
            }, this));


            $("#m_toogle_publish_target").click($.proxy(function() {
                $("#rMenuTarget").hide();
                $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
                if (this.tree.getSelectedNodes()[0]) {
                    var node = this.tree.getSelectedNodes()[0];
                    node.published = !node.published;
                    this.Everest.Update_Target_Info(node, node.id, node.name, node.description, node.published, function(data, status, obj) {
                        this.UI.createAlert("notif", "success", "Target " + (obj.published == false ? "Unp" : "P") + "ublished.");
                        var aObj = $("#" + node.tId);
                        var checkStatut = $("#status_" + node.tId);
                        checkStatut.remove();
                        aObj.prepend('<i id="status_' + node.tId + '" class="fa fa-' + (obj.published == true ? 'check-circle" style="color:green' : 'lock" style="color:orange') + '"></i>');

                        //boolean to updatelist target select in Targeting interface
                        this.UI.isUpdatedPubliTgt = true;
                        //boolean to update TargetList interface
                        this.UI.classBindData.isChangePubliStateTgt = true;

                        //   this.updateDomListContext(obj.published);
                    }.bind(this), this.Everest_callbackError.bind(this));
                }
            }, this));

        }
    }

    /*
        Update list of targets and call a notif Alert when we delete a target
    */
    this.callbackSuccess_ConfirmDelTarget = function(data, status, node) {
        this.Everest.Delete_Target(this.selected, this.selected.id, function(data, status, node) {
            this.tree.removeNode(node);
            // alert("Target deleted");
            this.UI.createAlert("notif", "success", this.contentMessageFileJSON.MESSAGE_ALERT_TARGET_DEL);
        }.bind(this), this.Everest_callbackError.bind(this));
    }


    this.updateDomListContext = function(boolPublish) {
        // popup scenario context list
        var node = this.tree.getSelectedNodes()[0];
        var popup = $("#treeTargetList");
        var separateur = node.tId.indexOf("_");
        var sub = node.tId.substring(separateur, node.tId.length);
        var aObj = $("#treeTargetList" + sub);
        var checkStatut = $("#status_treeTargetList" + sub);
        checkStatut.remove();
        aObj.prepend('<i id="status_treeTargetList' + sub + '" class="fa fa-' + (boolPublish == true ? 'check-circle" style="color:green' : 'lock" style="color:orange') + '"></i>');

    }


    this.addDiyDom = function(treeId, treeNode) {
        var aObj = $("#" + treeNode.tId);

        if (treeNode.level == 0 && this.boolAdd == false) {
            this.Everest.Read_Target_Info(this.saveData.items[this.cptData], this.saveData.items[this.cptData].id, function(d) {
                aObj.prepend('<i id="status_' + treeNode.tId + '" class="fa fa-' + (d.published == true ? 'check-circle" style="color:green' : 'lock" style="color:orange') + '"></i>');
            }, this.Everest_callbackError.bind(this));
        }
        this.cptData++;
    };


    /*
        Creates the tree of targets
        Init boolean, which determines if we change a target to publish/unpublish status. If it is true : update the list of Target in Analysing->KPI Targets
    */
    this.initTree = function(data, status, id) {
        this.cptData = 0;
        if (this.nextUrl == true) {
            for (var element in data.items) {
                if (data.items.hasOwnProperty(element)) {
                    this.saveData.items.push(data.items[element]);
                }

            }
        } else {
            this.saveData = data;
        }

        this.UI.isUpdatedPubliTgt = false;
        this.UI.classBindData.isChangePubliStateTgt = false;
        for (var i = 0; i < data.items.length; i++) {
            var obj = {
                id: data.items[i].id,
                pId: 0,
                name: data.items[i].name,
                isParent: false,
                published: data.items[i].published,
                description: data.items[i].description
            };
            this.zNodes.push(obj);
        }

        if (data.nextUrl != null) {
            this.nextUrl = true;
            this.Everest.getNextUrl(data.nextUrl, id, this.initTree.bind(this), this.Everest_callbackError.bind(this));
        } else {

            var settings = {
                view: {
                    dblClickExpand: false,
                    showLine: false,
                    selectedMulti: false,
                    addDiyDom: this.addDiyDom.bind(this)
                },
                data: {
                    simpleData: {
                        enable: true,
                        idKey: "id",
                        pIdKey: "pId",
                        rootPId: ""
                    }
                },
                callback: {
                    onRightClick: this.OnRightClick.bind(this)
                }
            }

            this.tree = $.fn.zTree.init(this.tree, settings, this.zNodes);
            this.isInit = true;
            $("#box_SU_ManageTarget .overlay").hide();
        }
    }

    /*
        Defines the event on rightclick : open the right menu
    */
    this.OnRightClick = function(event, treeId, treeNode) {

        if (!treeNode && event.target.tagName.toLowerCase() != "button" && $(event.target).parents("a").length == 0) {
            this.tree.cancelSelectedNode();
        } else if (treeNode && !treeNode.noR) {
            this.tree.selectNode(treeNode);
            this.showRMenu("node", event.clientX, event.clientY);
        }
    }

    /*
        Show right menu
    */
    this.showRMenu = function(type, x, y) {
        $("#rMenuTarget").stop().css({
            "top": y + "px",
            "left": x + "px"
        }).show("fold");
        $("body").bind("mousedown", this.onBodyMouseDown.bind(this));
    }


    this.onBodyMouseDown = function(event) {
        if (!(event.target.id == "rMenuTarget" || $(event.target).parents("#rMenuTarget").length > 0)) {
            $("#rMenuTarget").hide();
            $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
        }
    }


    /*
        Add new entry in the tree list
    */
    this.Addchild = function(data, status, obj) {
        this.boolAdd = true;
        this.tree = $.fn.zTree.getZTreeObj("treeTarget");
        var tabNode = [];
        for (var i = 0; i < data.items.length; i++) {
            tabNode.push({
                id: data.items[i].id,
                pId: obj.id,
                name: data.items[i].name
            });
        }
        var newNodes = this.tree.addNodes(null, tabNode);
        var aObj = $("#" + newNodes[0].tId);
        aObj.prepend('<i id="status_' + newNodes[0].tId + '" class="fa fa-lock" style="color:orange"></i>');

        if (data.nextUrl != undefined && data.nextUrl != null) { //On charge la suite
            this.Everest.getNextUrl(data.nextUrl, obj, this.callbackSuccessChild.bind(this), this.Everest_callbackError.bind(this));
        }
    }


    /*
        When click on Create Target : call Everest req to create new Target
        Update interface with the AddChild fct
    */
    this.NewTarget = function(nodeCopy) {
        //Rajoute la box
        this.createboxSU_NewTgt(nodeCopy, function() {return true;});

        $("#newTarget_create").click({
            nodeCopy: nodeCopy
        }, function(event) {
            if ($("#newTarget_name").val().length > 0) {
                var reg = new RegExp(this.UI.classBindData.contentRegExLabel);
                if (reg.test($("#newTarget_name").val())) {
                    //TA001: Create Or Copy Target
                    $("#overlay_newTarget").show();
                    var param = {
                        pid: null,
                        name: $("#newTarget_name").val(),
                        nodeCopy: event.data.nodeCopy
                    };
                    this.Everest.Create_Or_Copy_Target(param, $("#newTarget_name").val(), $("#newTarget_comments").val(), (event.data.nodeCopy != undefined && event.data.nodeCopy != null ? event.data.id : undefined), function(data, status, p) {
                        var obj = {
                            id: p.pid,
                            nextUrl: null
                        };
                        var d = {
                            items: [{
                                id: data.id,
                                name: p.name
                            }]
                        };
                        this.Addchild(d, status, obj);
                        $("#box_newTarget").remove();
                        // alert("New target created");
                        this.UI.createAlert("notif", "success", this.contentMessageFileJSON.MESSAGE_ALERT_TARGET_ADDED);
                    }.bind(this), this.Everest_callbackError.bind(this));
                } else {
                    this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_FOLDER_WRONG_NAME);
                    return -1;
                }
            } else {
                // alert("Name is empty!");
                this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_EMPTY_NAME);
                return -1;
            }
        }.bind(this));

    }


    /*
        When click on Edit entry in right-click menu : creates new interface
    */
    this.BT_EditTarget = function(node) {
        if (this.EditTarget == undefined) {
            this.EditTarget = new SU_Edit_Target_CLS(this.Everest, this.UI);
            this.EditTarget.init();
        }
        this.UI.moveUOto({
            idParent: "box_Edit_Target",
            id: "uo_Edit_Target",
            ItemLink: this.EditTarget
        });
        this.EditTarget.Load(node);
    }

    /*
        Creates infos interface of the target selected : when click on Infos button
    */
    this.callbackSuccessInfos = function(data, status, node) {
        this.createboxSU_InfosTgt(data, function() {
            return true;
        });

    }




    this.Everest_callbackError = function(data, status) {

        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");
    }

}
SU_ManageTarget_CLS.prototype.constructor = SU_ManageTarget_CLS;

SU_ManageTarget_CLS.prototype.createboxSU_ListTgt = function(CallBackClose) {

    $("#box_SU_ManageTarget").remove();
    var c = "<div id=\"SU_ManageTarget_content\">";
    c += "      <a class=\"btn btn-block btn-social btn-xs use-skin\" style=\"color:#ffffff\" id=\"m_new_target\"><i class=\"fa fa-folder-o\"></i> " + this.contentFileJSON.MENU_LEFT_SUPER_USER_TARGET_CREATE + "</a>";
    c += "      <ul id=\"treeTarget\" class=\"ztree\"></ul>";
    c += "      <div id=\"SU_ManageTarget_overlay\" class=\"overlay\">";
    c += "          <i class=\"fa fa-refresh fa-spin\"></i>";
    c += "      </div>";
    c += "  </div>";

    this.UI.CreateBox("SU_ManageTarget", {
        draggable: true,
        group: "SuperUser",
        resizable: {
            minHeight: 500,
            minWidth: 475
        },
        useSkin: true,
        title: this.contentFileJSON.MENU_LEFT_SUPER_USER_TARGET_TITLE,
        content: c,
        visible: false,
        menuBt: {
            reduceTo: "div_ManageTarget", //ID vers qui on doit réduire la div
            fullscreen: {
                css: {
                    position: "absolute",
                    top: "140px",
                    width: "500px",
                    height: "500px",
                    left: "9%",
                    right: "",
                    bottom: ""
                },
                fct: function() {

                }.bind(this)
            } //position / taille de div pour quand on sort du fullscreen
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "140px",
            left: "9%",
            width: "475px",
            height: "500px",
            "min-width": "475px",
            "min-height": "500px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};

SU_ManageTarget_CLS.prototype.createboxSU_NewTgt = function(nodeCopy, CallBackClose) {

    $("#box_newTarget").remove();
    var c = "<div id=\"newTarget_content\" style=\"width:100%; height:100%;\">";
    c += "      <table style=\"width:100%; height:100%;\">";
    if (nodeCopy != undefined) {
        c += "          <tr style=\"height: 50px;\"><td colspan=\"2\"><i>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_DUPLICATE_COPY + nodeCopy.name + "</i></td></tr>";
    }
    c += "          <tr style=\"height: 50px;\"><td>" + this.contentFileJSON.MENU_NEW_NAME + " </td>";
    c += "          <td>";
    c += "              <input type=\"text\" class=\"form-control\" id=\"newTarget_name\" placeholder=\"" + this.contentFileJSON.MENU_LEFT_SUPER_USER_TARGET_CREATE_NEWTARGET_NAME_CONTENT + "\">";
    c += "          </td></tr>";
    c += "          </tr>";
    c += "          <td>" + this.contentFileJSON.MENU_NEW_COMMENTS + " </td>";
    c += "          <td>";
    c += "              <textarea  rows=\"5\" style=\"width:100%; height:100%; resize:none;\" id=\"newTarget_comments\" placeholder=\"\"></textarea>";
    c += "          </td></tr>";
    c += "      </table>";
    c += "  </div>";
    var f = "<div class=\"pull-right\" ><button class=\"btn use-skin\" style=\"color:#ffffff\" id=\"newTarget_create\">" + this.contentFileJSON.MENU_NEW_CREATE + "</button></div>";
    var h = (nodeCopy != undefined ? 360 : 310);
    this.UI.CreateBox("newTarget", {
        draggable: true,
        group: "SuperUser",
        useSkin: true,
        title: this.contentFileJSON.MENU_LEFT_SUPER_USER_TARGET_CREATE_NEW,
        content: c,
        addOverlay: true,
        footer: f,
        resizable: {
            minHeight: h,
            minWidth: 400
        },
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            left: "40%",
            width: "400px",
            height: h + "px",
            "min-width": "400px",
            "min-height": h + "px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};


SU_ManageTarget_CLS.prototype.createboxSU_InfosTgt = function(data, CallBackClose) {

    $("#box_infosTarget").remove();
    var c = "<div id=\"infosTarget_content\" style=\"width:100%; height:100%;\">";
    c += "      <table style=\"width:100%; height:100%;\">";
    c += "          <tr style=\"height: 50px;\"><td>" + this.contentFileJSON.MENU_NEW_NAME + " </td>";
    c += "              <td>" + data.name + "</td></tr>";
    c += "          </tr>";
    c += "          <td>" + this.contentFileJSON.MENU_NEW_COMMENTS + " </td>";
    c += "          <td>";
    c += "              <div class=\"box box-solid box-primary\" style=\"width:100%; height:100%; overflow:auto; text-align:left;padding:5px\" >" + data.description + "</div>";
    c += "          </td></tr>";
    c += "          <tr style=\"height: 50px;\"><td>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_INFO_PUBLISHED + " </td>";
    c += "              <td><i class=\"fa fa-" + (data.published == true ? "check-circle\" style=\"color:green" : "times-circle\" style=\"color:red") + "\"></i>" + (data.published == true ? " Yes" : " No") + "</td></tr>";
    c += "          </tr>";
    c += "      </table>";
    c += "  </div>";

    this.UI.CreateBox("infosTarget", {
        draggable: true,
        group: "SuperUser",
        useSkin: true,
        title: data.name,
        content: c,
        resizable: {
            minHeight: 310,
            minWidth: 400
        },
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            left: "40%",
            width: "400px",
            height: "310px",
            "min-width": "400px",
            "min-height": "310px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};
export default SU_ManageTarget_CLS;
