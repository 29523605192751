/**
 * @author Wanji Zhu <Wanji.Zhu@eifer.org>
 *
 *
 * A custom url loader for external template markup file.
 * This template loader is supposed to work with single external template for each component
 *
 */
const { ko } = window;

class KnockoutTemplateLoader {
  constructor() {
    this.cache = {};
    // this.getConfig = ko.components.defaultLoader.getConfig;
    this.loadComponent = ko.components.defaultLoader.loadComponent;
    this.loadViewModel = ko.components.defaultLoader.loadViewModel;
  }

  loadTemplate(name, templateConfig, callback) {
    if (templateConfig.path == null) {
      // unrecognized config format. let another loader handle it.
      callback(null);
    } else {
      const existing = this.cache[name];

      if (existing) {
        // load cached template if exist
        callback(existing);
      } else {
        $.get(templateConfig.path)
          .then(template => {
            this.cache[name] = ko.utils.parseHtmlFragment(template.trim());
            callback(this.cache[name]);
          })
          .catch(() => {
            // unexpected http error. let another loader handle it.
            callback(null);
          });
      }
    }
  }
}

export default KnockoutTemplateLoader;
