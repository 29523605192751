"use strict";

import Everest_CLS from "./controller/everest.js";
import Interface_MOSA_CLS from "./controller/interface_mosa.js";
import TaskManager_CLS from "./controller/taskmanager_cls.js";
import UI from "./ui.js";
import Interface_User_CLS from "./interface_user.js";
import KnockoutTemplateLoader from "./helpers/ko.loader.js";
import koRegister from "./helpers/ko.register.js";
import i18nextMixin from "./helpers/ko.i18n.mixin.js";
import Cookies from "js-cookie";


class Cavi2 {
    /**
     * Entry Class
     *
     * @param UrlEverest {String} relative path of server url config
     * @param UrlLibelle {String} relative path of UI label config
     */
    constructor(UrlEverest, UrlLibelle) {
        this.UrlEverest = UrlEverest;
        // TODO remove it and replace it with i18n
        this.UrlLibelle = UrlLibelle;
        // app components
        this.UI = undefined;
        this.Kpi = undefined;
        this.Everest = undefined;
        this.TaskManager = undefined;
        this.GestionMOSA = undefined;
        this.User = undefined;
        // this.GestionSimulations = undefined;
        // this.MOSA = undefined;
    }

    init() {
        $(document).ready(() => {
            // prevent undesired browser default behaviours
            this.preventBrowserDefaults();

            // register evt listeners such as resizable, draggable and droppable etc.
            this.registerElementEventListeners();

            // init all app components and bind to KnockoutJS
            Promise.all([$.getJSON(this.UrlEverest), $.getJSON(this.UrlLibelle)])
                .then(data => {
                    this.initComponents(data);

                    this.initKnockoutJS();

                    this.showLoginPage();

                    this.showBrowserWarning();
                })
                .catch(error => {
                    //TODO how to handle error, retry and alert when in failure?
                    console.log(error);
                });
        });
    }

    initComponents(configs) {
        // Centralized management of kpi to not to request them every time.
        // this.Kpi = new Cavi2.Kpi(this.Everest);
        const [urls, locales] = configs
        this.Everest = new Everest_CLS(urls, locales);
        this.UI = new UI(this.Everest, locales);
        this.TaskManager = new TaskManager_CLS();
        this.GestionMOSA = new Interface_MOSA_CLS(this.Everest, this.TaskManager, this.UI);
        this.User = new Interface_User_CLS(this.Everest, this.GestionMOSA, this.UI);
        // this.resize();
    }

    initKnockoutJS() {

        // use promise to guarantee ko.applyBindings after i18nextko init
        i18nextMixin
            .on("languageChanged", i18nextMixin.updateAppComponentsLang, this.UI, this.GestionMOSA)
            .init({
                // define the order of default lang, change the order if desired
                fallbackLng: ["fr", "en"],
                // define allowed lang
                whitelist: ["en", "fr"],
                preload: ["en", "fr"],
                ns: ["common", "menu", "message", "prodec"]
            })
            .then((ko) => {
                //register customized knockout template loader
                ko.components.loaders.unshift(new KnockoutTemplateLoader());

                // register all custom binding in app
                koRegister.registerCustomBindings();

                // register all ko components in app
                koRegister.registerComponents(this.GestionMOSA);

                // ko binding must be after i18n init (backend)
                ko.applyBindings(this);

            }).catch((error) => {
            console.log(error);
        });
    }

    showLoginPage() {

        $("#mainDiv").show();

        $("#buttonLogin").attr("disabled", false);

        // Handle the signOut
        let signOut = $(".cavi_mosa_logout");
        let everestSessionCookie = Cookies.get("EVERESTSessionId");
        !everestSessionCookie || ["unset", undefined].includes(everestSessionCookie) ? signOut.hide() : signOut.show();
    }

    showBrowserWarning() {
        if (Cookies.get("no_more_chrome_warning") === "true") {
            return;
        }

        if (!navigator.userAgent.toLowerCase().includes("chrome")) {
            $("#browser-warning").fadeIn();
        }

        $("#browser-warning").on("close.bs.alert", () => {
            let isChecked = $("#no-more-chrome-warning").is(":checked");
            Cookies.set("no_more_chrome_warning", isChecked, { expires: 365 });
        });
    }

    registerElementEventListeners() {
        //TODO move part of code to CSS
        //Popup results (Graph / Tables)
        $("#popup_result")
            .draggable({
                containment: "#mainContent",
                cursor: "move",
                snap: "#mainContent",
                handle: "#h_popup_result"
            })
            .resizable({
                minHeight: 250,
                minWidth: 475
            });
        $("#popup_result").on("click", () => {
            this.UI.setZIndexToMax("popup_result");
        });

        $("#bt_close_popup_result").on("click", function() {
            $("#popup_result").fadeOut(600);
        });

        $("#bt_fullscreen_popup_result").on("click", function() {
            if ($("#bt_fullscreen_popup_result i").hasClass("fa-expand") === true) {
                //Passe en FullScreen
                $("#bt_fullscreen_popup_result i")
                    .removeClass("fa-expand")
                    .addClass("fa-compress");
                $("#popup_result").css({
                    position: "absolute",
                    left: 0,
                    top: "50px",
                    right: 0,
                    bottom: "20px",
                    width: "",
                    height: ""
                });
            } else {
                $("#bt_fullscreen_popup_result i")
                    .removeClass("fa-compress")
                    .addClass("fa-expand");
                $("#popup_result").css({
                    position: "absolute",
                    top: "115px",
                    left: "25%",
                    right: "",
                    bottom: "",
                    width: "50%",
                    height: "70%"
                });
            }
        });

        $("#mainpanel").droppable({
            accept: ".taskItem",
            drop: function(event, ui) {
                var idChild = $(ui.draggable).prop("id") + "_child";
                var cursorX = ui.offset.left;
                var cursorY = ui.offset.top;
                $("#" + idChild).remove();
                $(this).append(
                    $(ui.draggable)
                        .clone()
                        .prop("id", idChild)
                        .removeClass("taskItem ui-draggable-dragging")
                        .css({
                            position: "absolute",
                            left: cursorX,
                            top: cursorY,
                            width: "350px",
                            border: "solid 1px white"
                        })
                        .draggable({
                            cursor: "move"
                        })
                );
                $("#" + idChild + " .info-box-text").after(
                    "<div style=\"position:absolute; top:2px;right:2px;\"><button class=\"btn btn-box-tool btclose\" style=\"color:white;\"><i class=\"fa fa-close\"></i></button>"
                );
                $("#" + idChild + " .btclose").on("click", function() {
                    var options = {
                        to: "#div_Task",
                        className: "ui-effects-transfer-open"
                    };
                    $("#" + idChild)
                        .fadeOut(200)
                        .effect("transfer", options, 500)
                        .dequeue()
                        .remove();
                });
            }
        });

        $(".taskItem").draggable({
            containment: "#mainpanel",
            cursor: "move",
            snap: "#mainpanel",
            revert: "invalid",
            helper: "clone"
        });

        $(document).delegate(".cavi_mosa_logout", "click", () => {
            this.User.Logout();
            $(".cavi_mosa_logout").hide();
        });
    }

    preventBrowserDefaults() {
        // prevent window resize
        $(window).resize(function() {
        });

        // prevent touchMove event
        document.addEventListener(
            "touchmove",
            function(e) {
                e.preventDefault();
            },
            false
        );
    }

    /**
     * Force the size of the panel to have the scroll
     * The page won't be scrolled anyway, since <body>'s "overflow" is hidden
     */
    resize() {
        //TODO remove this method
    }
}

export default Cavi2;
