import Cavi2Util from "../helpers/cavi.util.js";

const { toQueryString } = Cavi2Util;

// Supported Versions
const SUPPORTED_WMS_VERSIONS = ["1.0.0", "1.1.0", "1.1.1", "1.3.0"];

const SUPPORTED_WFS_VERSIONS = ["1.0.0", "1.1.0", "2.0.0"];

const SUPPORTED_WMS_INFO_FORMAT = [
    "text/plain",
    "application/vnd.ogc.gml",
    "application/vnd.ogc.gml/3.1.1",
    "text/html",
    "application/json",
    "text/javascript",
];

const SUPPORTED_WFS_OUTPUT_FORMAT = [
    "GML2",
    "GML3",
    "shape-zip",
    "application/json",
    "text/javascript",
    "csv",
];

const SUPPORTED_WMS_EXCEPTIONS = [
    "application/vnd.ogc.se_xml",
    "application/vnd.ogc.se_inimage",
    "application/vnd.ogc.se_blank",
    "application/json",
    "text/javascript",
];

const SUPPORTED_WFS_EXCEPTIONS = ["text/xml", "application/json", "text/javascript"];

// internal helpers
const delimit = (arg, sep = ",") =>
    Array.isArray(arg) ? arg.join(sep) : typeof arg === "string" ? arg : "";

const normalizeURL = (url, params = {}) => `${_.trimEnd(url.trim(), "?")}?${toQueryString(params)}`;

const isSupport = (param, available = []) => param && available.includes(param);

// WFS getFeature
const getFeature = async (url, params = {}) => {
    // see https://docs.geoserver.org/stable/en/user/services/wfs/basics.html
    // see https://docs.geoserver.org/stable/en/user/services/wfs/reference.html
    if (!url) {
        throw "GeoServer WFS URL is not defined";
    }

    const {
        service,
        request,
        version,
        typeNames,
        typeName,
        bbox,
        count,
        maxFeatures,
        outputFormat,
        exceptions,
        ...optionals
    } = params;

    const _service = service && service.toUpperCase() === "WFS" ? service.toUpperCase() : "WFS";
    const _request = request === "GetFeature" ? request : "GetFeature";
    const _version = isSupport(version, SUPPORTED_WFS_VERSIONS) ? version : "2.0.0";
    const isV20 = _version === "2.0.0";
    const _typeNames = delimit(isV20 ? typeNames : typeName, ",");
    // if version = `2.0.0` and crs is EPSG:4326, bbox axis order needed to be flipped: minY,minX,maxY,maxX
    // older versions: minX,minY,maxX,maxY
    const _bbox = delimit(bbox, ",") || "";
    const _count = isV20 && count ? count : maxFeatures || "";
    const _outputFormat = isSupport(outputFormat, SUPPORTED_WFS_OUTPUT_FORMAT)
        ? outputFormat
        : "application/json";
    const _exceptions = isSupport(exceptions, SUPPORTED_WFS_EXCEPTIONS)
        ? exceptions
        : "application/json";

    const _params = {
        service: _service,
        request: _request,
        version: _version,
        [isV20 ? "typeNames" : "typeName"]: _typeNames,
        bbox: _bbox,
        outputFormat: _outputFormat,
        [isV20 ? "count" : "maxFeatures"]: _count,
        exceptions: _exceptions,
        ...optionals,
    };

    return fetch(normalizeURL(url, _params));
};

// WMS GetFeatureInfo
const getFeatureInfo = async (url, params = {}) => {
    // see https://docs.geoserver.org/stable/en/user/services/wms/reference.html#getfeatureinfo
    if (!url) {
        throw "GeoServer WMS URL is not defined";
    }

    // required params for requests
    const {
        service,
        request,
        version,
        layers,
        styles,
        crs,
        srs,
        bbox,
        width,
        height,
        query_layers,
        x,
        y,
        i,
        j,
        info_format,
        exceptions,
        ...optionals
    } = params;

    const _service = service && service.toUpperCase() === "WMS" ? service.toUpperCase() : "WMS";
    const _request = request === "GetFeatureInfo" ? request : "GetFeatureInfo";
    const _version = isSupport(version, SUPPORTED_WMS_VERSIONS) ? version : "1.3.0";
    const isV130 = _version === "1.3.0";
    const _layers = delimit(layers, ",");
    const _styles = delimit(styles, ",");
    // if version >= '1.3.0', use crs, older version use srs
    const _crs = isV130 ? crs || srs : srs || crs;
    // if version >= `1.3.0` and crs is EPSG:4326, bbox axis order needed to be flipped: minY,minX,maxY,maxX
    // older versions: minX,minY,maxX,maxY
    const _bbox = delimit(bbox, ",") || "";
    const _width = width || 256;
    const _height = height || 256;
    const _query_layers = !query_layers ? _layers : delimit(query_layers);
    // if version >= '1.3.0', use i, j, older versions use x, y
    const _x = isV130 ? i || x : x || i || 0;
    const _y = isV130 ? j || y : y || j || 0;
    const _info_format = isSupport(info_format, SUPPORTED_WMS_INFO_FORMAT)
        ? info_format
        : "application/json";
    const _exceptions = isSupport(exceptions, SUPPORTED_WMS_EXCEPTIONS)
        ? exceptions
        : "application/json";

    const _params = {
        service: _service,
        request: _request,
        version: _version,
        layers: _layers,
        styles: _styles,
        [isV130 ? "crs" : "srs"]: _crs || "EPSG:4326",
        bbox: _bbox,
        width: _width,
        height: _height,
        query_layers: _query_layers,
        info_format: _info_format,
        feature_count: 1,
        [isV130 ? "i" : "x"]: _x,
        [isV130 ? "j" : "y"]: _y,
        exceptions: _exceptions,
        ...optionals,
    };

    return fetch(normalizeURL(url, _params));
};

// Utils
const parseOWSExceptions = (xml) => {
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    const nodesList = doc.getElementsByTagName("ows:Exception");
    // we construct the same error interface as GeoServer here
    return Array.from(nodesList).map((elem) => ({
        locator: elem.getAttribute("locator"),
        code: elem.getAttribute("exceptionCode"),
        text: Array.from(elem.getElementsByTagName("ows:ExceptionText"))
            .map((n) => n.childNodes[0].nodeValue)
            .join(","),
    }));
};

const isOWSError = (e) => !!(e && e.code && e.locator && e.text);

const respHandler = async (resp) => {
    return new Promise(async (resolve, reject) => {
        // The fact is GeoServer always return 200 even it is bad response
        const contentType = resp.headers.get("content-type");

        if (contentType.includes("application/json")) {
            const payload = await resp.json();
            const { exceptions } = payload;
            return exceptions ? reject(exceptions) : resolve(payload);
        } else {
            // Not all GeoServer exceptions returns json error
            // such as wrong service type, it will return xml
            const payload = await resp.text();
            return contentType.includes("xml") && payload.includes("Exception")
                ? reject(parseOWSExceptions(payload))
                : resolve(payload);
        }
    });
};

// flatten features.properties as features
const getFeatureProps = (payload) =>
    payload && Array.isArray(payload.features) ? _.map(payload.features, (f) => f.properties) : [];

export default {
    wfs: {
        getFeature,
    },
    wms: {
        getFeatureInfo,
    },
    utils: {
        respHandler,
        getFeatureProps,
        isOWSError,
    },
};
