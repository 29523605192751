// export const OSM_URL = "http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png";
// export const OSM_URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}";
export const OSM_URL = "http://{s}.tile.geofabrik.de/{id}/{z}/{x}/{y}.{ext}";

export const VERSIONED_ONSHORE_CONSTANTS = {
    v2020: {
        TRAJECTORY_END_YEAR: 2050,
        UPDATE_YEARS: [2020, 2025, 2030, 2035, 2040, 2045, 2050],
        TURBINE_PID_PREFIX: "X",
        TURBINE_WIND_SPEED_ATTR_NAME: "max_avg_wind_speed",
        BUILDING_KPI_MATRIX: {
            CELL: [
                "Power",
                "Tot_installed_power",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Year_lcoe",
                "Optimal_turbine",
                "Energy_prod",
                "Load_factor",
            ],
            ADMIN: [
                "Power",
                "Tot_installed_power",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Installed_soc_accept_power_pot",
                "Tot_soc_accept_power_pot",
                "Ava_soc_accept_power_pot",
            ],
        },
    },
    "v2020.2.0": {
        TRAJECTORY_END_YEAR: 2050,
        UPDATE_YEARS: [2020, 2025, 2030, 2035, 2040, 2045, 2050],
        TURBINE_PID_PREFIX: "X",
        TURBINE_WIND_SPEED_ATTR_NAME: "max_avg_wind_speed",
        BUILDING_KPI_MATRIX: {
            CELL: [
                "Tot_installed_power",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Optimal_turbine",
                "Energy_prod",
                "Total_energy",
                "Load_factor",
                "Year_lcoe",
                "Cell_residual_area",
                "Acceptance_forest",
                "Acceptance_land",
            ],
            ADMIN: [
                "Tot_installed_power",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Installed_soc_accept_power_pot",
                "Tot_soc_accept_power_pot",
                "Ava_soc_accept_power_pot",
            ],
        },
    },
    "v2021.1.0": {
        TRAJECTORY_END_YEAR: 2050,
        UPDATE_YEARS: [2021, 2026, 2030, 2035, 2040, 2045, 2050],
        TURBINE_PID_PREFIX: "X",
        TURBINE_WIND_SPEED_ATTR_NAME: "max_avg_wind_speed",
        BUILDING_KPI_MATRIX: {
            CELL: [
                "Tot_installed_power",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Optimal_turbine",
                "Energy_prod",
                "Total_energy",
                "Load_factor",
                "Year_lcoe",
                "Cell_residual_area",
                "Ava_tot_area",
                "Acceptance_forest",
                "Acceptance_land",
            ],
            ADMIN: [
                "Installed_soc_accept_power_pot",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Tot_soc_accept_power_pot",
                "Ava_soc_accept_power_pot",
            ],
        },
    },
    "v2021.2.0": {
        TRAJECTORY_END_YEAR: 2050,
        UPDATE_YEARS: [2021, 2026, 2030, 2035, 2040, 2045, 2050],
        TURBINE_PID_PREFIX: "X",
        TURBINE_WIND_SPEED_ATTR_NAME: "max_avg_wind_speed",
        BUILDING_KPI_MATRIX: {
            CELL: [
                "Tot_installed_power",
                "Annual_installed_power",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Optimal_turbine",
                "Energy_prod",
                "Total_energy",
                "Load_factor",
                "Year_lcoe",
                "Cell_residual_area",
                "Ava_tot_area",
                "Acceptance_forest",
                "Acceptance_land",
                "Historical_turbine_name",
            ],
            ADMIN: [
                "Installed_soc_accept_power_pot",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Tot_soc_accept_power_pot",
                "Ava_soc_accept_power_pot",
            ],
        },
    },
    "v2022.1.0": {
        TRAJECTORY_END_YEAR: 2050,
        UPDATE_YEARS: [2022, 2027, 2030, 2035, 2040, 2045, 2050],
        TURBINE_PID_PREFIX: "X",
        TURBINE_WIND_SPEED_ATTR_NAME: "max_avg_wind_speed",
        BUILDING_KPI_MATRIX: {
            CELL: [
                "Tot_installed_power",
                "Annual_installed_power",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Optimal_turbine",
                "Energy_prod",
                "Total_energy",
                "Load_factor",
                "Year_lcoe",
                "Cell_residual_area",
                "Ava_tot_area",
                "Acceptance_forest",
                "Acceptance_land",
                "Historical_turbine_name",
            ],
            ADMIN: [
                "Installed_soc_accept_power_pot",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Tot_soc_accept_power_pot",
                "Ava_soc_accept_power_pot",
            ],
        },
    },
    "v2023.1.0": {
        TRAJECTORY_END_YEAR: 2050,
        UPDATE_YEARS: [2023, 2028, 2030, 2035, 2040, 2045, 2050],
        TURBINE_PID_PREFIX: "X",
        TURBINE_WIND_SPEED_ATTR_NAME: "max_avg_wind_speed",
        BUILDING_KPI_MATRIX: {
            CELL: [
                "Tot_installed_power",
                "Annual_installed_power",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Optimal_turbine",
                "Energy_prod",
                "Total_energy",
                "Load_factor",
                "Year_lcoe",
                "Cell_residual_area",
                "Ava_tot_area",
                "Acceptance_forest",
                "Acceptance_land",
                "Historical_turbine_name",
            ],
            ADMIN: [
                "Installed_soc_accept_power_pot",
                "Tot_ava_tech_power_pot",
                "Max_installable_power",
                "Tot_soc_accept_power_pot",
                "Ava_soc_accept_power_pot",
            ],
        },
    },
};

export const VERSIONED_OFFSHORE_CONSTANTS = {
    "v2021.1.0": {
        TRAJECTORY_END_YEAR: 2050,
        KPI_TOOLTIPS: [
            {
                kpi: "Constraints_Category",
                description:
                    "The constraint category defines the available areas for wind power deployment considered in your scenario",
            },
            {
                kpi: "Constraints_Supply_Curves",
                description: "Supply curve type(s) to be simulated",
            },
            {
                kpi: "Discount_rate_WACC",
                description: "Discount rate used in the LCOE calculations",
            },
            {
                kpi: "Interim_Interest",
                description:
                    "Used for topping up TOTEX and CAPEX with an additional financing cost over the first years of the project",
            },
            {
                kpi: "Re_Powering_Factor",
                description: "Factor accounting for the capacity increase of repowering",
            },
            {
                kpi: "Re_Powering_Rate",
                description: "Factor accounting for the replacement rate of repowering",
            },
            {
                kpi: "To_be_Installed",
                description:
                    "Enter a gross trajectory (OWF data shall be included, repowering is considered in addition if not excluded)",
            },
            {
                kpi: "Trajectory_Year",
                description: "Set an end year for your trajectory at which the analysis stops",
            },
            {
                kpi: "Turbine_availability",
                description:
                    "Expresses the expected average annual turbine operating availability over its lifetime",
            },
            { kpi: "Turbine_lifetime_mean", description: "Average technical lifetime" },
            {
                kpi: "Turbine_lifetime_sigma",
                description:
                    "Random variable (positive or negative) that is added to the average technical lifetime (mean)",
            },
            {
                kpi: "power_density",
                description:
                    "Describes the (maximum) wind capacity installed per km² of available area (MW/km²)",
            },
            {
                kpi: "Technical_Limit_Set",
                description:
                    "Check box to apply country-specific reference value and uncheck to apply 20 MW/km²",
            },
        ],
    },
};

export const COUNTRY_ABBREV = {
    BELGIUM: "BE",
    GERMANY: "DE",
    SPAIN: "ES",
    FRANCE: "FR",
    ITALY: "IT",
    NETHERLANDS: "NL",
    POLAND: "PL",
    PORTUGAL: "PT",
    UNITEDKINGDOM: "UK",
    IRELAND: "IE",
};

export const CONSTRAINTS_CATEGORIES = [
    { label: "Available Area without Forest (category 1)", filter: "con1_nf" },
    { label: "Available Area without Forest (category 2)", filter: "con2_nf" },
    { label: "Available Area without Forest (category 3)", filter: "con3_nf" },
    { label: "Available Area with Forest (category 1)", filter: "con1_wf" },
    { label: "Available Area with Forest (category 2)", filter: "con2_wf" },
    { label: "Available Area with Forest (category 3)", filter: "con3_wf" },
];

export const SOCIAL_ACCEPTANCE_TABLE_COLUMNS = {
    // it should be extendable to onshore as well if needed
    OFFSHORE_DIST_ACCEPTANCE_COLUMN: "Distance from shore (in NM)",
    OFFSHORE_ACCEPTANCE_COLUMN: "Acceptance factor",
    OFFSHORE_SEA_BASIN_COLUMN: "Sea basin",
};

export const MARKET_VALUE_LAYERS = {
    MAP: "StudyAreaLayers-PRODEC-ONSHORE:Layer-MAP-MARKET-VALUE",
    CELL: "StudyAreaLayers-PRODEC-ONSHORE:Layer-CELL-MARKET-VALUE",
    REF: "StudyAreaLayers-PRODEC-ONSHORE:Layer-REF-MARKET-VALUE",
    STATS: "StudyAreaLayers-PRODEC-ONSHORE:Layer-STATS-MARKET-VALUE",
};

export const MARKET_VALUE_TIME_INTERVAL = [2010, 2018];
