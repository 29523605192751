/**
 * Knockout JQuery UI Binding Handler
 *
 * Usage:
 *
 * # use jquery ui default options
 * <div data-bind='draggable'></div>
 *
 * # use custom options
 * <div data-bind='draggable:{options:{...}}'></div>
 *
 * # class animation (special case)
 * <div data-bind='addClass:{className:'class1', options:{...}}'></div>
 *
 * <div data-bind='toggleClass:{className:'class1', switch:true, options:{...}}'></div>
 * <div data-bind='switchClass:{removeClassName:'class1', addClassName: 'class2', options:{...}}'></div>
 */

const { hasOwnProperty } = Object.prototype;

function getJQueryUIBindings($) {
  const interactions = ["draggable", "droppable", "resizable", "selectable", "sortable"];

  const effects = [
    "effect",
    "show",
    "hide",
    "toggle",
    "addClass",
    "removeClass",
    "toggleClass",
    "switchClass",
    "animate",
  ];

  // currently aim to only support interactions and effects
  const bindings = [...interactions, ...effects];

  const getBindingArgs = (bindingName, value) => {
    if (!value) return [value];

    const { options } = value;
    const { className } = value;

    if (["addClass", "removeClass"].includes(bindingName)) {
      return [className, options];
    }

    if (bindingName === "toggleClass") {
      return hasOwnProperty.call(value, "switch")
        ? [className, !!value.switch, options]
        : [className, options];
    }

    if (bindingName === "switchClass") {
      return [value.removeClassName, value.addClassName, options];
    }

    return [options];
  };

  const getBindingHandler = bindingName => ({
    update(element, valueAccessor) {
      // value should be jquery ui draggable configuration object
      const value = ko.toJS(ko.unwrap(valueAccessor()));
      const bindingArgs = getBindingArgs(bindingName, value);
      $(element)[bindingName](...bindingArgs);
    },
  });

  return bindings.reduce((accumulator, binding) => {
    accumulator[binding] = getBindingHandler(binding);
    return accumulator;
  }, {});
}

export default getJQueryUIBindings(window.jQuery);
