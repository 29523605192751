import bg from "../assets/images/prodec/wind_turbines.jpg";

class Carousel {
    constructor() {
        this.currentIdx = ko.observable(0);

        this.slides = ko.observableArray(
            [
                bg,
            ].map(item => this.getBackgroundImage(item)),
        );

        // bind class method into instance
        this.next = this.next.bind(this);
        // this.dispose = this.dispose.bind(this);

        this.timer = setInterval(this.next, 5500);

        this.dispose = this.dispose.bind(this);
    }

    next() {
        const { length } = this.slides();
        const nextIdx = this.currentIdx() + 1;
        this.currentIdx(nextIdx < length ? nextIdx : 0);
    }

    dispose() {
        window.clearInterval(this.timer);
    }

    getBackgroundImage(imgPath) {
        return `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${imgPath})`;
    }
}

const carousel = new Carousel();

export default carousel;
