"use strict";

class AnalysingTargetListEditUI {
    constructor(UI, controller){
        this.controller=controller;
        this.UI = UI;
        this.contentFileJSON = this.UI.accessContentJSON;

        this.treeUO;
        this.initTreeUO();
        this.treeUO.checkAllNodes(false);//Solve bug #3992

        //Build and add the box
        this.createboxTargetList_edit(function() {return true;});

        //define and add events
        $("#TargetList_Edit_finish").click(()=> {
            $("#box_TargetList_Edit").hide();
        });
    }

    createboxTargetList_edit(CallBackClose) {

        $("#box_TargetList_Edit").remove();
        var c = "<div id=\"TargetList_Edit_content\">";
        c += "      <div style=\"position:absolute; top:0px; left:0px; bottom:0px; width:260px; overflow : auto;\">";
        c += "          <div id=\"uo_TargetList_Edit\" style=\"width:100%;\"></div>"; //Arbre avec les urban Objects
        c += "      </div>";
        c += "      <div class=\"use-skin-border\" style=\"position:absolute; top:0px; left:260px; bottom:0px; right:0px; overflow:auto;\">";
        c += "          <div style=\"text-align:left; height:65px;\">";
        c += "              <div class=\"pull-left\" >";
        c += "                  <table><tr><td><h3 style=\"padding-bottom: 9px;\">" + this.contentFileJSON.MENU_LEFT_SUPER_USER_EDIT_TARGET_EDIT_NAME + "</h3></td><td>&nbsp;&nbsp;&nbsp;<input type=\"text\" id=\"TargetList_Edit_kpi_filter\" /></td></tr></table>";
        c += "              </div>";
        c += "          </div>";
        c += "          <div id=\"TargetList_Edit_noUO\" class=\"callout use-skin-border\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
        c += "              <h4>" + this.contentFileJSON.MENU_TOP_CITY_URBAN + "</h4>";
        c += "              <p>" + this.contentFileJSON.MENU_TOP_CITY_URBAN_MESSAGE + "</p>";
        c += "          </div>";
        c += "          <div id=\"TargetList_Edit_noAttrib\" class=\"callout use-skin-border\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
        c += "              <h4>" + this.contentFileJSON.MENU_LABEL_TARGET + "</h4>";
        c += "              <p>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_EDIT_TARGET_EDIT_MESSAGE + "</p>";
        c += "          </div>";
        c += "          <div id=\"items_TargetList_Edit\" style=\"display:none\">";
        c += "          </div>";
        c += "          <div class=\"overlay\" style=\"display:none\">";
        c += "              <i class=\"fa fa-refresh fa-spin\"></i>";
        c += "          </div>";
        c += "      </div>";
        c += "  </div>";

        var f = "<div class=\"pull-right\" ><button class=\"btn use-skin\" style=\"color:#ffffff\" id=\"TargetList_Edit_finish\">" + this.contentFileJSON.MENU_BUTTON_FINISH + "</button></div>";

        this.UI.CreateBox("TargetList_Edit", {
            draggable: true,
            group: "Analysing",
            resizable: {
                minHeight: 500,
                minWidth: 800 /*, handles: 'e, s, w'*/
            }, //...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
            useSkin: true,
            title: this.contentFileJSON.MENU_LABEL_TAR_ATT + "<span id=\"TargetList_Edit_title_span\"></span>",
            content: c,
            footer: f,
            addOverlay: true,
            visible: false,
            menuBt: {
                reduceTo: "box_Analysing_TargetList", //ID vers qui on doit réduire la div
                fullscreen: {
                    css: {
                        position: "absolute",
                        top: "125px",
                        width: "860px",
                        height: "500px",
                        left: "6%",
                        right: "",
                        bottom: ""
                    }
                } //position / taille de div pour quand on sort du fullscreen
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "125px",
                left: "6%",
                width: "860px",
                height: "500px",
                "min-width": "800px",
                "min-height": "500px",
                "z-index": Zindex
            }
        }, CallBackClose);
        Zindex = Zindex + 1;
    }
    getUI(){return this.UI;}
    initTreeUO() {
        if (this.treeUO == undefined) {
            this.treeUO = $.fn.zTree.getZTreeObj("tree_URB");
        }
    }

    getTreeUO() {
        this.initTreeUO();
        return this.treeUO;
    }
    /*initTree*/
    initTree(){
        $("#TargetList_Edit_kpi_filter").keyup(() =>{
            var value = $.trim($("#TargetList_Edit_kpi_filter").val()).toLowerCase();
            this.controller.setTabTargetFilter(value);
        });
    }

    /*ResetFromUO*/
    resetFromUO() {
        $("#TargetList_Edit_noUO").show();
        $("#items_TargetList_Edit").hide();
    }

    /*LoadFromUO*/
    setVisibilityEditNoUO(visible) {
        if (visible){
            $("#TargetList_Edit_noUO").show();
        }else{
            $("#TargetList_Edit_noUO").hide();
        }
    }
    /*Load*/
    load(nodeTarget) {
        $("#items_TargetList_Edit").hide();
        if (nodeTarget != undefined && nodeTarget != null) {
            this.controller.setCurrentNodeTarget(nodeTarget); //Bien passer le node.id à tous les appels everest
            $("#TargetList_Edit_title_span").text(nodeTarget.name);
            $("#box_TargetList_Edit").show();
        }
        $("#TargetList_Edit_noAttrib").hide();
        //Si un uo est selectionné, on lance l'init, sinon on affiche le "You have to select one or more UO"
        var selectedNodes = this.treeUO.getCheckedNodes();
        if (selectedNodes.length > 0) {
            $("#TargetList_Edit_noUO").hide();
            $("#overlay_TargetList_Edit").show();
            this.controller.readTargetKPIValues(selectedNodes[0]);
        } else {
            $("#TargetList_Edit_noUO").show();
        }
    }


    /*BuildInterface*/
    setVisibilityNoRes(visible) {
        if (visible){
            $("#TargetList_Edit_noAttrib").show();
            $("#items_TargetList_Edit").hide();
        }else{
            $("#TargetList_Edit_noAttrib").hide();
            $("#items_TargetList_Edit").show();
        }
    }
    buildInterface(){
        var slider = $("#slider_trgt_ro_TargetList_Edit_it").data("ionRangeSlider");
        slider.reset();
        $("#overlay_TargetList_Edit").hide();
    }




    /*Everest_callbackError*/
    everestCallbackError(data, status) {
        $("#overlay_TargetList_Edit").hide();
        this.treeUO.checkAllNodes(false);

        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");
    }
}
export default AnalysingTargetListEditUI;
