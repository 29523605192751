"use strict";

/***
 Stores all box creation : interface for new element, or results interface
 It is defined in UI.js

 - UI : used to access at the labels JSON file content and the createBox() function
 ***/

var CreateBox_CLS = function(UI) {
    this.UI = UI;
    this.contentFileJSON = this.UI.accessContentJSON;

};

CreateBox_CLS.prototype.constructor = CreateBox_CLS;

/*used by mosa_ui and interface_mosa_ui*/
CreateBox_CLS.prototype.createboxUpdateMOSA = function(type, CallBackClose) {

    let typeFile = "";
    let txt = "";

    // i18n data bind labels for updateMOSA (MID or CityGML)
    // default MID
    let updateMosaLabelBinding = {
        selectFile: "i18n:{text:{key:'menu:MENU_LABEL_MID_UPDATE_TXT'}}",
        showLog: "i18n:{text:{key:'menu:MENU_BUTTON_LOG'}}",
        loadFile: "i18n:{text:{key:'menu:MENU_LABEL_MID_LOAD_TXT'}}"
    };

    if (type === "MID") {
        typeFile = this.contentFileJSON.MENU_LABEL_MID;
        txt = this.contentFileJSON.MENU_LABEL_MID_UPDATE_TXT;
    } else if (type === "3D") {
        typeFile = this.contentFileJSON.MENU_LABEL_GML;
        txt = this.contentFileJSON.MENU_LABEL_GML_UPDATE_TXT;

        // switch data bind
        updateMosaLabelBinding.selectFile = "i18n:{text:{key:'menu:MENU_LABEL_GML_UPDATE_TXT'}}";
        updateMosaLabelBinding.loadFile = "i18n:{text:{key:'menu:MENU_LABEL_GML_LOAD_TXT'}}";
    }

    $("#box_updateMosaSelect").remove();

    let c = `<div id="updateMosaSelect_content">`;
    c += `  <table style="width:100%; height:100%;">`;

    c += `      <tr><td><span class="btn btn-primary use-skin fileinput-button">` + `<label for="updateMosa_url" data-bind="${updateMosaLabelBinding.selectFile}">${txt}</label><input id="updateMosa_url" type="file" >` + `</span></td><td><input type="checkbox" id="updateMosa_check"><label for="updateMosa_check" data-bind="${updateMosaLabelBinding.showLog}">${this.contentFileJSON.MENU_BUTTON_LOG}</label></td></tr>`;

    c += `      <tr><td><span id="fileSelectedUpdateMOSA"></span></td><td><span id="runIDUpdateMOSA"></span></td></tr>`;
    c += `      <tr><td><span id="runStatusUpdateMOSA" style="font-weight:bold;"></span></td><td><span id="textStatusUpdateMOSA" style="font-weight:bold;"></span></td></tr>`;

    c += `  </table><br/><br/>`;
    c += `      <div id="contentLogs" style="display:none;">`;
    c += `<textarea id="boxLogUpdate" style="width:100%; height:100px;max-height:350px;font-size:11px;" readonly></textarea></div>`;
    c += `  </div>`;

    let f = `<button class="btn btn-primary use-skin" id="updateMosaSelect_load" data-bind="${updateMosaLabelBinding.loadFile}">${this.contentFileJSON.MENU_LABEL_LOAD + typeFile + this.contentFileJSON.MENU_LABEL_FILE}</button></div>`;

    this.UI.CreateBox("updateMosaSelect", {
        draggable: true,
        useSkin: true,
        title: this.contentFileJSON.MENU_UPDATE_MOSA,
        tag: "MENU_UPDATE_MOSA",
        content: c,
        addOverlay: true,
        footer: f,
        resizable: {
            minHeight: 400,
            minWidth: 1200
        },
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            left: "15%",
            width: "400px",
            height: "360px",
            "min-width": "400px",
            "min-height": "360px",
            "max-width": "75%",
            "max-height": "550px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;

    // post apply bindings for the dom element
    ko.applyBindings({}, document.getElementById("box_updateMosaSelect"));
};

/*Still used ???*/
CreateBox_CLS.prototype.createboxUpdateInfoUser = function(CallBackClose) {

    $("#box_updateprofil").remove();
    var c = "<div class=\"user user-menu use-skin\" style=\"padding: 5px; margin: -10px -10px 5px -10px;color: #ffffff;\">";
    c += "<p>Fullname : <p>Actual : <label id=\"actual_fullname\"></label></p>";
    c += "<p>New : <input type=\"text\" class=\"form-control\" id=\"new_fullname\"></p></p>";
    c += "<p> Email : <p>Actual : <label id=\"actual_email\"></label></p>";
    c += "<p>New : <input type=\"text\" class=\"form-control\" id=\"new_email\"></p></p>";
    c += "<p>Login : <p>Actual : <label id=\"actual_login\"></label></p>";
    c += "<p>New : <input type=\"text\" class=\"form-control\" id=\"new_login\"></p></p>";
    c += " </div>";
    var f = "<div class=\"pull-right\" ><button class=\"btn btn-primary use-skin\" id=\"valid_updateinfos\">" + this.contentFileJSON.MENU_BUTTON_VALID + "</button></div>";

    this.UI.CreateBox("updateprofil", {
        draggable: true,
        useSkin: true,
        title: this.contentFileJSON.MENU_LABEL_UPDATE_INFOS,
        content: c,
        addOverlay: true,
        footer: f,
        resizable: {
            minHeight: 360,
            minWidth: 400
        },
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            left: "40%",
            width: "400px",
            height: "360px",
            "min-width": "400px",
            "min-height": "360px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};


/*Still used ???*/
CreateBox_CLS.prototype.createBoxDowload = function() {


    if ($("#box_dowloadLink").length > 0) {
        CallBackClose();
        this.UI.nbBoxOpened--;
        $("#box_dowloadLink").remove();
    }


    var c = "<div id=\"box_dowloadLink_content\" style=\"width:100%; height:100%;\">";
    c += "<div> Dowload Link </div><br/>";
    c += "</div>";

    this.UI.CreateBox("updateOpacityTerrain", {
        draggable: true,
        useSkin: true,
        title: "Dowload Link",
        content: c,

        addOverlay: false,
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            right: "0px",
            width: "300px",
            height: "200px",
            "min-width": "300px",
            "min-height": "200px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};

export default CreateBox_CLS;
