import TaskSimulation_CLS from "./controller/tasksimulation_cls.js";
import promisify from "./helpers/promise.util.js";
import BundleSharing from "./components/bundle.sharing.js";
import Auth from "./auth/index.js";
import loadingGIF from "ztree/css/zTreeStyle/img/loading.gif"
import newImg from "./assets/images/interface/new.png"
import successImg from "./assets/images/interface/success.png"
import runImg from "./assets/images/interface/run.png"
import stopImg from "./assets/images/interface/stop.png"
import downloadImg from "./assets/images/interface/download.png"

var Interface_Gestion_SIMU_CLS = function(Everest, GestionInitiatives, TaskManager, UI) {
    this.UI = UI;
    this.TaskManager = TaskManager;
    this.Everest = Everest;

    this.contentFileJSON = this.UI.accessContentJSON;
    this.contentMessageFileJSON = this.UI.accessMessageContentJSON;

    this.collapseNode = true;
    //Rajoute la box
    this.createboxGestionSimu(() => {
        if (this.tree) {
            this.tree.cancelMarkedNodes();
            // copy is off, paste is disabled
            this.toggleCopyPasteButtons(false);
        }
        return true;
    });

    this.tabSimulations = [];
    this.tree = $("#tree");
    this.isInit = false;
    this.zNodes = []; //[{ id: 1, pId: 0, name: "[core] Basic Functions", open: false },{id:101, pId:1, name:"Standard JSON Data", file:"core/standardData"}];
    this.GestionInitiatives = GestionInitiatives;
    this.currentCopy = null;
    this.parentExpand = undefined;

    //boolean for testing delete status
    // update ztree shared between interfaces: ranking results targeting
    this.isDeletingSim = false;

    //boolean set true when it's the user whiwh launch a run (and not the initialisation)
    this.Everest.cptRunIsnotFromInit = 0;
    this.Everest.boolRunIsnotFromInit = false;

    this.tabEvent = [];
    this.addEventTree = function(id, fct) {
        this.tabEvent[id] = fct;
    };

    this.runComplete = function(id) {
        var node = this.tree.getNodeByParam("id", id, null);
        if (node != null) {
            var nodeParent = node.getParentNode();
            for (var i in this.tabEvent) {

                if (this.tabEvent.hasOwnProperty(i)) {
                    if (this.isDeletingSim == true) {
                        this.tabEvent[i](node, nodeParent, true);
                    } else {
                        this.tabEvent[i](node, nodeParent, false);
                    }

                }
            }
        }
    };

    // distinguish copy & cut (default is neither copy nor cut)
    this.isCopyOrCut = undefined;

    BundleSharing.setup(Everest, UI);
};

Interface_Gestion_SIMU_CLS.prototype.constructor = Interface_Gestion_SIMU_CLS;

Interface_Gestion_SIMU_CLS.prototype.createboxGestionSimu = function(CallBackClose) {

    $("#box_simulation").remove();
    var c = "<div id=\"simulation_content\" >";
    c += "  <a class=\"btn btn-block btn-social btn-xs use-skin\"  id=\"m_new_bundle\"><i class=\"fa fa-folder-o\"></i> " + this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_NEWFOLDER_CREATE + "</a>";
    c += "  <div id=\"sim_default\">";
    c += "      <div id=\"sim_move\">";
    c += "          <ul id=\"tree\" class=\"ztree active\"></ul>";
    c += "      </div>";
    c += "  </div>";
    c += "  <div class=\"overlay\">";
    c += "      <i class=\"fa fa-refresh fa-spin\"></i>";
    c += "  </div>";
    c += "</div><!-- /.tab-pane -->";

    this.UI.CreateBox("simulation", {
        draggable: true,
        group: "Simulating",
        resizable: {
            minHeight: 500,
            minWidth: 475 /*, handles: 'e, s, w'*/
        }, //...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
        useSkin: true,
        title: this.contentFileJSON.MENU_LEFT_SIMULATING_CASES,
        content: c,
        visible: false,
        menuBt: {
            reduceTo: "div_SimulationCase" //ID vers qui on doit réduire la div
            //fullscreen: { position: "absolute", top:"115px", width:"475px", height:"500px", left: "20%", right: '', bottom: '' },//position / taille de div pour quand on sort du fullscreen
        },
        style: { //Contient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "115px",
            left: "20%",
            width: "475px",
            height: "500px",
            "min-width": "475px",
            "min-height": "500px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};

Interface_Gestion_SIMU_CLS.prototype.init = function() {
    this.UI.GestionSimulations = this;
    if (this.UI.classBindData.authToken != null) {
        if (this.isInit == true) {
            $.fn.zTree.destroy("tree");
            // remove toggle of tree status
            $("#mainpanel").off("click", this.toggleTreeActiveStatus);
            this.isInit = false;
        }

        this.zNodes = [];
        this.Everest.List_Bundles(this.initTree.bind(this), this.Everest_callbackError.bind(this));
        //Init le click sur "Run" du menu droit
        $("#rMenu").zIndex(999999);

        //Init le click sur "New Simulation" du menu droit
        $("#m_new_simu").click($.proxy(function() {
            $("#rMenu").hide();
            $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
            $("#box_newSimulationCase").css("zIndex", Zindex + 1);
            this.UI.setZIndexToMaxAsync("box_newSimulationCase");
            if (this.tree.getSelectedNodes()[0]) {
                this.createNewSimulationCase(this.tree.getSelectedNodes()[0], undefined, false);
            }
        }, this));

        //Init le click sur "Delete Simulation" du menu droit
        $("#m_del").click($.proxy(function() {
            $("#rMenu").hide();
            $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
            var nodeSelect = this.tree.getSelectedNodes();
            if (nodeSelect[0]) {
                for (var i = 0; i < this.TaskManager.simRunning.length; i++) {
                    if (this.TaskManager.simRunning[i] == nodeSelect[0].id) {
                        this.UI.createAlert("alert", "error", this.contentMessageFileJSON.MESSAGE_ALERT_ERROR_DEL_SIMRUN);
                        return -1;
                    }
                }
                this.UI.createConfirmBox(this.contentFileJSON.MENU_LABEL_MESSAGE_DEL_SIM + nodeSelect[0].name, this.callbackSuccess_ConfirmDelSimu.bind(this));
            }
        }, this));

        // init copy folder
        $("#m_copy_fold").on("click", () => {
            $("#rMenu").hide();

            const nodes = this.tree.getSelectedNodes();
            const selectedNode = nodes.length ? nodes[0] : undefined;

            if (selectedNode) {
                // if current marked node is sim case & it is in copy, force its'copy off
                this.toggleCopyPasteButtons(false);
                this.tree.cancelMarkedNodes();
                // remarked parent node
                this.tree.markNodes(selectedNode);
                this.createNewBundle(selectedNode, true);
                // select mode is off
                this.tree.cancelSelectedNode(selectedNode);
            }
        });

        //Init le click sur "New Bundle"
        $("#m_new_bundle").click($.proxy(function() {
            this.createNewBundle();

        }, this));

        //Init le click sur "Delete Simulation" du menu droit
        $("#m_delete_fold").click($.proxy(function() {
            $("#rMenu").hide();
            $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
            if (this.tree.getSelectedNodes()[0]) {
                this.UI.createConfirmBox(this.contentFileJSON.MENU_LABEL_MESSAGE_DEL_FOL + this.tree.getSelectedNodes()[0].name, this.callbackSuccess_ConfirmDelFold.bind(this));
            }
        }, this));

        // share folder
        $("#m_share_fold").on("click", () => {
            $("#rMenu").hide();

            const nodes = this.tree.getSelectedNodes();
            const selectedNode = nodes.length ? nodes[0] : undefined;

            if (selectedNode) {
                const promise = promisify(this.Everest.Read_Bundle_Info(selectedNode));

                if (!BundleSharing.isOpen()) {
                    BundleSharing.show();
                }

                BundleSharing.isFetching(true);

                promise.then(data => {
                    const {modelId, ...others} = data;
                    const bundle = {...others, bundleId: selectedNode.id}
                    const [users, groups] = Auth.reformUsersAndGroups(bundle);
                    BundleSharing.loadData(bundle, users, groups);
                }).catch(error => {
                    this.Everest_callbackError(error);
                }).finally(() => {
                    BundleSharing.isFetching(false);
                });
            }
        });

        // init copy simulation
        $("#m_dupl").on("click", () => {
            $("#rMenu").hide();
            // make sure in 'copy' mode
            this.isCopyOrCut = "copy";

            const nodes = this.tree.getSelectedNodes();
            const selectedNode = nodes.length ? nodes[0] : undefined;

            if (!selectedNode) return;

            if ($("#m_dupl").hasClass("btn-danger")) {
                // cancel the current marked sim case
                this.tree.cancelMarkedNodes();
                // copy is off
                this.toggleCopyPasteButtons(false);
            } else {
                // cancel unexpected marked parent node, e.g. right click copy folder, then right click copy sim case
                this.tree.cancelMarkedNodes();
                this.tree.markNodes(selectedNode);
                // copy is on
                this.toggleCopyPasteButtons(true);
            }
        });

        // init cut simulation
        $("#m_cut").on("click", () => {
            $("#rMenu").hide();
            // make sure in 'cut' mode
            this.isCopyOrCut = "cut";

            const nodes = this.tree.getSelectedNodes();
            const selectedNode = nodes.length ? nodes[0] : undefined;

            if (!selectedNode) return;

            if ($("#m_cut").hasClass("btn-danger")) {
                // cancel the current marked sim case
                this.tree.cancelMarkedNodes();
                // cut is off
                this.toggleCutPasteButtons(false);
            } else {
                // cancel unexpected marked parent node, e.g. right click copy folder, then right click copy sim case
                this.tree.cancelMarkedNodes();
                this.tree.markNodes(selectedNode);
                // cut is on
                this.toggleCutPasteButtons(true);
            }
        });

        // init paste simulation
        $("#m_paste").on("click", () => {
            $("#rMenu").hide();

            const nodes = this.tree.getMarkedNodes();
            const markedNode = nodes.length ? nodes[0] : undefined;
            const targetParentNode = this.tree.getSelectedNodes()[0];

            if (!markedNode) return;

            if (this.isCopyOrCut === "copy") {
                this.createNewSimulationCase(targetParentNode, markedNode, true);
                // new node is marked as copy, make its copy being on
                this.toggleCopyPasteButtons(true);
            } else {
                this.moveSimulationCase(targetParentNode, markedNode);
            }
        });

        //Init le click sur "Infos" du menu droit
        $("#m_info").click($.proxy(() => {
            $("#rMenu").hide();
            $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
            $("#box_infoSimulation").css("zIndex", Zindex + 1);
            this.UI.setZIndexToMaxAsync("box_infoSimulation");
            let selectedNode = this.tree.getSelectedNodes()[0];

            if (selectedNode) {
                this.createResourceInfoBox(selectedNode);
            }
        }));

        //Init le click sur "Define simulation case" du menu droit
        $("#m_define_sc").click($.proxy(function() {
            $("#rMenu").hide();
            this.defineScenario();
        }, this));
    }
};

Interface_Gestion_SIMU_CLS.prototype.defineScenario = function() {
    $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
    this.UI.setZIndexToMaxAsync("box_gestion_Initiatives");
    if (this.tree.getSelectedNodes()[0]) {
        var n = this.tree.getSelectedNodes()[0];
        //this.ApplyContext(this.tree.getSelectedNodes()[0]);
        this.UI.moveUOto({
            idParent: "box_gestion_Initiatives",
            id: "uo_gestion_Initiatives",
            ItemLink: this.GestionInitiatives
        });
        this.UI.classBindData.simuSelectedInitiative = n.id;

        // get the run status of the current sim case first
        promisify(this.Everest.Read_Run_Status(n.id)).then(resp => {
            this.GestionInitiatives.Load(n, resp["status"]);

            if (resp["status"] !== "notStarted") {
                this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_NO_MORE_SIM_CASE_EDIT);

                // disable import (ugly hack, when scenario is open and no objects are selected)
                $("#gestion_Initiatives_import").prop("disabled", resp["status"] !== "notStarted");
            }

        }).then(() => {
            // dispatch event 'onDefineScenario'
            if (window.eventDispatcher) {
                window.eventDispatcher.dispatch("onScenarioDefine");
            }
        }).catch(error => this.Everest_callbackError(error));
    }
};

Interface_Gestion_SIMU_CLS.prototype.callbackSuccess_ConfirmDelSimu = function(data, status, node) {

    // success callback after simulation being deleted
    const successCallback = function(data, status, node) {
        this.isDeletingSim = true;
        this.runComplete(node.id);
        var parentNode = node.getParentNode();
        this.tree.removeNode(node);
        parentNode.isParent = true;
        this.tree.updateNode(parentNode);
        this.UI.createAlert("notif", "success", `${this.contentMessageFileJSON.MESSAGE_ALERT_SIMU_DEL}:<br/>${node.name}`);
        this.isDeletingSim = false;

        // in case that user delete a node, which is in 'copy mode'
        const markedNodes = this.tree.getMarkedNodes();
        if (markedNodes.includes(node)) {
            this.toggleCopyPasteButtons(false);
            this.tree.cancelMarkedNodes(node);
        }

        // update all corresponding results trees
        const trees = [
            $.fn.zTree.getZTreeObj("treeSimAnaResult"),
            $.fn.zTree.getZTreeObj("treeSimAnaTargeting"),
            $.fn.zTree.getZTreeObj("treeSimAnaRanking")
        ];

        for (let tree of trees) {
            if (tree === undefined || tree === null) continue;

            // if the last child node is deleted, parent node will become leaf node
            tree.getNodes().forEach(parentNode => {
                // remove non parent node
                if (!parentNode.isParent) {
                    tree.removeNode(parentNode);
                }
            });
        }

        // fire simulation case delete event for PRODEC
        this.fireCaseDeleteEvent([node.id]);

    }.bind(this);

    // error callback
    const errorCallback = this.Everest_callbackError.bind(this);

    // pick up the selected node
    const selectedNode = this.tree.getSelectedNodes()[0];

    promisify(this.Everest.Read_Run_Status(selectedNode.id)).then(resp => {

        const runStatus = resp["status"];

        // if simulation not started at all, we don't perform delete ResultsLayer
        if (runStatus === "notStarted") {
            this.Everest.Delete_Simulation_Case(selectedNode, successCallback, errorCallback);
            return;
        }

        const inp = [
            {
                studyArea: this.Everest.selectedStudyAreaName,
                caseId: selectedNode.id
            }
        ];

        return promisify(this.Everest.Delete_GeoServerLayer(inp)).then(() => {

            this.Everest.Delete_Simulation_Case(selectedNode, successCallback, errorCallback);

        }).catch(error => {
            const err = {
                responseJSON: {
                    error: error.responseJSON.status,
                    errorMessage: "Pythlet Service Error: " + error.responseJSON.statusText
                }
            };
            this.Everest_callbackError(err);
        });

    }).catch(error => this.Everest_callbackError(error));
};

Interface_Gestion_SIMU_CLS.prototype.callbackSuccess_ConfirmDelFold = function(data, status, node) {

    const successCallback = function(data, status, node) {
        // collect caseIds before it is removed
        const children = node.children ? node.children : [];
        const caseIds = children.map(n => n.id);

        this.isDeletingSim = true;
        this.runComplete(node.id);
        this.tree.removeNode(node);
        this.UI.createAlert("notif", "success", `${this.contentMessageFileJSON.MESSAGE_ALERT_FOLDER_DEL}:<br/>${node.name}`);
        this.isDeletingSim = false;
        this.tree.cancelMarkedNodes();

        // fire "onSimCasesDelete" (for PRODEC)
        this.fireCaseDeleteEvent(caseIds);

    }.bind(this);

    const errorCallback = this.Everest_callbackError.bind(this);

    const selectedNode = this.tree.getSelectedNodes()[0];
    const childrenNodes = selectedNode.children ? selectedNode.children : [];

    const inp = childrenNodes.map(n => ({
        studyArea: this.Everest.selectedStudyAreaName,
        caseId: n.id
    }));

    // if bundle is empty, we don't delete ResultsLayer
    if (!inp.length) {
        this.Everest.Delete_Bundle(selectedNode, successCallback, errorCallback);
        return;
    }

    // if bundle is non empty, delete ResultsLayer of all cases in selected bundle
    promisify(this.Everest.Delete_GeoServerLayer(inp)).then(() => {
        this.Everest.Delete_Bundle(selectedNode, successCallback, errorCallback);
    }).catch(error => {
        const err = {
            responseJSON: {
                error: error.responseJSON.status,
                errorMessage: "Pythlet Service Error: " + error.responseJSON.statusText
            }
        };
        this.Everest_callbackError(err);
    });
};

Interface_Gestion_SIMU_CLS.prototype.createNewBundleBox = function(CallBackClose, node = undefined, isCopyPaste = false) {

    $("#box_newBundle").remove();

    const defaultName = node && isCopyPaste ? $.fn.zTree._z.tools.getDefaultNodeName(node.name) : "";

    let c = `<div id="newBundle_content" style="text-align: left">`;

    if (node) {
        c += `<h5 style="text-align: center;"><i>${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_NEW_COPY} : ${node.name}</i></h5>`;
    }

    c += `<table class="table table-responsive table-no-border">`;

    c += `<tr>`;
    c += `<td>${this.contentFileJSON.MENU_NEW_NAME}</td>`;
    c += `<td>
            <input type="text" class="form-control" id="newBundle_name"
            placeholder="${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_NEWFOLDER_NAME_CONTENT}" value="${defaultName}"
            />
          </td>`;
    c += `</tr>`;

    c += `<tr>`;
    c += `<td>${this.contentFileJSON.MENU_NEW_COMMENTS}</td>`;
    c += `<td><textarea id="newBundle_comments" rows="3" class="form-control" placeholder=""></textarea></td>`;
    c += `</tr>`;

    c += `</table>`;
    c += `</div>`;

    let f = `<div class="pull-right" ><button class="btn btn-primary use-skin" id="newBundle_create">${this.contentFileJSON.MENU_NEW_CREATE}</button></div>`;


    this.UI.CreateBox("newBundle", {
        draggable: true,
        group: "Simulating",
        useSkin: true,
        title: this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_NEWFOLDER_TITLE,
        content: c,
        addOverlay: true,
        footer: f,
        resizable: {
            minHeight: 310,
            minWidth: 400
        },
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            left: "40%",
            width: "400px",
            height: "310px",
            "min-width": "400px",
            "min-height": "310px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;

    // stop TW3D keyDown for all the text inputs
    $("input[id*=newBundle_], textarea[id*=newBundle_]").each(function() {
        $(this).keydown((evt) => evt.stopPropagation());
    });
};

Interface_Gestion_SIMU_CLS.prototype.createResourceInfoBox = function(selectedNode) {

    // TODO stop passing the treeNode to everest API call, we need to decouple zTree and ajax call
    if (selectedNode.isParent) {
        // read bundle info of selected bundle
        let promise = promisify(this.Everest.Read_Bundle_Info(selectedNode));

        promise.then((bundleInfo) => {
            // create the bundle info box for update
            this.createBundleInfoBox(selectedNode, bundleInfo);
        }).catch(error => this.Everest_callbackError(error));

    } else {

        let promises = [promisify(this.Everest.Read_Simulation_Case_Info(selectedNode)), this.getAllScenarios()];

        Promise.all(promises).then(resolved => {
            // info of selected simulation case
            let simCaseInfo = resolved[0];

            // all scenarios (AKA. contexts)
            let scenarios = resolved[1];

            // filter all published scenarios and sorted by scenarioId (DESC)
            scenarios["items"] = scenarios["items"].filter(item => item["published"]).sort((a, b) => b["id"] - a["id"]);

            // create the simulation case info box for update
            this.createSimulationCaseInfoBox(selectedNode, simCaseInfo, scenarios);

        }).catch(error => this.Everest_callbackError(error));
    }

};

Interface_Gestion_SIMU_CLS.prototype.createNewSimulationCaseBox = function(nodeCopy, CallBackClose, isCopyPaste = false) {

    $("#box_newSimulationCase").remove();

    const defaultName = nodeCopy && isCopyPaste ? $.fn.zTree._z.tools.getDefaultNodeName(nodeCopy.name) : "";

    let c = `<div id="newSimulationCase_content" style="text-align: left">`;

    if (nodeCopy !== undefined) {
        c += `<h5 style="text-align: center;"><i>${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_NEW_COPY} : ${nodeCopy.name}</i></h5>`;
    }

    c += `<table class="table table-responsive table-no-border">`;
    c += `<tr>`;
    c += `<td>${this.contentFileJSON.MENU_NEW_NAME}</td>`;
    c += `<td>
            <input type="text" id="newSimulationCase_name" class="form-control"
            placeholder="${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_NEW_NAME_CONTENT}" value="${defaultName}"/>
          </td>`;

    if (!nodeCopy) {
        // get published scenarios and sort them by id
        let nodes = $.fn.zTree.getZTreeObj("treeContextList").getNodesByParam("published", true, null).sort((a, b) => b["id"] - a["id"]);

        let contextsSelect = nodes.reduce((previousVal, currentVal, currentIndex, array) => {
            // if first elem, add open tag <select>
            currentIndex === 0 ? previousVal += `<select id="new_contexts_select" class="form-control">` : undefined;

            let selected = currentIndex === 0 ? "selected" : "";
            previousVal += `<option value="${currentVal["id"]}" ${selected}>${currentVal["name"]}</option>`;

            // if last elem, add close tag </select>
            currentIndex === array.length - 1 ? previousVal += `</select>` : undefined;
            return previousVal;
        }, "");

        c += `<tr><td>${this.contentFileJSON.MENU_CONTEXT_LABEL} :</td><td>${contextsSelect}</td></tr>`;
    }

    c += `</tr>`;
    c += `<tr>`;
    c += `<td>${this.contentFileJSON.MENU_NEW_COMMENTS}</td>`;
    c += `<td><textarea id="newSimulationCase_comments" class="form-control" placeholder=""></textarea></td>`;
    c += `</tr>`;
    c += `</table>`;
    c += `</div>`;

    let f = `<div class="pull-right" ><button class="btn btn-primary use-skin" id="newSimulationCase_create">${this.contentFileJSON.MENU_NEW_CREATE}</button></div>`;

    let h = (nodeCopy !== undefined ? 360 : 310);

    this.UI.CreateBox("newSimulationCase", {
        draggable: true,
        group: "Simulating",
        useSkin: true,
        title: this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_NEW_TITLE,
        content: c,
        addOverlay: true,
        footer: f,
        resizable: {
            minHeight: h,
            minWidth: 400
        },
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            left: "40%",
            width: "400px",
            height: h + "px",
            "min-width": "400px",
            "min-height": h + "px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;

    // stop TW3D keyDown for all the text inputs
    $("input[id*=newSimulationCase_], textarea[id*=newSimulationCase_]").each(function() {
        $(this).keydown((evt) => evt.stopPropagation());
    });
};

Interface_Gestion_SIMU_CLS.prototype.createBundleInfoBox = function(selectedNode, bundleInfo) {

    let CallBackClose = () => true;

    $("#box_infoSimulation").remove();

    let resourceType = "bundle";

    let h = 330;
    let c = `<div id="infoSimulation_content" style="text-align: left">`;
    c += `<table class="table table-responsive table-no-border">`;

    // c += `<tr><td>${this.contentFileJSON.MENU_NEW_NAME}</td><td>${data.name}</td></tr>`;
    c += `<tr><td>${this.contentFileJSON.MENU_NEW_NAME}</td><td><input id="${resourceType}_name" class="form-control" type="text" value="${bundleInfo.name}" required></td></tr>`;
    c += `<tr><td>${this.contentFileJSON.MENU_NEW_COMMENTS}</td><td><textarea id="${resourceType}_description" class="form-control">${bundleInfo.description}</textarea></td></tr>`;

    if (!selectedNode.isParent && bundleInfo.scenarioId) {
        c += `<tr><td>${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_INFOS_SCENAR} :</td><td>${bundleInfo.scenarioId}</td></tr>`;
        c += `<tr><td>${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_INFOS_FOLDER} :</td><td>${bundleInfo.bundleId}</td></tr>`;
        h += 50;
    } else if (bundleInfo.modelId !== undefined) {
        c += `<tr><td>${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_INFOS_MODEL} :</td><td>${bundleInfo.modelId}</td></tr>`;
        //IGO_TMA_3 : https://curtis-integ.edf-labs.net/redmine/issues/3875
        c += `<tr><td>${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_INFOS_BUNDLEID} :</td><td>${selectedNode.id}</td></tr>`;
        h += 50;
        ///////////////
    }
    c += `</table>`;
    c += `</div>`;

    let f = `<div class="pull-right">`;
    f += `<button class="btn btn-primary use-skin" id="${resourceType}_update">${this.contentFileJSON.MENU_UPDATE_LAYER_LABEL_UPDATE_BUTTON}</button>`;
    f += `</div>`;

    this.UI.CreateBox("infoSimulation", {
        draggable: true,
        group: "Simulating",
        useSkin: true,
        title: bundleInfo.name,
        content: c,
        footer: f,
        resizable: {
            minHeight: h,
            minWidth: 400
        },
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            left: "40%",
            width: "400px",
            height: h + "px",
            "min-width": "400px",
            "min-height": h + "px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;

    $(`#${resourceType}_update`).click(() => {
        this.updateBundleInfo(bundleInfo, selectedNode);
    });

    // stop TW3D keyDown for all the text inputs
    $(`input[id*=${resourceType}], textarea[id*=${resourceType}]`).each(function() {
        $(this).keydown((evt) => evt.stopPropagation());
    });
};

Interface_Gestion_SIMU_CLS.prototype.createSimulationCaseInfoBox = function(selectedNode, simCaseInfo, scenarios) {

    let CallBackClose = () => true;

    $("#box_infoSimulation").remove();

    let contextsSelect = scenarios.items.reduce((previousVal, currentVal, currentIndex, array) => {
        // if first elem, add open tag <select>
        currentIndex === 0 ? previousVal += `<select id="update_contexts_select" class="form-control">` : undefined;

        let selected = simCaseInfo["scenarioId"] === currentVal["id"] ? "selected" : "";
        previousVal += `<option value="${currentVal["id"]}" ${selected}>${currentVal["name"]}</option>`;

        // if last elem, add close tag </select>
        currentIndex === array.length - 1 ? previousVal += `</select>` : undefined;
        return previousVal;
    }, "");
    let resourceType = "case";

    let h = 330;
    let c = `<div id="infoSimulation_content" style="text-align: left">`;
    c += `<table class="table table-responsive table-no-border">`;

    c += `<tr><td>${this.contentFileJSON.MENU_NEW_NAME}</td><td><input id="${resourceType}_name" class="form-control" type="text" value="${simCaseInfo.name}" required></td></tr>`;
    c += `<tr><td>${this.contentFileJSON.MENU_NEW_COMMENTS}</td><td><textarea id="${resourceType}_description" class="form-control">${simCaseInfo.description}</textarea></td></tr>`;
    c += `<tr><td>${this.contentFileJSON.MENU_CONTEXT_LABEL} :</td><td>${contextsSelect}</td></tr>`;
    c += `<tr><td>${this.contentFileJSON.MENU_LEFT_SIMULATING_CASE_ID} :</td><td>${selectedNode.id}</td></tr>`;

    if (!selectedNode.isParent && simCaseInfo.scenarioId) {
        c += `<tr><td>${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_INFOS_SCENAR} :</td><td>${simCaseInfo.scenarioId}</td></tr>`;
        c += `<tr><td>${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_INFOS_FOLDER} :</td><td>${simCaseInfo.bundleId}</td></tr>`;
        h += 50;
    } else if (simCaseInfo.modelId !== undefined) {
        c += `<tr><td>${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_INFOS_MODEL} :</td><td>${simCaseInfo.modelId}</td></tr>`;
    }
    c += `</table>`;
    c += `</div>`;

    let f = `<div class="pull-right">`;
    f += `<button class="btn btn-primary use-skin" id="${resourceType}_update">${this.contentFileJSON.MENU_UPDATE_LAYER_LABEL_UPDATE_BUTTON}</button>`;
    f += `</div>`;

    this.UI.CreateBox("infoSimulation", {
        draggable: true,
        group: "Simulating",
        useSkin: true,
        title: simCaseInfo.name,
        content: c,
        footer: f,
        resizable: {
            minHeight: h,
            minWidth: 400
        },
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            left: "40%",
            width: "400px",
            height: h + "px",
            "min-width": "400px",
            "min-height": h + "px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;

    // update the resource info
    $(`#${resourceType}_update`).click(() => {
        this.updateSimulationCaseInfo(simCaseInfo, selectedNode);
    });

    // stop TW3D keyDown for all the text inputs
    $(`input[id*=${resourceType}], textarea[id*=${resourceType}]`).each(function() {
        $(this).keydown((evt) => evt.stopPropagation());
    });
};

Interface_Gestion_SIMU_CLS.prototype.createNewBundle = function(node = undefined, isCopyPaste = false) {
    // if cancel give back the callback
    const callback = () => this.focusTreeNode(node);

    // create UI box
    this.createNewBundleBox(callback, node, isCopyPaste);

    $("#newBundle_create").click(() => {

        const newBundleName = $("#newBundle_name").val();
        const newBundleDescription = $("#newBundle_comments").val();

        // validate the input name
        if (!this.isResourceNameValid(newBundleName, undefined, "siblings", "create")) {
            // if validation fails, returns the focus back to original tree node
            callback();
            return;
        }

        // empty parent node, children is undefined;
        const children = node && node.children ? node.children : [];

        const input = {
            bundle: {
                name: newBundleName,
                description: newBundleDescription,
                copyOf: undefined,
                modelId: undefined
            },
            cases: children.map(child => ({
                name: child.name,
                description: "",
                copyOf: child.id
            }))
        };

        this.copyBundleWithSimCases(input)
            .then(data => {
                const [newBundleId, ...newCaseIds] = data;

                const newChildren = children.map((newChild, idx) => ({
                    id: newCaseIds[idx],
                    pId: newBundleId,
                    isParent: false,
                    name: newChild.name
                }));

                return {
                    id: newBundleId,
                    pId: null,
                    isParent: true,
                    name: newBundleName,
                    children: newChildren
                };
            })
            .then(newNodeData => {

                $("#overlay_newBundle").show();

                const newNode = this.addTreeNode(newNodeData, isCopyPaste);

                // fire notification
                const { MESSAGE_ALERT_FOLDER_ADDED } = this.contentMessageFileJSON;
                this.UI.createAlert("notif", "success", `${MESSAGE_ALERT_FOLDER_ADDED}:<br/>${newNode.name}`);

                $("#box_newBundle").remove();
            })
            .catch(error => {
                this.Everest_callbackError(error);
            })
            .finally(() => $("#overlay_newBundle").hide());
    });

};

Interface_Gestion_SIMU_CLS.prototype.createNewSimulationCase = function(targetNode, node, isCopyPaste = false) {
    // if close the box, return the focus
    const callback = () => node ? this.focusTreeNode(node) : this.focusTreeNode(targetNode);

    //create the UI box
    this.createNewSimulationCaseBox(node, callback, isCopyPaste);

    $("#newSimulationCase_create").click(() => {

        // request input
        const newCaseName = $("#newSimulationCase_name").val();
        const newCaseDescription = $("#newSimulationCase_comments").val();
        const copyOf = node ? node.id : undefined;
        const scenarioId = $("#new_contexts_select").length ? parseInt($("#new_contexts_select option:selected").val()) : undefined;

        // determine what is the target node
        targetNode = targetNode ? targetNode : node.getParentNode();
        const bundleId = targetNode.id;

        // validate the input name
        if (!this.isResourceNameValid(newCaseName, targetNode, "descendants", "create")) {
            // if validation fails, returns the focus back to tree node
            callback();
            return;
        }

        this.createOrCopySimulationCase(newCaseName, newCaseDescription, copyOf, bundleId)
            .then((data) => {
                // if resolved, we'll start updating the tree and we don't wait for context update
                $("#overlay_newSimulationCase").show();

                const newCaseId = data["id"];

                const newNodeData = {
                    id: newCaseId,
                    pId: bundleId,
                    isParent: false,
                    name: newCaseName,
                    children: []
                };

                const newNode = this.addTreeNode(newNodeData, isCopyPaste);

                $("#box_newSimulationCase").remove();

                this.UI.createAlert("notif", "success", `${this.contentMessageFileJSON.MESSAGE_ALERT_SIMU_ADDED}:<br/>${newNode.name}`);

                return newCaseId;

            })
            .then(newCaseId => {
                if (!scenarioId) {
                    return;
                }
                return promisify(this.Everest.Update_Simulation_Case_Info_And_Scenario(
                    targetNode, newCaseId, newCaseName, newCaseDescription, scenarioId
                ));
            })
            .catch(error => this.Everest_callbackError(error))
            .finally(() => $("#overlay_newSimulationCase").hide());
    });
};

Interface_Gestion_SIMU_CLS.prototype.moveSimulationCase = function(node, nodeCopy) {
    this.Everest.Read_Simulation_Case_Info(nodeCopy, function(d, s, n) {
        var param = {
            pid: node.id,
            name: node.name,
            nodeCopy: nodeCopy
        };
        this.Everest.Create_Or_Copy_Simulation_Case(param, nodeCopy.name, (d.description != undefined && d.description != null && d.description != "" ? d.description : ""), (nodeCopy != undefined && nodeCopy != null ? nodeCopy.id : undefined), function(data, status, p) {

            var obj = {
                id: p.pid,
                nextUrl: null
            };
            var d = {
                items: [{
                    id: data.id,
                    name: nodeCopy.name
                }]
            };
            // on enléve addchild sinon on a un doublon quand on fait CUT sur un dossier nouvellement créé, vide et qui n'est pas ouvert quand on fait le PASTE dessus
            //
            if (this.collapseNode == false) { //si ouvert expand
                this.Addchild(d, status, obj);
            }
            this.UI.createAlert("notif", "success", this.contentMessageFileJSON.MESSAGE_ALERT_SIMU_MOVED);

            this.Everest.Delete_Simulation_Case(nodeCopy, function(data, status, node) {
                var parentNode = node.getParentNode();
                this.tree.removeNode(node);
                parentNode.isParent = true;
                this.tree.updateNode(parentNode);
            }.bind(this), this.Everest_callbackError.bind(this));

        }.bind(this), this.Everest_callbackError.bind(this));

    }.bind(this), this.Everest_callbackError.bind(this));

};

Interface_Gestion_SIMU_CLS.prototype.updateBundleInfo = function(selectedBundle, selectedNode) {

    let resourceType = "bundle";

    let oldInput = {
        name: selectedBundle["name"],
        description: selectedBundle["description"]
    };

    let newInput = {
        name: $(`#${resourceType}_name`).val(),
        description: $(`#${resourceType}_description`).val()
    };

    this.updateResourceInfo(resourceType, oldInput, newInput, selectedNode);
};

Interface_Gestion_SIMU_CLS.prototype.updateSimulationCaseInfo = function(selectedCase, selectedNode) {
    let resourceType = "case";

    let oldInput = {
        name: selectedCase["name"],
        description: selectedCase["description"],
        scenarioId: selectedCase["scenarioId"]
    };

    let newInput = {
        name: $(`#${resourceType}_name`).val(),
        description: $(`#${resourceType}_description`).val(),
        scenarioId: parseInt($("#update_contexts_select option:selected").val())
    };

    this.updateResourceInfo(resourceType, oldInput, newInput, selectedNode);
};

/***
 * Update resourceInfo e.g. name, description etc.
 *
 * @param resourceType {String} 'bundle'|'case'
 * @param oldInput {Object} original object before update (at least contains 'name' property)
 * @param newInput {Object} updated object after update (at least contains 'name' property)
 * @param selectedNode {$.fn.zTree.Node} zTreeNode that represents oldInput
 */
Interface_Gestion_SIMU_CLS.prototype.updateResourceInfo = function(resourceType, oldInput, newInput, selectedNode) {

    // if the new resource input doesn't match expected constraints, then exit
    if (!this.validateResourceInfoInput(oldInput, newInput, selectedNode)) {
        return;
    }

    //  if the new resource input pass all validations. update with newInput
    let request;
    let promise;
    let message = "";

    // either bundleId or caseId
    let resourceId = selectedNode["id"];

    // resource type can be either 'bundle' or 'case'
    if (resourceType === "bundle") {
        // update with MA006 (bundle)
        request = this.Everest.Update_Bundle_Info(resourceId, newInput["name"], newInput["description"]);
        promise = promisify(request);
        message = this.contentMessageFileJSON.MESSAGE_ALERT_FOLDER_UPDATED;
    } else if (resourceType === "case") {
        // update with MA012 (simulation case)
        request = this.Everest.Update_Simulation_Case_Info_And_Scenario(
            selectedNode, resourceId, newInput["name"], newInput["description"], newInput["scenarioId"]
        );
        promise = promisify(request);
        message = this.contentMessageFileJSON.MESSAGE_ALERT_SCENARIO_UPDATED;
    } else {
        return;
    }

    promise.then(() => {
        // update all corresponding trees
        let trees = [
            $.fn.zTree.getZTreeObj("tree"),
            $.fn.zTree.getZTreeObj("treeSimAnaResult"),
            $.fn.zTree.getZTreeObj("treeSimAnaTargeting"),
            $.fn.zTree.getZTreeObj("treeSimAnaRanking")
        ];

        for (let tree of trees) {
            if (tree === undefined || tree === null) continue;

            let foundNode = tree.getNodeByParam("id", resourceId, null);
            // if node is found, update the node with new name
            if (foundNode) {
                foundNode["name"] = newInput["name"];
                tree.updateNode(foundNode);
            }
        }

        $("#box_infoSimulation").remove();

        this.UI.createAlert("notif", "success", message);

    }).catch((error) => {
        this.Everest_callbackError(error);
    });
};

/**
 * Validate the new resource input against old resource input
 * during the create, update or copy a bundle or simulation case.
 *
 * @param oldInput {Object} original object before update (at least contains 'name' property)
 * @param newInput {Object} updated object after update (at least contains 'name' property)
 * @param selectedNode {$.fn.zTree.Node} zTreeNode that represents oldInput
 * @returns {boolean}
 */
Interface_Gestion_SIMU_CLS.prototype.validateResourceInfoInput = function(oldInput, newInput, selectedNode) {
    // new name AFTER update
    let newName = newInput["name"];

    return this.isResourceUpdated(oldInput, newInput) && this.isResourceNameValid(newName, selectedNode, "siblings", "update");
};

Interface_Gestion_SIMU_CLS.prototype.isResourceUpdated = function(oldInput, newInput) {
    // if user doesn't update anything, alert user that nothing is updated;
    if (_.isEqual(oldInput, newInput)) {
        this.UI.createAlert("alert", "warning", `${this.contentMessageFileJSON.MESSAGE_ALERT_NO_MODIFICATION_FOUND} ` +
            `${this.contentMessageFileJSON.MESSAGE_ALERT_NO_UPDATE_APPLIED}`);
        return false;
    } else {
        return true;
    }
};

Interface_Gestion_SIMU_CLS.prototype.isResourceNameValid = function(resourceName, selectedNode, searchAt, userAction) {
    return !this.isResourceNameEmpty(resourceName) &&
        !this.isResourceNameWrong(resourceName) &&
        !this.isResourceNameExisted(resourceName, selectedNode, searchAt, userAction);
};

Interface_Gestion_SIMU_CLS.prototype.isResourceNameEmpty = function(resourceName) {
    // if resource name is empty, alert the user that 'name' is empty;
    if (resourceName === "") {
        this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_EMPTY_NAME);
        return true;
    }
    return false;
};

Interface_Gestion_SIMU_CLS.prototype.isResourceNameWrong = function(resourceName) {
    let regex = new RegExp(this.UI.classBindData.contentRegExLabel);

    // if resource name does not match regex, alert the user 'name' is wrong;
    if (!regex.test(resourceName)) {
        this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_FOLDER_WRONG_NAME);
        return true;
    }

    return false;
};

Interface_Gestion_SIMU_CLS.prototype.isResourceNameExisted = function(resourceName, selectedNode, searchAt, userAction) {
    // check the if the resource name is already existed
    let ret, flag;

    // define how we search resource name on the tree
    if (searchAt === "siblings") {
        ret = this.isResourceNameExistedInSiblings(resourceName, selectedNode);
    } else if (searchAt === "descendants") {
        ret = this.isResourceNameExistedInDescendants(resourceName, selectedNode);
    } else {
        throw new SyntaxError("searchAt must be either siblings or descendants");
    }

    //  define checking strategies depends on what the user action is
    if (userAction === "update") {
        // if user is updating resource, we don't check if its current name is existed in the tree
        flag = selectedNode["name"] !== resourceName && ret["isExisted"];
    } else if (userAction === "create") {
        // if user is creating new resource, we only check if the new resource name is existed
        flag = ret["isExisted"];
    } else {
        throw new SyntaxError("searchAt must be either siblings or descendants");
    }

    if (flag) {
        this.UI.createAlert("alert", "warning", ret["message"] + this.contentMessageFileJSON.MESSAGE_ALERT_CHANGE_NAME);
        return true;
    }

    return false;
};

Interface_Gestion_SIMU_CLS.prototype.isResourceNameExistedInSiblings = function(resourceName, selectedNode) {
    let parentNode = selectedNode ? selectedNode.getParentNode() : undefined;

    // top level node has no parent
    if (!parentNode) {
        // search on top level siblings
        let isExisted = this.tree.getNodes().some((node) => resourceName === node["name"]);

        let message = `${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_NEWFOLDER_NAME_CONTENT} ` +
            `'${resourceName}'` + ` ${this.contentMessageFileJSON.MESSAGE_ALERT_ALREADY_EXISTED} `;

        return {
            isExisted: isExisted,
            message: isExisted ? message : ""
        };

    }

    return this.isResourceNameExistedInDescendants(resourceName, parentNode);
};

Interface_Gestion_SIMU_CLS.prototype.isResourceNameExistedInDescendants = function(resourceName, selectedNode) {

    let isExisted = selectedNode.children && selectedNode.children.some((node) => resourceName === node["name"]);

    let message = `${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_NEW_NAME_CONTENT} ` +
        `'${resourceName}'` + ` ${this.contentMessageFileJSON.MESSAGE_ALERT_ALREADY_EXISTED} ('${selectedNode["name"]}'). `;

    return {
        isExisted: isExisted,
        message: isExisted ? message : ""
    };
};

Interface_Gestion_SIMU_CLS.prototype.initTree = function(data, status, id) {
    for (var i = 0; i < data.items.length; i++) {
        //another get function
        //Everest.List_Bundles_Info(data.items[i].id, data.items[i].name);
        var obj = {
            id: data.items[i].id,
            pId: 0,
            name: data.items[i].name,
            open: false,
            isParent: true
        };
        this.zNodes.push(obj);
    }

    if (data.nextUrl != null) {
        this.Everest.List_Bundles_Next_Url(data.nextUrl, this.initTree.bind(this), this.Everest_callbackError.bind(this));
    } else {
        this.tree = $("#tree");
        var settings = {
            view: {
                dblClickExpand: true,
                showLine: true,
                selectedMulti: false
            },
            data: {
                simpleData: {
                    enable: true,
                    idKey: "id",
                    pIdKey: "pId",
                    rootPId: ""
                }
            },
            callback: {
                onExpand: this.OnExpandDir.bind(this),
                onCollapse: this.OnCollapse.bind(this),
                onClick: this.OnClick.bind(this),
                onRightClick: this.OnRightClick.bind(this),
                onDblClick: this.OnDblClick.bind(this),
                onCopy: this.onKeyboardCopy.bind(this),
                onPaste: this.onKeyboardPaste.bind(this),
                onDelete: this.onKeyboardDelete.bind(this),
                onCancel: this.onKeyboardCancel.bind(this)
            },
            keyboard: {
                enable: true
            }
        };

        this.tree = $.fn.zTree.init(this.tree, settings, this.zNodes);
        this.isInit = true;

        $("#box_simulation .overlay").hide();

        // during init : simulate click on expand on each parent nodes in simu ztree
        // to avoid pb icons disappearance when user add a simu in a bundle which had never been opened
        var nodes = this.tree.getNodes();
        this.Everest.cptRunIsnotFromInit = 0;
        this.Everest.boolRunIsnotFromInit = false;
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i)) {
                var evt = document.createEvent("MouseEvents");
                evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                document.getElementById(nodes[i].tId + "_switch").dispatchEvent(evt);

            }
        }

        // register click event to toggle tree status
        $("#mainpanel").on("click", this.toggleTreeActiveStatus);
    }
};

/**
 * Facility function to create the data structure to reuse AddChild in the class
 *
 * AddChild in this class is not just add child, also add parent...
 * AddChild MUST be executed in order, which means add parent first then you can add children
 *
 * Notice, node is not zTree's node here, but it has similar data structure
 * see flowing type
 *
 * @typedef node  {
 *   id:number|string,
 *   pId?:number|string,
 *   isParent:boolean
 *   name:string,
 *   children?:Array<node>
 * }
 *
 * @typedef obj {
 *   id:null|number|string
 *   nexUrl:null
 *   forceIsParent?boolean|null|undefined
 * }
 *
 * @typedef data {
 *   items:Array<{id:number|string,name:string>
 * }
 *
 * @typedef addChildInput {
 *   obj:obj
 *   data:data
 * }
 * @param node {node}
 * @param isDataSingle {boolean} isDataSingle: flag to determine if data items is multiple
 * @returns {{data: {items: Array}, obj: {forceIsParent: undefined, id: null, nextUrl: null}}}
 */
Interface_Gestion_SIMU_CLS.prototype.getAddChildInput = function(node, isDataSingle = true) {
    // obj represent parent node info
    const obj = {
        id: null,
        nextUrl: null,
        forceIsParent: undefined
    };

    // data represent children nodes info
    const d = {
        items: []
    };

    // obj has different form depends on whether node is child or parent
    if (node.isParent) {
        obj.forceIsParent = true;
    } else {
        obj.id = node.pId;
    }

    // data.items can be multiple
    if (isDataSingle) {
        // data.items => either single parent or single child
        d.items.push({ id: node.id, name: node.name });
    } else {
        // data.items => children nodes of one single parent
        obj.forceIsParent = undefined;
        obj.id = node.id;
        const items = node.children.map(child => ({ id: child.id, name: child.name }));
        d.items.push(...items);
    }

    return {
        obj: obj,
        data: d
    };
};

Interface_Gestion_SIMU_CLS.prototype.addTreeNode = function(newNodeData, isCopyPaste) {
    const { data: singleData, obj: singleObj } = this.getAddChildInput(
        newNodeData,
        true
    );
    // now we are adding either a new parent node or a child node
    const newNodes = this.Addchild(singleData, "success", singleObj);

    // workaround to bypass OnExpandDir
    newNodes.forEach(n => n.isParent ? n.isLoadedAsync = true : undefined);

    // we only handle single new node
    const newNode = newNodes[0];

    if (newNode.isParent) {
        // get back the event listener for the new parent node
        this.addDiyDom(this.tree.setting.treeId, newNode);
        $(`#diyLoading_${newNode.id}`).remove();
    }

    // must expand the new node's parent for adding jquery stuff
    const nodeToExpand = newNode.isParent ? newNode : newNode.getParentNode();
    this.tree.expandNode(nodeToExpand, true, true, true, false);

    // now we are adding children nodes of original parent to new parent
    if (newNodeData.children.length) {
        const { data: multiData, obj: singleObj } = this.getAddChildInput(
            newNodeData,
            false
        );

        // add children nodes to new parent
        this.Addchild(multiData, "success", singleObj);
    }

    if (isCopyPaste) {
        // remove original marked node
        this.tree.cancelMarkedNodes();
        // mark new created nodes
        this.tree.markNodes(newNodes);
    } else {
        // if not triggered by copy & paste, select the new parent node
        this.tree.selectNode(newNode);
    }

    // give back to focus to new node
    this.focusTreeNode(newNode);

    return newNodes[0];
};

Interface_Gestion_SIMU_CLS.prototype.focusTreeNode = function(node) {
    // get focus on node
    node ? $(`#${node.tId}`).focus() : undefined;
    $("#tree").addClass("active");
};

Interface_Gestion_SIMU_CLS.prototype.onKeyboardCopy = function(event, treeId, nodes) {
    if (!nodes.length) return;

    // copy is on
    this.isCopyOrCut = "copy";

    if (!nodes[0].isParent) {
        this.toggleCopyPasteButtons(true);
    } else {
        this.toggleCopyPasteButtons(false);
    }
};

Interface_Gestion_SIMU_CLS.prototype.onKeyboardPaste = function(event, treeId, targetNodes, nodes) {
    // if copy is off, return immediately
    if (this.isCopyOrCut !== "copy") return;

    const targetArray = !targetNodes.length ? [undefined] : targetNodes;

    // only work for single selection tree
    if (!nodes[0].isParent) {
        this.createNewSimulationCase(targetArray[0], nodes[0], true);
    } else {
        this.createNewBundle(nodes[0], true);
    }
};

Interface_Gestion_SIMU_CLS.prototype.onKeyboardDelete = function(event, treeId, nodes) {
    // handle user confirmation
    const confirmCallback = node => {
        let promise;
        let parentNode = undefined;
        // POST data for pythlet service
        let inp = [];

        // resolved handler when a node is deleted successfully
        const resolved = () => {
            parentNode = !node.isParent ? node.getParentNode() : undefined;

            // collect case ids before the node is removed (fire event for PRODEC)
            const caseIds = !node.isParent ? [node.id] : node.children ? node.children.map(n => n.id) : [];

            this.tree.removeNode(node);
            this.tree.cancelMarkedNodes(node);

            // fire the notification to tell user that resource is deleted
            const { MESSAGE_ALERT_FOLDER_DEL, MESSAGE_ALERT_SIMU_DEL } = this.contentMessageFileJSON;

            const alertMsg = node.isParent
                ? MESSAGE_ALERT_FOLDER_DEL
                : MESSAGE_ALERT_SIMU_DEL;

            this.UI.createAlert("notif", "success", `${alertMsg}:<br/>${node.name}`);

            // In zTree, when the last child node is removed from parent node,
            // zTree will mark the parent node as non parent
            if (parentNode && !parentNode.children.length) {
                // force itS parentNode back to parent
                parentNode.isParent = true;
                this.tree.updateNode(parentNode);
            }

            // in case that user delete a node in copy mode, forcefully back to off mode
            this.toggleCopyPasteButtons(false);

            // update all corresponding results trees
            const trees = [
                $.fn.zTree.getZTreeObj("treeSimAnaResult"),
                $.fn.zTree.getZTreeObj("treeSimAnaTargeting"),
                $.fn.zTree.getZTreeObj("treeSimAnaRanking")
            ];

            for (let tree of trees) {
                if (tree === undefined || tree === null) continue;

                let foundNode = tree.getNodeByParam("id", node.id, null);
                // if node is found, remove the node
                if (foundNode) {
                    tree.removeNode(foundNode);
                }

                // check if it is last child node
                if (!foundNode.isParent) {
                    const foundParent = foundNode.getParentNode();
                    // if parent node is undefined, return
                    if (!foundParent) return;

                    // check if the found node is the last child
                    // if it is last node, remove the parent node as well
                    const isLastChild = foundParent.children && !foundParent.children.length;
                    isLastChild ? tree.removeNode(foundParent) : undefined;
                }
            }

            // fire event (for PRODEC)
            this.fireCaseDeleteEvent(caseIds);
        };

        // rejected handler when deleting ResultsLayer fails
        const rejected = (error) => {
            const err = {
                responseJSON: {
                    error: error.responseJSON.status,
                    errorMessage: "Pythlet Service Error: " + error.responseJSON.statusText
                }
            };
            this.Everest_callbackError(err);
        };

        if (node.isParent) {
            // delete a parent node with keyboard
            const children = node.children ? node.children : [];

            inp = children.map(n => ({
                studyArea: this.Everest.selectedStudyAreaName,
                caseId: n.id
            }));

            if (!inp.length) {
                // if bundle is empty, we don't delete ResultsLayer
                promisify(this.Everest.Delete_Bundle(node))
                    .then(() => resolved())
                    .catch(error => this.Everest_callbackError(error));
            } else {
                // if bundle is non empty, we delete ResultsLayer firstly
                promise = promisify(this.Everest.Delete_GeoServerLayer(inp));
                promise.then(() => {
                    promisify(this.Everest.Delete_Bundle(node))
                        .then(() => resolved())
                        .catch(error => this.Everest_callbackError(error));
                }).catch(error => rejected(error));
            }
        } else {
            // delete a child node with keyboard
            inp = [
                {
                    studyArea: this.Everest.selectedStudyAreaName,
                    caseId: node.id
                }
            ];

            promise = promisify(this.Everest.Delete_GeoServerLayer(inp));
            promise.then(() => {
                promisify(this.Everest.Delete_Simulation_Case(node))
                    .then(() => resolved()).catch(error => this.Everest_callbackError(error));
            }).catch(error => rejected(error));
        }
    };

    // handle user cancel
    const cancelCallback = node => {
        // if it is in select mode, we cancel marked nodes behind the scene
        if (this.tree.isSelectedNode(node)) {
            this.tree.cancelMarkedNodes(node);
        }
        // back to focus for further deletion
        this.focusTreeNode(node);
    };

    // only works when 'selectedMulti' is false, otherwise, need to figure out relation between nodes
    nodes.forEach(node => {
        if (this.isAnyRunningSimCase(node)) {
            // fire alert
            const {
                MESSAGE_ALERT_ERROR_DEL_FOLDER_WITH_SIMRUN,
                MESSAGE_ALERT_ERROR_DEL_SIMRUN
            } = this.contentMessageFileJSON;

            const alertMsg = node.isParent
                ? MESSAGE_ALERT_ERROR_DEL_FOLDER_WITH_SIMRUN
                : MESSAGE_ALERT_ERROR_DEL_SIMRUN;

            this.UI.createAlert("alert", "error", alertMsg);

            this.tree.cancelMarkedNodes();
        } else {
            // fire confirmation box for user choice
            const { MENU_LABEL_MESSAGE_DEL_FOL, MENU_LABEL_MESSAGE_DEL_SIM } = this.contentFileJSON;

            const confirmMsg = node.isParent
                ? MENU_LABEL_MESSAGE_DEL_FOL
                : MENU_LABEL_MESSAGE_DEL_SIM;

            this.UI.createConfirmBox(
                `${confirmMsg} ${node.name}`,
                confirmCallback.bind(this, node),
                cancelCallback.bind(this, node)
            );
        }
    });
    return false;
};

Interface_Gestion_SIMU_CLS.prototype.onKeyboardCancel = function() {
    // copy is off
    this.isCopyOrCut = undefined;
    this.toggleCopyPasteButtons(false);
};

/**
 *  Toggle the copy & paste buttons combo in context menu
 *  true:  copy is on, paste is enabled (copy mode)
 *  false: copy is off, paste is disabled (default)
 *
 * @param flag {boolean}
 */
Interface_Gestion_SIMU_CLS.prototype.toggleCopyPasteButtons = function(flag = false) {
    this.toggleCopyButton(flag);
    this.togglePasteButton(flag);
};

/**
 *  Toggle the cut & paste buttons combo in context menu
 *  true:  cut is on, paste is enabled (copy mode)
 *  false: cut is off, paste is disabled (default)
 *
 * @param flag {boolean}
 */
Interface_Gestion_SIMU_CLS.prototype.toggleCutPasteButtons = function(flag = false) {
    this.toggleCutButton(flag);
    this.togglePasteButton(flag);
};

/**
 * Toggle copy and cut buttons combo in context menu for simulation case.
 *
 * The current right clicked node can only be one of following status
 *
 * 1. neither in 'copy' nor 'cut'
 * 2. in copy
 * 3. in cut
 *
 * @param node
 */
Interface_Gestion_SIMU_CLS.prototype.toggleCopyCutButtons = function(node) {
    // used only by child node (sim case)
    if (!node || node.isParent) return;

    const markedNodes = this.tree.getMarkedNodes();

    // current right clicked node is unmarked
    if (!markedNodes.includes(node)) {
        this.toggleCopyButton(false);
        this.toggleCutButton(false);
        return;
    }

    if (this.isCopyOrCut === "copy") {
        // copy is on, cut is off
        this.toggleCopyButton(true);
        this.toggleCutButton(false);
    } else {
        // cut is on, copy is off
        this.toggleCutButton(true);
        this.toggleCopyButton(false);
    }
};

Interface_Gestion_SIMU_CLS.prototype.togglePasteButton = function(flag) {
    if (flag) {
        // enable 'paste' in context menu
        $("#m_paste").removeClass("disabled");
    } else {
        // disable 'paste' in context menu
        $("#m_paste").addClass("disabled");
    }
};

Interface_Gestion_SIMU_CLS.prototype.toggleCopyButton = function(flag) {
    if (flag) {
        // copy is on, user should see 'cancel copy' in context menu
        $("#m_dupl").removeClass("btn-primary");
        $("#m_dupl").addClass("btn-danger");
        $("#m_dupl").html(`<i class="fa fa-copy"></i> ${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_CANCEL_COPY}`);
    } else {
        // copy is off, user should see 'copy' in context menu
        $("#m_dupl").addClass("btn-primary");
        $("#m_dupl").removeClass("btn-danger");
        $("#m_dupl").html(`<i class="fa fa-copy"></i> ${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_COPY}`);
    }
};

Interface_Gestion_SIMU_CLS.prototype.toggleCutButton = function(flag) {
    if (flag) {
        // cut is on, user should see 'cancel cut' in context menu
        $("#m_cut").removeClass("btn-primary");
        $("#m_cut").addClass("btn-danger");
        $("#m_cut").html(`<i class="fa fa-cut"></i> ${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_CANCEL_CUT}`);
    } else {
        // cut is off, user should see 'cut' in context menu
        $("#m_cut").addClass("btn-primary");
        $("#m_cut").removeClass("btn-danger");
        $("#m_cut").html(`<i class="fa fa-cut"></i> ${this.contentFileJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_CUT}`);
    }
};

/**
 * Toggle tree status 'active', it is purely for visual cue to notify user if they have focus
 * on the zTree or not
 *
 * @param evt
 */
Interface_Gestion_SIMU_CLS.prototype.toggleTreeActiveStatus = function(evt) {
    // if not visible, return immediately
    if (!$("#tree").is(":visible")) {
        return;
    }

    const activeElem = $(document.activeElement);
    const parentElem = activeElem.parent()[0];

    // if active in the tree itself
    if (parentElem.id.match(/^tree(_\d+_ul)?$/)) {
        const root = $(`#${parentElem.id}`).closest(".ztree")[0];
        root.id === "tree" ? $(`#${root.id}`).addClass("active") : undefined;
        return;
    }

    // following is the id/class keywords of white list that needs to mark tree active
    const whitelist = [
        "newSimulationCase",
        "newBundle",
        "new_contexts_select",
        "fa fa-copy"
    ];

    // if active in the popup box of create/copy simulation case or bundle
    if (whitelist.find(item => evt.target.id.includes(item) || evt.target.className.includes(item))) {
        $("#tree").addClass("active");
        return;
    }

    const contextMenuItem = $(evt.target).closest("a");
    // if active in the context menu
    if (contextMenuItem.length) {
        contextMenuItem[0].id.includes("m_") ? $("#tree").addClass("active") : undefined;
        return;
    }

    $("#tree").removeClass("active");
};

/**
 * Check if there is running simulation case
 * When node represents bundle, check if there is any running simulation case inside that bundle
 * When node represents simulation case, just check itself is running
 *
 * @param node
 * @returns {boolean|*}
 */
Interface_Gestion_SIMU_CLS.prototype.isAnyRunningSimCase = function(node) {
    if (!node) return false;

    if (node.isParent) {
        // empty parent node without children
        if (!node.children) {
            return false;
        }
        // extract all children nodes id
        const childNodeIds = node.children.map(child => child.id);

        return childNodeIds.some(childId =>
            this.TaskManager.simRunning.includes(childId)
        );
    } else {
        return this.TaskManager.simRunning.includes(node.id);
    }
};

Interface_Gestion_SIMU_CLS.prototype.OnCollapse = function() {
    this.collapseNode = true;
};

Interface_Gestion_SIMU_CLS.prototype.OnExpandDir = function(event, treeId, treeNode) {
    if (treeNode.isLoadedAsync != true) {
        //if (this.parentExpand == treeNode.id) {
        //this.tree.removeChildNodes(treeNode);
        $("#" + treeNode.tId + "_ul").empty();
        //}

        treeNode.isLoadedAsync = true;
        this.addDiyDom(treeId, treeNode);
        this.Everest.List_Simulation_Cases_By_Bundle(treeNode, this.Addchild.bind(this), this.Everest_callbackError.bind(this));
    } else {
        //   this.tree.removeChildNodes(treeNode);
        //  this.Everest.List_Simulation_Cases_By_Bundle(treeNode, this.Addchild.bind(this), this.Everest_callbackError.bind(this));
    }
    this.collapseNode = false;
};

Interface_Gestion_SIMU_CLS.prototype.OnRightClick = function(event, treeId, treeNode) {
    // remove the marked nodes
    // this.tree.cancelMarkedNodes();

    // make sure the tree is active when right click;
    $("#tree").addClass("active");

    if (!treeNode && event.target.tagName.toLowerCase() != "button" && $(event.target).parents("a").length == 0) {
        this.tree.cancelSelectedNode();
        this.OnClick(event, treeId, treeNode, 0);
    } else if (treeNode && !treeNode.noR) {
        this.tree.selectNode(treeNode);
        this.OnClick(event, treeId, treeNode, 0);
        // make sure every time, user can be in either copy or cut
        this.toggleCopyCutButtons(treeNode);
        this.showRMenu("node", event.clientX, event.clientY);
    }
};

Interface_Gestion_SIMU_CLS.prototype.OnDblClick = function(event, treeId, treeNode) {
    this.tree.selectNode(treeNode);
    if (this.Everest.selectedCaseId != undefined && this.Everest.selectedCaseId != null) {
        this.defineScenario(treeNode);
    }
};

Interface_Gestion_SIMU_CLS.prototype.showRMenu = function(type, x, y) {

    if (this.Everest.selectedCaseId != undefined && this.Everest.selectedCaseId != null) {
        $(".zts_leaf").show();
        $(".zts_dir").hide();
    } else {
        $(".zts_leaf").hide();
        $(".zts_dir").show();
    }
    $("#rMenu").stop().css({
        "top": y + "px",
        "left": x + "px"
    }).show("fold");
    $("body").bind("mousedown", this.onBodyMouseDown.bind(this));
};

Interface_Gestion_SIMU_CLS.prototype.onBodyMouseDown = function(event) {
    if (!(event.target.id === "rMenu" || $(event.target).parents("#rMenu").length > 0)) {
        $("#rMenu").hide();
        $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
    }
};

Interface_Gestion_SIMU_CLS.prototype.OnClick = function(event, treeId, treeNode, clickFlag) {
    if (treeNode && treeNode.isHover && !treeNode.isParent) {

        this.Everest.selectedCaseId = treeNode.id;
        $("#nom_simu").text(treeNode.name);

    } else {
        this.Everest.selectedCaseId = null;
        $("#nom_simu").text("none");
        //$("#ini_noSimu").show();
    }

    // clickFlag always 1 when single click, OnRightClick also trigger here
    // we don't cancel marked nodes if triggered by 'OnRightClick'
    if (!clickFlag) {
        return;
    }

    // cancel marked nodes when single click in keyboard mode
    if (treeNode) {
        // handle child node
        if (!treeNode.isParent) {
            this.tree.cancelMarkedNodes();
            this.toggleCopyPasteButtons(false);
            return;
        }
        // handle parent node
        const markedNodes = this.tree.getMarkedNodes();
        if (markedNodes.length && markedNodes[0].isParent) {
            this.tree.cancelMarkedNodes();
        }
    }
};

Interface_Gestion_SIMU_CLS.prototype.Addchild = function(data, status, obj) {
    var tabNode = [];
    var objRun = {};
    for (var i = 0; i < data.items.length; i++) {
        if (obj.forceIsParent != undefined) {
            tabNode.push({
                id: data.items[i].id,
                pId: obj.id,
                name: data.items[i].name,
                isParent: true
            });
        } else {
            // on ajoute les simulations dans objet stocké dans classe Bind
            // sera utilisé pour lire les attributs pour créer rapport PDF et DOC des ableaux et graphs
            this.UI.classBindData.List_Sim_Selected.push({
                "id": data.items[i].id,
                "name": data.items[i].name,
                "descr": data.items[i].description
            });
            objRun[i] = data.items[i].runStatus;
            tabNode.push({
                id: data.items[i].id,
                pId: obj.id,
                name: data.items[i].name
            });
        }
    }
    var parent = obj.id != null ? this.tree.getNodeByParam("id", obj.id, null) : null;
    var children = obj.id != null ? parent.children : this.tree.getNodes();
    // var newNodes = this.tree.addNodes(parent, tabNode, true);
    //console.log(newNodes);
    //Sort item by name
    var pos = -1;
    var newNodes = [];
    for (var n in tabNode) {
        if (tabNode.hasOwnProperty(n)) {
            for (var t in children) {
                if (children.hasOwnProperty(t)) {
                    if (tabNode[n].name.toString().toLowerCase() <= children[t].name.toString().toLowerCase()) {
                    } else {
                        pos++;
                    }
                }
            }
            //add item at the right position
            var tmp = this.tree.addNodes(parent, pos + 1, tabNode[n], true);
            //console.log(tmp);
            newNodes.push(tmp[0]);
            pos = -1;
        }
    }
    //console.log("-------------------------------------");

    //Rajout des bt Run/Dir
    for (var i in newNodes) {
        if (newNodes.hasOwnProperty(i) && $.isFunction(newNodes[i]) == false) {
            if (obj.forceIsParent != undefined) {
                //newNodes[i].isParent = true;
                //this.addDiyDom(i, newNodes[i]);
            } else {
                if ($("#diyBtn_" + newNodes[i].id).length > 0) {
                } else {
                    // if it is first node, it won't be inserted in DOM until its parent expands
                    if (newNodes[i].isFirstNode) {
                        // force expand
                        this.tree.expandNode(newNodes[i].getParentNode(), true, true);
                    }

                    let aObj = $("#" + newNodes[i].tId + "_a");
                    let editStr = "";
                    if (objRun[i] === "INPROGRESS" || objRun[i] === "PENDINGCONFIGURATION") {
                        this.TaskManager.AddTask(new TaskSimulation_CLS("allTaskMenuOnly", this.Everest, newNodes[i].id, newNodes[i].name, this.runComplete.bind(this), true));
                        this.TaskManager.simRunning.push(newNodes[i].id);
                        editStr = `<span id="diyResult_${newNodes[i].id}"><img src=${successImg} class="ztree-diy-btn" title="Success" alt="Success"/></span>`;
                    } else if (objRun[i] === "FINISHED" || objRun[i] === "FAILED") {
                        this.TaskManager.AddTask(new TaskSimulation_CLS(false, this.Everest, newNodes[i].id, newNodes[i].name, this.runComplete.bind(this), true));
                        editStr = `<span id="diyResult_${newNodes[i].id}"><img src=${successImg} class="ztree-diy-btn" title="Success" alt="Success"/></span>`;
                    } else {
                        editStr = `<span id="diyResult_${newNodes[i].id}"></span>`;
                    }

                    editStr += `<span id="diyStopBtn_${newNodes[i].id}" title="Stop" class="sim-paused-control"><img src=${stopImg} class="ztree-diy-btn"/></span>`;
                    if (objRun[i] === "notStarted" || objRun[i] === "PAUSED" || objRun[i] === undefined)
                        editStr += `<span id="diyBtn_${newNodes[i].id}" title="Run/Resume"><img src=${runImg} class="ztree-diy-btn"/></span>`;

                    editStr += `<span id="diyDownloadBtn_${newNodes[i].id}" class="d-none" title="Download Log"><img src=${downloadImg} alt="download log button" class="ztree-diy-btn"></span>`;

                    //FIXME: knock this all out (JS)
                    aObj.append(editStr);


                    //defines action on button play next to a node
                    $("#diyBtn_" + newNodes[i].id).click({
                        id: newNodes[i].id,
                        name: newNodes[i].name
                    }, function(event) {
                        this.Everest.selectedCaseId = event.data.id;
                        //this.Everest.cptRunIsnotFromInit++;
                        this.Everest.boolRunIsnotFromInit = true;
                        this.TaskManager.AddTask(new TaskSimulation_CLS(true, this.Everest, this.Everest.selectedCaseId, event.data.name, this.runComplete.bind(this)));
                        this.TaskManager.simRunning.push(this.Everest.selectedCaseId);
                        $("#diyStopBtn_" + this.id).hide();
                        $("#diyBtn_" + event.data.id).hide();
                    }.bind(this));

                    $("#diyStopBtn_" + newNodes[i].id)
                        .click({
                            id: newNodes[i].id,
                            name: newNodes[i].name
                        }, function(event) {
                            this.Everest.Stop_Simulation_Case(event.data.id, function() {
                                $("#diyStopBtn_" + newNodes[i].id).hide();
                            });
                        }.bind(this))
                        .toggle(objRun[i] === "PAUSED");

                    // register click listener for download!
                    $(`#diyDownloadBtn_${newNodes[i].id}`).on("click", { simCaseId: newNodes[i].id }, (evt) => {
                        const { simCaseId } = evt.data;

                        let promise = promisify(this.Everest.Read_Run_Status(simCaseId));

                        promise
                            .then(data => promisify(this.Everest.Read_Run_Output_File(data.id, "functional.log")))
                            .then(content => {
                                // load stuff in RAM
                                const filename = "functional.log";
                                const blob = new Blob([content], {
                                    type: "text/plain;charset=utf-8"
                                });

                                // const fileURL = URL.createObjectURL(blob);
                                // window.open(fileURL);
                                saveAs(blob, filename);
                            })
                            .catch(error => this.Everest_callbackError(error));
                    });
                }
            }
        }

    }

    if (data.nextUrl != null) { //On charge la suite
        this.Everest.List_Simulation_Cases_By_Bundle_Next_Url(data.nextUrl, obj, this.Addchild.bind(this), this.Everest_callbackError.bind(this));
    } else { //On a tout chargé
        $("#diyLoading_" + obj.id).remove();
    }

    this.tree.expandNode(obj, false, true, true, false);

    return newNodes;
};

Interface_Gestion_SIMU_CLS.prototype.addDiyDom = function(treeId, treeNode) {
    let aObj = $("#" + treeNode.tId + "_a");
    if (treeNode.level === 0) {
        let editStr = `<span id="diyLoading_${treeNode.id}">&nbsp;<img src=${loadingGIF} /></span>`;
        editStr += `<span id='diyBtn_${treeNode.id}' title='New simulation'><img src=${newImg} class='ztree-diy-btn'/></span>`;
        aObj.append(editStr);

        $("#diyBtn_" + treeNode.id).click(() => {
            this.createNewSimulationCase(treeNode);
        });
    }
};

Interface_Gestion_SIMU_CLS.prototype.Everest_callbackError = function(data, status) {

    var error;
    if (data.responseJSON != undefined) {
        error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
    }

    if (data.responseJSON != undefined)
        this.UI.createMessageError(error, data.responseJSON.errorMessage);
    else if (data.responseText != undefined)
        this.UI.createMessageError("", data.responseText);
    else
        this.UI.createAlert("alert", "error", "ERROR");
};

/**
 * Get all scenarios with its info (combined response of MA004 & SM002b)
 * MA004 lists all scenarios but with incomplete information, e.g. property "published" is not available
 *
 * where
 * <MA004>: list scenarios by modelId
 * <SM002b>: read scenario info by scenarioId
 *
 * @returns {Promise<some>}
 */
Interface_Gestion_SIMU_CLS.prototype.getAllScenarios = function() {

    // TODO move to everest.js as standard API call?
    return promisify(this.Everest.List_Scenarios(undefined)).then(data => {
        // if only first page available, return first page's data
        if (!data["nextUrl"]) {
            return data;
        }

        // recursively get data from next url
        let getDataFromNextUrl = (nextUrl) => {
            return promisify(this.Everest.getNextUrl(nextUrl))
                .then(nextData => {
                    // merge data from first page and data from next url
                    data["items"] = data["items"].concat(nextData["items"]);
                    data["nextUrl"] = nextData["nextUrl"];

                    if (nextData["nextUrl"]) {
                        return getDataFromNextUrl(nextData["nextUrl"]);
                    } else {
                        // resolve final page's data
                        return data;
                    }
                });
        };

        return getDataFromNextUrl(data["nextUrl"]);

    }).then((data) => {
        if (!Array.isArray(data["items"]) || !data["items"].length) {
            return;
        }
        // get each scenario's info
        let promises = data["items"].map((item) => promisify(this.Everest.Read_Scenario_Info(undefined, item["id"])));

        return Promise.all(promises).then((resolved) => {
            // resolved promise order is preserved
            data["items"].forEach((item, idx) => {
                // add "published" prop for each scenario with its info "published"
                item["published"] = resolved[idx]["published"];
            });

            return data;
        });

    });

};

/**
 * Promisify MA005: Create Or Copy Bundle
 *
 * @typedef MA005 = {id:number|string}
 * @param name {string}
 * @param description {string|null|undefined}
 * @param copyOf {number|string}
 * @return {Promise<any|never|MA005>}
 */
Interface_Gestion_SIMU_CLS.prototype.createOrCopyBundle = function(name, description, copyOf) {
    let param = {
        pid: null,
        name: name
    };

    return promisify(this.Everest.Create_Or_Copy_Bundle(param, name, description, copyOf));
};

/**
 * Promisify MA010: Create Or Copy Simulation Case
 *
 * @typedef MA010 {id:number|string}
 * @param name {string}
 * @param description {string|null|undefined}
 * @param copyOf {number|string}
 * @param bundleId {number|string}
 * @return {Promise<any|never|MA010>}
 */
Interface_Gestion_SIMU_CLS.prototype.createOrCopySimulationCase = function(name, description, copyOf, bundleId) {
    let param = {
        pid: bundleId,
        name: name
    };
    return promisify(this.Everest.Create_Or_Copy_Simulation_Case(param, name, description, copyOf));

};

/**
 * Copy a bundle and all its simulation cases in one shot.
 *
 * Combination of MA005 and MA010, since it is not directly supported in Everest
 *
 * No standard data structure (both input & output) for copying a bundle,
 * See following type information
 *
 * @typedef bundle {
 *     name: string,
 *     description: string|undefined|null,
 *     copyOf?: number|string,
 *     modelId?: number|string
 * }
 *
 * @typedef simCase {
 *     name: string,
 *     description: string|undefined|null,
 *     copyOf?: number|string
 * }
 *
 * @typedef input = {
 *     bundle:bundle,
 *     cases: Array<simCase>
 * }
 *
 * @param input {input}
 * @return {Promise<any[] | never>}: [newBundleId, newCaseId1, newCaseId2...]
 */
Interface_Gestion_SIMU_CLS.prototype.copyBundleWithSimCases = function(input) {
    let { bundle, cases } = input;

    // if bundle is not defined
    if (!bundle) return Promise.reject(new Error("No bundle is defined"));

    // filter out undefined or null
    cases = cases.filter(c => c);

    const bundlePromise = this.createOrCopyBundle(
        bundle.name,
        bundle.description,
        bundle.copyOf
    );

    // need to copy a bundle firstly then copy simulation cases inside the bundle
    return bundlePromise.then(({ id: newBundleId }) => {

        const promises = cases.map(simCase =>
            this.createOrCopySimulationCase(
                simCase.name,
                simCase.description,
                simCase.copyOf,
                newBundleId
            )
        );

        return Promise.all(promises).then(newCases => [newBundleId].concat(newCases.map(c => c.id)));
    });
};

/**
 * Fire event "onSimCasesDelete" when a or multiple simulation cases are deleted
 * (For PRODEC)
 *
 * @param caseIds
 */
Interface_Gestion_SIMU_CLS.prototype.fireCaseDeleteEvent = function(caseIds) {
    caseIds = Array.isArray(caseIds) ? caseIds : [caseIds];

    // if no case id exist, return immediately
    if (!caseIds.length) {
        return;
    }

    if (window.eventDispatcher) {
        window.eventDispatcher.dispatch("onSimCasesDelete", caseIds);
    }
};

export default Interface_Gestion_SIMU_CLS;
