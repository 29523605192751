// colors
const COLOR_REFERENCE = "#212121";
const COLOR_CELL_VALUE = "#e65100";
const COLOR_STATS_PERCENT0_TO_PERCENT100 = "#ffe0b2";
const COLOR_STATS_PERCENT25_TO_PERCENT75 = "#ffb74d";
import { AMCHARTS_GLOBAL_CONFIG } from "../globals/amcharts.globals";

const CHART_OPTIONS = {
    type: "serial",
    theme: "light",
    marginRight: 10,
    marginLeft: 10,
    autoMarginOffset: 10,
    mouseWheelZoomEnabled: true,
    dataDateFormat: "YYYY",
    precision: 2,
    startDuration: 0,
    trendLines: [],
    balloon: {
        borderThickness: 1,
        shadowAlpha: 0,
    },
    dataProvider: [],
    graphs: [
        {
            id: "g-p0",
            title: "percentile 0%",
            valueField: "percentile_0",
            lineAlpha: 1,
            fillAlphas: 0, // this must be 0
            lineColor: COLOR_STATS_PERCENT0_TO_PERCENT100,
            // showBalloon: false,
            showBalloon: true,
            balloonText: "<span style='font-size:10px;'>[[title]]: [[value]]</span>",
        },
        {
            id: "g-p100",
            title: "percentile 100%",
            fillAlphas: 1,
            lineColor: COLOR_STATS_PERCENT0_TO_PERCENT100,
            fillColors: COLOR_STATS_PERCENT0_TO_PERCENT100,
            fillToGraph: "g-p0",
            lineAlpha: 1,
            valueField: "percentile_100",
            legendAlpha: 1,
            // showBalloon: false,
            showBalloon: true,
            balloonText: "<span style='font-size:10px;'>[[title]]: [[value]]</span>",
        },
        {
            id: "g-p25",
            title: "percentile 25%",
            valueField: "percentile_25",
            lineAlpha: 1,
            fillAlphas: 0, // this must be 0
            lineColor: COLOR_STATS_PERCENT25_TO_PERCENT75,
            showBalloon: true,
            balloonText: "<span style='font-size:10px;'>[[title]]: [[value]]</span>",
        },
        {
            id: "g-p75",
            title: "percentile 75%",
            fillAlphas: 1,
            lineColor: COLOR_STATS_PERCENT25_TO_PERCENT75,
            fillColors: COLOR_STATS_PERCENT25_TO_PERCENT75,
            fillToGraph: "g-p25",
            lineAlpha: 1,
            valueField: "percentile_75",
            legendAlpha: 1,
            showBalloon: true,
            balloonText: "<span style='font-size:10px;'>[[title]]: [[value]]</span>",
        },
        {
            id: "g-val",
            valueField: "value",
            title: `selected cell`,
            type: "line",
            bullet: "round",
            bulletBorderAlpha: 0.5,
            bulletColor: "#FFFFFF",
            bulletSize: 4,
            hideBulletsCount: 10,
            lineThickness: 2,
            lineColor: COLOR_CELL_VALUE,
            useLineColorForBulletBorder: true,
            balloonText: "<span style='font-size:10px;'>[[title]]: [[value]]</span>",
        },
        {
            id: "g-ref",
            title: `reference`,
            valueField: "reference",
            type: "line",
            bullet: "round",
            bulletBorderAlpha: 0.5,
            bulletColor: "#FFFFFF",
            bulletSize: 4,
            hideBulletsCount: 10,
            lineThickness: 2,
            lineColor: COLOR_REFERENCE,
            useLineColorForBulletBorder: true,
            balloonText: "<span style='font-size:10px;'>[[title]]: [[value]]</span>",
        },
    ],
    valueAxes: [
        {
            id: "v1",
            title: "value",
            axisAlpha: 0,
            position: "left",
        },
    ],
    allLabels: [],
    titles: [
        {
            text: "Main Title",
            size: 18,
        },
        {
            text: "Secondary Title",
            size: 12,
        },
    ],
    chartScrollbar: {
        //oppositeAxis:false
        // offset: 15,
        // scrollbarHeight: 5
        graph: "g-val",
        oppositeAxis: false,
        offset: 30,
        scrollbarHeight: 80,
        backgroundAlpha: 0,
        selectedBackgroundAlpha: 0.1,
        selectedBackgroundColor: "#888888",
        graphFillAlpha: 0,
        graphLineAlpha: 0.5,
        selectedGraphFillAlpha: 0,
        selectedGraphLineAlpha: 1,
        autoGridCount: true,
        color: "#AAAAAA",
    },
    chartCursor: {
        // pan: true,
        // cursorAlpha: 0,
        // valueLineAlpha: 0
        pan: true,
        valueLineEnabled: true,
        valueLineBalloonEnabled: true,
        cursorAlpha: 1,
        cursorColor: "#4527a0",
        // limitToGraph: "g1",
        valueLineAlpha: 0.2,
        valueZoomable: true,
    },
    legend: {
        horizontalGap: 10,
        //maxColumns: 1,
        position: "right",
        useGraphSettings: false,
        markerSize: 15,
        marginTop: 10,
        // combineLegend: true,
        data: [
            { title: "Percentile 0% - 100%", color: COLOR_STATS_PERCENT0_TO_PERCENT100 },
            { title: "Percentile 25% - 75%", color: COLOR_STATS_PERCENT25_TO_PERCENT75 },
            { title: "Selected cell", color: COLOR_CELL_VALUE },
            { title: "Reference", color: COLOR_REFERENCE },
        ],
    },
    categoryField: "year",
    listeners: [
        {
            event: "dataUpdated",
            method: function () {
                $(".mv-chart-loader").removeClass("d-none").addClass("d-block");
            },
        },

        {
            event: "drawn",
            method: function (e) {
                $(".mv-chart-loader").removeClass("d-block").addClass("d-none");
            },
        },
    ],
    ...AMCHARTS_GLOBAL_CONFIG,
};

export const makeChart = (id, config) => {
    const options = _.isFunction(config) ? config({ ...CHART_OPTIONS }) : config;
    const _config = _.merge({}, CHART_OPTIONS, options || {});
    return AmCharts.makeChart(id, _config);
};
