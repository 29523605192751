class ContourSelectionModel {
    constructor() {
        this.layersArray = ko.observableArray([]);
        this.selectContour = this.selectContour.bind(this);
    }

    init(layers) {
        this.layersArray.push(...layers);
    }

    reset() {
        this.layersArray.removeAll();
    }

    selectContour(tw3d, layerObj) {
        const { id, wfsConfig, tw3dLayer } = layerObj;

        // check the checkbox of WFS static layers
        if (!tw3dLayer.IsVisible) {
            tw3dLayer.SetVisibility(true);
            this.checkLayerVisibility(id);
        }

        tw3dLayer.AreaSelect();
    }

    checkLayerVisibility(id) {
        const elem = $(`#mainLayerManager table td[data-layer*="${id}"] img`);
        if (elem.length) {
            elem.attr("src", "images/layers/checked.png");
        }
    }
}

export default ContourSelectionModel;
