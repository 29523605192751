class InfoModalModel {
  constructor(params) {
    this.objectPID = ko.observable(`${params.objIdentifier}`);
    this.objectInfo = ko.observableArray(this.toObjectInfo(params.objAttributes));
  }

  toObjectInfo(data) {
    return Object.keys(data)
      .map(elem => {
        return {
          name: elem,
          value: data[elem],
        };
      })
      .filter(elem => {
        return (!elem.name.startsWith("_")) && (typeof elem.value != "object"); //Keep only 'easy' to display values          
      });
  }
}

export default InfoModalModel;
