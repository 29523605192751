"use strict";

import Cavi2Util from "../helpers/cavi.util.js";
import loadingGIF from "ztree/css/zTreeStyle/img/loading.gif";
import { AMCHARTS_GLOBAL_CONFIG } from "../globals/amcharts.globals";

class AnalysingTargetingUI {
    constructor(UI, controller) {
        this.controller = controller;
        this.UI = UI;
        this.contentFileJSON = this.UI.accessContentJSON;
        this.contentMessageFileJSON = this.UI.accessMessageContentJSON;

        // boolean set true after creating the ztree simcase
        this.boolcreateTreeSim = false;
        this.tree = null;
        this.initTree();

        this.treeKpi = null;

        this.chart = null;

        this.mainSlider;
    }

    initTree(){
        if (this.tree == null){
            this.tree = $.fn.zTree.getZTreeObj("treeSimAnaTargeting");
        }
    }

    //initTreeUO() {
    //    if (this.treeUO == undefined) {
    //        this.treeUO = $.fn.zTree.getZTreeObj("tree_URB");
    //    }
    //}

    //getCheckedNodesUO() {
    //    this.initTreeUO();
    //    return this.treeUO.getCheckedNodes(true);
    //}

    getCheckedNodesTree() {
        this.initTree();
        return this.tree.getCheckedNodes(true);
    }

    getTreeKpi() {
        return this.treeKpi;
    }

    getCheckedNodesKpi() {
        return (this.treeKpi != null && this.treeKpi != undefined) ? this.treeKpi.getCheckedNodes(true) : [];
    }


    /*init*/
    init() {
        //Construit la box de gestion des résultats
        //Rajoute la box
        this.createboxTargeting(function() {return true;});

        $("#bt_analysing_Targeting_spider").click(this.controller.ResultGraph.bind(this.controller));
        $("#bt_fullscreen_analysing_Targeting_spider").on("click", function() {
            if ($("#bt_fullscreen_analysing_Targeting_spider i").hasClass("fa-expand") == true) { //Passe en FullScreen
                $("#bt_fullscreen_analysing_Targeting_spider i").removeClass("fa-expand").addClass("fa-compress");
                $("#move_analysing_Targeting_spider").appendTo("#fullscreen_analysing_Targeting_spider");
                $("#fullscreen_analysing_Targeting_spider").show();
            } else {
                $("#bt_fullscreen_analysing_Targeting_spider i").removeClass("fa-compress").addClass("fa-expand");
                $("#move_analysing_Targeting_spider").appendTo("#screen_analysing_Targeting_spider");
                $("#fullscreen_analysing_Targeting_spider").hide();
            }
        });

        this.initSpiderGraph();

        $("#analysing_Targeting_select").change(()=> {
            //var treeSim = $.fn.zTree.getZTreeObj("treeSimAnaTargeting");
            //var nodesSim = treeSim.getCheckedNodes(true);
            //igo TMA 2
            //if target doesn't have data for UO type
            //  this.currentKpiData = this.Everest.GetOpenDapMetadata(nodesSim[1].id, nodesSim[1].id, this.SetKpi.bind(this));
            // this.updateKpiSelection(this.currentKpiData);
            this.controller.updateKpiSelection(this.controller.getCurrentKpiData());
        });

    }


    createboxTargeting(CallBackClose) {

        $("#box_analysing_Targeting").remove();
        var c = "<div id=\"analysing_Targeting_content\">";
        c += "      <div style=\"position:absolute; top:0px; left:0px; bottom:0px; width:200px; overflow:auto;\">";
        c += "          <h4>" + this.contentFileJSON.MENU_LEFT_SIMULATING_CASE + "</h4>";
        c += "          <div id=\"sim_div_targeting\" style=\"width:100%;\">";
        c += "              <div id=\"sim_analysing_Targeting\" style=\"width:100%;\">"; //Arbre avec les simulations "run success"
        c += "              </div>";
        c += "              <ul id=\"treeSimAnaTargeting\" class=\"ztree\"></ul>";
        c += "          </div>";
        c += "      </div>";

        c += "      <div class=\"use-skin-border\" style=\"position:absolute; top:0px; left:200px; bottom:0px; width:260px; overflow:auto;\">";
        //c += "          <h4>";
        //c += this.contentFileJSON.MENU_TOP_CITY_URBAN;
        //c += "           </h4>";
        c += "          <div id=\"uo_analysing_Targeting\" style=\"width:100%;\"></div>"; //Arbre avec les urban Objects
        c += "      </div>";
        c += "      <div class=\"use-skin-border\" style=\"position:absolute; top:0px; left:465px; bottom:0px; right:0px; overflow:auto;\">";

        c += "          <div style=\"width:100%; height:calc(100% - 15px);\">";
        c += "          <h4>" + this.contentFileJSON.MENU_LEFT_ANALYSING_KPI_EVALUATION_TARGET + "</h4>";
        c += "          <div style=\"text-align:left; \">";
        c += "              <div style=\"margin-top:5px;\">";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_KPI_EVALUATION_TARGET_CHOOSE;
        c += `<select id="analysing_Targeting_select"><option value="-1">None</option></select><span id="analysing_Targeting_select_Loading" >&nbsp;<img src=${loadingGIF} alt="loading gif"/></span>`;
        c += "              </div>";
        c += "              <div style=\"margin-top:5px;\">";
        c += "                  <b>";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_TITLE;
        c += "                  : <input type=\"text\" id=\"analysing_Targeting_filter\" /></b>";
        c += "              </div>";
        c += "              <div style=\"width: 98%; position : absolute; height:calc(100% - 130px); margin-top:5px;\">";
        c += "              <div id=\"kpiEvaluationNoKpi\" class=\"callout use-skin-border kpiEvaluationMesage\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
        c += "                  <p>" + this.contentFileJSON.MENU_LEFT_KPI_SELECTION_NO_KPI_FOR_SEL + "</p>";
        c += "              </div>";
        c += "              <div id=\"kpiEvaluationUOAndScenario\" class=\"callout use-skin-border kpiEvaluationMesage\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
        c += "                  <p>" + this.contentFileJSON.MENU_LEFT_KPI_SELECTION_SELECT_UO_SCENARIO + "</p>";
        c += "              </div>";
        c += "                  <div id=\"kpi_analysing_Targeting\" style=\"width:100%;overflow:auto; height: calc(100% - 319px); min-height: 150px;\">"; //Arbre avec les kpi
        c += "                      <ul id=\"treeAnaTargKpi\" class=\"ztree\"></ul>";
        c += "                  </div>";
        c += "                  <div style=\"width:100%;height:500px;\">";
        c += "                      <div id=\"testBTn\">";
        c += "                      <button class=\"btn btn-primary use-skin classBtnClick\" id=\"bt_analysing_Targeting_spider\" style=\" margin-top:5px; margin-bottom:5px;\">";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RANKING_GRAPH;
        c += "                      </button>";
        c += "                      <div id=\"load_wait_fct\" class=\"overlayBtn\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div>";
        c += "                      </div>";
        c += "                      <div id=\"screen_analysing_Targeting_spider\"  style=\"width:100%; height:100%;\">";
        c += "                          <div id=\"move_analysing_Targeting_spider\" style=\"position : relative;height:100%;\">";
        c += "                              <div style=\"width:95%;height:50px;position:absolute; transform:scaleY(1.3);top:10px;\"><input id=\"analysing_Targeting_slider\" readonly=\"\" value=\"\" style=\"display:none;width:70%;height:50px;\"/>";
        c += "                              </div><div style=\"position:relative;float:right;\"><button id=\"bt_fullscreen_analysing_Targeting_spider\" class=\"btn btn-box-tool\"><i class=\"fa fa-expand fa-2x\" ></i></button></div>";
        c += "                              <hr/><div id=\"analysing_Targeting_spider\" style=\"position: absolute; height:100%; top: 100px; left: 0px; right: 0px; bottom: 10px;padding: 5px;\"></div>"; //Spider graph
        c += "                          </div>";
        c += "                      </div>";
        c += "                  </div>";
        c += "              </div>";
        c += "          </div>";
        c += "          <div id=\"kpi_analysing_Targeting_overlay\" class=\"overlay\">";
        c += "              <i class=\"fa fa-refresh fa-spin\"></i>";
        c += "          </div>";
        c += "      </div></div>";
        c += "      <div id=\"fullscreen_analysing_Targeting_spider\" class=\"overlay\" style=\"background-color:rgba(255,255,255,1); position: absolute; left: 2px; right: 2px; top: 2px; bottom: 2px; padding:3px\"></div>";
        c += "  </div>";


        var content_overlay = " <div class=\"alert use-skin\" style=\"margin:20px; color:white;\">";
        content_overlay += "        <h4>";
        content_overlay += this.contentFileJSON.MENU_LEFT_ANALYSING_KPI_EVALUATION_SIMULATION;
        content_overlay += "        </h4>";
        content_overlay += "        <p>";
        content_overlay += this.contentFileJSON.MENU_LEFT_ANALYSING_KPI_EVALUATION_MESSAGE;
        content_overlay += "        </p>";
        content_overlay += "    </div>";
        this.UI.CreateBox("analysing_Targeting", {
            draggable: true,
            group: "Analysing",
            resizable: {
                minHeight: 500,
                minWidth: 800 /*, handles: 'e, s, w'*/
            }, //...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
            useSkin: true,
            title: this.contentFileJSON.MENU_LEFT_ANALYSING_KPI_EVALUATION_TARGET,
            content: c,
            addOverlay: content_overlay,
            visible: false,
            menuBt: {
                reduceTo: "div_Targeting", //ID vers qui on doit réduire la div
                fullscreen: {
                    css: {
                        position: "absolute",
                        top: "120px",
                        width: "860px",
                        height: "500px",
                        left: "7%",
                        right: "",
                        bottom: ""
                    },
                    fct: function() {

                    }.bind(this)
                } //position / taille de div pour quand on sort du fullscreen
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "120px",
                left: "7%",
                width: "860px",
                height: "500px",
                "min-width": "800px",
                "min-height": "500px",
                "z-index": Zindex + 1
            }
        }, CallBackClose);
        Zindex = Zindex + 1;
    }
    initSlider(timescalesItem){
        if (timescalesItem != null) {
           if (this.mainSlider != undefined) {
                this.mainSlider.destroy();
            }
            $("#analysing_Targeting_slider").ionRangeSlider({
                type: "single",
                values: (timescalesItem.steps != undefined ? timescalesItem.steps : timescalesItem.data),
                grid: true,
                // force_edges: true,
                onFinish: this.controller.Slider_OnChange.bind(this.controller)
            });
            this.mainSlider = $("#analysing_Targeting_slider").data("ionRangeSlider");
        }
    }



    /*
    Init the spider graph result as empty
    */
    initSpiderGraph() {
        import(/* webpackPreload: true */ "../modules/amcharts.module").then(() => {
            //Init chart empty
            this.chart = AmCharts.makeChart("analysing_Targeting_spider", {
                "type": "radar",
                "theme": "none",
                "dataProvider": [],
                "valueAxes": [{
                    "axisTitleOffset": 50,
                    "minimum": 0,
                    "maximum": 150,
                    "axisAlpha": 0.15,
                }],
                "startDuration": 0,
                "graphs": [],
                "categoryField": "id",
                ...AMCHARTS_GLOBAL_CONFIG,
                legend: {
                    position: "bottom",
                    valueText: "[[value]]",
                    valueWidth: 100,
                    valueAlign: "left",
                    equalWidths: true,
                },
            });
        }).catch(error => console.log(error));
    }


    /**
        Fct which add or remove nodes on the simcase ztree on interfaces : ranking, results, targeting
        This is NOT a shared tree, cause events are different for each of these interfaces
        This fct is called when user add, remove, run a simcase
            -> stored in tabEvent and called in runComplete() : interface_gestion_simu.js
    */
    updateSimCaseTree(node, nodeParent, boolIsDelete) {
        var self = this;
        this.initTree();
        //var f = this.tree.getNodesByParam("isHidden", false, null);

        if (this.boolcreateTreeSim == false) {
            var d = $("#sim_analysing_Targeting");
            if (d != null || d != undefined) {
                $("#sim_analysing_Targeting").empty();
                //event onhover on ztree elements
                //alt tag : show the last run date
                function addHoverDom(treeId, treeNode) {
                    var aObj = $("#" + treeNode.tId + "_span");
                    var index = self.controller.getIndexSimuRun(treeNode.id);
                    if (index > -1) { // index=-1 if user hover a bundle node, else it's a simu node
                        aObj.attr('title', self.controller.getIndexSimuRun(index)["date"]);
                    }

                };
                this.tree = $("#treeSimAnaTargeting");
                var settings = {
                    view: {
                        addHoverDom: addHoverDom,
                        dblClickExpand: true,
                        showLine: true,
                        selectedMulti: true
                    },
                    check: {
                        enable: true,
                        chkboxType: {
                            "Y": "ps",
                            "N": "ps"
                        }
                    },
                    data: {
                        simpleData: {
                            enable: true,
                            idKey: "id",
                            pIdKey: "pId",
                            rootPId: ""
                        }
                    },
                    callback: {
                        onCheck: this.onCheck.bind(this),
                        onClick: this.onClick.bind(this)
                    }
                }
                this.tree = $.fn.zTree.init(this.tree, settings, []);
            }
            this.boolcreateTreeSim = true;
        }

        var currentnode = this.tree.getNodeByParam("id", node.id, null);
        if (currentnode == null) {
            if (boolIsDelete == true) {

            } else {

                var p = this.tree.getNodeByParam("id", nodeParent.id, null);
                if (p == null) {
                    var pos=-1;
                    var np = this.tree.getNodes();
                    for(var n in np){
                        if (np.hasOwnProperty(n)){
                            if (nodeParent.name.toString().toLowerCase() <= np[n].name.toString().toLowerCase()){
                            }else{
                                pos++;
                            }
                        }
                    }
                    var newNodes = this.tree.addNodes(null, pos +1, {
                        id: nodeParent.id,
                        pId: null,
                        name: nodeParent.name,
                        isParent: true
                    }, true);
                    p = newNodes[0];

                }

                //Sort item by name
                var pos=-1;
                for(var n in p.children){
                    if (p.children.hasOwnProperty(n)){
                        if (node.name.toString().toLowerCase() <= p.children[n].name.toString().toLowerCase()){
                        }else{
                            pos++;
                        }
                    }
                }
                this.tree.addNodes(p, pos+1, {
                    id: node.id,
                    pId: p.tId,
                    name: node.name,
                    isParent: false
                }, false);
            }

        } else {
            if (boolIsDelete == true) {
                // if user deletes a simu or a bundle :
                this.tree.removeNode(currentnode);
            }
        }

        $("#box_analysing_Targeting .overlay").hide(); //au moins un element, on masque la popup signalant qu'il faut run une simu
    };

    /*
    defines the event onclick nn the simulation ztree
*/
    onClick(event, treeId, treeNode) {
        //if (treeNode && treeNode.isHover == true && treeNode.isParent == false) {
        //    this.tree.checkNode(treeNode, true, true, true);
        //}
        var bool = !treeNode.checked;
        this.tree.checkNode(treeNode, bool, true, true);
    };

    /*
        defines the event oncheck nn the simulation ztree
    */

    onCheck(event, treeId, treeNode) {
        this.controller.updateCheck();
        /*
        var allNodes = [];
        if (treeNode.isParent == true) {
            allNodes = treeNode.children;
        } else {
            allNodes.push(treeNode);
        }
        if (this.treeKpi != null) {
            $.fn.zTree.destroy("treeAnaRankKpi");
        }
        this.treeKpi = null;

        //igo TMA 2
        for (var i = 0; i < allNodes.length; i++) {
            $("#kpi_analysing_Targeting_overlay").show();
            this.controller.getOpenDapMetadata(allNodes[i].id);
        }*/

    }
    resetMessage(){
        $('.kpiEvaluationMesage').hide();
    }
    messageBadSelect(){
        this.resetMessage();
        if (this.treeKpi != null) {
            $.fn.zTree.destroy("treeAnaTargKpi");
            this.treeKpi=null;
        }
        $('#kpiEvaluationUOAndScenario').show();

    }
    messageNoKpi(){
        this.resetMessage();
        $('#kpiEvaluationNoKpi').show();

    }

    /*initSelectTarget*/
    resetATSelect(){
        $("#analysing_Targeting_select").empty();
        $("#analysing_Targeting_select").append('<option value="-1">None</option>');
    }
    fillATSelect(saveData){
        for (var i = 0; i < saveData.items.length; i++) {
            $("#analysing_Targeting_select").append('<option value="' + saveData.items[i].id + '" title="' + saveData.items[i].description + '">' + saveData.items[i].name + '</option>');
        }
        $("#analysing_Targeting_select_Loading").remove();
    }


    /*
    called when user change the selected target
    update the KPI list to check KPI defined by this target with the callbackSuccessTarget_KPIValues() called on success of Read_Target_KPIValues()
    */
    updateKpiSelection() {
        var targetSel = $("#analysing_Targeting_select").val();
        if (targetSel != -1) {
            //when we have target + sim selected , launches the fct to get KPIs data
            //Get target infos
            //Read_Target_KPIValues = function (param, targetId, pid, callbackSuccess, callbackFailed) {

          //igo TMA 2
          var UOselected = this.controller.GestionUrbObj.getCheckedNodesUO();

            if (UOselected[0] != undefined && UOselected[0].id != undefined) {
                this.controller.readTargetKPIValues(targetSel, UOselected[0].id);
            } else {
                // if user doesn't select neighborhood level in the UO tree
                // we show an error message, cause all targets are related to the neighborhood level
                // then we automatically reselect the 'None' option in the list
                // this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_NO_NEIGHBORHOOD_SELECTED);
                //igo TMA 2
                this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_ERROR_UO);

                $('#analysing_Targeting_select option[value="-1"]').prop('selected', true);
                return -1;
            }
        } else {
            //Uncheck all
            var treeKpi = $.fn.zTree.getZTreeObj("treeAnaTargKpi");
            if (treeKpi != undefined) {
                var nodes = treeKpi.getNodes();
                for (var i in nodes) {
                    if (nodes.hasOwnProperty(i)) {
                        treeKpi.setChkDisabled(nodes[i], false, true, true);
                        treeKpi.checkAllNodes(false);

                    }
                }
            }

        }
    }

    /**/
    callbackSuccessTarget_KPIValues(data, status, param) {
        //Check only KPI connected to the selected target
        var treeKpi = $.fn.zTree.getZTreeObj("treeAnaTargKpi");
        var nodes = treeKpi.getNodesByParam("isParent", false, null);
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i)) {
                treeKpi.setChkDisabled(nodes[i], false, true, true);
                treeKpi.checkNode(nodes[i], false, true);

                // change it name returned by Read_Target_KPIValues() request by the displayname
                var it = nodes[i].id.split("/");
                it = it[it.length - 1];
                if (data[it] != undefined) {
                    var id = nodes[i].name;
                    data[id] = data[it];
                    delete data[it];
                    treeKpi.checkNode(nodes[i], true, true);
                }
            }
        }
        var nodes = treeKpi.getNodesByParam("isParent", true, null);
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i)) {
                treeKpi.setChkDisabled(nodes[i], true, false, true);
            }
        }
        return data;
    }

    /*addResult*/
    addResult(node, nodeParent) {
        if (this.tree.getNodeByParam("id", node.id, null) == null) {
            var p = this.tree.getNodeByParam("id", nodeParent.id, null);
            if (p == null) {
                var newNodes = this.tree.addNodes(null, {
                    id: nodeParent.id,
                    pId: null,
                    name: nodeParent.name,
                    isParent: true
                }, true);
                p = newNodes[0];

            }
            this.tree.addNodes(p, {
                id: node.id,
                pId: p.tId,
                name: node.name,
                isParent: false
            }, false);
        }
        $("#box_analysing_Targeting .overlay").hide(); // hide popup overlay ('need at least one sim run')
    }

    /**
     * Fix encoding of strings coming for OpenDAP
     */
    fixOpenDapStrEncoding(str) {
        return decodeURIComponent(escape(str).replace(/%uF7/g, "%").replace(/%C3%90%C2/g,'%D0').replace(/%C3%91%C2/g,'%D1'));
    }

    setVisibilityOverlay(visible) {
        if (visible) {
            $("#kpi_analysing_Targeting_overlay").show();
        } else {
            $("#kpi_analysing_Targeting_overlay").hide();
        }
    }
    /*
    when user select a simulation, it launches the GetOpenDapMetadata() request
    data : result of the GetOpenDapMetadata() req
    then create the ztree of KPIs list
    Then update the selected KPIs (check according to the selected Target )
    */
    setKpi(data) {

        if (this.treeKpi != null) {
            $.fn.zTree.destroy("treeAnaTargKpi");
        }
        var zNodes = [];
        var nTmp = [];

        var pStr;
        var obj;

      var UOselected = this.controller.GestionUrbObj.getCheckedNodesUO();

        if (UOselected.length > 1) {
            this.messageBadSelect();
            //this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_CHECKCORRECT_UO);
            //treeUO.checkAllNodes(false);
            $("#kpi_analysing_Targeting_overlay").hide();
            return -1;

        }else if (UOselected.length == 1) {
            if (data!=undefined && data!=null){
                var IsOneKpi = false;
                for (var i in data.attributes) {
                    if (i.indexOf("/Results/" + UOselected[0].UOtype + "/") == 0) {
                        //parses id and sub the last part corresponding at the technical id
                        var techID = i.substring(i.lastIndexOf("/") + 1, i.length);
                        // stores the display name
                        pStr = this.fixOpenDapStrEncoding(data.attributes[i]["META-Initiative"]);
                        pStr = (pStr == undefined ? Cavi2Util.CONST_DEFAULT_META_INITIATIVE : pStr);//IGO_TMA_3 : https://curtis-integ.edf-labs.net/redmine/issues/2964
                        //Support de METADATA:Visible dans les resultats
                        var isVisible = this.fixOpenDapStrEncoding(data.attributes[i]["META-Visible"]);
                        if (isVisible != undefined && isVisible.toLowerCase() == "no") { isVisible = false; } else { isVisible = true; }

                        if (pStr != undefined && isVisible==true) {
                            IsOneKpi=true;
                            if (nTmp[pStr] == undefined) {
                                obj = {
                                    id: pStr,
                                    pId: 0,
                                    name: pStr,
                                    code:"PARENT",
                                    open: false,
                                    isParent: true
                                };
                                zNodes.push(obj);
                                nTmp[pStr] = obj.id;
                            }
                            obj = {
                                id: i,
                                pId: nTmp[pStr],
                                name: this.fixOpenDapStrEncoding(data.attributes[i]["LABEL"]),
                                code: this.controller.getTimeScale(techID),
                                isParent: false
                            };
                            zNodes.push(obj);
                        }
                    }
                }
                if (IsOneKpi == false) {
                    this.messageNoKpi();
                }
                else {


                    var settings = {
                        view: {
                            dblClickExpand: true,
                            showLine: true,
                            selectedMulti: true
                        },
                        check: {
                            enable: true,
                            chkboxType: {
                                "Y": "ps",
                                "N": "ps"
                            }
                        },
                        data: {
                            simpleData: {
                                enable: true,
                                idKey: "id",
                                pIdKey: "pId",
                                rootPId: ""
                            }
                        }, callback: {
                            onCheck: this.onCheckKpi.bind(this)
                        }
                    }

                    this.treeKpi = $.fn.zTree.init($("#treeAnaTargKpi"), settings, zNodes);

                    $("#analysing_Targeting_filter").keyup(()=> {
                        var value = $.trim($("#analysing_Targeting_filter").val()).toLowerCase();
                        //Recoche tout
                        var nodes = this.treeKpi.getNodesByParam("isHidden", true);
                        this.treeKpi.showNodes(nodes);

                        if (value != "") {
                            var nodeList = this.treeKpi.getNodesByFilter(function filter(node) {
                                return !node.isParent && node.name.toLowerCase().indexOf(value) == -1;
                            });
                            //Hide items.
                            this.treeKpi.hideNodes(nodeList);
                        }

                    });
                    this.controller.updateKpiSelection(data); //Met à jour la sélection
                }
            }else{
                this.messageNoKpi();
            }
        }else{
            this.messageBadSelect();
        }
        $("#kpi_analysing_Targeting_overlay").hide();
    }

    onCheckKpi(event, treeId, treeNode) {
        if (this.treeKpi != undefined) {
            if (treeNode.checked == false) {
                if (this.getCheckedNodesKpi().length == 0) {
                    //On remet tout à l'etat initial
                    //Uncheck all
                    var nodes = this.treeKpi.getNodes();
                    for (var i in nodes) {
                        if (nodes.hasOwnProperty(i)) {
                            this.treeKpi.setChkDisabled(nodes[i], false, true, true);
                        }
                    }
                }
            } else {
                var currentNode = treeNode;
                if (treeNode.isParent == true) {
                    var nodesKpiParent = this.treeKpi.getNodesByFilter(function filter(node) {
                        return !node.isParent && node.checked && node.parentTId != currentNode.tId;
                    });
                    //Gestion à part pour les parent. Par defaut le ztree coche tous les enfants, on decoche ceux qui n'ont pas le bon code
                    //get the firstChild
                    if (nodesKpiParent.length > 0) {
                        currentNode = nodesKpiParent[0];
                    }
                    else if (treeNode.children.length > 0) {
                        currentNode = treeNode.children[0];
                    }
                    for (var i in treeNode.children) {
                        if (treeNode.children.hasOwnProperty(i)) {
                            if (!treeNode.children[i].isParent && treeNode.children[i].code != currentNode.code) {
                                this.treeKpi.checkNode(treeNode.children[i], false, false);
                            }
                        }
                    }
                }
                var nodesKpi = this.treeKpi.getNodesByFilter(function filter(node) {
                    return !node.isParent && node.checked;
                });
                if (nodesKpi.length == 1 || treeNode.isParent == true) {
                    //disabled all which are not of the same code
                    var nodes = this.treeKpi.getNodesByFilter(function filter(node) {
                        return !node.isParent;
                    });
                    for (var i in nodes) {
                        if (nodes.hasOwnProperty(i)) {
                            if (!nodes[i].isParent && nodes[i].code != currentNode.code) {
                                this.treeKpi.setChkDisabled(nodes[i], true, false, true);
                            }
                        }
                    }
                }
            }
        }
    }

    /*ResultGraph*/
    resultGraph(){

        var targetSel = $("#analysing_Targeting_select").val();

        //if user select a target, create spider graphs
        //igo TMA 2
        //if (targetSel != -1) {
        $("#load_wait_fct").show();
        document.getElementById("bt_analysing_Targeting_spider").disabled = true;
        //Reset graph data
        this.chart.dataProvider = [];
        this.chart.validateData();

        var nodes = this.tree.getCheckedNodes(true);
        var nodesKpi = null;
        var treeKpi = $.fn.zTree.getZTreeObj("treeAnaTargKpi");
        if (treeKpi != null) {
            nodesKpi = treeKpi.getNodesByFilter(function filter(node) {
                return !node.isParent && node.checked;
            });
        }
        else
        {
            //igo TMA 2
            // click button without kpi list
            document.getElementById("bt_analysing_Targeting_spider").disabled = false;
            $("#load_wait_fct").hide();
            return -1;

        }
        // if user doesn't check simu
        if (nodes.length == 0 /*|| nodesKpi.length == 0*/ ) {
            this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_SELECT_DATA);
            document.getElementById("bt_analysing_Targeting_spider").disabled = false;
            $("#load_wait_fct").hide();
            return -1;
        }

        if (nodesKpi.length == 0) {
            //igo TMA 2
            // if there is no KPI selected for a target
            // we show a message to the user
            document.getElementById("bt_analysing_Targeting_spider").disabled = false;
            $("#load_wait_fct").hide();
            this.UI.createAlert("alert", "info", this.contentMessageFileJSON.MESSAGE_ALERT_NO_KPI);
            return -1;
        }
        //TMA 2
        //uncheck UO before click button
      var UOselected = this.controller.GestionUrbObj.getCheckedNodesUO();

        if (UOselected.length == 0) {
            document.getElementById("bt_analysing_Targeting_spider").disabled = false;
            $("#load_wait_fct").hide();
            this.UI.createAlert("alert", "info", this.contentMessageFileJSON.MESSAGE_ALERT_CHECK);
            return -1;
        }
        var nbNodesSimu = 0;
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i) && nodes[i].isParent == false) {
                nbNodesSimu++;
            }
        }
        this.controller.setCptTotalPtSpiderGraph(nbNodesSimu * nodesKpi.length);
        return {nodes,nodesKpi};
    }

    addGraph(id, name){
        // add the '%' , all results will display in % at the end of treatment
        if (this.chart.getGraphById(id) == undefined) {
            this.chart.addGraph({
                id: id,
                "balloonText": "[[value]] % ",
                "bullet": "round",
                "valueField": id,
                "title": (name!=undefined?name:id)
            });
        }
        //hide scale values
        this.chart.getGraphById(id).valueAxis.labelsEnabled = false;
    }

    removeGraph(idTarget){
        //remove the Target graph, if user select 'none':
        if (this.chart.graphs.length > 1) {
            this.chart.removeGraph(this.chart.getGraphById(idTarget));
        }
    }

    /*CallBackAddResult*/
    getCheckedNodesTreeKpi(){
        return this.treeKpi.getCheckedNodes(true);
    }
    checkAndAddgraph(params,dataTarget){
        var targetSel = $("#analysing_Targeting_select").val();
        // change value type if user doesn't select a target : display the real value of simu
        // else use the % tag
        if ((dataTarget != undefined && targetSel != -1) || targetSel == -1) {
            this.addGraph(params.id, params.name)
        }
    }
    getTargetSel(){
        return $("#analysing_Targeting_select").val();
    }

    /*updateTabChart*/
    getMainSliderFrom(){return this.mainSlider.result.from; }
    updateTabChart(data,nameTarget){
        this.chart.dataProvider = data;
        this.chart.validateData();

        // delete chart if corresponding of a non-selected sim
        // MUST BE AFTER ADDING ALL GRAPHS FUNCTION
        var nodes = this.tree.getCheckedNodes(true);
        var sim = [];
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i) && nodes[i].isParent == false) {
                sim.push(nodes[i]);
            }
        }
        if (sim.length < this.chart.graphs.length) {
            for (var element in this.chart.graphs) {
                if (this.chart.graphs.hasOwnProperty(element) && this.chart.graphs[element].id != nameTarget) {
                    var ispresent = false;
                    for (var i = 0; ispresent == false && i < sim.length; i++) {
                        if (sim[i].id == this.chart.graphs[element].id) {
                            ispresent = true;
                        }
                    }
                    if (ispresent == false) {
                        this.chart.removeGraph(this.chart.graphs[element]);
                    }
                }
            }
            this.chart.validateData();
        }

        document.getElementById("bt_analysing_Targeting_spider").disabled = false;
        //$("#bt_analysing_Targeting_spider .overlay").hide();
        $("#load_wait_fct").hide();
    }

    /*Everest_callbackError*/
    everestCallbackError(data, status){
        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");
    }

}

export default AnalysingTargetingUI;
