import loadingGIF from "ztree/css/zTreeStyle/img/loading.gif"

var Interface_Gestion_URB_CLS = function (Everest, UI, TW3D) {
  this.Everest = Everest;
  this.UI = UI;
  this.tw3d = TW3D;
  this.twSelected = undefined;

  //context
  this.Cls_Target = null;
  this.Cls_Ranking = null;
  this.Cls_Result = null;

  //stores all KPI display name
  this.save_MetadataLabel = [];

  this.contentFileJSON = this.UI.accessContentJSON;
  this.contentMessageFileJSON = this.UI.accessMessageContentJSON;
  //build and add the box
  this.createboxGestionUO(function () { });
  this.myTestTabUO = [];

  this.objectType = [];
  this.tabUrbObj = [];
  this.tree = $("#tree_URB");
  this.isInit = false;
  this.zNodes = []; //[{ id: 1, pId: 0, name: "[core] Basic Functions", open: false },{id:101, pId:1, name:"Standard JSON Data", file:"core/standardData"}];
  this.ListModelZoom = undefined;
  this.CurrentItemLink = undefined;
  this.CurrentObjLink = undefined;

  //igo TMA 2
  //switch between result interface
  //this.allreadycharge = false;
  this.allreadycharge_AnaRes = false;
  this.allreadycharge_AnaTar = false;
  this.allreadycharge_AnaRank = false;

  this.elementChecked = false;//Still used ?
  // stores selected UO name
  this.nameUO = undefined;//Still used ?

  //Gestion des arbres Others
  this.treeOther = undefined;
  this.tabOther = undefined;
  //Gestion des arbre mini ((5.3,4.3)
  this.tabUoMini = [];

  this.InitUoMini = function (item) {
    if (this.zNodes.length > 0) {
      //Init tree
      item.tree = $("#tree_UOMini_" + item.id);
      var settings = {
        view: {
          dblClickExpand: true,
          showLine: true
        },
        data: {
          simpleData: {
            enable: true,
            idKey: "id",
            pIdKey: "pId",
            rootPId: ""
          }
        },
        callback: {
          onExpand: this.myOnExpand.bind(this),
          onClick: item.onClick
        }
      };

      item.tree = $.fn.zTree.init(item.tree, settings, this.zNodes);
    }
  };

  //Rajoute un arbre uo réduit (sans le dernier niveau) et le synchro avec le principal.
  this.AddUoMini = function (idDiv, idUniqueArbreMini, lvlMax, onClick) {
    var item = {
      idDivParent: idDiv,
      id: idUniqueArbreMini,
      lvlMax: lvlMax != undefined ? lvlMax : 2,
      onClick: onClick
    };
    this.tabUoMini[item.id] = item;
    $("#div_UOMini_" + item.id).remove(); //Suppr si existe déja.
    var c = '<div id="div_UoMini_' + item.id + '" >';
    c += "      <h3>" + this.contentFileJSON.MENU_LABEL_UOMINI + "</h3>";
    c += '      <ul id="tree_UOMini_' + item.id + '" class="ztree"></ul>';
    c += "</div>";
    $("#" + item.idDivParent).append(c);
    this.InitUoMini(item);
  };
};
Interface_Gestion_URB_CLS.prototype.constructor = Interface_Gestion_URB_CLS;

Interface_Gestion_URB_CLS.prototype.getCheckedNodesUO = function () {
  var t = this.getCurrentTree();
  var retour = [];
  if (t != undefined) {
    retour = t.getCheckedNodes(true);
  }
  return retour;
};

Interface_Gestion_URB_CLS.prototype.getCurrentTree = function () {
  var val = $("#urban_objects_select").val();
  var retour = undefined;
  if (val == "UrbanObject") {
    retour = this.tree;
  } else {
    if (this.treeOther != undefined) {
      retour = this.treeOther;
    }
  }
  return retour;
};


Interface_Gestion_URB_CLS.prototype.createboxGestionUO = function (CallBackClose) {

  $("#box_urban_objects").remove();
  var c = "<div id=\"urban_objects_content\" >";
  c += "    <div id=\"urban_objects_default\">";
  c += "      <div id=\"urban_objects_move\">";
  c += "          <div id='div_urban_objects_select'>";
  c += "              <select id=\"urban_objects_select\">";
  c += "                 <option value='UrbanObject'>" + this.contentFileJSON.MENU_TOP_CITY + "</option>";
  c += "              </select>";
  c += "          </div>";
  c += "          <div>";
  c += this.contentFileJSON.MENU_LABEL_FILTER_MODIFIED;
  c += "              <input type=\"checkbox\" id=\"urban_objects_modified\" />";
  c += "          </div>";
  c += "          <div>";
  c += this.contentFileJSON.MENU_FILTER;
  c += "           : <input type=\"text\" id=\"urban_objects_filter\" />";
  c += "          </div>";
  c += "          <div id='div_tree_urb'><ul id=\"tree_URB\" class=\"ztree\"></ul></div>";
  c += "          <div id='div_tree_other' style='display:none'>TreeOther<ul id=\"tree_Other\" class=\"ztree\"></ul></div>";
  c += "          <div id='div_select_no_simulation' class='callout use-skin-border' style='display:none; margin:2px; background-color:rgba(254, 88, 21, 0.1);'><p>Please select a scenario first</p></div>";
  c += "      </div>";
  c += "    </div>";
  c += "    <div class=\"overlay\">";
  c += "        <i class=\"fa fa-refresh fa-spin\"></i>";
  c += "    </div>";
  c += "</div><!-- /.tab-pane -->";

  this.UI.CreateBox("urban_objects", {
    draggable: true,
    group: "Others",
    resizable: {
      minHeight: 500,
      minWidth: 475 /*, handles: 'e, s, w'*/
    }, //...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
    useSkin: true,
    title: this.contentFileJSON.MENU_TOP_CITY_URBAN,
    content: c,
    visible: false,
    menuBt: {
      reduceTo: "div_Urban" //ID vers qui on doit réduire la div
      //fullscreen: { position: "absolute", top:"115px", width:"475px", height:"500px", left: "20%", right: '', bottom: '' },//position / taille de div pour quand on sort du fullscreen
    },
    style: { //Constient tous les styles à appliquer avec en id le nom du style
      position: "absolute",
      top: "115px",
      left: "25%",
      width: "475px",
      height: "500px",
      "min-width": "475px",
      "min-height": "500px",
      "z-index": Zindex + 1
    }
  }, CallBackClose);
  Zindex = Zindex + 1;
  $("#urban_objects_select").change((event, ValueDeLoption)=> {
    if (ValueDeLoption != "") {
      $("#urban_objects_select option[value='" + ValueDeLoption + "']").prop('selected', true);
    }
    this.urban_objects_select_change();
  });
};

Interface_Gestion_URB_CLS.prototype.urban_objects_select_change = function () {
  this.resetTreeOther();
  this.update_tree_others();
  this.testCheck();
};

Interface_Gestion_URB_CLS.prototype.update_tree_others = function(){

  var val = $("#urban_objects_select").val();
  if (val == "UrbanObject") {
    $("#div_tree_urb").show();
    $("#div_tree_other").hide();
    $("#div_select_no_simulation").hide();
    this.resetTreeOther();
  } else {
    $("#div_tree_urb").hide();
    var nodesScenario = [];
    if (this.CurrentObjLink != undefined && this.CurrentObjLink.id != undefined) {
      if (this.Cls_Result != undefined && this.CurrentObjLink.id == "uo_analysing_Result") {
        //Case KPI Selection
        nodesScenario = this.Cls_Result.getCheckedNodesTree();
        //if (this.Cls_Result.getCheckedNodesTree().length > 0) {
        //  isCheckedScenario = true;
        //}
      } else if (this.Cls_Ranking != undefined && this.CurrentObjLink.id == "uo_analysing_Ranking") {
        //Case KPI Ranking
        nodesScenario = this.Cls_Ranking.getCheckedNodesTree();
        //if (this.Cls_Ranking.getCheckedNodesTree().length > 0) {
        //  isCheckedScenario = true;
        //}
      } else if (this.Cls_Target != undefined && this.CurrentObjLink.id == "uo_analysing_Targeting") {
        //Case KPI Ranking
        nodesScenario = this.Cls_Target.getCheckedNodesTree();
        //if (this.Cls_Target.getCheckedNodesTree().length > 0) {
        //  isCheckedScenario = true;
        //}
      }
    }
    if (nodesScenario.length > 0) {
      //On check si il y a eu un changement car la fct est appelée que l'on check coté uo ou coté simu
      var updateUO = true;
      if (this.tabOther!=undefined && nodesScenario.length == this.tabOther.length) {
        updateUO = false;
        for (var i in nodesScenario) {
          if (nodesScenario.hasOwnProperty(i)) {
            if (nodesScenario[i].isParent != true) {
              if (nodesScenario[i].id != nodesScenario[i].id) {
                updateUO = true;//Si un item est != on update l'arbre
              }
            }
          }
        }
      }
      if (updateUO == true) {
        this.resetTreeOther();
        this.tabOther = nodesScenario;
        $("#div_select_no_simulation").hide();
        $("#div_tree_other").show();

        for (var i in nodesScenario) {
          if (nodesScenario.hasOwnProperty(i)) {
            if (nodesScenario[i].isParent != true) {
              this.Everest.getOpenDap(nodesScenario[i].id, ".dods?PID-" + val, val, this.addItemOther.bind(this), this.addItemOtherError.bind(this));
            }
          }
        }
      }
    } else {
      this.resetTreeOther();
      $("#div_select_no_simulation").show();
      $("#div_tree_other").hide();

    }
  }
}

Interface_Gestion_URB_CLS.prototype.resetTreeOther = function () {
  if (this.treeOther != undefined) {
    $.fn.zTree.destroy("tree_Other");
  }
  this.treeOther = undefined;
  this.tabOther = undefined;
};
Interface_Gestion_URB_CLS.prototype.initTreeOther = function (data){
  if (this.treeOther != undefined) {
    $.fn.zTree.destroy("tree_Other");
  }
  var settings = {
    view: {
      dblClickExpand: true,
      showLine: true
    },
    check: {
      enable: true,
      chkboxType: {
        Y: "",
        N: ""
      }
    },
    data: {
      simpleData: {
        enable: true,
        idKey: "id",
        pIdKey: "pId",
        rootPId: ""
      }
    },
    callback: {
      onClick: this.onClickOther.bind(this),
      onCheck: this.onCheck.bind(this)
    }
  };
  this.treeOther = $.fn.zTree.init($("#tree_Other"), settings, data);
  //[{ id: 1, pId: 0, name: "[core] Basic Functions", open: false }, { id: 101, pId: 1, name: "Standard JSON Data", file: "core/standardData" }]);//IGO
};

Interface_Gestion_URB_CLS.prototype.onClickOther = function (event, treeId, treeNode, isRightClick) {
    this.OnClick(treeNode);
};

Interface_Gestion_URB_CLS.prototype.addItemOther = function (data) {
  var newNodes = [];
  var val = $("#urban_objects_select").val();
  if (data != undefined && data["PID-" + val] != undefined && data["PID-" + val].data != undefined) {
    var urlIco = "";
    for (var it in this.ListModelZoom) {
      var item = this.ListModelZoom[it];
      if (item.name != undefined && item.name == val) {
        if (item.metadatas != undefined) {
          for (var m in item.metadatas) {
            if (item.metadatas[m].name != undefined && item.metadatas[m].name == "IconPath") {
              urlIco = item.metadatas[m].value;
            }
          }
        }
      }
    }
    var d = data["PID-" + val].data;
    for (var i in d) {
      if (d.hasOwnProperty(i)) {
        if (d[i] != undefined && d[i] != "") {
          var n = this.fixOpenDapStrEncoding(d[i]);
          var obj = { id: n, pid: 0, name: n, UOtype: val, pName: val };
          if (urlIco != "") {
            obj.icon = urlIco;
          }
          newNodes.push(obj);
        }
      }
    }
  }
  if (this.treeOther == undefined) {
    this.initTreeOther(newNodes);
  } else {
    this.treeOther.addNodes(null, newNodes);
  }
  this.testCheck();
};
Interface_Gestion_URB_CLS.prototype.addItemOtherError = function (data, status) {
  console.log("Error : addItemOtherError");
  console.log(data);
  console.log(status);
  console.log("==========================");
};


Interface_Gestion_URB_CLS.prototype.init = function () {
  this.UI.GestionUrbObj = this;
  if (this.UI.classBindData.authToken != null) {
    if (this.isInit == true) {
      $.fn.zTree.destroy("tree_URB");
      this.isInit = false;
    }
    this.zNodes = [];
    this.Everest.List_Urban_Object_Types(
      this.List_Urban_Object_Types_Callback.bind(this),
      this.Everest_callbackError.bind(this)
    );
  }
};

Interface_Gestion_URB_CLS.prototype.List_Urban_Object_Types_Callback = function (data, status) {
  this.ListModelZoom = data;
  this.initListInResults();
  this.Everest.List_Root_Urban_Objects(this.initTree.bind(this), this.Everest_callbackError.bind(this));
};

Interface_Gestion_URB_CLS.prototype.initListInResults = function () {
  $('#urban_objects_select').children('option:not(:first)').remove();
  for (var i in this.ListModelZoom) {
    var item = this.ListModelZoom[i];
    if (item.metadatas != undefined) {
      var isFound = false;
      for (var j in item.metadatas) {
        if (isFound == false && item.metadatas[j].name != undefined && item.metadatas[j].name == "ListInResults" && item.metadatas[j].value != undefined && item.metadatas[j].value == "yes") {
          isFound = true;
          $("#urban_objects_select").append('<option value="' + this.fixOpenDapStrEncoding(item.name) + '">' + this.fixOpenDapStrEncoding(item.name) + '</option>');
          //console.log(item.name);
        }
      }
    }
  }
};

/**
 * Fix encoding of strings coming for OpenDAP
 */
Interface_Gestion_URB_CLS.prototype.fixOpenDapStrEncoding = function(str) {
  return decodeURIComponent(escape(str).replace(/%uF7/g, "%").replace(/%C3%90%C2/g, '%D0').replace(/%C3%91%C2/g, '%D1'));
}

Interface_Gestion_URB_CLS.prototype.initTree = function (data, status, id) {
  this.tree = $("#tree_URB");
  for (var i = 0; i < data.items.length; i++) {
    var tmp_ico = this.Get_TreeicoLevel(data.items[i].objectType, data.items[i].hasChildren);

    var obj = {
      id: data.items[i].pid,
      pId: 0,
      name: data.items[i].name,
      open: false,
      isParent: data.items[i].hasChildren,
      UOtype: data.items[i].objectType,
      checked: false
    };
    var urlIco = "";
    for (var it in this.ListModelZoom) {
      var item = this.ListModelZoom[it];
      if (item.name != undefined && item.name == data.items[i].objectType) {
        if (item.metadatas != undefined) {
          for (var m in item.metadatas) {
            if (item.metadatas[m].name != undefined && item.metadatas[m].name == "IconPath") {
              urlIco = item.metadatas[m].value;
            }
          }
        }
      }
    }
    if (urlIco != "") {
      obj.icon = urlIco;
    } else {
      obj.iconSkin = tmp_ico; //"ico_pays",
    }
    this.zNodes.push(obj);
    if (obj.isParent)
      this.Everest.List_Urban_Objects_Children(
        obj.id,
        obj,
        this.getalltree.bind(this),
        this.Everest_callbackError.bind(this)
      );
  }
  var settings = {
    view: {
      dblClickExpand: true,
      showLine: true
    },
    check: {
      enable: true,
      chkboxType: {
        Y: "",
        N: ""
      }
    },
    data: {
      simpleData: {
        enable: true,
        idKey: "id",
        pIdKey: "pId",
        rootPId: ""
      }
    },
    callback: {
      onExpand: this.myOnExpand.bind(this),
      onClick: this.myOnClick.bind(this),
      onRightClick: this.onRightClick.bind(this),
      onCheck: this.onCheck.bind(this),
      beforeCheck: this.beforeCheck.bind(this)
    }
  };

  this.tree = $.fn.zTree.init(this.tree, settings, this.zNodes);
  this.isInit = true;
  //Rajoute "*" pour si l'item est modifié
  // for (var i = 0; i < data.items.length; i++) {
  //     var n = this.tree.getNodeByParam("id", data.items[i].pid, null);
  //     if (n != null) {
  //         this.addDiyDomChild(n);
  //     }
  // }

  $("#urban_objects_filter").keyup(
    function () {
      this.testCheck();
    }.bind(this)
  );

  $("#urban_objects_modified").change(
    function () {
      this.testCheck();
    }.bind(this)
  );

  $("#box_urban_objects .overlay").hide();
  for (var idItem in this.tabUoMini) {
    if (this.tabUoMini.hasOwnProperty(idItem)) {
      this.InitUoMini(this.tabUoMini[idItem]); //this.InitUoMini
    }
  }

    // dispatch event 'onRootObjectsLoaded' for PRODEC
    if (window.eventDispatcher) {
        // filter root objects which are not country
        var nodes = this.tree.getNodes().filter(function(node) {
            return !node.isParent && node.UOtype !== "Country";
        });

        // remove root objects which are not Country
        nodes.map(function(node) {
            this.tree.removeNode(node);
        }, this);

        window.eventDispatcher.dispatch("onRootObjectsLoaded", data);
    }
};

Interface_Gestion_URB_CLS.prototype.testCheck = function () {
  //Recoche tout
  var value = $.trim($("#urban_objects_filter").val()).toLowerCase();
  var cpt = 0;
  var curTree = this.getCurrentTree();
  if (curTree !== undefined && curTree.hasOwnProperty("getNodesByParam")) {
    var nodes = curTree.getNodesByParam("isHidden", true);
    curTree.showNodes(nodes);
    if (value != "") {
      var nodeList = curTree.getNodesByFilter(function filter(node) {
        return !node.isParent && node.name.toLowerCase().indexOf(value) == -1;
      });

      //Masque les items.
      curTree.hideNodes(nodeList);
    }

    if ($("#urban_objects_modified").is(":checked")) {
      var nodeList = curTree.getNodesByFilter(
        function filter(node) {
          var notModified = true;

          for (var i in this.Everest.UOModified.Initiatives[this.UI.classBindData.simuSelectedInitiative]) {
            if (
              node.id == this.Everest.UOModified.Initiatives[this.UI.classBindData.simuSelectedInitiative][i]
            ) {
              notModified = false;
            }
          }
          return !node.isParent && notModified;
        }.bind(this)
      );

      //Masque les items.
      curTree.hideNodes(nodeList);
    }
  }
};

/*
    Add to the KPI list an input box to set a weight for the rank calcul
*/
Interface_Gestion_URB_CLS.prototype.addDiyDomRank = function (treeId, treeNode) {
  if (treeNode.isParent == false) {
    var aObj = $("#" + treeNode.tId);
    var id = treeNode.id.replace(/\//g, "_");
    var editStr =
      "<span  id='item_ranking_" +
      id +
      "' disabled='true'" +
      (treeNode.checked == false ? " style='display:none'" : "") +
      " > - <input type='text' id='item_ranking_" +
      id +
      "_txt' title='Weight'/><select id='item_ranking_" +
      id +
      "_select' title='Trend'><option value='up'>up</option><option value='down'>down</option></select></span>";
    aObj.append(editStr);
  }
};

Interface_Gestion_URB_CLS.prototype.addDiyDom = function (treeId, treeNode) {
    let aObj = $(`#${treeNode.tId}_a`);
    const editStr =
        `<span class="diyLoading_${treeNode.id}">&nbsp;<img src=${loadingGIF} /></span>`;
    aObj.append(editStr);
};
Interface_Gestion_URB_CLS.prototype.addDiyDomChild = function (treeNode) {
  if (treeNode.tId != undefined) {
    var aObj = $("#" + treeNode.tId + "_a");
    //console.log(aObj);
    var editStr = "<span  >*</span>";
    aObj.append(editStr);
    //console.log(editStr);
  }
};

Interface_Gestion_URB_CLS.prototype.myOnExpand = function (event, treeId, treeNode) {
  if (treeNode.isLoadedAsync != true) {
    //Reporte le load async de partout
    //En premier sur l'arbre principal
    var node = this.tree.getNodeByParam("id", treeNode.id, null);
    if (node != null) {
      node.isLoadedAsync = true;
    }

    for (var idItem in this.tabUoMini) {
      if (this.tabUoMini.hasOwnProperty(idItem)) {
        node = this.tabUoMini[idItem].tree.getNodeByParam("id", treeNode.id, null);
        if (node != null) {
          node.isLoadedAsync = true;
        }
      }
    }
    this.addDiyDom(treeId, treeNode);
    this.Everest.List_Urban_Objects_Children(
      treeNode.id,
      treeNode,
      this.Addchild.bind(this),
      this.Everest_callbackError.bind(this)
    );
    if (this.UI.classBindData.idsimu != undefined && this.UI.classBindData.idsimu != null) {
      this.List_Updated_Urban_Objects_Init(this.UI.classBindData.idsimu);
    }
  }
};

Interface_Gestion_URB_CLS.prototype.List_Updated_Urban_Objects_Init = function (idSimu) {
  var tree = $.fn.zTree.getZTreeObj("tree_URB");
  var treeNode;
  if (this.Everest.UOModified.Initiatives[idSimu] != undefined) {
    $(".UOModified").hide();
    for (var i = 0; i < this.Everest.UOModified.Initiatives[idSimu].length; i++) {
      treeNode = tree.getNodeByParam("id", this.Everest.UOModified.Initiatives[idSimu][i]);
      if (treeNode != null) {
        if ($("#Modif_" + treeNode.id).length == 0) {
          var aObj = $("#" + treeNode.tId + "_a");
          var editStr =
            "<span id='Modif_" +
            treeNode.id +
            "' class='UOModified diyModified_" +
            treeNode.id +
            "' >*</span>";
          aObj.append(editStr);
        }
        $(".diyModified_" + treeNode.id).show();
      }
    }
  }
};


Interface_Gestion_URB_CLS.prototype.beforeCheck = function (event, treeId, treeNode) {
  //console.log("beforeCheck");
  var retour = true;
  var nodes = this.tree.getCheckedNodes(true);
  if (nodes.length > 0) {//At least one item is checked
    if (this.CurrentItemLink != undefined && this.CurrentItemLink.beforeCheck != undefined) {
      retour = this.CurrentItemLink.beforeCheck(this);
    }
  }
  return retour;
}
Interface_Gestion_URB_CLS.prototype.onCheck = function (event, treeId, treeNode) {
  //console.log("UO_onCheck");
  //console.log(treeId);
  //console.log(treeNode);
  //Solve bug #3992
  if (this.CurrentObjLink != undefined && this.CurrentObjLink.id != undefined && this.CurrentObjLink.id == "uo_TargetList_Edit") {
    var nodes = this.getCheckedNodesUO(true);
    if (nodes.length > 1) {
      this.getCurrentTree().checkAllNodes(false);
      this.getCurrentTree().checkNode(treeNode, true, false);
    }
  }
  //End #3992

  var nodes = this.getCheckedNodesUO(true);
  if (nodes.length > 0) {
    if (treeNode.checked == true) {
      this.Everest.selectedTypeName = treeNode.UOtype;
      if (!this.Everest.KpiMeta[treeNode.UOtype]) {
        this.Everest.Read_KPIMetadata(
          treeNode.UOtype,
          treeNode.UOtype,
          this.addKpIMeta.bind(this),
          this.Everest_callbackError.bind(this)
        );
      }

      for (var i in nodes) {
        if (nodes.hasOwnProperty(i)) {
          if (treeNode.UOtype !== nodes[i].UOtype) {
            //this.allreadycharge = false;
            this.allreadycharge_AnaRes = false;
            this.allreadycharge_AnaTar = false;
            this.allreadycharge_AnaRank = false;

            this.getCurrentTree().checkNode(nodes[i], false, true, true);
          }
        }
      }
    }
  }
  if (this.CurrentItemLink != undefined && this.CurrentItemLink.LoadFromUO != undefined && (treeNode == undefined || this.Everest.selectedTypeName == treeNode.UOtype)) {
    if (treeNode == undefined || (treeNode.checked == false && nodes[0] == undefined)) //Reset in treeNode is undefined (!?) and no node is checked at all
      this.CurrentItemLink.ResetFromUO();
    else
      this.CurrentItemLink.LoadFromUO(this, treeNode.checked == false ? nodes[0] : treeNode);
  }
  if (this.CurrentObjLink != undefined && this.CurrentObjLink.id != undefined) {
    if (this.Cls_Result != undefined && this.CurrentObjLink.id == "uo_analysing_Result") {
      //Case KPI Selection
      this.Cls_Result.updateCheck();//IGO
    } else if (this.Cls_Ranking != undefined && this.CurrentObjLink.id == "uo_analysing_Ranking") {
      //Case KPI Ranking
      this.Cls_Ranking.updateCheck();
    } else if (this.Cls_Target != undefined && this.CurrentObjLink.id == "uo_analysing_Targeting") {
      //Case KPI Ranking
      this.Cls_Target.updateCheck();
    }
  }
  this.Selection3D();
};



/*
    fct called when we check a ztree checkbox
    */
Interface_Gestion_URB_CLS.prototype.onCheckRank = function (event, treeId, treeNode) {
  var allNodes = [];
  if (treeNode.isParent == true) {
    allNodes = treeNode.children;
  } else {
    allNodes.push(treeNode);
  }
  for (var i = 0; i < allNodes.length; i++) {
    var numToString = allNodes[i].id.toString();
    numToString = numToString.replace(/\//g, "_");
    if (treeNode.checked == true) {
      $("#item_ranking_" + numToString).show();
      //$('#item_ranking_' + allNodes[i].id.replace(/\//g, '_')).show();
    } else {
      $("#item_ranking_" + numToString).hide();
      //$('#item_ranking_' + allNodes[i].id.replace(/\//g, '_')).hide();
    }
  }
  if (treeId === "treeSimAnaRanking") {
    this.currentSimId = allNodes[0].id;
    if (this.treeKpi != null) {
      $.fn.zTree.destroy("treeAnaRankKpi");
    }
    this.treeKpi = null;
    $("#kpi_analysing_Ranking_overlay").show();
    this.Everest.GetOpenDapMetadata(treeNode.id, treeNode.id, this.SetKpi.bind(this));
  }
};

Interface_Gestion_URB_CLS.prototype.Selection3D = function () {
  //Selection 3D
  var nodes = this.tree.getCheckedNodes(true);
  // console.log(nodes);
  var Tab_Select = [];
  for (var i in nodes) {
    if (nodes.hasOwnProperty(i)) {
      // if (nodes[i].name.indexOf("Block") > -1) {
      //if (nodes[i].level == 4) {
      //var pid_mod = parseInt(nodes[i].id); // - 1300000000; //+ 1400045443;
      //Tab_Select.push(pid_mod.toString());
      Tab_Select.push(nodes[i].id);
      //}
    }
  }
  // console.log(Tab_Select);
  // console.log("========================");
  if (Tab_Select.length > 0) this.tw3d.SelectUO(Tab_Select);
  else this.tw3d.UnSelectAllUO();
};

Interface_Gestion_URB_CLS.prototype.ZtreeSelection = function (Tab_Select) {
  var List_pid = [];
  var nodes = this.GestionUrbObj.tree.getCheckedNodes(true);
  for (var node in nodes) {
    if (nodes.hasOwnProperty(node)) {
      this.GestionUrbObj.tree.checkNode(nodes[node], false, true, false);
    }
  }

  for (var i = 0; i < Tab_Select.length; i++) {
    var List_node = this.GestionUrbObj.tree.getNodesByParam("id", Tab_Select[i], null);
    for (var node in List_node) {
      if (List_node.hasOwnProperty(node)) {
        //if (Get_node != undefined) {
        this.GestionUrbObj.tree.checkNode(List_node[node], true, true, false);
      }
    }
  }

  this.GestionUrbObj.twSelected = Tab_Select;
  if (Tab_Select[0] != undefined) {
    this.GestionUrbObj.onCheck(undefined, Tab_Select[0], this.GestionUrbObj.tree.getNodesByParam("id", Tab_Select[0], null)[0]);
  } else {
    this.GestionUrbObj.onCheck(undefined, undefined, undefined);
  }
};

Interface_Gestion_URB_CLS.prototype.Addchild = function (data, status, obj) {
  /* var tmp_ico = "ico_ville";
  var tmp_isParent = false;

  switch (obj.level) {
      case 0:
          tmp_ico = "ico_ville";
          tmp_isParent = true;
          break;
      case 1:
          tmp_ico = "ico_arrond";
          tmp_isParent = true;
          //this.UI.classBindData.infoUOneighbor = parseFloat(data.items[0].pid);
          break;
      case 2:
          tmp_ico = "ico_quartier";
          tmp_isParent = true;
          break;
      case 3:
          tmp_ico = "ico_bati";
          break;
  }*/
  var tmp_isParent = false;
  if (obj.level < this.ListModelZoom.length - 1) {
    tmp_isParent = true;
  }

  var myData;
  var tabNode = [];
  var isChecked = false;
  if (obj.checkOnLoad != undefined && obj.checkOnLoad == true) {
    isChecked = true;
  }
  for (var i = 0; i < data.items.length; i++) {
    if ((data.items[i].hasChildren && tmp_isParent == true) || !tmp_isParent) {
      var tmp_ico = this.Get_TreeicoLevel(data.items[i].objectType, data.items[i].hasChildren);
      var urlIco = "";
      for (var it in this.ListModelZoom) {
        var item = this.ListModelZoom[it];
        if (item.name != undefined && item.name == data.items[i].objectType) {
          if (item.metadatas != undefined) {
            for (var m in item.metadatas) {
              if (item.metadatas[m].name != undefined && item.metadatas[m].name == "IconPath") {
                urlIco = item.metadatas[m].value;
              }
            }
          }
        }
      }

      var test_check = false;
      if (this.twSelected != undefined) {
        if (this.twSelected.indexOf(data.items[i].pid) != -1) {
          test_check = true;
        }
      }
      if (test_check == true) {
        myData = {
          id: data.items[i].pid,
          pId: obj.id,
          name: data.items[i].name,
          open: false,
          isParent: tmp_isParent,
          checked: true
        };
      } else {
        myData = {
          id: data.items[i].pid,
          pId: obj.id,
          name: data.items[i].name,
          open: false,
          isParent: tmp_isParent,
          checked: isChecked
        };
      }

      if (urlIco != "") {
        myData.icon = urlIco;
      } else {
        myData.iconSkin = tmp_ico; //"ico_pays",
      }
      if (this.tree.getNodeByParam("id", data.items[i].pid, null) == null) {
        tabNode.push(myData);
      }
    }
  }
  var parent = this.tree.getNodeByParam("id", obj.id, null);
  tabNode = this.tree.addNodes(parent, tabNode, true);
  //AddDiydom
  // for (var newNode in tabNode) {
  //     if (tabNode.hasOwnProperty(newNode)) {
  //         this.addDiyDomChild(tabNode[newNode]);
  //
  //     }
  // }
  for (var idItem in this.tabUoMini) {
    if (this.tabUoMini.hasOwnProperty(idItem)) {
      if (this.tabUoMini[idItem].lvlMax >= parent.level) {
        parent = this.tabUoMini[idItem].tree.getNodeByParam("id", obj.id, null);
        this.tabUoMini[idItem].tree.addNodes(parent, tabNode, true);
      }
    }
  }
  if (data.nextUrl != null) {
    //On charge la suite
    this.Everest.List_Urban_Objects_Children_Next_Url(
      data.nextUrl,
      obj,
      this.Addchild.bind(this),
      this.Everest_callbackError.bind(this)
    );
  } else {
    $(".diyLoading_" + obj.id).remove();
    //Select premier item si aucun select et tous checked
    if (isChecked == true) {
      //Si plus aucun selectionné, on select le dernier coché.
      var nodes = this.tree.getSelectedNodes();
      if (nodes.length == 0) {
        var nodesChk = this.tree.getCheckedNodes(true);
        if (nodesChk.length > 0) {
          this.tree.selectNode(nodesChk[0], false);
          this.OnClick(nodesChk[0]);
        }
      }
    }
  }
  this.Selection3D();
};

Interface_Gestion_URB_CLS.prototype.myOnClick = function (event, treeId, treeNode, isRightClick) {
  var rMenu = '<div id="rMenuUOList" class="box-body no-padding" >';
  for (var i = 0; i < this.objectType.length; i++) {
    if (treeNode.level <= i) {
      rMenu +=
        '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="' +
        this.objectType[i] +
        '_SA" ><i class="fa fa-edit"></i> ' +
        this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_GRAPH_SELECT_ALL +
        this.objectType[i] +
        "</a>";
    }
  }
  rMenu += "  </div>";

  $("#page_centre").prepend(rMenu);
  for (var i = 0; i < this.objectType.length; i++) {
    if (treeNode.level <= i) {
      $("#" + this.objectType[i] + "_SA").click(
        $.proxy(
          function (e) {
            //this.allreadycharge = false;
            this.allreadycharge_AnaRes = false;
            this.allreadycharge_AnaTar = false;
            this.allreadycharge_AnaRank = false;

            var array = e.currentTarget.id.split("_");
            $("#rMenuUOList").hide();
            $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
            this.Everest.selectedTypeName = this.objectType;
            this.getallchildrenoftype(this.objectType, treeNode, array[0], this.tabUrbObj, treeId);
          }.bind(this),
          this
        )
      );
    }
  }
  if (isRightClick == undefined || isRightClick != "Y") {
    this.OnClick(treeNode);
  }
};

Interface_Gestion_URB_CLS.prototype.getallchildrenoftype = function (
  objectTypeTab,
  treeNode,
  objectWanted,
  tabUO,
  treeId
) {
  if (treeNode.isParent == true) {
    var parent = this.tree.getNodeByParam("id", treeNode.id, null);
    //exapnd first parent
    this.tree.expandNode(parent, true, false, false);
    //uncheck first parent
    this.tree.checkNode(parent, false, true, false);
    if (objectWanted != null) {
      if (!this.Everest.KpiMeta[objectWanted]) {
        this.Everest.Read_KPIMetadata(
          objectWanted,
          objectWanted,
          this.addKpIMeta.bind(this),
          this.Everest_callbackError.bind(this)
        );
      }
      //start recursive research
      this.Everest.List_Urban_Objects_Children(
        treeNode.id,
        objectWanted,
        this.checkSons.bind(this),
        this.Everest_callbackError.bind(this)
      );
      $("#kpi_analysing_Result_overlay").show();
    }
  }
};

Interface_Gestion_URB_CLS.prototype.checkSons = function (data, status, objectWanted) {
  var parent;
  //if all objectWanted aren't checked
  if (objectWanted != null) {
    //for all sons

    for (var i = 0; i < data.items.length; i++) {
      parent = this.tree.getNodeByParam("id", data.items[i].pid, null);

      // if we have found some of the objectWanted
      if (data.items[i].objectType === objectWanted) {
        if (this.allreadycharge_AnaRes == false) {
          this.OnClick(parent);
          this.allreadycharge_AnaRes = true;
        } else {
          this.tree.checkNode(parent, true, true, false);
        }
        this.tree.expandNode(parent, false, true, false);
      } else {
        // if we have'nt found some of the objectWanted
        this.tree.expandNode(parent, true, false, false);
        this.tree.checkNode(parent, false, true, false);
        //continue to recurse
        this.Everest.List_Urban_Objects_Children(
          data.items[i].pid,
          objectWanted,
          this.checkSons.bind(this),
          this.Everest_callbackError.bind(this)
        );
      }
    }
  }
  if (data.nextUrl != null) {
    //On charge la suite
    this.Everest.List_Urban_Objects_Children_Next_Url(
      data.nextUrl,
      objectWanted,
      this.checkSons.bind(this),
      this.Everest_callbackError.bind(this)
    );
  }
  this.Selection3D();
};

/*
Interface_Gestion_URB_CLS.prototype.getalltreeRoot = function(data, status, obj) {
    var tmp_ico = "ico_ville";
    var myData;
    var tabNode;
    for (var i = 0; i < data.items.length; i++) {
        myData = {
            id: data.items[i].pid,
            pId: obj.id,
            name: data.items[i].name,
            open: true,
            isParent: data.items[i].hasChildren,
            UOtype: data.items[i].objectType,
            iconSkin: tmp_ico,
            checked: false
        };
        tabNode.push(myData);

        if (data.items[i].hasChildren) {
            this.Everest.List_Urban_Objects_Children(data.items[i].pid, myData, this.getalltree.bind(this), this.Everest_callbackError.bind(this));
        }

    }
    var parent = this.tree.getNodeByParam("id", obj.id, null);
    tabNode = this.tree.addNodes(parent, tabNode, true);

};
*/

Interface_Gestion_URB_CLS.prototype.getalltree = function (data, status, obj) {
  // var tmp_ico = "ico_ville";

  /*
  switch (data.items[0].objectType) {
      case "Town":
          tmp_ico = "ico_ville";
          break;
      case "Neighborhood":
          tmp_ico = "ico_arrond";
          break;
      case "Precinct":
          tmp_ico = "ico_quartier";
          break;
      case "Building":
          tmp_ico = "ico_bati";
          break;
  }*/

  var myData;
  var tabNode = [];
  if (data.nextUrl != null) {
    //On charge la suite
    this.Everest.List_Urban_Objects_Children_Next_Url(
      data.nextUrl,
      obj,
      this.getalltree.bind(this),
      this.Everest_callbackError.bind(this)
    );
  }
  for (var i = 0; i < data.items.length; i++) {
    var tmp_ico = this.Get_TreeicoLevel(data.items[i].objectType, data.items[i].hasChildren);
    var urlIco = "";
    for (var it in this.ListModelZoom) {
      var item = this.ListModelZoom[it];
      if (item.name != undefined && item.name == data.items[i].objectType) {
        if (item.metadatas != undefined) {
          for (var m in item.metadatas) {
            if (item.metadatas[m].name != undefined && item.metadatas[m].name == "IconPath") {
              urlIco = item.metadatas[m].value;
            }
          }
        }
      }
    }

    myData = {
      id: data.items[i].pid,
      pId: obj.id,
      name: data.items[i].name,
      open: false,
      isParent: data.items[i].hasChildren,
      UOtype: data.items[i].objectType,
      checked: false
    };
    if (urlIco != "") {
      myData.icon = urlIco;
    } else {
      myData.iconSkin = tmp_ico; //"ico_pays",
    }
    tabNode.push(myData);

    if (data.items[i].hasChildren) {
      this.Everest.List_Urban_Objects_Children(
        data.items[i].pid,
        myData,
        this.getalltree.bind(this),
        this.Everest_callbackError.bind(this)
      );
    }
  }

  var parent = this.tree.getNodeByParam("id", obj.id, null);
  tabNode = this.tree.addNodes(parent, tabNode, true);

  var found = $.inArray(data.items[0].objectType, this.objectType);
  if (found < 0) {
    this.objectType.push(data.items[0].objectType);
  }

    // fire an event 'onChildObjectsLoaded' [all urban children objects]
    if (!data.nextUrl && window.eventDispatcher) {
        window.eventDispatcher.dispatch("onChildObjectsLoaded");
    }

};

/*
    Récupere le niveau pour un type
*/
Interface_Gestion_URB_CLS.prototype.Get_TreeTypeLevel = function (typeName) {
  for (var i = 0; i < this.ListModelZoom.length; i++) {
    if (this.ListModelZoom[i].name == typeName) {
      return i;
    }
  }
  return -1;
};

/*
    Récupere le niveau pour un type
*/

Interface_Gestion_URB_CLS.prototype.Get_TreeicoLevel = function (typeName, hasChildren) {
  var tmp_ico = "ico_pays";
  var UOtypeLeel = this.Get_TreeTypeLevel(typeName);

  switch (UOtypeLeel) {
    case 0:
      tmp_ico = "ico_pays";
      break;
    case 1:
      tmp_ico = "ico_ville";
      break;
    case 2:
      tmp_ico = "ico_arrond";
      break;
    case 3:
      tmp_ico = "ico_quartier";
      break;
    case 4:
      tmp_ico = "ico_bati";
      break;
  }

  if (hasChildren == false) {
    tmp_ico += "nc"; //no child
  }
  return tmp_ico;
};

/*
    Return True if the typeName has the metadata EnableColoring and EnableColoring==True
*/
Interface_Gestion_URB_CLS.prototype.isEnableColoring = function (typeName) {
  var retour = true;
  for (var i = 0; i < this.ListModelZoom.length; i++) {
    if (this.ListModelZoom.hasOwnProperty(i) && this.ListModelZoom[i].name == typeName && this.ListModelZoom[i].metadatas!=undefined) {
      var meta = this.ListModelZoom[i].metadatas;
      for (var j = 0; j < meta.length; j++) {
        if (meta.hasOwnProperty(i) && meta[j].name == "EnableColoring" && meta[j].value.toLowerCase()=="no") {
          retour = false;
          return retour;
        }
      }
    }
  }
  return retour;
};

Interface_Gestion_URB_CLS.prototype.onRightClick = function (event, treeId, treeNode) {
  if (!treeNode && event.target.tagName.toLowerCase() != "button" && $(event.target).parents("a").length == 0) {
    this.tree.cancelSelectedNode();
    this.myOnClick(event, treeId, treeNode);
  } else if (treeNode && !treeNode.noR) {
    this.tree.selectNode(treeNode);
    this.myOnClick(event, treeId, treeNode, "Y");
    this.showRMenu("node", event.clientX, event.clientY);
  }
  //
  //clic sur case a cochers
  if (treeNode == undefined) {
    return -1;
  }
};
Interface_Gestion_URB_CLS.prototype.showRMenu = function (type, x, y) {
  $("#rMenuUOList")
    .stop()
    .css({
      top: y + "px",
      left: x + "px"
    })
    .show("fold");
  $("body").bind("mousedown", this.onBodyMouseDown.bind(this));
};

Interface_Gestion_URB_CLS.prototype.onBodyMouseDown = function (event) {
  if (!(event.target.id == "rMenuUOList" || $(event.target).parents("#rMenuUOList").length > 0)) {
    $("#rMenuUOList").hide();
    $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
  }
};
Interface_Gestion_URB_CLS.prototype.addKpIMeta = function (data, status, obj) {
  this.Everest.KpiMeta[this.Everest.selectedTypeName] = data;
};

Interface_Gestion_URB_CLS.prototype.OnClick = function (treeNode) {
  var bool = !treeNode.checked;
  this.getCurrentTree().checkNode(treeNode, bool, true, true);
};

Interface_Gestion_URB_CLS.prototype.Everest_callbackError = function (data, status) {
  var error;
  if (data.responseJSON != undefined) {
    error = data.responseJSON.error != undefined ? data.responseJSON.error : "";
  }

  if (data.responseJSON != undefined) this.UI.createMessageError(error, data.responseJSON.errorMessage);
  else if (data.responseText != undefined) this.UI.createMessageError("", data.responseText);
  else this.UI.createAlert("alert", "error", "ERROR");
};

export default Interface_Gestion_URB_CLS;
