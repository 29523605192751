import "bs3/css/bootstrap.min.css";
import "jquery-ui/jquery-ui.min.css";

import "admin-lte/dist/css/AdminLTE.css";
import "admin-lte/dist/css/skins/_all-skins.css";

import "./assets/scss/deprecated.scss";
import "./assets/scss/index.scss";

import "jquery-ui/jquery-ui.min.js";
//TODO Fixed it or not? if the user won't use it in PRODEC
// import "bootstrap-colorpicker-plus/dist/css/bootstrap-colorpicker.min.css";
// import "bootstrap-colorpicker-plus/dist/css/bootstrap-colorpicker-plus.css";
// import "bootstrap-colorpicker-plus/dist/js/bootstrap-colorpicker.min.js"
// import "bootstrap-colorpicker-plus/dist/js/bootstrap-colorpicker-plus.min.js"
import Cavi2 from "./cavi2.js";

// lazy load future trunks
import(/* webpackPrefetch: true*/"font-awesome/css/font-awesome.css");
import(/* webpackPrefetch: true*/"admin-lte/dist/js/app.min");
import(/* webpackPrefetch: true*/"bs3/js/bootstrap.min");
import(/* webpackPrefetch: true*/"ion-rangeslider/css/ion.rangeSlider.min.css");
import(/* webpackPrefetch: true*/"ion-rangeslider/js/ion.rangeSlider.js");


if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
) {
    $("body").css({
        "font-size": "25px!important",
        "font-family": "'Source Sans Pro','Helvetica Neue', Arial, Helvetica, sans-serif",
        "font-weight": "400",
        "-webkit-font-smoothing": "antialiased",
    });

    $("ztree").css("font-size", "medium");
} else {
    console.log("desktop");
}

if (!window.cavi) {
    window.cavi = new Cavi2("json/url.json", "json/fichier_libelle_texte.json");
    cavi.init();
}

// enable HMR in development
if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
        module.hot.accept(["./cavi2.js"], () => console.log("👨‍💻: HMR is 🔥"));
    }
}



