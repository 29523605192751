import promisify from "../helpers/promise.util.js";
import handleError from "../errorHandler.js";
import { USERS_SHOULD_ALWAYS_INCLUDE } from "../auth/index.js";

class BundleSharing {
    constructor() {
        // deps
        this.Everest = undefined;
        this.UI = undefined;

        // src of truth
        this.bundle = undefined;

        // props
        this.users = ko.observable([]);
        this.groups = ko.observable([]);

        // states
        this.isOpen = ko.observable(false);
        // used while bundle info is loading
        this.isFetching = ko.observable(false);
        // used while bundle info is updating
        this.isSubmitting = ko.observable(false);
        // used to indicate if submit btn is disabled
        this.isSubmitDisabled = ko.computed(function() {
            return this.isFetching() || this.isSubmitting();
        }, this, { pure: true });

        // handlers
        this.hide = this.hide.bind(this);
        this.show = this.show.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    setup(everest, ui) {
        // deps injection , can't imagine I am doing this
        this.Everest = everest;
        this.UI = ui;
    }

    hide() {
        this.isOpen(false);
    };

    show() {
        this.isOpen(true);
    };

    loadData(bundle, users, groups) {
        this.bundle = bundle;
        this.users(users);
        this.groups(groups);
    }

    _toPayload(evt) {
        const users = evt.users();
        const groups = evt.groups();
        const { authorizedUsers, authorizedGroups, owner, ...others } = this.bundle;

        const currentUser = this.Everest.getUsername();

        // include users that MUST be included namely python user
        // avoid unexpected behaviour for migrations
        const whiteListUsers = USERS_SHOULD_ALWAYS_INCLUDE.map(username => ({ username }));

        // Force to include current user and owner every single time
        // avoid users to revoke the owner decision
        const defaultUsers = currentUser !== owner ?
            [{ username: currentUser }, { username: owner }] :
            [{ username: currentUser }];

        const extraUsers = defaultUsers.concat(whiteListUsers);

        const _users = users
            .filter((elem) => elem.published)
            .map(({ username }) => ({ username }))
            .concat(extraUsers);

        const _groups = groups
            .filter((elem) => elem.published)
            .map(({ code }) => ({ code }));

        return {
            ...others,
            authorizedUsers: _users,
            authorizedGroups: _groups,
        };
    }

    handleSubmit(evt) {
        const { bundleId, name, description, authorizedUsers, authorizedGroups } = this._toPayload(evt);
        const promise = promisify(this.Everest.Update_Bundle_Info(bundleId, name, description, authorizedUsers, authorizedGroups));

        this.isSubmitting(true);
        promise.then(() => {
            this.UI.createAlert("notif", "success", this.UI.accessMessageContentJSON.MESSAGE_ALERT_FOLDER_SHARED);
            this.isOpen(false);
        }).catch(error => {
            handleError(error, this.UI);
        }).finally(() => {
            this.isSubmitting(false);
        });
    }
}

const instance = new BundleSharing();

export default instance;
