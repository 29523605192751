import i18nextko from "i18next-ko/src/i18next-ko.js";
import Cookies from "js-cookie";
// force to use file loader in ko template
import gbFlag from "!!file-loader?name=static/images/[name].[hash:8].[ext]!flag-icon-css/flags/1x1/gb.svg";
import frFlag from "!!file-loader?name=static/images/[name].[hash:8].[ext]!flag-icon-css/flags/1x1/fr.svg";

class LanguageSelectorModel {
    constructor(i18nextOptions) {
        // default language set
        this.languagesMeta = [
            {
                code: "en",
                name: "English",
                imgPath: gbFlag,
            },
            {
                code: "fr",
                name: "Français",
                imgPath: frFlag,
            },
            // {
            //     code: "it",
            //     name: "Italiano",
            //     imgPath: "./images/flag-icon-css/flags/1x1/it.svg",
            // },
            // {
            //     code: "de",
            //     name: "Deutsch",
            //     imgPath: "./images/flag-icon-css/flags/1x1/de.svg",
            // },
            // {
            //     code: "ru",
            //     name: "Русский",
            //     imgPath: "./images/flag-icon-css/flags/1x1/ru.svg",
            // },
        ];

        // filter based on i18next.options.whitelist
        this.languagesMeta = this.languagesMeta.filter(elem =>
            i18nextOptions.whitelist.includes(elem.code),
        );

        this.availableLanguages = ko.observableArray(this.languagesMeta);

        this.currentFlag = ko.observable(
            this.languagesMeta.find(elem => elem.code === Cookies.get("i18next")).imgPath,
        );

        // use img alt; (For Accessibility)
        this.currentLangCode = ko.observable(
            this.languagesMeta.find(elem => elem.code === Cookies.get("i18next")).code,
        );

        this.changeLanguage = language => {
            this.currentLangCode(language.code);
            this.currentFlag(language.imgPath);
            i18nextko.setLanguage(language.code);
        };
    }
}

export default LanguageSelectorModel;
