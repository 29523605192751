"use strict";
import i18nextko from "i18next-ko/src/i18next-ko.js";

class MosaUI {
    constructor(UI, controller) {
        this.controller = controller;
        this.UI = UI;
        this.contentMessageFileJSON = this.UI.accessMessageContentJSON;
        this.contentFileJSON = this.UI.accessContentJSON;

        //timer to update file selection when update MOSA MID or cityGML
        this.timer_selectFile = undefined;
    }
    show(name) {
        $("#nom_mosa").text(name);

        $("#mainpanel").show();
        $.AdminLTE.layout.fix();
    }

    hide() {
        $("#mainpanel").hide();
        $.AdminLTE.layout.fix();
    }

    // Remove table of list MOSA
    reset(id) {
        if (id != null) {
            $('#LstMOSA_' + id).remove();
        }
    }

    // on the MOSA manage page in user normal mode
    // Add a MOSA in the dropdown list
    addList(name) {
        const tokens = name.split(/[.\-_]/).map(w => w.toLowerCase()).join(' ');
        var option = `<option value='${name}' title='${name}' data-tokens='${tokens}'>${name}</option>`;
        $("#list_select_mosa_usersimple").append(option);

        if (document.getElementById("list_select_mosa_usersimple").disabled) {
            document.getElementById("list_select_mosa_usersimple").disabled = false;
        }
    }

    // On SuperUser mode
    // Add a new line for a MOSA with all its elements
    addDiv(id, mosa) {

        let txtRow = `<tr id="LstMOSA_${id}">`;
        txtRow += `<td id="LstMOSA_${id}_name_TD"><p id="${id}" class="hidden"></p><b alt="${mosa.name}">${mosa.name}</b><i class="show text-justify">${mosa.description}</i></td>`;
        txtRow += `<td id="LstMOSA_${id}_MID_TD"><i class="fa fa-fw ${mosa.MIDFileId ? "fa-check" : "fa-close"}" alt="${mosa.MIDFileId ? "yes" : "no"}"></i></td>`;
        txtRow += `<td id="LstMOSA_${id}_GML_TD"><i class="fa fa-fw ${mosa.MIDFileId ? "fa-check" : "fa-close"}"  alt="${mosa.MIDFileId ? "yes" : "no"}"></i></td>`;
        txtRow += `<td id="LstMOSA_${id}_state_TD"><i class="fa fa-fw ${mosa.state === "PUBLISHED" ? "fa-check" : "fa-close"}"  alt="${mosa.state === "PUBLISHED" ? "no" : "yes"}"></i></td>`;
        txtRow += `<td id="LstMOSA_${id}_updateDate_TD">${mosa.updateDate ? mosa.updateDate : "-"}</td>`;
        txtRow += `<td id="LstMOSA_${id}_studyArea" >${mosa.studyArea.code ? mosa.studyArea.code : "-"}</td>`;
        txtRow += `</tr>`;

        // If the list is created at the initialization or when we do a sort, we add results consecutively
        // when we create/add a new MOSA, this entry is added at the top
        if (this.UI.classBindData.isAddingNewMOSA == true) {
            $("#LstMOSA").children("tbody").prepend(txtRow);
        } else {
            $("#LstMOSA").children("tbody").append(txtRow);
        }
    }

    //Click on icons functions
    publish(id,statusModif) {
        $("#LstMOSA_" + id + "_state_TD").empty();
        var html = "<i class='fa fa-fw " + ((statusModif == "PUBLISHED") ? "fa-check" : "fa-close") + "'></i>";
        $("#LstMOSA_" + id + "_state_TD").append(html);
    }

    // In SuperUser
    // Let us to update MID and CityGML files for a MOSA
    update(id) {
        // open first box to choose which type of file we want to upload
        this.createboxUpdateMOSAFile(function() {});

        //upload  MID
        $("#updateMosaFile_upModel").click((event)=> {
            this.controller.updateMOSAfile("MID");
        });

        //upload  CityGML
        $("#updateMosaFile_up3D").click((event)=> {
            this.controller.updateMOSAfile("3D");
        });

        $("#updateMosa_back").click(function(event) {
            $("#box_updateMosa").remove();
        });

    }

    createboxUpdateMOSAFile(CallBackClose) {


        $("#box_updateMosa").remove();
        var c = "<div id=\"updateMosaFile_content\" style=\"width:100%; height:100%;\">";
        c += "      <table style=\"width:100%; height:100%;\">";

        c += "          <tr style=\"height: 50px;\"><td><button class=\"btn btn-primary use-skin\" id=\"updateMosaFile_upModel\">" + this.contentFileJSON.MENU_BUTTON_UPMOSA + "</button></td>";
        c += "          <td>";
        c += "              <button class=\"btn btn-primary use-skin\" id=\"updateMosaFile_up3D\">" + this.contentFileJSON.MENU_BUTTON_UP3D + "</button></td>";
        c += "          </td></tr>";
        c += "      </table>";
        c += "  </div>";
        var f = "<div class=\"pull-right\" ><button class=\"btn btn-primary use-skin\" id=\"updateMosa_back\"  >" + this.contentFileJSON.MENU_BUTTON_BACK + "</button></div>";
        this.UI.CreateBox("updateMosa", {
            draggable: true,
            group: "SuperUser",
            useSkin: true,
            title: this.contentFileJSON.MENU_UPDATE_MOSA,
            content: c,
            addOverlay: true,
            footer: f,
            resizable: {
                minHeight: 360,
                minWidth: 400
            },
            visible: true,
            menuBt: {
                close: true
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "30%",
                left: "40%",
                width: "400px",
                height: "360px",
                "min-width": "400px",
                "min-height": "360px",
                "z-index": Zindex + 1
            }
        }, CallBackClose);
        Zindex = Zindex + 1;
    }

    /*
    Timer calls this function to check the file name selected in the Windows input file
    */
    updateFileSelected() {
        var files = document.getElementById('updateMosa_url').files;
        if (files[0] != undefined) {
            var nameFile = document.getElementById('updateMosa_url').files[0].name;
            $("#fileSelectedUpdateMOSA").text(nameFile);
        }
    }


    updateMOSAfile(typeFileUpload,id) {
        //create the box with input file and logs
        this.UI.newBox.createboxUpdateMOSA(typeFileUpload, ()=> {
            window.clearInterval(this.timer_selectFile);
        });

        if (this.timer_selectFile != undefined) {
            window.clearInterval(this.timer_selectFile);
        }

        //set the timer to check if a file is selected and update the textarea which display the name of the file
        this.timer_selectFile = setInterval(()=> {
            this.updateFileSelected();
        }, 10000);

        $("#updateMosaSelect_back").click((event)=> {
            $("#box_updateMosaSelect").remove();
            this.controller.update(id);
        });

        $("#updateMosa_check").change(function(event) {
            var e = event;
            if (e.target.checked == true) {
                $("#contentLogs").css("display", "block");
            } else {
                $("#contentLogs").css("display", "none");
            }
        });

        $("#updateMosaSelect_load").click((event)=> {
            this.disabledFormMosaFile(true);
            $("#updateMosa_back").prop("disabled", true);


            //clear timer when select load button
            window.clearInterval(this.timer_selectFile);

            let inputFile = document.getElementById('updateMosa_url');
            let message = "";

            if (inputFile.files != null && inputFile.files != undefined && inputFile.files[0] != undefined) {
                var type = inputFile.files[0].type;
                var nameFile = inputFile.files[0].name;
                var nameParsed = nameFile.substring(0, nameFile.indexOf("."));
                var extension = nameFile.substring(nameFile.indexOf(".") + 1, nameFile.length);


                if (extension == "zip") {
                    this.controller.createFile(typeFileUpload,type,nameFile, nameParsed,inputFile);
                } else {
                    // this.contentMessageFileJSON.MESSAGE_ALERT_MOSA_UPDATE_FILE_EXTENSION
                    message = i18nextko.i18n.t('MESSAGE_ALERT_MOSA_UPDATE_FILE_EXTENSION', {ns:"message"});
                    this.UI.createAlertSU("alert", "warning", message);
                    this.disabledFormMosaFile(false);
                    $("#updateMosaSelect_load").prop("disabled", false);
                    $("#updateMosa_url").prop("disabled", false);
                }
            } else {
                // this.contentMessageFileJSON.MESSAGE_ALERT_MOSA_UPDATE_FILE
                message = i18nextko.i18n.t('MESSAGE_ALERT_MOSA_UPDATE_FILE', {ns:"message"});
                this.UI.createAlertSU("alert", "warning", message);
                this.disabledFormMosaFile(false);
                $("#updateMosaSelect_load").prop("disabled", false);
                $("#updateMosa_url").prop("disabled", false);
            }

        });

    }

    disabledFormMosaFile(val) {
        $("#updateMosaSelect_load").prop("disabled", val);
        $("#updateMosa_url").prop("disabled", val);
    }
    setIdMid(val){
        this.UI.classBindData.idMID = val;
    }
    setIdGml(val){
        this.UI.classBindData.idGML = val;
    }
    getIdGml(){
        return this.UI.classBindData.idGML;
    }
    setRunId(runid){
        $("#runIDUpdateMOSA").text("Run ID : " + runid);
    }

    /*readRun*/
    readRunStatusExec(dataResponse){
        $("#runStatusUpdateMOSA").text(dataResponse.progress + " %");
        this.setProgressMessage(dataResponse.progressMessage);
        // this.boolOK = false;
        var posScrollMax = $("#boxLogUpdate").prop("scrollHeight");
        var posScroll = $("#boxLogUpdate").scrollTop();
        var boolBottomScroll = false;
        if (posScroll == posScrollMax) {
            //le scroll était tout en bas
            boolBottomScroll = true;
        }
        this.controller.readRunOutputFile(posScroll)
    }

    setProgressMessage(message) {

        $("#textStatusUpdateMOSA").text(message);
    }
    readRunOutputFile(d,posScroll){
        $("#boxLogUpdate").val(d);

        var NewposScroll = $("#boxLogUpdate").scrollTop();
        if (boolBottomScroll == true) {
            $("#boxLogUpdate").scrollTop($("#boxLogUpdate").prop("scrollHeight"));
        } else {
            $("#boxLogUpdate").scrollTop(posScroll);
        }
        //this.boolOK = true;
    }
    updateModel(idMID,id,idGML){

        if (idMID != undefined) {
            $("#LstMOSA_" + id + "_MID_TD").empty();
            var html = "<i class='fa fa-fw fa-check'></i>";
            $("#LstMOSA_" + id + "_MID_TD").append(html);
            this.controller.setIdMid(undefined);
        } else if (idGML != undefined) {
            $("#LstMOSA_" + id + "_GML_TD").empty();
            var html = "<i class='fa fa-fw fa-check'></i>";
            $("#LstMOSA_" + id + "_GML_TD").append(html);
            this.controller.setIdGml(undefined);
        }
        $("#box_updateMosaSelect").remove();
        $("#updateMosa_back").prop("disabled", false);
    }

    deleteFct(id,name) {
        // this.contentFileJSON.MENU_LABEL_MESSAGE_DEL_MOSA;
        let message = i18nextko.i18n.t('MENU_LABEL_MESSAGE_DEL_MOSA', {ns:'menu'});

        this.UI.createConfirmBoxSU(`${message} ${name}`, ()=> {
            this.controller.deleteModel(id);
        });
    }

    deleteModel(id){
        //refresh the list
        var elem_deleted = document.getElementById("LstMOSA_" + id);

        var parent_elem = document.getElementById(elem_deleted.offsetParent.id);
        var f = parent_elem.childNodes[1]; // index of the 'tbody' of the selected element returned ( "LstMOSA".. )
        if (f!=undefined)
            f.removeChild(elem_deleted);

    }

    listModelsInfo(data){
        if (data.authorFullName != null)
            $("#mosaAuthor").text(data.authorFullName);
        else
            $("#mosaAuthor").text(" --- ");

        if (data.createDate != null)
            $("#mosaDate").text(data.createDate);
        else
            $("#mosaDate").text(" --- ");

        if (data.description)
            $("#mosaInfos").text(data.description);
        else
            $("#mosaInfos").text(" --- ");
    }

    everestCallbackError(data, status){
        console.log(data, status)
        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined) {
            //this.UI.createMessageErrorSU(error, data.responseJSON.errorMessage);
            if ($("#boxLogUpdate") == undefined) {
                this.UI.createMessageErrorSU("", data.responseJSON.errorMessage);
            } else {
                this.setProgressMessage(data.responseJSON.errorMessage);
                $("#boxLogUpdate").val("");
                var psconsole = $("#boxLogUpdate");
                if (psconsole.length)
                    psconsole.scrollTop(psconsole[0].scrollHeight - psconsole.height());
            }
        } else if (data.responseText != undefined) {
            if ($("#boxLogUpdate") == undefined) {
                this.UI.createMessageErrorSU("", data.responseText);
            } else {
                $("#boxLogUpdate").val(data.responseText);
                var psconsole = $('#boxLogUpdate');
                if (psconsole.length)
                    psconsole.scrollTop(psconsole[0].scrollHeight - psconsole.height());

                //            var NewposScroll = $("#boxLogUpdate").scrollTop();                                                |  ----------------------------------------------------------------------------------------------------------------
                //            if(boolBottomScroll==true){                                                                       |  ----------------------------------------------------------------------------------------------------------------
                //                $("#boxLogUpdate").scrollTop($("#boxLogUpdate").prop("scrollHeight"));                        |  ----------------------------------------------------------------------------------------------------------------
                //            } else {                                                                                          |  ----------------------------------------------------------------------------------------------------------------
                //                $("#boxLogUpdate").scrollTop(posScroll);                                                      |  ----------------------------------------------------------------------------------------------------------------
                //            }                                                                                                 |  ----------------------------------------------------------------------------------------------------------------
            }
        } else
            this.UI.createAlertSU("alert", "error", "ERROR");
    }
}
export default MosaUI;
