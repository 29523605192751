const AMCHARTS_PATH = `plugins/amcharts`;

export const AMCHARTS_GLOBAL_CONFIG = {
    path: AMCHARTS_PATH,
    export: {
        enabled: true,
        libs: {
            path: `${AMCHARTS_PATH}/plugins/export/libs/`,
        },
    },
};

