import CreateBox_CLS from "./createBox.js";
import DataTmpBind_CLS from "./model/cls_data_tmp.js";
import Simulating_ContextList_CLS from "./interface_context_list.js";
import Cavi2Util from "./helpers/cavi.util.js";
import "./helpers/jquery.selectRange.js";
import i18nextko from "i18next-ko/src/i18next-ko";
import brandLogo from "./assets/images/brand/logo_icon_only.png"
import logoSquare from "./assets/images/brand/logo_square.jpg"
import logoBW from "./assets/images/brand/logo_bw_no_bg.png"
import userGuideHref from "./assets/docs/user_guide.pdf";

/*

Class let us to manage UI easily
*/

var UI = function(Everest, JSON) {
    /*
        Enums
    */

    this.Panel = {
        HeadLeft: "HeadLeft",
        HeadRight: "HeadRight",
        Left: "Left"
    };
    this.TypeMenu = {
        Menu: "Menu",
        LiBT: "LiBT",
        LiCB: "LiCB",
        LiAHref: "LiAHref",
        BT: "BT",
        CB: "CB",
        DivDropDown: "DivDropDown",
        Img: "Img",
        Other: "Other"
    };

    // JSON file content containing all labels :fichier_libelle_texte.json
    // separated in 2 parts : labels on UI + alert/warning messages
    this.contentTxt = JSON;
    //this.contentTxt = Everest.contentJSON;
    this.accessContentJSON = this.contentTxt.libelle[0];
    this.accessMessageContentJSON = this.contentTxt.message_alert[0];

    // JSON file content for the update visibility of menus, according to the lelvel of permission allowed for the acocunt logged
    this.contentFileUserConfig = undefined;


    // all labels in the HTML file are filled thanks to knockOUt, it is dynamically modified with this process
    this.labels = {
        back: ko.observable(this.accessContentJSON.MENU_BUTTON_BACK),
        forgetPWD: ko.observable(this.accessContentJSON.MENU_LABEL_FORGOT_PWD),
        titleChooseMOSA: ko.observable(this.accessContentJSON.MENU_LABEL_CHOOSE_MOSA),
        error: ko.observable(this.accessContentJSON.MENU_LABEL_ERROR),
        login: ko.observable(this.accessContentJSON.MENU_BUTTON_LOGIN),
        titleForgotPWD: ko.observable(this.accessContentJSON.MENU_LABEL_FORGOT_PWD_PAGETITLE_PWD),
        loginForgotInput: ko.observable(this.accessContentJSON.MENU_LABEL_FORGOT_PWD_LOGINENTER),
        titleForgotLogin: ko.observable(this.accessContentJSON.MENU_LABEL_FORGOT_PWD_PAGETITLE_LOGIN),
        emailForgotInput: ko.observable(this.accessContentJSON.MENU_LABEL_FORGOT_PWD_EMAILENTER),
        sendBtn: ko.observable(this.accessContentJSON.MENU_BUTTON_SENDREQ),
        labelWelcome: ko.observable(this.accessContentJSON.MENU_LABEL_WELCOME),
        signoutBtn: ko.observable(this.accessContentJSON.MENU_BUTTON_SIGNOUT),
        editMOSABtn: ko.observable(this.accessContentJSON.MENU_BUTTON_EDIT_MOSA),
        editShapeBtn: ko.observable(this.accessContentJSON.MENU_BUTTON_EDIT_SHAPE),
        chooseEntry: ko.observable(this.accessContentJSON.MENU_LABEL_VALUE_DROPDOWN),
        labelDescription: ko.observable(this.accessContentJSON.MENU_NEW_DESCR_MOSA),
        labelAuthor: ko.observable(this.accessContentJSON.MENU_LABEL_AUTHOR),
        labelDate: ko.observable(this.accessContentJSON.MENU_LABEL_DATE),
        labelInfos: ko.observable(this.accessContentJSON.MENU_LABEL_INFOS),
        labelLayerName: ko.observable(this.accessContentJSON.MENU_LABEL_LAYER_NAME),
        labelLayerId: ko.observable(this.accessContentJSON.MENU_LABEL_LAYER_ID),
        labelLayerDescription: ko.observable(this.accessContentJSON.MENU_LABEL_LAYER_DESCRIPTION),
        enterMosaBtn: ko.observable(this.accessContentJSON.MENU_BUTTON_ENTER),
        labelWelcomeTitle: ko.observable(this.accessContentJSON.MENU_LABEL_WELCOME_TITLE),
        labelMOSATitle: ko.observable(this.accessContentJSON.MENU_LABEL_LIST_MOSA_TITLE),
        labelMID: ko.observable(this.accessContentJSON.MENU_LABEL_MID_MOSATABLE),
        labelGML: ko.observable(this.accessContentJSON.MENU_LABEL_GML_MOSATABLE),
        labelPubli: ko.observable(this.accessContentJSON.MENU_LABEL_PUBLISHED_MOSATABLE),
        labelDateUpdate: ko.observable(this.accessContentJSON.MENU_LABEL_UPDATE_DATE_MOSATABLE),
        newMOSABtn: ko.observable(this.accessContentJSON.MENU_NEW_MOSA),
        shapeViewBtn: ko.observable(this.accessContentJSON.MENU_SHAPE_VIEW),
        loadMOSABtn: ko.observable(this.accessContentJSON.MENU_LOAD_MOSA),
        linkShapeBtn: ko.observable(this.accessContentJSON.MENU_LINK_SHAPE),
        publishMOSABtn: ko.observable(this.accessContentJSON.MENU_PUBLISH_MOSA),
        uploadMOSABtn: ko.observable(this.accessContentJSON.MENU_UPLOAD_MOSA),
        upload3DBtn: ko.observable(this.accessContentJSON.MENU_UPLOAD_3D),
        deleteMOSABtn: ko.observable(this.accessContentJSON.MENU_DELETE_MOSA),
        newLayerBtn: ko.observable(this.accessContentJSON.MENU_NEW_LAYER),
        mosaViewBtn: ko.observable(this.accessContentJSON.MENU_MOSA_VIEW),
        publishLayerBtn: ko.observable(this.accessContentJSON.MENU_PUBLISH_LAYER),
        updateLayerBtn: ko.observable(this.accessContentJSON.MENU_UPDATE_LAYER),
        deleteLayerBtn: ko.observable(this.accessContentJSON.MENU_DELETE_LAYER),
        rClickNewSim: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_NEW),
        rClickShareFolder: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_SHARE_FOLDER),
        rClickDelFolder: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_DELETE_FOLDER),
        rClickPaste: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_PASTE),
        rClickRun: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_RUN),
        rClickDefSim: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_DEFINE),
        rClickCut: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_CUT),
        rClickCopy: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_COPY),
        rClickDel: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_DELETE),
        rClickContext: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_CONTEXT),
        rClickStrat: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_STRATEGY),
        rClickSaveStrat: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_SAVEASSTRATEGY),
        rClickInfos: ko.observable(this.accessContentJSON.MENU_LEFT_SIMULATING_CASES_RIGHT_INFOS),
        labelResultTitle: ko.observable(this.accessContentJSON.MENU_LEFT_ANALYSING_RESULTS),
        labelChart: ko.observable(this.accessContentJSON.MENU_LEFT_ANALYSING_RESULTS_GRAPH_CHART),
        labelTables: ko.observable(this.accessContentJSON.MENU_LEFT_ANALYSING_RESULTS_GRAPH_TABLES),
        labelAllTables: ko.observable(this.accessContentJSON.MENU_LEFT_ANALYSING_RESULTS_GRAPH_TABLESALL),
        labelBubbleChart: ko.observable(this.accessContentJSON.MENU_LEFT_ANALYSING_RESULTS_GRAPH_BUBBLE),
        labelPieChart: ko.observable(this.accessContentJSON.MENU_LEFT_ANALYSING_RESULTS_GRAPH_PIE),
        labelBarDiagram: ko.observable(this.accessContentJSON.MENU_LEFT_ANALYSING_RESULTS_GRAPH_BAR),
        labelMOSA: ko.observable(this.accessContentJSON.MENU_FOOTER_MODEL),
        labelSimu: ko.observable(this.accessContentJSON.MENU_FOOTER_SIMULATION),
        labelLostCo: ko.observable(this.accessContentJSON.MENU_LABEL_LOST_CONNECT),
        version: ko.observable(this.accessContentJSON.MENU_FOOTER_VERSION),
        v: ko.observable(this.accessContentJSON.MENU_FOOTER_VERSION_DETAIL),
        copyright: ko.observable(this.accessContentJSON.MENU_FOOTER_COPYRIGHT),
        year: ko.observable(this.accessContentJSON.MENU_FOOTER_COPYRIGHT_YEAR),
        rights: ko.observable(this.accessContentJSON.MENU_FOOTER_COPYRIGHT_TEXT),
        edf: ko.observable(this.accessContentJSON.MENU_FOOTER_COPYRIGHT_EDF)
    };

    // all images in HTML
    this.images = {
        brandLogo: ko.observable(brandLogo),
        logoSquare: ko.observable(logoSquare)
    }

    // height of the navbar used to et the top-position for the notification popup
    this.heightHeader = 0;

    this.checkUO = false;
    this.boolUpdatePublish = false;
    //Param
    this.Everest = Everest;
    this.GestionUrbObj = undefined; //Utile pour follow les bonnes fonction quand l'urban tree change de parent.
    this.GestionSimulations = undefined;

    //default class (Template) for all elements
    this.current_skin = "skin-blue";


    // instance of Simulating_ContextList_CLS and Analysing_TargetList_CLS
    // when user click on he menu list context or target we force the create box even if it was already created.
    // We do it because , if we are in SU and we add a context or target, we have the updated list
    this.CtxUpdate = undefined;
    this.TgtUpdate = undefined;

    //createBox  instance
    this.newBox = new CreateBox_CLS(this);
    // classBind instance : store all values in a temp location for used it in another classes
    this.classBindData = new DataTmpBind_CLS();
    this.classBindData.copyEverest = this.Everest;
    this.classBindData.selectIdEditSHAPE = null;

    // count the nb of boxes
    // used to test if there is 1 box open, we disabled move with keyboard
    // if no window : we enable movement
    this.nbBoxOpened = 0;

    //Gestion pour savoir ou est la box UrbanObject
    //Si null, elle est par defaut dans #urban_objects_default
    //Sinon on a l'objet suivant :
    //  - idParent : id de la box contenant la liste d'UO => utile pour check si visible ou non pour savoir si le bt "Urban Object" du menu principal à un effet ou non.
    //  - id : id de la div contenant la liste d'UO => Utile pour recup le html et le remettre ailleur
    // Duplicate the UO menu in some other boxes like Analysing->results
    this.defaultUO = {
        idParent: "box_urban_objects",
        id: "urban_objects_default"
    };
    this.urbanObj = this.defaultUO;


    // set true if list_targets has nexturl attributs != null
    this.nextUrl = false;
    //save data items corresponding at list items targets published
    this.saveData = null;
    // set true if SU update statut publish target (publish or unpublish)
    // used to recreate dropdown liste in Targeting interface if it is set true
    this.isUpdatedPubliTgt = false;

    // change to every 30 sec to check token and reduce the requests
    var Time_checkConn = setInterval(this.Test_Conn.bind(this), 30000);
    this.BoolConnLost = false;

    //this.socleTW3D = undefined;

    //boolean defined by the boollean in API Igowebgl. If the box opacity terrain i opened, we disable measures.
    this.BoolSubTerrainMode = false;


    // Build a menu (top and left). we had event when we click on these elements later.
    //
    this.Menu = {
        // Urban: {
        //     id: "Urban",
        //     name: this.accessContentJSON.MENU_TOP_CITY,
        //     tag: "MENU_TOP_CITY",
        //     type: this.TypeMenu.Menu,
        //     panel: this.Panel.HeadLeft,
        //     action: {
        //         isToogleDisplay: true,
        //         idItem: "box_urban_objects",
        //         canShow: function() {
        //             this.nbBoxOpened++;
        //             if (this.urbanObj.idParent != this.defaultUO.idParent) {
        //                 if ($("#" + this.urbanObj.idParent).is(":visible") == false) {
        //                     this.moveUOto();
        //                     return true;
        //                 } else {
        //                     return false;
        //                 }
        //             } else {
        //                 return true;
        //             }
        //         }
        //     },
        //     visibility: true,
        //     order: 0
        // },
        // Layers: {
        //     id: "Layers",
        //     name: this.accessContentJSON.MENU_TOP_LAYERS,
        //     tag: "MENU_TOP_LAYERS",
        //     type: this.TypeMenu.Menu,
        //     noClose: true,
        //     panel: this.Panel.HeadLeft,
        //     childs: {},
        //     visibility: true,
        //     order: 1
        // },
        // Spatial: {
        //     id: "Spatial",
        //     name: this.accessContentJSON.MENU_TOP_SPATIAL, //name: "Spatial",
        //     tag: "MENU_TOP_SPATIAL",
        //     type: this.TypeMenu.Menu,
        //     panel: this.Panel.HeadLeft,
        //     childs: {
        //         Distances: {
        //             id: "Distances",
        //             name: this.accessContentJSON.MENU_TOP_SPATIAL_DISTANCES, //name: "Distances",
        //             tag: "MENU_TOP_SPATIAL_DISTANCES",
        //             type: this.TypeMenu.LiBT /*Button*/,
        //             visibility: true,
        //             order: 0
        //         }
        //     },
        //     visibility: true,
        //     order: 2
        // },
        // Bookmark: {
        //     id: "Bookmark",
        //     name: this.accessContentJSON.MENU_TOP_BOOKMARK, // name: "3D Bookmark",
        //     tag: "MENU_TOP_BOOKMARK",
        //     type: this.TypeMenu.Menu,
        //     panel: this.Panel.HeadLeft,
        //     childs: {},
        //     visibility: true,
        //     order: 3
        // },
        // Tools: {
        //     id: "Tools",
        //     name: this.accessContentJSON.MENU_TOP_TOOLS,
        //     tag: "MENU_TOP_TOOLS",
        //     type: this.TypeMenu.Menu,
        //     panel: this.Panel.HeadLeft,
        //     childs: {
        //
        //         Aerial: {
        //             id: "Aerial",
        //             name: this.accessContentJSON.MENU_TOP_ANALYSIS_AERIAL,
        //             tag: "MENU_TOP_ANALYSIS_AERIAL",
        //             type: this.TypeMenu.LiBT /*Button*/,
        //             action: {
        //                 fct: function() {
        //
        //                     if (this.BoolSubTerrainMode == false) {
        //                         this.createBoxMeasure(function() {
        //                             this.classBindData.soclecopy.Api.Analyse.CancelMesure();
        //                         }.bind(this));
        //                         this.classBindData.soclecopy.Api.Analyse.MesureAerialDistance();
        //                     } else {
        //                         this.createAlert("alert", "warning", this.accessMessageContentJSON.MESSAGE_ALERT_CANT_USE_MEASURES);
        //                     }
        //
        //                 }.bind(this)
        //             },
        //             visibility: true,
        //             order: 0
        //         },
        //         Horizontal: {
        //             id: "Horizontal",
        //             name: this.accessContentJSON.MENU_TOP_ANALYSIS_HORIZ,
        //             tag: "MENU_TOP_ANALYSIS_HORIZ",
        //             type: this.TypeMenu.LiBT /*Button*/,
        //             action: {
        //                 fct: function() {
        //
        //                     if (this.BoolSubTerrainMode == false) {
        //                         this.createBoxMeasure(function() {
        //                             this.classBindData.soclecopy.Api.Analyse.CancelMesure();
        //                         }.bind(this));
        //
        //                         //this.classBindData.soclecopy.scene.Cls_Analyse.Cls_Measure_dist.Start_Measure("Horizontal");
        //                         this.classBindData.soclecopy.Api.Analyse.MesureHorizontalDistance();
        //                     } else {
        //                         this.createAlert("alert", "warning", this.accessMessageContentJSON.MESSAGE_ALERT_CANT_USE_MEASURES);
        //                     }
        //                 }.bind(this)
        //             },
        //             visibility: true,
        //             order: 1
        //         },
        //         Area: {
        //             id: "Area",
        //             name: this.accessContentJSON.MENU_TOP_ANALYSIS_AREA,
        //             tag: "MENU_TOP_ANALYSIS_AREA",
        //             type: this.TypeMenu.LiBT /*Button*/,
        //             action: {
        //                 fct: function() {
        //
        //                     if (this.BoolSubTerrainMode == false) {
        //                         this.createBoxMeasure(function() {
        //                             this.classBindData.soclecopy.Api.Analyse.CancelMesure();
        //                         }.bind(this));
        //
        //                         //this.classBindData.soclecopy.scene.Cls_Analyse.Cls_Measure_area.Start_Measure();
        //                         this.classBindData.soclecopy.Api.Analyse.MesureArea();
        //                     } else {
        //                         this.createAlert("alert", "warning", this.accessMessageContentJSON.MESSAGE_ALERT_CANT_USE_MEASURES);
        //                     }
        //                 }.bind(this)
        //             },
        //             visibility: true,
        //             order: 2
        //         },
        //         Volume: {
        //             id: "Volume",
        //             name: this.accessContentJSON.MENU_TOP_ANALYSIS_VOLUME,
        //             tag: "MENU_TOP_ANALYSIS_VOLUME",
        //             type: this.TypeMenu.LiBT /*Button*/,
        //             action: {
        //                 fct: function() {
        //                     if (this.BoolSubTerrainMode == false) {
        //                         this.createBoxMeasure(function() {
        //                             this.classBindData.soclecopy.Api.Analyse.CancelMesure();
        //                         }.bind(this));
        //                         //this.classBindData.soclecopy.scene.Cls_Analyse.Cls_Measure_volume.Start_Measure();
        //                         this.classBindData.soclecopy.Api.Analyse.MesureVolume();
        //                     } else {
        //                         this.createAlert("alert", "warning", this.accessMessageContentJSON.MESSAGE_ALERT_CANT_USE_MEASURES);
        //                     }
        //                 }.bind(this)
        //             },
        //             visibility: true,
        //             order: 3
        //         },
        //         Sun: {
        //             id: "Sun",
        //             name: this.accessContentJSON.MENU_TOP_TOOLS_SUN,
        //             tag: "MENU_TOP_TOOLS_SUN",
        //             type: this.TypeMenu.LiBT /*Button*/,
        //             action: {
        //                 fct: function() {
        //                     this.createBoxUpdateSun(function() {
        //
        //                     }.bind(this));
        //                     //add datepicker in the box
        //                     //datepicker_box
        //                     $("#datepicker_box").datepicker({
        //                         onSelect: function() {
        //                             $("#container_slider").css("display", "block");
        //                         },
        //                         changeMonth: true,
        //                         changeYear: true
        //                     });
        //                     $("#hourpicker_box").ionRangeSlider({
        //                         type: "single",
        //                         step: 1,
        //                         min: 0,
        //                         max: 23,
        //                         grid: true,
        //                         onChange: this.updateSunPosition.bind(this)
        //                     });
        //
        //                 }.bind(this)
        //             },
        //             visibility: true,
        //             order: 4
        //         },
        //         Subterrain: {
        //             id: "Subterrain",
        //             name: this.accessContentJSON.MENU_TOP_TOOLS_OPACIY,
        //             tag: "MENU_TOP_TOOLS_OPACIY",
        //             type: this.TypeMenu.LiBT /*Button*/,
        //             action: {
        //                 fct: function() {
        //                     this.createBoxOpacityTerrain(function() {
        //                         this.razOpacityTerrain();
        //                     }.bind(this));
        //                     $("#opacityTerrain_box").ionRangeSlider({
        //                         type: "single",
        //                         step: 0.1,
        //                         min: 0,
        //                         max: 1,
        //                         from: 1,
        //                         grid: false,
        //                         onFinish: this.updateTerainOpacity.bind(this)
        //                     });
        //                     var BoolSubTerrain = this.classBindData.soclecopy.Api.Terrain.GetModeSousTerrain();
        //                     if (BoolSubTerrain == false) {
        //
        //                         this.classBindData.soclecopy.Api.Terrain.SetModeSousTerrain(true);
        //                         BoolSubTerrain = true;
        //                     }
        //                     this.BoolSubTerrainMode = BoolSubTerrain;
        //                 }.bind(this)
        //             },
        //             visibility: true,
        //             order: 5
        //         },
        //         Boussole: {
        //             id: "Boussole",
        //             name: this.accessContentJSON.MENU_TOP_ACCESSORIES_BOUSSOLE, // name: "Boussole",
        //             tag: "MENU_TOP_ACCESSORIES_BOUSSOLE",
        //             type: this.TypeMenu.LiCB /*Checkbox*/,
        //             isChecked: true,
        //             fct: (value) => {
        //                 this.classBindData.soclecopy.Api.Window.Compass.SetVisibility(value);
        //             },
        //             visibility: false,
        //             order: 2
        //         },
        //         Minimap: {
        //             id: "Minimap",
        //             name: this.accessContentJSON.MENU_TOP_ACCESSORIES_MINIMAP, //name: "Minimap",
        //             tag: "MENU_TOP_ACCESSORIES_MINIMAP",
        //             type: this.TypeMenu.LiCB /*Checkbox*/,
        //             isChecked: true,
        //             fct: (value) => {
        //                 this.classBindData.soclecopy.Api.Window.Map2d.SetVisibility(value);
        //             },
        //             visibility: false,
        //             order: 6
        //         }
        //     },
        //     visibility: true,
        //     order: 6
        //
        // },
        // Contour: {
        //     id: "Contour",
        //     name: this.accessContentJSON.MENU_TOP_ANALYSIS_CONTOUR,
        //     tag: "MENU_TOP_ANALYSIS_CONTOUR",
        //     type: this.TypeMenu.LiBT /*Button*/,
        //     panel: this.Panel.HeadLeft,
        //     action: {
        //         fct: () => {
        //             //this.OnSelectBuildingByContour()
        //             //TODO4061 : display panel
        //             //$("#box_selectByContour").remove();
        //             // $("#box_selectByContour").toggle();
        //             //console.log("OnSelectBuildingByContour");
        //         }
        //     },
        //     postUpdate: () => {
        //         const elem = $(`#div_${this.Menu.Contour.id}`);
        //         const parent = elem.parent();
        //         elem.attr({
        //             "class": "dropdown-toggle",
        //             "data-toggle": "dropdown",
        //             "data-placeholder": false
        //         });
        //
        //         // workaround to inject component
        //         parent.append(`<ul id="div_Contour_child" class="dropdown-menu" data-bind='component: "contour-selection"'/>`);
        //     },
        //     isChecked: false,
        //     visibility: true,
        //     order: 7
        // },
        // DeselectAll: {
        //     id: "DeselectAll",
        //     name: this.accessContentJSON.MENU_TOP_ANALYSIS_DESELECT_ALL,
        //     tag: "MENU_TOP_ANALYSIS_DESELECT_ALL",
        //     type: this.TypeMenu.LiBT /*Button*/,
        //     panel: this.Panel.HeadLeft,
        //     action: {
        //         fct: () => {
        //             //console.log("DeselectAll");
        //             this.DeselectAll();
        //         }
        //     },
        //     isChecked: false,
        //     visibility: true,
        //     order: 7
        // },
        Task: {
            id: "Task",
            img: "fa-info",
            type: this.TypeMenu.Menu,
            panel: this.Panel.HeadRight,
            noClose: true,
            childs: {
                AllTask: {
                    id: "AllTask",
                    name: "AllTask",
                    type: this.TypeMenu.DivDropDown,
                    /*DIV*/
                    options: {
                        create: true,
                        width: "350px",
                        minHeight: "400px",
                        height: "50%"
                    }
                }
            },
            visibility: true,
            order: 8
        },
        Parameters: {
            id: "Parameters",
            img: "fa-bars",
            type: this.TypeMenu.Menu,
            panel: this.Panel.HeadRight,
            childs: {
                ChangeMOSA: {
                    id: "ChangeMOSA",
                    name: this.accessContentJSON.MENU_TOP_MOSA,
                    tag: "MENU_TOP_MOSA",
                    type: this.TypeMenu.LiBT /*Button*/,
                    action: {
                        fct: function() {
                            $("#mainpanel").hide();
                            /* $(".imgLogoFooter").show();
                             $("#loginbox").show();
                             $("#mosa_panel_usersimple").show();*/
                            window.location.replace(window.location.href.split("#")[0]);

                            // dispose all ko components
                            $("#layers-selection").remove();
                            $("#bundle-sharing").remove();

                        }.bind(this)
                    },
                    visibility: true,
                    order: 0
                },
                Lock: {
                    id: "Lock",
                    name: this.accessContentJSON.MENU_TOP_LOCK,
                    tag: "MENU_TOP_LOCK",
                    type: this.TypeMenu.LiBT /*Button*/,
                    visibility: true,
                    order: 1
                },
                Logout: {
                    id: "Logout",
                    name: this.accessContentJSON.MENU_TOP_LOGOUT,
                    tag: "MENU_TOP_LOGOUT",
                    type: this.TypeMenu.LiBT /*Button*/,
                    action: {
                        fct: function() {
                            //window.location.replace(window.location.href.split("#")[0]);/*location.reload();*/
                            cavi.User.Logout();
                        }
                    },
                    visibility: true,
                    order: 2
                },
                InfosUser: {
                    id: "InfosUser",
                    name: this.accessContentJSON.MENU_TOP_INFOS,
                    tag: "MENU_TOP_INFOS",
                    type: this.TypeMenu.LiBT,
                    /*Button*/
                    action: {
                        isToogleDisplay: true,
                        idItem: "box_profil"
                    },
                    visibility: true,
                    order: 3
                },
                UserGuide: {
                    id: "UserGuide",
                    name: this.accessContentJSON.MENU_TOP_GUIDE,
                    tag: "MENU_TOP_GUIDE",
                    type: this.TypeMenu.LiAHref /*Button*/,
                    url: `${userGuideHref}`,
                    visibility: true,
                    order: 4
                },
                DirectLink: {
                    id:"Permalink",
                    name: this.accessContentJSON.MENU_TOP_PERMALINK,
                    tag: "MENU_TOP_PERMALINK",
                    type: this.TypeMenu.LiBT /*Button*/,
                    action: {
                        fct: function() {
                            //FIXME: Working around ugly TW3D behaviour with hash
                            //alert(window.location.href);
                            alert(window.location.href.split("#")[0]+window.locationhash);
                        }
                    },
                    visibility: true,
                    order: 5
                }
            },
            visibility: true,
            order: 9
        },
        Logo: {
            id: "Logo",
            img: logoBW,
            panel: this.Panel.HeadRight,
            type: this.TypeMenu.Img,
            padding: "5px",
            visibility: true,
            order: 10
        },
        Simulating: {
            id: "Simulating",
            name: this.accessContentJSON.MENU_LEFT_SIMULATING,
            tag: "MENU_LEFT_SIMULATING",
            css: "defaultBG",
            type: this.TypeMenu.Menu,
            classBt: "bgSimulating", //Classe à appliquer pour la couleur du boutton
            skinBt: "skin-blue", //Classe à appliquer pour la couleur du skin
            panel: this.Panel.Left,
            action: {
                params: "Simulating",
                fct: function(params) {
                    this.SelectMenuLeft(params);
                }.bind(this),
                isOpen: true
            },
            childs: {
                SimulationCase: {
                    id: "SimulationCase",
                    name: this.accessContentJSON.MENU_LEFT_SIMULATING_CASE,
                    tag: "MENU_LEFT_SIMULATING_CASE",
                    type: this.TypeMenu.BT /*Button*/,
                    action: {
                        isToogleDisplay: true,
                        idItem: "box_simulation",
                        fct: function() {
                            this.nbBoxOpened++;
                            if (this.classBindData.soclecopy != undefined) {
                                //this.classBindData.soclecopy.scene.Cls_Controls.Set_KeyboardEnable(false);
                            }
                        }.bind(this)
                    },

                    visibility: true,
                    order: 0
                },
                ScenarioContext: {
                    id: "ScenarioContext",
                    name: this.accessContentJSON.MENU_LEFT_SIMULATING_SCENARIO,
                    tag: "MENU_LEFT_SIMULATING_SCENARIO",
                    type: this.TypeMenu.BT /*Button*/,
                    action: {
                        isToogleDisplay: true,
                        idItem: "box_Simulating_ContextList",
                        fct: function() {
                            if (this.classBindData.isChangePubliStateCtx == true) {
                                if (this.CtxUpdate == undefined) {
                                    this.CtxUpdate = new Simulating_ContextList_CLS(this.Everest, this);
                                    this.CtxUpdate.init();
                                } else {
                                    this.CtxUpdate = new Simulating_ContextList_CLS(this.Everest, this);
                                    this.CtxUpdate.init();
                                }
                                this.classBindData.isChangePubliStateCtx = false;
                            }
                            this.nbBoxOpened++;
                            if (this.classBindData.soclecopy != undefined) {
                                //this.classBindData.soclecopy.scene.Cls_Controls.Set_KeyboardEnable(false);
                            }
                            $("#box_Simulating_ContextList").show();
                        }.bind(this)
                    },
                    visibility: true,
                    order: 1
                }
            },
            visibility: true,
            order: 0
        },
        Analysing: {
            id: "Analysing",
            name: this.accessContentJSON.MENU_LEFT_ANALYSING,
            tag: "MENU_LEFT_ANALYSING",
            css: "analysing",
            type: this.TypeMenu.Menu,
            classBt: "bgAnalysing", //Classe à appliquer pour la couleur du boutton
            skinBt: "skin-green", //Classe à appliquer pour la couleur du skin
            panel: this.Panel.Left,
            action: {
                params: "Analysing",
                fct: function(params) {
                    this.SelectMenuLeft(params);
                }.bind(this)
            },
            childs: {
                ResultAnalysis: {
                    id: "ResultAnalysis",
                    name: this.accessContentJSON.MENU_LEFT_ANALYSING_RESULTS,
                    tag: "MENU_LEFT_ANALYSING_RESULTS",
                    type: this.TypeMenu.BT /*Button*/,
                    action: {
                        isToogleDisplay: true,
                        idItem: "box_analysing_Result",
                        canShow: function() {
                            // reprise du ztree urban obj
                            if (this.urbanObj.idParent != "box_analysing_Result") {
                                this.moveUOto({
                                    idParent: "box_analysing_Result",
                                    id: "uo_analysing_Result"
                                }, true);
                            }
                            this.nbBoxOpened++;
                            if (this.classBindData.soclecopy != undefined) {
                                //this.classBindData.soclecopy.scene.Cls_Controls.Set_KeyboardEnable(false);
                            }
                            return true;
                        }.bind(this)
                    },
                    visibility: true,
                    order: 0
                },
                Targeting: {
                    id: "Targeting",
                    name: this.accessContentJSON.MENU_LEFT_ANALYSING_KPI_EVALUATION,
                    tag: "MENU_LEFT_ANALYSING_KPI_EVALUATION",
                    type: this.TypeMenu.BT /*Button*/,
                    action: {
                        isToogleDisplay: true,
                        idItem: "box_analysing_Targeting",
                        fct: function() {
                            // reprise du ztree urban obj
                            if (this.urbanObj.idParent != "box_analysing_Targeting") {
                              this.moveUOto({
                                idParent: "box_analysing_Targeting",
                                id: "uo_analysing_Targeting"
                              }, true);
                            }
                            if (this.isUpdatedPubliTgt == true) {
                                // test liste deroulante != vide
                                if (document.getElementById("analysing_Targeting_select").options.length > 0) {
                                    this.Everest.List_Targets(null, this.updateSelectTarget.bind(this), function(data, s, p) {
                                        if (data.responseJSON != undefined)
                                            this.createMessageError(data.responseJSON.error, data.responseJSON.errorMessage);
                                        else if (data.responseText != undefined)
                                            this.createMessageError("", data.responseText);
                                        else
                                            this.createAlert("alert", "error", "ERROR");
                                    }.bind(this));
                                }
                            }
                            this.nbBoxOpened++;
                            if (this.classBindData.soclecopy != undefined) {
                                //this.classBindData.soclecopy.scene.Cls_Controls.Set_KeyboardEnable(false);
                            }
                        }.bind(this)
                    },
                    visibility: true,
                    order: 1
                },
                TargetList: {
                    id: "TargetList",
                    name: this.accessContentJSON.MENU_LEFT_ANALYSING_KPI_TARGETS,
                    tag: "MENU_LEFT_ANALYSING_KPI_TARGETS",
                    type: this.TypeMenu.BT /*Button*/,
                    action: {
                        isToogleDisplay: true,
                        idItem: "box_Analysing_TargetList",
                        fct: function() {
                            if (this.classBindData.isChangePubliStateTgt == true) {
                                if (this.TgtUpdate == undefined) {
                                    this.TgtUpdate = new Analysing_TargetList_CLS(this.Everest, this);
                                    this.TgtUpdate.init();
                                } else {
                                    this.TgtUpdate = new Analysing_TargetList_CLS(this.Everest, this);
                                    this.TgtUpdate.init();
                                }


                                this.classBindData.isChangePubliStateTgt = false;
                            }

                            this.nbBoxOpened++;
                            if (this.classBindData.soclecopy != undefined) {
                                //this.classBindData.soclecopy.scene.Cls_Controls.Set_KeyboardEnable(false);
                            }

                            $("#box_Analysing_TargetList").show();
                        }.bind(this)
                    },
                    visibility: true,
                    order: 2
                },
                Ranking: {
                    id: "Ranking",
                    name: this.accessContentJSON.MENU_LEFT_ANALYSING_RANKING,
                    tag: "MENU_LEFT_ANALYSING_RANKING",
                    type: this.TypeMenu.BT /*Button*/,
                    action: {
                        isToogleDisplay: true,
                        idItem: "box_analysing_Ranking",
                        canShow: function() {
                            // reprise du ztree urban obj
                            if (this.urbanObj.idParent != "box_analysing_Ranking") {
                                var treeUO = $.fn.zTree.getZTreeObj("tree_URB");
                                this.moveUOto({
                                    idParent: "box_analysing_Ranking",
                                    id: "uo_analysing_Ranking"
                                }, true);
                            }
                            this.nbBoxOpened++;
                            if (this.classBindData.soclecopy != undefined) {
                                //this.classBindData.soclecopy.scene.Cls_Controls.Set_KeyboardEnable(false);
                            }
                            return true;
                        }.bind(this)
                    },

                    visibility: true,
                    order: 3
                }
            },
            visibility: true,
            order: 1
        },
        // SuperUser: {
        //     id: "SuperUser",
        //     name: this.accessContentJSON.MENU_LEFT_SUPER_USER,
        //     tag: "MENU_LEFT_SUPER_USER",
        //     css: "superuser",
        //     type: this.TypeMenu.Menu,
        //     classBt: "bgSuperUser", //Classe à appliquer pour la couleur du boutton
        //     skinBt: "skin-yellow", //Classe à appliquer pour la couleur du skin
        //     panel: this.Panel.Left,
        //     action: {
        //         params: "SuperUser",
        //         fct: function(params) {
        //             this.SelectMenuLeft(params);
        //         }.bind(this)
        //     },
        //     childs: {
        //         ManageScenario: {
        //             id: "ManageScenario",
        //             name: this.accessContentJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT,
        //             tag: "MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT",
        //             type: this.TypeMenu.BT,
        //             /*Button*/
        //             action: {
        //                 isToogleDisplay: true,
        //                 idItem: "box_SU_ManageScenarioContext",
        //                 fct: function() {
        //                     this.nbBoxOpened++;
        //                     if (this.classBindData.soclecopy != undefined) {
        //                         //this.classBindData.soclecopy.scene.Cls_Controls.Set_KeyboardEnable(false);
        //                     }
        //                 }.bind(this)
        //             },
        //             visibility: false,
        //             order: 0
        //         },
        //         ManageTarget: {
        //             id: "ManageTarget",
        //             name: this.accessContentJSON.MENU_LEFT_SUPER_USER_TARGET,
        //             tag: "MENU_LEFT_SUPER_USER_TARGET",
        //             type: this.TypeMenu.BT /*Button*/,
        //             visibility: false,
        //             order: 1,
        //             action: {
        //                 isToogleDisplay: true,
        //                 idItem: "box_SU_ManageTarget",
        //                 fct: function() {
        //                     this.nbBoxOpened++;
        //                     if (this.classBindData.soclecopy != undefined) {
        //                         //this.classBindData.soclecopy.scene.Cls_Controls.Set_KeyboardEnable(false);
        //                     }
        //                 }.bind(this)
        //             }
        //         }
        //     },
        //     needSuperUser: true /*Prit en compte que si l'utilisateur est un super user*/,
        //     visibility: false,
        //     order: 2
        // }
    };


    this.updateTerainOpacity = function(data) {
        this.classBindData.soclecopy.Api.Terrain.SetOpacity(data.from);
    };


    this.razOpacityTerrain = function() {
        this.classBindData.soclecopy.Api.Terrain.SetModeSousTerrain(false);
        this.BoolSubTerrainMode = false;
        this.classBindData.soclecopy.Api.Terrain.SetOpacity(1);
    };


    this.updateSunPosition = function(data) {
        //get position camera
        //reproject via API TW3D
        //var pos = this.classBindData.soclecopy.scene.Cls_Controls.GetCameraPos();

        var pos = this.classBindData.soclecopy.scene.api.Navigate.GetPosition();
        var longlatObj = this.classBindData.soclecopy.scene.api.CoordService.Reproject(pos);


        var day = $("#datepicker_box").val(); // MM/DD/YYYY
        var hour = $("#hourpicker_box").val(); // hour


        var d = new Date(day);
        d.setHours(hour);


        var Lat = longlatObj.lat;
        var Long = longlatObj.lon;


        if ((day != undefined && day != null) && (hour != undefined && hour != null)) {
            //get current long and lat
            // get position of the sun (azimuth and altitude) at today's sunrise
            /*suncalc: Returns an object with the following properties:

            altitude: sun altitude above the horizon in radians, e.g. 0 at the horizon and PI/2 at the zenith (straight over your head)
            azimuth: sun azimuth in radians (direction along the horizon, measured from south to west), e.g. 0 is south and Math.PI * 3/4 is northwest
            */
            var sunrisePos = SunCalc.getPosition(d, Lat, Long);
            var alt_deg = (sunrisePos.altitude * 180) / (Math.PI);
            var az_deg = (sunrisePos.azimuth * 180) / (Math.PI);


            //  sunrisePos.azimuth = (sunrisePos.azimuth - Math.PI / 4) / Math.PI;
            // sunrisePos.altitude = (sunrisePos.altitude - Math.PI / 2) * (-Math.PI);
            //sunrisePos.azimuth += Math.PI;

            var alt_deg = (sunrisePos.altitude * 180) / (Math.PI);
            var az_deg = (sunrisePos.azimuth * 180) / (Math.PI);


            var Norm_suncal_azi = 0;
            var Norm_shadersky_azi = 0;
            var Decal_suncal_azi = 0;
            var Decal_shadersky_azi = 0;

            var Norm_suncal_alt = 0;
            var Norm_shadersky_alt = 0;
            var Decal_suncal_alt = 0;
            var Decal_shadersky_alt = 0;

            var coeff = 1;
            /*
            if (sunrisePos.azimuth <= 0 && sunrisePos.azimuth > -Math.PI / 2) //&& sunrisePos.altitude >=0
            {
                coeff = -1;
                sunrisePos.azimuth+=-Math.PI / 2;
            }
            else if (sunrisePos.azimuth <= Math.PI / 2 && sunrisePos.azimuth > 0)
            {
                coeff = -1;
                sunrisePos.azimuth+= Math.PI / 2;
            }*/
            if ((sunrisePos.azimuth <= 0 && sunrisePos.azimuth > -Math.PI / 2) || (sunrisePos.azimuth <= Math.PI / 2 && sunrisePos.azimuth > 0)) {
                Norm_suncal_alt = Math.PI / 2 - 0;
                Norm_shadersky_alt = 0 - 0.5;
                Decal_suncal_alt = 0;
                Decal_shadersky_alt = 0.5;
            } else {
                Norm_suncal_alt = Math.PI / 2 - 0;
                Norm_shadersky_alt = 1 - 0.5;
                Decal_suncal_alt = 0;
                Decal_shadersky_alt = 0.5;
            }

            /*
            else if(((sunrisePos.azimuth <= -Math.PI / 2 && sunrisePos.azimuth >-Math.PI) && (sunrisePos.azimuth <= Math.PI && sunrisePos.azimuth > Math.PI/2)) && sunrisePos.altitude >=0)
            {
                  coeff = -1;
            }*/
            /*

                 if (sunrisePos.altitude <= Math.PI / 2 && sunrisePos.altitude > 0) {

                     Norm_suncal_alt = Math.PI / 2 - 0;
                     Norm_shadersky_alt = 0 - 0.5;
                     Decal_suncal_alt = 0;
                     Decal_shadersky_alt = 0.5;
                 }
                 else if (sunrisePos.altitude >= -Math.PI / 2 && sunrisePos.altitude < 0) {
                     Norm_suncal_alt =  0 - (-Math.PI / 2);
                     Norm_shadersky_alt = 0.5 -1;
                     Decal_suncal_alt = -Math.PI / 2;
                     Decal_shadersky_alt = 1;
                 }
                 */

            if (sunrisePos.azimuth <= 0 && sunrisePos.azimuth >= -Math.PI) {

                Norm_suncal_azi = -Math.PI - 0;
                Norm_shadersky_azi = 0.75 - 0.25;
                Decal_suncal_azi = 0;
                Decal_shadersky_azi = 0.25;

            } else if (sunrisePos.azimuth >= 0 && sunrisePos.azimuth < Math.PI / 2) {
                Norm_suncal_azi = Math.PI / 2 - 0;
                Norm_shadersky_azi = 0 - 0.25;
                Decal_suncal_azi = 0;
                Decal_shadersky_azi = 0.25;
            } else if (sunrisePos.azimuth >= Math.PI / 2 && sunrisePos.azimuth < Math.PI) {

                Norm_suncal_azi = Math.PI - Math.PI / 2;
                Norm_shadersky_azi = 0.75 - 1;
                Decal_suncal_azi = Math.PI / 2;
                Decal_shadersky_azi = 1;
            }


            var Shader_sky_azi = (((sunrisePos.azimuth - Decal_suncal_azi) / Norm_suncal_azi) * Norm_shadersky_azi + Decal_shadersky_azi);
            var Shader_sky_alti = (((sunrisePos.altitude - Decal_suncal_alt) / Norm_suncal_alt) * Norm_shadersky_alt + Decal_shadersky_alt);
            /*
            if (coeff < 0) {
                Shader_sky_alti = 1 - Shader_sky_alti;
                Shader_sky_azi = 1 - Shader_sky_azi;
            }
            */

            //return azimuth and altitude of sun
            //accéder à  sky de scene.js
            this.classBindData.soclecopy.Api.Analyse.SetSunPosition(Shader_sky_alti, Shader_sky_azi);

        } else {
            this.createAlert("alert", "error", this.accessMessageContentJSON.MESSAGE_ALERT_MUST_SELECT_DATE);
        }
    };


    //Initialise menu .
    this.initMenu = function() {
        // Loop on each menus
        if (this.Menu == null) {
            return -1;
        }
        for (var idMenu in this.Menu) {
            if (this.Menu[idMenu] != null) {

                if (this.Menu[idMenu].visibility == true)
                    this.appendHtml("div_" + this.Menu[idMenu].panel, this.Menu[idMenu]);
            }
        }
    };

    /*
    Fct called by selecting Targeting tab and if user updated a target
    it remove the select list, and recreate it
    called after List_target() request
    */
    this.updateSelectTarget = function(data, status, param) {
        this.isUpdatedPubliTgt = false;
        if (this.nextUrl == true) {
            for (var element in data.items) {
                if (data.items.hasOwnProperty(element)) {
                    this.saveData.items.push(data.items[element]);
                }

            }
        } else {
            this.saveData = null;
            this.saveData = data;
        }


        if (data.nextUrl != null) {
            $("#analysing_Targeting_select").empty();
            $("#analysing_Targeting_select").append("<option value=\"-1\">None</option>");
            this.nextUrl = true;
            this.Everest.getNextUrl(data.nextUrl, param, this.updateSelectTarget.bind(this), function(data, s, p) {
                if (data.responseJSON != undefined)
                    this.createMessageError(data.responseJSON.error, data.responseJSON.errorMessage);
                else if (data.responseText != undefined)
                    this.createMessageError("", data.responseText);
                else
                    this.createAlert("alert", "error", "ERROR");
            }.bind(this));
        } else {
            if (this.nextUrl == false) {
                //  this.saveData = null;
                $("#analysing_Targeting_select").empty();
                $("#analysing_Targeting_select").append("<option value=\"-1\">None</option>");
            }
            for (var i = 0; i < this.saveData.items.length; i++) {
                //if (this.saveData.items[i].published == true) {
                //Solve #3034 : CAVI hides unpublished targets for spidergraph where as superuser should see them.
                $("#analysing_Targeting_select").append("<option value=\"" + this.saveData.items[i].id + "\" title=\"" + this.saveData.items[i].description + "\">" + this.saveData.items[i].name + "</option>");
                //}
            }
            $("#analysing_Targeting_select_Loading").remove();
            this.nextUrl = false;
        }
    };


    //build the html according ti params e
    this.appendHtml = function(idParent, item) {
        if (item.id !== undefined) {

            let idDiv = "div_" + item.id;
            let css = "";
            if (item.css !== undefined) {
                css = item.css; //Force css, else it change with the skin theme
            }

            let html = "<div id='" + idDiv + "' class='" + css + "'>";
            let content = "";
            if (item.img !== undefined) {
                if (item.img.toLowerCase().indexOf("fa-") === 0) {
                    content = `<i class='fa ${item.img}'></i>`;
                } else {
                    let padding = item.padding !== undefined ? item.padding : "";
                    content = `<img src='${item.img}' alt='${item.id}' style='height:50px; padding:${padding}'>`;
                }
            }

            if (item.name !== undefined) {
                // content += item.name;
                content = item.tag && i18nextko.i18n.exists(item.tag, { ns: "menu" }) ? i18nextko.i18n.t(item.tag, { ns: "menu" }) : item.name;
            }

            let dataBind = item.tag ? `i18n:{text: { key: 'menu:${item.tag}'}}` : "";

            /*
            if (item.type=="CB") {
                content = '<label><input type="checkbox" id="' + idDiv + '_cb" value="value"> ' + content + '</label>';
            }*/

            if (item.panel !== undefined) {
                if (item.panel === this.Panel.HeadLeft || item.panel === this.Panel.HeadRight) {
                    if (item.childs !== undefined) {
                        let noClose = item.noClose !== undefined && item.noClose === true ? " noclose" : "";

                        html = `<li class="dropdown tasks-menu" >` +
                            `<a href="#" class="dropdown-toggle" data-toggle="dropdown" data-placeholder="false" id="${idDiv}" data-bind="${dataBind}">${content}</a>` +
                            `<ul id="${idDiv}_child" class="dropdown-menu ${noClose}" style="height:auto; width:auto"></ul>` +
                            `</li>`;
                    } else {
                        if (item.type === this.TypeMenu.Img) {
                            html = `<li id="${idDiv}">${content}</li>`;
                        } else {
                            html = `<li class="dropdown tasks-menu"><a href="#" id="${idDiv}" data-bind="${dataBind}">${content}</a></li>`;
                        }
                    }
                } else if (item.panel === this.Panel.Left) {
                    //Item Menu Gauche
                    let superUserStyle = item.needSuperUser !== undefined && item.needSuperUser === true ? "isSuperUser" : "";
                    let isOpenStyle = item.action !== undefined && item.action.isOpen !== undefined && item.action.isOpen === true ? "" : "none";

                    // btn-lg has been remove, since it is invalid
                    html = `<div style="margin-top:10px;" id="div_${item.id}_${item.classBt}" class="${superUserStyle}">` +
                        `<button class="btn btn-block btn-${item.classBt}" id="${idDiv}" data-bind="${dataBind}">${content}</button>` +
                        `<ul id="${idDiv}_child" style="margin-top:4px; display:${isOpenStyle}"></ul>` +
                        `</div>`;
                }
            } else {
                var li_width = 158;
                var ul_width = li_width + 10;

                if (item.type === this.TypeMenu.LiCB) {
                    if (item.childsLayer !== undefined && item.childsLayer.length > 0) {
                        html = "<li class='use-skin' id='li_sub_" + item.id + "'><input type='checkbox' id='" + idDiv + "_cb' class='" + css + "' name='" + idDiv + "_cb' value='VALUE'><label  for='" + idDiv + "_cb'>" + item.name + "</label></li>";

                        $("#div_" + item.idParent + "_child").append(html);
                    } else {

                        html = `<li class='use-skin' id='li_sub_${item.id}'>` +
                            `<input type='checkbox' id='${idDiv}_cb' class='' name='${idDiv}_cb' value='VALUE'>` +
                            `<label for='${idDiv}_cb' data-bind="${dataBind}">${content}</label>` +
                            `</li>`;
                    }

                } else if (item.type === this.TypeMenu.LiBT) {
                    if (item.id.indexOf("pdv") > -1) {
                        html = "<li class=\"use-skin\"><a href=\"#\" style=\"color:#ffffff; width:" + li_width + "px;height:26px;padding-left:0;position:absolute;\" id=\"" + idDiv + "\"><div class=\"testLink\">" + content + "</div></a><div id=\"del_" + idDiv + "\" style=\"position:relative;width:15px;margin-left:160px;\"><a href=\"#\" style=\"color:#ffffff;\" alt=\"Delete POV\"><i class=\"fa fa-close\"></i></a></div></li>";

                    } else {
                        html = `<li class="use-skin" id="li_sub_${item.id}">` +
                            `<a href="#" style="color:#ffffff; width:158px;height:26px;padding-left:0;" id="${idDiv}">` +
                            `<div class="testLink" data-bind="${dataBind}">${content}</div>` +
                            `</a>` +
                            `</li>`;
                    }
                } else if (item.type === this.TypeMenu.BT) {
                    let idP = idParent.replace("div_", "").replace("_child", "");
                    let btnClass = this.Menu[idP] !== undefined && this.Menu[idP].classBt !== undefined ? "btn-" + this.Menu[idP].classBt : "";

                    // btn-sm has been removed, since it is invalid
                    html = `<button class="btn btn-block ${btnClass}" id="${idDiv}" style="margin-left:15%; width:85%" data-bind="${dataBind}">${content}</button>`;

                } else if (item.type === this.TypeMenu.DivDropDown) {
                    if (item.options !== undefined && item.options.create !== undefined && item.options.create === true) {
                        html = "<li class=\"use-skin\"><div id=\"" + idDiv + "\" style=\"" +
                            (item.options.width !== undefined ? "width:" + item.options.width + "; " : "") +
                            (item.options.minHeight !== undefined ? "min-height:" + item.options.minHeight + "; " : "") +
                            (item.options.height !== undefined ? "height:" + item.options.height + "; " : "") +
                            "\"><div class=\"header\">" + (item.options.header !== undefined ? item.options.header : "") + "</div></div></li>";

                    }
                } else if (item.type === this.TypeMenu.LiAHref) {
                    if (item.url !== undefined) {
                        html = `<li class="use-skin" id="li_sub_${item.id}">` +
                            `<a href="${item.url}" target="_blank" style="color:#ffffff;width:${li_width}px;height:26px;padding-left:0;" id="${idDiv}">` +
                            `<div class="testLink" data-bind="${dataBind}">${content}</div>` +
                            `</a>` +
                            `</li>`;
                    }
                } else if (item.type === this.TypeMenu.Other) {
                    html = `<li class="use-skin" id="li_sub_${item.id}">` +
                        `<label style="color:#ffffff; width:158px;height:26px;padding-left:0;" id="${idDiv}">` +
                        `<div class="testLink" data-bind="${dataBind}">${content}</div>` +
                        `</label>` +
                        `</li>`;
                }
            }
            if (item.idParent === undefined) {
                $("#" + idParent).append(html); //Rajoute l'élément au parent
            }

            if (item.childs !== undefined) {
                for (var id in item.childs) {
                    this.appendHtml(idDiv + "_child", item.childs[id]);
                }
            }

            if (item.action !== undefined) {
                if (item.action.isToogleDisplay !== undefined && item.action.idItem !== undefined && item.action.isToogleDisplay === true) {
                    this.setEventToogleDisplay(idDiv, item.action.idItem, (item.action.canShow !== undefined ? item.action.canShow : null));
                }
                if (item.action.fct !== undefined) {
                    $("#" + idDiv).click(function() {
                        item.action.fct((item.action.params != undefined ? item.action.params : undefined));
                    });
                }
            }

            if (item.type === this.TypeMenu.LiCB || item.type === this.TypeMenu.CB) {

                // Add the fct on onChange event
                if (item.fct !== undefined) {
                    $("#" + idDiv + "_cb").change(function() {
                        item.fct($(this).is(":checked"));
                    });
                    if (item.isChecked !== undefined && item.isChecked === true) {
                        $("#" + idDiv + "_cb").prop("checked", true);
                    }
                }
            }

            if (item.id.indexOf("pdv") > -1) {
                var saveEverest = this.Everest;
                var self = this;
                $("#del_" + idDiv).click(function() {
                    saveEverest.Delete_Camera_Viewpoints(self.classBindData.mosaID, item.name, function() {
                        // empty POV list
                        // then create it again with the update
                        self.createAlert("notif", "success", self.accessMessageContentJSON.MESSAGE_ALERT_POV_DEL);
                        $("#div_Bookmark_child").empty();
                        self.Fct_rebuildPOV(self.classBindData.soclecopy);
                    });
                });
            }

            this.heightHeader = $("#header_id").css("height");

            if(_.isFunction(item.postUpdate)) {
                item.postUpdate();
            }
        }


    };


    // add an item on the table after initialisation of the class
    this.addItem = function(obj, idParent) {

        if (this.Menu == undefined) {
            this.Menu = {};
        }
        if (this.Menu[idParent] == undefined) {
            this.Menu[idParent] = {
                id: idParent,
                type: this.TypeMenu.Menu,
                childs: {}
            };
        }
        if (this.Menu[idParent].childs == undefined) {
            this.Menu[idParent].childs = {};
        }
        this.Menu[idParent].childs[obj.id] = obj;
        this.appendHtml("div_" + idParent + "_child", this.Menu[idParent].childs[obj.id]);
    };


    // Auto manage show/hide on table items
    this.setEventToogleDisplay = function(idBt, idDiv, canShow) {
        /*
        //Rajoute les event sur les éléments gérés par UI
        $("#" + idDiv).draggable({
            containment: '#mainContent',
            cursor: 'move',
            snap: '#mainContent', handle: "#h_" + idDiv
        }).resizable({ minHeight: 250, minWidth: 475 });

        $("#bt_h_" + idDiv).on("click", function () {
            var options = { to: "#" + idBt, className: "ui-effects-transfer" };
            $("#" + idDiv).fadeOut(200).effect("transfer", options, 500).dequeue();
        });
        */
        if (canShow != null) {

            $("#" + idBt).on("click", {
                fctCanShow: canShow.bind(this)
            }, function(event) {
                if (event.data.fctCanShow() == true) {
                    if ($("#" + idDiv).is(":visible") == false) {
                        var options = {
                            to: "#" + idDiv,
                            className: "ui-effects-transfer-open"
                        };
                        $("#" + idBt).effect("transfer", options, 300).dequeue();
                      $("#" + idDiv).css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);
                        //Zindex = Zindex + 1;
                        $("#" + idDiv).fadeIn(600);
                    } else {
                        var options = {
                            to: "#" + idBt,
                            className: "ui-effects-transfer"
                        };
                        $("#" + idDiv).fadeOut(200).effect("transfer", options, 500).dequeue();
                    }
                }
            }.bind(this));

        } else {

            $("#" + idBt).on("click", function() {
                if ($("#" + idDiv).is(":visible") == false) {
                    var options = {
                        to: "#" + idDiv,
                        className: "ui-effects-transfer-open"
                    };
                    $("#" + idBt).effect("transfer", options, 300).dequeue();
                  $("#" + idDiv).css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);
                    //Zindex = Zindex + 1;
                    $("#" + idDiv).fadeIn(600);
                } else {
                    var options = {
                        to: "#" + idBt,
                        className: "ui-effects-transfer"
                    };
                    $("#" + idDiv).fadeOut(200).effect("transfer", options, 500).dequeue();
                }
            });
        }
    };

  this.setZIndexToMax = function (idDiv) {
    Zindex = Cavi2Util.getMaxZofClass("zdynamic") + 1;
    $("#" + idDiv).css("zIndex", Zindex);
  };

  this.setZIndexToMaxAsync = function (idDiv) {
        setTimeout(() => { this.setZIndexToMax(idDiv);}, 10);
  };
    //Manage the left menu  (selection + modif du template)
    this.SelectMenuLeft = function(idSelect) {
        for (var id in this.Menu) {
            if (this.Menu[id].panel != undefined && this.Menu[id].panel == this.Panel.Left) {
                if (id == idSelect) {
                    if (this.Menu[id].skinBt != undefined) {
                        this.SetClsBG(this.Menu[id].skinBt);
                    }
                    $("#div_" + id + "_child").show("blind", {}, "slow").dequeue();
                } else {
                    $("#div_" + id + "_child").hide("blind", {}, "slow").dequeue();
                    $("." + id).hide(300).dequeue();
                }
            }
        }
        this.moveUOto(null);
        $(".Others").hide(300).dequeue(); //Si besoin de masquer les panels n'étant pas dans le menu de gauche

    };

    //Template switcher
    this.SetClsBG = function(skinName) {
        /*if (this.currentBG != newBG) {
            $(".BG").removeClass(this.currentBG).addClass(newBG);
            this.currentBG = newBG;
        }*/
        //Skin switcher
        $("body").removeClass(this.current_skin);
        $("body").addClass(skinName);
        this.current_skin = skinName;
    };

    //Creer une box en fct des option passées (draggable, resizable,...)
    /*
       ^
    // ! \\ Tous les paramètres sont optionnels
    =======
    options:{
        draggable:true,
        group:"idDuGroupe", //Permet de masquer tous les éléments du groupe lorsque l'on clique sur un titre du menu gauche
        resizable:{},//...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
        useSkin:true,
        css:"",//Utile si useSkin=false
        title:"",
        content:"",
        visible:true,
        menuBt:{
            reduceTo:"idItem",//ID vers qui on doit réduire la div
            close:true,//Fermer sans id si pas de reduceTo
            fullscreen:true,//pouvoir passer la box en fullscreen
        },
        style:{//Constient tous les styles à appliquer avec en id le nom du style
            position:"",
            top:"",
            left:"",
            right:"",
            bottom:""
            width:"",
            height:"",
            min-width:"",
            min-height:"",
            max-width:"",
            max-height:""
            //n'importe qu'elle autre balise css...
        }
    }
    */


    /*
        fct creating boxes, thanks to the HTML content we created and some options css
        Called from createBox.js
    */
    this.CreateBox = function(id, options, CallBackClose) {
        if (this.classBindData.soclecopy != undefined) {
            //this.classBindData.soclecopy.scene.Cls_Controls.Set_KeyboardEnable(false);
        }

        var namePanel = "";
        var nameContent = "";

        this.nbBoxOpened++;

        // box title (use data bind for i18n)
        let titleDataBind = options.tag?`i18n:{text:{key:'menu:${options.tag}'}}`:"";

        // if we are creating box depending MOSA management, we must add these boxes in 'loginbox' and not in 'mainContent'
        if (id == "newMosa" || id == "updateMosa" || id == "updateMosaSelect" || id == "LinkLayer" || id == "createLayer" || id == "create3DObjectsLayer" || id == "createPolygonsLayer" || id == "createPolylinesLayer" || id == "createLabelsLayer") {
            namePanel = "#loginbox";
            nameContent = "#loginbox";
        } else {
            namePanel = "#mainpanel";
            nameContent = "#mainContent";
        }

        var html = "<div tabindex='-1' id=\"box_" + id + "\" class=\"box box-solid zdynamic" + (options.useSkin != undefined && options.useSkin == true ? " box-primary" : (options.css != undefined ? " " + options.css : "")) + (options.group != undefined ? " " + options.group : "") + "\" style=\"";
        if (options.style != undefined) {
            for (var idStyle in options.style) {
                html += idStyle + ":" + options.style[idStyle] + ";";
            }
        }
        html += (options.visible != undefined && options.visible == true ? "" : " display:none;") + "\">";
        html += "<div class=\"box-header with-border\" id=\"box_header_" + id + "\">";
        html += `   <h3 id="h3_header_${id}" class="box-title" data-bind="${titleDataBind}">` + (options.title != undefined ? options.title : "&nbsp;") + "</h3>";
        if (options.menuBt != undefined) {
            html += "   <div class=\"pull-right\">";
            if (options.menuBt.fullscreen != undefined) {
                html += "<button id=\"bt_fullscreen_" + id + "\" class=\"btn btn-box-tool\"><i class=\"fa fa-expand\"></i></button>";
            }
            if (options.menuBt.reduceTo != undefined) {
                html += "<button id=\"bt_reduce_" + id + "\" class=\"btn btn-box-tool\"><i class=\"fa fa-close\"></i></button>";
            } else if (options.menuBt.close != undefined && options.menuBt.close == true) {
                html += "<button id=\"bt_close_" + id + "\" class=\"btn btn-box-tool\"><i class=\"fa fa-close\"></i></button>";
            }
            html += "</div>";
        }
        html += "   </div><!-- /.box-header -->";
        html += "   <div class=\"box-body\" id=\"box_body_" + id + "\" style=\"position: absolute; top:52px; left: 5px; bottom: " + (options.footer != undefined ? 60 : 20) + "px; right:5px; overflow:" + (options.overflow != undefined ? options.overflow : "auto") + ";\">";
        html += (options.content != undefined ? options.content : "");
        html += "   </div><!-- /.box body -->";
        if (options.footer != undefined) {
            html += "<div style=\"position: absolute; height:40px; left: 5px; bottom: 20px; right:15px;\" >";
            html += options.footer;
            html += "</div><!-- /.box-footer -->";
        }
        if (options.addOverlay != undefined) {
            html += "<div id=\"overlay_" + id + "\" class=\"overlay\" style=\"display:none; z-index:2000;position: absolute;top: 50px;bottom: 0;left: 0;right: 0; width:unset; height:unset;\">";
            if (options.addOverlay == true) {
                html += "<i class=\"fa fa-refresh fa-spin\"></i></div>";
            } else {
                html += options.addOverlay;
            }
        }

        html += "</div><!-- /.box -->";

        //Add the div in the webpage
        $(namePanel).append(html);

        $("#box_" + id).on("click", function() {
            //Zindex = Zindex + 1;
          $("#box_" + id).css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);

        });
        //Manage drag/drop, etc...
        if (options.draggable != undefined && options.draggable == true) {

            $("#box_" + id).draggable({
                // containment: mainContent,
                cursor: "move",
                snap: mainContent,
                handle: "#box_header_" + id,
                start: function() {
                  $("#box_" + id).css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);
                    //Zindex = Zindex + 1;
                    $(".box.box-solid.box-primary").css("opacity", 0.5);
                    $("#box_" + id).css("opacity", 1);
                },
                drag: function() {

                },
                stop: function() {
                    $(".box.box-solid.box-primary").css("opacity", 1);
                }
            });
        }
        if (options.resizable != undefined) {
            $("#box_" + id).resizable(options.resizable);
        }
        if (options.menuBt != undefined) {
            if (options.menuBt.fullscreen != undefined) {

                $("#bt_fullscreen_" + id).on("click", function() {
                    if ($("#bt_fullscreen_" + id + " i").hasClass("fa-expand") == true) { //Passe en FullScreen
                        $("#bt_fullscreen_" + id + " i").removeClass("fa-expand").addClass("fa-compress");
                        $("#box_" + id).css({
                            position: "absolute",
                            left: 0,
                            top: "50px",
                            right: 0,
                            bottom: "20px",
                            width: "",
                            height: ""
                        });
                    } else {
                        $("#bt_fullscreen_" + id + " i").removeClass("fa-compress").addClass("fa-expand");

                        $("#box_" + id).css(options.menuBt.fullscreen.css);
                      $("#box_" + id).css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);
                        //Zindex = Zindex + 1;
                    }
                    if (options.menuBt.fullscreen.fct != null) {
                        options.menuBt.fullscreen.fct();
                    }
                }.bind(options));
                html += "<button id=\"bt_fullscreen_" + id + "\" class=\"btn btn-box-tool\"><i class=\"fa fa-expand\"></i></button>";
            }
            // stores the ui instance
            var self = this;
            if (options.menuBt.reduceTo != undefined) {
                $("#bt_reduce_" + id).on("click", function() {
                    // enable keyboard input when close or reduce a box
                    // nbBoxOpened = 0 or undefined : undefined when we open main box : simulation case, scenario context list, SU, Analysing. Theses boxes are created with appendHTML, not createBox. We don't use the stopkeayboard when we open it
                    // and there is no nbBoxOpened value


                    if (CallBackClose != undefined) {
                        if (self.nbBoxOpened > 0) {
                            self.nbBoxOpened--;
                        } else {
                            self.nbBoxOpened = 0;
                        }
                        //if (self.classBindData.soclecopy != undefined && self.nbBoxOpened == 0) {
                        //    self.classBindData.soclecopy.scene.Cls_Controls.Set_KeyboardEnable(true);
                        //}
                        CallBackClose();
                    }

                    //Zindex = Zindex - 10;
                    $("#box_" + id).css("zIndex", 0);
                    var opt = {
                        to: "#" + options.menuBt.reduceTo,
                        className: "ui-effects-transfer"
                    };
                    $("#box_" + id).fadeOut(200).effect("transfer", opt, 500).dequeue();

                }.bind(options));
            } else if (options.menuBt.close != undefined && options.menuBt.close == true) {
                $("#bt_close_" + id).on("click", function() {

                    if (CallBackClose != undefined) {
                        if (self.nbBoxOpened > 0) {
                            self.nbBoxOpened--;
                        } else {
                            self.nbBoxOpened = 0;
                        }
                        //if (self.classBindData.soclecopy != undefined && self.nbBoxOpened == 0) {
                        //    self.classBindData.soclecopy.scene.Cls_Controls.Set_KeyboardEnable(true);
                        //}
                        CallBackClose();
                    }
                    //Zindex = Zindex - 10;
                    $("#box_" + id).css("zIndex", 0);
                    $("#box_" + id).fadeOut(600);


                }.bind(this));
            }


        }

        // enable keyboard control "enter" for name input ("enter" then trigger 'submit')
        // only allowed after login page is invisible
        if ($("#mainpanel").is(":visible") && $(`#box_${id}`).is(":visible")) {
            const inputElement = $(`#box_${id} input[id*=name][type=text]`);
            // if element not exist, exit;
            if (!inputElement.length) return;

            inputElement
                .selectRange(0, inputElement.val().length)
                .on("keydown", (evt) => {
                    if (evt.which === 13) {
                        evt.preventDefault();
                        $(`#box_${id} .pull-right button`).trigger("click");
                    }
                });
        }
        setTimeout(() => { $("#box_" + id).css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);}, 10);

    };


    /*
        create box confirm delete
    */
    this.createConfirmBox = function(message_content, confirmCallback, cancelCallback = undefined) {

        $("#div-confirm-message-text").empty();
        $("#div-confirm-message-text").append(message_content);

        $("#div-confirm-message").fadeIn();
        $("#myModal").css("display", "block");

        $("#valid").unbind("click");
        $("#cancel").unbind("click");

        const handleConfirm = function() {
            if($.isFunction(confirmCallback)){
                confirmCallback();
            }
            $("#myModal").hide();
            $("#div-confirm-message").hide();
        };
        const handleCancel = function() {
            if($.isFunction(cancelCallback)){
                cancelCallback();
            }
            $("#myModal").hide();
            $("#div-confirm-message").hide();
        };

        $("#valid").bind("click", handleConfirm);
        $("#cancel").bind("click", handleCancel);
    };


    /*
        create box confirm delete in the admin MOSA
    */
    this.createConfirmBoxSU = function(message_content, callbackSuccess) {

        $("#div-confirm-message-text-SU").empty();
        $("#div-confirm-message-text-SU").append(message_content);

        $("#div-confirm-message-SU").fadeIn();
        $("#myModal-SU").css("display", "block");

        $("#valid-SU").unbind("click", handlerValid);
        $("#cancel-SU").unbind("click", handlerCancel);

        var handlerValid = function() {
            callbackSuccess();
            $("#myModal-SU").hide();
            $("#div-confirm-message-SU").hide();
            // no matter what, remove the text (for i18n)
            $("#div-confirm-message-text-SU").empty();
        };

        var handlerCancel = function() {
            $("#myModal-SU").hide();
            $("#div-confirm-message-SU").hide();
            // no matter what, remove the text (for i18n)
            $("#div-confirm-message-text-SU").empty();
        };

        $("#valid-SU").bind("click", handlerValid);
        $("#cancel-SU").bind("click", handlerCancel);
    };

    /*
        create box/div when an error during Everest or OpenDap request appends
        called by Everest_callbackError
    */
    this.createMessageError = function(code, message) {
        var html = "";
        $("#div-alert-msgError").css("display", true);
        html += "<div class=\"alert alert-danger zalert\">";
        html += "<button type=\"button\" id=\"btn-close-error\" class=\"close\" data-dismiss=\"alert\">×</button>";
        html += "<h3>";
        html += i18nextko.i18n.t("MENU_LABEL_ERROR", { ns: "menu" }) + code;
        html += "</h3>";
        html += "</div>";
        var $html=$(html);
        if(message.indexOf('\n') >= 0) {
            $html.append(
                $('<a>').html(i18nextko.i18n.t("MENU_LABEL_DNL_ERROR_REPORT", { ns: "menu" }))
                    .attr('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(message.replace(/\n/g,'\r\n')))
                    .attr('download', "error.log")
            );
        }else{
            $html.append(message);
        }


        $html.append()
        $("#div-alert-msgError").stop();
        $("#div-alert-msgError").empty();
        $("#div-alert-msgError").append($html);
        var closePopup = function() {
            $("#div-alert-msgError").css("display", "none");
            $("#btn-close-error").unbind("click", closePopup);
        };
        $("#btn-close-error").bind("click", closePopup);
        $("#div-alert-msgError").show();
    };


    /*
        create box/div when an error during Everest or OpenDap request appends
        called by Everest_callbackError
        called by mosa.js et interface_mosa.js (we are not in the div containing 3D)
    */
    this.createMessageErrorSU = function(code, message) {
        var html = "";
        $("#div-alert-msgErrorSU").css("display", true);
        html += "<div class=\"alert alert-danger zalert\">";
        html += "<button type=\"button\" id=\"btn-close-errorSU\" class=\"close\" data-dismiss=\"alert\">×</button>";
        html += "<h3>";
        html += this.accessContentJSON.MENU_LABEL_ERROR + code;
        html += "</h3>";
        html += message;
        html += "</div>";
        $("#div-alert-msgErrorSU").stop();
        $("#div-alert-msgErrorSU").empty();
        $("#div-alert-msgErrorSU").append(html);
        var closePopup = function() {
            $("#div-alert-msgErrorSU").css("display", "none");
            $("#btn-close-errorSU").unbind("click", closePopup);
        };
        $("#btn-close-errorSU").bind("click", closePopup);
        $("#div-alert-msgErrorSU").show();
    };

    /*
      create box/div when an error during Everest or OpenDap request appends
      called by forgot PWD & login box
  */
    this.createMessageErrorFgtPWD_Login = function(code, message) {
        var html = "";
        $("#div-alert-msgErrorForgotPWD_Login").css("display", true);
        html += "<div class=\"alert alert-danger zalert\">";
        html += "<button type=\"button\" id=\"btn-close-errorForgotPWD_Login\" class=\"close\" data-dismiss=\"alert\">×</button>";
        html += "<h3>";
        html += this.accessContentJSON.MENU_LABEL_ERROR + code;
        html += "</h3>";
        html += message;
        html += "</div>";
        $("#div-alert-msgErrorForgotPWD_Login").stop();
        $("#div-alert-msgErrorForgotPWD_Login").empty();
        $("#div-alert-msgErrorForgotPWD_Login").append(html);
        var closePopup = function() {
            $("#div-alert-msgErrorForgotPWD_Login").css("display", "none");
            $("#btn-close-errorForgotPWD_Login").unbind("click", closePopup);
        };
        $("#btn-close-errorForgotPWD_Login").bind("click", closePopup);
        $("#div-alert-msgErrorForgotPWD_Login").show();
    };


    /*
        create box/div containing msg error when click on forgot password
    */
    this.createMsgErrorIT = function(message) {
        var html = "";
        $("#div-contact-IT").css("display", true);
        html += "<div class=\"alert alert-danger zalert\">";
        html += "<button type=\"button\" id=\"btn-close-IT\" class=\"close\" data-dismiss=\"alert\">×</button>";
        html += "<h3>";
        html += this.accessContentJSON.MENU_LABEL_ERROR;
        html += "</h3>";
        html += message;
        html += "</div>";
        $("#div-contact-IT").stop();
        $("#div-contact-IT").empty();
        $("#div-contact-IT").append(html);
        var closePopup = function() {
            $("#div-contact-IT").css("display", "none");
            $("#btn-close-IT").unbind("click", closePopup);
        };
        $("#btn-close-IT").bind("click", closePopup);
        $("#div-contact-IT").show();
    };

    /*
        create msg Alert/success/info in notif box or div alert
    */
    this.createAlert = function(type_message, categorie_message, message) {
        var html = "";
        var temp = 2000;
        var delay_temp = 5000;
        if (type_message == "notif") {

            $("#div-notif-message").css("top", this.heightHeader);
            $("#div-notif-message").css("display", true);
            html += "<div id=\"notification\" class=\"zalert\">";
            if (categorie_message == "info") {
                html += "<div class=\"callout callout-info\">";
            } else if (categorie_message == "warning") {
                html += "<div class=\"callout callout-warning\">";
            } else if (categorie_message == "success") {
                html += "<div class=\"callout callout-success\">";
            } else if (categorie_message == "error") {
                html += "<div class=\"callout callout-danger\">";
            }
            html += message;
            html += "</div></div>";
            $("#div-notif-message").hide();
            $("#div-notif-message").empty();
            $("#div-notif-message").append(html);
            $("#div-notif-message").show("slide", {
                direction: "up"
            }, 500);
            $("#div-notif-message").delay(delay_temp).hide("slide", {
                direction: "up"
            }, 500);
            // $("#div-notif-message").css("display", "none");
        } else if (type_message == "alert") {
            //$("#div-alert-message").css("display", true); //Bug with hide if we set the attribute display
            if (categorie_message == "info") {
                html += "<div class=\"alert alert-info zalert\" style=\"margin-left:5px;\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-info\"></i>";
            } else if (categorie_message == "warning") {
                html += "<div class=\"alert alert-warning\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-warning\"></i>";
            } else if (categorie_message == "success") {
                html += "<div class=\"alert alert-success\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-check\"></i>";
            } else if (categorie_message == "error") {
                html += "<div class=\"alert alert-danger\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-ban\"></i>";
            }
            html += message;
            html += "</div>";
            $("#div-alert-message").stop();
            $("#div-alert-message").empty();
            $("#div-alert-message").append(html);
            $("#div-alert-message").show();
            $("#div-alert-message").delay(delay_temp).fadeOut();
            // $("#div-alert-message").css("display", "none");
        }

    };
    // To ways to call :
    // .createDowloadAlertLink(url,fileName) or .createDowloadAlertLink({"fileName1":"url1","fileName2":"url2" ...})
    this.createDowloadAlertLink = function(urls, optFileName) {
        if (optFileName) {
            var url = urls;
            urls = {};
            urls[optFileName] = url;
        }
        //$("#div-alert-message").css("display", true);
        var html = "";
        for (var name in urls) {
            var url = urls[name];
            html += "<div class=\"alert alert-info zalert\" style=\"margin-left:5px;z-index:99999999\">";
            html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
            html += "<i class=\"icon fa fa-info\"></i>";
            html += "<span><strong>Download : </strong><a href=\"" + url + "\" target=\"_blank\">" + name + "</a></span>";
            html += "</div>";
        }
        $("#div-alert-message").stop();
        $("#div-alert-message").empty();
        $("#div-alert-message").append(html);
      $("#div-alert-message").show();
      $("#div-alert-message button.close").click(function () {
        $("#div-alert-message").hide();
      });
      $("#div-alert-message a").click( ()=> {
        $("#div-alert-message span").html(this.accessMessageContentJSON["MESSAGE_ALERT_DOWNLOAD_LINK"] == undefined ? "Download starting, please wait (this might take some minutes)..." : this.accessMessageContentJSON["MESSAGE_ALERT_DOWNLOAD_LINK"] );
      });
    };


    /*
        create msg Alert/success/info in notif box or div alert
        called by mosa.js et interface_mosa.js (we are not in the div containing 3D)
    */
    this.createAlertSU = function(type_message, categorie_message, message) {
        var html = "";
        var temp = 2000;
        var delay_temp = 5000;
        if (type_message == "notif") {

            $("#div-notif-messageSU").css("top", this.heightHeader);
            $("#div-notif-messageSU").css("display", true);
            html += "<div id=\"notificationSU\" class=\"zalert\">";
            if (categorie_message == "info") {
                html += "<div class=\"callout callout-info\">";
            } else if (categorie_message == "warning") {
                html += "<div class=\"callout callout-warning zalert\">";
            } else if (categorie_message == "success") {
                html += "<div class=\"callout callout-success zalert\">";
            } else if (categorie_message == "error") {
                html += "<div class=\"callout callout-danger zalert\">";
            }
            html += message;
            html += "</div></div>";
            $("#div-notif-messageSU").hide();
            $("#div-notif-messageSU").empty();
            $("#div-notif-messageSU").append(html);
            $("#div-notif-messageSU").show("slide", {
                direction: "up"
            }, 500);
            $("#div-notif-messageSU").delay(delay_temp).hide("slide", {
                direction: "up"
            }, 500);
            // $("#div-notif-message").css("display", "none");
        } else if (type_message == "alert") {
            $("#div-alert-messageSU").css("display", true);
            if (categorie_message == "info") {
                html += "<div class=\"alert alert-info zalert\" style=\"margin-left:5px;\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-info\"></i>";
            } else if (categorie_message == "warning") {
                html += "<div class=\"alert alert-warning zalert\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-warning\"></i>";
            } else if (categorie_message == "success") {
                html += "<div class=\"alert alert-success zalert\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-check\"></i>";
            } else if (categorie_message == "error") {
                html += "<div class=\"alert alert-danger zalert\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-ban\"></i>";
            }
            html += message;
            html += "</div>";
            $("#div-alert-messageSU").stop();
            $("#div-alert-messageSU").empty();
            $("#div-alert-messageSU").append(html);
            $("#div-alert-messageSU").show();
            $("#div-alert-messageSU").delay(delay_temp).fadeOut();
            // $("#div-alert-message").css("display", "none");
        }

    };


    /*
       create msg Alert/success/info in notif box or div alert
       called in bow forgot PWD & login
   */
    this.createAlertFgtPWD_Login = function(type_message, categorie_message, message) {
        var html = "";
        var temp = 2000;
        var delay_temp = 5000;
        if (type_message == "alert") {
            $("#div-alert-messageForgotPWD_Login").css("display", true);
            if (categorie_message == "info") {
                html += "<div class=\"alert alert-info zalert\" style=\"margin-left:5px;\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-info\"></i>";
            } else if (categorie_message == "warning") {
                html += "<div class=\"alert alert-warning zalert\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-warning\"></i>";
            } else if (categorie_message == "success") {
                html += "<div class=\"alert alert-success zalert\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-check\"></i>";
            } else if (categorie_message == "error") {
                html += "<div class=\"alert alert-danger zalert\">";
                html += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\">×</button>";
                html += "<i class=\"icon fa fa-ban\"></i>";
            }
            html += message;
            html += "</div>";
            $("#div-alert-messageForgotPWD_Login").stop();
            $("#div-alert-messageForgotPWD_Login").empty();
            $("#div-alert-messageForgotPWD_Login").append(html);
            $("#div-alert-messageForgotPWD_Login").show();
            $("#div-alert-messageForgotPWD_Login").delay(delay_temp).fadeOut();
            // $("#div-alert-message").css("display", "none");
        }

    };


    this.moveUOto = function(obj, showGroup) {
        if (this.GestionUrbObj != undefined) {
            this.GestionUrbObj.CurrentItemLink = undefined;
            this.GestionUrbObj.CurrentObjLink = obj;
        }
        if (obj != undefined && obj != null) {
            if (this.urbanObj != undefined && obj.idParent != this.urbanObj.idParent) {
                $("#" + this.urbanObj.idParent).hide();
            }
            this.urbanObj = obj;
            if (this.GestionUrbObj != undefined && obj.ItemLink != undefined) {
                this.GestionUrbObj.CurrentItemLink = obj.ItemLink;
            }
            $("#" + this.defaultUO.idParent).hide();
        } else {
            //Reset
            this.urbanObj = this.defaultUO;
        }
        $("#urban_objects_move").prependTo("#" + this.urbanObj.id);
      $(".UOModified").hide();
      if (showGroup != undefined && showGroup==true) {
        $("#div_urban_objects_select").show();
      } else {
        $("#div_urban_objects_select").hide();
      }
      //$('#urban_objects_select option[value="UrbanObject"]').prop('selected', true);
      $("#urban_objects_select").trigger("change", ['UrbanObject']);
    };


    //Lance l'initialisation sur le constructeur.
    this.initMenu();
    //uniquement pour tester les box
    this.testBox = function(id) {
        this.CreateBox(id, {
            draggable: true,
            resizable: {
                minHeight: 250,
                minWidth: 300 /*, handles: 'e, s, w'*/
            }, //...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
            useSkin: true,
            //css:"",//Utile si useSkin=false
            title: "Titre " + id,
            content: "<h1>h1 -> Test de la  box  : " + id + "</h1>",
            visible: true,
            menuBt: {
                reduceTo: "div_SimulationCase", //ID vers qui on doit réduire la div
                //close:true,//Fermer sans id si pas de reduceTo
                fullscreen: {
                    css: {
                        position: "absolute",
                        top: "25%",
                        left: "25%",
                        right: "",
                        bottom: "",
                        width: "300px",
                        height: "250px"
                    }
                } //position / taille de div pour quand on sort du fullscreen
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "150px",
                left: "250px",
                //right:"",
                //bottom:""
                width: "300px"
                //height:"",
                //min-width:"",
                //min-height:"",
                //max-width:"",
                //max-height:""
                //n'importe qu'elle autre balise css...
            }
        });

    };
};

UI.prototype.constructor = UI;

UI.prototype.OnSelectBuildingByContour = function() {
    console.error("UI.OnSelectBuildingByContour is normally replaced by the right implementation by tw3d.js module.");
    alert("Contour selection not available");
}
UI.prototype.DeselectAll = function() {
    console.error("Deselect All is normally replaced by the right implementation by tw3d.js module.");
    alert("Deselect All not available");
}

UI.prototype.createBoxMeasure = function(CallBackClose) {

    if ($("#box_infosMeasure").length > 0) {
        CallBackClose();
        this.nbBoxOpened--;
        $("#box_infosMeasure").remove();
    }

    var c = "<div id=\"infosMeasure_content\" style=\"width:100%; height:100%;\">";
    c += "      <table style=\"width:100%; height:100%;\" id=\"infosMeasure_content_table\">";

    c += "      </table>";
    c += "  </div>";

    this.CreateBox("infosMeasure", {
        draggable: false,
        useSkin: true,
        title: this.accessContentJSON.MENU_TOP_ANALYSIS_TITLE_MEASURE,
        content: c,
        addOverlay: false,
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            right: "0px",
            width: "300px",
            height: "200px",
            "min-width": "300px",
            "min-height": "200px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};


UI.prototype.createBoxUpdateSun = function(CallBackClose) {


    if ($("#box_updateSunPos").length > 0) {
        CallBackClose();
        this.nbBoxOpened--;
        $("#box_updateSunPos").remove();
    }

    var c = "<div id=\"updateSunPos_content\" style=\"width:100%; height:100%;\">";
    c += "<div> " + this.accessContentJSON.MENU_TOP_SUN_SELECT + " </div><br/>";
    c += "<div id=\"datepicker_box\"></div><br/>";
    c += "This hour is set according to your position (browser's time zone, GMT)<br/>";
    c += "<div id=\"container_slider\" style=\"display:none;\"><input type=\"text\" id=\"hourpicker_box\" /></div><br/>";
    c += "  </div>";

    this.CreateBox("updateSunPos", {
        draggable: true,
        resizable: {
            minHeight: 400,
            minWidth: 300
        },
        useSkin: true,
        title: this.accessContentJSON.MENU_TOP_SUN_TITLE_UPDATE,
        content: c,

        addOverlay: false,
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            right: "0px",
            width: "300px",
            height: "400px",
            "min-width": "300px",
            "min-height": "400px",
            "max-height": "500px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};

UI.prototype.createBoxOpacityTerrain = function(CallBackClose) {


    if ($("#box_updateOpacityTerrain").length > 0) {
        CallBackClose();
        this.nbBoxOpened--;
        $("#box_updateOpacityTerrain").remove();
    }


    var c = "<div id=\"updateOpacityTerrain_content\" style=\"width:100%; height:100%;\">";
    c += "<div> " + this.accessContentJSON.MENU_TOP_TOOLS_OPACIY_LABEL + " </div><br/>";
    c += "<div id=\"container_slider_opacityTerrain\" ><input type=\"text\" id=\"opacityTerrain_box\" /></div><br/>"; // slider
    c += "  </div>";

    this.CreateBox("updateOpacityTerrain", {
        draggable: true,
        useSkin: true,
        title: this.accessContentJSON.MENU_TOP_TOOLS_OPACIY_TITLE,
        content: c,

        addOverlay: false,
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            right: "0px",
            width: "300px",
            height: "200px",
            "min-width": "300px",
            "min-height": "200px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};
/*

    After load complete JSON configcaviuser.json or configcavisuperuser.json (depending your user account), update menus/btns/labels visibility
*/
UI.prototype.updateVisibility = function(content) {
    this.contentFileUserConfig = content;

    for (var idMenu in this.Menu) {
        if (this.Menu[idMenu] != null) {
            for (var idMenuConfig in this.contentFileUserConfig.ConfCAVI[0].menus) {

                if (idMenuConfig == idMenu) {
                    if (this.contentFileUserConfig.ConfCAVI[0].menus[idMenuConfig].visibility != this.Menu[idMenu].visibility) {
                        var divName = "#div_" + idMenu;
                        if (this.contentFileUserConfig.ConfCAVI[0].menus[idMenuConfig].visibility == true) {
                            if ($(divName).length > 0) {
                                $(divName).show();
                            } else {
                                this.appendHtml("div_" + this.Menu[idMenu].panel, this.Menu[idMenu]);
                            }
                        } else {
                            $(divName).hide();
                        }
                    }

                    //Checker les boites si des enfants sont selectionnes
                    if (this.Menu[idMenu].childs != undefined) {
                        for (var idchild in this.Menu[idMenu].childs) {
                            if (this.contentFileUserConfig.ConfCAVI[0].menus[idMenuConfig].childs != undefined) {
                                if (this.contentFileUserConfig.ConfCAVI[0].menus[idMenuConfig].childs[idchild] != undefined) {
                                    let vis = (this.contentFileUserConfig.ConfCAVI[0].menus[idMenuConfig].childs[idchild].visibility==true);
                                    let $ldiv = $("#div_Left #div_" + idchild);
                                    if ($ldiv.length > 0) {
                                        $ldiv.toggle(vis);
                                    }else{
                                        $("#li_sub_"+this.Menu[idMenu].childs[idchild].id).toggle(vis);
                                    }

                                    let chk = this.contentFileUserConfig.ConfCAVI[0].menus[idMenuConfig].childs[idchild].isChecked;
                                    if(chk !== undefined) {
                                        $("#div_" + idchild+"_cb").prop('checked',chk);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

};


UI.prototype.Test_Conn = function() {

    var UI = this;
    if (this.classBindData.authToken != null) {
        this.Everest.TestConn("Test_Conn", function(data, status) {
            if (UI.BoolConnLost == true) {
                UI.createAlert("notif", "info", UI.accessMessageContentJSON.MESSAGE_ALERT_CONN_OK);
                UI.BoolConnLost = false;
            }

        }, function(data) {
            if (data.responseText == undefined) {
                UI.createAlert("notif", "warning", UI.accessMessageContentJSON.MESSAGE_ALERT_CONN_LOST);
                UI.BoolConnLost = true;
            }


        });
    }
};

UI.prototype.getModeLayerStudyArea = function () {

  if (this.contentFileUserConfig != undefined && this.contentFileUserConfig["ConfCAVI"] != undefined && this.contentFileUserConfig["ConfCAVI"][0] != undefined && this.contentFileUserConfig["ConfCAVI"][0]["modeLayerStudyArea"] != undefined) {
    var modeLayerStudyArea = (this.contentFileUserConfig["ConfCAVI"][0]["modeLayerStudyArea"] != undefined ? this.contentFileUserConfig["ConfCAVI"][0]["modeLayerStudyArea"] : "false");
    return modeLayerStudyArea;
  }else{
    return false;
  }
};

export default UI;
