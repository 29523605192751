"use strict";

class AnalysingTargetListUI {
    constructor(UI, controller) {
        this.controller = controller;
        this.UI = UI;
        this.contentFileJSON = this.UI.accessContentJSON;
        this.contentMessageFileJSON = this.UI.accessMessageContentJSON;

        // Build and add the list of target box
        this.createboxTargetList(function() {return true;});

        var rMenu = '<div id="rMenuTargetList" class="box-body no-padding" >';
        rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_edit_targetList"><i class="fa fa-edit"></i> ' + this.contentFileJSON.MENU_LEFT_RIGHT_CLICK_VIEW + '</a>';
        rMenu += '      <a class="btn btn-block btn-social btn-xs use-skin" style="margin-top:1px;" id="m_infos_targetList"><i class="fa fa-info"></i> ' + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_INFOS + '</a>';
        rMenu += '  </div>';
        $("#page_centre").prepend(rMenu);
        this.tree = $("#treeTargetList");

        $("#box_Analysing_TargetList .overlay").show();
    }


    createboxTargetList(CallBackClose) {

        $("#box_Analysing_TargetList").remove();
        var c = "<div id=\"Analysing_TargetList_content\">";
        c += "      <ul id=\"treeTargetList\" class=\"ztree\"></ul>";
        c += "      <div id=\"Analysing_TargetList_overlay\" class=\"overlay\">";
        c += "          <i class=\"fa fa-refresh fa-spin\"></i>";
        c += "      </div>";
        c += "  </div>";

        this.UI.CreateBox("Analysing_TargetList", {
            draggable: true,
            group: "Analysing",
            resizable: {
                minHeight: 500,
                minWidth: 475
            },
            useSkin: true,
            title: this.contentFileJSON.MENU_LEFT_SUPER_USER_TARGET_TITLE,
            content: c,
            visible: false,
            menuBt: {
                reduceTo: "div_TargetList", //ID vers qui on doit réduire la div
                fullscreen: {
                    css: {
                        position: "absolute",
                        top: "140px",
                        width: "500px",
                        height: "500px",
                        left: "9%",
                        right: "",
                        bottom: ""
                    },
                    fct: function() {

                    }.bind(this)
                } //position / taille de div pour quand on sort du fullscreen
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "140px",
                left: "9%",
                width: "475px",
                height: "500px",
                "min-width": "475px",
                "min-height": "500px",
                "z-index": Zindex + 1
            }
        }, CallBackClose);
        Zindex = Zindex + 1;
    }
    getUI(){
        return this.UI;
    }
    /*init*/
    isAuth(){return this.UI.classBindData.authToken != null; }
    resetTreeTargetList(){
        $.fn.zTree.destroy("treeTargetList");

    }
    init(){

        //Menu  : right-Click
        //Init  click on "Edit TargetList Simulation" of the right-click menu
        $("#m_edit_targetList").click($.proxy(function() {
            $("#rMenuTargetList").hide();
            $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
            if (this.tree.getSelectedNodes()[0]) {
                this.controller.BT_EditTarget(this.tree.getSelectedNodes()[0]);
            }
        }, this));
        //Init  click on "Infos"  of the right-click menu
        $("#m_infos_targetList").click($.proxy(function() {
            $("#rMenuTargetList").hide();
            $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
            if (this.tree.getSelectedNodes()[0]) {
                this.controller.readTargetInfo(this.tree.getSelectedNodes()[0]);
            }
        }, this));

    }
    /*addDiyDom*/
    addDiyDom(treeNode, d) {
        var aObj = $("#" + treeNode.tId);
        aObj.prepend('<i id="status_' + treeNode.tId + '" class="fa fa-' + (d.published == true ? 'check-circle" style="color:green' : 'lock" style="color:orange') + '"></i>');
    }

    /*initTree*/
    initTree(settings,zNodes){
        this.tree = $.fn.zTree.init(this.tree, settings, zNodes);
    }
    setVisibilityOverlay(visible){
        if (visible){
            $("#box_Analysing_TargetList .overlay").show();
        }else{
            $("#box_Analysing_TargetList .overlay").hide();

        }
    }
    /*
when user do a right-click on the target list, open a contextual menu dispaying some actions
*/
    OnRightClick(event, treeId, treeNode) {

        if (!treeNode && event.target.tagName.toLowerCase() != "button" && $(event.target).parents("a").length == 0) {
            this.tree.cancelSelectedNode();
        } else if (treeNode && !treeNode.noR) {
            this.tree.selectNode(treeNode);
            this.showRMenu("node", event.clientX, event.clientY);
        }
    }

    /*
    Show the contextual menu, according to the position of the click
    */
    showRMenu(type, x, y) {
        $("#rMenuTargetList").stop().css({
            "top": y + "px",
            "left": x + "px"
        }).show("fold");
        $("body").bind("mousedown", this.onBodyMouseDown.bind(this));
    }

    onBodyMouseDown(event) {
        if (!(event.target.id == "rMenuTargetList" || $(event.target).parents("#rMenuTargetList").length > 0)) {
            $("#rMenuTargetList").hide();
            $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
        }
    }


    moveUOto(editTarget) {
        this.UI.moveUOto({
            idParent: "box_TargetList_Edit",
            id: "uo_TargetList_Edit",
            ItemLink: editTarget
        });
    }

    callbackSuccessInfos(data, status, node) {
        this.createboxInfosTgt(data, function() {return true;});
    }

    createboxInfosTgt(data, CallBackClose) {

        $("#box_infosTargetList").remove();
        var c = "<div id=\"infosTargetList_content\" style=\"width:100%; height:100%;\">";
        c += "      <table style=\"width:100%; height:100%;\">";
        c += "          <tr style=\"height: 50px;\"><td>" + this.contentFileJSON.MENU_NEW_NAME + " </td>";
        c += "              <td>" + data.name + "</td></tr>";
        c += "          </tr>";
        c += "          <td>" + this.contentFileJSON.MENU_NEW_COMMENTS + " </td>";
        c += "          <td>";
        c += "              <div class=\"box box-solid box-primary\" style=\"width:100%; height:100%; overflow:auto; text-align:left;padding:5px\" >" + data.description + "</div>";
        c += "          </td></tr>";
        c += "          <tr style=\"height: 50px;\"><td>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_INFO_PUBLISHED + " </td>";
        c += "              <td><i class=\"fa fa-" + (data.published == true ? "check-circle\" style=\"color:green" : "times-circle\" style=\"color:red") + "\"></i>" + (data.published == true ? " Yes" : " No") + "</td></tr>";
        c += "          </tr>";
        c += "      </table>";
        c += "  </div>";

        this.UI.CreateBox("infosTargetList", {
            draggable: true,
            group: "Analysing",
            useSkin: true,
            title: data.name,
            content: c,
            resizable: {
                minHeight: 310,
                minWidth: 400
            },
            visible: true,
            menuBt: {
                close: true
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "30%",
                left: "40%",
                width: "400px",
                height: "310px",
                "min-width": "400px",
                "min-height": "310px",
                "z-index": Zindex + 1
            }
        }, CallBackClose);
        Zindex = Zindex + 1;
    }

    everestCallbackError(data, status) {

        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");
    }
}

export default AnalysingTargetListUI;
