import Attributs_Initiative_CLS from "./attributs_initiative.js";

var Simulating_ContextList_Edit_CLS = function(Everest, UI) {
    this.UI = UI;
    this.Everest = Everest;
    this.tabContext = [];
    this.data_Models_Info = null; //Info au niveau du mosa (liste des categories d'initiatives + timescales possibles)
    this.UO_Metadata = []; //Listes des metadata des attributs pour un typeName
    this.Urban_Objects_Attributes = null; //Liste des valeurs sauvegardées pour un couple "pid" (UrbanObject) / "caseId" (simulation)
    this.treeUO = $.fn.zTree.getZTreeObj("tree_URB");

    this.contentFileJSON = this.UI.accessContentJSON;

    this.currentNodeContext = null; //On stoque les info du context, car le sim id de everest peut changer et ne plus être bon.
    //Construit la box de gestion des Context
    //Rajoute la box
    this.createboxGestionContext(function() {
        return true;
    });


    //Ajout des events

    $("#ContextList_Edit_finish").click(function() {
        $("#box_ContextList_Edit").hide();
    }.bind(this));

    this.init = function() {
        this.tabContext = [];
        this.Everest.List_Models_Info(this.initTree.bind(this), this.Everest_callbackError.bind(this));
    };

    this.initTree = function(data, status, id) {
        this.data_Models_Info = data;
        var params = {
            mode: "Edit",
            uid: "ctx_ro",
            isReadOnly: true,
            idParent: "items_ContextList_Edit"
        };
        var par = {
            code: "ContextList_Edit_it"
        };
        this.tabContext[par.code] = new Attributs_Initiative_CLS(par, data.timescales, params, this.UI, false);
    };

    this.Reset = function() {
        for (var i in this.tabContext) {
            if (this.tabContext[i].Reset != undefined) {
                this.tabContext[i].Reset();
            }
        }
        this.Urban_Objects_Attributes = null;

    };

    this.ResetFromUO = function() {
        this.Reset();
        this.Everest.selectedTypeName = null;
        this.Everest.selectedPid = null;
        $("#ContextList_Edit_noUO").show();
        $("#items_ContextList_Edit").hide();
    };
    this.LoadFromUO = function(uo, treeNode) {
        //if (this.Everest.selectedPid != treeNode.id) {
            this.Reset();
            //uo.tree.checkNode(treeNode, true, true, true);
            if (uo.ListModelZoom != undefined) {
                var typeName = treeNode.UOtype;
                this.Everest.selectedTypeName = typeName;
                this.Everest.selectedPid = treeNode.id;
                this.Load();
                $("#ContextList_Edit_noUO").hide();
            }
        //}
    };
    this.Load = function(nodeContext) {
        $("#items_ContextList_Edit").hide();
        if (nodeContext != undefined && nodeContext != null) {
            this.currentNodeContext = nodeContext; //Bien passer le node.id à tous les appels everest
            $("#ContextList_Edit_title_span").text(nodeContext.name);
          $("#box_ContextList_Edit").show();
          this.UI.setZIndexToMax("box_ContextList_Edit");
            //Mets * sur les items modifiés
            $(".UOModified").hide();
            for (var idItem in this.Everest.UOModified.Context) {
                if (this.Everest.UOModified.Context.hasOwnProperty(idItem)) {
                    $(".diyModified_" + idItem).show();
                }
            }
        }
        $("#ContextList_Edit_noAttrib").hide();
        //Si un uo est selectionné, on lance l'init, sinon on affiche le "You have to select one or more UO"
        var selectedNodes = this.treeUO.getCheckedNodes(true);
        if (selectedNodes.length > 0) {
            $("#ContextList_Edit_noUO").hide();
            $("#overlay_ContextList_Edit").show();
            var params = {
                nodeContext: this.currentNodeContext,
                nodeUo: selectedNodes[0]
            };
            //(param, scenarioId, pid, callbackSuccess,...)
            this.Everest.Read_Scenario_Attribute_Values(params, params.nodeContext.id, params.nodeUo.id, this.Read_Scenario_Attribute_Values_Callback.bind(this), this.Everest_callbackError.bind(this));
        } else {
            $("#ContextList_Edit_noUO").show();
        }
    };
    //Recup les valeurs par defaut pour le couple pid/contextid
    this.Read_Scenario_Attribute_Values_Callback = function(data, status, obj) {

        this.Urban_Objects_Attributes = data;
        //Si le typename à changé, on requetes les nouveaux params.
        if (this.UO_Metadata[this.Everest.selectedTypeName] == undefined) {
            this.Everest.Read_Urban_Object_Type_Metadata(this.Everest.selectedTypeName, this.Everest.selectedTypeName, this.Read_Urban_Object_Type_Metadata_Callback.bind(this), this.Everest_callbackError.bind(this));
        } else {
            //On a tout, on lance le traitement
            this.BuildInterface(this.UO_Metadata[this.Everest.selectedTypeName]);
        }
    };

    //Recup les valeurs pour un typeName
    this.Read_Urban_Object_Type_Metadata_Callback = function(data, status, typeName) {

        this.UO_Metadata[typeName] = data;
        //On a tout, on lance le traitement
        this.BuildInterface(this.UO_Metadata[typeName]);

    };


    //Recup l'ordre dans les metadata
    this.Get_DisplayOrder = function(item, defaultVal) {
      var retour = (defaultVal == undefined ? 0 : defaultVal);
      if (item["metadatas"] != undefined) {
        for (var i = 0; i < item["metadatas"].length; i++) {
          if (item["metadatas"][i].name == "DisplayOrder") {
            retour = item["metadatas"][i].value;
          }
        }
      }
      return retour;
    }
    this.BuildInterface = function(Object_Type_Metadata) {
        //Pour rappel, on doit construire une interface à partir de 3 elements :
        //  - data_Models_Info :  contient les info générique (notamment les timescales) => Requeter une fois au départ
        //  - Object_Type_Metadata (ou this.UO_Metadata[this.Everest.selectedTypeName]) : contient le type de données pour chaque élément=> Requeter une fois par typename (Building / city / ...)
        //  - Urban_Objects_Attributes => contient les valeurs pour chaque timescale correspondant.
        var isEmpty = true;
      this.tabContext["ContextList_Edit_it"].Reset();
      //On trie les items par display order pour que les tableaux soient dans le bon sens.
      var otmSorted = [];
      for (var key in Object_Type_Metadata) {
        if (this.Urban_Objects_Attributes[key] != undefined) {
          var item = Object_Type_Metadata[key];
          if (item.initiative == undefined && this.Is_Allowed(item.metadatas) == true) {
            otmSorted.push({ key: key, displayOrder: this.Get_DisplayOrder(item) });
          }
        }
      }
      otmSorted.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
      var key;
      for (var i in otmSorted) {
        if (otmSorted.hasOwnProperty(i)) {
          key = otmSorted[i].key;
          var item = Object_Type_Metadata[key];
          this.tabContext["ContextList_Edit_it"].AddItem(key, item, this.Urban_Objects_Attributes[key], null, false,"Edit");
          isEmpty = false;
        }
      }


        //console.log("BuildInterface");

        if (isEmpty) {
            //Afficher "Aucun rez"...
            $("#ContextList_Edit_noAttrib").show();
            $("#items_ContextList_Edit").hide();
        } else {
            //Init des valeurs
            for (var i in this.tabContext) {
                if (this.tabContext[i].InitValues != undefined) {
                    this.tabContext[i].InitValues();
                }
            }
            $("#ContextList_Edit_noAttrib").hide();
            $("#items_ContextList_Edit").show();
            if ($("#nav_ctx_ro_ContextList_Edit_it").find(".active").find(".nav-link").is(":visible")==false){
                $("#nav_ctx_ro_ContextList_Edit_it").find(".nav-link:visible:first").tab('show');
            }
        }
        $("#overlay_ContextList_Edit").hide();
    };

    //retourne True si le compte à les droits suffisant pour afficher le champ
    this.Is_Allowed = function(metadatas) {
        if (this.Everest.SuperUser == true)
            return true;

        var resu = false;
        for (var i = 0; i < metadatas.length; i++) {
            if (metadatas[i].name == "SuperUser") {
                if (metadatas[i].value == "no") {
                    return true;
                }
            }
        }
        return resu;
    };

    this.Reset_modif = function() {
        for (var i in this.tabContext) {
            if (this.tabContext.hasOwnProperty(i)) {
                if (this.tabContext[i].InitAllValues != undefined) {
                    this.tabContext[i].InitAllValues();
                }
            }
        }

    };

    this.Sauve_modif = function() {
        for (var i in this.tabContext) {
            if (this.tabContext.hasOwnProperty(i)) {
                if (this.tabContext[i].SaveAllValues != undefined) {
                    this.tabContext[i].SaveAllValues();
                }
            }
        }
    };

    this.Everest_callbackError = function(data, status) {

        $("#overlay_ContextList_Edit").hide();
        this.treeUO.checkAllNodes(false);

        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");

    };
};

Simulating_ContextList_Edit_CLS.prototype.constructor = Simulating_ContextList_Edit_CLS;

Simulating_ContextList_Edit_CLS.prototype.createboxGestionContext = function(CallBackClose) {

    $("#box_ContextList_Edit").remove();
    var c = "<div id=\"ContextList_Edit_content\">";
    c += "      <div style=\"position:absolute; top:0px; left:0px; bottom:0px; width:260px; overflow : auto;\">";
    c += "          <div id=\"uo_ContextList_Edit\" style=\"width:100%;\"></div>"; //Arbre avec les urban Objects
    c += "      </div>";
    c += "      <div class=\"use-skin-border\" style=\"position:absolute; top:0px; left:260px; bottom:0px; right:0px; overflow:auto;\">";
    c += "          <div style=\"text-align:left; height:50px;\">";
    c += "              <div class=\"pull-left\" >";
    c += "                  <h3>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_EDIT_NAME + "</h3>";
    c += "              </div>";
    c += "          </div>";
    c += "          <div id=\"ContextList_Edit_noUO\" class=\"callout use-skin-border\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
    c += "              <h4>" + this.contentFileJSON.MENU_TOP_CITY_URBAN + "</h4>";
    c += "              <p>" + this.contentFileJSON.MENU_TOP_CITY_URBAN_MESSAGE + "</p>";
    c += "          </div>";
    c += "          <div id=\"ContextList_Edit_noAttrib\" class=\"callout use-skin-border\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
    c += "              <h4>" + this.contentFileJSON.MENU_CONTEXT_LABEL + "</h4>";
    c += "              <p>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_EDIT_MESSAGE + "</p>";
    c += "          </div>";
    c += "          <div id=\"items_ContextList_Edit\" style=\"display:none\">";
    c += "          </div>";
    c += "          <div class=\"overlay\" style=\"display:none\">";
    c += "              <i class=\"fa fa-refresh fa-spin\"></i>";
    c += "          </div>";
    c += "      </div>";
    c += "  </div>";

    var f = "<div class=\"pull-right\" > <button class=\"btn use-skin\" style=\"color:#ffffff\" id=\"ContextList_Edit_finish\">" + this.contentFileJSON.MENU_BUTTON_FINISH + "</button></div>";

    this.UI.CreateBox("ContextList_Edit", {
        draggable: true,
        group: "Simulating",
        resizable: {
            minHeight: 500,
            minWidth: 800 /*, handles: 'e, s, w'*/
        }, //...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
        useSkin: true,
        title: this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_EDIT_TITLE + "<span id=\"ContextList_Edit_title_span\"></span>",
        content: c,
        footer: f,
        addOverlay: true,
        visible: false,
        menuBt: {
            reduceTo: "div_ScenarioContext", //ID vers qui on doit réduire la div
            fullscreen: {
                css: {
                    position: "absolute",
                    top: "115px",
                    width: "860px",
                    height: "500px",
                    left: "5%",
                    right: "",
                    bottom: ""
                }
            } //position / taille de div pour quand on sort du fullscreen
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "115px",
            left: "5%",
            width: "860px",
            height: "500px",
            "min-width": "800px",
            "min-height": "500px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};
export default Simulating_ContextList_Edit_CLS;
