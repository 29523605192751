import MOSA_CLS from "./controller/mosa.js";
import i18nextko from "i18next-ko/src/i18next-ko.js";
import logoSquare from "./assets/images/brand/logo_square.jpg"

/*
defines common fct for user normal and SU
stores user profile infos
defines all connection functions : show/hide connection page, show/hide reconnect page
*/


var Interface_User_CLS = function(Everest, Interface_MOSA, UI) {
    this.UI = UI;

    this.contentFileJSON = this.UI.accessContentJSON;
    this.contentMessageFileJSON = this.UI.accessMessageContentJSON;


    //DataBinding user infos
    this.infoUser = {
        email: ko.observable(""),
        fullName: ko.observable(),
        issuperuser: ko.observable(true)
    };


    this.infoUser.issuperuserTxt = ko.pureComputed(function() {
        return "" + ((this.issuperuser() == true) ? "Super " : "") + "User";
    }, this.infoUser);

    this.fileJSONconfig = undefined;

    this.Everest = Everest;
    this.Interface_MOSA = Interface_MOSA;
    this.Everest.callbackErrorTokenTimeout = $.proxy(this.callbackErrorTokenTimeout, this);

    //build and add the interface box
    this.createboxUser(function() {

    });

    $("#box_profil_update").click(function() {
        this.UpdateInfoUser();
    }.bind(this));

    $("#box_profil_resetPWD").click(function() {
        this.ResetPwd(this.infoUser.fullName);
    }.bind(this));

    $("#box_profil_resendLogin").click(function() {
        this.ResendLogin(this.infoUser.email);
    }.bind(this));


    $("#div_Lock").click(function() {
        this.ShowLock();
    }.bind(this));

    $("#password").keypress(function(ev) {
        var keycode = (ev.keyCode ? ev.keyCode : ev.which);
        if (keycode == "13") {
            cavi.User.Login();
        }
    }.bind(this));

    $("#username").keypress(function(ev) {
        var keycode = (ev.keyCode ? ev.keyCode : ev.which);
        if (keycode == "13") {
            cavi.User.Login();
        }
    }.bind(this));
    $("#formConnexion").hide();
    this.Everest.Check_Token(()=> {
        setTimeout(() => {
            this.callbackFctSuccess();
        }, 1000)
       }, () => {$("#formConnexion").show();});
};


Interface_User_CLS.prototype.createboxUser = function(CallBackClose) {

    $("#box_profil").remove();
    var c = "<div class=\"user user-menu use-skin\" style=\"padding: 5px; margin: -10px -10px 5px -10px;color: #ffffff;\">";
    c += "    <!-- User image -->";
    c += `        <img src=${logoSquare} class="img-circle" style="width:100px" alt="User Image">`;
    c += "        <p>";
    c += "            <span data-bind=\"text: cavi.User.infoUser.fullName\"></span><br />";
    c += "            <span data-bind=\"text: cavi.User.infoUser.email\"></span><br />";
    c += this.contentFileJSON.MENU_LABEL_SUPERUSER;
    c += "             <span id=\"isSuperUser\" data-bind=\"text: cavi.User.infoUser.issuperuserTxt\"> </span>";
    c += "        </p>";
    c += "    </div>";

    c += "    <!-- Menu Body -->";
    c += "    <div class=\"user-body\">";
    /*  c += '        <div class="col-xs-4 text-center">';
      c += '            <span href="#" id="box_profil_update">';
      c += this.contentFileJSON.MENU_TOP_INFOS_UPDATE_PROFILE;
      c += '            </span>';
      c += '        </div>';
      c += '        <div class="col-xs-4 text-center">';
      c += '            <span href="#" id="box_profil_resetPWD">';
      c += this.contentFileJSON.MENU_TOP_INFOS_RESET_PASSWORD;
      c += '            </span>';
      c += '        </div>';
      c += '        <div class="col-xs-4 text-center">';
      c += '            <span href="#" id="box_profil_resendLogin">';
      c += this.contentFileJSON.MENU_TOP_INFOS_RESEND_LOGIN;
      c += '            </span>';
      c += '        </div>';
      */
    c += "    </div>";
    c += "    <!-- Menu Footer-->";
    c += "    <div class=\"user-footer\">";
    c += "    </div";

    c += " </div>";

    this.UI.CreateBox("profil", {
        draggable: true,
        group: "Others",
        useSkin: true,
        title: this.contentFileJSON.MENU_TOP_INFOS,
        content: c,
        visible: false,
        menuBt: {
            reduceTo: "div_Parameters" //ID vers qui on doit réduire la div
            //fullscreen: { position: "absolute", top:"115px", width:"475px", height:"500px", left: "20%", right: '', bottom: '' },//position / taille de div pour quand on sort du fullscreen
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "100px",
            right: "10%",
            width: "auto",
            height: "auto",
            "min-width": "320px",
            "min-height": "310px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};


Interface_User_CLS.prototype.setContentJSON = function(obj) {

    this.fileJSONconfig = obj;
};


Interface_User_CLS.prototype.constructor = Interface_User_CLS;


// In SuperUser mode
// When user click on 'Edit MOSA', hide the first MOSA panel and show the edit MOSA panel
Interface_User_CLS.prototype.EditMosa = function() {

    $("#mosa_panel_usersimple").hide();
    $("#mosaPanelEdit").show();

    // adjust dataTable columns width (dataTables won't auto calculate column with of hidden table)
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
};
// When user click on 'Edit SHAPE', hide the first MOSA panel and show the edit MOSA panel
Interface_User_CLS.prototype.EditShape = function() {

    $("#mosa_panel_usersimple").hide();
    $("#shapePanelEdit").show();

    // adjust dataTable columns width (dataTables won't auto calculate column with of hidden table)
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
};


//Connection CAVI
Interface_User_CLS.prototype.Login = function() {
    $("#loginError").hide();

    this.Everest.Init_Token($("#username").val(), $("#password").val(), this.callbackFctSuccess.bind(this), this.callbackFctError.bind(this));
    $("#buttonLogin").attr("disabled", true);
};


// Show the connection box
Interface_User_CLS.prototype.Show = function() {
    $("#formLogin").show();
};

//  Hide the connection box
Interface_User_CLS.prototype.Hide = function() {
    $("#formLogin").hide();
};


Interface_User_CLS.prototype.HideEditMosa = function() {
    $("#mosa_panel_usersimple").show();
    $("#mosaPanelEdit").hide();
};


Interface_User_CLS.prototype.HideEditShape = function() {
    $("#mosa_panel_usersimple").show();
    $("#shapePanelEdit").hide();
};

Interface_User_CLS.prototype.Logout = function() {
    document.cookie = "EVERESTSessionId="
    document.cookie = "EVERESTUsername=";
    location.reload();
};


Interface_User_CLS.prototype.ResetPwd = function() {
    var log = $("#login_resetPWD").val();
    var reg = new RegExp(this.UI.classBindData.contentRegExResetPWD);


    if (log != "" && log != null && log != undefined) {
        // if (reg.test(log)) {
        this.Everest.ResetPassword(log, this.UI.createAlertFgtPWD_Login("alert", "success", this.contentMessageFileJSON.MESSAGE_ALERT_RESET_PWD_REGISTER), this.UI.createMessageErrorFgtPWD_Login.bind(this));
        // }

    } else {
        this.UI.createAlertFgtPWD_Login("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_WRONG_CHARACTER);
    }

};

Interface_User_CLS.prototype.ResendLogin = function() {
    var mail = {
        "email": $("#mail_resendLOGIN").val()
    };
    var reg = new RegExp(this.UI.classBindData.contentRegExResendLogin);

    if (mail != "" && mail != null && mail != undefined) {
        //if (reg.test(mail)) {
        this.Everest.ResendLogin(mail, this.UI.createAlertFgtPWD_Login("alert", "success", this.contentMessageFileJSON.MESSAGE_ALERT_RESEND_LOGIN_REGISTER), this.UI.createMessageErrorFgtPWD_Login.bind(this));
        // }
    } else {
        this.UI.createAlertFgtPWD_Login("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_WRONG_CHARACTER);
    }

};


// Fct called when user click on 'Send request' on the  'forget password' page
Interface_User_CLS.prototype.ForgetPwd = function() {
    $("#formLogin").hide();
    $("#login_pwd_forget").show();
};
Interface_User_CLS.prototype.HideForgetPwd = function() {
    $("#login_pwd_forget").hide();
    $("#formLogin").show();

};

/*
    If connection succeeds, launches Everest req read profile user.
    In callback success : callbacReadProfileSuccess() defines variables of fullname and type of user (SU or not).
    Then we try to load JSON file config related to the status of the user
*/
Interface_User_CLS.prototype.callbackFctSuccess = function(data, status) {
    // show the logout button
    $(".cavi_mosa_logout").show().css("display", "inline-block");

    import(/* webpackPreload: true */ './modules/dataTable.module').then(() => {
        this.Everest.ReadProfile(this.Everest.getUsername(), this.callbacReadProfileSuccess.bind(this));
    }).catch(error => console.log(error));

    $.getJSON("json/configcavi.json", this.endInterfaceShow.bind(this));
};


/*
    Update the visibility of menu thanks to JSON config file
    Then show interface MOSA, which let user to select a MOSA
    If user is a user classic, he just can see a dropdown menu to select a MOSA
    A SU, can see a button Editing MOSA
*/
Interface_User_CLS.prototype.endInterfaceShow = function(data) {
    this.fileJSONconfig = data;
    this.UI.contentFileUserConfig = this.fileJSONconfig;
    this.UI.updateVisibility(this.UI.contentFileUserConfig);

    this.Interface_MOSA.Init();
    this.Interface_MOSA.Show();
    this.Hide();
    $("#mainDiv").show();
    $("#formConnexion").show();
  //#3962
  if (data != undefined && data["ConfCAVI"] != undefined && data["ConfCAVI"][0] != undefined && data["ConfCAVI"][0]["buttons"] != undefined) {
    for (var k in data["ConfCAVI"][0]["buttons"]) {
      if (data["ConfCAVI"][0]["buttons"].hasOwnProperty(k) && data["ConfCAVI"][0]["buttons"][k] == false) {
        $("#" + k).hide();
        //Cas à part
        if (k == "buttonEditingMOSA") {
            $("#LstSHAPE_Mosaview ").hide();
            $("#space_button_mosa").hide();//only one button, we remove the space beetween the two buttons
        } else if (k == "buttonEditingSHAPE") {
            $("#LstMOSA_Shapeview ").hide();
            $("#space_button_mosa").hide();//only one button, we remove the space beetween the two buttons
        }
      }
    }
  }
  //end #3962
};


Interface_User_CLS.prototype.callbackFctError = function(jqXHR, textStatus, errorThrown) {
    let errorToSend;
    let errorKey;

    if (jqXHR.status === 401) {
        // errorToSend = this.contentMessageFileJSON.MESSAGE_ALERT_LOGIN_401;
        errorKey = "MESSAGE_ALERT_LOGIN_401";
        errorToSend = i18nextko.i18n.t(errorKey, { ns: "message" });

    } else {
        // errorToSend = this.contentMessageFileJSON.MESSAGE_ALERT_LOGIN_500;
        errorKey = "MESSAGE_ALERT_LOGIN_500";
        errorToSend = i18nextko.i18n.t(errorKey, { ns: "message" });
    }

    $("#loginErrorTxt").text(errorToSend);
    $("#loginErrorTxt").attr("data-error", errorKey);
    $("#loginError").show();
    $("#buttonLogin").attr("disabled", false);
};
Interface_User_CLS.prototype.callbacReadProfileSuccess = function(data, status) {
    this.Everest.SuperUser = data.issuperuser;
    this.Everest.studyArea = data.studyAreas;
    this.infoUser.fullName(data.fullName);
    this.infoUser.issuperuser(data.issuperuser);
    this.UI.classBindData.userFullname = data.fullName;
    this.UI.classBindData.userStudyAreas = data.studyAreas;

    if (data.issuperuser === false) {
        $("<style>.isSuperUser {display : none;}</style>").appendTo("head");
    }

    // i18n shapefile table label binding
    let shpTableLabelBinding = {
        shpName: "i18n:{text:{key:'menu:MENU_LABEL_NAME'}}",
        shpType: "i18n:{text:{key:'menu:MENU_LABEL_TYPE'}}",
        published: "i18n:{text:{key:'menu:MENU_LABEL_PUBLISHED_MOSATABLE'}}",
    };

    //FIXME: Layer management window must be inited in INTERFACE_MOSA/INTERFACE_MOSA_UI : not HERE !!!!

    //start ShapeFile list
    $("#box-body_content_tableSHAPE").empty();

    let s = `<table class="table table-striped table-results-calculs" id="LstSHAPE" style="width: 100%">`;
    s += `<thead>`;
    s += `<tr>`;
    //s +=        "<th style=`width: 40%;`>&nbsp;</th>";
    s += `<th style="cursor:pointer;" data-bind="${shpTableLabelBinding.shpName}">Name</th>`;
    s += `<th style="cursor:pointer;" data-bind="${shpTableLabelBinding.shpType}">TYPE</th>`;
    s += `<th style="cursor:pointer;" data-bind="${shpTableLabelBinding.published}">${this.contentFileJSON.MENU_LABEL_PUBLISHED_MOSATABLE}</th>`;
    s += `<th style="cursor:pointer;">${this.contentFileJSON.MENU_LABEL_studyArea}</th>`;
    s += `</tr>`;
    s += `</thead><tfoot></tfoot><tbody></tbody>`;

    $("#box-body_content_tableSHAPE").append(s);

    $("#LstSHAPE").DataTable({
        // "fixedHeader": true,
        // "responsive": true,
        // "bAutoWidth": true,
        //"scrollY": 200,
        //"scrollX": true,
        "lengthMenu": [ 6, 60, 100 ],
        "order": [[2, "desc"], [1, "asc"], [0, "asc"]],
        "columnDefs": [
            {
                "targets": 0,
                "orderable": true
            }, {
                "targets": 1,
                "order": [
                    [1, "asc"],
                    [2, "desc"]
                ]
            }, {
                "targets": 2,
                "order": [
                    [1, "asc"],
                    [2, "desc"]
                ]
            }, {
                "targets": 3,
                "visible": false
            }, {
                type: "alt-string",
                targets: 0
            }, {
                type: "alt-string",
                targets: 1
            }, {
                type: "alt-string",
                targets: 2
            }, {
                type: "alt-string",
                targets: 3
            }
        ]
    });

    // post apply bindings for the dom element
    ko.applyBindings({}, document.getElementById("LstSHAPE"));


    if (data.studyAreas.length > 1) {
        var innerMOSA = "";
        var innerSHAPE = "";
        var shapes_Headers = new Object();
        var mosa_Headersnew = new Object();

        // both will append multiple times... empty firstly
        $("#selectMultiTown").empty();
        $("#selectMultiTownShape").empty();
        for (var i = data.studyAreas.length - 1; i >= 0; i--) {
            if (data.issuperuser) this.Everest.List_Shapefiles(data.studyAreas[i].code, this.Interface_MOSA.callbackShapeSuccess.bind(this.Interface_MOSA), this.Interface_MOSA.Everest_callbackError.bind(this.Interface_MOSA));
            innerMOSA = "<button class=\"buttonMuliCity\" id=\"city_" + data.studyAreas[i].code + "\">" + data.studyAreas[i].name + "</button>";
            innerSHAPE = "<button class=\"buttonMuliCity\" id=\"city_SHAPE" + data.studyAreas[i].code + "\">" + data.studyAreas[i].name + "</button>";
            shapes_Headers[data.studyAreas[i].name] = "city_SHAPE" + data.studyAreas[i].code;
            mosa_Headersnew[data.studyAreas[i].name] = "city_" + data.studyAreas[i].code;
            $("#selectMultiTown").append(innerMOSA);
            $("#selectMultiTownShape").append(innerSHAPE);
            $("#city_" + data.studyAreas[i].code).click({
                name: data.studyAreas[i].name,
                code: data.studyAreas[i].code
            }, function(event) {
                var table = $("#LstMOSA").DataTable();

                for (var j = data.studyAreas.length - 1; j >= 0; j--) {
                    var a = mosa_Headersnew[data.studyAreas[j].name];
                    $("#" + a).css("background-color", "rgb(0,26,112)");
                }
                var a = mosa_Headersnew[event.data.name];
                $("#" + a).css("background-color", "rgb(0,91,187)");
                var val = event.data.code;
                //if (val == "M") val = "MUG";
                //if (val == "P") val = "PRODEC";
                table.columns(5).search("^" + val + "$", true, false, true).draw();
            });
            $("#city_SHAPE" + data.studyAreas[i].code).click({
                name: data.studyAreas[i].name,
                code: data.studyAreas[i].code
            }, function(event) {
                var table = $("#LstSHAPE").DataTable();
                for (var j = data.studyAreas.length - 1; j >= 0; j--) {
                    var a = shapes_Headers[data.studyAreas[j].name];
                    $("#" + a).css("background-color", "rgb(0,26,112)");
                }
                var a = shapes_Headers[event.data.name];
                $("#" + a).css("background-color", "rgb(0,91,187)");
                var val = event.data.code;
                //if (val == "M") val = "MUG";
                //if (val == "P") val = "PRODEC";
                table.columns(3).search("^" + val + "$", true, false, true).draw();
            });
        }
        ;
    } else if (data.studyAreas.length === 1) {
        if (data.issuperuser) this.Everest.List_Shapefiles(data.studyAreas[0].code, this.Interface_MOSA.callbackShapeSuccess.bind(this.Interface_MOSA), this.Interface_MOSA.Everest_callbackError.bind(this.Interface_MOSA));
        if (data.studyAreas[0].iconUrl === "") {
            $("#studyAreaLogo").html("no icon for this city project");
        } else {
            $("#studyAreaLogo").html("<img src=" + data.studyAreas[0].iconUrl + " alt=" + "studyArea Logo" + " >");
        }
        $("#city_" + data.studyAreas[0].code).click({
            name: data.studyAreas[0].name
        }, function(event) {
            var table = $("#LstMOSA").DataTable();
            //            var val=event.data.name.charAt(0);
            //            if(val=="M")val="MUG";
            //              if(val=="P")val="PRODEC";
            //            table.columns(5).search(val).draw();


        });
    }
};

Interface_User_CLS.prototype.Add = function(mosa) {
    if (shape.id != undefined) {
        if (this.tabMosa[shape.id] != undefined && this.tabMosa[shape.id] != null) {
            this.tabMosa[shape.id].Reset();
        }
        this.tabMosa[shape.id] = new MOSA_CLS(mosa, this.Select.bind(this), this.Everest, this.UI);
        this.tabMosa[shape.id].AddDiv();
        this.tabMosa[shape.id].AddList();
    } else {

    }
};

/************************************
 Gestion du lock screen
 *************************************/
Interface_User_CLS.prototype.callbackErrorTokenTimeout = function(jqXHR, textStatus, errorThrown) {
    this.ShowLock();
};

Interface_User_CLS.prototype.Unlock = function() {
    $("#lockError").hide();
    this.Everest.Init_Token(this.Everest.getUsername(), $("#password_lock").val(), this.UnlockSuccess.bind(this), this.UnlockError.bind(this));
};
Interface_User_CLS.prototype.UnlockSuccess = function(data, status) {

    //Launch again requests which are not finished cause the token lose
    this.Everest.RerunErrors();
    $("#password_lock").val("");
    this.HideLock();
};
Interface_User_CLS.prototype.UnlockError = function(jqXHR, textStatus, errorThrown) {
    $("#lockErrorTxt").text(errorThrown);
    $("#lockError").show();
};
Interface_User_CLS.prototype.ShowLock = function() {
    if ($("#login_pwd_forget").css("display") == "none" && $("#formLogin").css("display") == "none") {
        $("#lockError").hide();
        $("#password_lock").val("");
        $(".lockscreen-wrapper").show();
        $(".lockscreen-wrapper-bg").show();
        $("#mainpanel").hide();
    }
};

Interface_User_CLS.prototype.HideLock = function() {
    $("#lockError").hide();
    $(".lockscreen-wrapper").hide();
    $(".lockscreen-wrapper-bg").hide();
    $("#mainpanel").show();
};


Interface_User_CLS.prototype.callbackSuccess = function(data, status, id) {
    this.UI.createAlert("notif", "success", this.contentMessageFileJSON.MESSAGE_ALERT_INFOS_UPDATED);
};


Interface_User_CLS.prototype.callbackError = function(data, status) {

    var error;
    if (data.responseJSON != undefined) {
        error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
    }

    if (data.responseJSON != undefined)
        this.UI.createMessageError(error, data.responseJSON.errorMessage);
    else if (data.responseText != undefined)
        this.UI.createMessageError("", data.responseText);
    else
        this.UI.createAlert("alert", "error", "ERROR");
};

export default Interface_User_CLS;
