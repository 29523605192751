import Interface_Gestion_SIMU_CLS from "../interface_gestion_simu.js";
import Interface_Gestion_URB_CLS from "../interface_gestion_urbanobj.js";
import Interface_Gestion_Initiatives_CLS from "./interface_gestion_initiatives.js";
import Analysing_Result_CLS from "./analysing_results.js";
import Analysing_Targeting_CLS from "./analysing_targeting.js";
import Analysing_TargetList_CLS from "./analysing_targetlist.js";
import Analysing_Ranking_CLS from "./analysing_ranking.js";
import SU_ManageScenarioContext_CLS from "../su_managescenariocontext.js";
import SU_ManageTarget_CLS from "../su_managetarget.js";
import MOSA_CLS from "./mosa.js";
import Simulating_ContextList_CLS from "../interface_context_list.js";
// import Tw3D_CLS from "../tw3d.js";
import Tw3D_CLS from "../tw3d_mock.js";
import InterfaceMosaUI from "../view/interface_mosa_ui.js";
import LayersSelectionModel from "../components/layers.selection.js";
import carousel from "../components/carousel.js";
import ContourSelectionModel from "../components/contour.selection.js";
import Cavi2Util from "../helpers/cavi.util.js";
import Auth, { USERS_SHOULD_NEVER_SHOW } from "../auth/index.js";

/*
  Defined fct applied on each MOSA
*/
// /!\ Global var ??? /!\


class Interface_MOSA_CLS {
    constructor(Everest, TaskManager, UI) {

        this.interfaceMosaUI=new InterfaceMosaUI(UI,this);

        this.GestionSimulations = undefined;
        this.GestionUrbObj = undefined;
        this.GestionInitiatives = undefined;
        this.Everest = Everest;
        this.TaskManager = TaskManager;
        this.selectedId = null;
        this.isInit = false;
        this.tabMosa = [];
        this.tabShape = [];
        this.shapeId = null;
        this.shapeName = null;
        this.shapeType = null;
        this.tw3d = {contourSelectionModel: new ContourSelectionModel()};
        this.AnalysingResult = {layersSelectionModel: new LayersSelectionModel([])};
        this.AnalysingTargeting = null;
        this.AnalysingKpiTargets = null;
        this.AnalysingRanking = null;
        this.SimulatingContext = null;
        this.shapeFiles = null;

        this.dataResponse = undefined;
        this.myRunId = undefined;
        this.fileLog = undefined;

        this.newMOSAId = undefined;


        this.id_polygone;
        this.id_Polylines;
        this.id_Label;
        this.id_3DObject;

    }

    // In SuperUser
    // fct called when user create a MOSA : fill the form with name, comment, then a new box appears when user validate  'create'.
    // He can now set a MID.
    // When the upload is ended, user can add a cityGML file, in updating the MOSA
    New(){
        this.interfaceMosaUI.newMosa();
    }

    createModel(idStudyArea,name,comments){
        var studyArea;
        if (this.Everest.studyArea.length > 1) {
            studyArea = this.Everest.studyArea[idStudyArea];
        } else {
            studyArea = this.Everest.studyArea[0];
        }

        this.Everest.Create_Model(name,
                                comments,
                                studyArea.code,
                                (data, status, p) =>{ this.createModelCallback(name, comments,studyArea,data, status, p); },
                                this.Everest_callbackError.bind(this));
    }

    createModelCallback(name, comments,studyArea,data, status, p){
        this.interfaceMosaUI.removeNewMosa();

        //store the ID of the new MOSA
        var modelId = data.id;
        this.newMOSAId = data.id;
        var dateActu = new Date();
        var dateForma = dateActu.getFullYear() + "-" + (dateActu.getMonth() + 1) + "-" + dateActu.getDate() + " " + dateActu.getHours() + ":" + dateActu.getMinutes() + ":" + dateActu.getSeconds() + "." + dateActu.getMilliseconds();
        var input = {
            name: name,
            id: modelId,
            state: "UNPUBLISHED",
            description: comments,
            MIDFileId: null,
            cityGMLFileId: null,
            createDate: dateForma,
            updateDate: dateForma,
            studyArea: studyArea
        };

        this.interfaceMosaUI.setIsAddingNewMOSA(true);
        // add element to the list
        this.Add(input);

        this.interfaceMosaUI.setIsAddingNewMOSA(false);

        this.interfaceMosaUI.createboxUpdateMOSA(modelId);

    }

    createFile(nameParsed, type, meta, nameFile,inputFile){
        this.Everest.Create_File(nameParsed, type, meta, (data, status, p)=> {
            var dat = data;
            var fileID = data.id;
            var typeZip = "application/zip";
            var reader = new FileReader();
            //console.log("titi")
            reader.onloadend = ()=> {
                this.Everest.Upload_File(nameFile, fileID, typeZip, reader.result, (d, s, p)=> {
                    //console.log("toto")
                    var fileLog = "functional.log";
                    var runid = d.runId;
                    this.interfaceMosaUI.setTextUpdateMosa(runid);
                    this.Everest.Read_Run_Status_Exec(runid, (d, s, p)=> {
                        this.dataResponse = d;
                        //console.log("toto1")
                        this.myRunId = runid;
                        this.fileLog = fileLog;
                        this.readRun(d, runid, fileLog);
                    });
                }, this.Everest_callbackError.bind(this));

            };
            reader.readAsArrayBuffer(inputFile.files[0]);
        }, this.Everest_callbackError.bind(this));
    }
    /*
        Uses a timeout to launch again the read_run_stauts and update the TextArea infos
        When progress==100, update the list by adding new entry whith the name of the new MOSA
    */
    readRun(donnee, runid, fileLog) {

        this.Everest.Read_Run_Status_Exec(this.myRunId, (data, status, p)=> {
            this.dataResponse = data;
            //console.log(this.dataResponse);
            this.interfaceMosaUI.readRun(this.dataResponse);
            this.Everest.Read_Run_Output_File(this.myRunId, this.fileLog, (d, s, p)=> {
                this.interfaceMosaUI.readRunOutputFile(d);
            });
        });

        if (this.dataResponse.status == "inProgress" && this.dataResponse.progress < 100) {
            setTimeout(this.readRun.bind(this), 5000);
        } else if (this.dataResponse.status == "failed") {
            clearTimeout();
        } else {
            this.interfaceMosaUI.resetReadRun(this.newMOSAId);
        }

    }

    /*
        Uses a timeout to launch again the read_run_stauts and update the TextArea infos
        When progress==100, update the list by adding new entry whith the name of the new MOSA
    */
    readRunLayer(runid) {
        this.Everest.Read_Run_Status_Exec(runid, (data, status, p)=> {
            //this.getLog(runid);
          this.interfaceMosaUI.readRunLayer(data, this.shapeId, this.shapeName, this.shapeType, runid);
          this.Everest.Read_Run_Output_File(runid, "functional.log", (d, s, p) => {
            this.interfaceMosaUI.readRunOutputFileLayer(d, s, p, true);
          }, (d, s, p) => {
            this.interfaceMosaUI.readRunOutputFileLayer(d,s,p,false);
          });
        });
    }



    // In user normal
    // When user select 'Enter' to access at MOSA 3D
    SelectMOSA() {
        const val = this.interfaceMosaUI.getSelectedMOSA();
        let modelId;

        for (let i in this.tabMosa) {
            if (this.tabMosa.hasOwnProperty(i) && this.tabMosa[i].mosa.name === val) {
                modelId = this.tabMosa[i].id;
                break;
            }
        }

        Promise.all([
            import(/* webpackPreload: true */ "../modules/ztree.module"),
            import(/* webpackPreload: true */ "../modules/leaflet.module"),
        ]).then(() => {
            this.Select(modelId);
            $("#browser-warning").hide();
        }).catch(error => console.log(error));
    }

    // In user normal
    // Create the dropdown list containing all MOSa published
    changeMOSA() {
        //remove the default msg on the list
        var val = this.interfaceMosaUI.getSelectedMOSA();
        this.interfaceMosaUI.changeMOSA();
        for (var i in this.tabMosa) {
            if (this.tabMosa.hasOwnProperty(i)) {
                if (this.tabMosa[i].mosa.name == val) {
                    this.Everest.Results["List_Models_Info"] = undefined;
                    this.Everest.Set_Selected_Id(this.tabMosa[i].id);


                    // Lorsque l'on sélectionne un MOSA dans la liste, les infos sont mises à jour
                    // update the div infos when user select an element in the list
                    this.Everest.List_Models_Info((data, status, id)=> {
                        this.interfaceMosaUI.listModelsInfo(data);
                    }, null);


                }

            }
        }
    }


    // Add the MOSA when we create the list
    Add(mosa) {

        if (mosa.id != undefined) {
            if (this.tabMosa[mosa.id] != undefined && this.tabMosa[mosa.id] != null) {
                this.tabMosa[mosa.id].Reset();
            }
            this.tabMosa[mosa.id] = new MOSA_CLS(mosa, this.Select.bind(this), this.Everest, this.interfaceMosaUI.getUI());
            this.tabMosa[mosa.id].AddDiv();
            this.tabMosa[mosa.id].AddList();
        } else {

        }
    }

    // In user normal
    // Fct called when user click on'Enter', Init all classes
    Select(id) {
        if (this.tabMosa[id] != undefined && this.tabMosa[id] != null) {

            this.selectedId = id;
            this.Everest.Set_Selected_Id(id);
            this.Everest.Set_Selected_StudyAreaName(this.tabMosa[id].GetStudyAreaName());
            this.Hide();
            this.tabMosa[id].Show();
            this.interfaceMosaUI.selectMosa(id,this.tabMosa[id].mosa);

            this.tw3d = Object.assign(new Tw3D_CLS(this.Everest, this.interfaceMosaUI.getUI()), this.tw3d);
            // this.tw3d = new Tw3D_CLS(this.Everest, this.interfaceMosaUI.getUI());

            this.GestionUrbObj = new Interface_Gestion_URB_CLS(this.Everest, this.interfaceMosaUI.getUI(), this.tw3d);
            // we postpone the init later for PRODEC
            // this.GestionUrbObj.init();

            this.tw3d.Fct_SelectZtree = this.GestionUrbObj.ZtreeSelection.bind(this);

            this.GestionInitiatives = new Interface_Gestion_Initiatives_CLS(this.Everest, this.interfaceMosaUI.getUI());
            this.GestionInitiatives.init();

            //this.GestionSimulations.init();
            this.GestionSimulations = new Interface_Gestion_SIMU_CLS(this.Everest, this.GestionInitiatives, this.TaskManager, this.interfaceMosaUI.getUI());
            this.GestionSimulations.init();


            //  get fct TW3D to update POV list after a delete
            this.interfaceMosaUI.getUI().Fct_rebuildPOV = this.tw3d.list_POV.bind(this.tw3d);


            //Gestion des results
            this.AnalysingResult = Object.assign(new Analysing_Result_CLS(this.Everest, this.interfaceMosaUI.getUI(), this.tw3d, this.GestionUrbObj), this.AnalysingResult);
            this.AnalysingResult.init(this.GestionSimulations);
            //Gestion des kpi target
            this.AnalysingTargeting = new Analysing_Targeting_CLS(this.Everest, this.interfaceMosaUI.getUI(), this.GestionUrbObj);
            this.AnalysingTargeting.init(this.GestionSimulations);
            //Gestion des results
            this.AnalysingKpiTargets = new Analysing_TargetList_CLS(this.Everest, this.interfaceMosaUI.getUI());
            this.AnalysingKpiTargets.init();
            //
            this.SimulatingContext = new Simulating_ContextList_CLS(this.Everest, this.interfaceMosaUI.getUI(), this.GestionUrbObj);
            this.SimulatingContext.init();
            //Gestion des results
            this.AnalysingRanking = new Analysing_Ranking_CLS(this.Everest, this.interfaceMosaUI.getUI(), this.GestionUrbObj);
            this.AnalysingRanking.init(this.GestionSimulations);
            //Super user => Manage scenario context
            this.SU_ManageScenarioContext = new SU_ManageScenarioContext_CLS(this.Everest, this.interfaceMosaUI.getUI());
            this.SU_ManageScenarioContext.init();
            //Super user => Manage target
            this.SU_ManageTarget = new SU_ManageTarget_CLS(this.Everest, this.interfaceMosaUI.getUI());
            this.SU_ManageTarget.init();

            //igo TMA 2
            //Passage du contexte
            this.GestionUrbObj.Cls_Target = this.AnalysingTargeting;
            this.GestionUrbObj.Cls_Ranking = this.AnalysingRanking;
            this.GestionUrbObj.Cls_Result = this.AnalysingResult;

            // we reset value of nb box opened, beacause when we init classes, we create the createbox and inc the counter f box opened
            this.interfaceMosaUI.setNbBoxOpened(0);

            //FIXME: Working around ugly TW3D behaviour with hash
            //location.hash="#modelId="+id;
            window.locationhash="#modelId="+id;
            // workaround for disposal
            carousel.dispose();

            // search users and groups based on selected study
            const { code } = this.tabMosa[id].mosa.studyArea;

            const promises = [
                this.Everest.searchUsers(null, code, null),
                this.Everest.searchGroups(null, code)
            ];
            // filter out users that should be involved
            const filter = (username) => username !== this.Everest.getUsername() && !USERS_SHOULD_NEVER_SHOW.includes(username);
            Promise.all(promises).then(data => {
                const [usersPayload, groupsPayload] = data;
                const users = usersPayload.items.filter(({ username }) => filter(username));
                const groups = window._.uniqBy(groupsPayload.items, "code");
                Auth.useUsers(users);
                Auth.useGroups(groups);
            }).catch(error => {
                if (error.status === 404) {
                    console.error("UP005 (Search Users) or UP006(Search Groups) is not available");
                    // remove the share button since service is not available
                    $("#m_share_fold").remove();
                }
            });


            // Init profile 'PRODEC' when user chooses it
            const currentModelName = this.tabMosa[id].name.toLowerCase().trim();

            const isProdec = Cavi2Util.hasChars(currentModelName, ["prodec", "lower_c", "lower-c", "lowerc"]);

            // need to determine which profile and country is on to display the map view
            // mode: onshore/offshore
            const [currentProfile, currentCountry, currentVersion] = currentModelName.replace(new RegExp("^(prodec|lower_c|lower-c|lowerc)-"), "").split("-");

            if (isProdec) {
                const isProfileOnshore = currentProfile === "onshore";
                const req = isProfileOnshore ?
                    import(/* webpackPreload: true */"../prodec/prodec.onshore") :
                    import(/* webpackPreload: true */"../prodec/prodec.offshore");

                req.then(module => {
                    const { default: Component } = module;
                    this.PRODEC = new Component();
                    // offshore component needs explicitly init
                    if (!isProfileOnshore) {
                        this.PRODEC.init();
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    // initialize this to get root objects
                    this.GestionUrbObj.init();

                    $("#mainpanel").css({ "height": "inherit" });
                    window.eventDispatcher.dispatch("onProfileInit",
                        this.Everest,
                        this.interfaceMosaUI,
                        currentCountry.toUpperCase(),
                        currentProfile,
                        currentVersion
                    );
                });
            } else{
                // if not the PRODEC branch, we inits as normal
                this.GestionUrbObj.init();
            }

        }
    }

    //in user normal
    // show the page for select MOSA
    Show() {
        this.interfaceMosaUI.setVisibilityMosaPanel(true);
    }

    //in user normal
    // hide the page for select MOSA
    Hide() {
        this.interfaceMosaUI.setVisibilityMosaPanel(false);
        this.interfaceMosaUI.setVisibilityMosaEditPanel(false);
        this.interfaceMosaUI.setVisibilityShapefilePanel(false);
    }


    // reset values stored for MOSA
    Reset() {
        if (this.selectedId != null && this.tabMosa[this.selectedId] != undefined && this.tabMosa[this.selectedId] != null) {
            this.tabMosa[this.selectedId].Hide();

        }
        this.selectedId = null;
        this.Everest.Set_Selected_Id(null);
        this.isInit = false;



        for (var item in this.tabMosa) {
            if (this.tabMosa.hasOwnProperty(item)) {
                this.tabMosa[item].Reset();
            }

        }
        this.tabMosa = [];
    }

    /*
    Result only visible in SU
    init the table containing all MOSA
    define functions called when click on icons actions
    */
    Init() {
        if (this.selectedID != null || this.selectedID == undefined) {
            this.Reset();
        }
        this.interfaceMosaUI.init(this.Everest.authToken);
    }

    getMosa(id){
        return this.tabMosa[id];
    }

    updateStudyArea(id, txt){
        var JsonToSend = {
            "status": txt
        };
        this.Everest.Update_StudyArea(id, JsonToSend, (d, s, p)=> {
            this.interfaceMosaUI.updateStudyAreaCallback(id,txt);
        }, this.Everest_callbackError.bind(this));
    }
    listModels(){
        $("#select_mosa_usersimple").hide();
        this.Everest.List_Models(this.callbackSuccess.bind(this), this.Everest_callbackError.bind(this)); //  à l'initialisation on lance directement un tri sur la date
    }

    /*
    linkLayer
    */
    linkLayer(idMosa, studyAreaMosa) {
        this.interfaceMosaUI.createboxLinkLayer([]);
        this.Everest.List_Shapefiles(studyAreaMosa, (d, s, p)=> {
            this.interfaceMosaUI.createboxLinkLayer(d.studyAreaLayers);

            this.getwfsModel(idMosa);

            this.interfaceMosaUI.linkLayer();
        }, this.Everest_callbackError.bind(this));
    }

    unlinkModelWFSlayers(idShape, objDom ){
        this.Everest.Unlink_Model_WFSlayers(idShape, ()=> {
            this.interfaceMosaUI.unlinkModelWFSlayers(idShape, objDom);
        }, this.Everest_callbackError.bind(this));

    }

    linkModelWFSlayers(idShape, objDom ){
        this.Everest.Link_Model_WFSlayers(idShape, ()=> {
            this.interfaceMosaUI.linkModelWFSlayers(idShape,objDom);
        }, this.Everest_callbackError.bind(this));
    }

    getwfsModel(idMosa) {
        this.Everest.List_Model_WFSlayers(idMosa, (d, s, p)=> {
            this.interfaceMosaUI.getwfsModelCallback(d);
        }, this.Everest_callbackError.bind(this));
    }

    createLayer(idMosa, studyAreas) {
        this.interfaceMosaUI.createLayer(idMosa, studyAreas);
    }
    createStudyArea(selectedArea, layerName,layerType){
        this.shapeName = layerName;
        this.shapeType = layerType;
        this.Everest.Create_StudyArea(selectedArea, layerName, layerType, this.uploadSHAPEFILE.bind(this), this.Everest_callbackError.bind(this));
    }
    getShapeType(){
        return this.shapeType;
    }
    getShapeId(){
        return this.shapeId;
    }

    //uploadSHAPEFILE
    //create a file using FM001 of type SHAPEFILE and metadata LayerId
    uploadSHAPEFILE(data, status, p) {
        this.interfaceMosaUI.uploadShapefile(data);
    }
    createShapefile(data,nameFile,nameParsed,meta,type,layerFile){
        this.shapeId = data.id.toString();
        this.Everest.Create_File(nameParsed, type, meta, (data, status, p)=> {
            var fileID = data.id;
            var typeZip = "application/zip";
            var reader = new FileReader();
            reader.onloadend = ()=> {
                this.Everest.Upload_File(nameFile, fileID, typeZip, reader.result, (d, s, p)=> {
                    this.interfaceMosaUI.uploadShapefileCallback(d);
                }, this.Everest_callbackError.bind(this));
            };
            reader.readAsArrayBuffer(layerFile[0]);
        }, this.Everest_callbackError.bind(this));
    }

    //create form for Polygon layer
    updatePolygonsLayer(id) {
        //create the box with input file and logs
        this.id_polygone = id;
        this.Everest.Read_StudyAreaDescription(id, (d, s, p)=> {
            this.interfaceMosaUI.createboxPolygonsForm(d);
            this.interfaceMosaUI.updatePolygonsLayer(id);
        }, this.Everest_callbackError.bind(this));


    }
    updateStudyAreaLayer(id, JsonToSend,idDom){
        this.Everest.Update_StudyArea(id, JsonToSend, (d, s, p)=> {
            this.interfaceMosaUI.removeDom(idDom);
        }, this.Everest_callbackError.bind(this));
    }
    //create form for Polygon layer
    updatePolylinesLayer(id) {
        //create the box with input file and logs
        this.id_Polylines = id;

        this.Everest.Read_StudyAreaDescription(id, (d, s, p)=> {
            this.interfaceMosaUI.createboxPolylinesForm(d);
            this.interfaceMosaUI.updatePolylinesLayer(id);
        }, this.Everest_callbackError.bind(this));
    }
    //create form for Polygon layer

    update3DObjectsLayer(id) {
        //create the box with input file and logs
        this.id_3DObject = id;
        this.Everest.Read_StudyAreaDescription(id, (d, s, p)=> {
            this.interfaceMosaUI.createbox3DObjectsForm(d);
            this.interfaceMosaUI.update3DObjectsLayer(id);
        }, this.Everest_callbackError.bind(this));
    }
    //create form for Polygon layer
    updateLabelsLayer(id) {
        //create the box with input file and logs
        this.Everest.Read_StudyAreaDescription(id, (d, s, p)=> {
            this.interfaceMosaUI.createboxLabelsForm(d);
            this.interfaceMosaUI.updateLabelsLayer(id);
        }, this.Everest_callbackError.bind(this));
    }

    //Delete layer
    deleteLayer(id) {
        this.interfaceMosaUI.confirmDeleteLayer(id);
    }
    deleteStudyArea(idShape){
        this.Everest.Delete_StudyArea(idShape, ()=> {
            this.interfaceMosaUI.deleteStudyArea(idShape);
        }, this.Everest_callbackError);
    }

    publishMOSA() {
        this.interfaceMosaUI.publishMOSA();
    }

    callbackSuccess(data, status, id) {
        //  table.clear();
        //  table.draw();

        for (var i = 0; i < data.items.length; i++) {
            // remove the unexpected trailing spaces of MOSA name
            if(data.items[i].name){
                data.items[i].name = data.items[i].name.trim();
            }
            this.Add(data.items[i]);
        }


        if (data.nextUrl != undefined && data.nextUrl != null) { //On charge la suite des résultats

            this.Everest.getNextUrl(data.nextUrl, id, this.callbackSuccess.bind(this), this.Everest_callbackError.bind(this));

        } else {
            import(/* webpackPreload: true */ '../modules/dataTable.module').then(() => {
                this.interfaceMosaUI.initLstMOSA();
                this.isInit = true;
                $("#select_mosa_usersimple").show();

                //FIXME: Working around ugly TW3D behaviour with hash
                //var hash = window.location.hash;
                var hash = window.locationhash;

                if(hash.startsWith("#modelId=")) {
                    this.Select(parseInt(hash.substring("#modelId=".length)));
                }
            }).catch(error => console.log(error));

            // init selectpicker
            Promise.all([
                import(/* webpackPreload: true */ "bootstrap-select/dist/css/bootstrap-select.css"),
                import(/* webpackPreload: true */ "bootstrap-select/dist/js/bootstrap-select.js"),
            ]).then(() => {
                $("#list_select_mosa_usersimple").selectpicker("refresh");
            })
        }
    }

    //cette methode est utilisée par interface_user
    callbackShapeSuccess(data, status, id) {
        for (var i = 0; i < data.studyAreaLayers.length; i++) {

            this.Everest.Read_StudyAreaDescription(data.studyAreaLayers[i].id, (d, s, p)=> {
                this.interfaceMosaUI.addRowLstShape(d);
            }, this.Everest_callbackError.bind(this));
        }

        if (data.nextUrl != undefined && data.nextUrl != null) { //On charge la suite des résultats
            this.Everest.getNextUrl(data.nextUrl, id, this.callbackShapeSuccess.bind(this), this.Everest_callbackError.bind(this));
        } else {
            this.interfaceMosaUI.initLstShape();
        }
        this.interfaceMosaUI.setBtEditShpDisabled(false);
    }

    readInfoShp(selectIdEditSHAPE){
        this.Everest.Read_StudyAreaDescription(selectIdEditSHAPE, (d, s, p)=> {
            this.interfaceMosaUI.setInfoShp(d);
        }, this.Everest_callbackError.bind(this));
    }


    Everest_callbackError(data, status) {
        this.interfaceMosaUI.everestCallbackError(data, status);
    }
}

export default Interface_MOSA_CLS;
