"use strict";
import { AMCHARTS_GLOBAL_CONFIG } from "../globals/amcharts.globals";
import Cavi2Util from "../helpers/cavi.util";

const { fixDigits } = Cavi2Util;
const NETCDF_EMPTY = 9.969209968386869e+36;

/*
 Class to manage the Results->KPI Selection tab
 It is used to compare different simulations with previously defined KPIs
 /!\ The comparison can be set on charts or on tables /!\
 */
class AnalysingResultsUI {
    constructor(controller, UI) {
        this.controller = controller;

        //TODO Remove the link with UI.
        this.UI = UI;
        //File with all texts to display
        this.contentFileJSON = this.UI.accessContentJSON;
        this.contentMessageFileJSON = this.UI.accessMessageContentJSON;


        this.tree;
        this.initTree();
        this.treeKpi = null;
        // boolean set true after creating the ztree simcase
        this.boolcreateTreeSim = false;

        this.graphCollec = []; // array containing all charts created by clicking on 'Graph/array' btn
        this.graphBubbleCollec = []; // array containing all charts created by clicking on 'Graph/array' btn
        this.graphPieCollec = []; // array containing all charts created by clicking on 'Graph/array' btn
        this.graphBarCollec = []; // array containing all charts created by clicking on 'Graph/array' btn
        this.graphCollecFromTempToReport = []; // array containinig all charts stores in the 'div_tempreport' (same as charts shown in 'popup_result')

        this.ColoringSlider = undefined;
    }

    getUI() {
        return this.UI;
    }

    createAlert(id) {
        if (this.contentMessageFileJSON[id] != undefined) {
            this.UI.createAlert("alert", "warning", this.contentMessageFileJSON[id]);
        }
    }

    initTree() {
        if (this.tree == undefined) {
            this.tree = $.fn.zTree.getZTreeObj("treeSimAnaResult");
        }
    }


    /*Init*/
    init() {
        //Creates the box of results
        this.createboxResults(() => {
            return true;
        });

        // defines event click on buttons
        $("#bt_analysing_result_graph").click(this.controller.ResultGraph.bind(this.controller));
        $("#bt_analysing_result_export").click(this.controller.ExportResult.bind(this.controller)); //  export Excel & netCDF
        $("#bt_analysing_result_report").click(this.controller.ReportResult.bind(this.controller)); //  report PDF, Word
        $("#bt_analysing_result_color").click(this.controller.ColorResult.bind(this.controller));
        $("#bt_analysing_result_gis").click(() => this.controller.GISResult());
    }

    createboxResults(CallBackClose) {

        $("#box_analysing_Result").remove();
        var c = "<div id=\"analysing_Result_content\">";
        c += "      <div style=\"position:absolute; top:0px; left:0px; bottom:0px; width:200px; overflow:auto;\">";
        c += "          <h4>";
        c += this.contentFileJSON.MENU_LEFT_SIMULATING_CASE;
        c += "          </h4>";
        c += "          <div id=\"sim_div_result\" style=\"width:100%;\">";
        c += "              <div id=\"sim_analysing_Result\" style=\"width:100%;\">"; //Arbre avec les simulations "run success"
        c += "              </div>";
        c += "              <ul id=\"treeSimAnaResult\" class=\"ztree\"></ul>";
        c += "          </div>";
        c += "      </div>";
        c += "      <div class=\"use-skin-border\" style=\"position:absolute; top:0px; left:200px; bottom:0px; width:260px; overflow:auto;\">";
        //c += "          <h4>";
        //c += this.contentFileJSON.MENU_TOP_CITY_URBAN;
        //c += "           </h4>";
        c += "          <div id=\"uo_analysing_Result\" style=\"width:100%;\"></div>"; //Arbre avec les urban Objects
        c += "      </div>";
        c += "      <div class=\"use-skin-border\" style=\"position:absolute; top:0px; left:465px; bottom:0px; right:0px; overflow:auto;\">";
        c += "          <h4>";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_TITLE;
        c += "          </h4>";
        c += "          <div>";
        c += this.contentFileJSON.MENU_FILTER;
        c += "           : <input type=\"text\" id=\"analysing_Result_filter\" />";
        c += "          </div>";
        c += "          <div style=\"text-align:left\">";
        c += "              <div id=\"kpi_analysing_Resultt_noAttrib\" class=\"callout use-skin-border\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
        c += "                  <p>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_EDIT_MESSAGE + "</p>";
        c += "              </div>";
        c += "              <div id=\"kpiSimulationNoKpi\" class=\"callout use-skin-border kpiSelectionMesage\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
        c += "                  <p>" + this.contentFileJSON.MENU_LEFT_KPI_SELECTION_NO_KPI_FOR_SEL + "</p>";
        c += "              </div>";
        c += "              <div id=\"kpiSimulationSelectUOAndScenario\" class=\"callout use-skin-border kpiSelectionMesage\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
        c += "                  <p>" + this.contentFileJSON.MENU_LEFT_KPI_SELECTION_SELECT_UO_SCENARIO + "</p>";
        c += "              </div>";
        c += "              <div id=\"kpi_analysing_Result\" style=\"width:100%;\">"; //Arbre avec les kpi
        c += "                  <ul id=\"treeAnaResKpi\" class=\"ztree\"></ul>";
        c += "              </div>";
        c += "              <div id=\"kpi_analysing_Result_overlay\" class=\"overlay\">";
        c += "                  <i class=\"fa fa-refresh fa-spin\"></i>";
        c += "              </div>";
        c += "          </div>";
        c += "      </div>";
        c += "  </div>";

        var f = "<div style=\"padding-top:5px;\">  <div  class=\"pull-left\"><button class=\"btn btn-primary use-skin classBtnClick\" id=\"bt_analysing_result_export\" >";
        f += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_EXPORT;
        f += "              </button></div>";
        f += "          <div class=\"pull-right\" >   <button class=\"btn btn-primary use-skin classBtnClick\" id=\"bt_analysing_result_graph\">";
        f += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_GRAPH;
        f += "                                      </button>";
        f += "                                      <div class=\"load_wait_fctRes\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div>";
        // f += '                                      <button class="btn btn-primary use-skin" id="bt_analysing_result_array">Array</button>';
        f += `<button class="btn btn-primary use-skin classBtnClick" id="bt_analysing_result_gis">${this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_GIS}</button>`;

        f += "                                      <button class=\"btn btn-primary use-skin classBtnClick\" id=\"bt_analysing_result_color\">";
        f += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_COLOR;
        f += "                                      </button>";
        f += "                                      <button class=\"btn btn-primary use-skin classBtnClick\" id=\"bt_analysing_result_report\">";
        f += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_REPORT;
        f == "                                      </button>";
        f += "           </div></div>";

        var content_overlay = " <div class=\"alert use-skin\" style=\"margin:20px; color:white;\">";
        content_overlay += "        <h4>";
        content_overlay += this.contentFileJSON.MENU_LEFT_ANALYSING_KPI_EVALUATION_SIMULATION;
        content_overlay += "        </h4>";
        content_overlay += "        <p>";
        content_overlay += this.contentFileJSON.MENU_LEFT_ANALYSING_KPI_EVALUATION_MESSAGE;
        content_overlay += "          </p>";
        content_overlay += "    </div>";
        this.UI.CreateBox("analysing_Result", {
            draggable: true,
            group: "Analysing",
            resizable: {
                minHeight: 500,
                minWidth: 800, /*, handles: 'e, s, w'*/
            }, //...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
            useSkin: true,
            title: this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS,
            content: c,
            footer: f,
            addOverlay: content_overlay,
            visible: false,
            menuBt: {
                reduceTo: "div_ResultAnalysis", //ID vers qui on doit réduire la div
                fullscreen: {
                    css: {
                        position: "absolute",
                        top: "120px",
                        width: "860px",
                        height: "500px",
                        left: "7%",
                        right: "",
                        bottom: "",
                    },
                    fct: function() {

                    }.bind(this),
                }, //position / taille de div pour quand on sort du fullscreen
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "120px",
                left: "7%",
                width: "860px",
                height: "550px",
                "min-width": "800px",
                "min-height": "500px",
                "z-index": Zindex + 1,
            },
        }, CallBackClose);
        Zindex = Zindex + 1;
    }

    /*updateSimCaseTree*/
    updateSimCaseTree(node, nodeParent, boolIsDelete) {
        this.initTree();
        var self = this;
        if (this.boolcreateTreeSim == false) {
            var d = $("#sim_analysing_Result");
            if (d != null || d != undefined) {
                $("#sim_analysing_Result").empty();
                //event onhover on ztree elements
                //alt tag : show the last run date
                let addHoverDom = (treeId, treeNode) => {
                    var aObj = $("#" + treeNode.tId + "_span");
                    var index = self.controller.getIndexSimuRun(treeNode.id);
                    if (index > -1) { // index=-1 if user hover a bundle node, else it's a simu node
                        aObj.attr("title", self.controller.getInfoSimuRun(index)["date"]);
                    }

                };

                this.tree = $("#treeSimAnaResult");
                var settings = {
                    view: {
                        addHoverDom: addHoverDom,
                        dblClickExpand: true,
                        showLine: true,
                        selectedMulti: true,
                    },
                    check: {
                        enable: true,
                        chkboxType: {
                            "Y": "ps",
                            "N": "ps",
                        },
                    },
                    data: {
                        simpleData: {
                            enable: true,
                            idKey: "id",
                            pIdKey: "pId",
                            rootPId: "",
                        },
                    },
                    callback: {
                        onCheck: this.onCheck.bind(this),
                        onClick: this.onClick.bind(this),
                    },
                };


                this.tree = $.fn.zTree.init(this.tree, settings, []);
            }
            this.boolcreateTreeSim = true;
        }

        var currentnode = this.tree.getNodeByParam("id", node.id, null);
        if (currentnode == null) {
            if (boolIsDelete == true) {

            } else {
                var p = this.tree.getNodeByParam("id", nodeParent.id, null);
                if (p == null) {
                    var pos = -1;
                    var np = this.tree.getNodes();
                    for (var n in np) {
                        if (np.hasOwnProperty(n)) {
                            if (nodeParent.name.toString().toLowerCase() <= np[n].name.toString().toLowerCase()) {
                            } else {
                                pos++;
                            }
                        }
                    }
                    var newNodes = this.tree.addNodes(null, pos + 1, {
                        id: nodeParent.id,
                        pId: null,
                        name: nodeParent.name,
                        isParent: true,
                    }, true);
                    p = newNodes[0];
                }

                //Sort item by name
                var pos = -1;
                for (var n in p.children) {
                    if (p.children.hasOwnProperty(n)) {
                        if (node.name.toString().toLowerCase() <= p.children[n].name.toString().toLowerCase()) {
                        } else {
                            pos++;
                        }
                    }
                }
                this.tree.addNodes(p, pos + 1, {
                    id: node.id,
                    pId: p.tId,
                    name: node.name,
                    isParent: false,
                }, false);
            }
        } else {
            if (boolIsDelete == true) {
                // if user deletes a simu or a bundle :
                this.tree.removeNode(currentnode);
            }
        }

        $("#box_analysing_Result .overlay").hide(); // hide popup "no simu"
    }

    /*
     Defines onclick event actions
     */
    onClick(event, treeId, treeNode) {
        // if (treeNode && treeNode.isHover == true && treeNode.isParent == false) {
        // when user click on simu it checks the checkbox
        var bool = !treeNode.checked;
        this.tree.checkNode(treeNode, bool, true, true);

        // }
    }

    onCheck(event, treeId, treeNode) {
        this.controller.updateCheck();
    }

    resetMessage() {
        $(".kpiSelectionMesage").hide();
    }

    messageBadSelect() {
        this.resetMessage();
        if (this.treeKpi != null) {
            $.fn.zTree.destroy("treeAnaResKpi");
            this.treeKpi = null;
        }
        $("#kpiSimulationSelectUOAndScenario").show();

    }

    messageNoKpi() {
        this.resetMessage();
        $("#kpiSimulationNoKpi").show();

    }

    /*load*/
    markUOModified(context) {
        //Set an * on UO modified
        $(".UOModified").hide();
        for (var idItem in context) {
            if (context.hasOwnProperty(idItem)) {
                $(".diyModified_" + idItem).show();
            }
        }
    }

    load() {
        $("kpi_analysing_Resultt_noAttrib").hide();
        //If user had selected an UO, launches the fct Read_Scenario_Attribute_Values() request else show the box "You have to select one or more UO"
        var selectedNodes = this.controller.GestionUrbObj.getCheckedNodesUO();//this.treeUO.getSelectedNodes();
        if (selectedNodes.length > 0) {
            $("#kpi_analysing_Result_overlay").show();
            this.controller.readScenarioAttributeValues(selectedNodes);
        }

    }

    /* BuildInterface */
    setVisibilityResult(visible) {
        if (visible) {
            $("#kpi_analysing_Resultt_noAttrib").hide();
            $("#kpi_analysing_Result").show();
        } else {
            $("#kpi_analysing_Resultt_noAttrib").show();
            $("#kpi_analysing_Result").hide();

        }
    }

    setVisibilityOverlay(visible) {
        if (visible) {
            $("#kpi_analysing_Result_overlay").show();
        } else {
            $("#kpi_analysing_Result_overlay").hide();
        }
    }

    /*deprecated ???? still used ?*/
    addResult(node, nodeParent) {
        if (this.tree.getNodeByParam("id", node.id, null) == null) {
            var p = this.tree.getNodeByParam("id", nodeParent.id, null);
            if (p == null) {
                var newNodes = this.tree.addNodes(null, {
                    id: nodeParent.id,
                    pId: null,
                    name: nodeParent.name,
                    isParent: true,
                }, true);
                p = newNodes[0];

            }
            this.tree.addNodes(p, {
                id: node.id,
                pId: p.tId,
                name: node.name,
                isParent: false,
            }, false);
        }
        $("#box_analysing_Result .overlay").hide(); //hide the popup 'no simu'
    }

    /*SetKpi*/
    setKpi(data) {

        if (this.treeKpi != null) {
            $.fn.zTree.destroy("treeAnaResKpi");
            this.treeKpi = null;
        }
        var zNodes = [];
        var nTmp = [];

        var pStr;
        var obj;
        var indice = -1;
        var nodes = this.controller.GestionUrbObj.getCheckedNodesUO();
        var tabUO = [];
        var typeUO = "";

        var objMetadata = [];

        if (nodes.length == 0) {
            this.messageBadSelect();
            //this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_CHECKCORRECT);
            return -1;
        } else {

            if (nodes[0].UOtype) {
                typeUO = nodes[0].UOtype;
            } else {
                this.messageBadSelect();
                //this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_CHECKCORRECT);
                return -1;
            }

            // for (var i = 0 ; i < 1; i++) {          //lopp on UO selected
            //
            //     if ((nodes[i].iconSkin.indexOf("arrond") > -1 && nodes[i].name.indexOf("Building") == -1) || nodes[i].level == 2) {
            //         typeUO = "Neighborhood";
            //     }
            //     else if (nodes[i].name.indexOf("Building") > -1 || nodes[i].level == 4) {
            //         typeUO = "Building";
            //     } else {
            //         this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_CHECKCORRECT);
            //     }
            //
            // }
            var newLabelValue;
            var nbRes = 0;
            var kpiData = this.controller.getCurrentKpiData();
            if (kpiData != null && kpiData != undefined && kpiData.attributes != undefined) {
                for (var i in kpiData.attributes) {
                    newLabelValue = i;
                    pStr = this.fixOpenDapStrEncoding(kpiData.attributes[i]["META-Initiative"]);
                    pStr = (pStr == undefined ? Cavi2Util.CONST_DEFAULT_META_INITIATIVE : pStr);//IGO_TMA_3 : https://curtis-integ.edf-labs.net/redmine/issues/2964
                    //Support de METADATA:Visible dans les resultats
                    var isVisible = this.fixOpenDapStrEncoding(kpiData.attributes[i]["META-Visible"]);
                    if (isVisible != undefined && isVisible.toLowerCase() == "no") {
                        isVisible = false;
                    } else {
                        isVisible = true;
                    }
                    //
                    if (pStr != undefined && isVisible == true) {
                        //igo TMA 2
                        //Warning "/Results/iris/e_electricityload_e-Bike_" and typeUO="city"
                        if (newLabelValue.indexOf(typeUO + "/") != -1) {
                            nbRes++;
                            if (nTmp[pStr] == undefined) {
                                obj = {
                                    id: pStr,
                                    pId: 0,
                                    name: pStr,
                                    open: false,
                                    isParent: true,
                                };
                                zNodes.push(obj);
                                nTmp[pStr] = obj.id;
                            }
                            obj = {
                                id: i,
                                pId: nTmp[pStr],
                                name: this.fixOpenDapStrEncoding(kpiData.attributes[i]["LABEL"]),
                                title: kpiData.attributes[i]["DESCRIPTION"] ? this.fixOpenDapStrEncoding(kpiData.attributes[i]["DESCRIPTION"]) : "",
                                isParent: false,
                            };

                            if (!obj.isParent && kpiData.attributes[i]["META-order"]) {
                                obj["$$order"] = parseInt(kpiData.attributes[i]["META-order"]);
                            }

                            zNodes.push(obj);

                            var cut = newLabelValue.replace("/Results/" + typeUO + "/", "");
                            objMetadata.push({
                                name: cut,
                                displayName: this.fixOpenDapStrEncoding(kpiData.attributes[i]["LABEL"]),
                            });
                        } else {
                        }
                    }
                }

                this.controller.setSaveMetadataLabel(objMetadata);
                if (nbRes > 0) {
                    var settings = {
                        view: {
                            dblClickExpand: true,
                            showLine: true,
                            selectedMulti: true,
                        },
                        check: {
                            enable: true,
                            chkboxType: {
                                "Y": "ps",
                                "N": "ps",
                            },
                        },
                        data: {
                            key: {
                                title: "title",
                            },
                            simpleData: {
                                enable: true,
                                idKey: "id",
                                pIdKey: "pId",
                                rootPId: "",
                            },
                        },
                        callback: {
                            onClick: this.onKPIClick.bind(this),
                        },
                    };

                    // if KPI nodes has extended prop $$order, then sort with order
                    // otherwise sort alphabetically (ASC)
                    if (zNodes.some(item => item["$$order"])) {
                        zNodes = _.sortBy(zNodes, ["$$order"]);
                    } else {
                        zNodes.sort((a, b) => {
                            // ensure that each word in the name only separated by single space
                            const _normalizeName = (name) => {
                                const arr = name.split(/\s+/);
                                return arr.join(" ").toLowerCase();
                            };

                            const aName = _normalizeName(a.name);
                            const bName = _normalizeName(b.name);

                            if (aName < bName) return -1;
                            if (aName > bName) return 1;
                            return 0;
                        });
                    }

                    this.treeKpi = $.fn.zTree.init($("#treeAnaResKpi"), settings, zNodes);
                    $("#analysing_Result_filter").keyup(() => {
                        var value = $.trim($("#analysing_Result_filter").val()).toLowerCase();
                        //Check all
                        nodes = this.treeKpi.getNodesByParam("isHidden", true);
                        this.treeKpi.showNodes(nodes);

                        if (value != "") {
                            var nodeList = this.treeKpi.getNodesByFilter(function filter(node) {
                                return !node.isParent && node.name.toLowerCase().indexOf(value) == -1;
                            });
                            //hide items of 'nodeList'
                            this.treeKpi.hideNodes(nodeList);
                        }
                    });
                } else {
                    this.messageNoKpi();
                }
            } else {
                this.messageNoKpi();
            }
            $("#kpi_analysing_Result_overlay").hide();

            // dispatch event "onKpiMetaLoaded"
            if (window.eventDispatcher) {
                if (data) {
                    data.attributes = Cavi2Util.fixOpenDapStrEncoding(data.attributes);

                    // jsdap doesn't handle KPIs which are not Grid, construct same data structure
                    const attrs = Object.keys(data).filter((k) => k.includes("/Results"));
                    for (let attrName of attrs) {
                        const kpiName = attrName.trim().split("/")[3];
                        // jump if attrName doesn't match the form "/Results/<EntityType>/<KpiName>"
                        if(!kpiName) {
                            continue;
                        }

                        const { id, type, name, shape, attributes, dimensions, ...rest } = data[attrName];
                        data.attributes[attrName] = attributes;
                        data[kpiName] = {
                            type: "Grid",
                            attributes: {},
                            array: {
                                type,
                                attributes: {},
                                name,
                                dimensions,
                                shape,
                                id: `${kpiName}.${name}`,
                                ...rest,
                            },
                            maps: dimensions.reduce(
                                (acc, dim, idx) => ({
                                    ...acc,
                                    ...{
                                        [dim]: {
                                            type: "String",
                                            attributes: {},
                                            name: dim,
                                            dimensions: [dim],
                                            shape: [shape[idx]],
                                        },
                                    },
                                }),
                                {}
                            ),
                            name: kpiName,
                            id: kpiName,
                        };
                        delete data[attrName];
                    }
                }
                window.eventDispatcher.dispatch("onKpiMetaLoaded", data);
            }
        }
    }

    onKPIClick(event, treeId, treeNode) {
        var bool = !treeNode.checked;
        this.treeKpi.checkNode(treeNode, bool, true, true);
    }

    /**
     * Fix encoding of strings coming for OpenDAP
     */
    fixOpenDapStrEncoding(str) {
        let ret = str;
        try {
            ret = decodeURIComponent(escape(str).replace(/%uF7/g, "%").replace(/%C3%90%C2/g, "%D0").replace(/%C3%91%C2/g, "%D1"));
        } catch (error) {
            // do nothing
        }

        return ret;
    }

    /** ResultGraph **/
    setVisibilityWaitFctRes(visible) {
        if (visible) {
            document.getElementById("bt_analysing_result_graph").disabled = true;
            $(".load_wait_fctRes").show();
        } else {
            document.getElementById("bt_analysing_result_graph").disabled = false;
            $(".load_wait_fctRes").hide();
        }
    }

    emptyChart() {
        this.graphCollec = [];
        this.graphBubbleCollec = []; // array containing all charts created by clicking on 'Graph/array' btn
        this.graphPieCollec = []; // array containing all charts created by clicking on 'Graph/array' btn
        this.graphBarCollec = [];
        this.graphCollecFromTempToReport = [];
        // div stores graphs for report
        $("#div_charts").empty();
    }


    getTreeKpi() {
        return this.treeKpi;
    }

    getCheckedNodesKpi() {
        return (this.treeKpi != null && this.treeKpi != undefined) ? this.treeKpi.getCheckedNodes(true) : [];
    }

    getCheckedNodesTree() {
        this.initTree();
        return this.tree ? this.tree.getCheckedNodes(true) : [];
    }

    /**
     *  Check if  user 'select' more than allowed urban objects in the tree
     *  Status 'select' represents both 'selected' and 'checked' in zTree concept
     *
     */
    isSelectedUOMoreThanAllowed = function() {

        const tree = $.fn.zTree.getZTreeObj("tree_URB");

        // if tree somehow not yet exists, we don't check this constraint
        if (!tree) return false;

        // max allowed selected UO length
        let upperBound = this.UI.contentFileUserConfig["ConfCAVI"][0].menus.Analysing.childs.ResultAnalysis.maxUrbanObjectsLength;

        // cast upper bound to int
        try {
            upperBound = parseInt(upperBound);

            // parsed upper bound is NaN or 0, we don't check this constraint
            if (!upperBound) {
                return false;
            }

        } catch (e) {
            // if upper bound can't be parsed, we don't check this constraint
            return false;
        }

        const selectedNodes = tree.getSelectedNodes();
        const checkedNodes = tree.getCheckedNodes(true);
        const isMoreThanAllowed = selectedNodes.length > upperBound || checkedNodes.length > upperBound;

        if (isMoreThanAllowed) {
            this.UI.createAlert("alert", "warning", `${this.contentMessageFileJSON.MESSAGE_ALERT_TO_MANY_UO} ${upperBound}`);
        }

        return isMoreThanAllowed;
    };

    resultGraphCheck(hasNode) {
        if (hasNode == false) {
            //alert "Check at least one urban object"
            this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_CHECK);
            this.setVisibilityWaitFctRes(false);
            return false;
        }

        //creates the kpi filter to apply on the opendap request
        if (this.treeKpi == null) {
            //alert "Select at least one simulaion case to initialise KPI."
            this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_SELECT_DATA);
            this.setVisibilityWaitFctRes(false);
            return false;
        }
        return true;
    }

    resultGraphNoKpi() {
        //alert "Check at least one kpi"
        this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_CHECKKPI);
        this.setVisibilityWaitFctRes(false);
        return false;
    }

    resultGraphTableAndDiv(hideRes) {
        //Init results tables and div

        var hasNode = false;
        var nodes = this.tree.getCheckedNodes(true);
        //var nodes = this.tree.getSelectedNodes();
        //builds empty interface to fill it on asynchronous mode
        //Tables
        var htmlTable = "<div class=\"nav-tabs-custom\" style=\"width:100%;\">";
        htmlTable += "<ul class=\"nav nav-tabs\">";
        var liHtmlTable = "";
        var divHtmlTable = "";


        for (var i in nodes) {
            if (nodes.hasOwnProperty(i)) {
                if (nodes[i].isParent == false) {
                    this.controller.addSimu(nodes[i].name, nodes[i].id);
                    liHtmlTable += "<li" + (hasNode == false ? " class=\"active\"" : "") + "><a href=\"#tabResultTable_" + nodes[i].id + "\" data-toggle=\"tab\"><i class=\"fa fa-table\"></i> " + nodes[i].name + "</a></li>";
                    divHtmlTable += "<div class=\"tab-pane" + (hasNode == false ? " active" : "") + "\" id=\"tabResultTable_" + nodes[i].id + "\"></div>";
                    hasNode = true;
                }
            } else {
                this.setVisibilityWaitFctRes(false);
            }
        }
        this.controller.updateNbGraphs();

        htmlTable += liHtmlTable + "</ul>";
        htmlTable += "<div class=\"tab-content\" style=\"overflow:auto\">" + divHtmlTable + "</div>";
        htmlTable += "</div>";
        if (hasNode == true) {
            //Bar diagram  tin one tabs
            $("#tabPopupR_6").html("<div style='width:100%; height:100%;'  id='barDiagram'><div id='titleColBar'></div></div>");
            //Pie chart tin one tabs
            $("#tabPopupR_5").html("<div style='width:100%; height:100%;' id='pieChart'><div id='titleColPie'></div></div>");
            //Bubble chart tin one
            $("#tabPopupR_4").html("<div style='width:100%; height:100%;' id='bubbleChart'><div id='titleColBubble'></div></div>");
            //All tables in one tabs
            $("#tabPopupR_3").html("<div id='all_tables' ><div id='titleColAllGraphs'></div></div>");
            //Tables by simu
            $("#tabPopupR_2").html(htmlTable);
            //Graph/Chart
            $("#tabPopupR_1").html("<div style='width:100%; height:100%;' id='results_ghaph'><div id='titleCol'></div></div>");


            // code used to reduce size of the "JS chart by amCharts"
            // when user click on the 'Chart' tab : launches loop to be sure the label size is reduced
            // else when user changes tab of the interface, the label title override each graph
            $("#link2tabPopupR_1").click(function() {
                setTimeout(function() {
                    for (var nbTurn = 0; nbTurn < 10; nbTurn++) {
                        $("a[title='JavaScript charts']").css("font-size", "4px");
                    }

                }, 200);
            });
            $("#link2tabPopupR_4").click(function() {
                setTimeout(function() {
                    for (var nbTurn = 0; nbTurn < 10; nbTurn++) {
                        $("a[title='JavaScript charts']").css("font-size", "4px");
                    }

                }, 200);
            });
            $("#link2tabPopupR_5").click(function() {
                setTimeout(function() {
                    for (var nbTurn = 0; nbTurn < 10; nbTurn++) {
                        $("a[title='JavaScript charts']").css("font-size", "4px");
                    }

                }, 200);
            });
            $("#link2tabPopupR_6").click(function() {
                setTimeout(function() {
                    for (var nbTurn = 0; nbTurn < 10; nbTurn++) {
                        $("a[title='JavaScript charts']").css("font-size", "4px");
                    }

                }, 200);
            });
            // amcharts graphs must be 'shown' else it doesn't appear on report
            // so we show the box but with a negative z-index, user isn't bothered by the popup
            if (hideRes == true) {
                $("#popup_result").css("z-index", -20);
                $("#popup_result").show();
                $("#div_tempReport").css("opacity", 0);
                $("#div_tempReport").show();
            } else {

                this.UI.setZIndexToMaxAsync("popup_result");
                //$("#popup_result").css("z-index", 900000);
                $("#popup_result").show();
                $("#div_tempReport").hide();
            }


        } else {

            this.UI.createAlert("alert", "info", this.contentMessageFileJSON.MESSAGE_ALERT_SELECT_SIMU);
            this.setVisibilityWaitFctRes(false);
            return -1;
        }
    }

    addResultGraph(i, j, tabNameId, tabNameKPI, tabNameSimu, unity, tabNameKPITitle, all) {
        var nameID = "simu-" + tabNameId[i] + "-" + tabNameKPI[j];
        //replace all'/' by '-' character
        var find = "/";
        var re = new RegExp(find, "g");
        nameID = nameID.replace(re, "-");

        // deletes all spaces in selected simulations name
        // else div can't be created with space in 'id'

        var nameSimuChgt = "simu_" + tabNameId[i];
        var margeL = 10;
        var nameKPI = tabNameKPI[j];

        // for i18n
        let selectAllLabel = this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_GRAPH_SELECT_ALL;
        let deselectAllLabel = this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_GRAPH_DESELECT_ALL;

        if (j == 0) {
            $("#titleCol").append("<div id=\"div_graph_" + nameSimuChgt + "\" style=\"float:left;\"><div style=\"font-weight:bold;font-size:20px; color:green;\" id=\"graph_col_" + nameSimuChgt + "\">" + tabNameSimu[i] + "</div><div style=\"font-weight:bold;font-size:16px; color:green;\" id=\"graph_col_scen_" + tabNameId[i] + "\">&nbsp;</div></div>");
            $("#titleColAllGraphs").append("<div id=\"div_result_" + nameSimuChgt + "\" style=\"float:left;margin-left:" + margeL + "px;\"><div style=\"font-weight:bold;font-size:20px; color:green;\" id=\"col_" + nameSimuChgt + "\">" + tabNameSimu[i] + "</div></div>");
            $("#titleColBubble").append("<div id=\"div_graph_bubble_" + nameSimuChgt + "\" style=\"float:left;\"><div style=\"font-weight:bold;font-size:20px; color:green;\" id=\"graph_col_bubble_" + nameSimuChgt + "\">" + tabNameSimu[i] + "</div></div>");
            $("#titleColPie").append("<div id=\"div_graph_pie_" + nameSimuChgt + "\" style=\"float:left;\"><div style=\"font-weight:bold;font-size:20px; color:green;\" id=\"graph_col_pie_" + nameSimuChgt + "\">" + tabNameSimu[i] + "</div></div>");
            $("#titleColBar").append("<div id=\"div_graph_bar_" + nameSimuChgt + "\" style=\"float:left;\"><div style=\"font-weight:bold;font-size:20px; color:green;\" id=\"graph_col_bar_" + nameSimuChgt + "\">" + tabNameSimu[i] + "</div></div>");
        }

        // 'div_result_..' container of tables in 'tables display all' corresponding at a chart
        // 'div_graph_...' container of charts
        $("#div_result_" + nameSimuChgt).append("<div><h4>" + tabNameKPITitle[j] + "</h4><div class=\"table_unit\">" + unity + "</div><div id=\"table_kpi" + j + "_simu" + i + "\" style=\"overflow:auto;\"><table id=\"table_" + nameID + "\"><tr id=\"tr_table_" + nameID + "\"></tr></table></div></div> ");
        $("#div_graph_" + nameSimuChgt).append("<div><div id=\"graph_table_kpi" + j + "_simu" + i + "\" style=\"overflow:auto;min-height:90px;\"></div><div id=\"Select_Deselect_" + nameID + "\"><input type=\"radio\" style=\"margin-left:10px;\" name=\"name_" + nameID + "\" id=\"check_" + nameID + "\">" + selectAllLabel + "<input type=\"radio\" name=\"name_" + nameID + "\" style=\"margin-left:10px;\" id=\"uncheck_" + nameID + "\">" + deselectAllLabel + "</div></div> ");
        // 'div_charts' stores the same current chart in a temp div which is used when we create a PDF/Doc report
        $("#div_charts").append("<div id=\"forreport_graph_table_kpi" + j + "_simu" + i + "\" style=\"overflow:auto;min-height:90px;\"></div>");

        $("#div_graph_bubble_" + nameSimuChgt).append("<div><div id=\"graph_table_kpi_bubble" + j + "_simu" + i + "\" style=\"overflow:auto;min-height:90px;\"></div><div id=\"Select_Deselect_bubble_" + nameID + "\"><input type=\"radio\" style=\"margin-left:10px;\" name=\"name_bubble_" + nameID + "\" id=\"check_bubble_" + nameID + "\">" + selectAllLabel + "<input type=\"radio\" name=\"name_bubble_" + nameID + "\" style=\"margin-left:10px;\" id=\"uncheck_bubble__" + nameID + "\">" + deselectAllLabel + "</div></div> ");
        $("#div_graph_pie_" + nameSimuChgt).append("<div><div id=\"graph_table_kpi_pie" + j + "_simu" + i + "\" style=\"overflow:auto;min-height:90px;\"></div><div id=\"Select_Deselect_pie_" + nameID + "\"><input type=\"radio\" style=\"margin-left:10px;\" name=\"name_pie_" + nameID + "\" id=\"check_pie_" + nameID + "\">" + selectAllLabel + "  <input type=\"radio\" name=\"name_pie_" + nameID + "\" style=\"margin-left:10px;\" id=\"uncheck_pie_" + nameID + "\">" + deselectAllLabel + "</div></div> ");
        $("#div_graph_bar_" + nameSimuChgt).append("<div><div id=\"graph_table_kpi_bar" + j + "_simu" + i + "\" style=\"overflow:auto;min-height:90px;\"></div><div id=\"Select_Deselect_bar_" + nameID + "\"><input type=\"radio\" style=\"margin-left:10px;\" name=\"name_bar_" + nameID + "\" id=\"check_bar_" + nameID + "\">" + selectAllLabel + "<input type=\"radio\" name=\"name_bar_" + nameID + "\" style=\"margin-left:10px;\" id=\"uncheck_bar_" + nameID + "\">" + deselectAllLabel + "</div></div> ");


        var totalMarge = margeL * tabNameSimu.length;
        var W = (100 / tabNameSimu.length) - 3;
        var H = $("#idContent").height();
        var wContainer = $("#idContent").width();
        var wTables = Math.floor((W / 100) * wContainer);
        $("#div_result_" + nameSimuChgt).css("width", W + "%");
        $("#div_graph_" + nameSimuChgt).css("width", W + "%");
        $("#div_graph_bubble_" + nameSimuChgt).css("width", W + "%");
        $("#div_graph_pie_" + nameSimuChgt).css("width", W + "%");
        $("#div_graph_bar_" + nameSimuChgt).css("width", W + "%");

        $("#graph_table_kpi" + j + "_simu" + i).css("height", H + "px"); // set the height of the content of a particular graph
        $("#graph_table_kpi_bubble" + j + "_simu" + i).css("height", H + "px"); // set the height of the content of a particular graph
        $("#graph_table_kpi_pie" + j + "_simu" + i).css("height", H + "px"); // set the height of the content of a particular graph
        $("#graph_table_kpi_bar" + j + "_simu" + i).css("height", H + "px"); // set the height of the content of a particular graph

        $("#forreport_graph_table_kpi" + j + "_simu" + i).css("height", H + "px"); // set the height of the content of a particular graph for report (div temp)
        // $('#report_div_graph_' + nameSimuChgt).css("width", "700px");
        // var t = document.getElementById('graph_table_kpi' + j + '_simu' + i);
        var tablegraph = document.getElementById("tr_table_" + nameID);
        tablegraph.innerHTML += "<td id=\"td_table_" + nameID + "\"></td>";


        var self = this;

        $("#Select_Deselect_" + nameID).click(function() {
            var show = $(":input", this)[0].checked;
            var hide = $(":input", this)[1].checked;
            if (hide) {
                self.controller.hideAllGraphs($(this));
            } else {
                self.controller.showAllGraphs($(this));
            }
        });

        $("#Select_Deselect_bubble_" + nameID).click(function() {
            var show = $(":input", this)[0].checked;
            var hide = $(":input", this)[1].checked;
            if (hide) {
                self.controller.hideAllGraphs($(this));
            } else {
                self.controller.showAllGraphs($(this));
            }
        });

        $("#Select_Deselect_pie_" + nameID).click(function() {
            var show = $(":input", this)[0].checked;
            var hide = $(":input", this)[1].checked;
            if (hide) {
                self.controller.hideAllGraphs($(this));
            } else {
                self.controller.showAllGraphs($(this));
            }
        });

        $("#Select_Deselect_bar_" + nameID).click(function() {
            var show = $(":input", this)[0].checked;
            var hide = $(":input", this)[1].checked;
            if (hide) {
                self.controller.hideAllGraphs($(this));
            } else {
                self.controller.showAllGraphs($(this));
            }
        });

        var divScroll = "table_kpi" + j + "_simu" + i;
        var divScrollJQ = "#" + divScroll;

        // builds a string containig all talbes id
        all += divScrollJQ;
        if (!(j + 1 == tabNameKPI.length && i + 1 == tabNameSimu.length)) {
            all += ",";
        }

        // creates a table containing indexes of next and prev tables for an index
        var tabNext = [];
        for (var next = i + 1; next < tabNameSimu.length; next++) {
            tabNext.push(next);
        }
        for (var prev = 0; prev < i; prev++) {
            tabNext.push(prev);
        }

        for (var scrollIndic = 0; scrollIndic < tabNext.length; scrollIndic++) {
            //create dynamically id of next tables
            var nextScroll = "";

            nextScroll = "table_kpi" + j + "_simu" + tabNext[scrollIndic];
            $("#" + divScroll)[0].nextScroll = nextScroll;
            $("#" + divScroll)[0].currentScroll = divScroll;


            var sync = function(e) {
                var $divs = $(all);
                var me = $(this);
                //remove the scroll event , else it loop for update the scroll
                var $other = $divs.not(me).off("scroll");
                // test if elements of the list depend of the same KPI line (a same KPI) than the KPI which call the event
                // if it is TRUE, then we launch the event
                // else do nothing

                var sub = me[0].id.substring(0, 10);
                $divs.each(function(index) {
                    if ($divs[index].id.indexOf(sub) > -1) {
                        $(this).scrollLeft(me.scrollLeft());
                    }

                });
                // wait a delay, then we add the event onscroll on all elements
                setTimeout(function() {
                    $other.on("scroll", sync);
                }, 10);
            };
            //define the event onscroll on the current table
            $("#" + divScroll).on("scroll", sync);
        }

        var txtTitle;
        var nameSim;
        // creates all necessary charts empty

        var graph = AmCharts.makeChart("graph_table_kpi" + j + "_simu" + i, {
            type: "serial",
            id: nameID,
            nameKPIUsed: tabNameKPI[j],
            nameSimu: tabNameSimu[i],
            nameKPI: tabNameKPITitle[j],
            nameID: tabNameId[i],
            nameKPIFormat: tabNameKPI[j],
            addClassNames: true,
            categoryField: "year",
            categoryAxis: {
                gridPosition: "start",
            },
            graphs: [{}],

            valueAxes: [{
                gridAlpha: 0.07,
                axisAlpha: 0,
                dashLength: 5,
                gridCount: 10,
                position: "left",
                title: unity,
            }],

            chartCursor: {
                cursorAlpha: 0,
            },
            chartScrollbar: {
                oppositeAxis: false,
                color: "FFFFFF",
            },
            titles: [{
                size: 13,
                text: tabNameKPITitle[j],
            }],
            dataProvider: [{}],
            path: AMCHARTS_GLOBAL_CONFIG.path,
            "export": {
                ...AMCHARTS_GLOBAL_CONFIG.export,
                position: "top-right",
            },
            legend: {
                position: "bottom",
                valueText: "",
            },

        });

        var barChart = AmCharts.makeChart("graph_table_kpi_bar" + j + "_simu" + i, {
            type: "serial",
            theme: "light",
            id: nameID,
            nameKPIUsed: tabNameKPI[j],
            nameSimu: tabNameSimu[i],
            nameKPI: tabNameKPITitle[j],
            nameID: tabNameId[i],
            nameKPIFormat: tabNameKPI[j],
            addClassNames: true,
            categoryField: "year",
            dataProvider: [{}],
            valueAxes: [{
                gridColor: "#FFFFFF",
                gridAlpha: 0.2,
                dashLength: 0,
            }],
            titles: [{
                size: 13,
                text: tabNameKPITitle[j],
            }],
            gridAboveGraphs: true,
            startDuration: 1,
            graphs: [{}],
            chartCursor: {
                categoryBalloonEnabled: false,
                cursorAlpha: 0,
                zoomable: false,
            },
            // categoryField: "id",
            categoryAxis: {
                gridPosition: "start",
                gridAlpha: 0,
                tickPosition: "start",
                tickLength: 20,
            },
            ...AMCHARTS_GLOBAL_CONFIG
        });

        var bubbleChart = AmCharts.makeChart("graph_table_kpi_bubble" + j + "_simu" + i, {
            type: "xy",
            theme: "light",
            dataDateFormat: "YYYY",
            startDuration: 1.5,
            trendLines: [],
            balloon: {
                adjustBorderColor: false,
                shadowAlpha: 0,
                fixedPosition: true,
            },
            graphs: [{}],
            valueAxes: [{
                id: "ValueAxis-1",
                axisAlpha: 0,
            }, {
                id: "ValueAxis-2",
                axisAlpha: 0,
                position: "bottom",
                type: "year",

            }],
            allLabels: [],
            titles: [{
                size: 13,
                text: tabNameKPITitle[j],
            }],
            dataProvider: [{}],

            ...AMCHARTS_GLOBAL_CONFIG,

            chartScrollbar: {
                offset: 15,
                scrollbarHeight: 5,
            },

            chartCursor: {
                pan: true,
                cursorAlpha: 0,
                valueLineAlpha: 0,
            },
        });

        var pieChart = AmCharts.makeChart("graph_table_kpi_pie" + j + "_simu" + i, {
            type: "pie",
            theme: "light",
            id: nameID,
            nameKPIUsed: tabNameKPI[j],
            nameSimu: tabNameSimu[i],
            nameKPI: tabNameKPITitle[j],
            nameID: tabNameId[i],
            nameKPIFormat: tabNameKPI[j],
            addClassNames: true,
            dataProvider: [],
            balloon: {
                fixedPosition: true,
            },
            titles: [{
                size: 13,
                text: tabNameKPITitle[j],
            }],
           ...AMCHARTS_GLOBAL_CONFIG
        });
        // define same charts but in the temp div used for creation of report PDF and Word
        var graphToReport = AmCharts.makeChart("forreport_graph_table_kpi" + j + "_simu" + i, {
            type: "serial",
            id: nameID,

            nameKPIUsed: tabNameKPI[j],
            nameSimu: tabNameSimu[i],
            nameKPI: tabNameKPITitle[j],
            nameID: tabNameId[i],
            nameKPIFormat: tabNameKPI[j],
            addClassNames: true,
            categoryField: "year",
            categoryAxis: {
                gridPosition: "start",
            },
            graphs: [{}],
            valueAxes: [{
                gridAlpha: 0.07,
                axisAlpha: 0,
                dashLength: 5,
                gridCount: 10,
                position: "left",
                title: unity,
            }],
            chartCursor: {
                cursorAlpha: 0,
            },
            chartScrollbar: {
                oppositeAxis: false,
                color: "FFFFFF",
            },
            titles: [{
                size: 13,
                text: tabNameKPITitle[j],
            }],
            dataProvider: [{}],
            path: AMCHARTS_GLOBAL_CONFIG.path,
            "export": {
                ...AMCHARTS_GLOBAL_CONFIG.export,
                position: "top-right",
            },
            legend: {
                position: "bottom",
                valueText: "",
            },
        });

        var valueAxis = new AmCharts.ValueAxis();
        graph.addValueAxis(valueAxis);
        graphToReport.addValueAxis(valueAxis);

        this.graphCollec.push({
            idSimu: tabNameId[i],
            data: {
                objGraph: graph,
            },
        });
        this.graphBubbleCollec.push({
            idSimu: tabNameId[i] + "_bubble",
            data: {
                objGraph: bubbleChart,
            },
        });

        this.graphPieCollec.push({
            idSimu: tabNameId[i] + "_pie",
            data: {
                objGraph: pieChart,
            },
        });
        this.graphBarCollec.push({
            idSimu: tabNameId[i] + "_bar",
            data: {
                objGraph: barChart,
            },
        });
        // add graph element for report use
        this.graphCollecFromTempToReport.push({
            idSimu: tabNameId[i],
            data: {
                objGraph: graphToReport,
            },
        });
    }

    setScenarioInformation(idScen, info) {
        $("#graph_col_scen_" + idScen).html(info);
    }

    /**

     Fct loops on content chart graphs and show all elements of the graphs
     Check a radio btn call this fct
     /!\ : showGraph() is like a removeGraph
     we save content graphs chart in save_graphsLine table
     */
    showAllGraphs(obj, save_graphsLine) {
        var cut = obj[0].id.replace("Select_Deselect_", "");
        for (var element in save_graphsLine) {
            if (save_graphsLine.hasOwnProperty(element)) {
                if (cut == save_graphsLine[element].data.objGraph.id) {
                    if (save_graphsLine[element].data.objGraph.graphs != undefined && save_graphsLine[element].data.objGraph.graphs.length > 0) {

                        for (var graphEl in save_graphsLine[element].data.objGraph.graphs) {

                            var chart = save_graphsLine[element].data.objGraph;
                            var graph = chart.graphs[graphEl];
                            chart.showGraph(graph);
                        }
                    }
                }
            }
        }
    }

    /**
     Fct loops on content chart graphs and hide all elements of the graphs
     Check a radio btn call this fct
     /!\ : hideGraph() is like a removeGraph
     we save content graphs chart in save_graphsLine table
     */
    hideAllGraphs(obj, save_graphsLine) {
        var cut = obj[0].id.replace("Select_Deselect_", "");
        for (var element in save_graphsLine) {
            if (save_graphsLine.hasOwnProperty(element)) {
                if (cut == save_graphsLine[element].data.objGraph.id) {
                    if (save_graphsLine[element].data.objGraph.graphs != undefined && save_graphsLine[element].data.objGraph.graphs.length > 0) {
                        for (var i in save_graphsLine[element].data.objGraph.graphs) {
                            var chart = save_graphsLine[element].data.objGraph;
                            var graph = chart.graphs[i];
                            chart.hideGraph(graph);
                        }
                    }
                }
            }
        }
    }

    /*onResultLoadedColor*/
    boxAnalysingResultColoring(nameKPI, unity, params, data) {

        if ($("#box_analysing_Result_Coloring").length == 0) {
            this.UI.classBindData.firstKPIColoring = nameKPI;
            this.createboxResultLoadedColor(nameKPI, unity, this.controller.CallBackClose.bind(this.controller));
        } else {

            //igo TMA 2
            //cas meme kpi et echelles differentes

            //if (this.UI.classBindData.firstKPIColoring == nameKPI) {
            //    $("#box_analysing_Result_Coloring").show();
            // } else {
            $("#box_analysing_Result_Coloring").remove();
            this.createboxResultLoadedColor(nameKPI, unity, this.controller.CallBackClose.bind(this.controller));
            // }
        }
        $("#box_body_analysing_Result_Coloring").attr("style", "overflow:hidden");

        var timeScale = params.tab[0][1];

        if (data[timeScale] != undefined && data[timeScale].data != undefined) {
            $("#Result_Coloring_slider").ionRangeSlider({
                type: "single",
                values: data[timeScale].data,
                grid: true,
                // force_edges: true,
                onFinish: this.controller.Slider_OnChange.bind(this.controller),
                onStart: this.controller.Slider_OnChange.bind(this.controller),
            });
            this.ColoringSlider = $("#Result_Coloring_slider").data("ionRangeSlider");
            this.ColoringSlider.callOnStart();
        }
    }

    createboxResultLoadedColor(nameKPI, unit, CallBackClose) {

        var c = "<div id=\"box_analysing_Result_Coloring_content zdynamic\">";
        c += nameKPI;
        c += "          <div style=\"text-align:left; \">";
        c += "              <div style=\"width: 98%; position : absolute; height:calc(100% - 130px); margin-top:5px;\">";
        c += "                      <div id=\"screen_Result_Coloring_spider\"  style=\"width:100%; height:100%;\">";
        c += "                          <div id=\"move_Result_Coloring_spider\" style=\"position : relative;height:75%;\">";
        c += "                              <div style=\"width:92%;height:50px;position:absolute\"><input id=\"Result_Coloring_slider\" readonly=\"\" value=\"\" style=\"display:none;width:70%;height:50px;\"/>";
        c += "                              </div>";
        c += "                              <canvas id=\"Canvas_Coloring\" style=\"width:92%;height:25px;position:absolute;margin-top:70px;\">",
            c += "                              </canvas>";

        c += "  <div id=\"minmaxColor\" style=\"position: absolute;bottom: -30px;width: 100%;\" >";
        c += "    <div id=\"minColor\"     align=\"left\"    style=\"width: 30%; display: inline-block;\"   ></div>";
        c += "    <div id=\"unitColor\"    align=\"center\"  style=\"width: 30%; display: inline-block;\"  >" + unit + "</div>";
        c += "    <div id=\"maxColor\"     align=\"right\"   style=\"width: 30%; display: inline-block;\"   ></div>";
        c += "  </div>";

        c += "                          </div>";
        c += "                      </div>";
        c += "              </div>";
        c += "     </div>";

        c += "  </div>";

        var f = "";
        this.UI.CreateBox("analysing_Result_Coloring", {
            draggable: true,
            group: "Analysing",
            useSkin: true,
            title: this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_COLOR_TITLE,
            content: c,
            footer: f,
            addOverlay: true,
            visible: true,
            menuBt: {
                close: true,
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "135px",
                left: "9%",
                width: "400px",
                height: "250px",
                "z-index": Zindex + 1,

            },
        }, CallBackClose);
        Zindex = Zindex + 1;

        this.UI.setZIndexToMaxAsync("box_analysing_Result_Coloring");
    }

    /*Slider_OnChange*/
    sliderOnChange(Val_Min, Val_Max, Coul1, Coul2) {
        function rgb2hex(red, green, blue) {
            var rgb = blue | (green << 8) | (red << 16);
            return "#" + (0x1000000 + rgb).toString(16).slice(1);
        }

        $("#minColor").text(Val_Min == null ? "-" : Val_Min);
        $("#maxColor").text(Val_Max == null ? "-" : Val_Max);
        var canvas = document.getElementById("Canvas_Coloring");
        if (canvas.getContext) {
            var ctx = canvas.getContext("2d");
            var lingrad = ctx.createLinearGradient(0, 0, canvas.width, 0);
            lingrad.addColorStop(0, rgb2hex(Coul1[0], Coul1[1], Coul1[2]));
            lingrad.addColorStop(1, rgb2hex(Coul2[0], Coul2[1], Coul2[2]));
            ctx.fillStyle = lingrad;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }
    }

    /*ExportResult*/
    isNCExportEnaled() {
        return this.UI.contentFileUserConfig.ConfCAVI[0].netcdfExport;
    }

    /*
     Fct called when user click on 'Export results' button
     There is NetCDF and Excel files type available
     */

    exportResult() {
        if (!this.isNCExportEnaled()) {
            this.controller.LaunchCreateExport.apply(this.controller);
        } else {
            //Build the export box and define function called when click on its btns
            if ($("#box_analysing_Result_Export").length == 0) {
                this.createboxResultExport(function() {
                    return true;
                });

                $("#analysing_Result_Export_excel").click(this.controller.LaunchCreateExport.bind(this.controller));
                $("#analysing_Result_Export_netcdf").click(this.controller.LaunchCreateExport.bind(this.controller));
            } else {
                $("#box_analysing_Result_Export").show();
            }
            this.UI.setZIndexToMaxAsync("box_analysing_Result_Export");
            //$("#box_analysing_Result_Export").css("zIndex", Zindex + 1);
        }
    }

    createboxResultExport(CallBackClose) {

        var c = "<div id=\"analysing_Result_Export_content\">";
        c += "  <div style=\"width:110px;position:absolute;\"><label><input type=\"radio\" name=\"analysing_Result_Export_radio\" id=\"analysing_Result_Export_excel\" ><a class=\"btn btn-app\"><i class=\"fa fa-file-excel-o\"></i> ";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_EXPORT_EXCEL;
        c += "      </a></label>";
        c += "      <div class=\"load_wait_fct_reportExcel\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div>";
        c += "  </div>";
        c += "&nbsp;&nbsp;&nbsp;";
        c += "  <div style=\"width:110px;position:relative;float:right;left:-50px\"><label><input type=\"radio\" name=\"analysing_Result_Export_radio\" id=\"analysing_Result_Export_netcdf\"><a class=\"btn btn-app\"><i class=\"fa fa-file-text-o\"></i> ";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_EXPORT_CDF;
        c += "      </a></label>";
        c += "      <div class=\"load_wait_fct_reportCDF\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div>";
        c += "        </div>";
        c += "</div>";

        var f = "";
        //f+= '<div class="pull-right" ><button class="btn btn-primary use-skin classBtnClick" id="analysing_result_export_apply">';
        //f += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_EXPORT_BTN;
        //f += '</button></div>';
        this.UI.CreateBox("analysing_Result_Export", {
            draggable: true,
            group: "Analysing",
            useSkin: true,
            title: this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_EXPORT,
            content: c,
            footer: f,
            addOverlay: true,
            visible: true,
            menuBt: {
                close: true,
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "130px",
                left: "8%",
                width: "350px",
                height: "220px",
                "z-index": Zindex + 1,
            },
        }, CallBackClose);
        Zindex = Zindex + 1;
    }

    /* ReportResult
     Fct called when user click on 'Report' button
     There is PDF and Word files type available
     */
    reportResult() {
        //Build the report box and define function called when click on its btns
        if ($("#box_analysing_Result_Report").length == 0) {
            this.createboxResultReport(function() {
                return true;
            });
            $("#analysing_Result_Report_pdf").click(this.controller.LaunchCreateReport.bind(this.controller));
            $("#analysing_Result_Report_word").click(this.controller.LaunchCreateReport.bind(this.controller));
        } else {
            $("#box_analysing_Result_Report").show();
        }
        this.UI.setZIndexToMaxAsync("box_analysing_Result_Report");
        //$("#box_analysing_Result_Report").css("zIndex", Zindex + 1);
    }

    createboxResultReport(CallBackClose) {

        var f = "";
        var c = "<div id=\"analysing_Result_Report_content\">";
        c += "  <div style=\"width:110px;position:absolute;\"><label><input type=\"radio\" name=\"analysing_Result_Report_radio\" id=\"analysing_Result_Report_pdf\" ><a class=\"btn btn-app\"><i class=\"fa fa-file-pdf-o\"></i> ";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_REPORT_PDF;
        c += "      </a></label>";
        c += "      <div class=\"load_wait_fct_exportPDF\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div>";
        c += "  </div>";
        c += "&nbsp;&nbsp;&nbsp;";
        c += "  <div style=\"width:110px;position:relative;float:right;left:-50px\"><label><input type=\"radio\" name=\"analysing_Result_Report_radio\" id=\"analysing_Result_Report_word\"><a class=\"btn btn-app\"><i class=\"fa fa-file-word-o\"></i> ";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_REPORT_WORD;
        c += "      </a></label>";
        c += "      <div class=\"load_wait_fct_exportWORD\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div>";

        c += "  </div>";
        c += "</div>";


        this.UI.CreateBox("analysing_Result_Report", {
            draggable: true,
            group: "Analysing",
            useSkin: true,
            title: this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_REPORT,
            content: c,
            footer: f,
            addOverlay: true,
            visible: true,
            menuBt: {
                close: true,
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "130px",
                left: "8%",
                width: "350px",
                height: "220px",
                "z-index": Zindex + 1,
            },
        }, CallBackClose);
        Zindex = Zindex + 1;
    }


    /*LaunchCreateReport
     Called when user choose a type of Report export (for graphs and arrays)
     obj: element event onclick
     */
    launchCreateReport(obj) {
        if (obj.target.id.indexOf("word") > -1) {
            this.controller.setReportChosen("WORD");
            $("#analysing_Result_Report_word").unbind("click", this.controller.LaunchCreateReport.bind(this.controller));

        } else if (obj.target.id.indexOf("pdf") > -1) {
            this.controller.setReportChosen("PDF");
            $("#analysing_Result_Report_pdf").unbind("click", this.controller.LaunchCreateReport.bind(this.controller));

        }
        var treeKPI = $.fn.zTree.getZTreeObj("treeAnaResKpi");
        //if (this.treeUO != null && treeKPI != null && this.tree != null) {
        if (treeKPI != null && this.tree != null) {
            var UOselected = this.controller.GestionUrbObj.getCheckedNodesUO();
            var KPIselected = treeKPI.getCheckedNodes(true);

            // var simCheckedNodes = this.tree.getSelectedNodes(); // !!!!!!!!!!!!! si plisuers simcase cochées comment c'est stocké? sinon forcé en tant que tableau pour traitement de la suite
            var simCheckedNodes = this.tree.getCheckedNodes(true).filter(function(node) {
                return !node.isParent;
            });
            var simSelected = simCheckedNodes;
            // for (var i = 0; i < simCheckedNodes.length; i++) {
            //     if (simCheckedNodes[i].isParent == false) {
            //         simSelected.push(simCheckedNodes[i]);
            //     }
            // }

            var boolJustFirst = true;

            if (simSelected.length > 0 && UOselected.length > 0 && KPIselected.length > 0 && boolJustFirst == true) {

                // check if we have al least 1 selected element on each panels
                if (this.controller.getReportChosen() == "PDF") {
                    $(".load_wait_fct_exportPDF").show();
                } else if (this.controller.getReportChosen() == "WORD") {
                    $(".load_wait_fct_exportWORD").show();
                }
                // set disable radio btn and icon fo report
                $(".btn-app").prop("disabled", true);
                $("#analysing_Result_Report_word").prop("disabled", true);
                $("#analysing_Result_Report_pdf").prop("disabled", true);

                this.controller.setListSimuSelectedReport(simSelected);

                this.controller.ResultGraph(true);
                this.controller.checkChoice();
            } else {
                this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_REPORT_TREE);
                return -1;
            }
        } else {
            this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_REPORT_TREE);
            return -1;
        }
    }

    /*
    Called when user choose a type of Export file (for simu cases)
    obj: element event onclick
    */
    launchCreateExport(obj) {
        if (!this.isNCExportEnaled() || obj.target.id.indexOf("excel") > -1) {
            this.controller.setExportChosen("EXCEL");
            $("#analysing_Result_Export_excel").unbind("click", this.controller.LaunchCreateExport.bind(this.controller));
        } else if (obj.target.id.indexOf("netcdf") > -1) {
            this.controller.setExportChosen("NETCDF");
            $("#analysing_Result_Export_netcdf").unbind("click", this.controller.LaunchCreateExport.bind(this.controller));
        }
        this.initTree();
        if (this.tree != null) {
            var simSelected = [];
            var simCheckedNodes = this.tree.getCheckedNodes();
            for (var i = 0; i < simCheckedNodes.length; i++) {
                if (!simCheckedNodes[i].isParent) {
                    simSelected.push(simCheckedNodes[i]);
                }
            }
            if (simSelected.length == 0) {
                this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_EXPORT_NOSIM);
                return -1;
            }

            if (!this.isNCExportEnaled() || this.controller.getExportChosen() == "EXCEL") {
                $(".btn-app").prop("disabled", true);
                $("analysing_Result_Export_excel").prop("disabled", true);
                this.controller.ExportEXCEL(simSelected);
            } else if (this.controller.getExportChosen() == "NETCDF") {
                $(".btn-app").prop("disabled", true);
                $("#analysing_Result_Export_netcdf").prop("disabled", true);
                this.controller.ExportNETCDF(simSelected);
            }
        }
    }

    /*ExportNETCDF
    */
    exportNetcdfOrExcel(urls) {
        this.UI.createDowloadAlertLink(urls);
        $(".btn-app").prop("disabled", false);
        $("#analysing_Result_Export_netcdf").prop("disabled", false);

    }

    /*CreateReportWord
     Fct which add elements to create the report in Docx format and donwload the result
     */

    createReportWord() {
        var nameMosa = this.UI.classBindData.mosaName;
        var userName = this.UI.classBindData.userFullname;

        var img3D = undefined;

        var content = "";

        var charts = {};
        var nbCharts = this.graphCollecFromTempToReport.length;
        for (var i = 0; i < nbCharts; i++) {
            charts[i] = this.graphCollecFromTempToReport[i];
        }

        // For the Word report : requires an intermediate stage : convert link src image to base 64 compatible with the plugin html-docx.js
        // stores in a temp div the img from the 3D view
        if (this.controller.is3dLoaded()) {
            img3D = this.controller.getSnapShot(function(data) {
                $("#imgReport").append("<img src='" + data + "' class='toConvert64' style='text-align:center;width:500px;height:300px;'/>");
            }, 500, 300);
        }
        // stores the result of context request
        var ctn = [];

        // - defines name simu from this.tabAssocSimuId_name association table
        // calls the convert fct to transform img3D in base64
        var listGraph = () => {
            var img = this.convertImgTo64(); // converts img 3D view

            content += "<div><h3 style='text-align:center;font-weight:bold;'>Report MOSA : " + nameMosa + "</h3>";
            content += "<h5 style='text-align:left;font-weight:bold;'>User name : " + userName + "</h5>";
            content += "<div >" + img + "</div>";
            content += "<br clear='all' style='page-break-before:always' />";
            content += "</div>";

            // set correspondance between id sim and its name in objToCreateTableReport array
            this.controller.updateLinkObjToCreateTableReport();


            for (var x in charts) {
                if (charts.hasOwnProperty(x)) {
                    var chart = charts[x].data.objGraph;
                    this.controller.findCtxFromSimID(ctn, chart, this.controller, "Word", content);
                }
            }
        };


        setTimeout(listGraph, 6000);
    }


    createReportPDF() {
        var nameMosa = this.UI.classBindData.mosaName;
        var userName = this.UI.classBindData.userFullname;
        var img3D = undefined;

        var charts = {};
        var nbCharts = this.graphCollecFromTempToReport.length;
        for (var i = 0; i < nbCharts; i++) {
            charts[i] = this.graphCollecFromTempToReport[i];
        }

        // stores body of the PDF report
        var content = [];

        // get the image of the 3D view
        // and add some texts
        if (this.controller.is3dLoaded()) {
            img3D = this.controller.getSnapShot(function(data) {
                content.push({
                    text: "Report MOSA : " + nameMosa,
                    fontSize: 17,
                    bold: true,
                    alignment: "center",
                }, {
                    text: " ",
                    fontSize: 17,
                    bold: true,
                    alignment: "center",
                }, {
                    text: "User name : " + userName,
                    fontSize: 14,
                    bold: true,
                    alignment: "left",
                }, {
                    text: " ",
                    fontSize: 17,
                    bold: true,
                    alignment: "center",
                }, {
                    image: data,
                    width: 500,
                    height: 300,
                    alignment: "center",
                    "pageBreak": "after",

                });

            }, 500, 300);
        }
        var listGraph = () => {

            // set correspondance between id sim and its name in objToCreateTableReport array
            this.controller.updateLinkObjToCreateTableReport();

            for (var x in charts) {
                if (charts.hasOwnProperty(x)) {
                    var chart = charts[x].data.objGraph;
                    this.controller.findCtxFromSimID(content, chart, this.controller, "PDF", "");
                }
            }

        };

        setTimeout(listGraph, 6000);
    }

    /*
 Fct converts a img URL to a base 64 URL
 - based on the convert example of html-docx-js plugin
 - for each graphs, calls the context request to define infos about the simulations and context related
 - convert the image src to canvas.toDataURL() and returns result to insert in the report content string
 */
    convertImgTo64() {
        const delay = ms => new Promise(_ => setTimeout(_, ms));
        var regularImages = document.querySelectorAll(".toConvert64");
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        for (var id in regularImages) {
            if (regularImages.hasOwnProperty(id)) {
                var imgElement = regularImages[id];
                delay(200).then(() => {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    canvas.width = imgElement.width == 0 ? 300 : imgElement.width;
                    canvas.height = imgElement.height == 0 ? 300 : imgElement.height;
                    ctx.drawImage(imgElement, 0, 0);
                    // by default toDataURL() produces png image, but you can also export to jpeg
                    // checkout function's documentation for more details
                    var dataURL = canvas.toDataURL();
                    imgElement.setAttribute("src", dataURL);
                });
            }
        }/*
        [].forEach.call(regularImages, function(imgElement) {
            if ($(imgElement).width()==0 && $(imgElement).height()==0){
                console.log("Wait");
                await waitFct(200);
            }
            // preparing canvas for drawing
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            canvas.width = $(imgElement).width();
            canvas.height = $(imgElement).height();
            ctx.drawImage(imgElement, 0, 0,$(imgElement).width(),$(imgElement).height());
            // by default toDataURL() produces png image, but you can also export to jpeg
            // checkout function's documentation for more details
            var dataURL = canvas.toDataURL();
            imgElement.setAttribute("src", dataURL);
        });
        */
        canvas.remove();

        var img = $("#imgReport").prop("innerHTML");
        $("#imgReport").empty();
        return img;
    }


    appendContentWord(content, chart, listSimuSelectedReport, d, element) {
        content.push("<div>");
        content.push("<h5 style='text-align:left;font-weight:bold;'>Simulation : " + chart.nameSimu + "</h5>");
        content.push("<p style='font-size:12px'>Description " + chart.nameSimu + " : " + listSimuSelectedReport[element].description + "</p>");
        content.push("<p style='font-size:12px;font-weight:bold;'>KPI : " + chart.nameKPI + "</p>");
        content.push("<p style='font-size:12px;font-weight:bold;'>Context : " + d.name + "</p>");
        content.push("<p style='font-size:12px;font-weight:bold;'>Description context : " + d.description + "</p>");
        // image graphs
        let ec = chart["export"].setup.chart;
        let w = 800;
        let h = Math.floor(ec.divRealHeight * w * 1.0 / ec.divRealWidth);
        $("#imgReport").append("<img src='" + ec.exportedImage + "' class='toConvert64' style='text-align:center' width='" + w + "' height='" + h + "'/>");
        content.push("<div >" + this.convertImgTo64() + "</div>");
        content.push("<br clear='all' style='page-break-after:always' />");
        content.push("</div>");
    }

    appendAllTableWord(content, contentReport, objToCreateTableReport) {
        // add all tables at the end of the report
        var arrString = content.join("");

        contentReport += arrString;
        contentReport += "<div><h3 style='text-align:left;font-weight:bold;'>TABLES : </h3>";
        for (var el in objToCreateTableReport) {
            if (objToCreateTableReport.hasOwnProperty(el)) {
                contentReport += "<br/>";
                contentReport += "<h5 style='text-align:center;font-weight:bold;'>" + objToCreateTableReport[el]["name"] + "</h5><br/>";
                contentReport += "<h5 style='text-align:left;font-weight:bold;'>" + objToCreateTableReport[el]["nameSim"] + "</h5><br/>";
                var table = $("#table_result_" + objToCreateTableReport[el]["IDsim"] + "_" + objToCreateTableReport[el]["techID"]);
                contentReport += "<div  style='font-size:9px;'> " + table.prop("outerHTML") + "</div>";

            }
        }
        contentReport += "</div>";
        contentReport += "</body></html>";
        var contentHTML = "<!DOCTYPE html><body>" + contentReport;

        import(/* webpackPreload: true */"html-docx-js/dist/html-docx").then(module => {
            const {default: htmlDocx} = module;

            var converted = htmlDocx.asBlob(contentHTML, {
                orientation: "landscape",
                margins: {
                    left: 520,
                },
            });
            saveAs(converted, this.UI.classBindData.mosaName + ".docx");
            $(".btn-app").prop("disabled", false);
            $("#analysing_Result_Report_word").prop("disabled", false);
            $("#analysing_Result_Report_pdf").prop("disabled", false);
            $(".load_wait_fct_exportWORD").hide();
            $("#div_tempReport").hide();
        });

    }

    appendAllTablePdf(content, objToCreateTableReport) {
        // add all tables at the end of the report
        content.push({
            text: "TABLES : ",
            fontSize: 14,
            bold: true,
            alignment: "left",
        });
        for (var el in objToCreateTableReport) {
            if (objToCreateTableReport.hasOwnProperty(el)) {
                content.push({
                    text: " ",
                    fontSize: 17,
                    bold: true,
                    alignment: "left",
                }, {
                    text: objToCreateTableReport[el]["name"],
                    fontSize: 14,
                    bold: true,
                    alignment: "center",
                }, {
                    text: " ",
                    fontSize: 17,
                    bold: false,
                    alignment: "left",
                }, {
                    text: objToCreateTableReport[el]["nameSim"],
                    fontSize: 14,
                    bold: false,
                    alignment: "left",
                }, {
                    text: " ",
                    fontSize: 17,
                    bold: false,
                    alignment: "left",
                });
                var table = $("#table_result_" + objToCreateTableReport[el]["IDsim"] + "_" + objToCreateTableReport[el]["techID"]);
                var simpleHtm = table.prop("outerHTML");
                this.controller.parseHtmlTable(content, simpleHtm);
                content.push({
                    text: " ",
                    fontSize: 17,
                    bold: false,
                    alignment: "left",
                });
            }
        }
        // fct creating the pdf from the 'content' array
        var docDefinition = {
            content: content,
            pageOrientation: "landscape",
        };
        var name = this.UI.classBindData.mosaName + ".pdf";
        pdfMake.createPdf(docDefinition).download(name);
        $(".btn-app").prop("disabled", false);
        $("#analysing_Result_Report_word").prop("disabled", false);
        $("#analysing_Result_Report_pdf").prop("disabled", false);
        $(".load_wait_fct_exportPDF").hide();
        $("#div_tempReport").hide();
    }


    /*
     Fct which add tables containing values in 'tabResultTable_<simid>' div
     Adds buttons on theses DataTables tables to export content
     data : data response of getOpenDap() request
     params : params returned by the getOpenDap() request
     dataType: technicalID of the KPI
     timeScale : type of timescale of the KPI
     pidKind: type of UO pid
     tabUO: selected UO
     simId: simulation ID
     */
    updateValueGraphTab(data, params, dataType, timeScale, pidKind, tabUO, simId, unity) {
        if (data[dataType] != undefined) {
            //Tableau
            // Titre du tableau : displayName
            var name = "";
            var labelSave = [];
            if (this.UI.classBindData.save_LABEL != undefined && this.UI.classBindData.save_LABEL.length > 0) {
                labelSave = this.UI.classBindData.save_LABEL;
            } else if (this.controller.getSaveMetadataLabel() != undefined && this.controller.getSaveMetadataLabel().length > 0) {
                labelSave = this.controller.getSaveMetadataLabel();
            }
            for (var element in labelSave) {
                if (labelSave.hasOwnProperty(element)) {
                    if (labelSave[element].name == dataType) {
                        name = labelSave[element].displayName;
                    }
                }
            }

            // stores simID, technical Id of KPI and the display name
            this.controller.appendObjToCreateTableReport({
                "IDsim": simId,
                "nameSim": "",
                "techID": dataType,
                "name": name,
            });


            var it = "<th>pid</th><th>" + this.contentFileJSON.MENU_LABEL_NAME + "</th>";
            var head = "<div><h4 class=\"box-title\">" + name + "</h4><div class=\"table_unit\">" + unity + "</div>";
            var body = "<div class=\"box\"><div class=\"box-header\">";
            body += "</div><div class=\"box-body\"><table id=\"table_result_" + simId + "_" + dataType + "\" class=\"table table-bordered table-striped table-results-calculs\" style=\"border:2px solid #767272\"><thead><tr>" + it;
            var footer = "<tfoot><tr>" /* + it*/;

            //Init TH header / footer
            for (var t = 0; t < data[timeScale]["data"].length; t++) {
                it = "<th>" + data[timeScale]["data"][t] + "</th>";
                body += it;
                // footer += it;             //on enléve la ligne footer qui reprend les années
            }
            body += "</tr></thead><tbody>";
            var id;
            for (var y = 0; y < data[pidKind]["data"].length; y++) {
                id = data[pidKind]["data"][y];
                if (tabUO[id] != undefined) { //Filtre pour ne garder que les cochés.
                    body += "<tr><td>" + id + "</td>";
                    body += "<td>" + tabUO[id] + "</td>";
                    for (var x = 0; x < data[timeScale]["data"].length; x++) {
                        body += "<td>" + fixDigits(data[dataType]["data"][0][x][y]) + "</td>"; //TODO traiter quand plus de 2 dimensions.
                    }
                    body += "</tr>";
                }
            }

            var nameID = "simu-" + simId + "-" + dataType;


            var find = "/";
            var re = new RegExp(find, "g");
            nameID = nameID.replace(re, "-");

            body += "</tbody>";
            footer += "</tr></tfoot></table></div></div></div>";
            $("#tabResultTable_" + simId).append(head + body + footer);


            $("#table_result_" + simId + "_" + dataType).DataTable({
                dom: "Bfrtip",
                buttons: ["copy", "print",
                    {
                        extend: "excel",
                        filename: name,
                    }, {
                        extend: "pdf",
                        filename: name,
                    }, {
                        extend: "csv",
                        filename: name,
                    },

                ],
            });
            $("#td_table_" + nameID).append(body + footer);

            // i = params.tab.length;
            this.controller.updateNbCptRes();
            return;
        }
    }

    updateGraphVal(data, params, dataType, timeScale, pidKind, tabUO, simId, kpiMeta) {

        for (var m = 0; m < this.graphCollec.length; m++) {
            if (data.name.indexOf(simId) > -1 && this.graphCollec[m].idSimu == simId && this.graphCollec[m].data.objGraph.nameKPIUsed == dataType) {
                this.controller.updateValueGraphTab(data, params, dataType, timeScale, pidKind, tabUO, simId);

                if (data[dataType] != undefined) {
                    //Graph
                    var chartData = [];
                    var bubbleData = [];
                    var pieData = [];
                    var barData = [];

                    for (var x = 0; x < data[timeScale]["data"].length; x++) {
                        var item = {
                            "year": data[timeScale]["data"][x],
                        };
                        var itemBubble = {
                            "year": data[timeScale]["data"][x],
                        };
                        var allGraphs = [];
                        var bubbleGraphs = [];
                        var barGraphs = [];
                        var allGraphsForReport = [];
                        var idValue;

                        for (var y = 0; y < data[pidKind]["data"].length; y++) {
                            var id = data[pidKind]["data"][y].toString();
                            if (tabUO[id] != undefined) { //Filtre pour ne garder que les cochés.
                                if (data[dataType] != undefined && data[dataType]["data"] != undefined && data[dataType]["data"][0][x][y] != undefined && data[dataType]["data"][0][x][y] != NETCDF_EMPTY) {
                                    idValue = id;

                                    item[id] = fixDigits(data[dataType]["data"][0][x][y]);
                                    itemBubble[id] = item[id];
                                    itemBubble["value"] = 10;

                                    allGraphs.push(this.controller.graphConfigFromMetadata({
                                        type: "line",
                                        title: tabUO[id],
                                        valueField: id,
                                        lineAlpha: 0.6,
                                        lineThickness: 3,
                                        fillAlphas: 0,
                                        balloonText: tabUO[id] + ": [[value]]",
                                    }, kpiMeta));
                                    allGraphsForReport.push(this.controller.graphConfigFromMetadata({
                                        type: "line",
                                        title: tabUO[id],
                                        valueField: id,
                                        lineAlpha: 0.6,
                                        lineThickness: 3,
                                        fillAlphas: 0,
                                        balloonText: tabUO[id] + ": [[value]]",
                                    }, kpiMeta));
                                    barGraphs.push({
                                        type: "column",
                                        title: tabUO[id],
                                        valueField: id,
                                        fillAlphas: 0.8,
                                        lineAlpha: 0.2,
                                        balloonText: tabUO[id] + ": [[value]]",
                                    });

                                    bubbleGraphs.push({
                                        balloonText: "x:<b>[[x]]</b> y:<b>[[y]]</b><br>value:<b>[[value]]</b>",
                                        bullet: "circle",
                                        bulletBorderAlpha: 0.2,
                                        bulletAlpha: 0.8,
                                        lineAlpha: 0,
                                        fillAlphas: 0,
                                        valueField: "value",
                                        xField: id,
                                        yField: "year",
                                        maxBulletSize: 100,
                                    });
                                }
                            }
                        }

                        chartData.push(item);
                        bubbleData.push(itemBubble);
                        barData.push(item);

                        if (item[idValue] != 0) {
                            pieData.push({
                                title: item.year,
                                valeur: Math.abs(item[idValue]),
                            });
                        }
                    }

                    this.graphCollec[m].data.objGraph.graphs = allGraphs;
                    this.graphCollec[m].data.objGraph.dataProvider = chartData;
                    this.graphCollec[m].data.objGraph.validateData();
                    this.graphCollec[m].data.objGraph.dataChanged = true;

                    //bubble charts
                    this.graphBubbleCollec[m].data.objGraph.graphs = bubbleGraphs;
                    this.graphBubbleCollec[m].data.objGraph.dataProvider = bubbleData;
                    this.graphBubbleCollec[m].data.objGraph.validateData();
                    this.graphBubbleCollec[m].data.objGraph.dataChanged = true;

                    //Pie charts
                    this.graphPieCollec[m].data.objGraph.dataProvider = pieData;
                    this.graphPieCollec[m].data.objGraph.valueField = "valeur";
                    this.graphPieCollec[m].data.objGraph.titleField = "title";
                    this.graphPieCollec[m].data.objGraph.autoDisplay = true;
                    this.graphPieCollec[m].data.objGraph.validateData();
                    this.graphPieCollec[m].data.objGraph.dataChanged = true;

                    //Bar diagrams
                    this.graphBarCollec[m].data.objGraph.graphs = barGraphs;
                    this.graphBarCollec[m].data.objGraph.dataProvider = barData;
                    this.graphBarCollec[m].data.objGraph.categoryField = "year";
                    this.graphBarCollec[m].data.objGraph.validateData();
                    this.graphBarCollec[m].data.objGraph.dataChanged = true;

                    /// for report
                    this.graphCollecFromTempToReport[m].data.objGraph.graphs = allGraphsForReport;
                    this.graphCollecFromTempToReport[m].data.objGraph.dataProvider = chartData;
                    this.graphCollecFromTempToReport[m].data.objGraph.validateData();
                    this.graphCollecFromTempToReport[m].data.objGraph.dataChanged = true;

                    this.controller.updateValGraph({
                            "nameKPI": this.graphCollec[m].data.objGraph.nameKPIFormat,
                            "min": this.graphCollec[m].data.objGraph.valueAxes[0].min,
                            "max": this.graphCollec[m].data.objGraph.valueAxes[0].max,
                        },
                        this.graphCollec[m], this.graphBubbleCollec[m], this.graphPieCollec[m], this.graphBarCollec[m]);

                }
            }
        }
    }

    updateGraphValOneSimu(k, data, params, dataType, timeScale, pidKind, tabUO, simId, kpiMeta) {
        if (!this.graphCollec[k]) return;

        if (data[dataType] != undefined) {
            //Graph

            var chartData = [];
            var bubbleData = [];
            var pieData = [];
            var barData = [];
            var idValue;

            for (var x = 0; x < data[timeScale]["data"].length; x++) {
                var item = {
                    "year": data[timeScale]["data"][x],
                };
                var itemBubble = {
                    "year": data[timeScale]["data"][x],
                };
                var allGraphs = [];
                var bubbleGraphs = [];
                var barGraphs = [];
                var allGraphsForReport = [];
                var id;

                for (var y = 0; y < data[pidKind]["data"].length; y++) {
                    id = data[pidKind]["data"][y].toString();
                    if (tabUO[id] != undefined) { //Filtre pour ne garder que les cochés.
                        if (data[dataType]["data"][0][x][y] != undefined && data[dataType]["data"][0][x][y] != NETCDF_EMPTY) {
                            idValue = id;
                            //TODO traiter quand plus de 2 dimensions.
                            item[id] = fixDigits(data[dataType]["data"][0][x][y]);
                            itemBubble[id] = item[id];
                            itemBubble["value"] = 10;

                            allGraphs.push(this.controller.graphConfigFromMetadata({
                                type: "line",
                                title: tabUO[id],
                                valueField: id,
                                lineAlpha: 0.6,
                                lineThickness: 3,
                                fillAlphas: 0,
                                balloonText: tabUO[id] + ": [[value]]",
                            }, kpiMeta));

                            barGraphs.push({
                                type: "column",
                                title: tabUO[id],
                                valueField: id,
                                fillAlphas: 0.8,
                                lineAlpha: 0.2,
                                balloonText: tabUO[id] + ": [[value]]",
                            });

                            bubbleGraphs.push({
                                balloonText: "x:<b>[[x]]</b> y:<b>[[y]]</b><br>value:<b>[[value]]</b>",
                                bullet: "circle",
                                bulletBorderAlpha: 0.2,
                                bulletAlpha: 0.8,
                                lineAlpha: 0,
                                fillAlphas: 0,
                                valueField: "value",
                                xField: id,
                                yField: "year",
                                maxBulletSize: 100,
                            });
                            allGraphsForReport.push(this.controller.graphConfigFromMetadata({
                                type: "line",
                                title: tabUO[id],
                                valueField: id,
                                lineAlpha: 0.6,
                                lineThickness: 3,
                                fillAlphas: 0,
                                balloonText: "",
                            }, kpiMeta));
                        }
                    }
                }

                chartData.push(item);
                bubbleData.push(itemBubble);
                barData.push(item);

                if (item[idValue] != 0) {
                    pieData.push({
                        title: item.year,
                        valeur: Math.abs(item[idValue]),
                    });
                }
            }


            //graphs et tables
            this.graphCollec[k].data.objGraph.graphs = allGraphs;
            this.graphCollec[k].data.objGraph.dataProvider = chartData;
            this.graphCollec[k].data.objGraph.validateData();
            this.graphCollec[k].data.objGraph.dataChanged = true;

            //bubble charts
            this.graphBubbleCollec[k].data.objGraph.graphs = bubbleGraphs;
            this.graphBubbleCollec[k].data.objGraph.dataProvider = bubbleData;
            this.graphBubbleCollec[k].data.objGraph.validateData();
            this.graphBubbleCollec[k].data.objGraph.dataChanged = true;

            //Pie charts
            this.graphPieCollec[k].data.objGraph.dataProvider = pieData;
            this.graphPieCollec[k].data.objGraph.valueField = "valeur";
            this.graphPieCollec[k].data.objGraph.titleField = "title";
            this.graphPieCollec[k].data.objGraph.autoDisplay = true;
            this.graphPieCollec[k].data.objGraph.validateData();
            this.graphPieCollec[k].data.objGraph.dataChanged = true;

            //Bar diagrams
            this.graphBarCollec[k].data.objGraph.graphs = barGraphs;
            this.graphBarCollec[k].data.objGraph.categoryField = "year",
                this.graphBarCollec[k].data.objGraph.dataProvider = barData;
            this.graphBarCollec[k].data.objGraph.validateData();
            this.graphBarCollec[k].data.objGraph.dataChanged = true;

            //For report
            this.graphCollecFromTempToReport[k].data.objGraph.graphs = allGraphsForReport;
            this.graphCollecFromTempToReport[k].data.objGraph.dataProvider = chartData;
            this.graphCollecFromTempToReport[k].data.objGraph.validateData();
            this.graphCollecFromTempToReport[k].data.objGraph.dataChanged = true;

            // $("#graph_" + this.graphCollec[k].data.objGraph.id + "_overlay").hide();
            this.controller.updateValGraph({
                    "nameKPI": this.graphCollec[k].data.objGraph.nameKPIFormat,
                    "min": this.graphCollec[k].data.objGraph.valueAxes[0].min,
                    "max": this.graphCollec[k].data.objGraph.valueAxes[0].max,
                },
                this.graphCollec[k], this.graphBubbleCollec[k], this.graphPieCollec[k], this.graphBarCollec[k]);

        }
    }

    setMinMaxGraph(objMinMaxAxis) {

        for (var element in this.graphCollec) {
            if (this.graphCollec.hasOwnProperty(element)) {
                for (var el in objMinMaxAxis) {
                    if (objMinMaxAxis.hasOwnProperty(el)) {
                        if (this.graphCollec[element].data.objGraph.nameKPIFormat == objMinMaxAxis[el]["nameKPI"]) {

                            for (var courbe in this.graphCollec[element].data.objGraph.graphs) {
                                if (this.graphCollec[element].data.objGraph.graphs.hasOwnProperty(courbe)) {
                                    this.graphCollec[element].data.objGraph.graphs[courbe].valueAxis.minimum = objMinMaxAxis[el]["min"];
                                    this.graphCollec[element].data.objGraph.graphs[courbe].valueAxis.maximum = objMinMaxAxis[el]["max"];

                                    this.graphCollec[element].data.objGraph.validateData();
                                }
                            }
                        }
                    }
                }

            }
        }
        // same thing to charts stored fro report
        for (var element in this.graphCollecFromTempToReport) {
            if (this.graphCollecFromTempToReport.hasOwnProperty(element)) {
                for (var el in objMinMaxAxis) {
                    if (objMinMaxAxis.hasOwnProperty(el)) {
                        if (this.graphCollecFromTempToReport[element].data.objGraph.nameKPIFormat == objMinMaxAxis[el]["nameKPI"]) {

                            for (var courbe in this.graphCollecFromTempToReport[element].data.objGraph.graphs) {
                                if (this.graphCollecFromTempToReport[element].data.objGraph.graphs.hasOwnProperty(courbe)) {
                                    this.graphCollecFromTempToReport[element].data.objGraph.graphs[courbe].valueAxis.minimum = objMinMaxAxis[el]["min"];
                                    this.graphCollecFromTempToReport[element].data.objGraph.graphs[courbe].valueAxis.maximum = objMinMaxAxis[el]["max"];

                                    this.graphCollecFromTempToReport[element].data.objGraph.validateData();
                                }
                            }
                        }
                    }
                }

            }
        }

        // line to reduce size of the "JS chart by amCharts"
        $("a[title='JavaScript charts']").css("font-size", "4px");
    }


    everestCallbackError(data, status) {
        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");
    }
}

export default AnalysingResultsUI;
