"use strict";

import Analysing_TargetList_Edit_CLS from './analysing_targetlist_edit.js';
import AnalysingTargetListUI from '../view/analysing_targetlist_ui.js';
/*
    Open a box containing list of  - all existing targets  (in SU mode)
                                   - only published targets (in user normal mode)
*/
class Analysing_TargetList_CLS {
    constructor(Everest, UI) {
        this.Everest = Everest;
        //stores result of List_Targets() request
        this.saveData = null;
        this.cptData = 0;


        this.EditTarget = undefined;

        // if the nextUrl is not null
        // stores other items in saveData
        this.nextUrl = false;

        this.isInit = false;
        this.zNodes = [];
        this.currentCopy = null;

        this.analysingTargetListUI = new AnalysingTargetListUI(UI,this);
    }
    init() {
        if (this.analysingTargetListUI.isAuth()) {
            if (this.isInit == true) {
                this.analysingTargetListUI.resetTreeTargetList();
                this.isInit = false;
            }
            this.zNodes = [];
            this.Everest.List_Targets(null, this.initTree.bind(this), this.Everest_callbackError.bind(this));
            this.analysingTargetListUI.init();
        }
    }

    readTargetInfo(node){
        this.Everest.Read_Target_Info(node, node.id, this.callbackSuccessInfos.bind(this), this.Everest_callbackError.bind(this));

    }
    /*
    Add an icon before the Target entry
    display if the target is published or not
    */
    addDiyDom(treeId, treeNode) {
        if (treeNode.level == 0) {

            this.Everest.Read_Target_Info(this.saveData.items[this.cptData], this.saveData.items[this.cptData].id, (d)=> {
                this.analysingTargetListUI.addDiyDom(treeNode,d)
            }, this.Everest_callbackError.bind(this));
        }
        this.cptData++;
    }



    /*
    Init the ztree of data target
    */
    initTree(data, status, id) {
        this.cptData = 0;
        if (this.nextUrl == true) {
            for (var element in data.items) {
                if (data.items.hasOwnProperty(element)) {
                    this.saveData.items.push(data.items[element]);
                }

            }
        } else {
            this.saveData = data;
        }


        for (var i = 0; i < data.items.length; i++) {
            var obj = {
                id: data.items[i].id,
                pId: 0,
                name: data.items[i].name,
                isParent: false,
                published: data.items[i].published,
                description: data.items[i].description
            };
            this.zNodes.push(obj);
        }

        if (data.nextUrl != null) {
            this.nextUrl = true;
            this.Everest.getNextUrl(data.nextUrl, id, this.initTree.bind(this), this.Everest_callbackError.bind(this));
        } else {
            if (this.zNodes.length > 0) {

                var settings = {
                    view: {
                        dblClickExpand: false,
                        showLine: false,
                        selectedMulti: false,
                        addDiyDom: this.addDiyDom.bind(this)
                    },
                    data: {
                        simpleData: {
                            enable: true,
                            idKey: "id",
                            pIdKey: "pId",
                            rootPId: ""
                        }
                    },
                    callback: {
                        onRightClick: this.analysingTargetListUI.OnRightClick.bind(this.analysingTargetListUI)
                    }
                }

                this.analysingTargetListUI.initTree(settings, this.zNodes);
                this.isInit = true;
            }
            this.analysingTargetListUI.setVisibilityOverlay(false);
        }
    }





    /*NotUSed ???
    Addchild(data, status, obj) {
        var tabNode = [];
        for (var i = 0; i < data.items.length; i++) {
            tabNode.push({
                id: data.items[i].id,
                pId: obj.id,
                name: data.items[i].name
            });
        }
        var newNodes = this.tree.addNodes(null, tabNode, true);
        if (data.nextUrl != undefined && data.nextUrl != null) { //On charge la suite
            this.Everest.getNextUrl(data.nextUrl, obj, this.callbackSuccessChild.bind(this), this.Everest_callbackError.bind(this));
        }
    }
    */

    /*
    Called when user select 'edit'
    create and show another box interface

    */
    BT_EditTarget(node) {
        if (this.EditTarget == undefined) {
            this.EditTarget = new Analysing_TargetList_Edit_CLS(this.Everest, this.analysingTargetListUI.getUI());
            this.EditTarget.init();
        }
        this.analysingTargetListUI.moveUOto(this.EditTarget);
        this.EditTarget.Load(node);
    }


    callbackSuccessInfos(data, status, node) {
        this.analysingTargetListUI.callbackSuccessInfos(data, status, node);
    }


    Everest_callbackError(data, status) {
        this.analysingTargetListUI.everestCallbackError(data, status);
    }

}

export default Analysing_TargetList_CLS;
