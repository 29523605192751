// users that should never show on the GUI
export const USERS_SHOULD_NEVER_SHOW = [
    "pythonuser", "localadmin", "TestIT", "dist@eifer.org",
]

// users that must include during each bundle update
export const USERS_SHOULD_ALWAYS_INCLUDE = [
    "pythonuser"
]

let usersList = [];
let groupsList = [];

const setUsers = (users) => Array.isArray(users) ? usersList.push(...users) : undefined;

const setGroups = (groups) => Array.isArray(groups) ? groupsList.push(...groups) : undefined;

const useUsers = (state) => {
    setUsers(state);
    return [usersList, setUsers];
};

const useGroups = (state) => {
    setGroups(state);
    return [groupsList, setGroups];
};

const reformUsersAndGroups = (bundle) => {
    const { authorizedUsers, authorizedGroups, owner } = bundle;

    const users = !authorizedUsers ? [] : usersList.map((user, idx) => {
        const uid = `user${idx}`;
        const published =
            authorizedUsers.findIndex((elem) => elem.username === user.username) >= 0;
        return { ...user, published, id: uid };
    }).filter(({ username }) => username !== owner); // further filter out owner

    const groups = !authorizedGroups ? [] : groupsList.map((group, idx) => {
        const gid = `group${idx}`;
        const published =
            authorizedGroups.findIndex((elem) => elem.code === group.code) >= 0;
        return { ...group, published, id: gid };
    });

    return [users, groups];
};

export default {
    useUsers,
    useGroups,
    reformUsersAndGroups,
};
