"use strict";
/***
Manage dynamic table
***/

class Dynamic_Table_CLS {

  constructor(id, name, idParent, timescale) {

    this.id = id;
    this.timescale = timescale;
    this.idJs = 't_' + idParent + "_" + timescale + "_" + id;
    this.idParent = idParent;
    this.name = name;
    this.rows = [];
    this.columns = [];

  }
  //If the title doesn't exist, create the Row
  getRow(title) {
    var retour = -1;
    for (var i = 0; i < this.rows.length; i++) {
      if (this.rows[i] == title) {
        retour = i;
        return i;
      }
    }
    if (retour == -1) {
      retour = this.rows.length;
      //Add the column to the UI
      this.addRowUI(title, retour);
      this.rows[retour] = title;
    }
    return retour;
  }
  //If the title doesn't exist, create the Column
  getColumn(title) {
    var retour = -1;
    for (var i = 0; i < this.columns.length; i++) {
      if (this.columns[i] == title) {
        retour = i;
        return i;
      }
    }
    if (retour == -1) {
      retour = this.columns.length;
      //add the column to the ui
      this.addColumnUI(title,retour);
      this.columns[retour] = title;
    }
    return retour;
  }

  /*
      All functions for UI
  */
  setText(rowTitle, columnTitle, value) {
    var rowId = this.getRow(rowTitle);
    var columnId = this.getColumn(columnTitle);
    /*
    console.log("======================");
    console.log("setText(" + rowId + " " + columnId + ") : " + value);
    console.log("Infos  (" + this.rows.length + " " + this.columns.length + "): " + rowTitle + " / " + columnTitle);
    console.log(this.rows);
    console.log(this.columns);
    console.log("======================");
    */
    $("#" + this.idJs + "_row_" + rowId + "_col_" + columnId).html(value);
  }

  addRowUI(title, id) {
      var txt = "<tr id='" + this.idJs + "_row_" + id + "'><td id='" + this.idJs + "_row_" + id + "_col_h'  class='dynamicTableFirstCol'><b>" + title + "</b></td>";
    for (var i = 0; i < this.columns.length; i++) {
        txt += "<td id='" + this.idJs + "_row_" + id + "_col_" + i + "' class='dynamicTableCell'></td>";
    }
    txt += "</tr>";
    $('#' + this.idJs + ' tbody:first').append(txt);
    //$('#' + this.idJs + ' tbody tr:last td:first').html($('#row').val());
  }

  addColumnUI(title, id) {
      $('#' + this.idJs + ' thead:first tr').append($("<th>"));
      $('#' + this.idJs + ' thead:first tr>th:last').html(title);
      $('#' + this.idJs + ' thead:first tr>th:last').addClass("dynamicTableTH");
    for (var i = 0; i < this.rows.length; i++) {
        $("#" + this.idJs + "_row_" + i).append("<td id='" + this.idJs + "_row_" + i + "_col_" + id + "' class='dynamicTableCell'></td>");
    }

  }

  getHtmlInit() {
      return "<div class='dynamicTableDiv'><p class='dynamicTableTitle'>" + this.name + "</p><table class='dynamicTable' border='0' cellpadding='0' cellspacing='0'  id='" + this.idJs + "'><thead><tr><th class='dynamicTableTH' style='background-color:transparent;border-top-color:transparent;border-left-color:transparent;'>&nbsp;</th></tr></thead><tbody></tbody></table></div>";
  }
}
export default Dynamic_Table_CLS;
