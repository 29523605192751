import Attributs_Initiative_CLS from "../attributs_initiative.js";
import InterfaceGestionInitUI from "../view/interface_gestion_initiatives_ui.js";

class Interface_Gestion_Initiatives_CLS{
    constructor(Everest, UI){

        this.interfaceGestionInitUI = new InterfaceGestionInitUI(this, UI);
        this.Everest = Everest;
        this.tabInitiatives = [];
        this.data_Models_Info = null; //Info au niveau du mosa (liste des categories d'initiatives + timescales possibles)
        this.Object_Type_Metadata = null; //Listes des attributs pour un typeName
        this.Object_Type_Name = null; //Evite de requeter plusieurs fois si on reste sur le même typeName
        this.Urban_Objects_Attributes = null; //Liste des valeurs sauvegardées pour un couple "pid" (UrbanObject) / "caseId" (simulation)

        this.currentNodeSimu = null; //On stoque les info de la simulation, car le sim id de everest peut changer et ne plus être bon.
        this.currentNodeSimuRunStatus = undefined;

        this.nodesSelected = null;

    }

    init() {
        if (this.interfaceGestionInitUI.getToken() != null) {
            this.tabInitiatives = [];
            this.Everest.List_Models_Info(this.initTree.bind(this), this.Everest_callbackError.bind(this));
        }
    }

    initTree(data, status, id){
        this.interfaceGestionInitUI.resetScroll(true);
        this.data_Models_Info = data;
        var params = {
            mode: "Accord",
            uid: "ini",
            idParent: "accordion_initiatives",
            gestionAttrib: "gestion_Initiatives_categories",
            Fct_save: this.Sauve_initiative.bind(this)
        };

        for (var i = 0; i < data.initiatives.length; i++) {
            if (i == 0) {
                this.interfaceGestionInitUI.setIsFirstInitiative(true);
            } else {
                this.interfaceGestionInitUI.setIsFirstInitiative(false);
            }
            if (data.initiatives[i].code.indexOf(".") > -1) {
                data.initiatives[i].code = data.initiatives[i].code.replace(".", "");
            }
            this.tabInitiatives[data.initiatives[i].code] = new Attributs_Initiative_CLS(data.initiatives[i], data.timescales, params, this.interfaceGestionInitUI.getUI(), i == 0 );
        }
    }

    Reset() {
        for (var i in this.tabInitiatives) {
            if (this.tabInitiatives[i].Reset != undefined) {
                this.tabInitiatives[i].Reset();
            }
        }
        // à voir si commenté c'est ok
        //this.Urban_Objects_Attributes = null;
    }

    ResetFromUO() {
        this.Reset();
        this.Everest.selectedTypeName = null;
        this.Everest.selectedPid = null;
        this.interfaceGestionInitUI.setVisibilityNoUO(true);
    }

    LoadFromUO(uo, treeNode) {
        //if (this.Everest.selectedPid != treeNode.id) {
        this.ResetFromUO();
        if (uo.ListModelZoom != undefined) {
            var typeName = treeNode.UOtype;
            this.Everest.selectedTypeName = typeName;
            this.Everest.selectedPid = treeNode.id;
            this.Load(undefined, this.currentNodeSimuRunStatus);
            this.interfaceGestionInitUI.setVisibilityNoUO(false);
        }
        //}
    }
    //Confirm before cancel change
    beforeCheck(uo) {
        //console.log(document.getElementById("Edit_Context_save").disabled);
        if (this.interfaceGestionInitUI.getVisibilitySave() == false){
            return true;
        }else{
            if (this.interfaceGestionInitUI.confirm()){
                this.Reset_modif();
                return true;
            }else{
                return false;
            }
        }
    }
    Load(nodeSimu, simRunStatus) {

        this.currentNodeSimuRunStatus = simRunStatus;

        if (this.Everest.selectedPid == undefined) {
            var nodesUO = this.interfaceGestionInitUI.UI.GestionUrbObj.tree.getNodesByFilter(function filter(node) { return !node.isParent && node.checked; });
            if (nodesUO.length > 0) {
                var treeNode = nodesUO[0];
                this.Everest.selectedTypeName = treeNode.UOtype;
                this.Everest.selectedPid = treeNode.id;
                this.interfaceGestionInitUI.setVisibilityNoUO(false);
            }
        }

        if (nodeSimu != undefined && nodeSimu != null) {
            this.interfaceGestionInitUI.load(nodeSimu);
            this.currentNodeSimu = nodeSimu; //TODO : passer le node.id à tous les appel everest utilisant actuellement this.selectedCaseId
        }
        if (this.currentNodeSimu != undefined && this.currentNodeSimu != null && this.currentNodeSimu.id != null) {
            //On mets à jours les items modifiés.
            if (this.Everest.UOModified.Initiatives[this.currentNodeSimu.id] != undefined) {
                this.List_Updated_Urban_Objects_Init(this.currentNodeSimu.id);
            } else {
                this.Everest.List_Updated_Urban_Objects(this.currentNodeSimu.id, this.currentNodeSimu.id, this.List_Updated_Urban_Objects_Callback.bind(this), this.Everest_callbackError.bind(this));
            }
        }
        this.interfaceGestionInitUI.setVisibilityNoIni(false);
        if (this.Everest.selectedTypeName != null && this.currentNodeSimu != null && this.currentNodeSimu.id != null && this.Everest.selectedPid != null) {
            this.interfaceGestionInitUI.setVisibilityOverlayGestIni(true);
            //Load des valeurs par defaut.
            this.Everest.Read_Urban_Objects_Attributes(this.currentNodeSimu.id, this.Everest.selectedPid, this.Read_Urban_Objects_Attributes_Callback.bind(this), this.Everest_callbackError.bind(this));

        }

    }

    //Recup les valeurs par defaut pour le couple pid/caseid
    Read_Urban_Objects_Attributes_Callback(data, status, obj) {
        this.Urban_Objects_Attributes = data;
        //Si le typename à changé, on requetes les nouveaux params.
        if (this.Object_Type_Name == null || this.Object_Type_Name != this.Everest.selectedTypeName) {
            this.Everest.Read_Urban_Object_Type_Metadata(this.Everest.selectedTypeName, this.Everest.selectedTypeName, this.Read_Urban_Object_Type_Metadata_Callback.bind(this), this.Everest_callbackError.bind(this));
        } else {
            // only fresh sim case can be modified
            const isReadOnly = this.currentNodeSimuRunStatus !== "notStarted";
            this.BuildInterface(isReadOnly);

            if (window.eventDispatcher) {
                window.eventDispatcher.dispatch("onInterfaceBuilt", this.data_Models_Info, isReadOnly);
            }

        }

    }

    List_Updated_Urban_Objects_Callback(data, status, idSimu) {
        if (this.Everest.UOModified.Initiatives[idSimu] == undefined) {
            this.Everest.UOModified.Initiatives[idSimu] = [];
        }
        ///ec qui etait avant
        for (var i = 0; i < data.items.length; i++) {
            //this.Everest.UOModified.Initiatives[idSimu].push(data.items[i].pid);
            this.Everest.UOModified.Initiatives[idSimu][this.Everest.UOModified.Initiatives[idSimu].length] = data.items[i].pid;
            //console.log(this.Everest.UOModified.Initiatives[idSimu].length);
        }

        if (data.nextUrl != null) {
            this.Everest.getNextUrl(data.nextUrl, idSimu, this.List_Updated_Urban_Objects_Callback.bind(this), this.Everest_callbackError.bind(this));
        } else {
            this.List_Updated_Urban_Objects_Init(idSimu);
        }

    }

    List_Updated_Urban_Objects_Init(idSimu) {

        if (this.Everest.UOModified.Initiatives[idSimu] != undefined) {

            this.interfaceGestionInitUI.hideModifiedUrbanObjects();

            for (var i = 0; i < this.Everest.UOModified.Initiatives[idSimu].length; i++) {
                this.interfaceGestionInitUI.markUrbanObjectModified(this.Everest.UOModified.Initiatives[idSimu][i]);
            }
        }
    }

    //Recup les valeurs pour un typeName
    Read_Urban_Object_Type_Metadata_Callback(data, status, typeName) {
        this.Object_Type_Name = typeName;
        this.Object_Type_Metadata = data;

        // only fresh sim case can be modified
        const isReadOnly = this.currentNodeSimuRunStatus !== "notStarted";
        this.BuildInterface(isReadOnly);

        //dispatch event 'onInterfaceBuilt'
        if (window.eventDispatcher) {
            window.eventDispatcher.dispatch("onInterfaceBuilt", this.data_Models_Info, isReadOnly);
        }

    }

    BuildInterface(isReadOnly) {


        // disable import if neccessary
        $("#gestion_Initiatives_import").prop("disabled", isReadOnly);

        //console.log("IGI_BuildInterface");
        var isEmpty = true;
        this.Reset();

        // sort by displayName
        const metaNames = _.sortBy(Object.keys(this.Object_Type_Metadata).map(key => ({
                key,
                displayName: this.Object_Type_Metadata[key]["displayName"],
            }),
        ), ["displayName"]).map(item => item.key);

        for (let key of metaNames) {
            let item = this.Object_Type_Metadata[key];
            if (this.Is_Allowed(item.metadatas) == true && this.tabInitiatives[item.initiative] != undefined) {
                this.tabInitiatives[item.initiative].AddItem(key, item, this.Urban_Objects_Attributes[key], null, true, undefined, isReadOnly);
                isEmpty = false;
            }
        }

        //var h = $("#gestion_Initiatives_part_content_attributes").height();
        //var hT = $("#nav_ini_ENBLED1").height();
        //var y = $("#collapse_ini_ENBLED1_slider").height() * (2 / 100);
        //var u = h - 50 - hT -y -10 - $("#collapse_ini_ENBLED1_slider").height();


        //$("#collapse_ini_ENBLED1_content_variant").css("height", u + "px");
        this.interfaceGestionInitUI.buildInterface();


        if (isEmpty) {
            //Afficher "Aucun rez"...
            this.interfaceGestionInitUI.setVisibilityNoIni(true);
        } else {
            //Init des valeurs
            for (var i in this.tabInitiatives) {
                if (this.tabInitiatives[i].InitValues != undefined) {
                    this.tabInitiatives[i].InitValues();
                }
            }
            this.interfaceGestionInitUI.setVisibilityNoIni(false);
        }
        this.interfaceGestionInitUI.setVisibilityOverlayGestIni(false);
        this.interfaceGestionInitUI.resetScrool();
        this.Everest.List_Updated_Urban_Objects_Attributes(this.currentNodeSimu.id, this.Everest.selectedPid, this.List_Updated_Urban_Objects_Attributes_Callback.bind(this), this.Everest_callbackError.bind(this));
    }
    selectIni(id){
        if (id!=undefined && this.tabInitiatives[id]!=undefined)
            this.tabInitiatives[id].selectPanel();
    }
    updateCheckBox(isChecked,initiative,attribut){
        /*
        // ajout dans le tableau de la bonne initiative, l'attribut qui a été coché
        if (isChecked) {
            this.tabInitiatives[initiative].tabExportAttr.push(attribut);
        } else { // supprime du bon tableau, l'attribut que l'on a décoché
            for (var i = 0; i < this.tabInitiatives[initiative].tabExportAttr.length; i++) {
                if (this.tabInitiatives[initiative].tabExportAttr[i] == attribut) {
                    this.tabInitiatives[initiative].tabExportAttr.splice(i, 1);
                }
            }
        }*/
    }

    // ajoute des * sur les initiatives déjà modifiées
    List_Updated_Urban_Objects_Attributes_Callback(data, status, obj) {
        this.interfaceGestionInitUI.listUpdatedUrbanObjectsAttributesCallback(data, status, obj);
    }

    //retourne True si le compte à les droits suffisant pour afficher le champ
    Is_Allowed(metadatas) {
        if (this.Everest.SuperUser == true)
            return true;

        var resu = false;
        for (var i = 0; i < metadatas.length; i++) {
            if (metadatas[i].name == "SuperUser") {
                if (metadatas[i].value == "no") {
                    return true;
                }
            }
        }
        return resu;
    }

    Reset_modif() {
        this.interfaceGestionInitUI.setVisibilityWaitReset(true);
        for (var i in this.tabInitiatives) {
            if (this.tabInitiatives.hasOwnProperty(i)) {
                if (this.tabInitiatives[i].objParent != undefined && this.interfaceGestionInitUI.isVisibleCollapse(this.tabInitiatives[i].params.uid,this.tabInitiatives[i].objParent.code)) {
                    if (this.tabInitiatives[i].InitAllValues != undefined) {
                        this.tabInitiatives[i].boolHaveChange = false;
                        this.tabInitiatives[i].InitAllValues();
                    }
                }
            }
            this.tabInitiatives[i].boolHaveChange = true;
        }

        this.interfaceGestionInitUI.setVisibilityWaitReset(false);

    }

    Sauve_modif() {
        this.interfaceGestionInitUI.setVisibilityWaitSave(true);
        for (var i in this.tabInitiatives) {
            if (this.tabInitiatives.hasOwnProperty(i)) {
                if (this.tabInitiatives[i].objParent != undefined && this.interfaceGestionInitUI.isVisibleCollapse(this.tabInitiatives[i].params.uid,this.tabInitiatives[i].objParent.code)) {
                    if (this.tabInitiatives[i].SaveAllValues != undefined) {
                        this.tabInitiatives[i].boolHaveChange = false;
                        this.tabInitiatives[i].SaveAllValues();
                    }
                }
            }
            this.tabInitiatives[i].boolHaveChange = true;
        }
        this.interfaceGestionInitUI.setVisibilityWaitSave(false);
    }

    Sauve_initiative(object, afterSaveAllCB) {
        this.nodesSelected = this.interfaceGestionInitUI.getCheckedNodes(true);
        this.Sauve_initiative_afterSaveAllCB = afterSaveAllCB;
        for (var i = 0; i < this.nodesSelected.length; i++) {
            if (i + 1 == this.nodesSelected.length) {
                this.Everest.Update_Urban_Objects_Attributes(this.nodesSelected[i].id, this.currentNodeSimu.id, this.nodesSelected[i].id, object, this.Sauve_initiative_callbacksucces.bind(this), this.Sauve_initiative_callbackError.bind(this));
            } else {
                this.Everest.Update_Urban_Objects_Attributes(this.nodesSelected[i].id, this.currentNodeSimu.id, this.nodesSelected[i].id, object, this.Sauve_initiative_updateUO_callbacksucces.bind(this), this.Sauve_initiative_callbackError.bind(this));
            }
        }
    }

    Sauve_initiative_updateUO_callbacksucces(data, status, id) {
        // Tester si avec ça c'est ok pour affichage des * qui apparaissent n'importe quand
        //-> j'ai commenté les 2 lignes que j'avais ajouté précédemment
        for (var i = 0; i < this.Everest.UOModified.Initiatives[this.currentNodeSimu.id].length; i++) {
            if (this.Everest.UOModified.Initiatives[this.currentNodeSimu.id][i] == id) {
                break;
            }
        }
        if (this.Everest.UOModified.Initiatives[this.currentNodeSimu.id].length == i) {
            this.Everest.UOModified.Initiatives[this.currentNodeSimu.id][this.Everest.UOModified.Initiatives[this.currentNodeSimu.id].length] = id;
        }
        this.interfaceGestionInitUI.setVisibilityDiy(id,true);
    }

    Sauve_initiative_callbacksucces(data, status) {
        this.Sauve_initiative_updateUO_callbacksucces(data, status, this.nodesSelected[this.nodesSelected.length - 1].id);
        if(this.Sauve_initiative_afterSaveAllCB) this.Sauve_initiative_afterSaveAllCB()
        this.interfaceGestionInitUI.saveInitSucces();
    }

    Sauve_initiative_callbackError(data, status) {
        this.interfaceGestionInitUI.saveInitError();

    }

    Everest_callbackError(data, status) {
        this.interfaceGestionInitUI.everestCallbackError(data, status);
    }

    initiativesExport(nodes){
        var filtre = "";
        var filtreINI = "";

        for (var i in nodes) {
            if (nodes.hasOwnProperty(i)) {
                filtre += (filtre == "" ? "?pids=" : ",") + nodes[i].id;
            }
        }

        // trigger Download for PRODEC
        if (window.eventDispatcher) {
            var filterObject = { filter: filtre };
            window.eventDispatcher.dispatch("onInputDataExport", filterObject);
            filtre = filterObject["filter"];
        }

        for (var i in this.tabInitiatives) {
            for (var element in this.tabInitiatives[i].tabExportAttr) {
                if (this.tabInitiatives[i].tabExportAttr.hasOwnProperty(element)) {
                    filtreINI += (filtreINI == "" ? "?attributes=" : ",") + this.tabInitiatives[i].tabExportAttr[element];
                }

            }
        }
        if (filtreINI != "" && filtre != "") {
            filtreINI = filtreINI.replace("?", "&");
        }
        filtre += filtreINI;


        var fileName = this.currentNodeSimu.id + ".xlsx";
        var xlsxLink = this.Everest.dl_Export_Data_To_XLSX(this.currentNodeSimu.id, (filtre == "" ? undefined : filtre), true, {});
        this.interfaceGestionInitUI.createDowloadAlertLink(xlsxLink,fileName);
    }

    initiativesImport(inputVal){
        var reader = new FileReader();

        reader.onloadend = ()=> {
            this.Everest.Import_Data_From_XLSX(this.currentNodeSimu.name, self.currentNodeSimu.id, filtre, inputFile.files[0].type, reader.result, (d, s, p)=> {
                this.interfaceGestionInitUI.createAlertImport();
            }, (d, s, p) =>{
                this.Everest_callbackError(d, s);

            });
            this.interfaceGestionInitUI.importCloneControl();
        }
        reader.readAsArrayBuffer(inputFile.files[0]);
    }
}

export default Interface_Gestion_Initiatives_CLS;
