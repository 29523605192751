"use strict";


/**
 * Promisify Ajax call
 *
 * @param fn ajax call
 * @param verbose {Boolean} True will resolve/reject jqXhr. default return simplified response object
 * @returns {Promise<any>}
 */
export default function promisify(fn, verbose = false) {
    // promisify ajax call
    // need more restrict HTTP code instead of plain text on resolve/reject
    return new Promise((resolve, reject) => {
        fn.done((data, status, jqXhr) => !verbose ? resolve(data) : resolve({
            status: jqXhr["status"],
            statusText: jqXhr["statusText"],
            data: data
        })).fail((jqXhr, textStatus, errorThrown) => {

            // fallback when server returns successful response but with wrong ContentType
            // This will cause parse error (e.g. json)
            if (jqXhr.status === 200 && textStatus === "parsererror") {
                return !verbose ? resolve(jqXhr.responseText) : resolve({
                    status: jqXhr["status"],
                    statusText: jqXhr["statusText"],
                    data: jqXhr.responseText
                });
            }

            return !verbose ? reject({
                //TODO might extend the shorthand error object
                status: jqXhr["status"],
                statusText: jqXhr["statusText"],
                responseJSON: jqXhr["responseJSON"],
                responseText: jqXhr["responseText"]
            }) : reject(jqXhr);


        });
    });
}
