"use strict";

import Cavi2Util from "../helpers/cavi.util.js";

class AnalysingRankingUI {
    constructor(controller, UI) {
        this.controller = controller;

        //TODO Remove the link with UI.
        this.UI = UI;
        //File with all texts to display
        this.contentFileJSON = this.UI.accessContentJSON;
        this.contentMessageFileJSON = this.UI.accessMessageContentJSON;

        this.treeKpi = null;

        this.tree;
        this.initTree();
        //this.treeUO;
        //this.initTreeUO();
        // boolean set true after creating the ztree simcase
        this.boolcreateTreeSim = false;
    }

    initTree() {
        if (this.tree == undefined) {
            this.tree = $.fn.zTree.getZTreeObj("treeSimAnaRanking");
        }
    }

    //initTreeUO() {
    //    if (this.treeUO == undefined) {
    //        this.treeUO = $.fn.zTree.getZTreeObj("tree_URB");
    //    }
    //}

    //getCheckedNodesUO() {
    //    this.initTreeUO();
    //    return this.treeUO.getCheckedNodes(true);
    //}

    getTreeKpi() {
        return this.treeKpi;
    }

    getCheckedNodesKpi() {
        return (this.treeKpi != null && this.treeKpi != undefined) ? this.treeKpi.getCheckedNodes(true) : [];
    }

    getCheckedNodesTree() {
        this.initTree();
        return this.tree.getCheckedNodes(true);
    }

    /*init*/
    init() {
        // Build and add the results box
        this.createboxRanking(function () {return true;});
        $("#bt_analysing_Ranking_perf").click(this.controller.CalculateResults.bind(this.controller));
        $("#bt_fullscreen_analysing_ranking_result").on("click", function () {
            if ($("#bt_fullscreen_analysing_ranking_result i").hasClass("fa-expand") == true) { //Passe en FullScreen
                $("#bt_fullscreen_analysing_ranking_result i").removeClass("fa-expand").addClass("fa-compress");
                $("#move_analysing_ranking_result").appendTo("#fullscreen_analysing_ranking_result");
                $("#fullscreen_analysing_ranking_result").show();
            } else {
                $("#bt_fullscreen_analysing_ranking_result i").removeClass("fa-compress").addClass("fa-expand");
                $("#move_analysing_ranking_result").appendTo("#screen_analysing_ranking_result");
                $("#fullscreen_analysing_ranking_result").hide();
            }
        });

    }

    createboxRanking(CallBackClose) {

        $("#box_analysing_Ranking").remove();
        var c = "<div id=\"analysing_Ranking_content\">";
        c += "      <div style=\"position:absolute; top:0px; left:0px; bottom:0px; width:200px; overflow:auto;\">";
        c += "          <h4>";
        c += this.contentFileJSON.MENU_LEFT_SIMULATING_CASE;
        c += "          </h4>";
        c += "          <div id=\"sim_div_ranking\" style=\"width:100%;\">";
        c += "              <div id=\"sim_analysing_Ranking\" style=\"width:100%;\">"; //Arbre avec les simulations "run success"
        c += "              </div>";
        c += "              <ul id=\"treeSimAnaRanking\" class=\"ztree\"></ul>";
        c += "          </div>";
        c += "      </div>";
        c += "      <div class=\"use-skin-border\" style=\"position:absolute; top:0px; left:200px; bottom:0px; width:260px; overflow:auto;\">";
        //c += "          <h4>";
        //c += this.contentFileJSON.MENU_TOP_CITY_URBAN;
        //c += "           </h4>";
        c += "          <div id=\"uo_analysing_Ranking\" style=\"width:100%;\"></div>"; //Arbre avec les urban Objects
        c += "      </div>";
        c += "       <div class=\"use-skin-border\" style=\"position:absolute; top:0px; left:465px; bottom:0px; right:0px; overflow:auto;\">";
        c += "          <div style=\"width:100%; height:calc(100% - 15px);text-align:left\">";
        c += "              <h4>";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RESULTS_TITLE;
        c += "              </h4>";
        c += "              <div style=\"margin-top:5px;text-align:left\">";
        c += "                  <table style=\"width:100%\"><tr><td><b>";
        c += this.contentFileJSON.MENU_FILTER;
        c += "                  : <input type=\"text\" id=\"analysing_Ranking_filter\" /></b></td><td><b>";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RANKING_YEAR;
        c += "                  </b><select id=\"analysing_Ranking_years\"></select></td></tr></table>";
        c += "              </div>";
        c += "              <div style=\"width: 98%; position : absolute; height:calc(100% - 130px); margin-top:5px;\">";
        c += "              <div id=\"kpiRankingNoKpi\" class=\"callout use-skin-border kpiRankingMesage\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
        c += "                  <p>" + this.contentFileJSON.MENU_LEFT_KPI_SELECTION_NO_KPI_FOR_SEL + "</p>";
        c += "              </div>";
        c += "              <div id=\"kpiRankingUOAndScenario\" class=\"callout use-skin-border kpiRankingMesage\" style=\"background-color: rgba(254,88,21, 0.1); display:none\">";
        c += "                  <p>" + this.contentFileJSON.MENU_LEFT_KPI_SELECTION_SELECT_UO_SCENARIO + "</p>";
        c += "              </div>";
        c += "                  <div id=\"kpi_analysing_Ranking\" style=\"width:100%;overflow:auto; height: calc(100% - 319px); min-height: 150px;\">"; //Arbre avec les kpi
        c += "                      <ul id=\"treeAnaRankKpi\" class=\"ztree\"></ul>";
        c += "                  </div>";
        c += "                  <div style=\"width:100%;height:300px;text-align:left\">";
        c += "                      <div style=\"width:100%;padding:3px;\">";
        c += "                          <button class=\"btn btn-primary use-skin classBtnClick\" id=\"bt_analysing_Ranking_perf\">";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RANKING_BTN;
        c += "                          </button>   <div class=\"load_wait_fctRank\" style=\"display:none;\"><i class=\"fa fa-refresh fa-spin\"></i></div>";
        c += "                      </div>";
        c += "                      <div id=\"screen_analysing_ranking_result\"  style=\"width:100%; height:100%;\">";
        c += "                          <div id=\"move_analysing_ranking_result\" style=\"position : relative;height:100%;\">";
        c += "                              <div class=\"nav-tabs-custom\" style=\"position : relative;height:100%;\">";
        c += "                                  <ul class=\"nav nav-tabs\">";
        c += "                                      <li class=\"active\"><a href=\"#tab_ranking_rank\" data-toggle=\"tab\">" + this.contentFileJSON.MENU_LEFT_ANALYSING_RANKING_RANK + " <span class=\"setYear\"></span></a></li>";
        c += "                                      <li><a href=\"#tab_ranking_matrix\" data-toggle=\"tab\">" + this.contentFileJSON.MENU_LEFT_ANALYSING_RANKING_MATRIX + " <span class=\"setYear\"></span></a></li>";
        //c += '                                      <li><a href="#tab_ranking_graph" data-toggle="tab">' + this.contentFileJSON.MENU_LEFT_ANALYSING_RANKING_GRAPH + ' <span class="setYear"></span></a></li>';
        c += "                                      <li class=\"pull-right\"><button id=\"bt_fullscreen_analysing_ranking_result\" class=\"btn btn-box-tool text-muted\"><i class=\"fa fa-expand fa-2x\"></i></button></li>";
        c += "                                  </ul>";
        c += "                                  <div class=\"tab-content\" style=\"position : relative;height:100%;\">";
        c += "                                      <div class=\"tab-pane active\" id=\"tab_ranking_rank\" style=\"position : relative;height:100%;\">";
        c += "                                          <table id=\"table_result_rank\" class=\"table table-bordered table-striped\"><thead><tr><th>";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RANKING_TABLE_TITLE_3;
        c += "                                          </th><th>";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RANKING_TABLE_TITLE_2;
        c += "                                          </th><th>";
        c += this.contentFileJSON.MENU_LEFT_ANALYSING_RANKING_TABLE_TITLE_1;
        c += "                                          </th></tr></thead>";
        c += "                                          <tbody></tbody>",
            c += "                                          <tfoot></tfoot></table>";
        c += "                                      </div><!-- /.tab-pane -->";
        c += "                                      <div class=\"tab-pane\" id=\"tab_ranking_matrix\">";
        c += "                                      </div><!-- /.tab-pane -->";

        c += "                                  </div><!-- /.tab-content -->";
        c += "                              </div>";
        c += "                          </div>";
        c += "                      </div>";
        c += "                  </div>";
        c += "              </div>";
        c += "          </div>";
        c += "      </div>";
        c += "      <div id=\"kpi_analysing_Ranking_overlay\" class=\"overlay\">";
        c += "          <i class=\"fa fa-refresh fa-spin\"></i>";
        c += "      </div>";
        c += "      <div id=\"fullscreen_analysing_ranking_result\" class=\"overlay\" style=\"background-color:rgba(255,255,255,1); position: absolute; left: 2px; right: 2px; top: 2px; bottom: 2px; padding:3px\"></div>";
        c += "  </div>";


        var content_overlay = " <div class=\"alert use-skin\" style=\"margin:20px; color:white;\">";
        content_overlay += "        <h4>";
        content_overlay += this.contentFileJSON.MENU_LEFT_ANALYSING_KPI_EVALUATION_SIMULATION;
        content_overlay += "        </h4>";
        content_overlay += "        <p>";
        content_overlay += this.contentFileJSON.MENU_LEFT_ANALYSING_KPI_EVALUATION_MESSAGE;
        content_overlay += "        </p>";
        content_overlay += "    </div>";
        this.UI.CreateBox("analysing_Ranking", {
            draggable: true,
            group: "Analysing",
            resizable: {
                minHeight: 500,
                minWidth: 800 /*, handles: 'e, s, w'*/
            }, //...options du resizable : si undefined => non resizable, passer {} pour resizable sans options
            useSkin: true,
            title: this.contentFileJSON.MENU_LEFT_ANALYSING_RANKING_TITLE,
            content: c,
            addOverlay: content_overlay,
            visible: false,
            menuBt: {
                reduceTo: "div_Ranking", //ID vers qui on doit réduire la div
                fullscreen: {
                    css: {
                        position: "absolute",
                        top: "120px",
                        width: "860px",
                        height: "500px",
                        left: "7%",
                        right: "",
                        bottom: ""
                    },
                    fct: function() {

                    }.bind(this)
                } //position / taille de div pour quand on sort du fullscreen
            },
            style: { //Constient tous les styles à appliquer avec en id le nom du style
                position: "absolute",
                top: "120px",
                left: "7%",
                width: "860px",
                height: "500px",
                "min-width": "800px",
                "min-height": "500px",
                "z-index": Zindex + 1
            }
        }, CallBackClose);
        Zindex = Zindex + 1;
    }

    initTimeScale(timescalesItem) {
        if (timescalesItem != null) {
            var htmlSelect = "";
            for (var i = 0; i < timescalesItem.steps.length; i++) {
                htmlSelect += "<option value='" + i + "'>" + timescalesItem.steps[i] + "</option>";
            }
            if (htmlSelect != "") {
                $("#analysing_Ranking_years").append(htmlSelect);
            }

        }
    }
    /*updateSimCaseTree*/

    updateSimCaseTree(node, nodeParent, boolIsDelete) {
        var self = this;
        this.initTree();

        if (this.boolcreateTreeSim == false) {
            var d = $("#sim_analysing_Ranking");
            if (d != null || d != undefined) {
                $("#sim_analysing_Ranking").empty();
                //event onhover on ztree elements
                //alt tag : show the last run date
                function addHoverDom(treeId, treeNode) {
                    var aObj = $("#" + treeNode.tId + "_span");
                    var index = self.controller.getIndexSimuRun(treeNode.id);
                    if (index > -1) { // index=-1 if user hover a bundle node, else it's a simu node
                        aObj.attr('title', self.controller.getInfoSimuRun(index)["date"]);
                    }
                };
                this.tree = $("#treeSimAnaRanking");
                var settings = {
                    view: {
                        addHoverDom: addHoverDom,
                        dblClickExpand: true,
                        showLine: true,
                        selectedMulti: true
                    },
                    check: {
                        enable: true,
                        chkboxType: {
                            "Y": "ps",
                            "N": "ps"
                        }
                    },
                    data: {
                        simpleData: {
                            enable: true,
                            idKey: "id",
                            pIdKey: "pId",
                            rootPId: ""
                        }
                    },
                    callback: {
                        onClick: this.onClick.bind(this),
                        onCheck: this.onCheck.bind(this)
                    }
                }




                this.tree = $.fn.zTree.init(this.tree, settings, []);
            }

            this.boolcreateTreeSim = true;
        }


        var currentnode = this.tree.getNodeByParam("id", node.id, null);
        if (currentnode == null) {
            if (boolIsDelete == true) {

            } else {
                var p = this.tree.getNodeByParam("id", nodeParent.id, null);
                if (p == null) {
                    var pos=-1;
                    var np = this.tree.getNodes();
                    for(var n in np){
                        if (np.hasOwnProperty(n)){
                            if (nodeParent.name.toString().toLowerCase() <= np[n].name.toString().toLowerCase()){
                            }else{
                                pos++;
                            }
                        }
                    }
                    var newNodes = this.tree.addNodes(null, pos+1, {
                        id: nodeParent.id,
                        pId: null,
                        name: nodeParent.name,
                        isParent: true
                    }, true);
                    p = newNodes[0];
                }

                //Sort item by name
                var pos=-1;
                for(var n in p.children){
                    if (p.children.hasOwnProperty(n)){
                        if (node.name.toString().toLowerCase() <= p.children[n].name.toString().toLowerCase()){
                        }else{
                            pos++;
                        }
                    }
                }
                this.tree.addNodes(p, pos+1, {
                    id: node.id,
                    pId: p.tId,
                    name: node.name,
                    isParent: false
                }, false);
            }

        } else {
            if (boolIsDelete == true) {
                // if user deletes a simu or a bundle :
                this.tree.removeNode(currentnode);
            }
        }

        $("#box_analysing_Ranking .overlay").hide(); //at least one simulation launched
    }
    /*
    Define actions to apply when user click on a tree node (simulation tree in ranking box)
    Launches the openDap function to get KPIs associated
    */
    onClick(event, treeId, treeNode) {
        /*if (treeNode && treeNode.isHover == true && treeNode.isParent == false) {
            this.tree.checkNode(treeNode, true, true, true);
            //igo TMA 2
        }*/
        var bool = !treeNode.checked;
        this.tree.checkNode(treeNode, bool, true, true);
    }

    /*
    fct called when we check a ztree checkbox
    */
    onCheck(event, treeId, treeNode) {
        this.controller.updateCheck();
        /*
        var allNodes = [];
        if (treeNode.isParent == true) {
            allNodes = treeNode.children;
        } else {
            allNodes.push(treeNode);
        }
        for (var i = 0; i < allNodes.length; i++) {
            var numToString = allNodes[i].id.toString();
            numToString = numToString.replace(/\//g, '_');
            if (treeNode.checked == true) {
                $('#item_ranking_' + numToString).show();
            } else {
                $('#item_ranking_' + numToString).hide();
            }
        }
        if (treeId === "treeSimAnaRanking") {

            if (this.treeKpi != null) {
                $.fn.zTree.destroy("treeAnaRankKpi");
            }
            this.treeKpi = null;
            $("#kpi_analysing_Ranking_overlay").show();

            this.controller.onCheck(allNodes);
        }*/
    }
    onCheckKpi(event, treeId, treeNode) {
        var allNodes = [];
        if (treeNode.isParent == true) {
            allNodes = treeNode.children;
        } else {
            allNodes.push(treeNode);
        }
        for (var i = 0; i < allNodes.length; i++) {
            var numToString = allNodes[i].id.toString();
            numToString = numToString.replace(/\//g, '_');
            if (treeNode.checked == true) {
                $('#item_ranking_' + numToString).show();
            } else {
                $('#item_ranking_' + numToString).hide();
            }
        }
    }

    /*  addResult
            Still used ?
    */
    addResult(node, nodeParent) {
        if (this.tree.getNodeByParam("id", node.id, null) == null) {
            var p = this.tree.getNodeByParam("id", nodeParent.id, null);
            if (p == null) {
                var newNodes = this.tree.addNodes(null, {
                    id: nodeParent.id,
                    pId: null,
                    name: nodeParent.name,
                    isParent: true
                }, true);
                p = newNodes[0];

            }
            this.tree.addNodes(p, {
                id: node.id,
                pId: p.tId,
                name: node.name,
                isParent: false
            }, false);
        }
        $("#box_analysing_Ranking .overlay").hide(); //au moins un element, on masque la popup signalant qu'il faut run une simu
    }



    /*
        Add to the KPI list an input box to set a weight for the rank calcul
    */
    addDiyDom(treeId, treeNode) {
        if (treeNode.isParent == false) {
            var aObj = $("#" + treeNode.tId);
            var id = treeNode.id.replace(/\//g, '_');
            var editStr = "<span  id='item_ranking_" + id + "' disabled='true'" + (treeNode.checked == false ? " style='display:none'" : "") + " > - <input type='text' id='item_ranking_" + id + "_txt' title='Weight'/><select id='item_ranking_" + id + "_select' title='Trend'><option value='up'>up</option><option value='down'>down</option></select></span>";
            aObj.append(editStr);
        }
    }

    /*setKpi*/
    setKpi(data) {

        if (this.treeKpi != null) {
            $.fn.zTree.destroy("treeAnaRankKpi");
        }
        var zNodes = [];
        var nTmp = [];

        var pStr;
        var obj;
        var indice = -1;

      var UOselected = this.controller.GestionUrbObj.getCheckedNodesUO();
        // if (UOselected == 0) {
        // this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_CHECKCORRECT_NEIGHBORHOOD);

        // } else {

        if (UOselected.length > 1) {
            this.messageBadSelect();
           // this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_CHECKCORRECT_UO);
            //this.treeUO.checkAllNodes(false);
            this.treeKpi = null;
            $("#kpi_analysing_Ranking_overlay").hide();

            return -1;
        }else if (UOselected.length == 1) {
            if (data!=undefined && data!=null){
                var IsOneKpi = false;

                for (var i in data.attributes) {

                    if (i.indexOf("/Results/" + UOselected[0].UOtype + "/") == 0) {
                        //IGO_TMA_3 : https://curtis-integ.edf-labs.net/redmine/issues/2964
                        pStr = (data.attributes[i]["META-Initiative"] == undefined ? Cavi2Util.CONST_DEFAULT_META_INITIATIVE : data.attributes[i]["META-Initiative"]) + " - Weight - Trend";

                        if (pStr != undefined) {

                            IsOneKpi = true;

                            if (nTmp[pStr] == undefined) {
                                obj = {
                                    id: pStr,
                                    pId: 0,
                                    name: pStr,
                                    open: false,
                                    isParent: true
                                };
                                zNodes.push(obj);
                                nTmp[pStr] = obj.id;
                            }
                            obj = {
                                id: i,
                                pId: nTmp[pStr],
                                name: data.attributes[i]["LABEL"],
                                isParent: false
                            };
                            zNodes.push(obj);
                        }
                    }
                }

                //Disable calculate button if no Kpi
                if (IsOneKpi == false) {
                    document.getElementById("bt_analysing_Ranking_perf").disabled = true;
                    this.messageNoKpi();
                }
                else {
                    document.getElementById("bt_analysing_Ranking_perf").disabled = false;
                    var settings = {
                        view: {
                            dblClickExpand: true,
                            showLine: true,
                            selectedMulti: true,
                            addDiyDom: this.addDiyDom.bind(this)
                        },
                        check: {
                            enable: true,
                            chkboxType: {
                                "Y": "ps",
                                "N": "ps"
                            }
                        },
                        data: {
                            simpleData: {
                                enable: true,
                                idKey: "id",
                                pIdKey: "pId",
                                rootPId: ""
                            }
                        },
                        callback: {
                            onCheck: this.onCheckKpi.bind(this)
                        }
                    }

                    this.treeKpi = $.fn.zTree.init($("#treeAnaRankKpi"), settings, zNodes);
                    $("#treeAnaRankKpi a").css("width", "calc(100% - 35px)");
                    $("#analysing_Ranking_filter").keyup(function () {
                        var value = $.trim($("#analysing_Ranking_filter").val()).toLowerCase();
                        //Recoche tout
                        var nodes = this.treeKpi.getNodesByParam("isHidden", true);
                        let nodeList;
                        this.treeKpi.showNodes(nodes);

                        if (value != "") {
                            nodeList = this.treeKpi.getNodesByFilter(function filter(node) {
                                return !node.isParent && node.name.toLowerCase().indexOf(value) == -1;
                            });
                            //Hide all items
                            this.treeKpi.hideNodes(nodeList);
                        }

                    }.bind(this));
                }
            }else{
                this.messageNoKpi();
            }
        }else{
            this.messageBadSelect();
        }
        $("#kpi_analysing_Ranking_overlay").hide();
    }

    setVisibilityOverlay(visible) {
        if (visible) {
            $("#kpi_analysing_Ranking_overlay").show();
        } else {
            $("#kpi_analysing_Ranking_overlay").hide();
        }
    }

    resetMessage() {
        $('.kpiRankingMesage').hide();
    }

    messageBadSelect() {
        this.resetMessage();
        if (this.treeKpi != null) {
            $.fn.zTree.destroy("treeAnaRankKpi");
            this.treeKpi=null;
        }
        document.getElementById("bt_analysing_Ranking_perf").disabled = true;
        $('#kpiRankingUOAndScenario').show();
    }

    messageNoKpi() {
        this.resetMessage();
        document.getElementById("bt_analysing_Ranking_perf").disabled = true;
        $('#kpiRankingNoKpi').show();

    }
    /*Rank_Success*/
    rankSuccess(data_sort,input) {
        var res = "";
        var nb = 1;
        for (var i in data_sort) {
            if (data_sort.hasOwnProperty(i)) {
                var data_tmp = data_sort[i];
                res += "<tr><td>" + nb.toString() + "</td><td>" + this.idTreeToName(data_tmp[0]) + "</td><td>" + data_tmp[1] + "</td></tr>";
                $("#r_" + input.timeStep + "_" + data_tmp[0]).text(nb.toString());
                this.controller.setTabLastRank(data_tmp[0], {
                    pos: nb,
                    val: data_tmp[1]
                });
                nb++;
            }
        }

        //refresh datatables for ordering

        var table_rank = $('#table_result_rank').DataTable();
        table_rank.clear().destroy();
        $("#table_result_rank  > tbody").html(res);
        table_rank = $('#table_result_rank').DataTable();
        table_rank.order([0, 'asc'])
            .draw();


        var sauvhtml_matrix = $("#table_result_matrix  > tbody").html();
        var table_result = $('#table_result_matrix').DataTable();
        table_result.clear().destroy();
        $("#table_result_matrix  > tbody").html(sauvhtml_matrix);
        table_result = $('#table_result_matrix').DataTable();
        table_result.order([0, 'asc'])
            .draw();



        $("#kpi_analysing_Ranking_overlay").hide();

        document.getElementById("bt_analysing_Ranking_perf").disabled = false;
        $(".load_wait_fctRank").hide();
    }
    /*
        Return the name of an element
        id: id to find associated name
    */
    idTreeToName(id) {
        var nodes = this.tree.getNodesByParam("id", id);
        if (nodes.length > 0) {
            return nodes[0].name;
        } else {
            return id;
        }
    }

    /*
        Call the fct for calculating results, and updating content of tables
    */
    calculateResults() {

        document.getElementById("bt_analysing_Ranking_perf").disabled = true;
        $(".load_wait_fctRank").show();


        // var boolErreur = false;
        var totalCpt = 0;
        var nodes = this.tree.getCheckedNodes(true);
        var nodesKpi = null;
        if (this.treeKpi != null) {
            nodesKpi = this.treeKpi.getCheckedNodes(true);
        }
        if (nodes.length == 0 /*|| nodesKpi.length == 0*/) {
            if (nodesKpi != null) {
                this.UI.createAlert("alert", "info", this.contentMessageFileJSON.MESSAGE_ALERT_SELECT_SIMU);
                document.getElementById("bt_analysing_Ranking_perf").disabled = false;
                $(".load_wait_fctRank").hide();
                return -1;
            } else {
                this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_SELECT);
                document.getElementById("bt_analysing_Ranking_perf").disabled = false;
                $(".load_wait_fctRank").hide();
                return -1;
            }
        }

        $('[href=#tab_ranking_rank]').tab('show');
        $(".rank").text("-");
        var input = {
            simCases: [],
            timeStep: $("#analysing_Ranking_years option:selected").text(),
            KPIs: {}
        };
        var isEmpty = true;
        // var nodes = this.tree.getCheckedNodes(true);
        for (var i in nodes) {
            if (nodes.hasOwnProperty(i)) {
                if (nodes[i].isParent == false) {
                    isEmpty = false;
                    input.simCases.push(nodes[i].id);
                }
            }
        }
        if (isEmpty == false) {

            if (this.treeKpi != undefined) {
                nodes = this.treeKpi.getCheckedNodes(true);
                for (var i in nodes) {
                    if (nodes.hasOwnProperty(i)) {
                        if (nodes[i].isParent == false) {
                            var id = nodes[i].id.replace(/\//g, '_');
                            var name = nodes[i].id.replace("/Results/", "");

                            var weightforKPI = parseFloat($("#item_ranking_" + id + "_txt").val().replace(/,/g, '.'));
                            if (weightforKPI === "" || isNaN(weightforKPI)) {
                                this.UI.createAlert("alert", "error", this.contentMessageFileJSON.MESSAGE_ALERT_FILL_FORM);
                                document.getElementById("bt_analysing_Ranking_perf").disabled = false;
                                $(".load_wait_fctRank").hide();
                                return -1;
                            } else {
                                weightforKPI = Math.round(weightforKPI);
                                $("#item_ranking_" + id + "_txt").val(weightforKPI);
                                var preferredTrend = $("#item_ranking_" + id + "_select").val();
                                totalCpt += weightforKPI;

                                //igo TMA2.2
                              var UOselected = this.controller.GestionUrbObj.getCheckedNodesUO();
                                if (UOselected[0].id != undefined) {
                                    // Somes tests for checking the weight set on KPIs
                                    // it i necessary it doesn't be superior at 100
                                    //#3994
                                    //if (weightforKPI != NaN && weightforKPI >= 0.001 && weightforKPI <= 99.999 && totalCpt <= 100) {
                                    if (weightforKPI != NaN && weightforKPI >= 1) {
                                        input.KPIs[name] = {
                                            "weight": weightforKPI,
                                            "preferredTrend": preferredTrend,
                                            //igo TMA 2
                                            "pid": [UOselected[0].id]
                                        };

                                    } else {
                                        totalCpt = parseFloat(totalCpt.toFixed(2));
                                        //if (weightforKPI < 0.001 || weightforKPI >= 99.999) {
                                        if (weightforKPI < 1) {
                                            this.UI.createAlert("alert", "error", this.contentMessageFileJSON.MESSAGE_ALERT_OUTOFRANGE);
                                            document.getElementById("bt_analysing_Ranking_perf").disabled = false;
                                            $(".load_wait_fctRank").hide();
                                            return -1;
                                        } /*else if (totalCpt > 100) {
                                            this.UI.createAlert("alert", "error", this.contentMessageFileJSON.MESSAGE_ALERT_COUNT);
                                            document.getElementById("bt_analysing_Ranking_perf").disabled = false;
                                            $(".load_wait_fctRank").hide();
                                            return -1;
                                        }*/
                                        //end #3994
                                    }
                                } else {
                                    //this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_NO_NEIGHBORHOOD_SELECTED);
                                    // igo TMA 2
                                    this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_ERROR_UO);
                                    document.getElementById("bt_analysing_Ranking_perf").disabled = false;
                                    $(".load_wait_fctRank").hide();

                                    return -1;
                                }
                            }
                        }
                    }
                }

            } else {
                this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_SELECT);
                document.getElementById("bt_analysing_Ranking_perf").disabled = false;
                $(".load_wait_fctRank").hide();
                return -1;
            }

            this.controller.executeRanking(input);

        }

        $('[href=#tab_ranking_matrix]').tab('show');

        // loop on each simulations selected
        var nodes = this.tree.getCheckedNodes(true);
        if (this.treeKpi != undefined) {


            var nodesKpi = this.treeKpi.getCheckedNodes(true);
            //Reset graph
            this.controller.setcDataChart([]);
            $("#tab_ranking_matrix").empty().append('<table id="table_result_matrix" class="table table-bordered table-striped"><thead></thead><tbody></tbody><tfoot></tfoot></table>');
            var thead_foot = "<tr><th>Rank</th><th>Simulation Case</th>";
            var tbody = "";

            this.controller.setCurrentYear({
                val: $("#analysing_Ranking_years").val(),
                txt: $("#analysing_Ranking_years option:selected").text()
            });
            $(".setYear").text("(Year:" + this.controller.getCurrentYear().txt + ")");
            var isFirst = true;
            //Init tableau matrix empty
            for (var i in nodes) {
                if (nodes.hasOwnProperty(i) && nodes[i].isParent == false) {
                    tbody += "<tr><td id='r_" + this.controller.getCurrentYear().txt + "_" + nodes[i].id + "' class='rank'>" + (this.controller.getTabLastRank(nodes[i].id) != undefined ? this.controller.getTabLastRank(nodes[i].id).pos : "-") + "</td><td>" + nodes[i].name + "</td>";
                    //Loop on each kpi
                    for (var k in nodesKpi) {
                        if (nodes.hasOwnProperty(i) && nodesKpi[k].isParent == false) {
                            //Init matrix table
                            var it = nodesKpi[k].id.split("/");
                            it = it[it.length - 1];
                            if (isFirst == true)
                                thead_foot += "<th>" + nodesKpi[k].name + "</th>";
                            tbody += "<td id='kpi_" + nodes[i].id + "_" + it + "'>-</td>";
                        }
                    }
                    if (isFirst == true) {
                        thead_foot += "</tr>";
                        isFirst = false;
                        $("#table_result_matrix  > thead").html(thead_foot);
                        $("#table_result_matrix  > tfoot").html(""); //on enléve le pied de tableau
                        //$("#table_result_matrix  > tfoot").html(thead_foot);
                    }
                    tbody += "</tr>";
                }
            }
            $("#table_result_matrix  > tbody").html(tbody);
            //igo TMA 2

            $('#table_result_matrix').DataTable();

            // $('#table_result_matrix').DataTable({
            //     "order": [[0, "asc"]]
            // });
            this.controller.calculateResultsKpi(nodes,nodesKpi);
        } else {
            this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_SELECT);
            document.getElementById("bt_analysing_Ranking_perf").disabled = false;
            $(".load_wait_fctRank").hide();
            return -1;
        }

    }

    callBackAddResult(params, val, unit) {
        $('#kpi_' + params.id + '_' + params.it).text(val + ' ' + unit);



    }
    /*Everest_callbackError*/
    everestCallbackError(data, status) {
        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");
    }
}

export default AnalysingRankingUI;

