import MosaUI from "../view/mosa_ui.js";
/*
    Contains fct applied on a specific MOSA
*/
class MOSA_CLS{
    constructor(Mosa, fct_Select, Everest, UI) {

        this.mosa = Mosa;
        this.Everest = Everest; //mosa recup de mosa.
        this.id = this.mosa.id; //Raccourcit pour acces à l'id.
        this.name = this.mosa.name;
        this.status = this.mosa.state;
        this.fct_Select = fct_Select;
        this.idMID = undefined;
        this.idGML = undefined;
        this.dataResponse = undefined;
        this.myRunId = undefined;
        this.fileLog = undefined;


        //UI
        this.mosaUI=new MosaUI(UI,this);
    }

  GetStudyAreaName(){
    if (this.mosa != undefined && this.mosa.studyArea != undefined && this.mosa.studyArea.code != undefined) {
      return this.mosa.studyArea.code;
    } else {
      return "";
    }
  }

    Show() {
        this.mosaUI.show(this.mosa.name);
    }

    Hide() {
        this.mosaUI.hide();
    }

    // Remove table of list MOSA
    Reset() {
        this.mosaUI.reset(this.id);
        this.id = null;
    }


    // on the MOSA manage page in user normal mode
    // Add a MOSA in the dropdown list
    AddList() {
        this.mosaUI.addList(this.mosa.name);
    }

    // On SuperUser mode
    // Add a new line for a MOSA with all its elements
    AddDiv() {
        this.mosaUI.addDiv(this.id,this.mosa);
    }

    //Click on icons functions
    publish(id) {
        var self = this;
        var statusModif = (this.status == "PUBLISHED" ? "UNPUBLISHED" : "PUBLISHED");
        this.status = statusModif;
        var dateActu = new Date();
        // var dateForma = dateActu.getDate() + "/" + (dateActu.getMonth() + 1) + "/" + dateActu.getFullYear();
        var dateForma = dateActu.getFullYear() + "-" + (dateActu.getMonth() + 1) + "-" + dateActu.getDate() + " " + dateActu.getHours() + ":" + dateActu.getMinutes() + ":" + dateActu.getSeconds() + "." + dateActu.getMilliseconds();

        var inputs = {
            "state": statusModif,
            "updateDate": dateForma
        };
        this.Everest.Update_Model(id, inputs, ()=> {
            this.mosaUI.publish(id,statusModif);
        }, this.Everest_callbackError.bind(this));


    }

    // In SuperUser
    // Let us to update MID and CityGML files for a MOSA
    update(id) {
        this.mosaUI.update(id);
    }

    /*
        when user selects a type of file to update
        ->creates a new box and launches process to update the file MID or GML
    */


    updateMOSAfile(typeFileUpload) {
        this.mosaUI.updateMOSAfile(typeFileUpload,this.id);
    }
    createFile(typeFileUpload,type,nameFile, nameParsed,inputFile){
        if (typeFileUpload == "MID") {
            type = "MID";
        } else if (typeFileUpload == "3D") {
            type = "CityGML_CAVI2";
        }

        var meta = {
            "modelId": this.id.toString()
        };
        this.Everest.Create_File(nameParsed, type, meta, (data, status, p)=> {

            var fileID = data.id;
            var typeZip = "application/zip";
            if (typeFileUpload == "MID") {
                this.idMID = fileID;
                this.mosaUI.setIdMid(fileID);
            } else if (typeFileUpload == "3D") {
                this.idGML = fileID;
                this.mosaUI.setIdGml(fileID);
            }
            var reader = new FileReader();
            reader.onloadend = ()=> {
                this.Everest.Upload_File(nameFile, fileID, typeZip, reader.result, (d, s, p)=> {

                    var fileLog = "functional.log";
                    var runid = d.runId;
                    this.mosaUI.setRunId(runid);
                    this.Everest.Read_Run_Status_Exec(runid, (d, s, p)=> {
                        this.dataResponse = d;
                        this.myRunId = runid;
                        this.fileLog = fileLog;

                        // temporaire pour import du MID FILE sans message d'erreur
                        // il faudrait filtrer le message d'erreur dans la fonction Everest_callbackError
                        // et le passer en info
                        //setTimeout(()=>{
                            this.readRun(d, runid, fileLog);
                        //},15000); //delay is in milliseconds
                    });
                    },
                    this.Everest_callbackError.bind(this));

            };//End reader.onloadend
            reader.readAsArrayBuffer(inputFile.files[0]);
            },
            this.Everest_callbackError.bind(this));
    }



    /*
        Uses a timeout to launch again the read_run_stauts and update the TextArea infos
        When progress==100 (upload file is finished), launches the Everest raq updateModel to set the new update date, and id of the new file
    */

    readRun(donnee, runid, fileLog) {
        this.Everest.Read_Run_Status_Exec(this.myRunId, (data, status, p)=> {
            this.dataResponse = data;
            this.readRunStatusExec(this.dataResponse);
        }, this.Everest_callbackError.bind(this));


    }

    Everest_callbackError_Waiting(data, status) {
        this.mosaUI.setProgressMessage(data.responseText);
    }

    readRunStatusExec(dataResponse) {
        this.mosaUI.readRunStatusExec(dataResponse);//Display the message
        if (dataResponse.status == "failed") {
            clearTimeout();
            this.mosaUI.disabledFormMosaFile(false);
        } else if (dataResponse.progress == 100) {
            var dateActu = new Date();
            var dateForma = dateActu.getFullYear() + "-" + (dateActu.getMonth() + 1) + "-" + dateActu.getDate() + " " + dateActu.getHours() + ":" + dateActu.getMinutes() + ":" + dateActu.getSeconds() + "." + dateActu.getMilliseconds();
            var inputs = {};
            if (this.idMID != undefined) {
                inputs = {
                    "MIDFileId": this.idMID,
                    "updateDate": dateForma
                };
            } else if (this.mosaUI.getIdGml()!= undefined) {
                inputs = {
                    "cityGMLFileId": this.idGML,
                    "updateDate": dateForma
                };
            }
            this.Everest.Update_Model(this.id, inputs, ()=> {
                this.mosaUI.updateModel(this.idMID,this.id,this.idGML);
            }, this.Everest_callbackError.bind(this));
        } else //if ((dataResponse.status == "inProgress" || "created") && dataResponse.progress < 100)
        {
            setTimeout(this.readRun.bind(this), 5000);
        }
    }

    readRunOutputFile(posScroll){
        this.Everest.Read_Run_Output_File(this.myRunId, this.fileLog, (d, s, p)=> {
            console.log(d, s, p);
            this.mosaUI.readRunOutputFile(d,posScroll);
            //}, this.Everest_callbackError_Waiting.bind(this));
        }, this.Everest_callbackError.bind(this));
    }
    setIdMid(val){
        this.idMID=val;
    }
    setIdGml(val){
        this.idGML=val;
    }


    // In SuperUser
    // Delete the selected MOSA
    delete_fct(id) {
        this.mosaUI.deleteFct(id,this.name);
    }
    deleteModel(id){
        this.Everest.Delete_Model(id, ()=> {
            this.mosaUI.deleteModel(id);
        }, this.Everest_callbackError.bind(this));
    }
    // In SuperUser
    // Show infos about selected MOSA
    info(id) {

        // -> reset the content div
        this.Everest.Results["List_Models_Info"] = undefined;

        // add infos
        this.Everest.Set_Selected_Id(id);

        this.Everest.List_Models_Info((data, status, id)=> {
            this.mosaUI.listModelsInfo(data);
        }, null);
    }


    Everest_callbackError(data, status) {
        this.mosaUI.everestCallbackError(data, status);
    }

}
export default MOSA_CLS;
