import Simulating_ContextList_Edit_CLS from './interface_gestion_context.js'
var Simulating_ContextList_CLS = function(Everest, UI) {
    this.UI = UI;
    this.Everest = Everest;
    this.contentFileJSON = this.UI.accessContentJSON;
    this.contentMessageFileJSON = this.UI.accessMessageContentJSON;
    this.saveData = null;
    this.cptData = 0;

    this.EditContext = undefined;

    //Construit la box de gestion des résultats
    //Rajoute la box
    this.createboxContextList(function() {
        return true;
    });

    var rMenu = "<div id=\"rMenuContextList\" class=\"box-body no-padding\" >";
    rMenu += "      <a class=\"btn btn-block btn-social btn-xs use-skin\" style=\"margin-top:1px;\" id=\"m_edit_contextList\"><i class=\"fa fa-edit\"></i> " + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_EDIT + "</a>";
    rMenu += "      <a class=\"btn btn-block btn-social btn-xs use-skin\" style=\"margin-top:1px;\" id=\"m_infos_contextList\"><i class=\"fa fa-info\"></i> " + this.contentFileJSON.MENU_LEFT_SUPER_USER_RIGHT_INFOS + "</a>";
    rMenu += "  </div>";
    $("#page_centre").prepend(rMenu);
    this.tree = $("#treeContextList");
    this.isInit = false;
    this.zNodes = [];
    this.currentCopy = null;
    $("#box_Simulating_ContextList .overlay").show();

    this.init = function() {
        if (this.UI.classBindData.authToken != null) {
            if (this.isInit == true) {
                $.fn.zTree.destroy("treeContextList");
                this.isInit = false;
            }
            this.zNodes = [];
            this.Everest.List_Scenarios(null, this.initTree.bind(this), this.Everest_callbackError.bind(this));

            //Menu  : Click Droit
            //Init le click sur "Edit TargetList Simulation" du menu droit
            $("#m_edit_contextList").click($.proxy(function() {
                $("#rMenuContextList").hide();
                $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
                if (this.tree.getSelectedNodes()[0]) {
                    this.BT_EditContext(this.tree.getSelectedNodes()[0]);
                }
            }, this));
            //Init le click sur "Infos" du menu droit
            $("#m_infos_contextList").click($.proxy(function() {
                $("#rMenuContextList").hide();
                $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
                if (this.tree.getSelectedNodes()[0]) {
                    var node = this.tree.getSelectedNodes()[0];
                    this.Everest.Read_Scenario_Info(node, node.id, this.callbackSuccessInfos.bind(this), this.Everest_callbackError.bind(this));
                }
            }, this));

        }
    };


    this.addDiyDom = function(treeId, treeNode) {
        let aObj = $("#" + treeNode.tId);

        if (treeNode.level === 0 && this.saveData.items[this.cptData]) {
            this.Everest.Read_Scenario_Info(this.saveData.items[this.cptData], this.saveData.items[this.cptData].id, (d) => {
                aObj.prepend("<i  id=\"status_" + treeNode.tId + "\" class=\"fa fa-" + (d.published === true ? "unlock-alt\" style=\"color:green" : "lock\" style=\"color:orange") + "\"></i> ");
                // update the tree node
                treeNode["published"] = d.published === true;
                this.tree.updateNode(treeNode);

            }, this.Everest_callbackError.bind(this));
        }
        this.cptData++;
    };


    this.initTree = function(data, status, id) {
        this.cptData = 0;
        this.saveData = data;
        for (var i = 0; i < data.items.length; i++) {
            var obj = {
                id: data.items[i].id,
                pId: 0,
                name: data.items[i].name,
                isParent: false,
                published: data.items[i].published,
                description: data.items[i].description
            };
            this.zNodes.push(obj);

        }

        if (data.nextUrl != null) {
            this.Everest.getNextUrl(data.nextUrl, id, this.initTree.bind(this), this.Everest_callbackError.bind(this));
        } else {
            if (this.zNodes.length > 0) {

                var settings = {
                    view: {
                        dblClickExpand: false,
                        showLine: false,
                        selectedMulti: false,
                        addDiyDom: this.addDiyDom.bind(this)
                    },
                    data: {
                        simpleData: {
                            enable: true,
                            idKey: "id",
                            pIdKey: "pId",
                            rootPId: ""
                        }
                    },
                    callback: {
                        onRightClick: this.OnRightClick.bind(this)
                    }
                };


                this.tree = $.fn.zTree.init(this.tree, settings, this.zNodes);
                this.isInit = true;
            }
            $("#box_Simulating_ContextList .overlay").hide();
        }
    };

    this.OnRightClick = function(event, treeId, treeNode) {

        if (!treeNode && event.target.tagName.toLowerCase() != "button" && $(event.target).parents("a").length == 0) {
            this.tree.cancelSelectedNode();
        } else if (treeNode && !treeNode.noR) {
            this.tree.selectNode(treeNode);
            this.showRMenu("node", event.clientX, event.clientY);
        }
    };

    this.showRMenu = function(type, x, y) {
        $("#rMenuContextList").stop().css({
            "top": y + "px",
            "left": x + "px"
        }).show("fold");
        $("body").bind("mousedown", this.onBodyMouseDown.bind(this));
    };

    this.onBodyMouseDown = function(event) {
        if (!(event.target.id == "rMenuContextList" || $(event.target).parents("#rMenuContextList").length > 0)) {
            $("#rMenuContextList").hide();
            $("body").unbind("mousedown", this.onBodyMouseDown.bind(this));
        }
    };

    this.Addchild = function(data, status, obj) {
        var tabNode = [];
        for (var i = 0; i < data.items.length; i++) {
            tabNode.push({
                id: data.items[i].id,
                pId: obj.id,
                name: data.items[i].name
            });
        }
        var newNodes = this.tree.addNodes(null, tabNode, true);
        if (data.nextUrl != undefined && data.nextUrl != null) { //On charge la suite
            this.Everest.getNextUrl(data.nextUrl, obj, this.callbackSuccessChild.bind(this), this.Everest_callbackError.bind(this));
        }
    };


    this.BT_EditContext = function(node) {
        if (this.EditContext == undefined) {
            this.EditContext = new Simulating_ContextList_Edit_CLS(this.Everest, this.UI);
            this.EditContext.init();
        }
        this.UI.moveUOto({
            idParent: "box_Simulating_ContextList",
            id: "uo_ContextList_Edit",
            ItemLink: this.EditContext
        });
        this.EditContext.Load(node);
    };
    this.callbackSuccessInfos = function(data, status, node) {
        this.createboxInfosCtx(data, function() {
            return true;
        });
    };

    this.Everest_callbackError = function(data, status) {
        var error;
        if (data.responseJSON != undefined) {
            error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
        }

        if (data.responseJSON != undefined)
            this.UI.createMessageError(error, data.responseJSON.errorMessage);
        else if (data.responseText != undefined)
            this.UI.createMessageError("", data.responseText);
        else
            this.UI.createAlert("alert", "error", "ERROR");
    };

};
Simulating_ContextList_CLS.prototype.constructor = Simulating_ContextList_CLS;

Simulating_ContextList_CLS.prototype.createboxContextList = function(CallBackClose) {

    $("#box_Simulating_ContextList").remove();
    var c = "<div id=\"Simulating_ContextList_content\">";
    c += "      <ul id=\"treeContextList\" class=\"ztree\"></ul>";
    c += "      <div id=\"Simulating_ContextList_overlay\" class=\"overlay\">";
    c += "          <i class=\"fa fa-refresh fa-spin\"></i>";
    c += "      </div>";
    c += "  </div>";

    this.UI.CreateBox("Simulating_ContextList", {
        draggable: true,
        group: "Simulating",
        resizable: {
            minHeight: 500,
            minWidth: 475
        },
        useSkin: true,
        title: this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_TITLE,
        content: c,
        visible: false,
        menuBt: {
            reduceTo: "div_ScenarioContext", //ID vers qui on doit réduire la div
            fullscreen: {
                css: {
                    position: "absolute",
                    top: "140px",
                    width: "500px",
                    height: "500px",
                    left: "9%",
                    right: "",
                    bottom: ""
                },
                fct: function() {

                }.bind(this)
            } //position / taille de div pour quand on sort du fullscreen
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "140px",
            left: "9%",
            width: "475px",
            height: "500px",
            "min-width": "475px",
            "min-height": "500px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};

Simulating_ContextList_CLS.prototype.createboxInfosCtx = function(data, CallBackClose) {

    $("#box_infosContextList").remove();
    var c = "<div id=\"infosContextList_content\" style=\"width:100%; height:100%;\">";
    c += "      <table style=\"width:100%; height:100%;\">";
    c += "          <tr style=\"height: 50px;\"><td>" + this.contentFileJSON.MENU_NEW_NAME + " </td>";
    c += "              <td>" + data.name + "</td></tr>";
    c += "          </tr>";
    c += "          <td>" + this.contentFileJSON.MENU_NEW_COMMENTS + " </td>";
    c += "          <td>";
    c += "              <div class=\"box box-solid box-primary\" style=\"width:100%; height:100%; overflow:auto; text-align:left;padding:5px\" >" + data.description + "</div>";
    c += "          </td></tr>";
    c += "          <tr style=\"height: 50px;\"><td>" + this.contentFileJSON.MENU_LEFT_SUPER_USER_SCENARIO_CONTEXT_INFO_PUBLISHED + " </td>";
    c += "              <td><i class=\"fa fa-" + (data.published == true ? "unlock-alt\" style=\"color:green" : "times-circle\" style=\"color:red") + "\"></i>" + (data.published == true ? " Yes" : " No") + "</td></tr>";
    c += "          </tr>";
    c += "      </table>";
    c += "  </div>";

    this.UI.CreateBox("infosContextList", {
        draggable: true,
        group: "Simulating",
        useSkin: true,
        title: data.name,
        content: c,
        resizable: {
            minHeight: 310,
            minWidth: 400
        },
        visible: true,
        menuBt: {
            close: true
        },
        style: { //Constient tous les styles à appliquer avec en id le nom du style
            position: "absolute",
            top: "30%",
            left: "40%",
            width: "400px",
            height: "310px",
            "min-width": "400px",
            "min-height": "310px",
            "z-index": Zindex + 1
        }
    }, CallBackClose);
    Zindex = Zindex + 1;
};
export default Simulating_ContextList_CLS;
