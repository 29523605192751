import AnalysingTargetingUI from '../view/analysing_targeting_ui.js';
/*
 Class to manage the Analysing->Targeting (KPI evaluation) tab
Using a predefined target, the user can compare the results of different simulations. The target can only be defined by a Super User.

*/

class Analysing_Targeting_CLS{
  constructor(Everest, UI, GestionUrbObj) {

    this.GestionUrbObj = GestionUrbObj;
        this.analysingTargetingUI = new AnalysingTargetingUI(UI,this);
        this.Everest = Everest;

        this.currentKpiData = null;
        this.currentSimId = null;

        this.cDataChart;

        this.tabValues = [];


        //int : defines total nb : nb simu x nb kpi
        this.cptTotalPtSpiderGraph = 0;
        //int : defined the current nb
        this.cptActualPtSpiderGraph = 0;

        this.dataTarget;

        //stores id of target graph : only one target can be selected at the same time.
        // So the id set on this.chart correspondig at the target is this name
        this.nameTarget = "Targets";



        //save data items corresponding at list items targets published
        this.saveData = null;

        // if the nextUrl for list targets is not null
        // stores other items in saveData
        this.nextUrl = false;

        this.errorKpi=false;
    }
    setErrorKpi(val){
        this.errorKpi=val;
    }
    getErrorKpi(){
        return this.errorKpi;
  }
  getCheckedNodesTree() {
    return this.analysingTargetingUI.getCheckedNodesTree();
  }

    /*
    init and Build the box and define event click
    */
    init(GestionSimulations) {
        this.analysingTargetingUI.init();

        this.Everest.List_Models_Info((data, status, id)=> {
            var timescalesItem = null;
            for (var i in data.timescales) {
                if (data.timescales.hasOwnProperty(i)) {
                    if (data.timescales[i].code != "C") { //item.timescale) {
                        timescalesItem = data.timescales[i];
                    }
                }
            }
            this.analysingTargetingUI.initSlider(timescalesItem);
        }, this.Everest_callbackError.bind(this));

        GestionSimulations.addEventTree("treeSimAnaTargeting", this.updateSimCaseTree.bind(this));

        this.Everest.List_Targets(null, this.initSelectTarget.bind(this), this.Everest_callbackError.bind(this));

    }
    getCurrentKpiData(){return this.currentKpiData;}
    setCurrentKpiData(val){this.currentKpiData=val;}


    /**
        Fct which add or remove nodes on the simcase ztree on interfaces : ranking, results, targeting
        This is NOT a shared tree, cause events are different for each of these interfaces
        This fct is called when user add, remove, run a simcase
            -> stored in tabEvent and called in runComplete() : interface_gestion_simu.js
    */
    updateSimCaseTree(node, nodeParent, boolIsDelete) {
        this.analysingTargetingUI.updateSimCaseTree(node, nodeParent, boolIsDelete);
    }
    getIndexSimuRun(id){
        return this.Everest.infosSimuRun.findIndex(function(x) {
            return x.idSim == id;
        });
    }
    getInfoSimuRun(index){
        return this.Everest.infosSimuRun[index];
    }

    getOpenDapMetadata(node){
        this.Everest.GetOpenDapMetadata(node.id, node.id, this.SetKpi.bind(this), this.setKpiError.bind(this));
    }
    getTimeScale(kpiId) {
        var retour = "";
        if (this.currentKpiData != undefined && this.currentKpiData[kpiId] != undefined && this.currentKpiData[kpiId].maps != undefined) {
            var data = this.currentKpiData[kpiId].maps;
            for (var key in data) {
                if (!/^TIMESCALE-.*/.test(key)) continue;
                retour = key.substr(10);
            }
        }
        return retour;
    }

    setKpiError(data, status){
        this.SetKpi(null,null);
        this.errorKpi=true;
    }

    /**
    Call if a Scenario or a UrbanObject is checked
    **/
  updateCheck() {
    this.GestionUrbObj.update_tree_others();
        this.errorKpi=false;
        this.analysingTargetingUI.resetMessage();
        var nodes = this.analysingTargetingUI.getCheckedNodesTree();
        if(nodes.length > 0){
          var nodesUO = this.GestionUrbObj.getCheckedNodesUO();
            if (nodesUO.length > 0) {
                var noRes=true;
                for (var i in nodes) {
                    if (nodes.hasOwnProperty(i)) {
                        if (nodes[i].isParent != true) {
                            this.analysingTargetingUI.setVisibilityOverlay(true);
                            this.getOpenDapMetadata(nodes[i]);
                            this.analysingTargetingUI.UI.allreadycharge_AnaTar = true;
                            noRes=false;
                        }
                    }
                }
                if (noRes==true){
                    //Show message check at least one UO + one Scenario
                    this.analysingTargetingUI.messageBadSelect();
                }
            }else{
                //Show message check at least one UO + one Scenario
                this.analysingTargetingUI.messageBadSelect();
            }
        }else{
            //Show message check at least one UO + one Scenario
            this.analysingTargetingUI.messageBadSelect();
        }
    }
    /*
     on List_Targets() request callback success, it creates the dropdown list containing all published targets
    */
    initSelectTarget(data, status, param) {
        if (this.nextUrl == true) {
            for (var element in data.items) {
                if (data.items.hasOwnProperty(element)) {
                    this.saveData.items.push(data.items[element]);
                }

            }
        } else {
            this.saveData = data;
        }

        if (data.nextUrl != null) {
            this.analysingTargetingUI.resetATSelect();
            this.nextUrl = true;
            this.Everest.getNextUrl(data.nextUrl, param, this.initSelectTarget.bind(this), this.Everest_callbackError.bind(this));
        } else {
            this.analysingTargetingUI.fillATSelect(this.saveData);
        }

    };


    /*
    called when user change the selected target
    update the KPI list to check KPI defined by this target with the callbackSuccessTarget_KPIValues() called on success of Read_Target_KPIValues()
    */
    updateKpiSelection(data) {
        this.dataTarget = undefined;
        this.analysingTargetingUI.updateKpiSelection();

    }
    readTargetKPIValues(targetSel,id){
        //igo TMA 2
        //mettre le pid de l'UO selectionne
        this.Everest.Read_Target_KPIValues({
            t: targetSel,
            p: id
        }, targetSel, id, this.callbackSuccessTarget_KPIValues.bind(this), this.Everest_callbackError.bind(this));
    }

    /*
        Gets the data returned by the Read_Target_KPIValues() request, containing KPIs associated at the selected target
        and then launches the result calculate
    */
    callbackSuccessTarget_KPIValues(data, status, param) {


        //data: kpi modified in the target selected (KPI check)
        this.dataTarget = this.analysingTargetingUI.callbackSuccessTarget_KPIValues(data, status, param);
        this.ResultGraph();
    };


    /*
        Defines the event onchange, when user move the indicator of the year slider
    */
    Slider_OnChange(data) {
        //only if a graph dataprovider is defined

        //igo TMA 2
        //if (this.dataTarget != undefined) {
            this.updateTabChart();
        //}

    };

    /* Still used ??? */
    addResult(node, nodeParent) {
        this.analysingTargetingUI.addResult(node, nodeParent);
    }




    /*
    when user select a simulation, it launches the GetOpenDapMetadata() request
    data : result of the GetOpenDapMetadata() req
    then create the ztree of KPIs list
    Then update the selected KPIs (check according to the selected Target )
    */
    SetKpi(data) {
        this.currentKpiData = (this.errorKpi==false?data:null);//Used to get later these data
        this.analysingTargetingUI.setKpi((this.errorKpi==false?data:null));
    }


    /*
    Fct which launches calcul for the targeting
    Adn update spider graphs data
    */
    ResultGraph() {
        //Reset graph data
        this.cDataChart = [];

        //Reset cpt associated to this graph
        this.cptActualPtSpiderGraph = 0;
        this.cptTotalPtSpiderGraph = 0;

        var res = this.analysingTargetingUI.resultGraph();
        if (res==-1){// If res contains -1 there is a data missing, we stop the function.
            return -1;
        }
        //else res contains the data to treat.
        var nodes=res.nodes;
        var nodesKpi=res.nodesKpi;

        for (var i in nodes) {
            if (nodes.hasOwnProperty(i) && nodes[i].isParent == false) {

                //Loop on each KPIs
                for (var k in nodesKpi) {
                    if (nodesKpi.hasOwnProperty(k)) {
                        if (nodesKpi[k].isParent == false) {
                            var params = [];
                            var filtre = "";
                            var it = nodesKpi[k].id.split("/");
                            it = it[it.length - 1];
                            params.id = nodes[i].id;
                            params.name = nodes[i].name;
                            params.idKpi = nodesKpi[k].id;
                            params.nameKpi = nodesKpi[k].name;
                            params.it = it;
                            if (this.currentKpiData[it] != undefined) {
                                //Mode 1 seul kpi, la requete multi marche pas.
                                filtre += (filtre == "" ? '' : ',') + this.currentKpiData[it].array.dimensions[1] + "," + this.currentKpiData[it].array.dimensions[0] + "," + nodesKpi[k].id;

                                params.type1 = this.currentKpiData[it].array.dimensions[0];
                                params.type2 = this.currentKpiData[it].array.dimensions[1];
                                params.unit = this.currentKpiData.attributes[nodesKpi[k].id].UNIT;
                            }
                            //params = { id: nodes[i].id, idKpi: nodesKpi[k].id, tab: params };
                            if (this.tabValues[nodes[i].id] != undefined && this.tabValues[nodes[i].id][nodesKpi[k]] != undefined) {
                                //If the request already has been launched, add the result
                                this.CallBackAddResult(this.tabValues[nodes[i].id][nodesKpi[k]], params);
                            } else {
                                //Launches openDap request
                                var filter = '.dods?' + filtre;
                                this.Everest.getOpenDap(nodes[i].id, filter, params, this.CallBackAddResult.bind(this), this.Everest_callbackError.bind(this));
                            }
                        }
                    }
                }

            }
        }
        var idTarget = this.nameTarget;
        if (this.dataTarget != undefined) {
            for (var it in this.dataTarget) {
                if (this.dataTarget.hasOwnProperty(it)) {
                    for (var year in this.dataTarget[it]) {
                        if (this.dataTarget[it].hasOwnProperty(year)) {
                            if (this.cDataChart[year] == undefined) {
                                this.cDataChart[year] = [];
                            }
                            if (this.cDataChart[year][it] == undefined) { //Kpi
                                this.cDataChart[year][it] = {
                                    id: it
                                };
                            }
                            this.cDataChart[year][it][idTarget] = this.dataTarget[it][year];
                        }
                    }
                }
            }

            this.analysingTargetingUI.addGraph(idTarget);


        } else {
            this.analysingTargetingUI.removeGraph(idTarget);
        }
        /*
        } else {
            //if user select 'none'
            //error msg and uncheck nodes kpi
            treeKpi.checkAllNodes(false);
            this.UI.createAlert("alert", "warning", this.contentMessageFileJSON.MESSAGE_ALERT_MUST_CHOOSE_TARGET);
            document.getElementById("bt_analysing_Targeting_spider").disabled = false;
            $("#load_wait_fct").hide();
            return -1;
        }*/
    }

    setCptTotalPtSpiderGraph(val){this.cptTotalPtSpiderGraph=val;}


    /*
    Processing data returned by OpenDap
    add graphs on the chart, and transform value in %
    */
    CallBackAddResult(data, params) {


        if (this.tabValues[params.id] == undefined) {
            this.tabValues[params.id] = [];
        }
        if (this.tabValues[params.id][params.idKpi] == undefined) {
            this.tabValues[params.id][params.idKpi] = data;
        }

        var nodesKpi = this.analysingTargetingUI.getCheckedNodesTreeKpi();


        for (var x = 0; x < data[params.type1]["data"].length; x++) {
            if (this.cDataChart[x] == undefined) {
                this.cDataChart[x] = [];
            }


            if (this.cDataChart[x][params.nameKpi] == undefined) {
                this.cDataChart[x][params.nameKpi] = {
                    id: params.nameKpi
                };
            }

            this.cDataChart[x][params.nameKpi][params.id] = data[params.it]["data"][0][x][0]; //On prend que la 1ere valeur dans tous les cas.
        }

        this.analysingTargetingUI.checkAndAddgraph(params,this.dataTarget);

        // change the display value on graphs
        // % calcul

            // user select a target
        var targetSel = this.analysingTargetingUI.getTargetSel();
        if (targetSel != -1) {
            var idTarget = this.nameTarget;
            if (this.dataTarget != undefined) {
                for (var i = 0; i < this.cDataChart.length; i++) {
                    if (this.cDataChart[i][params.nameKpi][idTarget] != undefined) {

                        //if value target != 0
                        if (this.cDataChart[i][params.nameKpi][idTarget] != 0) {
                            var cent = (this.cDataChart[i][params.nameKpi][params.id] * 100) / (this.cDataChart[i][params.nameKpi][idTarget]);
                            if (cent % 1 != 0) {
                                cent = Math.round(cent);
                            }
                            this.cDataChart[i][params.nameKpi][params.id] = cent;

                        } else {
                            //if value target = 0
                            this.cDataChart[i][params.nameKpi][params.id] = 0;
                        }
                    }
                    else if (this.cDataChart[i][params.nameKpi][params.id] != undefined) {
                        //igo TMA 2. pas de valeur sur la target
                        this.cDataChart[i][params.nameKpi][idTarget] = 0;

                    }
                }

            }
        }


        // at the end of processing
        // if we have finish to process all data (nb selected KPI * nb selected sim )
        // we order the graph branch : we use the ascending order in clockwise direction
        this.cptActualPtSpiderGraph++;
        if (this.cptActualPtSpiderGraph == this.cptTotalPtSpiderGraph) {
            // we loop on the content of dataprovider to set value of 'Target' at 100 (for a good draw on chart)
            for (var i = 0; i < this.cDataChart.length; i++) {
                for (var element in this.cDataChart[i]) {
                    if (this.cDataChart[i].hasOwnProperty(element)) {
                        //igo TMA 2
                        if (targetSel != -1) {

                            if (this.cDataChart[i][element][idTarget] != undefined) {
                                //if value target != 0
                                if (this.cDataChart[i][element][idTarget] != 0) {
                                    this.cDataChart[i][element][idTarget] = 100;
                                } else {
                                    //if value target = 0
                                    this.cDataChart[i][element][idTarget] = 0;
                                }
                            }
                        }
                        else
                        {
                            //get Val max for one kpi
                            var val_max = 0;
                            for (var id_simu in this.cDataChart[i][element]) { 
                                if (this.cDataChart[i][element].hasOwnProperty(id_simu) && id_simu!="id") {

                                    //test numeric value
                                    if(!isNaN(parseInt(this.cDataChart[i][element][id_simu])))
                                    {
                                        if(this.cDataChart[i][element][id_simu] > val_max)
                                        {
                                            val_max = this.cDataChart[i][element][id_simu];
                                        }
                                    }
                                }
                            }
                            //calc kpi value compared to Val max
                            for (var id_simu in this.cDataChart[i][element]) {
                                if (this.cDataChart[i][element].hasOwnProperty(id_simu) && id_simu != "id") {
                                    if (!isNaN(parseInt(this.cDataChart[i][element][id_simu]))) {
                                        if (val_max > 0) {

                                            var cent = (this.cDataChart[i][element][id_simu] * 100) / val_max;
                                            if (cent % 1 != 0) {
                                                cent = Math.round(cent);
                                            }
                                            this.cDataChart[i][element][id_simu] = cent;
                                        }
                                        else {
                                            this.cDataChart[i][element][id_simu] = 0;

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
          if (data != undefined && data[params.type1] != undefined) {
            this.analysingTargetingUI.initSlider(data[params.type1]);
          }
            //then update the chart
            this.updateTabChart();
        }
    }


    /*
        Order branches graph
    */
    updateTabChart() {
        var newTab = [];
        var annee = this.analysingTargetingUI.getMainSliderFrom();
        if (this.cDataChart[annee] != undefined) {
            for (var i in this.cDataChart[annee]) {
                if (this.cDataChart[annee].hasOwnProperty(i)) {
                    newTab.push(this.cDataChart[annee][i]);
                }
            }
        }
        var newTabSort = newTab.sort(function (a, b) {
            if (a==undefined || a.id == undefined || b==undefined || b.id == undefined) {
                return 0;
            }
            var nA = a.id.toLowerCase();
            var nB = b.id.toLowerCase();

            if (nA < nB)
                return -1;
            else if (nA > nB)
                return 1;
            return 0;
        });
        this.analysingTargetingUI.updateTabChart(newTabSort,this.nameTarget);
    }

    Everest_callbackError(data, status) {
        this.analysingTargetingUI.everestCallbackError(data, status);
    }
}

export default Analysing_Targeting_CLS;
