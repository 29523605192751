// global error handler
export default (e, ui) => {
    // normal runtime error instance
    if (_.isError(e)) {
        ui.createAlert("alert", "error", e.message);
        return;
    }

    // response error
    const { responseJSON, responseText } = e;

    // from response json
    if (responseJSON) {
        const { error, errorMessage } = responseJSON;
        ui.createMessageError(error, errorMessage);
        return;
    }

    // from response txt
    if (responseText) {
        ui.createMessageError("", responseText);
        return;
    }

    ui.createAlert("alert", "error", "Oohs something goes wrong!");
}
