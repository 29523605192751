import Attributs_CLS from '../attributs.js';
import AnalysingTargetListEditUI from '../view/analysing_targetlist_edit_ui.js';

/*
   Open a similar box than the Edit box created in su_edit_target.js
   The user only can see values, not modify it
*/

class Analysing_TargetList_Edit_CLS {
    constructor(Everest, UI) {

        this.analysingTargetListEditUI = new AnalysingTargetListEditUI(UI,this);

        this.Everest = Everest;
        this.tabTarget = [];
        this.data_Models_Info = null; //Info at MOSA level  (liste des categories d'initiatives + timescales possibles)
        this.UO_Metadata = []; //List of metadata for attributs from a typename
        this.Urban_Objects_Attributes = null; //List of saved values for a pair "pid" (UrbanObject) / "targetID" (target)

        // table store attributes name which had been changed in a target
        this.tabAttributesModif = [];

        this.currentNodeTarget = null; //On stoque les info de la target, car le sim id de everest peut changer et ne plus être bon.

        this.LevelUORequired = 2;

    }

    init() {
        this.tabTarget = [];
        this.Everest.List_Models_Info(this.initTree.bind(this), this.Everest_callbackError.bind(this));
    }

    initTree(data, status, id) {
        this.data_Models_Info = data;
        var params = {
            mode: "Tree",
            uid: "trgt_ro",
            isReadOnly: true,
            idParent: "items_TargetList_Edit"
        };
        var par = {
            code: "TargetList_Edit_it"
        };
        this.tabTarget[par.code] = new Attributs_CLS(par, data.timescales, params, this.analysingTargetListEditUI.getUI());
        this.analysingTargetListEditUI.initTree();
    }
    setTabTargetFilter(value){
        for (var i in this.tabTarget) {
            if (this.tabTarget[i].Filter != undefined) {
                this.tabTarget[i].Filter(value);
            }
        }
    }

    Reset() {
        for (var i in this.tabTarget) {
            if (this.tabTarget[i].Reset != undefined) {
                this.tabTarget[i].Reset();
            }
        }
        this.Urban_Objects_Attributes = null;

    }

    ResetFromUO() {
        this.Reset();
        this.Everest.selectedTypeName = null;
        this.Everest.selectedPid = null;
        this.analysingTargetListEditUI.resetFromUO();
    }

    LoadFromUO(uo, treeNode) {
        if (this.Everest.selectedPid != treeNode.id) {
            this.Reset();
            var typeName = treeNode.UOtype;
            this.Everest.selectedTypeName = typeName;
            this.Everest.selectedPid = treeNode.id;
            this.Load();
            this.analysingTargetListEditUI.setVisibilityEditNoUO(false);
        }
    }
    Load(nodeTarget) {
        this.analysingTargetListEditUI.load(nodeTarget);
    }

    setCurrentNodeTarget(val){
        this.currentNodeTarget=val;
    }
    readTargetKPIValues(selectedNodes){
        var params = {
            nodeTarget: this.currentNodeTarget,
            nodeUo: selectedNodes
        }
        //(param, targetId, pid, callbackSuccess,...)
        this.Everest.Read_Target_KPIValues(params, params.nodeTarget.id, params.nodeUo.id, this.Read_Target_KPIValues_Callback.bind(this), this.Everest_callbackError.bind(this));
    }

    //Recup les valeurs par defaut pour le couple pid/Targetid
    Read_Target_KPIValues_Callback(data, status, obj) {
        this.Urban_Objects_Attributes = data;
        //Si le typename à changé, on requetes les nouveaux params.
        if (this.UO_Metadata[this.Everest.selectedTypeName] == undefined) {
            this.Everest.Read_KPIMetadata(this.Everest.selectedTypeName, this.Everest.selectedTypeName, this.Read_KPIMetadata_Callback.bind(this), this.Everest_callbackError.bind(this));
        } else {
            //On a tout, on lance le traitement
            this.BuildInterface(this.UO_Metadata[this.Everest.selectedTypeName], this.Everest.selectedTypeName);
        }
    }

    //Recup les valeurs pour un typeName
    Read_KPIMetadata_Callback(data, status, typeName) {
        this.UO_Metadata[typeName] = data;

        //On a tout, on lance le traitement
        this.BuildInterface(this.UO_Metadata[typeName], typeName);

    }
    BuildInterface(Object_Type_Metadata, typeName) {
        //Pour rappel, on doit construire une interface à partir de 3 elements :
        //  - data_Models_Info :  contient les info générique (notamment les timescales) => Requeter une fois au départ
        //  - Object_Type_Metadata (ou this.UO_Metadata[this.Everest.selectedTypeName]) : contient le type de données pour chaque élément=> Requeter une fois par typename (Building / city / ...)
        //  - Urban_Objects_Attributes => contient les valeurs pour chaque timescale correspondant.
        var isEmpty = true;
        this.tabTarget["TargetList_Edit_it"].Reset();
        //Construit l'arbre
        var zNodes = [];
        var nTmp = [];

        var pStr;
        var obj;
        var indice = -1;
        for (var i in Object_Type_Metadata) {
            pStr = this.GetMetadata(Object_Type_Metadata[i]["metadatas"], "Initiative");
            if (pStr != undefined) {
                if (nTmp[pStr] == undefined) {
                    obj = {
                        id: pStr,
                        pId: 0,
                        name: pStr,
                        open: false,
                        isParent: true
                    };
                    zNodes.push(obj);
                    nTmp[pStr] = obj.id;
                }
                obj = {
                    id: i,
                    pId: nTmp[pStr],
                    name: Object_Type_Metadata[i]["displayName"],
                    isParent: false,
                    chkDisabled: true,
                    obj: Object_Type_Metadata[i]
                };
                zNodes.push(obj);
                isEmpty = false;
            }
        }


        //igo TMA 2
        for (var i in Object_Type_Metadata) {
            var TreeUOtype = this.Get_ListTreeType(this.analysingTargetListEditUI.getTreeUO());
            var UOtypeLeel = this.Get_TreeTypeLevel(TreeUOtype, typeName);

            //if(typeName=="Neighborhood" || typeName=="Building")
            if (UOtypeLeel >= this.LevelUORequired)
            {
                Object_Type_Metadata[i].IsInitiative = false;
            }
            else
            {
                Object_Type_Metadata[i].IsInitiative = true;
            }
        }

        if (isEmpty) {
            //Afficher "Aucun rez"...
            this.analysingTargetListEditUI.setVisibilityNoRes(true);
        } else {
            this.tabTarget["TargetList_Edit_it"].AddItem(Object_Type_Metadata, zNodes, this.Urban_Objects_Attributes, null, false);
            //Init des valeurs
            for (var i in this.tabTarget) {
                if (this.tabTarget[i].InitAllValues != undefined) {
                    this.tabTarget[i].InitAllValues(0);
                }
            }
            this.analysingTargetListEditUI.setVisibilityNoRes(false);
        }
        this.analysingTargetListEditUI.buildInterface();
    }

    /*
     Récupère la liste des UOType à partir du tree
    */
    Get_ListTreeType(tree) {

        var array_Type = new Array();
        var array_nodes = tree.transformToArray(tree.getNodes());
        for (var i = 0; i < array_nodes.length; i++) {

            var level = array_nodes[i].level;
            var id = array_nodes[i].id;
            if (level != undefined && id != undefined) {
                if (array_Type[level] == undefined) {
                    array_Type[level] = { level: array_nodes[i]["UOtype"], pid: id };
                }
            }
        }
        return array_Type;
    }

    /*
        Récupere le niveau pour un type
    */
    Get_TreeTypeLevel(array_nodes, typeName) {
        for (var i = 0; i < array_nodes.length; i++) {
            if(array_nodes[i].level == typeName)
            {
                return i;
            }
        }
        return -1;
    }

    //Convertit les metadata en tableau associatif. Plus simple d'acces
    GetMetadata(tab, meta) {
        for (var i in tab) {
            if (tab.hasOwnProperty(i) && tab[i].name == meta) {
                return tab[i].value;
            }
        }
        return "";
    }
    //retourne True si le compte à les droits suffisant pour afficher le champ
    Is_Allowed(metadatas) {
        if (this.Everest.SuperUser == true)
            return true;

        var resu = false;
        for (var i = 0; i < metadatas.length; i++) {
            if (metadatas[i].name == "SuperUser") {
                if (metadatas[i].value == "no") {
                    return true;
                }
            }
        }
        return resu;
    }

    Reset_modif() {
        for (var i in this.tabTarget) {
            if (this.tabTarget.hasOwnProperty(i)) {
                if (this.tabTarget[i].InitAllValues != undefined) {
                    this.tabTarget[i].InitAllValues();
                }
            }
        }

    }





    Everest_callbackError(data, status) {
        this.analysingTargetListEditUI.everestCallbackError(data, status);
    }


}

export default Analysing_TargetList_Edit_CLS;
