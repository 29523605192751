import Cavi2Util from "../helpers/cavi.util.js";

export default {
  fadeVisible: {
    init(element, valueAccessor) {
      const value = valueAccessor();
      $(element).toggle(ko.unwrap(value));
    },
    update(element, valueAccessor) {
      const value = valueAccessor();

      if (ko.unwrap(value)) {
        // fadein to topmost
        $(element).fadeIn({
          complete: $(element).css({ "z-index": Cavi2Util.getMaxZ(undefined) + 1 }),
        });
      } else {
        // fadeout to normal flow
        $(element).fadeOut({ complete: $(element).css({ "z-index": 0 }) });
      }
    },
  },
};
