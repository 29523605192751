"use strict";

import {
    DEFAULT_FILL_COLOR,
    DEFAULT_STROKE_COLOR,
    LAYER_TYPE_3DOBJECTS,
    LAYER_TYPE_LABELS,
    LAYER_TYPE_POLYGONS,
    LAYER_TYPE_POLYLINES
} from "../helpers/constants.js";

import Cavi2Util from "../helpers/cavi.util.js";

const getColorFallback = Cavi2Util.getColorFallback;

class LayersSelectionModel {
    constructor(resultsLayersGroups) {
        // for panel visibility
        this.state = ko.observable(false);

        // result layers groups
        this.resultsLayersGroups = ko.observableArray(resultsLayersGroups);

        // wfs layers
        this.wfsLayers = {};

        this.hide = () => {
            this.state(false);
            // not remove the results layers groups. no need to load multiple times
            // this.resultsLayersGroups.removeAll();
        };

        this.show = () => {
            this.state(true);
        };

        this.reset = () => {
            this.hide();
            this.resultsLayersGroups.removeAll();
            // TODO TW3D should provide tear down, not remove wfs layers since they loaded
            // this.wfsLayers = {}
        };

        this.run = (simCases, resultsLayers, tw3d) => {
            let current = simCases.map(elem => elem["caseId"]);
            let previous = this.resultsLayersGroups().map(elem => elem["caseId"]);

            let casesToAdd = _.difference(current, previous);
            let casesToRemove = _.difference(previous, current);

            // tear down first
            if (casesToRemove.length) {
                // remove no more needed results layers groups
                this.resultsLayersGroups.remove((elem) => casesToRemove.includes(elem["caseId"]));

                // disable corresponding wfs layers
                this.disableWFSLayers(casesToRemove);

                // console.log(`Disable Result Layers of Cases: ${casesToRemove}`);
            }

            // set up later
            if (casesToAdd.length) {
                simCases = simCases.filter(elem => casesToAdd.includes(elem["caseId"]));

                // add new layer info results layers groups
                this.resultsLayersGroups.push(...this.toResultsLayersGroups(simCases, resultsLayers));

                // enable corresponding wfs layers
                this.enableWFSLayers(tw3d);

                // console.log(`Enable Result Layers of Cases: ${casesToAdd}`);
            }

        };

        this.toggleWFSLayer = (resultLayer) => {
            let wfsLayer = this.findWFSLayer(resultLayer);

            if (wfsLayer) {
                // toggle result layer's visibility
                resultLayer.visible(!resultLayer.visible());

                // set corresponding wfs layer's visibility
                wfsLayer.SetVisibility(resultLayer.visible());

                // console.log(`Set Result Layer ${resultLayer.visible() ? "visible" : "invisible"}: ${resultLayer["id"]}`);
            }
        };

        this.zoomInWFSLayer = (tw3d, resultLayer) => {
            let wfsLayer = this.findWFSLayer(resultLayer);

            if (wfsLayer) {
                // zoom in the layer's bounding box
                tw3d.flyToBbox(resultLayer["proj"], resultLayer["boundingBox"]);

                // console.log(`Zoom in Result Layer: ${resultLayer["id"]}`);
            }
        };
    }

    /**
     * Load or Cache the WFS Layers.
     * Assume that "One Simulation Case can ONLY RUN ONCE".
     * In other words, layers of one simulation case won't be updated any more
     * in the run time.
     *
     * This loads the WFS layers of one simulation case if they are not loaded before
     * If loaded, it will stop any further loading of that simulation case.
     *
     * @param tw3d
     */
    enableWFSLayers(tw3d) {
        for (let resultsLayersGroup of ko.toJS(this.resultsLayersGroups)) {
            let caseId = resultsLayersGroup["caseId"];

            // init wfs layers of the simCase if not exist
            if (!this.wfsLayers[caseId]) {
                this.wfsLayers[caseId] = [];
            }

            // if wfs layers of the simCase are already loaded, jump out the loop
            if (this.wfsLayers[caseId].length) {
                continue;
            }

            // if not loaded, load new wfs layers for the simCase
            let resultsLayers = resultsLayersGroup["resultsLayers"];

            this.wfsLayers[caseId] = this.wfsLayers[caseId].concat(resultsLayers.map(resultLayer => {
                return tw3d.loadWFSFeatureLayer(resultLayer, caseId);
            }));
        }
    }

    /**
     * Set layers of simulation cases invisible.
     * Assume that "One Simulation Case can ONLY RUN ONCE".
     *
     * @param caseIds {Array}
     */
    disableWFSLayers(caseIds) {
        for (let caseId of caseIds) {
            this.wfsLayers[caseId] ? this.wfsLayers[caseId].forEach(layer => layer.SetVisibility(false)) : undefined;
        }
    }

    findWFSLayer(resultLayer) {
        let layerIdToCaseId = (layerId) => layerId && typeof layerId === "string" ? layerId.split("-").pop() : "";

        let wfsLayersByCase = this.wfsLayers[layerIdToCaseId(resultLayer["id"])];

        return wfsLayersByCase ? wfsLayersByCase.find(layer => layer.id.includes(resultLayer["id"])) : undefined;
    }

    /**
     * Return results layers group, which has following data structure.
     *
     * <resultsLayersGroups> = [
     *     <resultsLayerGroup>
     *   ...
     * ]
     *
     * where:
     *  <resultsLayerGroup> = {
     *      caseId: <case_id>,
     *      caseName: <case_name>,
     *      resultsLayers: [<resultLayer>...]
     *      _elementId: <element_id>
     *  }
     *
     *
     * @param simCases {array} [{caseId: 'xxx', caseName: 'xxx'}..]
     * @param resultsLayers {array} "resultsLayers" from UA008
     * @returns {array}
     * @private
     */
    toResultsLayersGroups(simCases, resultsLayers) {
        return simCases.map(simCase => {
            // for dom element collapse
            simCase["_groupId"] = `results-layers-group-${simCase["caseId"]}`;

            // simCase["caseName"] = `${simCase["caseName"]} (${simCase["caseId"]})`;

            simCase["resultsLayers"] = this._extendResultsLayers(simCase["caseId"], resultsLayers);

            return simCase;
        });
    }

    /**
     * Return extended results layers for one single simulation case, which has following structure
     *
     * <resultsLayers> = [
     *      <resultLayer>,
     *      ....
     * ]
     *
     * where:
     *  <resultLayer> = {
     *     id: <technicalName>-caseId,
     *     ... (inherit props from original resultLayer)
     *     legendConfig: <LegendConfig>
     * }
     *
     * @param caseId {string}
     * @param resultsLayers {array} "resultsLayers" from UA008
     * @returns {array}
     * @private
     */
    _extendResultsLayers(caseId, resultsLayers) {
        return resultsLayers.map(layer => {
            // need deep copy of the layer info for each sim case
            let copy = JSON.parse(JSON.stringify(layer));

            // assign unique id for layer info
            copy["id"] = `${layer["technicalName"]}-${caseId}`;

            // observe layer's visibility
            copy["visible"] = ko.observable(!!layer.visible);

            // add legend config to layer for UI manipulation
            copy["legendConfig"] = this.toLegendConfig(layer);

            return copy;
        });
    }

    /**
     * Return a legend config object.
     *
     * There are several variants of the return
     * depending on layer type & layer config.
     *
     * 1. Polylines & Polygons, always return:
     * {
     *   type: "Polylines"| "Polygones",
     *   fillColor: <FillColor> {hex},
     *   strokeColor: <StrokeColor> {hex},
     *   groups: <LegendGroups> {array}
     * }
     *
     * 2. 3DObjects , always return:
     * {
     *    type: "3DObjects",
     *    src: "../images/layers/Model3D.png",
     *    groups: []
     * };
     *
     * 3. Labels, which have 2 variants
     *
     * (1) if image url in layer config just pure URL:
     * return {
     *    type: "Labels",
     *    src: <imageURL>,
     *    groups: []
     * };
     *
     * (2) if image url or txt color in layer config represents classifications
     * return {
     *    type: "Labels",
     *    fillColor: <FillColor> {hex},
     *    strokeColor: <StrokeColor> {hex},
     *    groups: <LegendGroups> {array}
     * };
     *
     *
     * @param layer
     * @return {object}
     */
    toLegendConfig(layer) {
        let layerType = layer["type"];
        let defaults = {};

        if ([LAYER_TYPE_POLYGONS, LAYER_TYPE_POLYLINES].includes(layerType)) {
            defaults = this._toPolyGeomLegendConfig(layer);
        }

        if (layerType === LAYER_TYPE_3DOBJECTS) {
            defaults = this._to3DObjectLegendConfig(layer);
        }

        if (layerType === LAYER_TYPE_LABELS) {
            defaults = this._toLabelsLegendConfig(layer);
        }

        // post update group items for all layer types
        defaults["groups"] = defaults["groups"].map(group => {
            group["items"] = group["items"].map(item => {
                item["type"] = layerType;
                return item;
            });

            return group;
        });

        return defaults;
    }

    _toLabelsLegendConfig(layer) {
        let imageUrl = layer["layerConfig"]["image_url"];
        let txtColor = layer["layerConfig"]["txt_color"];

        // if image url is a pure URL, root element should be image and has no classification
        if (imageUrl && typeof imageUrl === "string" && !imageUrl.startsWith("@")) {
            return {
                type: layer["type"],
                src: imageUrl,
                groups: []
            };
        }

        let imageClassGroups = this._getImageLegendGroups(this._toClassification(imageUrl));
        let canvasClassGroups = this._getCanvasLegendGroups(this._toClassification(txtColor), DEFAULT_STROKE_COLOR);

        // if both image url & txt color both have no classification
        if (!imageClassGroups.length && !canvasClassGroups.length) {
            return {
                type: layer["type"],
                fillColor: getColorFallback(txtColor, DEFAULT_FILL_COLOR),
                strokeColor: DEFAULT_STROKE_COLOR,
                groups: []
            };
        }

        // if one of image url and txt color has classification
        return {
            type: layer["type"],
            fillColor: DEFAULT_FILL_COLOR,
            strokeColor: DEFAULT_STROKE_COLOR,
            // image url classification's priority is higher than txt color's one
            groups: imageClassGroups.length ? imageClassGroups : canvasClassGroups
        };
    }

    _toPolyGeomLegendConfig(layer) {
        let layerType = layer["type"];
        let layerConfig = layer["layerConfig"];

        let legendConfig = {
            type: layerType,
            fillColor: DEFAULT_FILL_COLOR,
            strokeColor: DEFAULT_STROKE_COLOR,
            groups: []
        };

        let fillColor = LAYER_TYPE_POLYGONS === layerType ? layerConfig["fill_color"] : layerConfig["line_color"];
        let strokeColor = layerConfig["border_color"];

        legendConfig["groups"] = this._getCanvasLegendGroups(this._toClassification(fillColor), this._toClassification(strokeColor));

        // if no classification available
        if (!legendConfig["groups"].length) {
            legendConfig["fillColor"] = getColorFallback(fillColor, DEFAULT_FILL_COLOR);
            legendConfig["strokeColor"] = getColorFallback(strokeColor, DEFAULT_STROKE_COLOR);
        }

        return legendConfig;
    }

    _to3DObjectLegendConfig(layer) {
        return {
            type: layer["type"],
            src: "../images/layers/Model3D.png",
            groups: []
        };
    }

    /**
     * Return image legend groups for Labels
     *
     * it should return as following e.g.
     * [{
     *      title:"classification_name1",
     *      items:[
     *          {name:"class_name1", src:"123.svg"},
     *          {name:"class_name2", src:"456.svg"},
     *          {name:"class_name3", src:"789.svg"},
     *         ....
     *      ]
     * }]
     *
     * @param imageUrl {object} classification-like imageUrl
     * @returns {Array}
     * @private
     */
    _getImageLegendGroups(imageUrl) {
        if (this._isInvalidClassification(imageUrl)) return [];

        let title = imageUrl["title"];

        let items = Object.keys(imageUrl["val"]).map(prop => {
            return {
                name: prop,
                src: imageUrl["val"][prop]
            };
        });

        return items.length ? [{ title: title, items: items }] : [];
    }

    /**
     * Return canvas legend groups in which fillColor & strokeColor represent classifications
     *
     * it should return as following e.g.
     * [{
     *      title:"classification_name1",
     *      items:[
     *          {name:"class_name1", fillColor:"f1", strokeColor:"s1"},
     *          {name:"class_name2", fillColor:"f2", strokeColor:"s2"},
     *          {name:"class_name3", fillColor:"f3", strokeColor:"s3"},
     *         ....
     *      ]
     * }, ...
     * ]
     *
     * @param fillColor {object} classification-like fillColor
     * @param strokeColor {object} classification-like strokeColor
     * @returns {Array}
     * @private
     */
    _getCanvasLegendGroups(fillColor, strokeColor) {
        // if both are invalid, then return
        if (this._isInvalidClassification(fillColor) && this._isInvalidClassification(strokeColor)) return [];

        let normalizedFillColor = this._normalizeCanvasClassification(strokeColor, fillColor, DEFAULT_FILL_COLOR);
        let normalizedStrokeColor = this._normalizeCanvasClassification(fillColor, strokeColor, DEFAULT_STROKE_COLOR);

        if (normalizedFillColor["title"] === normalizedStrokeColor["title"]) {
            return this._getHomoCanvasLegendGroups(normalizedFillColor, normalizedStrokeColor);
        } else {
            //TODO DOES IT EVEN MAKE SENSE TO PRESENT 2 CLASSIFICATIONS?
            return this._getHeteroCanvasLegendGroups(normalizedFillColor, normalizedStrokeColor);
        }
    }

    /**
     * Return homogeneous legend groups if fillColor & strokeColor represent single classification
     *
     * Given that as following e.g.
     * fillColor = "@cls_1{'prop1':'val11', 'prop3':'val13'}"
     * strokeColor = "@cls_1{'prop1':'val21', 'prop2': 'val22'}"
     *
     * it should return:
     * [{
     *      title:"cls_1",
     *      items:[
     *          {name:"pro1", fillColor:"va11", strokeColor:"val21"},
     *          {name:"pro2", fillColor:<defaultFillColor>, strokeColor:"val22"},
     *          {name:"pro3", fillColor:"va13", strokeColor:<defaultStrokeColor>},
     *
     *      ]
     * }]
     *
     * @param fillColor {object} classification-like fillColor
     * @param strokeColor {object} classification-like strokeColor
     * @returns {Array}
     * @private
     */
    _getHomoCanvasLegendGroups(fillColor, strokeColor) {
        // extract unique props from both colors
        let mergedProps = Array.from(new Set([...Object.keys(fillColor["val"]), ...Object.keys(strokeColor["val"])]));

        let title = fillColor["title"] ? fillColor["title"] : strokeColor["title"];
        let items = mergedProps.map(prop => {
            return {
                name: prop,
                fillColor: getColorFallback(fillColor["val"][prop], DEFAULT_FILL_COLOR),
                strokeColor: getColorFallback(strokeColor["val"][prop], DEFAULT_STROKE_COLOR)
            };
        });

        if (items.length) {
            return [{ title: title, items: items }];
        } else {
            return [];
        }
    }

    /**
     * Return heterogeneous legend groups if fillColor & strokeColor represent
     * 2 different classifications
     *
     * Given that as following e.g.
     * fillColor = "@cls_1{'prop1':'val11'}"
     * strokeColor = "@cls_2{'prop1':'val22'}"
     *
     * it should return:
     * [
     *  {
     *      title:"cls_1",
     *      items:[{name:"pro1", fillColor:"va11", strokeColor:<defaultStrokeColor>}]
 *      },
     *  {
     *      title:"cls_2",
     *      items:[{name:"pro2", fillColor:<defaultFillColor>, strokeColor:"val22"}]
 *      }
     * ]
     *
     * @param fillColor {object} classification-like fillColor
     * @param strokeColor {object} classification-like strokeColor
     * @returns {Array}
     * @private
     */
    _getHeteroCanvasLegendGroups(fillColor, strokeColor) {
        return [fillColor, strokeColor]
            .map((colorObj, index) => {
                // true is fillColor, false is strokeColor
                let isFillColor = !index;

                let title = colorObj["title"];

                let items = Object.keys(colorObj["val"]).map(prop => {
                    let fillVal = fillColor["val"][prop],
                        strokeVal = strokeColor["val"][prop];

                    return {
                        name: prop,
                        fillColor: isFillColor ? getColorFallback(fillVal, DEFAULT_FILL_COLOR) : DEFAULT_FILL_COLOR,
                        strokeColor: isFillColor ? DEFAULT_STROKE_COLOR : getColorFallback(strokeVal, DEFAULT_STROKE_COLOR)
                    };
                });

                if (items.length) {
                    return { title: title, items: items };
                } else {
                    return undefined;
                }
            })
            .filter(elem => elem);
    }

    /**
     * Return self if input str doesn't match classification definition
     * Or return a classification object
     *
     * Given that e.g."@cls_name{'prop1':'val1','prop2':'val2'}"
     * should return
     *
     * <classification> = {
     *    title: 'cls_name'
     *    val: {
     *      pro1: val1,
     *      pro2: val2
     *    }
     * }
     *
     * @param str {string}
     * @returns {object|string}
     * @private
     */
    _toClassification(str) {
        let hasClasses = str && typeof str === "string" && str.startsWith("@");

        // if no classification, self return
        if (!hasClasses) {
            return str;
        }

        try {
            //TODO too fragile... maybe something else
            let title = str.substring(1, str.indexOf("{"));
            let val = JSON.parse(str.substring(str.indexOf("{")).replace(/'/g, "\""));

            return !title || !val ? str : { title: title, val: val };
        } catch (e) {
            // catch unexpected json parsed error and self return
            return str;
        }
    }

    /**
     * Return true if the classification is invalid
     *
     * @param classification
     * @returns {boolean}
     * @private
     */
    _isInvalidClassification(classification) {
        // valid parsed classification should be {title: '', val: {}}, strictly check
        let matchClassification = obj => {
            // title should be non empty string
            let isValidTitle = typeof obj["title"] === "string" && obj["title"];

            // val should be non empty object
            let isValidVal = typeof obj["val"] === "object" && !!Object.keys(obj["val"]).length;

            return isValidTitle && isValidVal;
        };

        return typeof classification === "string" || !classification || !matchClassification(classification);
    }

    _normalizeCanvasClassification(source, target, defaultTargetValue) {
        let derivedFromSource = (source, target, defaultTargetValue) => {
            let copy = JSON.parse(JSON.stringify(source));

            for (let prop in copy["val"]) {
                if (copy["val"].hasOwnProperty(prop)) {
                    copy["val"][prop] = getColorFallback(target, defaultTargetValue);
                }
            }

            return copy;
        };

        let flag = typeof target === "string" || !target || !Object.keys(target).length;

        return flag ? derivedFromSource(source, target, defaultTargetValue) : target;
    }
}

export default LayersSelectionModel;
