import Cavi2Util from "../helpers/cavi.util.js";
import warnImg from "../assets/images/interface/warning.png";
import pauseImg from "../assets/images/interface/paused.png";
import successImg from "../assets/images/interface/success.png";

/***
Let to manage a simulation run
- display : False -> No progress window, True -> Display in (i) menu and on 3D -> "allTaskMenuOnly" -> Display in (i) menu only
- Fct mandatory : Check(), isFinished
- Var mandatory : id
***/

var TaskSimulation_CLS = function(display, everest, id, name, runComplete, getLast) {
    this.id = id;
    this.name = name;
    this.Everest = everest;

    this.isShowTaskSimuPopUp = display;
    this.topPos = 70;
    this.leftPos = 250;
    this.libelleTxt = this.Everest.libelle.libelle[0];

    this.objEverest = {
        progress: 0,
        id: this.id,
        startDate: "-",
        status: "",
        endDate: ""
    };
    this.requeteEnCours = false;
    this.lastUpdate = new Date(1);
    this.isFirstTime = (getLast != undefined && getLast == true ? false : true);
    this.buildHTML();
    this.Check();
    this.nbErreur = 0;
    this.runComplete = runComplete;

    this.runFailed = false;

    if(this.isShowTaskSimuPopUp=="allTaskMenuOnly" && !$("#div_Task").parents('.dropdown:first').is('.open')) $("#div_Task").dropdown('toggle');

}

TaskSimulation_CLS.prototype.constructor = TaskSimulation_CLS;

TaskSimulation_CLS.prototype.getNbSec = function() {

    return Math.abs(((new Date()).getTime() - this.lastUpdate.getTime()) / 1000);
}

TaskSimulation_CLS.prototype.isFinished = function() {
    //If there is more than 3 errors, we stop the run. Il est possible d'avoir une erreur de doublon de lancement de tache qui ne bloque pas le process par la suite.
    return this.objEverest.progress == 100 || this.nbErreur > 2;
}


/*
    Create the box  containing infos about run simulation (name, date, % ...)
*/
TaskSimulation_CLS.prototype.buildHTML = function() {

    $("#div_" + this.id).remove();

    var topDiv, leftDiv;


    if ($('div.taskPosition').length) {
        var lastDiv;
        lastDiv = $(".taskPosition").last();
        var valueDisplay = $(lastDiv).css('display');

        leftDiv = $(".taskPosition").last().position().left;
        topDiv = $(".taskPosition").last().position().top;
        if (leftDiv < this.leftPos)
            leftDiv = this.leftPos;
        if (topDiv < this.topPos)
            topDiv = this.topPos;
        if (valueDisplay == "block") {
            var heightGlobal = $('#mainContent').height();
            var widthGlobal = $('#mainContent').width();
            topDiv += ((1 / 100) * heightGlobal);
            leftDiv += ((1 / 100) * widthGlobal);
        }

        this.leftPos = leftDiv;
        this.topPos = topDiv;
    } else {
        topDiv = 70;
        leftDiv = 250;
    }
    var html = '<div class="info-box bg-aqua taskItem taskPosition zdynamic" id="div_' + this.id + '">';
    html += '       <span class="info-box-icon"><i class="fa fa-hourglass-o icon_' + this.id + '"></i></span>';
    html += '       <div class="info-box-content">';
    html += '           <span class="info-box-text">' + this.name + ' (' + this.id + ')' + '</span>';
    html += '           <span class="progress-description text_' + this.id + '">' + (this.objEverest.endDate != undefined && this.objEverest.endDate != null && this.objEverest.endDate != "" ? this.libelleTxt.POPUP_RUN_SIMU_END + this.objEverest.endDate : this.libelleTxt.POPUP_RUN_SIMU_START + this.objEverest.startDate) + '</span>';
    html += '           <!-- The progress section is optional -->';
    html += '           <div class="progress">';
    html += '               <div class="progress-bar pbar_' + this.id + '" style="width: ' + this.objEverest.progress + '%"></div>';
    html += '           </div>';
    html += '           <span class="progress-description desc_' + this.id + '">' + this.objEverest.progress + "% - " + this.objEverest.status + '</span>';
    html += '       </div><!-- /.info-box-content -->';
    html += '   </div><!-- /.info-box -->';


    if (this.isShowTaskSimuPopUp) {
        $("#div_AllTask").append(html);
    }

    $("#div_" + this.id).draggable({
        containment: '#mainpanel',
        cursor: 'move',
        snap: '#mainpanel',
        revert: 'invalid',
      helper: 'clone',
      start: ()=> {
        $("#div_" + this.id + "_child").css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);
      },
      stop: () => {
        $("#div_" + this.id + "_child").css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);
        $("#div_" + this.id + "_child").on("click", () => {
          $("#div_" + this.id + "_child").css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);
        });
      }
    });

  $("#div_" + this.id + "_child").on("click", ()=> {
    $("#div_" + this.id + "_child").css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);
    });
    if(this.isShowTaskSimuPopUp != "allTaskMenuOnly") {
        let idChild = "div_" + this.id + "_child";
        $("#" + idChild).remove();

        $("#mainpanel").append($("#div_" + this.id).clone().prop('id', idChild).removeClass('taskItem ui-draggable-dragging').css({
            position: 'absolute',
            left: leftDiv + 'px',
            top: topDiv + 'px',
            width: "350px",
          border: 'solid 1px white',
          zIndex: Cavi2Util.getMaxZofClass("zdynamic") + 1
        }).draggable({
          cursor: 'move',
          start: function () {
            $("#" + idChild).css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);
          },
          stop: () => {
            $("#" + idChild).css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);
          }
        }));

        $("#" + idChild + " .info-box-text").after('<div style="position:absolute; top:2px;right:2px;"><button class="btn btn-box-tool btclose" style="color:white;"><i class="fa fa-minus"></i></button>');
        $("#" + idChild + " .btclose").on("click", function () {
          var options = {
            to: "#div_Task",
            className: "ui-effects-transfer-open"
          };
          $("#" + idChild).fadeOut(200).effect("transfer", options, 500).dequeue().remove();
          if(!$("#div_Task").parents('.dropdown:first').is('.open')) $("#div_Task").dropdown('toggle');
        });
        $("#" + idChild).on("click", function () {
          $("#" + idChild).css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1);
        });
        setTimeout(() => { $("#" + idChild).css("zIndex", Cavi2Util.getMaxZofClass("zdynamic") + 1)},10);
    }
}



/*
    If
*/
TaskSimulation_CLS.prototype.Check = function() {
    if (this.requeteEnCours == false && this.getNbSec() > 3) { //Mise à jours toutes les 3 secondes.
        this.runEverest();
    }
}

/*
    Launches Everest request for running sim case or read status of this simulation selected
*/
TaskSimulation_CLS.prototype.runEverest = function() {
    this.lastUpdate = new Date();
    this.requeteEnCours = true;
    if (this.isFirstTime == true) {
        this.isFirstTime = false;
        this.Everest.Run_Simulation_Case(this.id, this.CheckSucces.bind(this), this.CheckFailed.bind(this));
    } else {
        this.Everest.Read_Run_Status(this.id, this.CheckSucces.bind(this), this.CheckFailed.bind(this));
    }
}

/*
    Fct called on callbackSuccess of  Run_Simulation_Case and Read_Run_Status
    Check status and progress
    Modify HTML content box simulation
*/
TaskSimulation_CLS.prototype.CheckSucces = function(data, status, id) {
    this.objEverest = data;
    //check if a simulation is already present in the array storing key/value pairs (idSimu + endDate)
    //returns the object found
    var ifPresent = this.Everest.infosSimuRun.find(function(elem) {
        return elem["idSim"] === id;
    });
    //stores the index of the pair
    var index = this.Everest.infosSimuRun.findIndex(function(x) {
        return x.idSim == id; //this.id;
    });
    //if the object is not in the array, adds it
    //else check if the endDate is different
    if (ifPresent === undefined) {
        if (this.objEverest.id != null) { // means it's running : id of run
            this.Everest.infosSimuRun.push({
                "idSim": this.id,
                "date": this.objEverest.endDate
            });
        }
    } else {
        if (index > -1) {
            if (this.Everest.infosSimuRun != undefined) {
                if ((this.objEverest.endDate != null) && (this.objEverest.endDate != this.Everest.infosSimuRun[index]["date"])) {
                    this.Everest.infosSimuRun[index]["date"] = this.objEverest.endDate;
                }
            }
        }
    }


    //Update the content of simulation run box
    $(".text_" + this.id).text(this.objEverest.endDate != undefined && this.objEverest.endDate != null && this.objEverest.endDate != "" ? /*"End : "*/ this.libelleTxt.POPUP_RUN_SIMU_END + this.objEverest.endDate : /*"Start : "*/ this.libelleTxt.POPUP_RUN_SIMU_START + this.objEverest.startDate);
    $(".pbar_" + this.id).css("width", this.objEverest.progress + "%");
    //$(".desc_" + this.id).text(this.objEverest.progress + " - " + this.objEverest.status);


    if (this.objEverest.status == "notStarted") {
        $(".desc_" + this.id).text(this.objEverest.progress + "% - " + this.libelleTxt.POPUP_RUN_SIMU_NOT_Start);
    } else if (this.objEverest.status == "pendingConfiguration") {
        $(".desc_" + this.id).text(this.objEverest.progress + "% - " + this.libelleTxt.POPUP_RUN_SIMU_PENDING);
    } else if (this.objEverest.status == "created") {
        $(".desc_" + this.id).text(this.objEverest.progress + "% - " + this.libelleTxt.POPUP_RUN_SIMU_STARTING);
    } else if (this.objEverest.status == "stopped") {
        $(".desc_" + this.id).text(this.objEverest.progress + "% - " + this.libelleTxt.POPUP_RUN_SIMU_STOP);
    } else if (this.objEverest.status == "inProgress") {
        $(".desc_" + this.id).text(this.objEverest.progress + "% - " + this.libelleTxt.POPUP_RUN_SIMU_PROGRESS)
    } else if (this.objEverest.status == "paused") {
        $(".desc_" + this.id).text(this.objEverest.progress + "% - " + this.libelleTxt.POPUP_RUN_SIMU_PAUSED)
    } else if (this.objEverest.status == "finished") {
        $(".desc_" + this.id).text(this.objEverest.progress + "% - " + this.libelleTxt.POPUP_RUN_SIMU_COMPLETE)
    } else {
        $(".desc_" + this.id).text(this.objEverest.progress + "% - " + this.libelleTxt.POPUP_RUN_SIMU_FAIL)
    }




    //var tabClasse = ["fa-hourglass-o", "fa-hourglass-3", "fa-hourglass", "fa-check", "fa-warning"];
    var htmlDiy = "";
    if (this.objEverest.progress == undefined) {
        htmlDiy = "0% ";
    } else {
        htmlDiy = this.objEverest.progress + "% ";

    }

    $("#diyStopBtn_" + this.id).toggle(this.objEverest.status === "paused");

    if (this.objEverest.status !== undefined && this.objEverest.status === "failed") {
        this.nbErreur++;
        $(".icon_" + this.id).removeClass().addClass("fa fa-warning icon_" + this.id);
        htmlDiy = `<img src=${warnImg} class='ztree-diy-btn' alt='Error' title='${this.objEverest.status}'/>`;
        this.runFailed = true;
    } else if (this.objEverest.status === "paused") {
        $(".icon_" + this.id).removeClass().addClass("fa fa-pause-circle icon_" + this.id);
        htmlDiy = `<img src=${pauseImg} class='ztree-diy-btn' alt='Paused' title='${this.objEverest.status}'/>`;
        this.runFailed = false;
        this.runComplete(this.id);
    } else if (this.objEverest.progress === 100) {
        if ($(".icon_" + this.id).hasClass("fa fa-check") === false) {
            $(".icon_" + this.id).removeClass().addClass("fa fa-check icon_" + this.id);
            this.runFailed = false;
            $("#div_" + this.id + "_child").delay(5000).fadeOut();
        }
        if (this.objEverest.status === "finished") {
            htmlDiy = `<img src=${successImg}  class='ztree-diy-btn' title='Success : ${this.objEverest.endDate}' alt='Success'/>`;
            $(".icon_" + this.id).on("click", function() {
                $("#popup_result").fadeIn(600);
                this.Everest.TestApiOpenDap(this.id);
            }.bind(this));
            this.runFailed = false;
            this.runComplete(this.id);

            //update layers dynamic WFS
            //boolean set to true
            // with imer on tw3d class, we test this boolean value
            this.Everest.boolCallWFSDyn = true;
            if (this.Everest.boolRunIsnotFromInit == true) {
                this.Everest.cptRunIsnotFromInit++;
            }
        }
    } else if (this.objEverest.progress > 75 && this.objEverest.progress < 100) {
        if ($(".icon_" + this.id).hasClass("fa-hourglass") == false) {
            $(".icon_" + this.id).removeClass().addClass("fa fa-hourglass icon_" + this.id);
            this.runFailed = false;
        }
    } else if (this.objEverest.progress > 25 && this.objEverest.progress <= 75) {
        if ($(".icon_" + this.id).hasClass("fa-hourglass-3") == false) {
            $(".icon_" + this.id).removeClass().addClass("fa fa-hourglass-3 icon_" + this.id);
            this.runFailed = false;
        }
    } else if (this.objEverest.progress <= 25) {
        if ($(".icon_" + this.id).hasClass("fa-hourglass-o") == false) {
            $(".icon_" + this.id).removeClass().addClass("fa fa-hourglass-o icon_" + this.id);
            this.runFailed = false;
        }
    } else {

        if ($(".icon_" + this.id).hasClass("fa-warning") == true) { //OK
            $(".icon_" + this.id).removeClass().addClass("fa fa-warning icon_" + this.id);
            this.runFailed = false;
        } else {
            $(".text_" + this.id).text(this.libelleTxt.POPUP_RUN_SIMU_START + "  ");
            $(".desc_" + this.id).text("0% - Waiting");
            this.runFailed = false;
        }
    }

    if (this.objEverest.status !== "notStarted" && this.objEverest.status !== "pendingConfiguration") {
            // when run started, enable log to be downloaded
            $(`#diyDownloadBtn_${this.id}`).removeClass("d-none").addClass("d-inline");
    }



    $("#diyResult_" + this.id).html(htmlDiy);
    this.lastUpdate = new Date();
    this.requeteEnCours = false;
}

/*
    Change HTML content box if the request Run (or read status) failed
*/
TaskSimulation_CLS.prototype.CheckFailed = function(jqXHR, textStatus, errorThrown) {

    this.nbErreur++;
    this.requeteEnCours = false;
    this.lastUpdate = new Date();
    $(".icon_" + this.id).removeClass().addClass("fa fa-warning icon_" + this.id);
    $("#diyResult_" + this.id).html(`<img src=${warnImg} style='height: 15px;vertical-align: middle;margin-bottom: 4px;' alt='Error' title='${textStatus} : ${errorThrown}'/>`);
}

/*
    If data return req Everest Run status is undefined or failed : create a box with the error message
*/
TaskSimulation_CLS.prototype.Everest_Error = function(data) {
    var error = "";
    var code_error = "";
    if (data.responseJSON != undefined) {
        code_error = (data.responseJSON.error != undefined ? data.responseJSON.error : "");
    }

    if (data.responseJSON != undefined) {
        error = data.responseJSON.errorMessage;
    } else if (data.responseText != undefined) {
        error = data.responseText;
    } else {
        error = "The simulation encountered an error during the run.";
    }


    $("#div-alert-msgError").css("display", true);

    var html = '<div class="alert alert-danger zalert">';
    html += '                <button type="button" id="btn-close-error" class="close" data-dismiss="alert">×</button>';
    html += '<h3>	<i class="icon fa fa-ban"></i> Error : ' + code_error + '</h3>';
    html += "                " + error;
    html += "             </div>";

    $("#div-alert-msgError").stop();
    $("#div-alert-msgError").empty();
    $("#div-alert-msgError").append(html);

    var closePopup = function() {
        $("#div-alert-msgError").css("display", "none");
        $("#btn-close-error").unbind("click", closePopup);
    };
    $("#btn-close-error").bind("click", closePopup);
    $("#div-alert-msgError").show();
}

export default TaskSimulation_CLS;
